<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="4" md="4" v-if="strainPreservedSelected">
        <v-card class="fill-height">
          <v-card-text>
            <v-container>
              <v-row class="mb-2">
                <v-col>
                  <v-btn fab color="primary" x-small @click="closeDetail">
                    <v-icon> mdi-keyboard-backspace </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <detail-preserved-working :reseed="strainPreservedSelected" />
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        :sm="strainPreservedSelected ? '8' : '12'"
        :md="strainPreservedSelected ? '8' : '12'"
      >
        <v-card class="fill-height">
          <v-card-text class="fill-height">
            <v-container class="fill-height">
              <v-row class="fill-height" align="center">
                <v-col offset="6" cols="4">
                  <v-text-field
                    label="Búsqueda"
                    prepend-inner-icon="mdi-magnify"
                    hide-details
                    v-model="search"
                  ></v-text-field>
                </v-col>
                <v-col cols="2" class="text-center">
                  <v-menu
                    offset-y
                    :close-on-click="true"
                    :close-on-content-click="false"
                    v-model="menu"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" v-bind="attrs" v-on="on">
                        <v-icon left>mdi-filter</v-icon>
                        Filtro
                      </v-btn>
                    </template>
                    <v-card width="350px">
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" class="px-0 py-0">
                              Antagonismo
                              <v-divider></v-divider>
                            </v-col>
                            <v-col cols="12">
                              <v-autocomplete
                                label="Confrontación VS"
                                :items="microorganisms"
                                hide-details
                                chips
                                v-model="identification"
                                :search-input.sync="searchMo"
                                :loading="isLoading"
                                @keydown="changeIdentificationValue"
                                item-text="name"
                                clearable
                                hide-no-data
                                return-object
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                              <v-select
                                label="Biocontrol"
                                :items="biocontrols"
                                :clearable="true"
                                hide-details
                                chips
                                v-model="bioControl"
                              ></v-select>
                            </v-col>
                            <v-col cols="12" class="px-0 py-0">
                              Nutrición
                              <v-divider></v-divider>
                            </v-col>
                            <v-col cols="12">
                              <v-select
                                label="Solubilización de potasio"
                                :items="solubilizations"
                                :clearable="true"
                                hide-details
                                chips
                                item-text="text"
                                return-object
                                v-model="solubilizationK"
                              ></v-select>
                            </v-col>
                            <v-col cols="12">
                              <v-select
                                label="Solubilización de fósforo"
                                :items="solubilizations"
                                :clearable="true"
                                hide-details
                                chips
                                item-text="text"
                                return-object
                                v-model="solubilizationP"
                              ></v-select>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" @click="clearFilter"
                          >Restaurar</v-btn
                        >
                        <v-btn color="primary" @click="applyFilter"
                          >Aplicar</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-data-table
                    :headers="headers"
                    :items="preserved_strains"
                    :page.sync="page"
                    :items-per-page="10"
                    :loading="loadTable"
                    hide-default-footer
                    fixed-header
                    height="56vh"
                    @click:row="rowSelect"
                    :item-class="row_classes"
                  >
                    <template #[`item.preservedDate`]="{ item }">
                      <span>{{ dateFormat(item.preservedDate) }} </span>
                    </template>
                    <template #[`item.identification`]="{ item }">
                      <span>{{ item.identification.name }} </span>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col cols="12">
                  <v-pagination
                    v-model="page"
                    :length="numPages"
                    total-visible="10"
                    color="primary"
                  ></v-pagination>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// vuex
import { mapActions, mapMutations } from "vuex";
//Libraries
import dayjs from "dayjs";
// components
import DetailPreservedWorking from "@/components/ceparium/DetailPreservedWorking.vue";
export default {
  components: {
    DetailPreservedWorking,
  },
  data: () => ({
    menu: null,
    solubilizationP: null, //phosphorus
    solubilizationK: null, //potasium
    bioControl: null,
    identification: null,
    searchMo: null,
    isLoading: false,
    closeOnClick: false,
    biocontrols: ["Muy malo", "Malo", "Deficiente", "Bueno", "Muy Bueno"],
    solubilizations: [
      {
        text: "Mala",
        value: "Low",
      },
      {
        text: "Media",
        value: "Medium",
      },
      {
        text: "Buena",
        value: "Good",
      },
      {
        text: "Muy buena",
        value: "Very good",
      },
    ],
    microorganisms: [],
    page: 1,
    numPages: 1,
    search: null,
    filter: null,
    loadTable: false,
    tab: "#reseed",
    headers: [
      {
        text: "ID. Cepa",
        sortable: false,
        value: "strainId",
      },
      { text: "Folio", value: "folio", sortable: false },
      {
        text: "Fecha de preservación",
        value: "preservedDate",
        sortable: false,
      },
      { text: "Organismo", value: "identification", sortable: false },
      { text: "Número de cepa", value: "strainNumber", sortable: false },
      { text: "# Tubos", value: "tubesQuantityAvailable", sortable: false },
    ],
    preserved_strains: [],
    strainPreservedSelected: null,
  }),
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("ceparium", ["getPrserved"]),
    ...mapActions("identification", ["searchIdentifications"]),
    changeIdentificationValue() {
      if (!this.searchMo) {
        this.identification = null;
      }
    },
    dateFormat(value) {
      const date = `${dayjs(value).format("DD/MM/YYYY - HH:mm")} hrs`;
      return date || "--/--/----";
    },
    rowSelect(rowValue) {
      this.preserved_strains = this.preserved_strains.map((data) => {
        if (rowValue._id == data._id) {
          data.clicked = true;
        } else {
          data.clicked = false;
        }
        return data;
      });
      this.strainPreservedSelected = this.mapSelection(rowValue);
    },
    row_classes(value) {
      if (value.clicked) {
        return "custom-highlight-row";
      }
    },
    closeDetail() {
      this.strainPreservedSelected = null;
      this.preserved_strains = this.preserved_strains.map((data) => {
        data.clicked = false;
        return data;
      });
    },
    mapSelection(preserved) {
      return {
        _id: preserved.isolate._id,
        folioAnalysis: preserved.folio,
        idTest: "NA",
        medium: "NA",
        inoculum: preserved.identification.name,
        sampleType: "",
        businessName: "",
        analysisType: "Resiembra",
        status: preserved.isolate.ceparium_data.status,
        mbTestData: null,
        molTestData: null,
        isolate: preserved.isolate,
        strain: preserved.isolate.strain,
        laboratory: null,
        laboratory_id: null,
      };
    },
    async applyFilter() {
      this.filter = {};
      if (this.identification) {
        this.filter.control = this.identification._id;
      }
      if (this.bioControl) {
        this.filter.bioControl = this.bioControl;
      }
      if (this.solubilizationP) {
        this.filter.solubilizationP = this.solubilizationP.value;
      }
      if (this.solubilizationK) {
        this.filter.solubilizationK = this.solubilizationK.value;
      }
      this.menu = false;
      this.strainPreservedSelected = null;
      await this.fetchData();
    },
    async clearFilter() {
      this.identification = null;
      this.bioControl = null;
      this.solubilizationP = null;
      this.solubilizationK = null;
      this.menu = false;
      this.filter = null;
      this.strainPreservedSelected = null;
      await this.fetchData();
    },
    async fetchData() {
      try {
        this.loadTable = true;
        let payload = {
          page: this.page,
          filter: this.filter,
          search: this.search,
          type: "WORK",
        };
        let response = await this.getPrserved(payload);
        if (!response) throw Error(response);
        this.preserved_strains = response.preserved;
        this.numPages = response.pages;
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Error al buscar cargar cepas preservadas",
          timeout: 4000,
        });
      }
      this.loadTable = false;
    },
  },
  async mounted() {
    await this.fetchData();
  },
  watch: {
    async page() {
      await this.fetchData();
    },
    async search() {
      this.strainPreservedSelected = null;
      this.page = 1;
      this.numPages = 1;
      await this.fetchData();
    },
    async searchMo(value) {
      if (value) {
        this.isLoading = true;
        try {
          const response = await this.searchIdentifications({
            name: value,
          });
          if (response.error) throw response;
          this.microorganisms = response.IDENTIFICATIONS;
        } catch (e) {
          console.log(e);
        }
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-highlight-row {
  background-color: rgba(4, 193, 5, 0.13);
  font-weight: bold;
}
</style>
