import Vue from "vue";
import axios from "axios";
// import VueAxios from "vue-axios";
import store from "../store/index";

//Env var
const baseURL = process.env.VUE_APP_API_ENDPOINT_PRODUCTION;

axios.defaults.baseURL = baseURL;
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.headers['Authorization'] = store.getters["session/token"];
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {
  const responseStatusCode = error.response.status
  // const requestURL = error.response.request.responseURL
  if(responseStatusCode == 401){
    // const responseData = error.response
    // store.commit("alerts/SET_ALERT_TYPE", {visible: true, type: "error", text: "La sesión ha caducado, por favor vuelve a iniciar sesión.", timeout: 8000})

    store.commit("session/SET_AUTHENTICATED_STATUS", false)

    // console.log(requestURL, responseData)
    // console.log({...error})
  }else{
    // Do something with response error
    return Promise.reject(error);
  }
});

Vue.use(axios);
