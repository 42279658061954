export function SET_REPLICAS(state, replicas) {
    state.replicas = [...replicas];
}

export function SET_REPLICAS_SAMPLES(state, samples) {
    state.replicasSamples = [...samples];
}

export function SET_SAMPLES_PER_REPLICAS(state, samples) {
    state.samplesPerReplicas = samples;
}