import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify,{
  directives:{
    Ripple
  }
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "04C105",
        accent: "1C4220",
        secondary: "027223",
        info: "FBC02D",
        warning: "FF8F1C",
        error: "CB3234",
        success: "05C105",
        gray: "616161",
        blue:"05A1E9"
      },
    },
  },
});
