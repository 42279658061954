<template>
  <v-container fluid fill-height class="px-0">
    <v-row>
      <v-col cols="12" xl="4" lg="4" md="6" class="d-flex flex-column">
        <EditProfit
          v-if="currentProfitVersion"
          :profit="currentProfitVersion"
          @month_selected="changeMonth"
          @version_change="changeVersion"
        />
      </v-col>
      <v-col cols="12" xl="4" lg="4" md="6" class="d-flex flex-column">
        <ProfitDose
          v-if="schedule && months"
          :schedule="schedule"
          :months="months"
        />
      </v-col>
      <v-col cols="12" xl="4" lg="4" class="d-flex flex-column">
        <ProfitMO v-if="currentProfit" :profitId="currentProfit"/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
//components
import EditProfit from "@/components/Profit/EditProfit";
import ProfitDose from "@/components/Profit/ProfitDose";
import ProfitMO from "@/components/Profit/ProfitMO";
//Vuex
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    EditProfit,
    ProfitDose,
    ProfitMO,
  },
  data: () => ({
    currentProfitVersion: null,
    currentProfit: null,
    schedule: null,
    months: null,
    bestStrain: null,
    pdf: null,
  }),
  async created() {
    try {
      let profitId = this.$route.params.id;
      let response = await this.getProfitVersion(profitId);

      if (response) {
        if (!response.error) {
          this.currentProfitVersion = response.profit;
          this.SET_PROFIT_VERSION(this.currentProfitVersion);
          // this.SET_SUGGESTIONS(response.profit.productsSugestions);
          this.SET_WEEKS_NOT_AVAILABLE(response.profit.weeksNotAvailable);
          // this.schedule = response.profit.products.schedule;
          // this.months = response.profit.products.months;
        } else {
          console.log(e);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: "Algo salio mal al cargar rentabilización",
            timeout: 5000,
          });
        }
      } else {
        console.log(e);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Algo salio mal al cargar rentabilización",
          timeout: 5000,
        });
      }
    } catch (e) {
      console.log(e);
      this.SET_ALERT_TYPE({
        visible: true,
        type: "error",
        text: "Algo salio mal al cargar rentabilización",
        timeout: 5000,
      });
    }
  },
  methods: {
    ...mapActions("profit", ["getProfit", "getProfitVersion"]),
    ...mapMutations("profit", [
      "CLEAN_STRAINS",
      "CLEAN_STRAINS_SELECTED",
      "CLEAN_DOSE_SCHEDULE",
      "SET_PROFIT_VERSION",
      "SET_SUGGESTIONS",
      "SET_WEEKS_NOT_AVAILABLE",
    ]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    changeMonth(value) {
      if (value) {
        let arrayAux = value.split(" ");
        let number = parseInt(arrayAux[0]);
        this.months = number;
      } else {
        this.months = null;
      }
    },
    changeVersion(value) {
      this.CLEAN_STRAINS();
      this.CLEAN_STRAINS_SELECTED();
      this.CLEAN_DOSE_SCHEDULE();
      if (value) {
        this.schedule = value.products.schedule;
        this.months = value.products.months;
        this.currentProfit = value._id;
        this.pdf = value.urlPDF;
      } else {
        this.schedule = null;
        this.months = null;
        this.currentProfit = null;
        this.pdf = null;
      }
    },
  },
  beforeDestroy() {
    this.CLEAN_STRAINS();
    this.CLEAN_STRAINS_SELECTED();
    this.CLEAN_DOSE_SCHEDULE();
  },
};
</script>