<template>
  <v-dialog v-model="dialog" transition="dialog-top-transition" max-width="80%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" small depressed block v-bind="attrs" v-on="on"
        ><v-icon>mdi-file-document-outline</v-icon></v-btn
      >
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar color="primary" dark
          >Reportes de resultados de capital biológico - (Diagnóstico rápido de
          Capital Biológico)</v-toolbar
        >
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-row align="center" justify="center">
                  <v-col cols="12" class="py-1">
                    <b><p class="text-h6 mb-0">Análisis con reporte qPCR</p></b>
                  </v-col>
                  <v-col cols="6" class="py-1">
                    <v-text-field
                      v-model="pagination1.search"
                      class="centered-input"
                      append-icon="mdi-magnify"
                      label="Búsqueda"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-1">
                    <v-data-table
                      hide-default-footer
                      dense
                      :loading="loading1"
                      :headers="headers"
                      :items="items1"
                      :items-per-page="8"
                      class="elevation-1"
                      no-data-text="No hay analisis disponibles"
                    >
                      <template v-slot:[`item.report`]="{ item }">
                        <v-btn color="primary" icon small @click="showReport(item.report)"
                          ><v-icon>mdi-file-eye</v-icon></v-btn
                        >
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col cols="12" class="pb-0">
                    <v-pagination
                      @input="getData1"
                      v-model="pagination1.page"
                      total-visible="4"
                      :length="length1"
                      color="primary"
                    ></v-pagination>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="6">
                <v-row align="center" justify="center">
                  <v-col cols="12" class="py-1">
                    <b
                      ><p class="text-h6 mb-0">
                        Análisis con reporte qPCR por generar
                      </p></b
                    >
                  </v-col>
                  <v-col cols="6" class="py-1">
                    <v-text-field
                      v-model="pagination2.search"
                      class="centered-input"
                      append-icon="mdi-magnify"
                      label="Búsqueda"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-1">
                    <v-data-table
                      hide-default-footer
                      dense
                      :loading="loading2"
                      :headers="headers2"
                      :items="items2"
                      :items-per-page="8"
                      class="elevation-1"
                      no-data-text="No hay analisis disponibles"
                    >
                      <template v-slot:[`item.folio`]="{ item }">
                        <b>{{ item.folio }}</b>
                      </template>
                      <template v-slot:[`item.report`]="{ item }">
                        <v-btn color="primary" icon small @click="generateNewReport(item._id, true)"
                          ><v-icon>mdi-file-plus</v-icon></v-btn
                        >
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col cols="12" class="pb-0">
                    <v-pagination
                      @input="getData2"
                      v-model="pagination2.page"
                      total-visible="4"
                      :length="length2"
                      color="primary"
                    ></v-pagination>
                  </v-col>
                </v-row>
              </v-col>
              <v-dialog v-model="dialogT" width="80%" scrollable>
                <v-card>
                  <v-card-text class="px-0 py-0">
                      <vue-pdf-app style="height: 80vh" :pdf="urlPreview"></vue-pdf-app>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn 
                      text 
                      color="warning" 
                      @click="dialogT = false" 
                      :disabled="creatingNewReport"
                    >
                      Cerrar
                    </v-btn>
                    <v-btn v-if="isTempReport || creatingNewReport" 
                      text 
                      color="success" 
                      :loading="creatingNewReport" 
                      @click="generateNewReport(analysisReport, false)"
                    >
                      Generar reporte final
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialog.value = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import VuePdfApp from "vue-pdf-app";
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css";
import axios from "axios";

export default {
  components: {
    VuePdfApp,
  },
  data() {
    return {
      loading1: false,
      loading2: false,
      dialog: false,
      headers: [
        { text: "Folio de análisis", value: "folio", sortable: false },
        { text: "Razón social", value: "businessName", sortable: false },
        { text: "# Iden", value: "identifications", sortable: false },
        { text: "# No Iden", value: "notIdentifications", sortable: false },
        { text: "Reporte", value: "report", sortable: false },
      ],
      headers2: [
        { text: "Folio de análisis", value: "folio", sortable: false },
        { text: "Razón social", value: "businessName", sortable: false },
        { text: "# Iden", value: "identifications", sortable: false },
        { text: "# No Iden", value: "notIdentifications", sortable: false },
        { text: "Generar reporte", value: "report", sortable: false },
      ],
      items1: [],
      totalData1: 0,
      items2: [],
      totalData2: 0,
      desserts: [],
      pagination1: {
        page: 1,
        limit: 6,
        search: "",
      },
      pagination2: {
        page: 1,
        limit: 6,
        search: "",
      },
      urlPreview : '',
      dialogT : false,
      isTempReport : false,
      creatingNewReport : false,
      analysisReport : null
    };
  },
  computed: {
    length1() {
      return Math.ceil(this.totalData1 / this.pagination1.limit);
    },
    length2() {
      return Math.ceil(this.totalData2 / this.pagination2.limit);
    },
    ...mapState(["cancelToken"]),
  },
  methods: {
    ...mapActions("molecularIdentification", [
      "getAnalysisWithReport",
      "getAnalysisWithoutReport",
      "generateReport"
    ]),
    ...mapMutations(["SET_CANCEL_TOKEN"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async getData() {
      try {
        this.loading1 = true;
        this.loading2 = true;

        await this.getData1();
        await this.getData2();
      } catch (error) {}
    },
    async getData1() {
      try {
        this.loading1 = true;
        let request1 = await this.getAnalysisWithReport(this.pagination1);
        if (request1.error) throw request1;

        this.items1 = this.formatAnalysis(request1.ANALYSIS);
        this.totalData1 = request1.ANALYSIS_COUNT;
        this.loading1 = false;
      } catch (error) {}
    },
    async getData2() {
      try {
        this.loading2 = true;
        let request2 = await this.getAnalysisWithoutReport(this.pagination2);
        if (request2.error) throw request2;

        this.items2 = this.formatAnalysis(request2.ANALYSIS);
        this.totalData2 = request2.ANALYSIS_COUNT;
        this.loading2 = false;
      } catch (error) {}
    },
    formatAnalysis(analyses) {
      return analyses.map((analysis) => {
        let strainsDetected = 0;
        let strainsNotDetected = 0;

        for (const molTest of analysis.molecularTests) {
          strainsDetected = strainsDetected + molTest.mosDetected;
          strainsNotDetected = strainsNotDetected + molTest.mosNotDetected;
        }

        return {
          _id: analysis._id,
          folio: analysis.folio,
          businessName: analysis.client.companyName,
          identifications: strainsDetected,
          notIdentifications: strainsNotDetected,
          report: analysis.urlMolecular ? analysis.urlMolecular : "-",
        };
      });
    },
    showReport(reportUrl){
      this.isTempReport = false
      this.urlPreview = reportUrl
      this.dialogT = true
    },
    async generateNewReport(analysisId, isTemp){
      try {

        if(isTemp){
          this.isTempReport   = true
          this.loading2       = true
          this.creatingNewReport = false
          this.analysisReport = analysisId
        }else{
          this.creatingNewReport  = true
          this.isTempReport       = false
          this.analysisReport     = null
        }
        
        let request = await this.generateReport({ analysis : analysisId, temp : isTemp })
        if(request.error) throw request

        if(isTemp){
          this.urlPreview = request.url
          this.dialogT = true
          this.loading2 = false
        }else{

          this.creatingNewReport  = false
          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: "Reporte generado correctamente.",
            timeout: 4000,
          });

          this.dialogT = false
          await this.getData()
        }

      } catch (error) {
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Error al generar reporte. Comunícate con soporte técnico.",
          timeout: 6000,
        });
      }
    }
  },
  watch: {
    async dialog(value) {
      if (value) {
        await this.getData();
      }
    },
    async "pagination1.search"() {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }

      //Save the cancel token for the current request
      this.SET_CANCEL_TOKEN(axios.CancelToken.source());
      await this.getData1();
    },
    async "pagination2.search"() {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }

      //Save the cancel token for the current request
      this.SET_CANCEL_TOKEN(axios.CancelToken.source());
      await this.getData2();
    },
  },
};
</script>

<style>
</style>