<template>
  <v-container>
    <v-row>
      <v-col class="d-flex flex-column">
        <v-card class="rounded-xl flex d-flex flex-column" elevation="5">
          <v-card-text>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="12" xl="4" lg="4" md="4">
                    <div v-if="!isLoading">
                      <span class="text-subtitle-1 font-weight-black">{{
                        companyName
                      }}</span>
                      <v-spacer></v-spacer>
                      <span class="text-body-2 font-weight-black">{{
                        name
                      }}</span>
                      <v-spacer></v-spacer>
                      <span class="text-subtitle-2 font-weight-bold">{{
                        areaName
                      }}</span>
                      <v-spacer></v-spacer>
                      <span class="mr-5 text-subtitle-2 font-weight-medium"
                        >Cultivo: {{ crop }}</span
                      ><span v-if="variety.length > 0">({{ variety }})</span>
                      <v-dialog
                        transition="dialog-bottom-transition"
                        max-width="600"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="primary" v-bind="attrs" v-on="on"
                            >mdi-information-outline</v-icon
                          >
                        </template>
                        <template v-slot:default="dialog">
                          <v-card>
                            <v-toolbar color="primary" dark
                              >Descripción de la muestra</v-toolbar
                            >
                            <v-card-text>
                              <div
                                class="text-body-1 pt-12"
                                v-if="description.length"
                              >
                                {{ description }}
                              </div>
                              <div class="text-body-1 pt-12" v-else>
                                Sin descripción
                              </div>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                              <v-btn text @click="dialog.value = false"
                                >Close</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-dialog>
                    </div>
                    <div v-else>
                      <v-skeleton-loader
                        type="list-item-three-line"
                      ></v-skeleton-loader>
                    </div>
                  </v-col>
                  <!-- <v-col cols="1"> <v-divider vertical></v-divider></v-col> -->
                  <v-col cols="12" xl="4" lg="4" md="4">
                    <div v-if="!isLoading">
                      <v-combobox
                        return-object
                        v-model="sampleSelected"
                        outlined
                        dense
                        @change="setSample(sampleSelected)"
                        hide-details
                        :items="samples"
                        item-text="alias"
                      ></v-combobox>
                      <v-spacer></v-spacer>
                      <span class="text-subtitle-2 font-weight-medium">{{
                        sampleType
                      }}</span>
                      <v-spacer v-if="areaType.length > 0"></v-spacer>
                      <span v-if="areaType.length > 0">
                        {{ areaType }}
                      </span>
                      <v-spacer></v-spacer>
                      <span class="mr-5 text-subtitle-2 font-weight-medium"
                        >Submuestras: {{ subSamplesQTY }}/{{ target }}</span
                      >
                      <v-icon color="primary" @click="showAllSubSamples"
                        >mdi-eye</v-icon
                      >
                    </div>
                    <div v-else>
                      <v-skeleton-loader
                        type="list-item-three-line"
                      ></v-skeleton-loader>
                    </div>
                  </v-col>
                  <v-col cols="12" xl="4" lg="4" md="4">
                    <div v-if="!isLoading">
                      <span class="text-subtitle-2 font-weight-bold"
                        >Código QR:
                      </span>
                      <span
                        class="text-subtitle-2 font-weight-medium font-italic"
                        >{{ code }}</span
                      >
                      <v-spacer></v-spacer>
                      <span class="text-subtitle-2 font-weight-bold"
                        >Responsable:
                      </span>
                      <span
                        class="text-subtitle-2 font-weight-medium font-italic"
                        >{{ sampler }}</span
                      >
                      <v-spacer></v-spacer>
                      <span class="text-subtitle-2 font-weight-bold"
                        >Fecha de toma:
                      </span>
                      <span
                        class="text-subtitle-2 font-weight-medium font-italic"
                        >{{ endDate }}</span
                      >
                      <v-spacer></v-spacer>
                      <span class="text-subtitle-2 font-weight-bold"
                        >Fecha de recepción:
                      </span>
                      <span
                        class="text-subtitle-2 font-weight-medium font-italic"
                        >{{ receptionDate }}</span
                      >
                      <v-spacer></v-spacer>
                    </div>
                    <div v-else>
                      <v-skeleton-loader
                        type="list-item-three-line"
                      ></v-skeleton-loader>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-if="
                  soilSample &&
                  (soilSample.soilArea.polygon.length > 0 ||
                    soilSample.soilArea.location)
                "
              >
                <MapSample :soilSample="soilSample" @markerTap="markerTaped" />
              </v-col>
              <v-col v-else>
                <v-card
                  style="height: 60vh; width: 100%; background-color: #aaabac"
                >
                  <v-card-text>
                    <v-row class="text-center">
                      <v-col>
                        <span class="font-weight-bold"
                          >Polígono no disponible</span
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" xl="5" lg="5" class="d-flex flex-column">
        <v-card
          class="rounded-xl flex d-flex flex-column"
          elevation="5"
          height="690"
        >
          <v-card-text class="overflow-y-auto ma-2">
            <v-row justify="center" v-if="isInitial">
              <v-col>
                <v-btn
                  color="primary"
                  block
                  :outlined="!isMedition"
                  @click="showMedition"
                >
                  Medición
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  color="primary"
                  block
                  :outlined="!isValuation"
                  @click="showValuation"
                >
                  Valuación
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="text-center mt-2" v-if="isMedition">
              <v-col>
                <div v-if="!isLoading && !isLoadingMedition">
                  <v-row class="text-start">
                    <v-col>
                      <span
                        class="primary--text font-weight-bold text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 text-lg-subtitle-1 text-xl-subtitle-1"
                        >Parámetros de salud de suelo</span
                      >
                      <v-btn
                        dark
                        color="primary"
                        class="ml-7"
                        rounded
                        v-if="showLoad"
                        @click="setSample(sampleSelected)"
                      >
                        <v-icon>mdi-refresh</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="measureSelected && measureSelected.pCTests"
                    justify="center"
                    class="text-center"
                  >
                    <v-col cols="12" v-if="measureSelected.pCTests.ph">
                      <span class="primary--text">pH:</span>
                      <span class="black--text ml-2">{{
                        measureSelected.pCTests.ph
                      }}</span>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="measureSelected.pCTests.organicMaterial"
                    >
                      <span class="primary--text">% materia orgánica:</span>
                      <span class="black--text ml-2">{{
                        measureSelected.pCTests.organicMaterial
                      }}</span>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="measureSelected.pCTests.labileCarbon"
                    >
                      <span class="primary--text"
                        >Captura de carbono (POX):</span
                      >
                      <span class="black--text ml-2">{{
                        measureSelected.pCTests.labileCarbon
                      }}</span>
                    </v-col>
                    <v-col cols="12" v-if="measureSelected.pCTests.texture">
                      <span class="primary--text">Textura de suelo:</span>
                      <span class="black--text ml-2">{{
                        measureSelected.pCTests.texture
                      }}</span>
                    </v-col>
                  </v-row>
                  <v-row v-else justify="center" class="text-center">
                    <v-col cols="12">
                      <span>No hay parámetros de salud del suelo</span>
                    </v-col>
                  </v-row>
                  <v-row class="text-start">
                    <v-col>
                      <span
                        class="primary--text font-weight-bold text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 text-lg-subtitle-1 text-xl-subtitle-1"
                        >Identificación de microorganismos</span
                      >
                    </v-col>
                  </v-row>
                  <div v-if="hasMolecularTest">
                    <v-row class="text-start">
                      <v-col cols="6" class="d-flex flex-column">
                        <span class="primary--text font-weight-bold"
                          >Benéficos</span
                        >
                      </v-col>
                      <v-col cols="3" class="text-center">
                        <span class="primary--text font-weight-bold">UMC</span>
                      </v-col>
                      <v-col cols="3" class="text-center">
                        <span class="primary--text font-weight-bold">CQ</span>
                      </v-col>
                    </v-row>
                    <div
                      v-if="
                        measureSelected &&
                        measureSelected.microbiological.beneficials.length > 0
                      "
                    >
                      <v-row
                        v-for="(item, index) of measureSelected.microbiological
                          .beneficials"
                        :key="index + item.name"
                        class="mt-2"
                      >
                        <v-col cols="6" class="py-1">
                          <span
                            class="black--text font-weight-bold font-italic"
                            >{{ item.name }}</span
                          >
                        </v-col>
                        <v-col cols="3" class="py-1">
                          <v-row no-gutters align="end">
                            <v-col>
                              <span class="font-weight-bold">{{
                                item.umc
                              }}</span></v-col
                            >
                          </v-row>
                        </v-col>
                        <v-col cols="3" class="py-1">
                          <v-row no-gutters align="end">
                            <v-col>
                              <span class="font-weight-bold">{{
                                item.cq
                              }}</span></v-col
                            >
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-else>
                      <v-row>
                        <v-col class="text-center">
                          <span class="font-weight-bold">No hay benéficos</span>
                        </v-col>
                      </v-row>
                    </div>

                    <v-row class="text-start">
                      <v-col cols="6" class="d-flex flex-column">
                        <span class="primary--text font-weight-bold"
                          >Patógenos</span
                        >
                      </v-col>
                      <v-col cols="3" class="text-center">
                        <span class="primary--text font-weight-bold">UMC</span>
                      </v-col>
                      <v-col cols="3" class="text-center">
                        <span class="primary--text font-weight-bold">CQ</span>
                      </v-col>
                    </v-row>
                    <div
                      v-if="
                        measureSelected &&
                        measureSelected.microbiological.threats.length > 0
                      "
                    >
                      <v-row
                        v-for="(item, index) of measureSelected.microbiological
                          .threats"
                        :key="index + item.name"
                        class="mt-2"
                      >
                        <v-col cols="6" class="py-1">
                          <span
                            class="info--text font-weight-bold font-italic"
                            >{{ item.name }}</span
                          >
                        </v-col>
                        <v-col cols="3" class="py-1">
                          <v-row no-gutters align="end">
                            <v-col>
                              <span class="font-weight-bold">{{
                                item.umc
                              }}</span></v-col
                            >
                          </v-row>
                        </v-col>
                        <v-col cols="3" class="py-1">
                          <v-row no-gutters align="end">
                            <v-col>
                              <span class="font-weight-bold">{{
                                item.cq
                              }}</span></v-col
                            >
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-else>
                      <v-row>
                        <v-col class="text-center">
                          <span class="font-weight-bold">No hay patógenos</span>
                        </v-col>
                      </v-row>
                    </div>

                    <v-row class="text-start">
                      <v-col cols="6" class="d-flex flex-column">
                        <span class="primary--text font-weight-bold"
                          >No detectados</span
                        >
                      </v-col>
                      <v-col cols="3" class="text-center">
                        <span class="primary--text font-weight-bold">UMC</span>
                      </v-col>
                      <v-col cols="3" class="text-center">
                        <span class="primary--text font-weight-bold">CQ</span>
                      </v-col>
                    </v-row>
                    <div
                      v-if="
                        measureSelected &&
                        measureSelected.microbiological.notDetecteds.length > 0
                      "
                    >
                      <v-row
                        v-for="(item, index) of measureSelected.microbiological
                          .notDetecteds"
                        :key="index + item.name"
                        class="mt-2"
                      >
                        <v-col cols="6" class="py-1">
                          <span
                            v-if="item.possibleThreat"
                            class="info--text font-weight-bold font-italic"
                            >{{ item.name }}</span
                          >
                          <span
                            v-else
                            class="black--text font-weight-bold font-italic"
                            >{{ item.name }}</span
                          >
                        </v-col>
                        <v-col cols="3" class="py-1">
                          <v-row no-gutters align="end">
                            <v-col>
                              <span class="font-weight-bold">-</span></v-col
                            >
                          </v-row>
                        </v-col>
                        <v-col cols="3" class="py-1">
                          <v-row no-gutters align="end">
                            <v-col>
                              <span class="font-weight-bold">{{
                                item.cq || "-"
                              }}</span></v-col
                            >
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-else>
                      <v-row>
                        <v-col class="text-center">
                          <span class="font-weight-bold"
                            >No hay no detectados</span
                          >
                        </v-col>
                      </v-row>
                    </div>
                  </div>

                  <div v-else>
                    <v-row class="text-start">
                      <v-col
                        cols="7"
                        xl="8"
                        lg="8"
                        md="8"
                        sm="8"
                        class="d-flex flex-column"
                      >
                        <span class="primary--text font-weight-bold"
                          >Bacterias</span
                        >
                      </v-col>
                      <v-col cols="5" xl="4" lg="4" md="4" sm="4">
                        <span class="primary--text font-weight-bold"
                          >Concentración</span
                        >
                      </v-col>
                    </v-row>
                    <div
                      v-if="
                        measureSelected &&
                        measureSelected.microbiological.bacterias.length > 0
                      "
                    >
                      <v-row
                        v-for="(item, index) of measureSelected.microbiological
                          .bacterias"
                        :key="index + item.name"
                        class="mt-2"
                      >
                        <v-col cols="8" class="py-1">
                          <span
                            v-if="item.possibleThreat"
                            class="info--text font-weight-bold font-italic"
                            >{{ item.name }}</span
                          >
                          <span
                            v-else
                            class="black--text font-weight-bold font-italic"
                            >{{ item.name }}</span
                          >
                        </v-col>

                        <v-col cols="4" class="py-1">
                          <v-row no-gutters align="end">
                            <v-col>
                              <span class="font-weight-bold">{{
                                item.interpretation
                              }}</span></v-col
                            >
                            <v-col>
                              <v-tooltip top color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="primary"
                                    class=""
                                    >mdi-information</v-icon
                                  >
                                </template>
                                <span class="font-weight-bold"
                                  >{{ item.concentration }} UFC/g</span
                                >
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-else>
                      <v-row>
                        <v-col class="text-center">
                          <span class="font-weight-bold">No hay bacterias</span>
                        </v-col>
                      </v-row>
                    </div>

                    <v-row class="text-start">
                      <v-col
                        cols="7"
                        xl="8"
                        lg="8"
                        md="8"
                        sm="8"
                        class="d-flex flex-column"
                      >
                        <span class="primary--text font-weight-bold"
                          >Hongos</span
                        >
                      </v-col>
                      <v-col cols="5" xl="4" lg="4" md="4" sm="4">
                        <span class="primary--text font-weight-bold"
                          >Concentración</span
                        >
                      </v-col>
                    </v-row>
                    <div
                      v-if="
                        measureSelected &&
                        measureSelected.microbiological.fungis.length > 0
                      "
                    >
                      <v-row
                        v-for="(item, index) of measureSelected.microbiological
                          .fungis"
                        :key="index + item.name"
                        class="mt-2"
                      >
                        <v-col cols="8" class="py-1">
                          <span
                            v-if="item.possibleThreat"
                            class="info--text font-weight-bold font-italic"
                            >{{ item.name }}</span
                          >
                          <span
                            v-else
                            class="black--text font-weight-bold font-italic"
                            >{{ item.name }}</span
                          >
                        </v-col>

                        <v-col cols="4" class="py-1">
                          <v-row no-gutters align="end">
                            <v-col>
                              <span class="font-weight-bold">{{
                                item.interpretation
                              }}</span></v-col
                            >
                            <v-col>
                              <v-tooltip top color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="primary"
                                    class=""
                                    >mdi-information</v-icon
                                  >
                                </template>
                                <span class="font-weight-bold"
                                  >{{ item.concentration }} UFC/g</span
                                >
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-else>
                      <v-row>
                        <v-col class="text-center">
                          <span class="font-weight-bold">No hay hongos</span>
                        </v-col>
                      </v-row>
                    </div>

                    <v-row class="text-start">
                      <v-col
                        cols="7"
                        xl="8"
                        lg="8"
                        md="8"
                        sm="8"
                        class="d-flex flex-column"
                      >
                        <span class="primary--text font-weight-bold"
                          >Nematodos</span
                        >
                      </v-col>
                      <v-col cols="5" xl="4" lg="4" md="4" sm="4">
                        <span class="primary--text font-weight-bold"
                          >Concentración</span
                        >
                      </v-col>
                    </v-row>
                    <div
                      v-if="
                        measureSelected &&
                        measureSelected.microbiological.nematodes.length > 0
                      "
                    >
                      <v-row
                        v-for="(item, index) of measureSelected.microbiological
                          .nematodes"
                        :key="index + item.name"
                        class="mt-2"
                      >
                        <v-col cols="8" class="py-1">
                          <span
                            v-if="item.possibleThreat"
                            class="info--text font-weight-bold font-italic"
                            >{{ item.name }}</span
                          >
                          <span
                            v-else
                            class="black--text font-weight-bold font-italic"
                            >{{ item.name }}</span
                          >
                        </v-col>

                        <v-col cols="4" class="py-1">
                          <v-row no-gutters align="end">
                            <v-col>
                              <span class="font-weight-bold">{{
                                item.interpretation
                              }}</span></v-col
                            >
                            <v-col>
                              <v-tooltip top color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="primary"
                                    class=""
                                    >mdi-information</v-icon
                                  >
                                </template>
                                <span class="font-weight-bold"
                                  >{{ item.concentration }} juveniles/100g</span
                                >
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-else>
                      <v-row>
                        <v-col class="text-center">
                          <span class="font-weight-bold">No hay nematodos</span>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <v-skeleton-loader
                    type="list-item,list-item-two-line,list-item-three-line,list-item,list-item,list-item-two-line,list-item-three-line,list-item,list-item,list-item-two-line"
                  ></v-skeleton-loader>
                </div>
              </v-col>
            </v-row>
            <v-row class="text-center mt-2" v-if="isValuation">
              <v-col>
                <div v-if="!isLoadingValuation">
                  <v-tabs v-if="valuation">
                    <v-tab
                      v-if="
                        valuation.regionalized &&
                        valuation.regionalized.length > 0
                      "
                    >
                      Control de enfermedades de suelo
                    </v-tab>
                    <v-tab-item
                      v-if="
                        valuation.regionalized &&
                        valuation.regionalized.length > 0
                      "
                    >
                      <v-container>
                        <v-row
                          v-for="(item, index) of valuation.regionalized"
                          :key="index + item.isolate.name"
                        >
                          <v-col xl="3" lg="4" md="5" sm="5">
                            <span class="accent--text">Control</span>
                            <v-spacer></v-spacer>
                            <span
                              class="accent--text font-weight-bold font-italic"
                              >{{ item.isolate.name }}</span
                            >

                            <v-img
                              :src="item.isolate.img"
                              contain
                              class="ml-auto mr-auto"
                              height="200px"
                              width="200px"
                            >
                            </v-img>
                          </v-col>
                          <v-col xl="8" lg="8" md="6" sm="6">
                            <carousel
                              :perPage="getWidth"
                              :navigationEnabled="true"
                              :navigationClickTargetSize="4"
                              :minSwipeDistance="1"
                              :paginationEnabled="false"
                            >
                              <slide
                                v-for="(control, index) of item.confrontations"
                                :key="index + control.name"
                              >
                                <v-row>
                                  <v-col>
                                    <v-img
                                      :src="control.img"
                                      contain
                                      class="ml-auto mr-auto"
                                      height="200"
                                      width="200"
                                    ></v-img>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-row>
                                      <v-col cols="12" class="pb-0">
                                        <span class="accent--text">{{
                                          item.isolate.name
                                        }}</span>
                                        <span class="black--text">
                                          vs {{ control.name }}</span
                                        >
                                      </v-col>
                                      <v-col cols="12" class="pt-0">
                                        <span class="black--text"
                                          >{{ control.percentage }}% - </span
                                        ><span>{{ control.bioControl }}</span>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </slide>
                            </carousel>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-tab-item>
                    <v-tab v-if="this.valuation.nutritionTests"
                      >Nutrición</v-tab
                    >
                    <v-tab-item v-if="this.valuation.nutritionTests">
                      <v-container>
                        <v-row
                          v-if="
                            this.valuation.nutritionTests.phosphorus &&
                            this.valuation.nutritionTests.phosphorus.length > 0
                          "
                        >
                          <v-col xl="3" lg="4" md="5" sm="5">
                            <span class="accent--text">Control negativo</span>
                            <v-spacer></v-spacer>
                            <span
                              class="accent--text font-weight-bold font-italic"
                            >
                              Solubilización de fósforo (P)</span
                            >

                            <v-img
                              :src="negativeControlP.img"
                              contain
                              class="ml-auto mr-auto"
                              height="200px"
                              width="200px"
                            >
                            </v-img>
                          </v-col>
                          <v-col xl="8" lg="8" md="6" sm="6">
                            <carousel
                              :perPage="getWidth"
                              :navigationEnabled="true"
                              :navigationClickTargetSize="4"
                              :minSwipeDistance="1"
                              :paginationEnabled="false"
                            >
                              <slide
                                v-for="(phosphoru, index) of valuation
                                  .nutritionTests.phosphorus"
                                :key="index + phosphoru.name"
                              >
                                <v-row>
                                  <v-col>
                                    <v-img
                                      :src="phosphoru.img"
                                      contain
                                      class="ml-auto mr-auto"
                                      height="200"
                                      width="200"
                                    ></v-img>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-row>
                                      <v-col cols="12" class="pb-0">
                                        <span class="accent--text"></span>
                                      </v-col>
                                      <v-col cols="12" class="pt-0">
                                        <span class="black--text"
                                          >{{ phosphoru.name }} - </span
                                        ><span>{{
                                          phosphoru.solubilization
                                            ? phosphoru.solubilization
                                            : 0
                                        }}</span>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </slide>
                            </carousel>
                          </v-col>
                        </v-row>

                        <v-row
                          v-if="
                            this.valuation.nutritionTests.potasium &&
                            this.valuation.nutritionTests.potasium.length > 0
                          "
                        >
                          <v-col xl="3" lg="4" md="5" sm="5">
                            <span class="accent--text">Control negativo</span>
                            <v-spacer></v-spacer>
                            <span
                              class="accent--text font-weight-bold font-italic"
                            >
                              Solubilización de potasio (K)</span
                            >

                            <v-img
                              :src="negativeControlF.img"
                              contain
                              class="ml-auto mr-auto"
                              height="200px"
                              width="200px"
                            >
                            </v-img>
                          </v-col>
                          <v-col xl="8" lg="8" md="6" sm="6">
                            <carousel
                              :perPage="getWidth"
                              :navigationEnabled="true"
                              :navigationClickTargetSize="4"
                              :minSwipeDistance="1"
                              :paginationEnabled="false"
                            >
                              <slide
                                v-for="(phosphoru, index) of valuation
                                  .nutritionTests.potasium"
                                :key="index + phosphoru.name"
                              >
                                <v-row>
                                  <v-col>
                                    <v-img
                                      :src="phosphoru.img"
                                      contain
                                      class="ml-auto mr-auto"
                                      height="200"
                                      width="200"
                                    ></v-img>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-row>
                                      <v-col cols="12" class="pb-0">
                                        <span class="accent--text"></span>
                                      </v-col>
                                      <v-col cols="12" class="pt-0">
                                        <span class="black--text"
                                          >{{ phosphoru.name }} - </span
                                        ><span>{{
                                          phosphoru.solubilization
                                            ? phosphoru.solubilization
                                            : 0
                                        }}</span>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </slide>
                            </carousel>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-tab-item>
                    <v-tab
                      v-if="
                        this.valuation.biostimulationTests &&
                        this.valuation.biostimulationTests.siderophores &&
                        this.valuation.biostimulationTests.siderophores.length >
                          0
                      "
                      >Sideróforos</v-tab
                    >
                    <v-tab-item
                      v-if="
                        this.valuation.biostimulationTests &&
                        this.valuation.biostimulationTests.siderophores &&
                        this.valuation.biostimulationTests.siderophores.length >
                          0
                      "
                    >
                      <v-container>
                        <v-row>
                          <v-col xl="3" lg="4" md="5" sm="5">
                            <span class="accent--text">Control negativo</span>
                            <v-spacer></v-spacer>
                            <span
                              class="accent--text font-weight-bold font-italic"
                            >
                              Sideróforos</span
                            >

                            <v-img
                              :src="negativeControlS.img"
                              class="ml-auto mr-auto"
                              contain
                              height="200px"
                              width="200px"
                            >
                            </v-img>
                          </v-col>
                          <v-col xl="8" lg="8" md="6" sm="6">
                            <carousel
                              :perPage="getWidth"
                              :navigationEnabled="true"
                              :navigationClickTargetSize="4"
                              :minSwipeDistance="1"
                              :paginationEnabled="false"
                            >
                              <slide
                                v-for="(siderophore, index) of valuation
                                  .biostimulationTests.siderophores"
                                :key="index + siderophore.name"
                              >
                                <v-row>
                                  <v-col>
                                    <v-img
                                      :src="siderophore.img"
                                      contain
                                      class="ml-auto mr-auto"
                                      height="200"
                                      width="200"
                                    ></v-img>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-row>
                                      <v-col cols="12" class="pb-0">
                                        <span class="accent--text"></span>
                                      </v-col>
                                      <v-col cols="12" class="pt-0">
                                        <span class="black--text"
                                          >{{ siderophore.name }} - </span
                                        ><span>{{
                                          siderophore.solubilization
                                            ? siderophore.solubilization
                                            : 0
                                        }}</span>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </slide>
                            </carousel>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-tab-item>

                    <v-tab
                      v-if="
                        this.valuation.biostimulationTests &&
                        this.valuation.biostimulationTests.cellulolytic &&
                        this.valuation.biostimulationTests.cellulolytic.length >
                          0
                      "
                    >
                      Actividad celulolítica</v-tab
                    >

                    <v-tab-item
                      v-if="
                        this.valuation.biostimulationTests &&
                        this.valuation.biostimulationTests.cellulolytic &&
                        this.valuation.biostimulationTests.cellulolytic.length >
                          0
                      "
                    >
                      <v-container>
                        <v-row>
                          <v-col xl="3" lg="4" md="5" sm="5">
                            <span class="accent--text">Control negativo</span>
                            <v-spacer></v-spacer>
                            <span
                              class="accent--text font-weight-bold font-italic"
                            >
                              Actividad celulolítica</span
                            >

                            <v-img
                              :src="negativeControlAC.img"
                              contain
                              class="ml-auto mr-auto"
                              height="200px"
                              width="200px"
                            >
                            </v-img>
                          </v-col>
                          <v-col xl="8" lg="8" md="6" sm="6">
                            <carousel
                              :perPage="getWidth"
                              :navigationEnabled="true"
                              :navigationClickTargetSize="4"
                              :minSwipeDistance="1"
                              :paginationEnabled="false"
                            >
                              <slide
                                v-for="(cellulolyti, index) of valuation
                                  .biostimulationTests.cellulolytic"
                                :key="index + cellulolyti.name"
                              >
                                <v-row>
                                  <v-col>
                                    <v-img
                                      :src="cellulolyti.img"
                                      contain
                                      class="ml-auto mr-auto"
                                      height="200"
                                      width="200"
                                    ></v-img>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-row>
                                      <v-col cols="12" class="pb-0">
                                        <span class="accent--text">{{
                                          cellulolyti.name
                                        }}</span>
                                      </v-col>
                                      <v-col cols="12" class="pt-0">
                                        <span class="black--text">CM - </span
                                        ><span>{{
                                          cellulolyti.interpretation
                                            ? cellulolyti.interpretation
                                            : 0
                                        }}</span>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </slide>
                            </carousel>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-tab-item>
                    <v-tab
                      v-if="
                        valuation.biostimulationTests &&
                        valuation.biostimulationTests.AIA
                      "
                    >
                      AIA
                    </v-tab>
                    <v-tab-item
                      v-if="
                        valuation.biostimulationTests &&
                        valuation.biostimulationTests.AIA
                      "
                    >
                      <v-container>
                        <v-row
                          class="mb-5 mt-1"
                          v-if="AIAB.length > 0 || AIAF.length > 0"
                        >
                          <v-col v-if="AIAB.length > 0"
                            ><v-btn
                              color="primary"
                              dark
                              small
                              block
                              @click="changeAIA('bacteria')"
                              >Bacterias</v-btn
                            ></v-col
                          >
                          <v-col v-if="AIAF.length > 0">
                            <v-btn
                              color="primary"
                              dark
                              small
                              block
                              @click="changeAIA('fungis')"
                              >Hongos</v-btn
                            >
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <Chart :results="AIA" />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-tab-item>
                  </v-tabs>
                  <v-row v-else justify="center" class="text-center">
                    <v-col cols="12">
                      <span>No hay valuación</span>
                    </v-col>
                  </v-row>
                </div>
                <div v-else>
                  <v-skeleton-loader
                    type="list-item,list-item-two-line,list-item-three-line,list-item,list-item,list-item-two-line,list-item-three-line,list-item,list-item,list-item-two-line"
                  ></v-skeleton-loader>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <SubSamplePhoto
      :img="subSampleData"
      :visible="isSubSampleSelected"
      @hideSubSampleModal="hideSubSampleModal"
    />
  </v-container>
</template>

<script>
//components
import MapSample from "@/components/reception/tracingSample/MapSample";
import SubSamplePhoto from "@/components/station1/modals/SubSamplePhoto";
import { Carousel, Slide } from "vue-carousel";
import Chart from "@/components/reception/tracingSample/chart";
//Vuex
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
//Libraries
import dayjs from "dayjs";
export default {
  components: {
    MapSample,
    SubSamplePhoto,
    Carousel,
    Slide,
    Chart,
  },
  data: () => ({
    isMedition: true,
    isValuation: false,
    sampleSelected: null,
    isLoading: false,
    tabs: null,
    soilSample: null,
    expand2: false,
    samples: [],
    measureSelected: null,
    companyName: "",
    name: "",
    areaName: "",
    crop: "",
    variety: "",
    sampleType: "",
    areaType: "",
    subSamplesQTY: 0,
    target: 0,
    code: "",
    sampler: "",
    endDate: "",
    receptionDate: "",
    description: "",
    isLoadingMedition: false,
    isLoadingValuation: false,
    isSubSampleSelected: false,
    subSampleData: [],
    showLoad: false,
    valuation: null,
    isInitial: false,
    negativeControlP: null,
    negativeControlF: null,
    negativeControlS: null,
    negativeControlAC: null,
    AIA: [],
    AIAF: [],
    AIAB: [],
    hasMolecularTest: false,
  }),
  computed: {
    getWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 1;
          break;
        case "sm":
          return 1;
          break;
        case "md":
          return 1;
          break;
        case "lg":
          return 2;
          break;
        case "xl":
          return 2;
          break;
      }
    },
  },
  methods: {
    ...mapActions("reception", [
      "getSamplesByArea",
      "getMedition",
      "getValuation",
    ]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    markerTaped(value) {},
    async formattSample(samples) {
      for (let sample of samples) {
        if (sample.folio) {
          sample.alias = `${this.getAnalysisType(sample.tracingStatus)} - ${
            sample.folio
          }`;
        } else {
          sample.alias = `${this.getAnalysisType(sample.tracingStatus)}`;
        }
      }
      return samples;
    },
    getAnalysisType(value) {
      let types = {
        INITIAL: "Inicial",
        MONITORING: "Seguimiento",
      };
      return types[value];
    },
    async setSample(sample) {
      let sampleFound = this.samples.find(
        (element) => element._id === sample._id
      );
      this.companyName =
        sampleFound.clientCompanyName.length == 0
          ? "No disponible"
          : sampleFound.clientCompanyName;
      this.name = sampleFound.client;
      this.areaName = sampleFound.soilArea.name;
      this.crop = this.getCrop(sampleFound.crop);
      this.variety = sampleFound.variety;
      this.sampleType = this.getSampleType(sampleFound.sampleType);
      this.areaType = this.getAreaType(sampleFound.soilArea.areaType);
      this.subSamplesQTY = sampleFound.subSamples.length;
      this.target = sampleFound.target.length;
      this.code = sampleFound.container;
      this.sampler = sampleFound.sampler;
      this.endDate = this.dateFormat(sampleFound.sampledDate);
      this.receptionDate = this.dateFormat(sampleFound.sampleReceptionDate);
      this.description = sampleFound.description;
      this.soilSample = sampleFound;
      this.isMedition = true;
      this.isValuation = false;
      if (
        sampleFound.tracingStatus === "INITIAL" ||
        sampleFound.tracingStatus === "Initial"
      ) {
        this.isInitial = true;
      } else {
        this.isInitial = false;
      }
      try {
        this.isLoadingMedition = true;
        let response = await this.getMedition({
          crop: sampleFound.crop,
          container: sampleFound.container,
        });
        if (response) {
          if (!response.error) {
            this.isLoadingMedition = false;
            this.showLoad = false;
            this.hasMolecularTest = response.hasMolecularTest;
            this.measureSelected = response;
          } else {
            throw response;
          }
        } else {
          throw response;
        }
      } catch (e) {
        console.log(e);
        this.isLoadingMedition = false;
        this.showLoad = true;
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Algo salio mal al cargar medición, intente de nuevo !!",
          timeout: 5000,
        });
      }
    },
    getCrop(value) {
      let template = "";
      const crop = {
        olive: "Aceituna",
        chard: "Acelga",
        achiote: "Achiote",
        agave: "Agave",
        avocado: "Aguacate",
        garlic: "Ajo",
        sesame: "Ajonjolí",
        basil: "Albahaca",
        artichoke: "Alcachofa",
        alcatraz: "Alcatraz",
        shrunkenAlfalfa: "Alfalfa achicalada",
        greenAlfalfa: "Alfalfa verde",
        cotton: "Algodón",
        almond: "Almendro",
        birdseed: "Alpiste",
        amaranth: "Amaranto",
        anise: "Anís",
        celery: "Apio",
        blueberry: "Arándano",
        myrtle: "Arrayán",
        rice: "Arroz",
        greenForageOats: "Avena forrajera en verde",
        oatGrain: "Avena grano",
        bamboo: "Bambú",
        eggplant: "Berenjena",
        beetroot: "Betabel",
        broccoli: "Brocoli",
        peanut: "Cacahuate",
        cocoa: "Cacao",
        cherryCoffee: "Café cereza",
        pumpkin: "Calabaza",
        sweetPotato: "Camote",
        sugarCane: "Caña de azúcar",
        canola: "Canola",
        carambolo: "Carambolo",
        safflower: "Cártamo",
        barley: "Cebada",
        greenForageBarley: "Cebada forrajera en verde",
        onion: "Cebolla",
        chives: "Cebollín",
        ryeGrain: "Centeno grano",
        cherry: "Cereza",
        chabacano: "Chabacano",
        pea: "Chícharo",
        squash: "Chayote",
        chia: "Chía",
        chilacayote: "Chilacayote",
        chillyPepper: "Chile / Pimiento",
        custardApple: "Chirimoya",
        coriander: "Cilantro",
        plum: "Ciruela",
        coconutFruit: "Coco fruta",
        cabbage: "Col (repollo)",
        brusselsSprouts: "Col de Bruselas",
        cauliflower: "Coliflor",
        cumin: "Comino",
        chrysanthemum: "Crisantemo (planta)",
        peach: "Durazno",
        date: "Dátil",
        greenBean: "Ejote",
        cornCob: "Elote",
        dill: "Eneldo",
        epazote: "Epazote",
        asparagus: "Espárrago",
        spicesAndMedicinals: "Especias y medicinales",
        spinach: "Espinaca",
        raspberry: "Frambuesa",
        strawberry: "Fresa",
        bean: "Frijol",
        chickpea: "Garbanzo",
        sunflower: "Girasol",
        pomegranate: "Granada",
        soursop: "Guanábana",
        guava: "Guayaba",
        henequen: "Henequén",
        peppermint: "Hierbabuena",
        aromaticHerbs: "Hierbas aromáticas",
        fig: "Higo",
        jamaica: "Jamaica",
        ginger: "Jenjibre",
        jicama: "Jícama",
        lettuce: "Lechuga",
        lentil: "Lenteja",
        lime: "Lima",
        lemon: "Limón",
        linseed: "Linaza",
        litchi: "Litchi",
        corn: "Maíz",
        macadamia: "Macadamia",
        maguey: "Maguey",
        mamey: "Mamey",
        tangerine: "Mandarina",
        mango: "Mango",
        mangosteen: "Mangostán",
        chamomile: "Manzanilla",
        appleTree: "Manzano",
        passionFruit: "Maracuyá",
        daisyFlower: "Margarita",
        marjoram: "Mejorana",
        melon: "Melón",
        quince: "Membrillo",
        mint: "Menta",
        moringa: "Moringa",
        turnip: "Nabo",
        nanche: "Nanche",
        napa: "Napa",
        orange: "Naranja",
        medlar: "Níspero",
        nochebuena: "Nochebuena (planta)",
        walnut: "Nogal",
        noni: "Noni",
        nopal: "Nopal",
        oregano: "Orégano",
        ornamental: "Ornamentales",
        orchid: "Orquídea (planta)",
        oilPalm: "Palma africana o de aceite",
        potato: "Papa",
        papaya: "Papaya",
        grasslands: "Pastizales",
        matGrass: "Pasto tapete (m2)",
        pasturesAndMeadows: "Pastos y praderas",
        cucumber: "Pepino",
        pearTree: "Peral",
        parsley: "Perejil",
        peron: "Perón",
        persimony: "Persimonio",
        pepper: "Pimienta",
        pineapple: "Piña",
        pinion: "Piñón",
        pistache: "Pistache",
        pitaya: "Pitaya",
        banana: "Plátano",
        pore: "Poro",
        radish: "Rábano",
        rambutan: "Rambután",
        romerito: "Romerito",
        rosemary: "Romero",
        aloe: "Sábila",
        watermelon: "Sandía",
        sorghum: "Sorgo",
        soy: "Soya",
        stevia: "Stevia",
        tobacco: "Tabaco",
        tamarind: "Tamarindo",
        tejocote: "Tejocote",
        tomato: "Tomate Rojo (jitomate)",
        greenTomato: "Tomate Verde",
        thyme: "Tomillo",
        grapefruit: "Toronja (pomelo)",
        clover: "Trébol",
        wheat: "Trigo",
        dutchTulip: "Tulipán holandés",
        tuna: "Tuna",
        grape: "Uva",
        vanilla: "Vainilla",
        purslane: "Verdolaga",
        vine: "Vid",
        cassavaFood: "Yuca alimenticia",
        carrot: "Zanahoria",
        sapodilla: "Zapote",
        blackberry: "Zarzamora",
        zempoalxochitl: "Zempoalxochitl",
        fallow: "Barbecho",
        other: "Otro",
      };
      let cropName = crop[value];
      if (cropName) {
        template = cropName;
      } else {
        template = "Sin cultivo";
      }
      return template;
    },
    getSampleType(value) {
      const types = {
        CLASSIC: "Suelo compuesto",
        MAS: "Muestra para genómica",
        MAS_VEGETABLE: "Vegetal",
        VEGETABLE: "Vegetal sin georeferencia",
        COMPOST: "Composta",
        PRODUCT: "Producto",
        SUBSTRATUM: "Sustrato",
        NO_ADVISOR: "Suelo sin asesor",
      };
      if (value.type) {
        let temp = types[value.type];
        if (value.type == "COMPOST" || value.type == "PRODUCT") {
          if (value.composition) {
            let compositions = {
              LIQUID: "Líquido",
              SOLID: "Sólido",
            };
            temp += ` ${compositions[value.composition]}`;
          }
        } else if (value.type == "MAS_VEGETABLE" || value.type == "VEGETABLE") {
          if (value.vegetableType) {
            let vegetableTypes = {
              Seedling: "Plántula",
              Root: "Raíz",
              Seed: "Semilla",
              Stem: "Tallo",
              Leaf: "Hoja",
              Fruit: "Fruto",
              Flower: "Flor",
            };
            if (vegetableTypes[value.vegetableType]) {
              temp += ` ${vegetableTypes[value.vegetableType]}`;
            }
          }
        }
        return temp;
      } else {
        return "N/A";
      }
    },
    getAreaType(value) {
      let areaTypes = {
        SOLENA: "Unidad de riego",
        EXPERIMENTAL: "Unidad experimental",
      };
      return areaTypes[value] ? areaTypes[value] : "";
    },
    dateFormat(value) {
      if (value) {
        const date = `${dayjs(value).format("DD-MM-YYYY - HH:mm")} hrs`;
        return date ? date : "N/A";
      } else {
        return "N/A";
      }
    },
    markerTaped(data) {
      if (typeof data != "undefined") {
        this.isSubSampleSelected = !this.isSubSampleSelected;
        let img = "";
        if (
          data.subSamplePhoto.length < 0 ||
          !data.subSamplePhoto.startsWith("http")
        ) {
          img = require("@/assets/img/no-img-sample.png");
        } else {
          img = data.subSamplePhoto;
        }

        this.subSampleData = [
          {
            title: data.description,
            src: img,
          },
        ];
      }
    },
    showAllSubSamples() {
      this.subSampleData = [];
      if (this.sampleSelected.samplePhotos.length > 0) {
        this.subSampleData.push({
          title: "",
          src: this.sampleSelected.samplePhotos[0].photo,
        });
      }

      for (let subSample of this.sampleSelected.subSamples) {
        let img = "";
        if (subSample.subSamplePhoto) {
          if (
            subSample.subSamplePhoto.length < 0 ||
            !subSample.subSamplePhoto.startsWith("http")
          ) {
            img = require("@/assets/img/no-img-sample.png");
          } else {
            img = subSample.subSamplePhoto;
          }
        } else {
          img = require("@/assets/img/no-img-sample.png");
        }

        this.subSampleData.push({
          title: subSample.description,
          src: img,
        });
      }
      this.isSubSampleSelected = !this.isSubSampleSelected;
    },
    hideSubSampleModal() {
      this.isSubSampleSelected = !this.isSubSampleSelected;
    },
    showMedition() {
      this.isMedition = true;
      this.isValuation = false;
    },
    async showValuation() {
      this.isMedition = false;
      this.isValuation = true;
      try {
        this.isLoadingValuation = true;
        let response = await this.getValuation({
          container: this.sampleSelected.container,
        });
        if (response) {
          if (!response.error) {
            this.isLoadingValuation = false;
            this.valuation = response.results_valuation;
            if (this.valuation) {
              if (this.valuation.nutritionTests) {
                if (
                  this.valuation.nutritionTests.phosphorus &&
                  this.valuation.nutritionTests.phosphorus.length > 0
                ) {
                  this.negativeControlP =
                    this.valuation.nutritionTests.phosphorus[0];
                  this.valuation.nutritionTests.phosphorus.shift();
                }
                if (
                  this.valuation.nutritionTests.potasium &&
                  this.valuation.nutritionTests.potasium.length > 0
                ) {
                  this.negativeControlF =
                    this.valuation.nutritionTests.potasium[0];
                  this.valuation.nutritionTests.potasium.shift();
                }
              }

              if (this.valuation.biostimulationTests) {
                if (this.valuation.biostimulationTests.siderophores) {
                  this.negativeControlS =
                    this.valuation.biostimulationTests.siderophores[0];
                  this.valuation.biostimulationTests.siderophores.shift();
                }
              }
              if (this.valuation.biostimulationTests) {
                if (this.valuation.biostimulationTests.cellulolytic) {
                  this.negativeControlAC =
                    this.valuation.biostimulationTests.cellulolytic[0];
                  this.valuation.biostimulationTests.cellulolytic.shift();
                }
              }

              if (
                this.valuation.biostimulationTests &&
                this.valuation.biostimulationTests.AIA
              ) {
                for (let element of this.valuation.biostimulationTests.AIA) {
                  if (element.mo === "bacteria") {
                    this.AIAB.push(element);
                  } else {
                    this.AIAF.push(element);
                  }
                }
                if (this.AIAB.length > 0) {
                  this.AIA = this.AIAB;
                } else {
                  this.AIA = this.AIAF;
                }
              }
            }
          } else {
            throw response;
          }
        } else {
          throw response;
        }
      } catch (e) {
        console.log(e);
        this.isLoadingValuation = false;
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Algo salio mal al cargar valuación, intente de nuevo !!",
          timeout: 5000,
        });
      }
    },
    changeAIA(value) {
      if (value === "bacteria") {
        this.AIA = this.AIAB;
      } else {
        this.AIA = this.AIAF;
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    try {
      let response = await this.getSamplesByArea(this.$route.params.id);
      if (response) {
        if (!response.error) {
          this.isLoading = false;
          let samplesAlias = await this.formattSample(response.samples);
          this.samples = samplesAlias;
          this.sampleSelected = samplesAlias[0];
          this.setSample(this.sampleSelected);
        } else {
          throw response;
        }
      } else {
        throw response;
      }
    } catch (e) {
      console.log(e);
      this.isLoading = false;
    }
  },
  watch: {},
};
</script>

<style scoped>
.v-tab {
  text-transform: none !important;
}
</style>
