export function SET_PROFIT(state, profit) {
  state.profit_selected = profit;
}

export function SET_SUGGESTIONS(state, suggestions) {
  state.suggestions = suggestions;
}

export function SET_WEEKS_NOT_AVAILABLE(state, weeksNotAvailable) {
  state.weeksNotAvailable = weeksNotAvailable;
}

export function SET_PROFIT_VERSION(state, profit) {
  state.profit_version = profit;
}

export function SET_STRAINS(state, strains) {
  state.strains = strains;
}

export function SET_STRAINS_SELECTED(state, strains) {
  state.strains_selected = strains;
}

export function PUSH_STRAINS_SELECTED(state, strains) {
  state.strains_selected.push(strains);
}

export function REMOVE_STRAINS_SELECTED(state, payload) {
  var index = state.strains_selected.indexOf(payload.value);
  var strain = state.strains_selected[index];
  state.months_data.forEach((month) => {
    month.forEach((week) => {
      week.doses.forEach((dose) => {
        dose.forEach((element) => {
          if (element.strain.strainId == strain.strainId) {
            dose.strain = "";
          }
        });
      });
    });
  });
  if (index != -1) {
    let aux = [];
    aux = state.strains_selected.filter(
      (element) => element.strainId != strain.strainId
    );
    state.strains_selected = aux;
  }
}

export function ADD_DOSE_STRAIN(state, doses) {
  for (let strain of state.strains) {
    let val = doses[strain.strainId];
    if (val) {
      strain.dose = val;
    } else {
      strain.dose = 0;
    }
  }
}

export function SET_DOSE_SCHEDULE(state, months) {
  state.months_data = months;
}

export function CLEAN_STRAINS(state) {
  state.strains = [];
}

export function CLEAN_STRAINS_SELECTED(state) {
  state.strains_selected = [];
}
export function CLEAN_DOSE_SCHEDULE(state) {
  state.months_data = [];
}
export function SET_SUMMARY(state, item) {
  state.summary = item;
}
export function SET_LOAD_PROFIT(state, item) {
  state.loadingProfitPages += item;
}

export function UNSET_LOAD_PROFIT(state, item) {
  state.loadingProfitPages = item;
}
