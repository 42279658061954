<template>
  <v-container fluid fill-height class="px-0">
    <v-row>
      <v-col cols="12" xl="4" lg="4" md="6" sm="12" xs="12">
        <v-card class="my-auto">
          <v-container class="pa-5">
            <v-row class="py-0">
              <v-col
                v-if="sampleSelected != null"
                cols="12"
                md="6"
                sm="8"
                class="font-weight-black text-subtitle-1"
                >Muestra {{ sampleSelected }}</v-col
              >
              <v-col
                v-else
                cols="12"
                md="6"
                sm="8"
                class="font-weight-black text-subtitle-1"
                >Muestra - - - -</v-col
              >
              <v-col
                v-if="sampleStatus.length > 0"
                cols="12"
                md="6"
                sm="4"
                class="font-weight-black"
                >{{ sampleStatus }}</v-col
              >
              <v-col v-else cols="12" md="6" sm="4" class="font-weight-black"
                >- - - -</v-col
              >
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">Cliente:</v-col>
              <v-col
                v-if="clientName.length > 0"
                cols="12"
                class="py-0 font-weight-bold text-subtitle-1"
                >{{ clientName }}</v-col
              >
              <v-col
                v-else
                cols="12"
                class="py-0 font-weight-bold text-subtitle-1"
                >- - - -</v-col
              >
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">Unidad de riego:</v-col>
              <v-col
                v-if="areaName.length > 0"
                cols="12"
                class="py-0 font-weight-bold text-subtitle-1"
                >{{ areaName }}</v-col
              >
              <v-col
                v-else
                cols="12"
                class="py-0 font-weight-bold text-subtitle-1"
                >- - - -</v-col
              >
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">Cultivo:</v-col>
              <v-col
                v-if="cropCycleName.length > 0"
                cols="12"
                class="py-0 font-weight-bold text-subtitle-1 green--text"
                >{{ cropCycleName }}</v-col
              >
              <v-col
                v-else
                cols="12"
                class="py-0 font-weight-bold text-subtitle-1 green--text"
                >- - - -</v-col
              >
            </v-row>
            <v-row>
              <v-col
                sm="12"
                class="pt-4 font-weight-bold text-subtitle-2 text-uppercase"
                >{{ sampleType }} {{ sampleTracingStatus }}</v-col
              >
              <!-- <v-col v-else sm="12" class="pt-4 font-weight-bold text-subtitle-2 text-uppercase">- - - -</v-col> -->
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0 text-subtitle-2 primary--text"
                >Muestreo finalizado</v-col
              >
              <v-col cols="12" class="py-0 text-subtitle-2"
                >Submuestras: {{ subSamples.length }}/{{ areaTargets }}</v-col
              >
            </v-row>
            <v-row>
              <v-col
                v-if="qrCode.length > 0"
                cols="12"
                class="py-0 text-subtitle-2"
              >
                Código QR: {{ qrCode }}
                <!-- <v-btn fab x-small text>
                                <v-icon>
                                    mdi-content-copy
                                </v-icon>
                            </v-btn> -->
              </v-col>
              <v-col v-else cols="12" class="py-0 text-subtitle-2">
                Código QR: - - - -
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0 text-subtitle-2">
                Responsable: {{ samplerName }}
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-if="samplingDate != null"
                cols="12"
                class="py-0 text-subtitle-2"
              >
                Fecha de toma: {{ dateFormat(samplingDate) }}
              </v-col>
              <v-col v-else cols="12" class="py-0 text-subtitle-2">
                Fecha de toma: - - - -
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-if="receptionDate != null"
                cols="12"
                class="py-0 text-subtitle-2"
              >
                Fecha de recepción: {{ dateFormat(receptionDate) }}
              </v-col>
              <v-col v-else cols="12" class="py-0 text-subtitle-2">
                Fecha de recepción: - - - -
              </v-col>
            </v-row>
            <v-row v-if="cropCycle">
              <v-col>
                <v-expansion-panels focusable>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      >Manejo agronómico</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <v-container fluid>
                        <v-row>
                          <v-col class="pb-1 pt-3">
                            <span
                              class="font-weight-bold text-body-2 primary--text"
                              >Cultivo anterior:
                            </span>
                            <span class="text-body-2">{{
                              getCrop(cropCycle.crop.lastCrop)
                            }}</span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="py-1">
                            <span
                              class="font-weight-bold text-body-2 primary--text"
                              >Variedad anterior:
                            </span>
                            <span
                              class="text-body-2"
                              v-if="cropCycle.crop.lastVariety && cropCycle.crop.lastVariety.length > 0"
                              >{{ cropCycle.crop.lastVariety }}</span
                            >
                            <span class="text-body-2" v-else>
                              Sin variedad
                            </span>
                          </v-col>
                        </v-row>
                        <v-row class="text-center">
                          <v-col class="pb-0">
                            <span class="font-weight-bold text-subtitle-1">
                              Problemas detectados
                            </span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="py-0">
                            <v-list>
                              <v-list-item>
                                <v-list-item-icon>
                                  <v-icon
                                    :color="
                                      cropCycle.crop.detectedIssues &&
                                      cropCycle.crop.detectedIssues.isFungi
                                        ? 'primary'
                                        : ''
                                    "
                                  >
                                    mdi-mushroom
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    Hongos
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-icon>
                                  <v-icon
                                    :color="
                                      cropCycle.crop.detectedIssues &&
                                      cropCycle.crop.detectedIssues.isBacteria
                                        ? 'primary'
                                        : ''
                                    "
                                  >
                                    mdi-bacteria
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    Bacterias
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-icon>
                                  <v-icon
                                    :color="
                                      cropCycle.crop.detectedIssues &&
                                      cropCycle.crop.detectedIssues.isNematode
                                        ? 'primary'
                                        : ''
                                    "
                                  >
                                    mdi-abugida-devanagari
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    Nemátodo
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-icon>
                                  <v-icon
                                    :color="
                                      cropCycle.crop.detectedIssues &&
                                      cropCycle.crop.detectedIssues.isNutrition
                                        ? 'primary'
                                        : ''
                                    "
                                  >
                                    mdi-nutrition
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    Nutición
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-icon>
                                  <v-icon
                                    :color="
                                      cropCycle.crop.detectedIssues &&
                                      cropCycle.crop.detectedIssues.isOther
                                        ? 'primary'
                                        : ''
                                    "
                                  >
                                    mdi-liquid-spot
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title> Otro </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-col>
                        </v-row>
                        <v-row
                          v-if="
                            cropCycle.crop.descriptionIssue &&
                            cropCycle.crop.descriptionIssue.length > 0
                          "
                        >
                          <v-col cols="12">
                            <span class="text-body-2 font-weight-bold"
                              >Descripción</span
                            >
                          </v-col>
                          <v-col  cols="12" class="py-0">
                            <span class="text-caption">{{
                              cropCycle.crop.descriptionIssue
                            }}</span>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <Map :soilSample="soilSample" @markerTap="markerTaped" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <template>
                  <v-carousel
                    v-if="subSamples.length > 0"
                    cycle
                    height="300"
                    hide-delimiter-background
                    show-arrows-on-hover
                  >
                    <v-carousel-item
                      v-for="(subSample, i) in subSamples"
                      :key="i"
                    >
                      <v-img
                        v-if="subSample.subSamplePhoto.startsWith('http')"
                        height="100%"
                        width="100%"
                        :src="subSample.subSamplePhoto"
                      ></v-img>
                      <v-img
                        v-else
                        height="100%"
                        width="100%"
                        :src="require('@/assets/img/no-img-sample.png')"
                      ></v-img>
                    </v-carousel-item>
                  </v-carousel>
                  <v-carousel
                    v-else
                    cycle
                    height="300"
                    hide-delimiter-background
                    show-arrows-on-hover
                  >
                    <v-carousel-item v-for="(slide, i) in slides" :key="i">
                      <v-img
                        height="100%"
                        width="100%"
                        :src="require('@/assets/img/no-img-sample.png')"
                      ></v-img>
                    </v-carousel-item>
                  </v-carousel>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12" xl="8" lg="8" md="6" sm="12" xs="12">
        <v-tabs v-model="tabs" color="primary" grow>
          <v-tab>Análisis fisicoquímicos</v-tab>
          <v-tab>Extracción de nemátodos</v-tab>
          <v-tab-item>
            <PCTItem
              :analysis="this.$route.params.analysis"
              @sampleChanged="changeSample"
            />
          </v-tab-item>
          <v-tab-item>
            <v-container>
              <NematodesItem
                :analysis="this.$route.params.analysis"
                @sampleChangedNematode="changeSampleNematodes"
              />
            </v-container>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <SubSamplePhoto
      :img="subSampleData"
      :visible="isSubSampleSelected"
      @hideSubSampleModal="hideSubSampleModal"
    />
  </v-container>
</template>

<script>
//components
import Map from "@/components/station1/Map";
import SubSamplePhoto from "@/components/station1/modals/SubSamplePhoto";
import PCTItem from "@/components/station1/PCTItem";
import NematodesItem from "@/components/station1/NematodesItem";
//Libraries
import dayjs from "dayjs";
//vuex
export default {
  components: {
    Map,
    SubSamplePhoto,
    PCTItem,
    NematodesItem,
  },
  data: () => ({
    soilSample: null,
    showMap: true,
    slides: ["First"],
    clientName: "",
    areaName: "",
    cropCycleName: "",
    cropCycle: null,
    subSamples: [],
    areaTargets: 0,
    qrCode: "",
    samplerName: "",
    samplingDate: "",
    receptionDate: "",
    sampleStatus: "",
    sampleType: "",
    sampleTracingStatus: "",
    polygon: [],
    currentFolio: "",
    isSubSampleSelected: false,
    subSampleData: [],
    sampleSelected: null,
    dataPCT: null,
    dataNEMA: null,
    tabs: [],
  }),
  watch: {
    tabs() {
      if (this.tabs == 0) {
        if (this.dataPCT != null) {
          this.changeSample(this.dataPCT);
        } else {
          this.clientName = "";
          this.areaName = "";
          this.cropCycleName = "";
          this.subSamples = "";
          this.areaTargets = "";
          this.qrCode = "";
          this.samplerName = "";
          this.samplingDate = null;
          this.receptionDate = null;
          this.sampleType = "";
          this.sampleTracingStatus = "";
          this.sampleStatus = "";
          this.soilSample = null;
          this.sampleSelected = null;
        }
      } else if (this.tabs == 1) {
        if (this.dataNEMA != null) {
          this.changeSampleNematodes(this.dataNEMA);
        } else {
          this.clientName = "";
          this.areaName = "";
          this.cropCycleName = "";
          this.subSamples = "";
          this.areaTargets = "";
          this.qrCode = "";
          this.samplerName = "";
          this.samplingDate = null;
          this.receptionDate = null;
          this.sampleType = "";
          this.sampleTracingStatus = "";
          this.sampleStatus = "";
          this.soilSample = null;
          this.sampleSelected = null;
        }
      }
    },
  },
  methods: {
    dateFormat(value) {
      const date = `${dayjs(value).format("DD-MM-YYYY - HH:mm")} hrs`;
      return date ? date : "----";
    },
    markerTaped(data) {
      if (typeof data != "undefined") {
        this.isSubSampleSelected = !this.isSubSampleSelected;
        let img = "";
        if (
          data.subSamplePhoto.length < 0 ||
          !data.subSamplePhoto.startsWith("http")
        ) {
          img = require("../assets/img/no-img-sample.png");
        } else {
          img = data.subSamplePhoto;
        }

        this.subSampleData = [
          {
            title: data.description,
            src: img,
          },
        ];
      }
    },
    hideSubSampleModal() {
      this.isSubSampleSelected = !this.isSubSampleSelected;
    },
    changeSample(data) {
      this.dataPCT = data;
      this.clientName = data.clientName;
      this.areaName = data.areaName;
      this.cropCycleName = data.cropCycleName;
      this.subSamples = data.subSamples;
      this.areaTargets = data.areaTargets;
      this.qrCode = data.qrCode;
      this.samplerName = data.samplerName;
      this.samplingDate = data.samplingDate;
      this.receptionDate = data.receptionDate;
      this.sampleType = data.sampleType;
      this.sampleTracingStatus = data.sampleTracingStatus;
      this.sampleStatus = data.sampleStatus;
      this.sampleSelected = data.sampleSelected;
      this.cropCycle = data.cropCycle;
      let issueArea = [];
      if (data.cropCycle) {
        issueArea = data.cropCycle.crop.issueArea
          ? data.cropCycle.crop.issueArea
          : [];
      }
      this.soilSample = { soilSample: data.soilSample, issueArea };
    },
    changeSampleNematodes(data) {
      this.dataNEMA = data;
      this.clientName = data.clientName;
      this.areaName = data.areaName;
      this.cropCycleName = data.cropCycleName;
      this.subSamples = data.subSamples;
      this.areaTargets = data.areaTargets;
      this.qrCode = data.qrCode;
      this.samplerName = data.samplerName;
      this.samplingDate = data.samplingDate;
      this.receptionDate = data.receptionDate;
      this.sampleType = data.sampleType;
      this.sampleTracingStatus = data.sampleTracingStatus;
      this.sampleStatus = data.sampleStatus;
      this.cropCycle = data.cropCycle;
      let issueArea = [];
      if (data.cropCycle) {
        issueArea = data.cropCycle.crop.issueArea
          ? data.cropCycle.crop.issueArea
          : [];
      }
      this.soilSample = { soilSample: data.soilSample, issueArea };
    },
    getCrop(value) {
      try {
        const crop = {
          olive: "Aceituna",
          chard: "Acelga",
          achiote: "Achiote",
          agave: "Agave",
          avocado: "Aguacate",
          garlic: "Ajo",
          sesame: "Ajonjolí",
          basil: "Albahaca",
          artichoke: "Alcachofa",
          alcatraz: "Alcatraz",
          shrunkenAlfalfa: "Alfalfa achicalada",
          greenAlfalfa: "Alfalfa verde",
          cotton: "Algodón",
          almond: "Almendro",
          birdseed: "Alpiste",
          amaranth: "Amaranto",
          anise: "Anís",
          celery: "Apio",
          blueberry: "Arándano",
          myrtle: "Arrayán",
          rice: "Arroz",
          greenForageOats: "Avena forrajera en verde",
          oatGrain: "Avena grano",
          bamboo: "Bambú",
          eggplant: "Berenjena",
          beetroot: "Betabel",
          broccoli: "Brocoli",
          peanut: "Cacahuate",
          cocoa: "Cacao",
          cherryCoffee: "Café cereza",
          pumpkin: "Calabaza",
          sweetPotato: "Camote",
          sugarCane: "Caña de azúcar",
          canola: "Canola",
          carambolo: "Carambolo",
          safflower: "Cártamo",
          barley: "Cebada",
          greenForageBarley: "Cebada forrajera en verde",
          onion: "Cebolla",
          chives: "Cebollín",
          ryeGrain: "Centeno grano",
          cherry: "Cereza",
          chabacano: "Chabacano",
          pea: "Chícharo",
          squash: "Chayote",
          chia: "Chía",
          chilacayote: "Chilacayote",
          chillyPepper: "Chile / Pimiento",
          custardApple: "Chirimoya",
          coriander: "Cilantro",
          plum: "Ciruela",
          coconutFruit: "Coco fruta",
          cabbage: "Col (repollo)",
          brusselsSprouts: "Col de Bruselas",
          cauliflower: "Coliflor",
          cumin: "Comino",
          chrysanthemum: "Crisantemo (planta)",
          peach: "Durazno",
          date: "Dátil",
          greenBean: "Ejote",
          cornCob: "Elote",
          dill: "Eneldo",
          epazote: "Epazote",
          asparagus: "Espárrago",
          spicesAndMedicinals: "Especias y medicinales",
          spinach: "Espinaca",
          raspberry: "Frambuesa",
          strawberry: "Fresa",
          bean: "Frijol",
          chickpea: "Garbanzo",
          sunflower: "Girasol",
          pomegranate: "Granada",
          soursop: "Guanábana",
          guava: "Guayaba",
          henequen: "Henequén",
          peppermint: "Hierbabuena",
          aromaticHerbs: "Hierbas aromáticas",
          fig: "Higo",
          jamaica: "Jamaica",
          ginger: "Jenjibre",
          jicama: "Jícama",
          lettuce: "Lechuga",
          lentil: "Lenteja",
          lime: "Lima",
          lemon: "Limón",
          linseed: "Linaza",
          litchi: "Litchi",
          corn: "Maíz",
          macadamia: "Macadamia",
          maguey: "Maguey",
          mamey: "Mamey",
          tangerine: "Mandarina",
          mango: "Mango",
          mangosteen: "Mangostán",
          chamomile: "Manzanilla",
          appleTree: "Manzano",
          passionFruit: "Maracuyá",
          daisyFlower: "Margarita",
          marjoram: "Mejorana",
          melon: "Melón",
          quince: "Membrillo",
          mint: "Menta",
          moringa: "Moringa",
          turnip: "Nabo",
          nanche: "Nanche",
          napa: "Napa",
          orange: "Naranja",
          medlar: "Níspero",
          nochebuena: "Nochebuena (planta)",
          walnut: "Nogal",
          noni: "Noni",
          nopal: "Nopal",
          oregano: "Orégano",
          ornamental: "Ornamentales",
          orchid: "Orquídea (planta)",
          oilPalm: "Palma africana o de aceite",
          potato: "Papa",
          papaya: "Papaya",
          grasslands: "Pastizales",
          matGrass: "Pasto tapete (m2)",
          pasturesAndMeadows: "Pastos y praderas",
          cucumber: "Pepino",
          pearTree: "Peral",
          parsley: "Perejil",
          peron: "Perón",
          persimony: "Persimonio",
          pepper: "Pimienta",
          pineapple: "Piña",
          pinion: "Piñón",
          pistache: "Pistache",
          pitaya: "Pitaya",
          banana: "Plátano",
          pore: "Poro",
          radish: "Rábano",
          rambutan: "Rambután",
          romerito: "Romerito",
          rosemary: "Romero",
          aloe: "Sábila",
          watermelon: "Sandía",
          sorghum: "Sorgo",
          soy: "Soya",
          stevia: "Stevia",
          tobacco: "Tabaco",
          tamarind: "Tamarindo",
          tejocote: "Tejocote",
          tomato: "Tomate Rojo (jitomate)",
          greenTomato: "Tomate Verde",
          thyme: "Tomillo",
          grapefruit: "Toronja (pomelo)",
          clover: "Trébol",
          wheat: "Trigo",
          dutchTulip: "Tulipán holandés",
          tuna: "Tuna",
          grape: "Uva",
          vanilla: "Vainilla",
          purslane: "Verdolaga",
          vine: "Vid",
          cassavaFood: "Yuca alimenticia",
          carrot: "Zanahoria",
          sapodilla: "Zapote",
          blackberry: "Zarzamora",
          zempoalxochitl: "Zempoalxochitl",
          fallow: "Barbecho",
          other: "Otro",
        };
        let name = crop[value];
        if (name) {
          return name;
        } else {
          return "Sin cultivo";
        }
      } catch (e) {
        return "Sin cultivo";
      }
    },
  },
};
</script>
