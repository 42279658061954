<template>
  <v-container class="fill-height pa-0" fluid>
    <v-row>
      <!-- detail item selected -->
      <v-col
        cols="12"
        xl="3"
        lg="4"
        v-if="showDetail && !$vuetify.breakpoint.mdAndDown"
      >
        <project-detail />
      </v-col>
      <!-- data table -->
      <v-col :xl="showDetail ? '9' : '12'" :lg="showDetail ? '8' : '12'">
        <project-table />
      </v-col>
    </v-row>
    <project-detail-modal
      v-model="showProjectDetailModal"
      v-if="showProjectDetailModal"
    />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import ProjectTable from "@/components/projects/ProjectTable.vue";
import ProjectDetail from "@/components/projects/ProjectDetail.vue";
import ProjectDetailModal from "@/components/projects/modal/ProjectDetailModal.vue";

export default {
  components: {
    ProjectTable,
    ProjectDetail,
    ProjectDetailModal,
  },
  data: () => ({
    showDetail: false,
    showProjectDetailModal: false,
  }),
  computed: {
    ...mapState("project", ["projectSelected"]),
  },
  watch: {
    projectSelected(value) {
      if (value) {
        this.showDetail = true;
        if (this.$vuetify.breakpoint.mdAndDown) {
          // show modal
          this.showProjectDetailModal = true;
        }
      } else {
        this.showDetail = false;
      }
    },
  },
};
</script>
