import axios from "axios";

/**
 * Function to search identifications by name
 * @param {function} { commit } - vuex function
 * @param {Object} { rootState } - vuex store object
 * @param {String} payload - string to search within identifications
 * @returns {data} -  Returns array of identifications
 */
export async function searchIdentifications({ commit, rootState }, payload) {
  try {
    const { data } = await axios.get("/identification/search", {
      params: payload,
      cancelToken: rootState.cancelToken && rootState.cancelToken.token,
    });

    if (data.error) throw data;
    commit("SET_IDENTIFICATIONS", data.IDENTIFICATIONS);
    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function searchIdentificationsHomology(
  { commit, rootState },
  payload
) {
  try {
    const { data } = await axios.get("/identification/search", {
      params: payload,
      cancelToken: rootState.cancelToken && rootState.cancelToken.token,
    });

    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to create a new nematode identification
 * @param {Object} { commit } - Vuex function
 * @param {Object} identif - Identification info
 */
export async function addNematodeIdentification({ commit }, identif) {
  try {
    const { data } = await axios.post("/identificationNematode/add", identif);
    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to update identification
 * @param {function} { commit } - vuex function
 * @param {Object} payload - id identification
 * @returns {data} -  Returns array of identifications
 */
export async function updateIdentification({ commit }, payload) {
  try {
    const { data } = await axios.put("/identification/update", payload);
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to create a new nematode identification
 * @param {Object} { commit } - Vuex function
 * @param {Object} identif - Identification info
 */
export async function addStrainIdentification({ commit }, identif) {
  try {
    const { data } = await axios.post("/identification/add", identif);
    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get all identification relations
 * @param {function} { commit } - vuex function
 * @param {Object} { rootState } - vuex store object
 * @returns {data} -  Returns array of identifications
 */
export async function searchIdentificationReactions({ commit, rootState }) {
  try {
    const { data } = await axios.get(
      "/identificationReaction/getIdentificationReactions"
    );

    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get all identification
 * @param {function} { commit } - vuex function
 * @param {Object} { rootState } - vuex store object
 * @returns {data} -  Returns array of identifications
 */
export async function getAllIdentifications({ commit, rootState }) {
  try {
    const { data } = await axios.get("/identification/all");

    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to save new identification relation
 * @param {function} { commit } - vuex function
 * @param {Object} { rootState } - vuex store object
 * @param payload payload - identifiation reaction relation data
 * @returns {data} -  Returns array of identifications
 */
export async function addNewIdentificationReaction(
  { commit, rootState },
  payload
) {
  try {
    const { data } = await axios.post(
      "/identificationReaction/addIdentificationReaction",
      payload
    );

    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function getIdentificationsPaged({ commit }, payload) {
  try {
    const { data } = await axios.get(
      "/identification/get-identifications-paged",
      {
        params: payload,
      }
    );

    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.message);
  }
}

export async function createIdentification({ commit, rootState }, payload) {
  try {
    const { data } = await axios.post(
      "/identification/create-identification",
      payload
    );

    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.message);
  }
}

export async function update(
  { commit, rootState },
  { payload, identificationId }
) {
  try {
    const { data } = await axios.put(
      "/identification/update-identification",
      payload,
      {
        params: { identificationId: identificationId },
      }
    );

    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.message);
  }
}
