import axios from "axios";

/**
 * get all preserved in ceparium
 */
export async function getPrserved({ commit }, payload) {
  try {
    const { data } = await axios.get("/ceparium", {
      params: payload,
    });
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    console.log(e);
    throw Error(e.message);
  }
}

export async function addPreservedID({ commit }, payload) {
  try {
    const { data } = await axios.post("/ceparium/add-preserved-id", payload);
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    throw Error(e.message);
  }
}

export async function updatePreservedID({ commit }, payload) {
  try {
    console.log(payload.preservedId );
    const { data } = await axios.put("/ceparium/update-preserved-id", {
      params: { preservedId: payload.preservedId },
      body: payload.body,
    });
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    throw Error(e.message);
  }
}
