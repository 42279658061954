<template>
  <div>
    <div
      class="camera-preview"
      ref="captureContainer"
      :style="imageDataURL != null ? 'display: none;' : ''"
    >
      <video ref="video" autoplay playsinline></video>
    </div>

    <canvas
      class="preview"
      ref="canvas"
      :style="imageDataURL == null ? 'display: none;' : ''"
    ></canvas>
    <!-- <button @click="capturarFoto">Capturar Foto</button> -->
    <v-row class="pt-5" align="center" justify="center">
      <v-col cols="6">
        <v-btn
          @click="capturarFoto"
          color="success"
          :disabled="imageDataURL != null"
          block
          ><v-icon left>mdi-camera</v-icon>Capturar Foto</v-btn
        >
      </v-col>
      <v-col cols="6">
        <v-btn
          @click="imageDataURL = null"
          color="warning"
          :disabled="imageDataURL == null"
          block
          ><v-icon left>mdi-camera-retake</v-icon>Repetir Foto</v-btn
        >
      </v-col>
      <v-col cols="8">
        <v-btn
          @click="uploadPhotos"
          color="success"
          :disabled="imageDataURL == null"
          :loading="uploadingPhotos"
          block
          ><v-icon left>mdi-cloud-upload-outline</v-icon>Subir fotografía</v-btn
        >
      </v-col>
    </v-row>

    <!-- <img v-if="imageDataURL" :src="imageDataURL" alt="Captura en forma circular" /> -->
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      imageDataURL: null,
      originalFile: null,
      smallFile: null,
      uploadingPhotos: false,
      stream: null,
    };
  },
  props: {
    test_name: {
      type: String,
      default: "test",
    },
  },
  mounted() {
    this.accederCamara();
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("biochemicalTest", ["uploadPhoto"]),
    accederCamara() {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            width: { ideal: 4096 },
            height: { ideal: 2160 },
            facingMode: "environment",
          },
        })
        .then((stream) => {
          this.$refs.video.srcObject = stream;
          this.stream = stream;
        })
        .catch((error) => {
          console.error("Error al acceder a la cámara: ", error);
        });
    },
    capturarFoto() {
      const container = this.$refs.captureContainer;
      const video = this.$refs.video;

      const videoWidth = video.videoWidth;
      const videoHeight = video.videoHeight;

      const size = Math.min(videoWidth, videoHeight);
      const xOffset = (videoWidth - size) / 2;
      const yOffset = (videoHeight - size) / 2;

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      canvas.width = 2160;
      canvas.height = 2160;

      // Dibuja el video en el canvas
      // context.drawImage(video, 0, 0, width, height);
      context.drawImage(
        video,
        xOffset,
        yOffset,
        size,
        size,
        0,
        0,
        canvas.width,
        canvas.height
      );

      const width = 300;
      const height = 300;

      // Crea una nueva imagen en forma circular
      const circularCanvas = this.$refs.canvas;
      const circularContext = circularCanvas.getContext("2d");
      circularCanvas.width = width;
      circularCanvas.height = height;
      circularContext.arc(width / 2, height / 2, width / 2, 0, 2 * Math.PI);
      circularContext.clip();
      circularContext.drawImage(canvas, 0, 0, width, height);

      console.log(circularContext);
      // Convierte el canvas en una imagen
      this.imageDataURL = circularCanvas.toDataURL();

      let fileB64 = this.b64ToFile(this.test_name, canvas.toDataURL());
      let fileB64Small = this.b64ToFile(
        `${this.test_name}-small`,
        circularCanvas.toDataURL()
      );

      this.originalFile = fileB64;
      this.smallFile = fileB64Small;

      // this.descargarImagen(canvas.toDataURL(), "original");
      // this.descargarImagen(circularCanvas.toDataURL(), "small");
    },
    descargarImagen(b64, name) {
      // Convierte la imagen en una cadena de datos base64
      const base64Data = b64.split(",")[1];
      const blob = atob(base64Data);

      // Crea un array de bytes a partir de la cadena de datos
      const byteArray = new Uint8Array(blob.length);
      for (let i = 0; i < blob.length; i++) {
        byteArray[i] = blob.charCodeAt(i);
      }

      // Crea un objeto Blob a partir del array de bytes
      const fileBlob = new Blob([byteArray], { type: "image/png" });
      const fileName = name + ".png";
      const imageFile = new File([fileBlob], fileName, { type: "image/png" });

      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(imageFile);
      downloadLink.download = fileName;
      downloadLink.click();
    },
    b64ToFile(fileName, b64) {
      var base64data = b64
        .replace("data:image/png;base64,", "")
        .replace("data:image/jpg;base64,", "");
      var bs = atob(base64data);
      var buffer = new ArrayBuffer(bs.length);
      var ba = new Uint8Array(buffer);
      for (var i = 0; i < bs.length; i++) {
        ba[i] = bs.charCodeAt(i);
      }
      var blob = new Blob([ba], { type: "image/png" });

      let file = new File([blob], fileName);

      return file;
    },
    async uploadPhotos() {
      this.uploadingPhotos = true;
      try {
        if (this.originalFile != null && this.smallFile != null) {
          console.log(this.originalFile);
          console.log(this.smallFile);

          var formData = new FormData();
          formData.append("file", this.smallFile);

          var formDataOriginal = new FormData();
          formDataOriginal.append("file", this.originalFile);

          let response = await this.uploadPhoto(formData);
          let response2 = await this.uploadPhoto(formDataOriginal);

          this.$emit("image_uploaded", {
            original: response2,
            small: response,
          });
        } else {
          throw Error("No hay fotografias para subir");
        }
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Error al subir las fotografias. Error: ${error.message}`,
          timeout: 4000,
        });
      }
      this.uploadingPhotos = false;
    },
  },
  beforeDestroy() {
    const tracks = this.stream.getTracks();

    tracks.forEach((track) => track.stop());

    // Limpiar la referencia a la instancia de MediaStream
    this.stream = null;
  },
};
</script>

<style>
.camera-preview {
  width: 300px; /* Ajusta el tamaño según tus necesidades */
  height: 300px; /* Ajusta el tamaño según tus necesidades */
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.camera-preview video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preview {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
</style>
