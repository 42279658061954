<template>
  <v-dialog v-model="dialog" max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        depressed
        color="secondary"
        class="v-btn--padding"
      >
        Nuevo medio de cultivo
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-center headline">
        <v-row>
          <v-col> Nuevo medio de cultivo </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <!-- Form -->
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-text-field
            v-model="form.nutrientAgar"
            :rules="nutrientAgarNameRules"
            label="Nombre del medio"
          ></v-text-field>
          <v-select
            v-model="form.moType"
            :items="itemsMoType"
            :rules="moTypeRules"
            label="Tipo de MO"
          />
          <v-select
            v-model="form.dilution"
            :items="itemsDilution"
            clearable
            label="Dilución por defecto (opcional)"
          />
        </v-form>
      </v-card-text>
      <!-- Actions -->
      <v-card-actions>
        <v-spacer />
        <v-btn @click="dialog = false" depressed dark color="gray">
          Cerrar
        </v-btn>
        <v-btn
          @click="createCultureMedia"
          :loading="loading"
          depressed
          color="primary"
        >
          Crear medio
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
// Validations
import { requiredValidation } from "@/helpers/validations";

export default {
  name: "CreateCultureMedia",
  data() {
    return {
      dialog: false,
      valid: true,
      loading: false,
      // data form
      form: {
        nutrientAgar: "",
        moType: "",
        dilution: "",
      },
      // items
      itemsMoType: [
        { value: "all", text: "Todos" },
        { value: "bacteria", text: "Bacteria" },
        { value: "fungi", text: "Hongo" },
        { value: "nematode", text: "Nemátodo" },
      ],
      itemsDilution: ["P", "Direct", "-6", "-5", "-4", "-3", "-2", "-1"],
      // rules
      nutrientAgarNameRules: requiredValidation("nombre del medio"),
      moTypeRules: requiredValidation("tipo de microoganismo"),
      // user data
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  watch: {
    // Reset form when opens or closes
    dialog: function () {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },
  },

  methods: {
    ...mapActions("cultureMedia", ["createCultureMedium"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),

    async createCultureMedia() {
      this.loading = true;
      try {
        if (this.$refs.form.validate()) {
          const response = await this.createCultureMedium(this.form);
          if (response.error) throw response;

          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: `Medio de cultivo creado correctamente`,
            timeout: 4000,
          });
        }
      } catch (error) {
        console.log("error createCultureMedia", error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Ha ocurrido un error, inténtalo de nuevo",
          timeout: 4000,
        });
      }
      this.loading = false;
      this.dialog = false;
    },
  },
};
</script>

<style lang="css" scoped>
.v-btn--padding.v-size--default {
  padding: 0 8px;
}
</style>
