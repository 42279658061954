import axios from "axios";

/**
 * Function to get count of every test media
 * @param {function} { commit } - Vuex function
 * @param {Object} payload - filters to get test media
 * @returns {data} -  Returns array of test media objects
 */

export async function getb64Image({ commit }, payload) {
    try {
        const { data } = await axios.post(`get-b64-image`, {
            imageUrl: payload,
        });
        if (data.error) throw data;
        commit("SET_B64_IMAGE", data.b64Image);
        return data.b64Image;
    } catch (e) {
        return e.response.data;
    }
}