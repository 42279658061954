// Guard
import { privateGuard } from "@/middlewares/guards";

// Layouts
import NavLayout from "../layouts/NavLayout.vue";

// Views
import RiskLevelsControlPanel from "../views/RiskLevelsControlPanel.vue";

export default [
  {
    path: "/level-risk-dashboard",
    beforeEnter: privateGuard,
    component: NavLayout,
    children: [
      {
        path: "",
        name: "Dashboard niveles de riesgo",
        component: RiskLevelsControlPanel,
        meta: {
          title: "Dashboard niveles de riesgo",
        },
      },
    ],
  },
];
