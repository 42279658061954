<template>
  <v-container class="fill-height">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h5>Consola de administración CMDA</h5>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="fill-height">
      <v-col>
        <v-card rounded="lg" elevation="5" class="fill-height">
          <v-card-text class="ma-0 pa-0">
            <v-container fluid>
              <v-row>
                <v-col :cols="mini ? '1' : '3'">
                  <v-row>
                    <v-col>
                      <v-navigation-drawer
                        v-model="drawer"
                        :mini-variant.sync="mini"
                        permanent
                        width="auto"
                      >
                        <v-list-item>
                          <v-list-item-icon @click.stop="mini = !mini">
                            <v-icon>mdi-menu</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title class="text-h6">
                              <span v-if="selectedItem == 0">
                                Gestion CMDA
                              </span>
                              <span v-else-if="selectedItem == 1">
                                Marcadores
                              </span>
                              <span v-else-if="selectedItem == 2">
                                Niveles
                              </span>
                              <span v-else-if="selectedItem == 3"> Test </span>
                              <span v-else-if="selectedItem == 4">
                                Productos (Prescripción)
                              </span>
                            </v-list-item-title>
                            <v-list-item-subtitle> Menú </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider></v-divider>

                        <v-list-item-group dense nav>
                          <v-list-item
                            :class="{ highlighted: selectedItem === 0 }"
                            @click="selectItem(0)"
                          >
                            <v-list-item-icon>
                              <v-icon
                                :class="selectedItem === 0 ? 'custom-icon' : ''"
                                >mdi-cog</v-icon
                              >
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title
                                >Gestion CMDA</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            :class="{ highlighted: selectedItem === 1 }"
                            @click="selectItem(1)"
                          >
                            <v-list-item-icon>
                              <v-icon
                                :class="selectedItem === 1 ? 'custom-icon' : ''"
                                >mdi-sign-text</v-icon
                              >
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Marcadores</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            :class="{ highlighted: selectedItem === 2 }"
                            @click="selectItem(2)"
                          >
                            <v-list-item-icon>
                              <v-icon
                                :class="selectedItem === 2 ? 'custom-icon' : ''"
                                >mdi-chart-timeline-variant</v-icon
                              >
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Niveles</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            :class="{ highlighted: selectedItem === 3 }"
                            @click="selectItem(3)"
                          >
                            <v-list-item-icon>
                              <v-icon
                                :class="selectedItem === 3 ? 'custom-icon' : ''"
                                >mdi-auto-fix</v-icon
                              >
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Test</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            :class="{ highlighted: selectedItem === 4 }"
                            @click="selectItem(4)"
                          >
                            <v-list-item-icon>
                              <v-icon
                                :class="selectedItem === 4 ? 'custom-icon' : ''"
                                >mdi-basket-outline</v-icon
                              >
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title
                                >Productos (Prescripción)</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-navigation-drawer>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col :cols="mini ? '11' : '9'">
                  <div v-if="selectedItem == 0">
                    <CMDAPanel />
                  </div>
                  <div v-else-if="selectedItem == 1">
                    <MolecularMarkerTable />
                  </div>
                  <div v-else-if="selectedItem == 2">
                    <LevelRiskTable />
                  </div>
                  <div v-if="selectedItem == 3">
                    <ResultTest />
                  </div>
                  <div v-else-if="selectedItem == 4">
                    <ProductsTable />
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <UpdateRiskLevelModal
      v-model="showUpdateRiskLevelModal"
      v-if="showUpdateRiskLevelModal"
      @refreshTable="fetchData"
    />
    <UpdateProductmodal
      v-model="showUpdateRiskLevelModalBASF"
      v-if="showUpdateRiskLevelModalBASF"
      @refreshTable="fetchDataBASF"
    />
  </v-container>
</template>

<script>
//Vuex
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
//components
//Libraries
import dayjs from "dayjs";
//modals
import UpdateRiskLevelModal from "@/components/cmda_admin/modals/UpdateRiskLevelModal.vue";
import UpdateProductmodal from "@/components/cmda_admin/modals/UpdateProductmodal.vue";
import LevelRiskTable from "@/components/cmda_admin/LevelRiskTable.vue";
import ResultTest from "@/components/cmda_admin/ResultTest.vue";
import ProductsTable from "@/components/cmda_admin/ProductsTable.vue";
import CMDAPanel from "@/components/cmda_admin/CMDAPanel.vue";
import MolecularMarkerTable from "@/components/cmda_admin/MolecularMarkerTable.vue";
export default {
  components: {
    UpdateRiskLevelModal,
    UpdateProductmodal,
    LevelRiskTable,
    ResultTest,
    ProductsTable,
    CMDAPanel,
    MolecularMarkerTable,
  },
  data: () => ({
    isVisible: false,
    headers: [
      {
        text: "Marcador molecular",
        sortable: false,
        value: "molecularMarker",
      },
      { text: "Familia", value: "family", sortable: false },
      {
        text: "Niveles de referencía",
        value: "riskLevelLow",
        sortable: false,
      },
      { text: "Cq's", value: "cqMax", sortable: false },
      { text: "Ultima actualización", value: "updatedAt", sortable: false },
      { text: "Acciones", value: "_id", sortable: false },
    ],
    headersBASF: [
      {
        text: "Marcador molecular",
        sortable: false,
        value: "molecularMarker",
      },
      {
        text: "Cultivo",
        sortable: false,
        value: "crop",
      },
      {
        text: "Paquete",
        sortable: false,
        value: "package",
      },
      { text: "Etapa fenologica", value: "phenologicalStage", sortable: false },
      { text: "Producto", value: "productName", sortable: false },
      { text: "Ultima actualización", value: "updatedAt", sortable: false },
      // { text: "Acciones", value: "_id", sortable: false },
    ],
    per_page: 10,
    page: 1,
    numPages: 1,
    sort: { createdAt: -1 },
    search: null,
    filter: {
      recommendationType: { $exists: false },
    },
    filter_basf: {
      recommendationType: "PRODUCT",
    },
    loadTable: false,
    riskLevels: [],
    showUpdateRiskLevelModal: false,
    showUpdateRiskLevelModalBASF: false,
    tabs: null,
    per_page_basf: 10,
    page_basf: 1,
    numPages_basf: 1,
    loadTableBASF: false,
    riskLevelsBASF: [],
    selectedItem: 0,
    drawer: true,
    mini: false,
  }),
  async mounted() {
    await this.fetchData();
    await this.fetchDataBASF();
  },
  methods: {
    ...mapActions("riskLevel", ["getPrescriptions"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async fetchData() {
      try {
        this.loadTable = true;
        let payload = {
          filter: this.filter,
          per_page: this.per_page,
          page: this.page,
          sort: this.sort,
        };
        let response = await this.getPrescriptions(payload);
        if (response.error) throw Error(response.message);
        this.numPages = response.pages;
        this.riskLevels = response.prescriptions;
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
      this.loadTable = false;
    },
    async fetchDataBASF() {
      try {
        this.loadTableBASF = true;
        let payload = {
          filter: this.filter_basf,
          per_page: this.per_page_basf,
          page: this.page_basf,
          sort: this.sort,
        };
        let response = await this.getPrescriptions(payload);
        if (response.error) throw Error(response.message);
        this.numPages_basf = response.pages;
        this.riskLevelsBASF = response.prescriptions;
        console.log(this.riskLevelsBASF);
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
      this.loadTableBASF = false;
    },
    dateFormat(value) {
      const date = `${dayjs(value).format("DD/MM/YYYY - HH:mm")} hrs`;
      return date || "--/--/----";
    },
    openModalUpdateLevels() {
      console.log(this.tabs);
      if (this.tabs == 0) {
        this.showUpdateRiskLevelModal = true;
      } else {
        this.showUpdateRiskLevelModalBASF = true;
      }
    },
    getPeheno(item) {
      let peheno = {
        Transplanting: "Trasplante",
        "Vegetative growth": "Desarrollo Vegetativo",
        Bloom: "Floración",
        Harvest: "Cosecha",
        Sowing: "Siembra",
        Sprout: "Emergencia",
        Tuberization: "Tuberización",
        "Tuber filling": "Llenado de tuberculos",
        "Foliage development": "Desarrollo foliar",
        "Foliar expansion": "Expansión foliar",
        "First leaf": "Primera hoja",
        "Third leaf": "Tercer hoja ",
        "Fifth leaf": "Quinta hoja",
        "Start of bulbing": "Inicio de bulbificación",
        "Bulb growth": "Desarrollo del bulbo",
        "Fruit maturity": "Maduración de fruto",
        Sprouting: "Brotación",
        "Pre-planting": "Presiembra",
      };
      return peheno[item] ? peheno[item] : item;
    },
    getCrop(value) {
      let template = "";
      const crop = {
        olive: "Aceituna",
        chard: "Acelga",
        achiote: "Achiote",
        agave: "Agave",
        avocado: "Aguacate",
        garlic: "Ajo",
        sesame: "Ajonjolí",
        basil: "Albahaca",
        artichoke: "Alcachofa",
        alcatraz: "Alcatraz",
        shrunkenAlfalfa: "Alfalfa achicalada",
        greenAlfalfa: "Alfalfa verde",
        cotton: "Algodón",
        almond: "Almendro",
        birdseed: "Alpiste",
        amaranth: "Amaranto",
        anise: "Anís",
        celery: "Apio",
        blueberry: "Arándano",
        myrtle: "Arrayán",
        rice: "Arroz",
        greenForageOats: "Avena forrajera en verde",
        oatGrain: "Avena grano",
        bamboo: "Bambú",
        eggplant: "Berenjena",
        beetroot: "Betabel",
        broccoli: "Brocoli",
        peanut: "Cacahuate",
        cocoa: "Cacao",
        cherryCoffee: "Café cereza",
        pumpkin: "Calabaza",
        sweetPotato: "Camote",
        sugarCane: "Caña de azúcar",
        canola: "Canola",
        carambolo: "Carambolo",
        safflower: "Cártamo",
        barley: "Cebada",
        greenForageBarley: "Cebada forrajera en verde",
        onion: "Cebolla",
        chives: "Cebollín",
        ryeGrain: "Centeno grano",
        cherry: "Cereza",
        chabacano: "Chabacano",
        pea: "Chícharo",
        squash: "Chayote",
        chia: "Chía",
        chilacayote: "Chilacayote",
        chillyPepper: "Chile / Pimiento",
        custardApple: "Chirimoya",
        coriander: "Cilantro",
        plum: "Ciruela",
        coconutFruit: "Coco fruta",
        cabbage: "Col (repollo)",
        brusselsSprouts: "Col de Bruselas",
        cauliflower: "Coliflor",
        cumin: "Comino",
        chrysanthemum: "Crisantemo (planta)",
        peach: "Durazno",
        date: "Dátil",
        greenBean: "Ejote",
        cornCob: "Elote",
        dill: "Eneldo",
        epazote: "Epazote",
        asparagus: "Espárrago",
        spicesAndMedicinals: "Especias y medicinales",
        spinach: "Espinaca",
        raspberry: "Frambuesa",
        strawberry: "Fresa",
        bean: "Frijol",
        chickpea: "Garbanzo",
        sunflower: "Girasol",
        pomegranate: "Granada",
        soursop: "Guanábana",
        guava: "Guayaba",
        henequen: "Henequén",
        peppermint: "Hierbabuena",
        aromaticHerbs: "Hierbas aromáticas",
        fig: "Higo",
        jamaica: "Jamaica",
        ginger: "Jenjibre",
        jicama: "Jícama",
        lettuce: "Lechuga",
        lentil: "Lenteja",
        lime: "Lima",
        lemon: "Limón",
        linseed: "Linaza",
        litchi: "Litchi",
        corn: "Maíz",
        macadamia: "Macadamia",
        maguey: "Maguey",
        mamey: "Mamey",
        tangerine: "Mandarina",
        mango: "Mango",
        mangosteen: "Mangostán",
        chamomile: "Manzanilla",
        appleTree: "Manzano",
        passionFruit: "Maracuyá",
        daisyFlower: "Margarita",
        marjoram: "Mejorana",
        melon: "Melón",
        quince: "Membrillo",
        mint: "Menta",
        moringa: "Moringa",
        turnip: "Nabo",
        nanche: "Nanche",
        napa: "Napa",
        orange: "Naranja",
        medlar: "Níspero",
        nochebuena: "Nochebuena (planta)",
        walnut: "Nogal",
        noni: "Noni",
        nopal: "Nopal",
        oregano: "Orégano",
        ornamental: "Ornamentales",
        orchid: "Orquídea (planta)",
        oilPalm: "Palma africana o de aceite",
        potato: "Papa",
        papaya: "Papaya",
        grasslands: "Pastizales",
        matGrass: "Pasto tapete (m2)",
        pasturesAndMeadows: "Pastos y praderas",
        cucumber: "Pepino",
        pearTree: "Peral",
        parsley: "Perejil",
        peron: "Perón",
        persimony: "Persimonio",
        pepper: "Pimienta",
        pineapple: "Piña",
        pinion: "Piñón",
        pistache: "Pistache",
        pitaya: "Pitaya",
        banana: "Plátano",
        pore: "Poro",
        radish: "Rábano",
        rambutan: "Rambután",
        romerito: "Romerito",
        rosemary: "Romero",
        aloe: "Sábila",
        watermelon: "Sandía",
        sorghum: "Sorgo",
        soy: "Soya",
        stevia: "Stevia",
        tobacco: "Tabaco",
        tamarind: "Tamarindo",
        tejocote: "Tejocote",
        tomato: "Tomate Rojo (jitomate)",
        greenTomato: "Tomate Verde",
        thyme: "Tomillo",
        grapefruit: "Toronja (pomelo)",
        clover: "Trébol",
        wheat: "Trigo",
        dutchTulip: "Tulipán holandés",
        tuna: "Tuna",
        grape: "Uva",
        vanilla: "Vainilla",
        purslane: "Verdolaga",
        vine: "Vid",
        cassavaFood: "Yuca alimenticia",
        carrot: "Zanahoria",
        sapodilla: "Zapote",
        blackberry: "Zarzamora",
        zempoalxochitl: "Zempoalxochitl",
        fallow: "Barbecho",
        other: "Otro",
      };
      let cropName = crop[value];
      if (cropName) {
        template = cropName;
      } else {
        template = "Sin cultivo";
      }
      return template;
    },
    selectItem(index) {
      this.selectedItem = index;
      // Aquí puedes realizar cualquier otra acción necesaria cuando se selecciona un elemento
    },
    toggleElement() {
      this.isVisible = !this.isVisible;
    },
    toggleDrawer() {
      this.drawer = !this.drawer; // Alterna el estado del drawer
    },
  },
  watch: {
    async page() {
      await this.fetchData();
    },
    async page_basf() {
      await this.fetchDataBASF();
    },
    async search(value) {
      if (this.tabs == 0) {
        this.page = 1;
        this.numPages = 1;
        if (value) {
          this.filter = {
            recommendationType: { $exists: false },
            search: value,
          };
        } else {
          this.filter = {
            recommendationType: { $exists: false },
          };
        }

        await this.fetchData();
      } else {
        this.page_basf = 1;
        this.numPages_basf = 1;
        if (value) {
          this.filter_basf = {
            recommendationType: "PRODUCT",
            cqMin: { $exists: true },
            cqMax: { $exists: true },
            search: value,
          };
        } else {
          this.filter_basf = {
            recommendationType: "PRODUCT",
          };
        }

        await this.fetchDataBASF();
      }
    },
  },
};
</script>
<style scoped>
.highlighted {
  background-color: rgba(4, 193, 5, 0.1);
}

.custom-icon {
  background: linear-gradient(45deg, #ff8f1c, #04c105); /* Degradado de color */
  -webkit-background-clip: text; /* Clip para que el degradado se aplique al texto */
  -webkit-text-fill-color: transparent; /* Oculta el color del texto */
}
</style>
