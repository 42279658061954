<template>
  <v-container fluid class="pa-0">
    <v-container class="pa-3">
      <v-row>
        <!-- Buttons -->
        <v-col cols="12" xl="6" lg="6" md="5" sm="12" xs="12">
          <v-combobox
            :items="samples"
            v-model="sampleSelected"
            @input="changeSample()"
            clearable
            hide-selected
            outlined
            dense
          ></v-combobox>
        </v-col>
        <v-col cols="12" xl="6" lg="6" md="7" sm="12" xs="12">
          <v-row class="flex-row-reverse">
            <v-col cols="12" xl="5" lg="6" md="6" sm="4" xs="12">
              <v-btn
                color="primary"
                block
                dense
                :disabled="disableFinishButton"
                @click="searchNematode()"
                >Agregar Nemátodo</v-btn
              >

              <v-dialog
                v-model="dialog"
                transition="dialog-top-transition"
                max-width="400"
                persistent
              >
                <template>
                  <v-card>
                    <v-card-title color="primary" class="justify-center" dark
                      ><span class="text--secondary h5 font-weight-bold"
                        >Identificar nemátodo</span
                      ></v-card-title
                    >
                    <v-card-text>
                      <v-form ref="nematodeForm" lazy-validation>
                        <v-row class="ml-3 mr-3">
                          <v-col>
                            <v-text-field
                              type="number"
                              v-model="nematodeTestForm.recoveredWater"
                              clearable
                              label="Volumen de agua recuperada"
                              :rules="validation()"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="ml-3 mr-3">
                          <v-col>
                            <v-text-field
                              type="number"
                              v-model="nematodeTestForm.takenWater"
                              clearable
                              label="Volumen de agua tomada"
                              :rules="validation()"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="ml-3 mr-3">
                          <v-col>
                            <v-text-field
                              type="number"
                              v-model="nematodeTestForm.totalNematodes"
                              clearable
                              label="Total de Nemátodos"
                              :rules="validation()"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="ml-3 mr-3">
                          <v-col>
                            <v-text-field
                              type="number"
                              v-model="nematodeTestForm.phytopathogenNematodes"
                              clearable
                              label="Nemátodos fitopatógenos"
                              :rules="validation()"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row class="ml-2 mr-2">
                          <v-col>
                            <span class="font-weight-bold body-2"
                              >Nemátodo identificado</span
                            >
                          </v-col>
                        </v-row>
                        <v-row class="ml-3 mr-3">
                          <v-col>
                            <v-text-field
                              type="number"
                              v-model="quantityNematodes"
                              clearable
                              label="Número de nemátodos"
                              :rules="validation()"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="ml-3 mr-3">
                          <v-col>
                            <v-combobox
                              :rules="validation()"
                              v-model="identification"
                              :search-input.sync="search"
                              :items="identificationsFounded"
                              :loading="isLoading"
                              @keydown="changeIdentificationValue"
                              label="Identificación"
                              item-text="name"
                              clearable
                              hide-no-data
                            >
                            </v-combobox>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-row class="justify-center mb-4" no-gutters>
                        <v-col
                          cols="12"
                          xl="4"
                          lg="4"
                          md="4"
                          sm="4"
                          xs="12"
                          class="ma-1"
                        >
                          <v-btn color="gray" block dark @click="clearForm()"
                            >Cerrar</v-btn
                          >
                        </v-col>
                        <v-col
                          cols="12"
                          xl="4"
                          lg="4"
                          md="4"
                          sm="4"
                          xs="12"
                          class="ma-1"
                        >
                          <v-btn
                            color="primary"
                            :loading="isLoadingNematode"
                            block
                            @click="addNematode()"
                            >Aceptar</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </v-col>
            <v-col cols="12" xl="5" lg="6" md="6" sm="4" xs="12">
              <v-dialog
                v-model="dialogDeleteNematodes"
                persistent
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="gray"
                    v-bind="attrs"
                    v-on="on"
                    :dark="disableDeleteNematodes"
                    block
                    dense
                    :disabled="!disableDeleteNematodes"
                    >Eliminar nemátodo</v-btn
                  >
                </template>
                <v-card>
                  <v-card-title class="text-h5">
                    Eliminar nematodos
                  </v-card-title>
                  <v-card-text
                    >¿Estas seguro que deseas eliminar los nematodos
                    seleccionados?</v-card-text
                  >
                  <v-card-actions>
                    <v-container>
                      <v-row>
                        <v-col cols="6">
                          <v-btn
                            color="gray"
                            dark
                            @click="dialogDeleteNematodes = false"
                            block
                          >
                            Cancelar
                          </v-btn>
                        </v-col>
                        <v-col cols="6">
                          <v-btn
                            color="primary"
                            @click="deleteNematodes()"
                            block
                          >
                            Aceptar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="12" xl="5" lg="6" md="6" sm="4" xs="12">
              <v-btn
                color="primary"
                block
                dense
                @click="finishNematodeCount()"
                :disabled="disableFinishButton"
                >Finalizar conteo</v-btn
              >
            </v-col>
            <v-col cols="12" xl="5" lg="6" md="6" sm="4" xs="12">
              <v-btn
                color="gray"
                v-bind="attrs"
                v-on="on"
                :dark="disableFinishButton"
                block
                dense
                :disabled="!disableFinishButton"
                @click="revertNematodeCount()"
                >Revertir conteo</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <!-- Table -->
        <v-col cols="12">
          <v-card tile elevation="0" min-height="550" class="my-auto">
            <v-container>
              <v-data-table
                dense
                :show-select="!disableFinishButton"
                height="500"
                :headers="headers"
                :items="nematodesList"
                hide-default-footer
                :loading="loadingData"
                v-model="checkBoxesSelected"
                item-key="strain_id"
              >
                <template #item="{ item }">
                  <tr
                    :class="
                      item.strain_id === selectedRow
                        ? 'custom-highlight-row'
                        : ''
                    "
                    :key="item.strain_id"
                  >
                    <td v-if="!disableFinishButton">
                      <v-checkbox
                        class="pa-0 ma-0"
                        v-model="checkBoxesSelected"
                        :ripple="false"
                        :value="item"
                        hide-details
                      ></v-checkbox>
                    </td>
                    <td>
                      <p style="font-size: 0.9em">
                        {{ item.analysis_id }}
                      </p>
                    </td>
                    <td>
                      <p style="font-size: 0.9em">
                        {{ item.client_company_name }}
                      </p>
                    </td>
                    <td>
                      <p style="font-size: 0.9em">
                        {{ item.sampleType }}
                      </p>
                    </td>
                    <td>
                      <p style="font-size: 0.9em">
                        {{ item.analysis_type }}
                      </p>
                    </td>
                    <!-- <td>
                      <p style="font-size: 0.9em">
                        {{ item.strain_id }}
                      </p>
                    </td> -->
                    <td>
                      <p style="font-size: 0.9em">
                        {{ item.identification }}
                      </p>
                    </td>
                    <td>
                      <p style="font-size: 0.9em">
                        {{ item.qty_strains }}
                      </p>
                    </td>
                    <td>
                      <p style="font-size: 0.9em">
                        {{ item.analysis_status }}
                      </p>
                    </td>
                    <td>
                      <v-btn small color="primary" @click="rowSelect(item)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

import { requiredValidation } from "@/helpers/validations.js";
import dayjs from "dayjs";

export default {
  data: () => ({
    disableDeleteNematodes: false,
    checkBoxesSelected: [],
    samples: [],
    sampleSelected: null,
    receptionSelected: null,
    search: null,
    headers: [
      {
        text: "ID análisis",
        value: "analysis_id",
      },
      {
        text: "Razón social",
        value: "client_company_name",
      },
      {
        text: "Tipo de muestra",
        value: "sampleType",
      },
      {
        text: "Tipo de análisis",
        value: "analysis_type",
      },
      {
        text: "Identificación",
        value: "identification",
      },
      {
        text: "Cantidad",
        value: "qty_strains",
      },
      {
        text: "Estatus del análisis",
        value: "analysis_status",
      },
      {
        text: "Editar nemátodo",
        value: "edit_nema",
      },
    ],
    nematodesList: [],
    strainList: [],
    loadingData: false,
    isLoading: false,
    isLoadingNematode: false,
    dialog: false,
    nematodeTestForm: {
      isChecked: false,
      recoveredWater: null,
      takenWater: null,
      totalNematodes: null,
      phytopathogenNematodes: null,
      soilSample: null,
      status: null,
    },
    identification: null,
    waterRecovered: null,
    waterTaken: null,
    totalNematode: null,
    pathogenicNematodes: null,
    quantityNematodes: null,
    nematodeSelected: null,
    dialogDeleteNematodes: false,
    disableFinishButton: false,
    selectedRow: null,
    user: JSON.parse(localStorage.getItem("user")),
  }),
  props: {
    analysis: {
      type: String,
      required: true,
    },
  },
  async created() {
    try {
      this.loadingData = true;
      let response = await this.getReceptionDetails({
        analysis: this.analysis,
        laboratory: this.user.laboratory._id,
      });
      if (response.error) {
        console.log(response.error);
        this.loadingData = false;
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Ocurrio un error al consultar analysis",
          timeout: 3500,
        });
      } else {
        let receptions = response.receptions;

        let samples = [];
        for (let item of receptions) {
          if (item.receptionDetail.sample_id.length > 0) {
            samples.push(item.receptionDetail.folio);
          }
        }

        this.samples = samples;
        if (samples.length > 0) {
          this.sampleSelected = samples[0];
          await this.changeSample();
        } else {
          this.loadingData = false;
        }
      }
    } catch (error) {
      this.loadingData = false;
      console.log(error);
      this.SET_ALERT_TYPE({
        visible: true,
        type: "error",
        text: "Ocurrio un error al consultar analysis",
        timeout: 3500,
      });
    }
  },
  computed: {
    ...mapState("identification", ["identificationsFounded"]),
    ...mapState("pqTest", ["containers"]),
  },
  methods: {
    ...mapActions("identification", [
      "searchIdentifications",
      "addNematodeIdentification",
    ]),
    ...mapActions("pqTest", ["getReceptionDetails"]),
    ...mapActions("nematodes", [
      "createNematode",
      "updateNematode",
      "deleteNematode",
      "finishNematodes",
      "reverthNematodes",
    ]),
    ...mapActions("strain", [
      "addStrain",
      "updateStrain",
      "getNematodeStrainsBySample",
    ]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async changeSample() {
      if (this.sampleSelected != null) {
        for (let reception of this.containers) {
          if (reception.receptionDetail.folio == this.sampleSelected) {
            let dataSample = {
              clientName: reception.receptionDetail.client_name,
              areaName: reception.receptionDetail.area_name,
              cropCycleName: this.getCrop(reception.receptionDetail.cropCycle),
              cropCycle: reception.receptionDetail.cropCycle,
              subSamples: reception.receptionDetail.sub_sample,
              areaTargets: reception.receptionDetail.area_targets,
              qrCode: reception.receptionDetail.qr_code,
              samplerName: reception.receptionDetail.sampler_name,
              samplingDate: reception.receptionDetail.sampling_date,
              receptionDate: reception.receptionDetail.reception_date,
              sampleType: this.getSampleType(
                reception.receptionDetail.sample_type
              ),
              sampleTracingStatus: this.getSampleTracingStatus(
                reception.receptionDetail.sample_tracing_status
              ),
              sampleStatus: this.getStatus(
                reception.receptionDetail.sample_status
              ),
              soilSample: {
                soilArea: reception.receptionDetail.area_polygon,
                subSamples: reception.receptionDetail.sub_sample,
              },
              sampleSelected: this.sampleSelected,
            };
            this.nematodeTestForm.soilSample =
              reception.receptionDetail.sample_id;
            this.receptionSelected = reception.receptionDetail;
            this.$emit("sampleChangedNematode", dataSample);

            //get nematode strain
            let response = await this.getNematodeStrainsBySample(
              reception.receptionDetail.sample_id
            );
            if (response.error) {
              throw response.error;
            } else {
              this.nematodesList = [];
              this.strainList = [];
              if (response.STRAINS.length > 0) {
                this.strainList = response.STRAINS;
                for (let item of response.STRAINS) {
                  if (item.nematodeTest != null) {
                    let templateSampleType = "";
                    templateSampleType += this.getSampleType(
                      reception.receptionDetail.sample_type
                    );
                    templateSampleType += " ";
                    templateSampleType += this.getSampleTracingStatus(
                      reception.receptionDetail.sample_tracing_status
                    );

                    let analysisStatusTemplate = "";
                    if (typeof item.nematodeTest.status != "undefined") {
                      let status = this.getNematodeStatus(
                        item.nematodeTest.status
                      );
                      analysisStatusTemplate += `${status} - `;
                    } else {
                      analysisStatusTemplate += `Identificado - `;
                    }

                    analysisStatusTemplate += this.dateFormat(
                      item.nematodeTest.updatedAt
                    );

                    this.nematodesList.push({
                      analysis_id: this.sampleSelected,
                      strain_id: item._id,
                      identification: item.identification.name,
                      qty_strains: item.identifiedNematodes,
                      sampleType: templateSampleType,
                      client_company_name:
                        reception.receptionDetail.client_company_name,
                      analysis_type: "Nematodos",
                      analysis_status: analysisStatusTemplate,
                    });
                  }
                }
                this.checkBoxesSelected = [];
              } else {
                this.disableFinishButton = false;
              }

              this.loadingData = false;
            }
            break;
          }
        }
      } else {
        let dataSample = {
          clientName: "",
          areaName: "",
          cropCycleName: "",
          subSamples: [],
          areaTargets: 0,
          qrCode: "",
          samplerName: "",
          samplingDate: null,
          receptionDate: null,
          sampleType: "",
          sampleTracingStatus: "",
          sampleStatus: "",
          soilSample: {
            soilArea: {
              polygon: [],
              location: {},
            },
            subSamples: [],
          },
          sampleSelected: this.sampleSelected,
        };
        this.loadingData = false;
        this.$emit("sampleChangedNematode", dataSample);
      }
    },
    changeIdentificationValue() {
      if (!this.search) {
        this.identification = null;
      }
    },
    validation(name) {
      return requiredValidation(name);
    },
    clearForm() {
      this.identification = null;
      this.waterRecovered = null;
      this.waterTaken = null;
      this.totalNematode = null;
      this.pathogenicNematodes = null;
      this.quantityNematodes = null;
      this.dialog = false;
      this.nematodeSelected = null;
      this.strainSelected = null;
      this.nematodeTestForm.recoveredWater = null;
      this.nematodeTestForm.takenWater = null;
      this.nematodeTestForm.totalNematodes = null;
      this.nematodeTestForm.phytopathogenNematodes = null;
    },
    async addNematode() {
      try {
        if (this.$refs.nematodeForm.validate()) {
          this.isLoadingNematode = true;
          //add new nematode
          let identification = await this.getIdentification();

          let responseNematode = await this.createOrUpdateNematodeTest();

          let responseStrain = await this.createOrUpdateStrain(
            responseNematode.NEMATODE._id,
            identification._id
          );

          if (!responseStrain.error) {
            this.strainList.push(responseStrain.STRAIN);
            this.SET_ALERT_TYPE({
              visible: true,
              type: "success",
              text: "Nematode added successfully!!",
              timeout: 3500,
            });

            await this.changeSample();

            // if (this.strainSelected == null) {
            //     if (responseStrain.STRAIN.nematodeTest != null) {
            //         let templateSampleType = "";
            //         templateSampleType += this.getSampleType(
            //             this.receptionSelected.sample_type
            //         );
            //         templateSampleType += " ";
            //         templateSampleType += this.getSampleTracingStatus(
            //             this.receptionSelected.sample_tracing_status
            //         );

            //         let analysisStatusTemplate = "";
            //         if (
            //             typeof responseStrain.STRAIN.nematodeTest.status !=
            //             "undefined"
            //         ) {
            //             let status = this.getNematodeStatus(
            //                 responseStrain.STRAIN.nematodeTest.status
            //             );
            //             analysisStatusTemplate += `${status} - `;
            //         } else {
            //             analysisStatusTemplate += `Identificado - `;
            //         }

            //         analysisStatusTemplate += this.dateFormat(
            //             responseStrain.STRAIN.nematodeTest.updatedAt
            //         );

            //         this.nematodesList.push({
            //             analysis_id: this.sampleSelected,
            //             strain_id: responseStrain.STRAIN._id,
            //             identification: responseStrain.STRAIN.identification.name,
            //             qty_strains: responseStrain.STRAIN.identifiedNematodes,
            //             sampleType: templateSampleType,
            //             client_company_name: this.receptionSelected.client_company_name,
            //             analysis_type: "Nematodos",
            //             analysis_status: analysisStatusTemplate,
            //         });
            //     }
            // } else {
            //     for (let item of this.nematodesList) {
            //         if (item.strain_id == responseStrain.STRAIN._id) {
            //             item.qty_strains = responseStrain.STRAIN.identifiedNematodes;
            //             let index = 0;
            //             for (let strain of this.strainList) {
            //                 if (strain._id == responseStrain.STRAIN._id) {
            //                     this.strainList[index] = responseStrain.STRAIN;
            //                     break;
            //                 }
            //                 index++;
            //             }
            //             break;
            //         }
            //     }
            // }

            this.isLoadingNematode = false;
            this.clearForm();
          } else {
            throw responseStrain;
          }
        }
      } catch (error) {
        this.isLoadingNematode = false;
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: error.message,
          timeout: 3500,
        });
      }
    },
    //get identification if exist if not creat a new one
    async getIdentification() {
      try {
        if (typeof this.identification !== "object" && this.search) {
          // New.
          this.identification = {
            name: this.search,
            moType: "nematode",
          };
          const response = await this.addNematodeIdentification(
            this.identification
          );
          if (response.error) throw response;
          return response.IDENTIFICATION;
        } else {
          // identification selected
          return this.identification;
        }
      } catch (e) {
        console.log("Error on getIdentificationId", e);
        return e;
      }
    },
    // Create nematode test if it doesn't exist.
    async createOrUpdateNematodeTest() {
      try {
        if (this.nematodeSelected == null) {
          // Create it.
          this.nematodeTestForm.status = "IDENTIFYING";
          const response = await this.createNematode(this.nematodeTestForm);
          if (response.error) throw response;
          return response;
        } else {
          // Update it.
          let payload = {
            nematodeId: this.nematodeSelected._id,
            nematode: this.nematodeTestForm,
          };
          const response = await this.updateNematode(payload);
          if (response.error) throw response;
          return response;
        }
      } catch (e) {
        console.log("Error on createOrUpdateNematodeTest", e);
        return e;
      }
    },
    async createOrUpdateStrain(nematodeTestId, identificationId) {
      try {
        if (this.strainSelected == null) {
          // Create it.
          let strain = {
            soilSample: this.nematodeTestForm.soilSample,
            identification: identificationId,
            identifiedNematodes: this.quantityNematodes,
            nematodeTest: nematodeTestId,
          };
          const response = await this.addStrain(strain);
          if (response.error) throw response;
          return response;
        } else {
          // Update it.
          let strain = {
            soilSample: this.nematodeTestForm.soilSample,
            identification: identificationId,
            identifiedNematodes: this.quantityNematodes,
            nematodeTest: nematodeTestId,
          };

          let payload = {
            strainId: this.strainSelected._id,
            strain: strain,
          };
          const response = await this.updateStrain(payload);
          if (response.error) throw response;
          return response;
        }
      } catch (e) {
        console.log("Error on createOrUpdateStrain", e);
        return e;
      }
    },
    getStatus(value) {
      const status = {
        VALIDATED: "VALIDADA",
        RECEIVED: "RECIBIDA",
        MEASURING: "MIDIENDO",
        MEASURED: "MEDIDA",
        VALUATING: "VALUANDO",
        VALUATED: "VALUADA",
        COMPLETED: "COMPLETADA",
      };
      if (value.length > 0) {
        return status[value];
      } else {
        return "- - - -";
      }
    },
    getCrop(cropCycleName) {
      let template = "";
      const crop = {
        olive: "Aceituna",
        chard: "Acelga",
        achiote: "Achiote",
        agave: "Agave",
        avocado: "Aguacate",
        garlic: "Ajo",
        sesame: "Ajonjolí",
        basil: "Albahaca",
        artichoke: "Alcachofa",
        alcatraz: "Alcatraz",
        shrunkenAlfalfa: "Alfalfa achicalada",
        greenAlfalfa: "Alfalfa verde",
        cotton: "Algodón",
        almond: "Almendro",
        birdseed: "Alpiste",
        amaranth: "Amaranto",
        anise: "Anís",
        celery: "Apio",
        blueberry: "Arándano",
        myrtle: "Arrayán",
        rice: "Arroz",
        greenForageOats: "Avena forrajera en verde",
        oatGrain: "Avena grano",
        bamboo: "Bambú",
        eggplant: "Berenjena",
        beetroot: "Betabel",
        broccoli: "Brocoli",
        peanut: "Cacahuate",
        cocoa: "Cacao",
        cherryCoffee: "Café cereza",
        pumpkin: "Calabaza",
        sweetPotato: "Camote",
        sugarCane: "Caña de azúcar",
        canola: "Canola",
        carambolo: "Carambolo",
        safflower: "Cártamo",
        barley: "Cebada",
        greenForageBarley: "Cebada forrajera en verde",
        onion: "Cebolla",
        chives: "Cebollín",
        ryeGrain: "Centeno grano",
        cherry: "Cereza",
        chabacano: "Chabacano",
        pea: "Chícharo",
        squash: "Chayote",
        chia: "Chía",
        chilacayote: "Chilacayote",
        chillyPepper: "Chile / Pimiento",
        custardApple: "Chirimoya",
        coriander: "Cilantro",
        plum: "Ciruela",
        coconutFruit: "Coco fruta",
        cabbage: "Col (repollo)",
        brusselsSprouts: "Col de Bruselas",
        cauliflower: "Coliflor",
        cumin: "Comino",
        chrysanthemum: "Crisantemo (planta)",
        peach: "Durazno",
        date: "Dátil",
        greenBean: "Ejote",
        cornCob: "Elote",
        dill: "Eneldo",
        epazote: "Epazote",
        asparagus: "Espárrago",
        spicesAndMedicinals: "Especias y medicinales",
        spinach: "Espinaca",
        raspberry: "Frambuesa",
        strawberry: "Fresa",
        bean: "Frijol",
        chickpea: "Garbanzo",
        sunflower: "Girasol",
        pomegranate: "Granada",
        soursop: "Guanábana",
        guava: "Guayaba",
        henequen: "Henequén",
        peppermint: "Hierbabuena",
        aromaticHerbs: "Hierbas aromáticas",
        fig: "Higo",
        jamaica: "Jamaica",
        ginger: "Jenjibre",
        jicama: "Jícama",
        lettuce: "Lechuga",
        lentil: "Lenteja",
        lime: "Lima",
        lemon: "Limón",
        linseed: "Linaza",
        litchi: "Litchi",
        corn: "Maíz",
        macadamia: "Macadamia",
        maguey: "Maguey",
        mamey: "Mamey",
        tangerine: "Mandarina",
        mango: "Mango",
        mangosteen: "Mangostán",
        chamomile: "Manzanilla",
        appleTree: "Manzano",
        passionFruit: "Maracuyá",
        daisyFlower: "Margarita",
        marjoram: "Mejorana",
        melon: "Melón",
        quince: "Membrillo",
        mint: "Menta",
        moringa: "Moringa",
        turnip: "Nabo",
        nanche: "Nanche",
        napa: "Napa",
        orange: "Naranja",
        medlar: "Níspero",
        nochebuena: "Nochebuena (planta)",
        walnut: "Nogal",
        noni: "Noni",
        nopal: "Nopal",
        oregano: "Orégano",
        ornamental: "Ornamentales",
        orchid: "Orquídea (planta)",
        oilPalm: "Palma africana o de aceite",
        potato: "Papa",
        papaya: "Papaya",
        grasslands: "Pastizales",
        matGrass: "Pasto tapete (m2)",
        pasturesAndMeadows: "Pastos y praderas",
        cucumber: "Pepino",
        pearTree: "Peral",
        parsley: "Perejil",
        peron: "Perón",
        persimony: "Persimonio",
        pepper: "Pimienta",
        pineapple: "Piña",
        pinion: "Piñón",
        pistache: "Pistache",
        pitaya: "Pitaya",
        banana: "Plátano",
        pore: "Poro",
        radish: "Rábano",
        rambutan: "Rambután",
        romerito: "Romerito",
        rosemary: "Romero",
        aloe: "Sábila",
        watermelon: "Sandía",
        sorghum: "Sorgo",
        soy: "Soya",
        stevia: "Stevia",
        tobacco: "Tabaco",
        tamarind: "Tamarindo",
        tejocote: "Tejocote",
        tomato: "Tomate Rojo (jitomate)",
        greenTomato: "Tomate Verde",
        thyme: "Tomillo",
        grapefruit: "Toronja (pomelo)",
        clover: "Trébol",
        wheat: "Trigo",
        dutchTulip: "Tulipán holandés",
        tuna: "Tuna",
        grape: "Uva",
        vanilla: "Vainilla",
        purslane: "Verdolaga",
        vine: "Vid",
        cassavaFood: "Yuca alimenticia",
        carrot: "Zanahoria",
        sapodilla: "Zapote",
        blackberry: "Zarzamora",
        zempoalxochitl: "Zempoalxochitl",
        fallow: "Barbecho",
        other: "Otro",
      };
      if (cropCycleName !== null) {
        let cropName = crop[cropCycleName.name];
        if (typeof cropName != "undefined") {
          template += cropName;
          if (cropCycleName.variety.length > 0) {
            template += `(${cropCycleName.variety})`;
          }
        } else {
          template = "- - - -";
        }
      } else {
        template = "- - - -";
      }
      return template;
    },
    getSampleType(value) {
      let template = "";
      const sampleType = {
        CLASSIC: "CLASICO",
        MAS: "MAS",
      };
      if (value.length > 0) {
        if (value === "MAS") {
          template = sampleType[value];
        } else {
          template = "";
        }
      } else {
        template = "";
      }

      return template;
    },
    getSampleTracingStatus(value) {
      let template = "";
      if (value === "Monitoring" || value === "MONITORING") {
        template += " SEGUIMIENTO";
      } else {
        template = " INICIAL";
      }
      return template;
    },
    dateFormat(value) {
      const date = `${dayjs(value).format("DD-MM-YYYY - HH:mm")} hrs`;
      return date ? date : "----";
    },
    getNematodeStatus(value) {
      if (value == "IDENTIFYING") {
        this.disableFinishButton = false;
        return "Identificando";
      } else {
        this.disableFinishButton = true;
        return "Identificado";
      }
    },
    async deleteNematodes() {
      try {
        let strainId = [];
        this.checkBoxesSelected.forEach((item, index) => {
          strainId.push(item.strain_id);
        });
        let response = await this.deleteNematode(strainId);
        if (response.error) throw response;

        this.checkBoxesSelected.forEach((item, index) => {
          this.removeItemOnce(this.nematodesList, item);
        });

        this.checkBoxesSelected = [];
        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: "Nematodos eliminados exitosamente!!",
          timeout: 3500,
        });
      } catch (e) {
        console.log(e);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Error al cargar tabala de nematodos.",
          timeout: 3500,
        });
      }

      this.dialogDeleteNematodes = false;
    },
    removeItemOnce(arr, value) {
      var index = arr.indexOf(value);
      if (index > -1) {
        arr.splice(index, 1);
      }
      return arr;
    },
    async finishNematodeCount() {
      try {
        let response = await this.finishNematodes({
          sampleId: this.receptionSelected.sample_id,
        });
        if (response.error) throw response;

        await this.changeSample();

        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: "Conteo de nematodos finalizado!!",
          timeout: 3500,
        });

        this.disableFinishButton = true;
        this.checkBoxesSelected = [];
      } catch (e) {
        console.log(e);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Error al finalizar conteo",
          timeout: 3500,
        });
      }
    },
    async revertNematodeCount() {
      try {
        let response = await this.reverthNematodes({
          sampleId: this.receptionSelected.sample_id,
        });
        if (response.error) throw response;

        await this.changeSample();

        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: "Conteo de nematodos finalizado!!",
          timeout: 3500,
        });

        this.disableFinishButton = false;
        this.checkBoxesSelected = [];
      } catch (e) {
        console.log(e);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Error al finalizar conteo",
          timeout: 3500,
        });
      }
    },
    rowSelect(rowValue) {
      this.selectedRow = rowValue.strain_id;
      // if (!this.disableFinishButton) {
      for (let strain of this.strainList) {
        if (this.selectedRow === strain._id) {
          this.nematodeSelected = strain.nematodeTest;
          this.strainSelected = strain;
          this.nematodeTestForm = {
            isChecked: false,
            recoveredWater: strain.nematodeTest.recoveredWater,
            takenWater: strain.nematodeTest.takenWater,
            totalNematodes: strain.nematodeTest.totalNematodes,
            phytopathogenNematodes: strain.nematodeTest.phytopathogenNematodes,
            status: "IDENTIFYING",
            soilSample: this.receptionSelected.sample_id,
          };
          this.quantityNematodes = strain.identifiedNematodes;
          this.identification = strain.identification;
          this.dialog = true;
          break;
        }
      }
      // }
    },
    searchNematode() {
      this.dialog = true;
      try {
        if (this.strainList.length > 0) {
          let defaultValues = this.strainList[0];
          this.nematodeTestForm.isChecked = false;
          this.nematodeTestForm.recoveredWater =
            defaultValues.nematodeTest.recoveredWater;
          this.nematodeTestForm.takenWater =
            defaultValues.nematodeTest.takenWater;
          this.nematodeTestForm.totalNematodes =
            defaultValues.nematodeTest.totalNematodes;
          this.nematodeTestForm.phytopathogenNematodes =
            defaultValues.nematodeTest.phytopathogenNematodes;
          this.quantityNematodes = defaultValues.identifiedNematodes;
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  watch: {
    async search(value) {
      if (value) {
        this.isLoading = true;
        try {
          const response = await this.searchIdentifications({
            name: value,
            moType: "nematode",
          });
          if (response.error) throw response;
        } catch (e) {
          console.log(e);
        }
        this.isLoading = false;
        // Validate error text
        if (this.errorText && this.search) {
          this.errorText = false;
        }
      }
    },
    checkBoxesSelected(value) {
      if (value.length > 0) {
        this.disableDeleteNematodes = true;
      } else {
        this.disableDeleteNematodes = false;
      }
    },
  },
};
</script>

<style scoped>
.custom-highlight-row {
  background-color: rgba(4, 193, 5, 0.13);
}
</style>
