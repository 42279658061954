<template>
  <v-container class="pt-0">
    <v-row
      v-if="wellsSelected.length > 0"
      class="my-0"
      align="center"
      justify="center"
    >
      <v-col cols="9" class="py-0">
        <v-combobox
          v-model="wellsSelected"
          :items="wellsSelected"
          label="Muestras seleccionadas en etapa de Elución"
          multiple
          small-chips
          readonly
        >
           <template v-slot:selection="data">
            <v-chip
              :key="JSON.stringify(data.item)"
              :color="data.item.sampleType.includes('VEGETABLE') ? 'primary' : 'warning' "
              small
            >
              <!-- <v-avatar
                class="accent white--text"
                left
                v-text="data.item.slice(0, 1).toUpperCase()"
              ></v-avatar> -->
              {{ data.item.folioAnalysis }} {{data.item.replica != '1' ? `(${data.item.replica})` : ''}}
            </v-chip>
          </template>
        </v-combobox>
      </v-col>
      <v-col cols="1" class="py-0">
        <v-btn icon block color="secondary" @click="clearSamples()"><v-icon>mdi-trash-can</v-icon></v-btn>
      </v-col>
      <v-col cols="2" class="py-0">
        <v-text-field
          v-model="reactionNumber"
          name="Reacciónes"
          label="Reacciónes"
          :rules="[reactionNumbersValidation]"
          :disabled="wellsSelected.length == 0"
        ></v-text-field>
      </v-col>
      <v-col cols="4" class="py-0">
        <v-btn
          color="primary"
          block
          small
          :disabled="wellsSelected.length == 0 || !reactionsValid"
          :loading="loading"
          @click="setAmplificationData"
          >Asignar muestras</v-btn
        >
      </v-col>
    </v-row>
    <v-row align="center" justify="center" v-else>
      <v-col cols="12" class="px-0 pb-0">
        <v-sheet class="mx-auto px-0" elevation="0" max-width="800">
          <v-slide-group
            v-model="reactionSelected"
            class="pa-1"
            active-class="success"
            mandatory
            show-arrows
          >
            <v-slide-item v-for="reaction, index in platesReaction" :key="index" v-slot="{ active, toggle }">
              <v-card
                :color="active ? undefined : 'grey lighten-1'"
                class="ma-1"
                height="100"
                width="95"
                @click="toggle"
              >
                <v-card-text class="white--text px-2 text-center" @click="formatPlatesReaction(reaction)" style="height: 100% !important;">
                  Reacción {{index+1}}
                  <p v-for="plate, indexPlate in reaction" :key="'Reaction'+indexPlate" class="text-left mb-0">▶ {{plate.folio}}</p>
                </v-card-text>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </v-col>
      <v-col cols="12" class="text-center py-0">
        <Plate v-if="analysis && platesReactionSelected.length > 0" :analysis="analysis.folio" :plates="platesReactionSelected" :reaction="(reactionSelected + 1)" type="AMPLIFICATION"/>
        <span v-else>No hay resultados</span>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Plate from "@/components/reception/plates/Plate.vue";
export default {
  components: { Plate },
  data: () => ({
    selected: [],
    model: null,
    isLoading: false,
    reactionNumber: '1',
    loading: false,
    platesReaction: [],
    reactionSelected : 0,
    platesReactionSelected : [],
    user: JSON.parse(localStorage.getItem("user")),
    reactionsValid : true
  }),
  computed: {
    ...mapState("molecularPlateWell", ["wellsSelected"]),
    reactionsNumbers(){
      const validation = /^[0-9]+([,][0-9]+|[,] [0-9]+)*$/;
      const valid = validation.test(this.reactionNumber);

      if(valid){
        
        if(this.reactionNumber.includes(',')){

          let reactionsValid = true;
          const reactions = this.reactionNumber.split(',').map(v => v.trim());

          const hasDup = this.hasDuplicates(reactions);

          if(hasDup){
            return []
          }

          for (const react of reactions) {
            const reactionNumber = parseInt(react);

            if(reactionNumber > 0 && reactionNumber < 14){
              reactionsValid = true;
            }else{
              reactionsValid = false;
              break;
            }
          }

          if(!reactionsValid){
            return []
          }

          return reactions.map(r => parseInt(r));
        }else{
          const reactionNumber = parseInt(this.reactionNumber.trim());

          if(reactionNumber > 0 && reactionNumber < 14){
            return [reactionNumber];
          }else{
            return []
          }
          
        }
        
      }else{
        return []
      }
    }
  },
  props: {
    analysis: {
      type: Object,
      require: true,
    },
    tabActive: {
      type: Number,
      require: true,
    },
    samples: {
      type: Array,
      require: true,
    },
  },
  methods: {
    ...mapActions("molecularPlateWell", [
      "getPlatesStats",
      "getAmplificationPlatesBySamples",
    ]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapMutations("molecularPlateWell", ["SET_WELLS_SELECTED"]),
    async setAmplificationData() {
      this.loading = true;
      try {

        for (const reactionNumber of this.reactionsNumbers) {
          let samples = this.filterSamplesByReaction(reactionNumber);
          let hasVM = samples.find(sample => sample.sampleType.includes('VEGETABLE'))

          if(samples.length > 0){
            let requestBody = {
              plateType: "AMPLIFICATION",
              reaction: reactionNumber,
              samples: samples.map((well) => {
                return {
                  _id : well.soilSample._id,
                  replica : well.replica
                };
              }),
              laboratory : this.user.laboratory._id,
              hasVM : hasVM ? true : false
            };

            let request = await this.getPlatesStats(requestBody);

            if (request.error) throw request;

            if(reactionNumber == this.reactionsNumbers[this.reactionsNumbers.length - 1]){
              this.formatPlates(request.plates);

              await this.getAmplificationPlates();

              this.SET_ALERT_TYPE({
                visible: true,
                type: "success",
                text: 'Muestras asignadas correctamente.',
                timeout: 4000,
              });

              this.SET_WELLS_SELECTED([]);
              this.reactionNumber = '1';
            }
          }else{
            this.SET_ALERT_TYPE({
              visible: true,
              type: "error",
              text: `No hay muestras para asignar a la reacción ${reactionNumber}.`,
              timeout: 3000,
            });
          }
        }
        
      } catch (error) {
        console.error(error)
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: error.message,
          timeout: 4000,
        });
      }

      this.loading = false;
    },
    async getAmplificationPlates() {
      try {
        let request = await this.getAmplificationPlatesBySamples({
          plateType: "AMPLIFICATION",
          samples: this.samples.map((sample) => {
            return sample._id;
          }),
          analysis: this.analysis._id,
        });

        if (request.error) throw request;

        this.platesReaction = request.platesReaction;

        this.formatPlatesReaction(request.platesReaction[0])

        // console.log(request);
      } catch (error) {
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: error.message,
          timeout: 4000,
        });
      }
    },
    formatPlates(plates) {
      const plateRows = {
        0: "A",
        1: "B",
        2: "C",
        3: "D",
        4: "E",
        5: "F",
        6: "G",
        7: "H",
      };

      let platesResult = [];
      for (const plate of plates) {
        let plateResult = [];
        // 8 number of rows
        for (let rowIndex = 0; rowIndex < 8; rowIndex++) {
          let rowLetter = plateRows[rowIndex];
          // 12 number of columns

          let rowData = {};
          for (let columnIndex = 0; columnIndex < 6; columnIndex++) {
            let wellDB = plate.wells.find(
              (well) =>
                well.wellPlate[0] == columnIndex + 1 &&
                well.wellPlate[1] == rowLetter
            );
            let wellData = {
              plateFolio: plate.folio,
              type: plate.type,
              position: [columnIndex + 1, rowLetter],
              isWater : false
            };

            if (wellDB) {
              if(wellDB.soilSample != null){
                wellData.folioAnalysis = wellDB.soilSample ? wellDB.soilSample.folioAnalysis : null;
                wellData.sampleType = wellDB.soilSample ? wellDB.soilSample.sampleType : null;
                wellData.soilSample = wellDB.soilSample ? wellDB.soilSample : null;
                wellData.replica = wellDB.replica ? wellDB.replica : null;
              }else{
                wellData.folioAnalysis = null
                wellData.sampleType = null
                wellData.control = wellDB.control ? wellDB.control : null;
                wellData.isWater = wellDB.soilSample == null;
              }
              
              wellData.reaction = wellDB.reaction
            } else {
              wellData.folioAnalysis = null;
              wellData.sampleType = null;
              wellData.soilSample = null;
              wellData.reaction = 0;
            }

            rowData[columnIndex] = { ...wellData };
          }

          plateResult.push(rowData);
        }

        platesResult.push(plateResult);
      }

      return platesResult;
    },
    formatPlatesReaction(plates) {
      const plateRows = {
        0: "A",
        1: "B",
        2: "C",
        3: "D",
        4: "E",
        5: "F",
        6: "G",
        7: "H",
      };

      this.platesReactionSelected = []
      let platesResult = [];
      for (const plate of plates) {
        let plateResult = []
        // 8 number of rows
        for (let rowIndex = 0; rowIndex < 8; rowIndex++) {
          let rowLetter = plateRows[rowIndex];
          // 12 number of columns

          let rowData = {}
          for (let columnIndex = 0; columnIndex < 12; columnIndex++) {
            let wellDB = plate.wells.find(
              (well) =>
                well.wellPlate[0] == columnIndex + 1 &&
                well.wellPlate[1] == rowLetter
            );
            let wellData = {
              plateFolio : plate.folio,
              type : plate.type,
              position : [(columnIndex + 1), rowLetter],
              disabled : columnIndex != 0 && columnIndex != 6 ? true : false,
              isWater : false
            }

            if (wellDB) {
              if(wellDB.soilSample != null){
                wellData.folioAnalysis = wellDB.soilSample ? wellDB.soilSample.folioAnalysis : null;
                wellData.sampleType = wellDB.soilSample ? wellDB.soilSample.sampleType : null;
                wellData.control = null;
                wellData.replica = wellDB.replica ? wellDB.replica : null;
              }else{
                wellData.folioAnalysis = null;
                wellData.sampleType = null;
                wellData.control = wellDB.control != undefined ? wellDB.control : null;
                wellData.isVegetal = wellDB.isVegetal;
                wellData.isWater = wellDB.control == undefined ? true : false;
              }
              
              wellData.reaction = wellDB.reaction
            } else {
              wellData.folioAnalysis = null
              wellData.sampleType = null
              wellData.reaction = 0
            }

            rowData[columnIndex] = {...wellData}
          }

          plateResult.push(rowData)
        }

        platesResult.push(plateResult)
      }

      this.platesReactionSelected = platesResult
      // console.log(platesResult)
    },
    filterSamplesByReaction(reactionNumber){
      let samples = []
      // Vegetable & soil samples
      if(reactionNumber > 0 && reactionNumber < 4){

        samples = this.wellsSelected
        // samples = this.wellsSelected.filter(well => well.sampleType.includes('VEGETABLE'))

      // Soil sample
      }else if(reactionNumber > 3 && reactionNumber < 14){
        samples = this.wellsSelected.filter(well => !well.sampleType.includes('VEGETABLE'))
      }
      // console.log(samples)
      return samples
    },
    async clearSamples(){
      this.SET_WELLS_SELECTED([])
      await this.getAmplificationPlates();
    },

    reactionNumbersValidation(value) {
      const validation = /^[0-9]+([,][0-9]+|[,] [0-9]+)*$/;
      const valid = validation.test(value);

      this.reactionsValid = true;

      if(valid){
        
        if(value.includes(',')){
          
          let reactionsValid = true;
          const reactions = value.split(',').map(v => v.trim());

          const hasDup = this.hasDuplicates(reactions);

          if(hasDup){
            return 'Hay números duplicados';
          }

          for (const react of reactions) {
            const reactionNumber = parseInt(react);

            if(reactionNumber > 0 && reactionNumber <= this.platesReaction.length){
              reactionsValid = true;
            }else{
              reactionsValid = false;
              break;
            }
          }

          if(!reactionsValid){
            this.reactionsValid = false;
            return 'Núm. de reacción inválido';
          }

          return true;
        }else{
          const reactionNumber = parseInt(value);

          if(reactionNumber > 0 && reactionNumber <= this.platesReaction.length){
            return true;
          }else{
            this.reactionsValid = false;
            return 'Ingresa un número válido'
          }
          
        }
        
      }else{
        this.reactionsValid = false;
        return 'Ingresa caracteres válidos'
      }
    },
    hasDuplicates(arr) {
      return new Set(arr).size !== arr.length;
    }
  },
  watch: {
    async tabActive() {
      if (this.tabActive == 3) {
        await this.getAmplificationPlates();
      }
    },
  },
};
</script>

<style>
</style>