import axios from "axios";


/**
 * Function to get only biochemical tests and isolates (controls)
 * @param  {function} {commit} - Vuex function
 * @param {Object} { rootState } - Vuex store object
 * @param {payload} payload - laboratory, skip, limit, status, search
 * @returns {data} -  Returns array of mbtest
 */
 export async function getBCTestIsolatesListS3({ commit, rootState }, payload) {
  try {
    const { data } = await axios.get("/biochemicalTestsS3/laboratory", {
      params: payload,
      cancelToken: rootState.cancelToken && rootState.cancelToken.token,
    });
    if (data.error) throw data;
    commit("SET_BCTEST_LIST", data.MB_TESTS);
    commit("SET_COUNT", data.COUNT);
  } catch (e) {
    return e.response ? e.response.data : e;
  }
}

/**
 * Function to create a new biochemical test
 * @param {Object} { commit, dispatch } - vuex functions
 * @param {Object} bcTest - biochemical test info
 */
export async function addBCTest({ commit, dispatch }, bcTest) {
  try {
    const { data } = await axios.post(`/biochemicalTest/add`, bcTest);
    if (data.error) throw data;
    commit("ADD_TO_LIST", data.biochemicalTest);
    const response = await dispatch(
      "getBiochemicalTestById",
      data.biochemicalTest._id
    );
    if (response.error) throw response;
    return data;
  } catch (e) {
    return e.response.data;
  }
}
/**
 * Function to get biochemical tests by sample
 * @param {Object} { commit } - vuex function
 * @param {Object} { sampleId, pagination } - id Sample and pagination object
 * @returns biochemical tests array
 */
export async function getBiochemicalTests({ commit }, { filters }) {
  try {
    const { data } = await axios.get("/biochemicalTest", {
      params: {
        filters,
      },
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}
/**
 * Function to get a biochemical test by id
 * @param {Object} { commit } - vuex function
 * @param {String} id - id biochemical test
 */
export async function getBiochemicalTestById({ commit, rootState }, id) {
  try {
    const { data } = await axios.get(`/biochemicalTest/${id}`);
    if (data.error) throw data;
    commit("SET_BCTEST_SELECTED", data.biochemicalTest);

    if (
      data.biochemicalTest.type === "Biostimulation-cellulolyticActivity" &&
      data.biochemicalTest.isolate
    ) {
      commit(
        "bioStimulation/SET_BIOSTIMULATION_NEGATIVE_CONTROL",
        data.biochemicalTest.isolate,
        {
          root: true,
        }
      );
    }

    if (
      data.biochemicalTest.type.includes("Nutrition") &&
      data.biochemicalTest.isolate
    ) {
      commit(
        "nutrition/SET_NUTRITION_NEGATIVE_CONTROL",
        data.biochemicalTest.isolate,
        {
          root: true,
        }
      );
    }
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to update a biochemical test
 * @param {Object} { dispatch } - vuex function
 * @param {Object} bcTest - biochemical test info
 */
export async function updateBCTest({ dispatch }, bcTest) {
  try {
    const { data } = await axios.put(
      `/biochemicalTest/update/${bcTest._id}`,
      bcTest
    );
    if (data.error) throw data;
    const response = await dispatch(
      "getBiochemicalTestById",
      data.biochemicalTest._id
    );
    if (response.error) throw response;
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to upload the antagonism photo to aws s3
 * @param {Object} { commit } - vuex function
 * @param {Image} image - mother box antogonism photo
 * @returns image url
 */
export async function uploadPhoto({ commit }, image) {
  try {
    const response = await axios.post("/upload-image-updated", image);
    console.log("RESPONSE",response);
    const {data} = response
    if (data.error) throw data;
    return data.urlImg;
  } catch (error) {
    return error;
  }
}

/**
 * Function to get biochemical tests by group ofsample
 * @param {Object} { commit } - vuex function
 * @param {Object} payload - array of sample ids
 * @returns biochemical tests array
 */
export async function getBiochemicalTestsGroup({ commit }, { filters }) {
  try {
    const { data } = await axios.get("/biochemicalTestGroup", {
      params: {
        filters,
      },
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get if finalized biochemicals in valuation group
 * @param {Object} context - vuex function
 * @param {Object} valuationGroupId
 */
export async function getFinalizedBiochemicalTests(
  context,
  { valuationGroupId }
) {
  try {
    const { data } = await axios.get("/getFinalizedBiochemicalTests", {
      params: {
        valuationGroupId,
      },
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to interrupt a biochemical test
 * @param {Object} { commit } - vuex functions
 * @param {Object} bcTest - biochemical test info
 */
 export async function interruptTest({ commit }, bcTest) {
  try {
    const { data } = await axios.post(`/biochemicalTest/interrupt-incubation`, bcTest);
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}