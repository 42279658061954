<template>
  <v-dialog v-model="show" width="50vw" scrollable persistent>
    <v-card scrollable>
      <v-card-text class="px-0">
        <v-container>
          <v-row>
            <v-col>
              <span class="text-h5 font-weight-bold"> Origen de envió </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                outlined
                dense
                label="Selecciona origen de envió"
                :items="address"
                item-text="name"
                return-object
                v-model="addressSelected"
                clearable
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                dense
                label="Ingresa número de registro"
                type="number"
                v-model="serialNumber"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="addressSelected">
            <v-col>
              <span> Dirección seleccionada: </span>
            </v-col>
            <v-col cols="12" class="py-0">
              <span class="font-weight-bold">
                {{ addressSelected.value.name }}
              </span>
            </v-col>
            <v-col cols="12" class="py-0">
              <span class="font-weight-bold">
                {{ addressSelected.value.address }}
              </span>
            </v-col>
            <v-col cols="12" class="py-0">
              <span class="font-weight-bold">
                {{ addressSelected.value.location }}
              </span>
            </v-col>
            <v-col cols="12" class="py-0">
              <span class="font-weight-bold">
                {{ addressSelected.value.phone }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="warning" @click="show = false" :disabled="isLoading"
          >Cancelar</v-btn
        >
        <v-btn color="primary" @click="triggerFunction" :loading="isLoading" :disabled="!addressSelected || !serialNumber"
          >Aceptar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      address: [
        {
          name: "Mexicali",
          value: {
            name: " GRUPO SOLENA SAPI DE CV - CMA MEXICALI",
            address: "Calzada de las Americas 75, Cuauhtémoc Norte",
            location: "Mexicali, Baja California, 21200",
            phone: "+52 477 240 8108",
          },
        },
        {
          name: "Irapuato",
          value: {
            name: " GRUPO SOLENA SAPI DE CV",
            address:
              "Libramiento norte carretera León Irapuato km 9.6, CINVESTAV",
            location: "Irapuato, Guanajuato, 36821",
            phone: "+52 477-231-3838",
          },
        },
      ],
      addressSelected: null,
      isLoading: false,
      serialNumber:null,
    };
  },
  props: {
    value: Boolean,
    trigger: Function,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    async triggerFunction() {
      this.isLoading = true;
      this.addressSelected.serialNumber = this.serialNumber
      await this.trigger(this.addressSelected);
      this.isLoading = false;
      this.show = false;
    },
  },
};
</script>

<style scoped></style>
