<template>
  <v-card tile elevation="0" class="my-auto pb-1">
    <v-container>
      <!-- Photo row -->
      <v-row>
        <v-col cols="12" md="12">
          <template v-if="imageUrl == null">
            <v-img
              :src="require('../../assets/img/no_image_strain.png')"
              aspect-ratio="1.0"
              contain
            ></v-img>
            <v-row
              v-if="isolateDB && isolateDB.status == 'INCUBATING'"
              justify="center"
            >
              <v-col cols="8">
                <AddEvidencePhotograph
                  :imageUrl="urlOriginalImage"
                  :testMedia="control.idTest"
                  :testData="isolateDB"
                  testType="Control"
                  @imageUpdated="formatData()"
                />
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-img :src="imageUrl" aspect-ratio="1.0" contain></v-img>
            <v-row justify="center" align="center">
              <v-col cols="4">
                <EditMiniature
                  :imageUrl="urlOriginalImage"
                  @imageUpdated="formatData()"
                />
              </v-col>
              <v-col
                cols="4"
                v-if="isolateDB && isolateDB.status == 'IN_PROGRESS'"
              >
                <AddEvidencePhotograph
                  :imageUrl="urlOriginalImage"
                  :testMedia="control.idTest"
                  :testData="isolateDB"
                  testType="Control"
                  @imageUpdated="formatData()"
                />
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
      <template>
        <!-- Analisis data row -->
        <v-row class="px-10">
          <v-col cols="7">
            <p class="font-weight-bold text-left mb-1">
              Análisis {{ control.folioAnalysis }}
            </p>
          </v-col>
          <v-col cols="5">
            <!-- <p class="font-weight-bold text-right mb-1">INICIAL MS</p> -->
          </v-col>
        </v-row>
        <!-- Test data headers row -->
        <v-row class="mt-0 pr-2">
          <v-col cols="2" class="pr-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              ID ensayo
            </p>
          </v-col>
          <v-col cols="2" class="px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">Medio</p>
          </v-col>
          <v-col cols="3" class="px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              Inóculo
            </p>
          </v-col>
          <v-col cols="2" class="px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              Caja petri
            </p>
          </v-col>
          <v-col cols="3" class="px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              Tipo análisis
            </p>
          </v-col>
        </v-row>
        <!-- Test data row -->
        <v-row class="mt-0 pr-2">
          <v-col cols="2" class="pr-0">
            <p class="text-caption text-center mb-1">{{ control.idTest }}</p>
          </v-col>
          <v-col cols="2" class="px-0">
            <p class="text-caption text-center mb-1">{{ control.medium }}</p>
          </v-col>
          <v-col cols="3" class="px-0">
            <p class="text-caption text-center mb-1">
              {{ control.inoculum }}
            </p>
          </v-col>
          <v-col cols="2" class="px-0">
            <p
              class="text-caption text-center mb-1"
              v-if="!control.isolate.testMedia"
            >
              No disponible
            </p>
            <p
              class="text-caption text-center mb-1"
              v-else-if="
                control.isolate.testMedia.containerType == 'petriDish90mmx15mm'
              "
            >
              90x15 mm
            </p>
            <p
              class="text-caption text-center mb-1"
              v-else-if="
                control.isolate.testMedia.containerType == 'petriDish60mmx15mm'
              "
            >
              60x15 mm
            </p>
          </v-col>
          <v-col cols="3" class="px-0">
            <p class="text-caption text-center mb-1">Control</p>
          </v-col>
        </v-row>
        <v-form ref="form" v-model="form" lazy-validation>
          <v-row class="mt-0" align="center" justify="center">
            <v-col cols="4" class="pt-8">
              <p class="text-right secondary--text font-weight-bold">
                Crecimiento
              </p>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="growth"
                :rules="formRules"
                class="centered-input"
                type="number"
                :disabled="disableFields"
              ></v-text-field>
            </v-col>
            <v-col cols="3" class="pt-8">
              <p class="secondary--text font-weight-bold">mm</p>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <v-card-actions class="justify-center pt-1">
        <v-row class="pt-0" justify="center" align="center">
          <v-col cols="8">
            <v-btn
              depressed
              block
              color="secondary"
              :disabled="disableFields"
              @click="updateControl"
            >
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import ReseedingModal from "./modals/reseedingModal.vue";
import { mapActions, mapMutations } from "vuex";
import EditMiniature from "./modals/EditMiniature.vue";
import AddEvidencePhotograph from "./modals/AddEvidencePhotograph.vue";

export default {
  components: {
    ReseedingModal,
    EditMiniature,
    AddEvidencePhotograph,
  },
  data() {
    return {
      imageUrl: null,
      urlOriginalImage: "",
      growth: "-",
      disableFields: true,
      form: true,
      formRules: [(v) => !!v || "El campo es requerido"],
      isolateDB: null,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  props: {
    control: {
      type: Object,
      require: true,
    },
  },
  async created() {
    await this.formatData();
  },
  watch: {
    async control() {
      await this.formatData();
    },
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("isolate", ["updateIsolate", "getIsolateById"]),
    async updateControl() {
      if (this.$refs.form.validate()) {
        try {
          let test = {
            _id: this.isolateDB._id,
            isolateGrowth: this.growth,
            user: this.user._id,
            laboratory: this.user.laboratory._id,
          };

          const response = await this.updateIsolate(test);
          if (response.error) {
            throw response;
          }

          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: `Control actualizado correctamente`,
            timeout: 2000,
          });

          // this.$emit('controlUpdated')
        } catch (e) {
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: `Error al actualizar el control, contacta a soporte.`,
            timeout: 2000,
          });
        }
      }
    },
    async formatData() {
      let isolate = this.control.isolate;

      let requestIsolate = await this.getIsolateById({ id: isolate._id });
      console.log("CONTROL", requestIsolate.ISOLATE);
      // console.log("ISOLATE", isolate);
      this.isolateDB = requestIsolate.ISOLATE;

      this.controlItem = [
        {
          moType: "Control",
          mo: this.control.inoculum,
        },
      ];

      this.growth = this.isolateDB.isolateGrowth;
      this.disableFields =
        this.isolateDB.status == "IN_PROGRESS" ? false : true;
      if (this.isolateDB.incubation) {
        this.imageUrl = this.isolateDB.incubation.morphologyPhoto
          ? this.isolateDB.incubation.morphologyPhoto
          : null;
      }

      if (this.imageUrl) {
        this.urlOriginalImage = this.imageUrl;
        if (!this.imageUrl.includes("-small")) {
          this.imageUrl =
            this.imageUrl.slice(0, this.imageUrl.length - 4) +
            "-small.jpg?" +
            Date.now();
        } else {
          this.imageUrl = `${this.imageUrl}?${Date.now()}`;
          this.urlOriginalImage = `${this.imageUrl.split("-small")[0]}${
            this.imageUrl.split("-small")[1]
          }`;
        }
      }
    },
  },
};
</script>

<style scoped>
.v-data-table__wrapper > table > tbody > tr:hover {
  background: inherit !important;
}

.centered-input >>> input {
  text-align: center;
}
</style>
