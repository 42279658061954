<template>
  <div>
    <v-row>
      <v-col class="pa-1">
        <v-card flat class="overflow-y-auto" height="750">
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-icon color="primary" @click="closeDetail"
                  >mdi-keyboard-backspace</v-icon
                >
              </v-col>
              <v-col class="text-end" v-if="!projectSelected.isPackageUpdated">
                <v-menu
                  bottom
                  left
                  :disabled="isGeneratingReport || isGeneratingReportControl"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list dense>
                    <v-list-item
                      v-if="
                        projectSelected.status == 'NEW' ||
                        projectSelected.status == 'IN_PROGRESS'
                      "
                      @click="showPackageAssignmentModal = true"
                    >
                      <v-list-item-icon>
                        <v-icon> mdi-package </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title> Asignar paquete </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="projectSelected.status == 'FINISHED'"
                      @click="showPackageUpdateModal = true"
                    >
                      <v-list-item-icon>
                        <v-icon> mdi-package </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title
                        >Configuración final paquete</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            <v-container v-if="projectSelected">
              <v-row class="text-start">
                <v-col>
                  <span class="font-weight-bold black--text">
                    {{ projectSelected.name }}
                  </span>
                </v-col>
              </v-row>
              <v-row v-if="projectSelected.markersPackage">
                <v-col>
                  <span class="font-weight-bold secondary--text font-italic">
                    {{ projectSelected.markersPackage.name }}
                    <span
                      v-if="projectSelected.isPackageUpdated"
                      class="blue--text"
                    >
                      (Actualizado)
                    </span>
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="7"
                  class="pb-0"
                  v-if="projectSelected.status == 'IN_PROGRESS'"
                >
                  <v-btn
                    color="red"
                    :dark="
                      isGeneratingReport || isGeneratingReportControl
                        ? false
                        : true
                    "
                    small
                    block
                    @click="reportFinish"
                    :disabled="isGeneratingReport || isGeneratingReportControl"
                    >Finalizar</v-btn
                  >
                </v-col>
              </v-row>
              <v-row class="text-left">
                <v-col>
                  <span class="font-weight-bold gray--text">
                    {{ projectSelected.metagenomicId }}
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" class="pt-0">
                      <span>{{ projectSelected.description }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <span
                        >{{
                          parseFloat(projectSelected.size).toFixed(1)
                        }}
                        Ha</span
                      >
                    </v-col>
                    <v-col class="py-0 text-end primary--text font-weight-bold">
                      <span>{{
                        getProjectType(projectSelected.projectType)
                      }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0 primary--text font-weight-bold">
                      <span>{{ getCrop(projectSelected.crop) }}</span>
                    </v-col>
                    <v-col class="py-0 text-end primary--text font-weight-bold">
                      <span>{{ getStatus(projectSelected.status) }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0 primary--text font-weight-bold">
                      <span>{{
                        getPhenoStage(projectSelected.phenologicalStage)
                      }}</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="ma-0 pa-0" cols="12">
                  <v-expansion-panels v-model="panel1" flat>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="pa-3">
                        <v-row>
                          <v-col>
                            {{ projectSelected.mainContrast.name }}
                          </v-col>
                          <v-col v-if="projectSelected.markersPackage">
                            <v-row class="text-end">
                              <v-col>
                                <v-btn
                                  v-if="projectSelected.status != 'FINISHED'"
                                  color="primary"
                                  small
                                  :disabled="isGeneratingReportControl"
                                  @click="reportGeneration('MAIN')"
                                  :loading="isGeneratingReport"
                                  >Generar reporte</v-btn
                                >
                                <v-icon
                                  v-if="projectSelected.reportMain"
                                  @click="
                                    openPDFView(projectSelected.reportMain.url)
                                  "
                                  color="blue"
                                  >mdi-file-document</v-icon
                                >
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="ma-0 pa-0">
                        <div
                          v-for="(sample, index) of projectSelected.mainContrast
                            .cropZones"
                          :key="index"
                        >
                          <v-row>
                            <v-col cols="12" xl="9" lg="8">
                              <v-icon color="primary"
                                >mdi-vector-polygon</v-icon
                              >
                              {{ sample.cropZoneName }}</v-col
                            >
                            <v-col cols="12" xl="3" lg="4"
                              >{{ sample.cropZoneSize }} Ha</v-col
                            >
                            <v-col></v-col>
                          </v-row>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
                <v-col
                  class="ma-0 pa-0"
                  cols="12"
                  v-if="projectSelected.isContrast"
                >
                  <v-expansion-panels v-model="panel2" flat>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="pa-3">
                        <v-row>
                          <v-col>
                            {{ projectSelected.secondaryContrast.name }}
                          </v-col>
                          <v-col v-if="projectSelected.markersPackage">
                            <v-row class="text-end">
                              <v-col>
                                <v-btn
                                  v-if="projectSelected.status != 'FINISHED'"
                                  color="primary"
                                  small
                                  :disabled="isGeneratingReport"
                                  @click="reportGeneration('SECONDARY')"
                                  :loading="isGeneratingReportControl"
                                  >Generar reporte</v-btn
                                >
                                <v-icon
                                  v-if="projectSelected.reportControl"
                                  @click="
                                    openPDFView(
                                      projectSelected.reportControl.url
                                    )
                                  "
                                  color="blue"
                                  >mdi-file-document</v-icon
                                >
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="ma-0 pa-0">
                        <div
                          v-for="(sample, index) of projectSelected
                            .secondaryContrast.cropZones"
                          :key="index"
                        >
                          <v-row>
                            <v-col cols="12" xl="9" lg="8">
                              <v-icon color="primary"
                                >mdi-vector-polygon</v-icon
                              >
                              {{ sample.cropZoneName }}</v-col
                            >
                            <v-col cols="12" xl="3" lg="4"
                              >{{ sample.cropZoneSize }} Ha</v-col
                            >
                            <v-col></v-col>
                          </v-row>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
              <v-row v-if="projectSelected.projectType == 'PRODUCT_SETUP'">
                <v-col class="primary--text font-weight-bold">
                  <span>Productos</span>
                </v-col>
                <v-col cols="12">
                  <div
                    v-for="(product, index) in projectSelected.products"
                    :key="index"
                  >
                    <v-chip class="ma-1">
                      <v-icon color="primary">mdi-sprout</v-icon>
                      {{ product.name }} - {{ product.doses }} dósis -
                      {{ getProductType(product.type) }}
                    </v-chip>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="projectSelected.projectType == 'PRODUCT_SETUP'">
                <v-col class="primary--text font-weight-bold">
                  <span>Aplicaciones</span>
                </v-col>
              </v-row>
              <v-row v-if="projectSelected.projectType == 'PRODUCT_SETUP'">
                <v-col>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <th class="text-center">Nombre</th>
                        <th class="text-center">Dosis</th>
                        <th class="text-center">Tipo</th>
                        <th class="text-center">Fecha aplicación</th>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in projectSelected.applications"
                          :key="index"
                        >
                          <td class="text-center">{{ item.name }}</td>
                          <td class="text-center">{{ item.doses }} dósis</td>
                          <td class="text-center">
                            {{ getProductType(item.type) }}
                          </td>
                          <td class="text-center">
                            {{ item.applicationDate }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <package-assignment-modal
      v-model="showPackageAssignmentModal"
      v-if="showPackageAssignmentModal"
      @updateData="addPackage"
    />
    <pdf-viewer
      v-model="showPdfViewer"
      v-if="showPdfViewer"
      :urlPreview="url"
      :actionButton="showActionButton"
      @close="clearUrl"
      @accept="reportSend"
    />
    <PackageUpdateModal
      v-model="showPackageUpdateModal"
      v-if="showPackageUpdateModal"
      @updateData="updatePackage"
    />
  </div>
</template>

<script>
//vuex
import { mapState, mapMutations, mapActions } from "vuex";
//components
import PackageAssignmentModal from "@/components/projects/modal/PackageAssignmentModal.vue";
import PackageUpdateModal from "@/components/projects/modal/PackageUpdateModal.vue";
import PdfViewer from "@/components/utils/PdfViewer.vue";

export default {
  data: () => ({
    panel1: null,
    panel2: null,
    showPackageAssignmentModal: false,
    showPdfViewer: false,
    isGeneratingReport: false,
    isGeneratingReportControl: false,
    url: "",
    reporType: null,
    showActionButton: true,
    showPackageUpdateModal: false,
  }),
  components: {
    PackageAssignmentModal,
    PdfViewer,
    PackageUpdateModal,
  },
  computed: {
    ...mapState("project", ["projectSelected"]),
  },
  methods: {
    ...mapMutations("project", ["SET_PROJECT"]),
    ...mapActions("project", ["generateReport", "sendReport", "finishProject"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    getProductType(value) {
      let types = {
        LIQUID: "Liquido",
        SOLID: "Solido",
      };
      return types[value];
    },
    getProjectType(value) {
      let status = {
        MISS_TEST: "Miss test",
        MISS_SETUP: "Miss setuo",
        MISS_DISCOVERY: "Miss discovery",
        PRODUCT_SETUP: "Product setup",
      };

      return status[value];
    },
    getCrop(value) {
      let template = "";
      const crop = {
        olive: "Aceituna",
        chard: "Acelga",
        achiote: "Achiote",
        agave: "Agave",
        avocado: "Aguacate",
        garlic: "Ajo",
        sesame: "Ajonjolí",
        basil: "Albahaca",
        artichoke: "Alcachofa",
        alcatraz: "Alcatraz",
        shrunkenAlfalfa: "Alfalfa achicalada",
        greenAlfalfa: "Alfalfa verde",
        cotton: "Algodón",
        almond: "Almendro",
        birdseed: "Alpiste",
        amaranth: "Amaranto",
        anise: "Anís",
        celery: "Apio",
        blueberry: "Arándano",
        myrtle: "Arrayán",
        rice: "Arroz",
        greenForageOats: "Avena forrajera en verde",
        oatGrain: "Avena grano",
        bamboo: "Bambú",
        eggplant: "Berenjena",
        beetroot: "Betabel",
        broccoli: "Brocoli",
        peanut: "Cacahuate",
        cocoa: "Cacao",
        cherryCoffee: "Café cereza",
        pumpkin: "Calabaza",
        sweetPotato: "Camote",
        sugarCane: "Caña de azúcar",
        canola: "Canola",
        carambolo: "Carambolo",
        safflower: "Cártamo",
        barley: "Cebada",
        greenForageBarley: "Cebada forrajera en verde",
        onion: "Cebolla",
        chives: "Cebollín",
        ryeGrain: "Centeno grano",
        cherry: "Cereza",
        chabacano: "Chabacano",
        pea: "Chícharo",
        squash: "Chayote",
        chia: "Chía",
        chilacayote: "Chilacayote",
        chillyPepper: "Chile / Pimiento",
        custardApple: "Chirimoya",
        coriander: "Cilantro",
        plum: "Ciruela",
        coconutFruit: "Coco fruta",
        cabbage: "Col (repollo)",
        brusselsSprouts: "Col de Bruselas",
        cauliflower: "Coliflor",
        cumin: "Comino",
        chrysanthemum: "Crisantemo (planta)",
        peach: "Durazno",
        date: "Dátil",
        greenBean: "Ejote",
        cornCob: "Elote",
        dill: "Eneldo",
        epazote: "Epazote",
        asparagus: "Espárrago",
        spicesAndMedicinals: "Especias y medicinales",
        spinach: "Espinaca",
        raspberry: "Frambuesa",
        strawberry: "Fresa",
        bean: "Frijol",
        chickpea: "Garbanzo",
        sunflower: "Girasol",
        pomegranate: "Granada",
        soursop: "Guanábana",
        guava: "Guayaba",
        henequen: "Henequén",
        peppermint: "Hierbabuena",
        aromaticHerbs: "Hierbas aromáticas",
        fig: "Higo",
        jamaica: "Jamaica",
        ginger: "Jenjibre",
        jicama: "Jícama",
        lettuce: "Lechuga",
        lentil: "Lenteja",
        lime: "Lima",
        lemon: "Limón",
        linseed: "Linaza",
        litchi: "Litchi",
        corn: "Maíz",
        macadamia: "Macadamia",
        maguey: "Maguey",
        mamey: "Mamey",
        tangerine: "Mandarina",
        mango: "Mango",
        mangosteen: "Mangostán",
        chamomile: "Manzanilla",
        appleTree: "Manzano",
        passionFruit: "Maracuyá",
        daisyFlower: "Margarita",
        marjoram: "Mejorana",
        melon: "Melón",
        quince: "Membrillo",
        mint: "Menta",
        moringa: "Moringa",
        turnip: "Nabo",
        nanche: "Nanche",
        napa: "Napa",
        orange: "Naranja",
        medlar: "Níspero",
        nochebuena: "Nochebuena (planta)",
        walnut: "Nogal",
        noni: "Noni",
        nopal: "Nopal",
        oregano: "Orégano",
        ornamental: "Ornamentales",
        orchid: "Orquídea (planta)",
        oilPalm: "Palma africana o de aceite",
        potato: "Papa",
        papaya: "Papaya",
        grasslands: "Pastizales",
        matGrass: "Pasto tapete (m2)",
        pasturesAndMeadows: "Pastos y praderas",
        cucumber: "Pepino",
        pearTree: "Peral",
        parsley: "Perejil",
        peron: "Perón",
        persimony: "Persimonio",
        pepper: "Pimienta",
        pineapple: "Piña",
        pinion: "Piñón",
        pistache: "Pistache",
        pitaya: "Pitaya",
        banana: "Plátano",
        pore: "Poro",
        radish: "Rábano",
        rambutan: "Rambután",
        romerito: "Romerito",
        rosemary: "Romero",
        aloe: "Sábila",
        watermelon: "Sandía",
        sorghum: "Sorgo",
        soy: "Soya",
        stevia: "Stevia",
        tobacco: "Tabaco",
        tamarind: "Tamarindo",
        tejocote: "Tejocote",
        tomato: "Tomate Rojo (jitomate)",
        greenTomato: "Tomate Verde",
        thyme: "Tomillo",
        grapefruit: "Toronja (pomelo)",
        clover: "Trébol",
        wheat: "Trigo",
        dutchTulip: "Tulipán holandés",
        tuna: "Tuna",
        grape: "Uva",
        vanilla: "Vainilla",
        purslane: "Verdolaga",
        vine: "Vid",
        cassavaFood: "Yuca alimenticia",
        carrot: "Zanahoria",
        sapodilla: "Zapote",
        blackberry: "Zarzamora",
        zempoalxochitl: "Zempoalxochitl",
        fallow: "Barbecho",
        other: "Otro",
      };
      let cropName = crop[value];
      if (cropName) {
        template = cropName;
      } else {
        template = "Sin cultivo";
      }
      return template;
    },
    getPhenoStage(value) {
      let peheno = {
        Transplanting: "Trasplante",
        "Vegetative growth": "Desarrollo Vegetativo",
        Bloom: "Floración",
        "Fruit developmento": "Desarrollo del fruto",
        Harvest: "Cosecha",
        Sowing: "Siembra",
        Sprout: "Emergencia",
        Tuberization: "Tuberización",
        "Tuber filling": "Llenado de tuberculos",
        "Foliage development": "Desarrollo foliar",
        "Foliar expansion": "Expansión foliar",
        "First leaf": "Primera hoja",
        "Third leaf": "Tercer hoja ",
        "Fifth leaf": "Quinta hoja",
        "Start of bulbing": "Inicio de bulbificación",
        "Bulb growth": "Desarrollo del bulbo",
        "Fruit maturity": "Maduración de fruto",
        Sprouting: "Brotación",
      };
      return peheno[value] ? peheno[value] : value;
    },
    getStatus(value) {
      let status = {
        NEW: "Nuevo",
        IN_PROGRESS: "En progreso",
        COMPLETED: "Completado",
      };
      return status[value];
    },
    closeDetail() {
      this.projectSelected.clicked = false;
      this.SET_PROJECT(null);
      this.$emit("close");
    },
    addPackage(packageSelected) {
      this.projectSelected.markersPackage = packageSelected;
      this.showPackageAssignmentModal = false;
    },
    async reportGeneration(reporType) {
      try {
        this.reporType = reporType;
        if (reporType == "MAIN") {
          this.isGeneratingReport = true;
        } else {
          this.isGeneratingReportControl = true;
        }
        let payload = {
          projectId: this.projectSelected._id,
          reporType: reporType,
        };
        let response = await this.generateReport(payload);
        this.url = response.url;
        this.showActionButton = true;
        this.showPdfViewer = true;
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
      this.isGeneratingReport = false;
      this.isGeneratingReportControl = false;
    },
    async reportSend() {
      try {
        let payload = {
          projectId: this.projectSelected._id,
          reporType: this.reporType,
          url: this.url,
        };
        let response = await this.sendReport(payload);
        if (this.reporType == "MAIN") {
          this.projectSelected.reportMain = response.report;
        } else {
          this.projectSelected.reportControl = response.report;
        }

        this.showPdfViewer = false;
        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: `Reporte enviado exitosamente`,
          timeout: 5000,
        });
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
    },
    async reportFinish() {
      try {
        let payload = {
          projectId: this.projectSelected._id,
        };
        let response = await this.finishProject(payload);

        this.projectSelected.status = response.status;
        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: `Proyecto finalizado exitosamente`,
          timeout: 5000,
        });
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
    },
    clearUrl() {
      this.showPdfViewer = false;
      this.url = "";
    },
    openPDFView(url) {
      this.showPdfViewer = true;
      this.url = url;
      this.showActionButton = false;
    },
    updatePackage(name) {
      this.projectSelected.isPackageUpdated = true;
      this.projectSelected.markersPackage.name = name
      this.showPackageUpdateModal = false;
    },
  },
};
</script>
<style scoped>
.fill-height {
  height: 100%;
}
</style>
