import axios from "axios";

/**
 * Function to add new molecular plate
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - Molecular plate data object
 * @returns {data} -  Returns array of molecular plates
 */
export async function addNewTubes({ commit }, payload) {
  try {
    const { data } = await axios.post("/molecularLisisTube/add-tubes", payload);
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get lisis tubes replicas & status if exists tubes reigster
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - Molecular plate data object
 * @returns {data} -  Returns array of molecular plates
 */
export async function getReplicas({ commit }, payload) {
  try {
    const { data } = await axios.get("/molecularLisisTube/get-replicas", {
      params : {
        analysis : payload
      }
    });
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}
