<template>
  <v-dialog v-model="dialog" width="650">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        depressed
        block
        v-bind="attrs"
        v-on="on"
        :disabled="strains.length == 0"
      >
        Resembrar
      </v-btn>
    </template>

    <v-card class="py-2" :loading="loading">
      <!-- <v-card-title class="text-h5 grey lighten-2">
        Privacy Policy
      </v-card-title> -->

      <v-container class="pa-6">
        <v-row>
          <v-col cols="6">
            <p class="text-center font-weight-bold">
              Muestra {{ microbiologicalTestSelected.soilSample.folioAnalysis }}
            </p>
          </v-col>
          <v-col cols="6">
            <p class="text-center">INICIAL MS</p>
          </v-col>
        </v-row>
        <!-- Test data headers row -->
        <v-row class="mt-0 pr-2">
          <v-col cols="3 px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              ID ensayo
            </p>
          </v-col>
          <v-col cols="2 px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">Medio</p>
          </v-col>
          <v-col cols="2 px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              Dilución
            </p>
          </v-col>
          <v-col cols="2 px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              Caja petri
            </p>
          </v-col>
          <v-col cols="3 px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              Tipo análisis
            </p>
          </v-col>
        </v-row>
        <!-- Test data row -->
        <v-row class="mt-0 pr-2">
          <v-col cols="3 px-0">
            <p class="text-caption text-center mb-1">
              {{ microbiologicalTestSelected.testMedia._id }}
            </p>
          </v-col>
          <v-col cols="2 px-0">
            <p class="text-caption text-center mb-1">
              {{
                microbiologicalTestSelected.testMedia.cultureMedia.nutrientAgar
              }}
            </p>
          </v-col>
          <v-col cols="2 px-0">
            <p class="text-caption text-center mb-1">
              {{ microbiologicalTestSelected.inoculation.dilution }}
            </p>
          </v-col>
          <v-col cols="2 px-0">
            <p
              class="text-caption text-center mb-1"
              v-if="
                microbiologicalTestSelected.testMedia.containerType ==
                'petriDish90mmx15mm'
              "
            >
              90x15 mm
            </p>
            <p class="text-caption text-center mb-1" v-else>60x15 mm</p>
          </v-col>
          <v-col cols="3 px-0">
            <p class="text-caption text-center mb-1">
              {{
                getMoType(
                  microbiologicalTestSelected.testMedia.cultureMedia.moType
                )
              }}
            </p>
          </v-col>
        </v-row>

        <v-row class="mt-6 pr-2">
          <v-col cols="3 px-0 pb-0">
            <v-row justify="center">
              <p class="text-caption text-center mb-0 font-weight-black">
                ID de ensayo
              </p>
            </v-row>
          </v-col>
          <v-col cols="3 px-0 pb-0">
            <v-row justify="center">
              <p class="text-caption text-center mb-0 font-weight-black">
                Medio
              </p>
            </v-row>
          </v-col>
          <v-col cols="3 px-0 pb-0">
            <v-row justify="center">
              <p class="text-caption text-center mb-0 font-weight-black">
                Tipo de análisis
              </p>
            </v-row>
          </v-col>
          <v-col cols="3 px-0 pb-0">
            <v-row justify="center">
              <p class="text-caption text-center mb-0 font-weight-black">
                Identificación de cepa
              </p>
            </v-row>
          </v-col>
        </v-row>

        <v-row
          class="pt-2 pr-2"
          v-for="(strain, index) in strains"
          :key="strain._id"
        >
          <v-col cols="3 px-0 pb-0">
            <v-row justify="center" align="center">
              <v-autocomplete
                class="px-6"
                v-model="strain.idTest"
                :items="testMediaList"
                :search-input.sync="search[index]"
                return-object
                item-text="_id"
                no-data-text="No se ha encontrado"
                label="ID de medio"
                outlined
                dense
              ></v-autocomplete>
            </v-row>
          </v-col>
          <v-col cols="3 px-0 pb-0">
            <v-row justify="center">
              <p class="text-center pt-2 mb-0" v-if="strain.idTest == null">
                - - -
              </p>
              <p class="text-center pt-2 mb-0" v-else>
                {{ strain.idTest.cultureMedia.nutrientAgar }}
              </p>
            </v-row>
          </v-col>
          <v-col cols="3 px-0 pb-0">
            <v-row justify="center">
              <p class="text-center pt-2 mb-0">Resiembra</p>
            </v-row>
          </v-col>
          <v-col cols="3 px-0 pb-0">
            <v-row justify="center">
              <p class="text-center pt-2 mb-0">{{ strain.identification }}</p>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-card-actions class="justify-center">
        <v-row class="pt-0 mt-0">
          <v-col cols="6" offset="3">
            <v-btn depressed block color="primary" :loading="loading" @click="saveReseedings">
              Continuar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      dialog: false,
      loading : false,
      tests: [
        {
          idTest: "",
          medium: "PDA",
          analysisType: "Resiembra",
          identification: "Aspergillus niger",
        },
        {
          idTest: "",
          medium: "AN",
          analysisType: "Resiembra",
          identification: "Trichoderma sp.",
        },
        {
          idTest: "",
          medium: "TME",
          analysisType: "Resiembra",
          identification: "Sin identificación",
        },
      ],
      search: [],
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  computed: {
    ...mapState(["cancelToken"]),
    ...mapState("testMedia", ["testMediaList"]),
  },
  props: {
    microbiologicalTestSelected: {
      type: Object,
      require: true,
    },
    strains: {
      type: Array,
      require: true,
    },
  },
  methods: {
    ...mapMutations(["SET_CANCEL_TOKEN"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("testMedia", ["searchTestMedia"]),
    ...mapActions("isolate", ["addIsolate"]),
    getMoType(value) {
      let types = {
        all: "Todos",
        bacteria: "Bacteria",
        fungi: "Hongo",
        nematode: "Nemátodo",
      };
      return types[value];
    },
    async saveReseedings() {
      let newIsolates = [];
      this.loading = true
      try {
        for (const strain of this.strains) {
          let isolateData = {
            user: JSON.parse(localStorage.getItem("user"))._id,
            soilSample: this.microbiologicalTestSelected.soilSample._id,
            isolateType: "Pure",
            status: "INOCULATED",
            testMedia: strain.idTest._id,
            inoculation: new Date(),
            incubation: {
              startDate: new Date(),
              incubator: null,
              temperature: null,
            },
            isolateGrowth: "",
            strain: strain._id,
          };

          let requestBody = {
            isolate: isolateData,
            isReseeding: true,
          };

          newIsolates.push({ ...requestBody });

          const response = await this.addIsolate({ ...requestBody });
          if (response.error) {
            throw response;
          }
        }

        console.log(newIsolates);
        this.$emit('reseedSaved')
        this.dialog = false

        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: "Resiembra/s creada correctamente",
          timeout: 4000,
        });
      } catch (e) {
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Error al crear resiembra/s",
          timeout: 4000,
        });
      }
      this.loading = false
    },
  },
  watch: {
    async search(search) {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.SET_CANCEL_TOKEN(axios.CancelToken.source());

      if (search) {
        try {
          let analysisType = this.getMoType(
            this.microbiologicalTestSelected.testMedia.cultureMedia.moType
          );
          console.log(analysisType)
          const response = await this.searchTestMedia({
            laboratory: this.user.laboratory._id,
            status: "UNREGISTERED",
            moTypes: [(analysisType == "Bacteria" ? "bacteria" : "fungi"), "all"],
          });
          if (response.error) throw response;
        } catch (error) {
          console.log("Error searching test media", error);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: error.message,
            timeout: 4000,
          });
        }
      }
    },
  },
};
</script>
