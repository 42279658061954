<template>
  <v-card tile elevation="0" class="my-auto pb-1" v-if="selected != undefined">
    <v-container>
      <!-- Photo row -->
      <v-row>
        <v-col cols="12" v-if="imageUrl == null">
          <v-img
            :src="require('../../assets/img/no_image_strain.png')"
            aspect-ratio="1.0"
            contain
          ></v-img>
        </v-col>
        <v-col cols="12" v-else>
          <v-img :src="imageUrl" aspect-ratio="1.0" contain></v-img>
          <v-row justify="center">
            <EditMiniature
              :imageUrl="urlOriginalImage"
              @imageUpdated="formatData()"
            />
          </v-row>
        </v-col>
        <v-col v-if="imageUrl != null" class="py-1" align="center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a :href="imageUrl">
                <v-btn small color="success" icon v-bind="attrs" v-on="on"
                  ><v-icon>mdi-download</v-icon></v-btn
                >
              </a>
            </template>
            <span>Descargar imágen</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <template>
        <!-- Analisis data row -->
        <v-row class="px-10">
          <v-col cols="7">
            <p class="font-weight-bold text-left mb-1">
              Análisis {{ selected.soilSample.folioAnalysis }}
            </p>
          </v-col>
          <v-col cols="5">
            <!-- <p class="font-weight-bold text-right mb-1">INICIAL MS</p> -->
          </v-col>
        </v-row>
        <!-- Test data headers row -->
        <v-row class="mt-0 pr-2">
          <v-col cols="3" class="px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              ID ensayo
            </p>
          </v-col>
          <v-col cols="2" class="px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">Medio</p>
          </v-col>
          <v-col cols="2" class="px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              Dilución
            </p>
          </v-col>
          <v-col cols="2" class="px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              Caja petri
            </p>
          </v-col>
          <v-col cols="3" class="px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              Tipo análisis
            </p>
          </v-col>
        </v-row>
        <!-- Test data row -->
        <v-row class="mt-0 pr-2">
          <v-col cols="3" class="px-0">
            <p class="text-caption text-center mb-1">
              {{ selected.testMedia._id }}
            </p>
          </v-col>
          <v-col cols="2" class="px-0">
            <p class="text-caption text-center mb-1">
              {{ selected.testMedia.cultureMedia.nutrientAgar }}
            </p>
          </v-col>
          <v-col cols="2" class="px-0">
            <p class="text-caption text-center mb-1">
              {{ selected.inoculation.dilution }}
            </p>
          </v-col>
          <v-col
            cols="2"
            class="px-0"
            v-if="selected.testMedia.containerType == 'petriDish90mmx15mm'"
          >
            <p class="text-caption text-center mb-1">90x15 mm</p>
          </v-col>
          <v-col cols="2" class="px-0" v-else>
            <p class="text-caption text-center mb-1">60x15 mm</p>
          </v-col>
          <v-col cols="3" class="px-0">
            <p class="text-caption text-center mb-1">
              {{ getMoType(selected.testMedia.cultureMedia.moType) }}
            </p>
          </v-col>
        </v-row>
        <!-- Client data row -->
        <v-row class="px-6 mt-0">
          <v-col cols="12" class="pb-0">
            <p class="text-left text-caption mb-0">Cliente:</p>
          </v-col>
          <v-col cols="12" class="py-0">
            <p class="font-weight-bold text-left mb-1">
              {{ selected.soilSample.container.client.companyName }}
            </p>
          </v-col>
        </v-row>
        <!-- Area data row -->
        <v-row class="px-6 mt-0">
          <v-col cols="12" class="pb-0">
            <p class="text-left text-caption mb-0">Unidad agrícola:</p>
          </v-col>
          <v-col cols="12" class="py-0">
            <p class="font-weight-bold text-left mb-0">
              {{ selected.soilSample.soilArea.name }}
            </p>
          </v-col>
          <v-col cols="12" class="pt-0">
            <p
              class="font-weight-bold text-left secondary--text mb-1"
              v-if="selected.soilSample.soilArea.cropCycles"
            >
              Cultivo: {{ getCrop(selected.soilSample.soilArea.cropCycles) }}
            </p>
            <p class="font-weight-bold text-left secondary--text mb-1" v-else>
              Cultivo: SIN ESPECIFICAR
            </p>
          </v-col>
        </v-row>
        <v-row class="px-6 mt-0">
          <v-col
            cols="12"
            v-if="
              selected.soilSample.soilArea.cropCycles &&
              selected.soilSample.soilArea.cropCycles.length > 0
            "
          >
            <v-expansion-panels focusable>
              <v-expansion-panel>
                <v-expansion-panel-header
                  >Manejo agronómico</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-container fluid>
                    <v-row>
                      <v-col class="pb-1 pt-3">
                        <span class="font-weight-bold text-body-2 primary--text"
                          >Cultivo anterior:
                        </span>
                        <span class="text-body-2">{{
                          getCropDetail(
                            selected.soilSample.soilArea.cropCycles[
                              selected.soilSample.soilArea.cropCycles.length - 1
                            ].crop.lastCrop
                          )
                        }}</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-1">
                        <span class="font-weight-bold text-body-2 primary--text"
                          >Variedad anterior:
                        </span>
                        <span
                          class="text-body-2"
                          v-if="
                            selected.soilSample.soilArea.cropCycles[
                              selected.soilSample.soilArea.cropCycles.length - 1
                            ].crop.lastVariety &&
                            selected.soilSample.soilArea.cropCycles[
                              selected.soilSample.soilArea.cropCycles.length - 1
                            ].crop.lastVariety.length > 0
                          "
                          >{{
                            selected.soilSample.soilArea.cropCycles[
                              selected.soilSample.soilArea.cropCycles.length - 1
                            ].crop.lastVariety
                          }}</span
                        >
                        <span class="text-body-2" v-else> Sin variedad </span>
                      </v-col>
                    </v-row>
                    <v-row class="text-center">
                      <v-col class="pb-0">
                        <span class="font-weight-bold text-subtitle-1">
                          Problemas detectados
                        </span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0">
                        <v-list>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon
                                :color="
                                  selected.soilSample.soilArea.cropCycles[
                                    selected.soilSample.soilArea.cropCycles
                                      .length - 1
                                  ].crop.detectedIssues &&
                                  selected.soilSample.soilArea.cropCycles[
                                    selected.soilSample.soilArea.cropCycles
                                      .length - 1
                                  ].crop.detectedIssues.isFungi
                                    ? 'primary'
                                    : ''
                                "
                              >
                                mdi-mushroom
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title> Hongos </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon
                                :color="
                                  selected.soilSample.soilArea.cropCycles[
                                    selected.soilSample.soilArea.cropCycles
                                      .length - 1
                                  ].crop.detectedIssues &&
                                  selected.soilSample.soilArea.cropCycles[
                                    selected.soilSample.soilArea.cropCycles
                                      .length - 1
                                  ].crop.detectedIssues.isBacteria
                                    ? 'primary'
                                    : ''
                                "
                              >
                                mdi-bacteria
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title> Bacterias </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon
                                :color="
                                  selected.soilSample.soilArea.cropCycles[
                                    selected.soilSample.soilArea.cropCycles
                                      .length - 1
                                  ].crop.detectedIssues &&
                                  selected.soilSample.soilArea.cropCycles[
                                    selected.soilSample.soilArea.cropCycles
                                      .length - 1
                                  ].crop.detectedIssues.isNematode
                                    ? 'primary'
                                    : ''
                                "
                              >
                                mdi-abugida-devanagari
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title> Nemátodo </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon
                                :color="
                                  selected.soilSample.soilArea.cropCycles[
                                    selected.soilSample.soilArea.cropCycles
                                      .length - 1
                                  ].crop.detectedIssues &&
                                  selected.soilSample.soilArea.cropCycles[
                                    selected.soilSample.soilArea.cropCycles
                                      .length - 1
                                  ].crop.detectedIssues.isNutrition
                                    ? 'primary'
                                    : ''
                                "
                              >
                                mdi-nutrition
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title> Nutición </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon
                                :color="
                                  selected.soilSample.soilArea.cropCycles[
                                    selected.soilSample.soilArea.cropCycles
                                      .length - 1
                                  ].crop.detectedIssues &&
                                  selected.soilSample.soilArea.cropCycles[
                                    selected.soilSample.soilArea.cropCycles
                                      .length - 1
                                  ].crop.detectedIssues.isOther
                                    ? 'primary'
                                    : ''
                                "
                              >
                                mdi-liquid-spot
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title> Otro </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="
                        selected.soilSample.soilArea.cropCycles[
                          selected.soilSample.soilArea.cropCycles.length - 1
                        ].crop.descriptionIssue &&
                        selected.soilSample.soilArea.cropCycles[
                          selected.soilSample.soilArea.cropCycles.length - 1
                        ].crop.descriptionIssue.length > 0
                      "
                    >
                      <v-col cols="12">
                        <span class="text-body-2 font-weight-bold"
                          >Descripción</span
                        >
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <span class="text-caption">{{
                          selected.soilSample.soilArea.cropCycles[
                            selected.soilSample.soilArea.cropCycles.length - 1
                          ].crop.descriptionIssue
                        }}</span>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <!-- Sample data row -->
        <v-row class="px-6 mt-2">
          <v-col cols="12" class="py-0">
            <p class="text-left text-caption mb-0 primary--text">
              Submuestras {{ selected.soilSample.subSamples.length }}/{{
                selected.soilSample.soilArea.targetLocations.length
              }}
            </p>
          </v-col>
          <v-col cols="12" class="py-0">
            <p class="text-left text-caption mb-0">
              Responsable:
              {{
                `${selected.soilSample.sampler.name} ${selected.soilSample.sampler.lastName}`
              }}
            </p>
          </v-col>
          <v-col cols="12" class="py-0">
            <p class="text-left text-caption mb-0">
              Fecha de toma:
              {{
                selected.soilSample.endDate
                  .substr(0, 19)
                  .replace("-", "/")
                  .replace("-", "/")
                  .replace("T", " - ")
              }}
              hrs
            </p>
          </v-col>
          <v-col cols="12" class="py-0">
            <p
              class="text-left text-caption mb-0"
              v-if="selected.soilSample.container.reception != null"
            >
              Fecha de recepción:
              {{
                selected.soilSample.container.reception.date
                  .substr(0, 19)
                  .replace("-", "/")
                  .replace("-", "/")
                  .replace("T", " - ")
              }}
              hrs
            </p>
            <p class="text-left text-caption mb-0" v-else>
              Fecha de recepción: - - - -
            </p>
          </v-col>
        </v-row>
      </template>
    </v-container>

    <v-card-actions class="justify-center">
      <v-row class="pt-2" justify="center" align="center">
        <v-col cols="6">
          <v-btn
            depressed
            block
            @click="repeatTest"
            :disabled="selected.status != 'IN_PROGRESS'"
          >
            Repetir foto
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            depressed
            block
            color="primary"
            @click="showTestIdentifications"
          >
            Continuar
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import EditMiniature from "./modals/EditMiniature.vue";

export default {
  components: {
    EditMiniature,
  },
  data() {
    return {
      imageUrl: null,
      urlOriginalImage: "",
      mbTestBD: null,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  props: {
    selected: {
      type: Object,
      required: true,
    },
  },
  watch: {
    async selected() {
      await this.formatData();
    },
  },
  async created() {
    await this.formatData();
  },
  methods: {
    ...mapMutations(["UPDATE_SHOW_SAMPLE_DATA"]),
    ...mapActions("microbiologicalTest", [
      "updateMBTest",
      "getMicrobiologicalTestById",
    ]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async formatData() {
      await this.getMBTest();
      if (typeof this.mbTestBD.incubation.morphologyPhoto == "undefined") {
        this.imageUrl = null;
      } else {
        let image = this.mbTestBD.incubation.morphologyPhoto;
        if (image != null) {
          const imagePath = image.includes("-small")
            ? image.split("-small.jpg")[0]
            : image.split(".jpg")[0];

          this.urlOriginalImage = `${imagePath}.jpg`;
          this.imageUrl =
            image.slice(0, image.length - 4) + ".jpg?" + Date.now();
          // this.urlOriginalImage = image;
          // this.imageUrl =
          //   image
        } else {
          this.imageUrl = null;
        }
      }
    },
    getMoType(value) {
      let types = {
        all: "Todos",
        bacteria: "Bacteria",
        fungi: "Hongo",
        nematode: "Nemátodo",
      };
      return types[value];
    },
    getCrop(cropCycles) {
      if (cropCycles.length > 0) {
        let cropCycle = cropCycles[cropCycles.length - 1];
        let crop = cropCycle.crop;
        let cropName = this.getCropName(crop);

        // if (
        //   typeof cropCycle.plantDate != "undefined" &&
        //   cropCycle.plantDate != "" &&
        //   cropCycle.plantDate != null
        // ) {
        //   let date = new Date();

        //   let day = date.getDate();
        //   let month = date.getMonth() + 1;
        //   let year = date.getFullYear();

        //   let actuallyDate = `${year}/${
        //     month < 10 ? "0" + month : month
        //   }/${day}`;
        //   let daysDifference = this.countDays(
        //     cropCycle.plantDate,
        //     actuallyDate
        //   );

        //   if (
        //     typeof cropCycle.harvestDate == "undefined" ||
        //     cropCycle.harvestDate == ""
        //   ) {
        //     return `${cropName} - ${daysDifference} días`;
        //   } else {
        //     let difference = this.countDays(
        //       actuallyDate,
        //       cropCycle.harvestDate
        //     );

        //     if (difference > 0) {
        //       return `${cropName} - ${difference} días de la coshecha`;
        //     } else {
        //       return `${cropName} - ${daysDifference} días`;
        //     }
        //   }

        //   // console.log('Plant date', cropCycle.plantDate)
        //   // console.log('Harvest date', cropCycle.harvestDate)
        //   // console.log('Actual date', `${year}/${(month < 10) ? '0'+ month : month}/${day}`)
        //   // console.log('Diference date', this.countDays(cropCycle.plantDate, `${year}/${(month < 10) ? '0'+ month : month}/${day}`))
        // } else {
        return `${cropName}`;
        // }
      } else {
        return "SIN ESPECIFICAR";
      }
    },
    getCropDetail(value) {
      try {
        const crop = {
          olive: "Aceituna",
          chard: "Acelga",
          achiote: "Achiote",
          agave: "Agave",
          avocado: "Aguacate",
          garlic: "Ajo",
          sesame: "Ajonjolí",
          basil: "Albahaca",
          artichoke: "Alcachofa",
          alcatraz: "Alcatraz",
          shrunkenAlfalfa: "Alfalfa achicalada",
          greenAlfalfa: "Alfalfa verde",
          cotton: "Algodón",
          almond: "Almendro",
          birdseed: "Alpiste",
          amaranth: "Amaranto",
          anise: "Anís",
          celery: "Apio",
          blueberry: "Arándano",
          myrtle: "Arrayán",
          rice: "Arroz",
          greenForageOats: "Avena forrajera en verde",
          oatGrain: "Avena grano",
          bamboo: "Bambú",
          eggplant: "Berenjena",
          beetroot: "Betabel",
          broccoli: "Brocoli",
          peanut: "Cacahuate",
          cocoa: "Cacao",
          cherryCoffee: "Café cereza",
          pumpkin: "Calabaza",
          sweetPotato: "Camote",
          sugarCane: "Caña de azúcar",
          canola: "Canola",
          carambolo: "Carambolo",
          safflower: "Cártamo",
          barley: "Cebada",
          greenForageBarley: "Cebada forrajera en verde",
          onion: "Cebolla",
          chives: "Cebollín",
          ryeGrain: "Centeno grano",
          cherry: "Cereza",
          chabacano: "Chabacano",
          pea: "Chícharo",
          squash: "Chayote",
          chia: "Chía",
          chilacayote: "Chilacayote",
          chillyPepper: "Chile / Pimiento",
          custardApple: "Chirimoya",
          coriander: "Cilantro",
          plum: "Ciruela",
          coconutFruit: "Coco fruta",
          cabbage: "Col (repollo)",
          brusselsSprouts: "Col de Bruselas",
          cauliflower: "Coliflor",
          cumin: "Comino",
          chrysanthemum: "Crisantemo (planta)",
          peach: "Durazno",
          date: "Dátil",
          greenBean: "Ejote",
          cornCob: "Elote",
          dill: "Eneldo",
          epazote: "Epazote",
          asparagus: "Espárrago",
          spicesAndMedicinals: "Especias y medicinales",
          spinach: "Espinaca",
          raspberry: "Frambuesa",
          strawberry: "Fresa",
          bean: "Frijol",
          chickpea: "Garbanzo",
          sunflower: "Girasol",
          pomegranate: "Granada",
          soursop: "Guanábana",
          guava: "Guayaba",
          henequen: "Henequén",
          peppermint: "Hierbabuena",
          aromaticHerbs: "Hierbas aromáticas",
          fig: "Higo",
          jamaica: "Jamaica",
          ginger: "Jenjibre",
          jicama: "Jícama",
          lettuce: "Lechuga",
          lentil: "Lenteja",
          lime: "Lima",
          lemon: "Limón",
          linseed: "Linaza",
          litchi: "Litchi",
          corn: "Maíz",
          macadamia: "Macadamia",
          maguey: "Maguey",
          mamey: "Mamey",
          tangerine: "Mandarina",
          mango: "Mango",
          mangosteen: "Mangostán",
          chamomile: "Manzanilla",
          appleTree: "Manzano",
          passionFruit: "Maracuyá",
          daisyFlower: "Margarita",
          marjoram: "Mejorana",
          melon: "Melón",
          quince: "Membrillo",
          mint: "Menta",
          moringa: "Moringa",
          turnip: "Nabo",
          nanche: "Nanche",
          napa: "Napa",
          orange: "Naranja",
          medlar: "Níspero",
          nochebuena: "Nochebuena (planta)",
          walnut: "Nogal",
          noni: "Noni",
          nopal: "Nopal",
          oregano: "Orégano",
          ornamental: "Ornamentales",
          orchid: "Orquídea (planta)",
          oilPalm: "Palma africana o de aceite",
          potato: "Papa",
          papaya: "Papaya",
          grasslands: "Pastizales",
          matGrass: "Pasto tapete (m2)",
          pasturesAndMeadows: "Pastos y praderas",
          cucumber: "Pepino",
          pearTree: "Peral",
          parsley: "Perejil",
          peron: "Perón",
          persimony: "Persimonio",
          pepper: "Pimienta",
          pineapple: "Piña",
          pinion: "Piñón",
          pistache: "Pistache",
          pitaya: "Pitaya",
          banana: "Plátano",
          pore: "Poro",
          radish: "Rábano",
          rambutan: "Rambután",
          romerito: "Romerito",
          rosemary: "Romero",
          aloe: "Sábila",
          watermelon: "Sandía",
          sorghum: "Sorgo",
          soy: "Soya",
          stevia: "Stevia",
          tobacco: "Tabaco",
          tamarind: "Tamarindo",
          tejocote: "Tejocote",
          tomato: "Tomate Rojo (jitomate)",
          greenTomato: "Tomate Verde",
          thyme: "Tomillo",
          grapefruit: "Toronja (pomelo)",
          clover: "Trébol",
          wheat: "Trigo",
          dutchTulip: "Tulipán holandés",
          tuna: "Tuna",
          grape: "Uva",
          vanilla: "Vainilla",
          purslane: "Verdolaga",
          vine: "Vid",
          cassavaFood: "Yuca alimenticia",
          carrot: "Zanahoria",
          sapodilla: "Zapote",
          blackberry: "Zarzamora",
          zempoalxochitl: "Zempoalxochitl",
          fallow: "Barbecho",
          other: "Otro",
        };
        let name = crop[value];
        if (name) {
          return name;
        } else {
          return "Sin cultivo";
        }
      } catch (e) {
        return "Sin cultivo";
      }
    },
    getCropName(cropCycleName) {
      let template = "- - - -";
      const crop = {
        olive: "Aceituna",
        chard: "Acelga",
        achiote: "Achiote",
        agave: "Agave",
        avocado: "Aguacate",
        garlic: "Ajo",
        sesame: "Ajonjolí",
        basil: "Albahaca",
        artichoke: "Alcachofa",
        alcatraz: "Alcatraz",
        shrunkenAlfalfa: "Alfalfa achicalada",
        greenAlfalfa: "Alfalfa verde",
        cotton: "Algodón",
        almond: "Almendro",
        birdseed: "Alpiste",
        amaranth: "Amaranto",
        anise: "Anís",
        celery: "Apio",
        blueberry: "Arándano",
        myrtle: "Arrayán",
        rice: "Arroz",
        greenForageOats: "Avena forrajera en verde",
        oatGrain: "Avena grano",
        bamboo: "Bambú",
        eggplant: "Berenjena",
        beetroot: "Betabel",
        broccoli: "Brocoli",
        peanut: "Cacahuate",
        cocoa: "Cacao",
        cherryCoffee: "Café cereza",
        pumpkin: "Calabaza",
        sweetPotato: "Camote",
        sugarCane: "Caña de azúcar",
        canola: "Canola",
        carambolo: "Carambolo",
        safflower: "Cártamo",
        barley: "Cebada",
        greenForageBarley: "Cebada forrajera en verde",
        onion: "Cebolla",
        chives: "Cebollín",
        ryeGrain: "Centeno grano",
        cherry: "Cereza",
        chabacano: "Chabacano",
        pea: "Chícharo",
        squash: "Chayote",
        chia: "Chía",
        chilacayote: "Chilacayote",
        chillyPepper: "Chile / Pimiento",
        custardApple: "Chirimoya",
        coriander: "Cilantro",
        plum: "Ciruela",
        coconutFruit: "Coco fruta",
        cabbage: "Col (repollo)",
        brusselsSprouts: "Col de Bruselas",
        cauliflower: "Coliflor",
        cumin: "Comino",
        chrysanthemum: "Crisantemo (planta)",
        peach: "Durazno",
        date: "Dátil",
        greenBean: "Ejote",
        cornCob: "Elote",
        dill: "Eneldo",
        epazote: "Epazote",
        asparagus: "Espárrago",
        spicesAndMedicinals: "Especias y medicinales",
        spinach: "Espinaca",
        raspberry: "Frambuesa",
        strawberry: "Fresa",
        bean: "Frijol",
        chickpea: "Garbanzo",
        sunflower: "Girasol",
        pomegranate: "Granada",
        soursop: "Guanábana",
        guava: "Guayaba",
        henequen: "Henequén",
        peppermint: "Hierbabuena",
        aromaticHerbs: "Hierbas aromáticas",
        fig: "Higo",
        jamaica: "Jamaica",
        ginger: "Jenjibre",
        jicama: "Jícama",
        lettuce: "Lechuga",
        lentil: "Lenteja",
        lime: "Lima",
        lemon: "Limón",
        linseed: "Linaza",
        litchi: "Litchi",
        corn: "Maíz",
        macadamia: "Macadamia",
        maguey: "Maguey",
        mamey: "Mamey",
        tangerine: "Mandarina",
        mango: "Mango",
        mangosteen: "Mangostán",
        chamomile: "Manzanilla",
        appleTree: "Manzano",
        passionFruit: "Maracuyá",
        daisyFlower: "Margarita",
        marjoram: "Mejorana",
        melon: "Melón",
        quince: "Membrillo",
        mint: "Menta",
        moringa: "Moringa",
        turnip: "Nabo",
        nanche: "Nanche",
        napa: "Napa",
        orange: "Naranja",
        medlar: "Níspero",
        nochebuena: "Nochebuena (planta)",
        walnut: "Nogal",
        noni: "Noni",
        nopal: "Nopal",
        oregano: "Orégano",
        ornamental: "Ornamentales",
        orchid: "Orquídea (planta)",
        oilPalm: "Palma africana o de aceite",
        potato: "Papa",
        papaya: "Papaya",
        grasslands: "Pastizales",
        matGrass: "Pasto tapete (m2)",
        pasturesAndMeadows: "Pastos y praderas",
        cucumber: "Pepino",
        pearTree: "Peral",
        parsley: "Perejil",
        peron: "Perón",
        persimony: "Persimonio",
        pepper: "Pimienta",
        pineapple: "Piña",
        pinion: "Piñón",
        pistache: "Pistache",
        pitaya: "Pitaya",
        banana: "Plátano",
        pore: "Poro",
        radish: "Rábano",
        rambutan: "Rambután",
        romerito: "Romerito",
        rosemary: "Romero",
        aloe: "Sábila",
        watermelon: "Sandía",
        sorghum: "Sorgo",
        soy: "Soya",
        stevia: "Stevia",
        tobacco: "Tabaco",
        tamarind: "Tamarindo",
        tejocote: "Tejocote",
        tomato: "Tomate Rojo (jitomate)",
        greenTomato: "Tomate Verde",
        thyme: "Tomillo",
        grapefruit: "Toronja (pomelo)",
        clover: "Trébol",
        wheat: "Trigo",
        dutchTulip: "Tulipán holandés",
        tuna: "Tuna",
        grape: "Uva",
        vanilla: "Vainilla",
        purslane: "Verdolaga",
        vine: "Vid",
        cassavaFood: "Yuca alimenticia",
        carrot: "Zanahoria",
        sapodilla: "Zapote",
        blackberry: "Zarzamora",
        zempoalxochitl: "Zempoalxochitl",
        fallow: "Barbecho",
        other: "Otro",
      };
      if (cropCycleName !== null) {
        let cropName = crop[cropCycleName.cropName];
        if (typeof cropName != "undefined") {
          template = cropName;
          if (
            cropCycleName.variety != undefined &&
            cropCycleName.variety.length > 0
          ) {
            template += `(${cropCycleName.variety})`;
          }
        }
      }
      return template;
    },
    countDays(f1, f2) {
      var aDate1 = f1.split("/");
      var aDate2 = f2.split("/");
      var fDate1 = Date.UTC(aDate1[0], aDate1[1] - 1, aDate1[2]);
      var fDate2 = Date.UTC(aDate2[0], aDate2[1] - 1, aDate2[2]);
      var dif = fDate2 - fDate1;
      var dias = Math.floor(dif / (1000 * 60 * 60 * 24));
      return dias;
    },
    async repeatTest() {
      let incubationObj = { ...this.mbTestBD.incubation };
      if (incubationObj.endDate) delete incubationObj.endDate;

      delete incubationObj.morphologyPhoto;

      let form = {
        _id: this.mbTestBD._id,
        status: "INCUBATING",
        incubation: incubationObj,
        laboratory: this.user.laboratory._id,
      };

      const response = await this.updateMBTest(form);
      if (response.error) {
        throw response;
      }

      this.SET_ALERT_TYPE({
        visible: true,
        type: "success",
        text: "Ahora puedes tomar la evidencia fotográfica nuevamente.",
        timeout: 4000,
      });

      this.$emit("testUpdated");
    },
    async getMBTest() {
      let request = await this.getMicrobiologicalTestById(this.selected._id);
      this.mbTestBD = request.MICROBIOLOGICALTEST;
    },
    showTestIdentifications() {
      this.$emit("continueClicked");
      this.UPDATE_SHOW_SAMPLE_DATA(false);
    },
  },
};
</script>
