<template>
  <v-dialog v-model="show" width="50vw" scrollable persistent>
    <v-card height="auto" elevation="5" class="rounded-lg" min-height="200">
      <v-card-title class="text-h5 lighten-2">
        Transferir paquete
      </v-card-title>
      <v-card-text>
        <v-form lazy-validation ref="form">
          <v-container>
            <v-row>
              <v-col>
                <v-autocomplete
                  :search-input.sync="searchLab"
                  v-model="labFound"
                  :items="laboratories"
                  label="Buscar..."
                  hide-no-data
                  outlined
                  dense
                  item-text="name"
                  clearable
                  return-object
                  prepend-inner-icon="mdi-magnify"
                  :rules="validation()"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col>
              <v-btn
                color="red"
                block
                @click="closeModal"
                dark
                :disabled="isLoading"
                >Cancelar</v-btn
              >
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                block
                @click="confirmation"
                :loading="isLoading"
                >Aceptar</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//vuex
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
//validations
import { requiredValidation } from "@/helpers/validations.js";
export default {
  data: () => ({
    loader: false,
    isLoading: false,
    searchLab: null,
    labFound: null,
    laboratories: [],
  }),
  props: {
    value: Boolean,
    title: String,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions("laboratory", ["getMolecuclarLaboratories"]),
    closeModal() {
      this.show = false;
    },
    validation(name) {
      return requiredValidation(name);
    },
    confirmation() {
      //emit desision
      if (this.$refs.form.validate()) {
        this.$emit("confirmation", this.labFound);
        this.closeModal();
      }
    },
  },
  watch: {
    async searchLab(value) {
      try {
        if (value) {
          let payload = {
            search: value,
          };
          let response = await this.getMolecuclarLaboratories(payload);
          if (response.error) throw response;
          this.laboratories = response.laboratories;
        } else {
          this.laboratories = [];
          this.labFound = null;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
