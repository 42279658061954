<template>
  <v-dialog v-model="dialog" width="75%" persistent scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn block class="default" elevation="0" v-bind="attrs" v-on="on">
        Bioestimulación
      </v-btn>
    </template>

    <v-card class="py-2">
      <!-- <v-card-title class="text-h5 grey lighten-2">
        Privacy Policy
      </v-card-title> -->
      <v-card-text>
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-container class="pa-6">
            <v-row>
              <v-col cols="12">
                <p class="text-center text-h5">Nueva Bio-estimulación</p>
              </v-col>
            </v-row>

            <v-container>
              <v-row align="center" justify="center">
                <v-col cols="8">
                  <v-combobox
                    v-model="analysisToFound"
                    :items="analysesFounded"
                    :rules="analysisRules"
                    :loading="loadingAnalysis"
                    no-data-text="No se encontró el análisis"
                    return-object
                    label="Análisis"
                    no-filter
                    clearable
                    outlined
                    dense
                  >
                    <template v-slot:item="{ item }">
                      {{ item.folio }} -
                      {{
                        item.status == "MEASURED"
                          ? "MEDIDO"
                          : item.status == "VALUATING"
                          ? "VALUANDO"
                          : item.status == "RECEIVED"
                          ? "RECIBIDO"
                          : "VALUADO"
                      }}
                    </template>
                    <template v-slot:selection="{ item }">
                      <template v-if="typeof item == 'string'">
                        {{ item }}
                      </template>
                      <template v-else>
                        {{ item.folio }} -
                        {{
                          item.status == "MEASURED"
                            ? "MEDIDO"
                            : item.status == "VALUATING"
                            ? "VALUANDO"
                            : item.status == "RECEIVED"
                            ? "RECIBIDO"
                            : "VALUADO"
                        }}
                      </template>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </v-container>

            <v-row class="mt-2 pr-2">
              <v-col cols="3" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-caption text-center mb-0 font-weight-black">
                    ID medio
                  </p>
                </v-row>
              </v-col>
              <v-col cols="1" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-caption text-center mb-0 font-weight-black">
                    Medio
                  </p>
                </v-row>
              </v-col>
              <v-col cols="3" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-caption text-center mb-0 font-weight-black">
                    Tipo de ensayo
                  </p>
                </v-row>
              </v-col>
              <v-col cols="5" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-caption text-center mb-0 font-weight-black">
                    Inóculo
                  </p>
                </v-row>
              </v-col>
            </v-row>

            <v-row
              class="pt-4 pr-2"
              v-for="(item, index) in bcTestsSelected"
              :key="index"
            >
              <v-col cols="3" class="px-0 pb-0">
                <v-row
                  justify="center"
                  align="center"
                  v-if="item.testType != 'P. AIA'"
                >
                  <v-autocomplete
                    v-model="item.testMedia"
                    class="px-8"
                    :items="testMediaList"
                    :rules="testMediaRules"
                    @change="assingDataRow(index)"
                    return-object
                    item-text="_id"
                    no-data-text="No se ha encontrado"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-row>
                <v-row justify="center" align="center" class="pt-2" v-else
                  >- - -</v-row
                >
              </v-col>
              <v-col cols="1" class="px-0 pb-0">
                <v-row justify="center" v-if="item.testType != 'P. AIA'">
                  <p class="text-center pt-2 mb-0" v-if="item.testMedia == null">
                    - - -
                  </p>
                  <p class="text-center pt-2 mb-0" v-else>
                    {{ item.testMedia.cultureMedia.nutrientAgar }}
                  </p>
                </v-row>
                <v-row justify="center" align="center" class="pt-2" v-else
                  >- - -</v-row
                >
              </v-col>
              <v-col cols="3" class="px-5 pb-0">
                <v-row justify="center" align="center">
                  <v-select
                    v-model="item.testType"
                    :items="testTypes"
                    label="Tipo de ensayo"
                    outlined
                    dense
                  ></v-select>
                </v-row>
              </v-col>
              <v-col
                :cols="bcTestsSelected.length > 1 ? '4' : '5'"
                class="px-5 pb-0"
              >
                <v-row justify="center" align="center">
                  <v-select
                    v-model="item.inoculum"
                    label="Inóculo"
                    item-text="identificationSample"
                    :items="inocules"
                    :rules="inoculumRules"
                    :loading="loadingAnalysis"
                    return-object
                    outlined
                    dense
                  >
                    <template v-slot:selection="{item}">
                      <template v-if="!item.identificationSample.includes('Banco')">
                        <span v-if="item.microbiologicalTest">
                          🔬 {{item.identificationSample}}
                        </span>
                        <span v-else>
                          🧬 {{item.identificationSample}}
                        </span>
                      </template>
                      <template v-else>
                        <img
                          src="/favicon.png"
                          width="25px"
                          height="25px"
                        /><img />
                        <span>
                          {{ item.identificationSample }}</span
                        >
                      </template>
                    </template>
                    <template v-slot:item="{ item }">
                      <template v-if="!item.identificationSample.includes('Banco')">
                        <span v-if="item.microbiologicalTest">
                          🔬 {{item.identificationSample}}
                        </span>
                        <span v-else>
                          🧬 {{item.identificationSample}}
                        </span>
                      </template>
                      <template v-else>
                        <img
                          src="/favicon.png"
                          width="25px"
                          height="25px"
                        /><img />
                        <span class="font-weight-bold">
                          {{ item.identificationSample }}</span
                        >
                      </template>
                    </template>
                  </v-select>
                </v-row>
              </v-col>
              <v-col cols="1" class="px-0 pb-0" v-if="bcTestsSelected.length > 1">
                <v-row justify="center">
                  <v-btn @click="removeTest(index)" color="red" fab x-small dark>
                    <v-icon>mdi-delete-forever</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-form>  
      </v-card-text>
      
      <v-card-actions class="justify-center">
        <v-row class="pt-0 mt-0">
          <v-col cols="4">
            <v-btn depressed block color="warning" @click="dialog = false">
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn depressed block color="secondary" @click="addTest">
              Añadir ensayo
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn depressed block color="primary" @click="createTests">
              Continuar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
// validations
import {
  requiredValidation,
  requiredFValidation,
} from "@/helpers/validations.js";
// library
import axios from "axios";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      loadingAnalysis: false,
      valid: true,
      analysisToFound: "",
      analysisSelected: null,
      // autocomplete
      searchAnalysisList: "",
      search: [],
      // items dilution
      dilutionItems: [
        { dilution: "P", inverse: 0.667 },
        { dilution: "Direct", inverse: 1 },
        { dilution: "-6", inverse: 1000000 },
        { dilution: "-5", inverse: 100000 },
        { dilution: "-4", inverse: 10000 },
        { dilution: "-3", inverse: 1000 },
        { dilution: "-2", inverse: 100 },
        { dilution: "-1", inverse: 10 },
      ],
      // rules
      analysisRules: requiredFValidation("muestra"),
      testMediaRules: requiredValidation("ID de medio"),
      inoculumRules: requiredValidation("Inóculo"),
      dilutionRules: requiredFValidation("dilución"),
      user: JSON.parse(localStorage.getItem("user")),
      bcTestsSelected: [
        {
          testMedia: null,
          medium: null,
          testType: "P. Sideróforos",
          inoculum: null,
        },
      ],
      testTypes: ["P. Sideróforos", "P. AIA", "A. Celulolítica"],
      inocules: [],
    };
  },
  watch: {
    // Search test media
    // async search(search) {
    //   if (this.dialog) {
    //     if (typeof this.cancelToken != typeof undefined) {
    //       this.cancelToken.cancel("Operation canceled due to new request.");
    //     }
    //     //Save the cancel token for the current request
    //     this.SET_CANCEL_TOKEN(axios.CancelToken.source());

    //     if (search) {
    //       try {
    //         const response = await this.searchTestMedia({
    //           laboratory: this.user.laboratory._id,
    //           status: "UNREGISTERED",
    //         });
    //         if (response.error) throw response;
    //       } catch (error) {
    //         console.log("Error searching test media", error);
    //         this.SET_ALERT_TYPE({
    //           visible: true,
    //           type: "error",
    //           text: error.message,
    //           timeout: 4000,
    //         });
    //       }
    //     }
    //   }
    // },
    // search samples that are validated
    async searchAnalysisList(value) {
      this.loadingAnalysis = true;

      if (value && typeof value == "string") {
        this.analysisSelected = null;

        if (typeof this.cancelToken != typeof undefined) {
          this.cancelToken.cancel("Operation canceled due to new request.");
        }
        //Save the cancel token for the current request
        this.SET_CANCEL_TOKEN(axios.CancelToken.source());

        try {
          const response = await this.searchAnalysis({
            laboratory: this.user.laboratory._id,
            status: ["MEASURED", "VALUATING", "VALUATED", "RECEIVED"],
            search: value,
          });

          if (response.error) throw response;

          try {
            const response = await this.searchTestMedia({
              laboratory: this.user.laboratory._id,
              status: "UNREGISTERED",
            });
            if (response.error) throw response;
          } catch (error) {
            console.log("Error searching test media", error);
            this.SET_ALERT_TYPE({
              visible: true,
              type: "error",
              text: error.message,
              timeout: 4000,
            });
          }
        } catch (error) {
          console.log("Error searching sample", error);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: error.message,
            timeout: 4000,
          });
        }
      } else {
        await this.processAnalysis(value);
      }

      this.loadingAnalysis = false;
    },
    dialog: function () {
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.bcTestsSelected = [
          {
            testMedia: null,
            medium: null,
            testType: "",
            inoculum: null,
          },
        ];
      }
    },
    analysisToFound() {
      let analysisString = this.analysisToFound;
      this.searchAnalysisList = analysisString;
    },
  },
  computed: {
    ...mapState(["cancelToken"]),
    ...mapState("reception", ["analysesFounded"]),
    ...mapState("testMedia", ["testMediaList"]),
    ...mapState("strain", ["bioactiveBankStrains"]),
  },
  methods: {
    ...mapMutations(["SET_CANCEL_TOKEN"]),
    ...mapMutations(["SET_LOADER"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("testMedia", ["searchTestMedia"]),
    ...mapActions("microbiologicalTest", ["createMBTest"]),
    ...mapActions("biochemicalTest", ["addBCTest"]),
    ...mapActions("bioStimulation", ["addBiostimulationTest"]),
    ...mapActions("reception", [
      "searchValidatedReception",
      "getStrainsByAnalysis",
      "searchAnalysis",
    ]),
    // add sample to array of selected tests
    addTest() {
      this.bcTestsSelected.push({
        testMedia: null,
        medium: null,
        testType: "P. Sideróforos",
        inoculum: null,
      });
    },
    // remove sample to array of selected tests
    removeTest(index) {
      this.bcTestsSelected.splice(index, 1);
    },
    // assing data selected for dilution to the actual row
    assingDataRow(i) {
      this.bcTestsSelected[i].dilution =
        this.bcTestsSelected[i].testMedia &&
        this.bcTestsSelected[i].testMedia.cultureMedia &&
        this.bcTestsSelected[i].testMedia.cultureMedia.dilution
          ? this.bcTestsSelected[i].testMedia.cultureMedia.dilution
          : null;

      if (this.bcTestsSelected[i].dilution) {
        this.bcTestsSelected[i].medium =
          this.bcTestsSelected[i].testMedia.cultureMedia.nutrientAgar;
      }
    },
    // get microorganism type
    getMOType(type) {
      let itemsMoType = {
        all: "Todos",
        bacteria: "Bacteria",
        fungi: "Hongo",
        nematode: "Nemátodo",
      };
      return itemsMoType[type];
    },
    async createTests() {
      if (this.$refs.form.validate()) {
        this.SET_LOADER(true);

        try {
          let bcTestSiderophores = this.analysisSelected.bcTests.find(
            (test) => test.type == "Biostimulation-siderophores"
          );
          let bcTestAIA = this.analysisSelected.bcTests.find(
            (test) => test.type == "Biostimulation-indoleaceticAcid"
          );
          let bcTestCellActivity = this.analysisSelected.bcTests.find(
            (test) => test.type == "Biostimulation-cellulolyticActivity"
          );

          console.log("BIOCHEMICAL TESTS", this.analysisSelected.bcTests);

          for (const test of this.bcTestsSelected) {
            let bioStimulationTest = {};
            if (test.testType == "P. Sideróforos" && bcTestSiderophores) {
              bioStimulationTest = this.formatTestData(
                test,
                bcTestSiderophores
              );
            } else if (test.testType == "P. AIA" && bcTestAIA) {
              bioStimulationTest = this.formatTestData(test, bcTestAIA);
            } else if (
              test.testType == "A. Celulolítica" &&
              bcTestCellActivity
            ) {
              bioStimulationTest = this.formatTestData(
                test,
                bcTestCellActivity
              );
            } else {
              let bcTest = {
                user: this.user._id,
                type:
                  test.testType == "P. Sideróforos"
                    ? "Biostimulation-siderophores"
                    : test.testType == "P. AIA"
                    ? "Biostimulation-indoleaceticAcid"
                    : "Biostimulation-cellulolyticActivity",
                status: "IN_PROGRESS",
                analysis: this.analysisSelected._id,
                laboratory: this.user.laboratory._id,
              };

              console.log("NEW BC TEST", bcTest);

              let request = await this.addBCTest(bcTest);

              if (request.error) throw new Error(request.message);

              if (
                request.biochemicalTest.type == "Biostimulation-siderophores"
              ) {
                bcTestSiderophores = request.biochemicalTest;
                bioStimulationTest = this.formatTestData(
                  test,
                  bcTestSiderophores
                );
              } else if (
                request.biochemicalTest.type ==
                "Biostimulation-indoleaceticAcid"
              ) {
                bcTestAIA = request.biochemicalTest;
                bioStimulationTest = this.formatTestData(test, bcTestAIA);
              } else {
                bcTestCellActivity = request.biochemicalTest;
                bioStimulationTest = this.formatTestData(
                  test,
                  bcTestCellActivity
                );
              }
            }
            const response = await this.addBiostimulationTest(
              bioStimulationTest
            );
            if (response.error) {
              throw response;
            }
          }

          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: "Nutriciónes agregadas correctamente.",
            timeout: 4000,
          });

          this.$emit("newTests");
          this.dialog = false;
        } catch (error) {
          console.log(error);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text:
              error.message === "testMediaRegisted"
                ? "Ya está registrado este medio de cultivo"
                : "Ha ocurrido un error, inténtalo de nuevo",
            timeout: 4000,
          });
        }
        this.SET_LOADER(false);
      }
    },
    formatTestData(test, bqTest) {
      if (bqTest.type == "Biostimulation-indoleaceticAcid") {
        return {
          status: "IN_PROGRESS",
          productionResults: [
            {
              time: 24,
              result: "0",
              ppm: 0,
            },
            {
              time: 36,
              result: "0",
              ppm: 0,
            },
            {
              time: 48,
              result: "0",
              ppm: 0,
            },
            {
              time: 72,
              result: "0",
              ppm: 0,
            },
            {
              time: 96,
              result: "0",
              ppm: 0,
            },
            {
              time: 120,
              result: "0",
              ppm: 0,
            },
            {
              time: 144,
              result: "0",
              ppm: 0,
            },
          ],
          biochemicalTest: bqTest._id,
          phytohormone:
            bqTest.type == "Biostimulation-siderophores"
              ? "production_siderophores"
              : bqTest.type == "Biostimulation-indoleaceticAcid"
              ? "production_indoleaceticAcid"
              : "cellulolytic_activity",
          hasCellulolyticActivity: false,
          strain: test.inoculum.strainId,
          soilSample: test.inoculum.soilSample
            ? test.inoculum.soilSample
            : undefined,
          laboratory: this.user.laboratory._id,
        };
      } else {
        return {
          status: "INOCULATED",
          growth: 0,
          biochemicalTest: bqTest._id,
          phytohormone:
            bqTest.type == "Biostimulation-siderophores"
              ? "production_siderophores"
              : bqTest.type == "Biostimulation-indoleaceticAcid"
              ? "production_indoleaceticAcid"
              : "cellulolytic_activity",
          testMedia: test.testMedia._id,
          inoculation: new Date(),
          hasCellulolyticActivity:
            bqTest.type == "Biostimulation-cellulolyticActivity"
              ? false
              : undefined,
          strain: test.inoculum.strainId,
          soilSample: test.inoculum.soilSample
            ? test.inoculum.soilSample
            : undefined,
          laboratory: this.user.laboratory._id,
        };
      }
    },

    async processAnalysis(analysisSelected) {
      this.inocules = [];
      this.analysisSelected = analysisSelected;

      this.bcTestsSelected = this.bcTestsSelected.map((test) => {
        return {
          ...test,
          inoculum: null,
        };
      });

      if (analysisSelected) {
        let analysisId = analysisSelected._id;

        let request = await this.getStrainsByAnalysis({
          analysis: analysisId,
          threath: false,
          laboratory : this.user.laboratory._id
        });

        if (request.error) throw new Error();

        let inocules = [];
        for (const strain of request.strains) {
          if (!strain.posibleThreat) {
            let obj = {
              strainId: strain._id,
              microbiologicalTest: strain.microbiologicalTest,
              identification: strain.identification.name,
              identificationSample: `${strain.identification.name} - ${strain.sampleFolio}`,
              moType: strain.identification.moType,
              concentration: strain.concentration,
              soilSample: strain.soilSample,
            };

            inocules.push(obj);
          }
        }

        for (const strain of this.bioactiveBankStrains) {
          let obj = {
            strainId: strain._id,
            identification: strain.identification.name,
            identificationSample: `${strain.identification.name} - Banco de bioactivos`,
            moType: strain.identification.moType,
          };

          inocules.push(obj);
        }

        this.inocules = inocules;
      }
    },
  },
};
</script>
