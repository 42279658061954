<template>
<vue-easy-lightbox scrollDisabled escDisabled moveDisabled :visible="visible" :imgs="img" @hide="handleHide">
</vue-easy-lightbox>
</template>

<script>
export default {
    methods: {
        handleHide() {
            this.$emit("hideSubSampleModal")
        }
    },
    props: {
        img: Array,
        visible: Boolean
    },
}
</script>

<style>
.vel-img-title {
    -webkit-text-stroke-width: thin;
    color: black !important;
    letter-spacing: 2px !important;
}
</style>
