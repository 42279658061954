// Set laboratories list
export function SET_LABORATORIES(state, laboratories) {
  state.laboratories = laboratories;
}

export function SET_SELECTED_MOLECULAR_MARKER_TO_UPDATE(
  state,
  molecularMarker
) {
  state.molecularMarkerToUpdate = molecularMarker;
}

export function SET_TEST_DATA(state, testData) {
  state.testData = testData;
}

export function SET_RAW_DATA(state, rawData) {
  state.rawData = rawData;
}