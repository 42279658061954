<template>
  <div>
    <v-row>
      <!-- testmedia -->
      <v-col cols="12" md="4">
        <!-- loader -->
        <v-skeleton-loader
          v-if="loading"
          type="table"
          :types="{
            'table-thead': 'heading@4',
            'table-row': 'table-cell@4',
          }"
        />
        <TestMedia v-else />
      </v-col>
      <!-- mbtests -->
      <v-col cols="12" md="8">
        <!-- loader -->
        <v-skeleton-loader
          v-if="loading"
          type="table"
          :types="{
            'table-thead': 'heading@4',
            'table-row': 'table-cell@4',
          }"
        />
        <MBTable v-else />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// vuex
import { mapActions, mapMutations } from "vuex";
// components
import TestMedia from "@/components/station2/TestMedia";
import MBTable from "@/components/station2/MBTable";

export default {
  name: "MediaAndInoculation",
  components: { TestMedia, MBTable },
  data() {
    return {
      loading: false,
      // user data
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  async created() {
    this.getTestMediaData();
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("cultureMedia", ["getCultureMedia"]),

    async getTestMediaData() {
      try {
        this.loading = true;

        // get available culture media
        const response = await this.getCultureMedia();
        if (response.error) throw response;

        this.loading = false;
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: error.message,
          timeout: 4000,
        });
      }
    },
  },
};
</script>

<style scoped></style>
