import axios from "axios";

/**
 * Function to get a nematode by sample
 * @param {Object} context - Vuex functions.
 * @param {Object} payload - fields from strain
 * @returns Object Strain added.
 */
export async function addStrain({ commit }, payload) {
  try {
    const { data } = await axios.post("/strain/add", payload);
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

/**
 * Function to get a nematode by sample
 * @param {Object} context - Vuex functions.
 * @param {Object} payload - fields from strain
 * @returns Object Strain updated.
 */
export async function updateStrain({ commit }, payload) {
  try {
    const { data } = await axios.put(
      `/strain/update/${payload.strainId}`,
      payload.strain
    );
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get a nematode by sample
 * @param {Object} context - Vuex functions.
 * @param {String} payload - sample id
 * @returns Array object of nematode strains
 */
export async function getNematodeStrainsBySample({ commit }, payload) {
  try {
    const { data } = await axios.get(`/strain`, {
      params: {
        filters: { soilSample: payload, nematodeTest: { $exists: true } },
      },
    });
    if (data.error) throw data;
    commit("SET_STRAIN_LIST", data.STRAINS);
    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function getStrainsByMBTest({ commit }, mbTestId) {
  try {
    const { data } = await axios.get(
      `/strainsByMicrobiologicalTest/${mbTestId}`
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function getStrainById({ commit }, id) {
  try {
    const { data } = await axios.get(`/strain/${id}`);
    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function removeStrain({ commit }, { strainId, mbTestId }) {
  try {
    const { data } = await axios.delete(`/strain/remove/${strainId}`, {
      params: { mbTestId },
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get a strains from bioactive bank
 * @param {Object} context - Vuex functions.
 * @returns Array object of bioactive bank strains
 */
export async function getBioactiveBankStrains({ commit }) {
  try {
    const { data } = await axios.get(`/strain`, {
      params: { filters: { bioactiveBank: true } },
    });
    if (data.error) throw data;
    commit("SET_BIOACTIVE_BANK_STRAINS_LIST", data.STRAINS);
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to upload isolate photo to aws s3
 * @param {Object} { state, dispatch } - vuex functions
 * @param {Image} image - isolate image
 */
export async function uploadStrainPhoto({ state }, image) {
  try {
    const { data } = await axios.post("/strain/uploadImageStrain", image);
    if (data.error) throw data;
    return data;
  } catch (error) {
    return error;
  }
}
