// Set list of biostimulations.
export function SET_BIOSTIMULATION_TEST_LIST(state, biostimulationTestsList) {
  if (state.biostimulationNegativeControl) {
    state.biostimulationTestsList = [
      state.biostimulationNegativeControl,
      ...biostimulationTestsList,
    ];
  } else {
    state.biostimulationTestsList = biostimulationTestsList;
  }
}

// Select current biostimulation test.
export function SET_SELECTED_BIOSTIMULATION_TEST(state, biostimulationTest) {
  state.biostimulationTestSelected = biostimulationTest;
}

// Set control of biostimulation test.
export function SET_BIOSTIMULATION_NEGATIVE_CONTROL(state, data) {
  state.biostimulationNegativeControl = data;
}
