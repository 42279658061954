export default {
    containerSelected: null,
    containers: [],
    pctSelected:null,
    textures: [
      {
        text: "Arcilla",
        value: "clay",
        clay: [40, 100],
        silt: [0, 40],
        sand: [0, 45],
      },
      {
        text: "Arcillo arenoso",
        value: "sandyClay",
        clay: [35, 55],
        silt: [0, 20],
        sand: [45, 65],
      },
      {
        text: "Arcillo limoso",
        value: "siltyClay",
        clay: [40, 60],
        silt: [40, 60],
        sand: [0, 20],
      },
      {
        text: "Franco arcilloso",
        value: "clayLoam",
        clay: [27, 40],
        silt: [15, 53],
        sand: [20, 45],
      },
      {
        text: "Franco arcillo limoso",
        value: "siltyClayLoam",
        clay: [27, 40],
        silt: [40, 72],
        sand: [9, 38],
      },
      {
        text: "Franco arcillo arenoso",
        value: "sandyClayLoam",
        clay: [20, 35],
        silt: [0, 28],
        sand: [45, 80],
      },
      {
        text: "Franco arenoso",
        value: "sandyLoam",
        clay: [0, 20],
        silt: [0, 50],
        sand: [45, 70],
      },
      {
        text: "Franco",
        value: "loam",
        clay: [5, 27],
        silt: [27, 50],
        sand: [23, 52],
      },
      {
        text: "Franco limoso",
        value: "siltLoam",
        clay: [0, 27],
        silt: [50, 80],
        sand: [0, 50],
      },
      {
        text: "Limo",
        value: "silt",
        clay: [0, 13],
        silt: [80, 100],
        sand: [0, 20],
      },
      {
        text: "Arena",
        value: "sand",
        clay: [0, 10],
        silt: [0, 15],
        sand: [85, 100],
      },
      {
        text: "Arenoso franco",
        value: "loamySand",
        clay: [0, 15],
        silt: [0, 30],
        sand: [70, 90],
      },
    ],
    adjustments:[
      {
        temperature: 15,
        value: -1.62,
      },
      {
        temperature: 15.5,
        value: -1.44,
      },
      {
        temperature: 16,
        value: -1.26,
      },
      {
        temperature: 16.5,
        value: -1.08,
      },
      {
        temperature: 17,
        value: -0.90,
      },
      {
        temperature: 17.5,
        value: -0.72,
      },
      {
        temperature: 18,
        value: -0.54,
      },
      {
        temperature: 18.5,
        value: -0.36,
      },
      {
        temperature: 19,
        value: -0.18,
      },
      {
        temperature: 19.5,
        value: 0,
      },
      {
        temperature: 20,
        value: 0.18,
      },
      {
        temperature: 20.5,
        value: 0.36,
      },
      {
        temperature: 21,
        value: 0.54,
      },
      {
        temperature: 21.5,
        value: 0.18,
      },
      {
        temperature: 22,
        value: 0.90,
      },
      {
        temperature: 22.5,
        value: 1.08,
      },
      {
        temperature: 23,
        value: 1.26,
      },
      {
        temperature: 23.5,
        value: 1.44,
      },
      {
        temperature: 24,
        value: 1.62,
      },
      {
        temperature: 24.5,
        value: 1.80,
      },
      {
        temperature: 25,
        value: 1.98,
      },
      {
        temperature: 25.5,
        value: 2.15,
      },
      {
        temperature: 26,
        value: 2.34,
      },
      {
        temperature: 26.5,
        value: 2.52,
      },
      {
        temperature: 27,
        value: 2.70,
      },
      {
        temperature: 27.5,
        value: 2.858,
      },
      {
        temperature: 28,
        value: 3.06,
      },
      {
        temperature: 29,
        value: 3.42,
      },
      {
        temperature: 30,
        value: 3.78,
      },
    ],
  };
  