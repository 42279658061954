<template>
  <v-dialog v-model="dialog" width="750">
    <template v-slot:activator="{ on, attrs }">
      <v-fade-transition>
        <v-btn
          v-show="samples.length > 0"
          v-bind="attrs"
          v-on="on"
          class="ml-1"
          color="success"
          small
          >Muestras a secuenciación</v-btn
        >
      </v-fade-transition>
    </template>

    <v-card>
      <v-card-title class="text-h5 secondary white--text">
        Muestras para secuenciación
      </v-card-title>

      <v-card-text>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-combobox
                    v-model="samples"
                    :items="samples"
                    item-text="folio_sample"
                    label="Muestras seleccionadas"
                    return-object
                    readonly
                    multiple
                    chips
                    ></v-combobox>
                </v-col>
                <v-col cols="9">
                  <v-combobox
                    v-model="reportSelected"
                    :items="sequencingReports"
                    :loading="loading"
                    item-text="name"
                    label="Reporte"
                    return-object
                    @update:search-input="findReport"
                  ></v-combobox>
                </v-col>
                <v-col cols="3">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-switch v-bind="attrs" v-on="on" v-model="assignedAutomatically" label="Asignación automática" hide-details></v-switch>
                    </template>
                    <span>Asignación automática de muestras a placa</span>
                  </v-tooltip>
                </v-col>
            </v-row>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog" :disabled="loadingSaving"> Cancelar </v-btn>
        <v-btn color="primary" text @click="saveSamples" :loading="loadingSaving"> Aceptar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
  data: () => ({
    dialog: false,
    loading : false,
    loadingSaving : false,
    sequencingReports : [],
    reportSelected : null,
    assignedAutomatically : true,
    filters: {
        search: "",
        limit: 10,
        page: 1,
        pages: 0,
        total: 0,
    },
  }),
  props: {
    samples: {
      type: Array,
      require: true,
      default: []
    },
  },
  methods : {
    ...mapActions('sequencingReport', ['getAllReports', 'assignReportInSamples']),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async getReports() {
      this.loading = true;

      try {
        const request = await this.getAllReports({ ...this.filters });

        this.sequencingReports = request.reports;

        this.filters.pages = request.pages;
        this.filters.total = request.totalReports;

        // console.log(request);
      } catch (error) {
        console.error(error);
      }

      this.loading = false;
    },
    async findReport(value){
        this.filters.search = value;
        await this.getReports();
    },
    closeDialog(){
        this.dialog = false;
        this.loading  = false;
        this.loadingSaving = false;
        this.sequencingReports  = [];
        this.reportSelected  = null;
        this.filters = {
            search: "",
            limit: 10,
            page: 1,
            pages: 0,
            total: 0,
        };
    },
    async saveSamples(){
        this.loadingSaving = true;

        try {    
            if(this.reportSelected && typeof this.reportSelected == 'object'){

                const payload = {
                    assignedAutomatically : this.assignedAutomatically,
                    reportId : this.reportSelected._id, 
                    samples : this.samples.map(s => s.folio_sample)
                };
                
                await this.assignReportInSamples(payload);

                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "success",
                    text: "Muestras asignadas al reporte",
                    timeout: 4000,
                });

                this.closeDialog();
                this.$emit('samplesUpdated');
            }else{
                this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: "Debes seleccionar un reporte.",
                    timeout: 4000,
                });
            }
            
        } catch (error) {
            this.SET_ALERT_TYPE({
                visible: true,
                type: "error",
                text: `Error al guardar reporte. Error: ${error.message}`,
                timeout: 5000,
            });
        }

        this.loadingSaving = false;
    }
  },
};
</script>

<style>
</style>