<template>
  <v-card>
    <v-toolbar color="secondary" dark>Posibles identificaciónes</v-toolbar>

    <v-container class="pt-5">
      <v-row>
        <v-col cols="12" class="py-1">
          <v-autocomplete
            v-model="identification"
            :search-input.sync="search"
            :items="identificationsFounded"
            :loading="isLoading"
            @keydown="changeIdentificationValue"
            label="Buscador"
            placeholder="Buscador"
            item-text="name"
            return-object
            outlined
            dense
            no-data-text="No hay identificaciones"
            :error="error"
            :error-messages="errorMessage"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row justify="center" align="center" class="mt-0">
        <v-col cols="12" class="pt-0">
          <p class="text-center mb-0">Posibles identificaciónes</p>
        </v-col>
      </v-row>
      <v-row
        v-for="(identificationPossible, index) in possibleIdentifications"
        :key="index"
      >
        <v-col cols="9" class="py-1">
          <p v-if="type == 'bacteria'" class="mt-0 text-body-2">🦠 {{ identificationPossible.name }}</p>
          <p v-else class="mt-0 text-body-2">🍄 {{ identificationPossible.name }}</p>
        </v-col>
        <v-col cols="3" class="py-1">
          <v-btn
            v-if="possibleIdentifications.length > 1"
            @click="deleteIdentification(index)"
            color="red"
            fab
            x-small
            dark
          >
            <v-icon>mdi-delete-forever</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions>
      <v-spacer></v-spacer>

      <!-- <v-btn text color="warning" @click="menu = false">
        Cerrar
      </v-btn> -->
      <add-new-identification-modal :moType="type"/>
      <v-btn color="primary" text @click="addPosibleIdentification">
        Agregar identifcación
      </v-btn>
      <v-btn
        color="secondary"
        text
        :disabled="possibleIdentifications.length == 0"
        @click="saveIdentifications()"
      >
        Aceptar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import AddNewIdentificationModal from './modals/addNewIdentificationModal.vue';

export default {
  components: {
    AddNewIdentificationModal
  },
  data() {
    return {
      error: false,
      errorMessage: "",
      identification: {
        name: null,
        moType: null,
      },
      search: "",
      isLoading: false,
      identifications: [],
      removedIdentifications: [],
      possibleIdentifications: [],
    };
  },
  props: {
    possibleIdentificationsStrain: {
      type: Array,
      require: true,
    },
    type : {
      type : String,
      require : true
    }
  },
  mounted() {
    this.SET_IDENTIFICATIONS([])
    for (const identification of this.possibleIdentificationsStrain) {
      if (identification.name != "Sin identificar") {
        this.possibleIdentifications.push(identification);
      }
    }
  },
  methods: {
    ...mapActions("identification", ["searchIdentifications"]),
    ...mapMutations("identification", ["SET_IDENTIFICATIONS"]),
    changeIdentificationValue() {
      if (!this.search) {
        this.identification = {};
      }
    },
    addPosibleIdentification() {
      if (this.identification.name != null) {
        let exists = this.possibleIdentifications.find(
          (id) => id._id == this.identification._id
        );
        if (typeof exists == "undefined") {
          this.error = false;
          this.possibleIdentifications.push({
            ...this.identification,
          });
        } else {
          this.errorMessage = "Ya existe la identificación en la lista";
          this.error = true;
        }
      } else if(this.identification.name == null && this.search == null) {
        this.errorMessage = "Debes agregar una identificación";
        this.error = true;
      } else if(this.identification.name == null && this.search != null){
        this.errorMessage = "No existe la identificación en la base de datos";
        this.error = true;
      }
    },
    deleteIdentification(index) {
      this.possibleIdentifications.splice(index, 1);
    },
    saveIdentifications() {
      this.$emit("aceptButton", this.possibleIdentifications);
    },
  },
  computed: {
    ...mapState("identification", ["identificationsFounded"]),
  },
  watch: {
    async search(val) {
      if (!val) return;
      this.isLoading = true;
      try {
        const response = await this.searchIdentifications({
          name: val,
          moType: this.type,
        });
        if (response.error) throw response;
      } catch (e) {
        console.log(e);
      }
      // Remove from autocomplete items if the identification is already in the table.
      this.identifications.forEach((identif, i) => {
        const index = this.identificationsFounded.findIndex(
          (i) => i._id === identif._id
        );
        if (index !== -1) {
          this.identificationsFounded.splice(index, 1);
        }
      });
      this.isLoading = false;
    },
  },
};
</script>
