import axios from "axios";

/**
 * Function to create a new culture medium
 * @param {function} dispatch - Vuex dispatch.
 * @param {Object} payload - culture medium info
 */
export async function createCultureMedium({ dispatch }, payload) {
  try {
    const { data } = await axios.post(`/cultureMedia/create`, payload);
    if (data.error) throw data;

    // Fetch culture media list
    const response = await dispatch("getCultureMedia");
    if (response.error) {
      throw response;
    }
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

/**
 * Function to get all culture media
 * @param  { Function } {commit} - Vuex function
 * @returns { data } - Returns array culture media
 */
export async function getCultureMedia({ commit }) {
  try {
    const { data } = await axios.get("/cultureMedia");

    if (data.error) throw data;
    commit("SET_CULTURE_MEDIA_LIST", data.CULTURE_MEDIA);
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

/**
 * Function to get culture media by sample type
 * @param  { Function } {commit} - Vuex function
 * @returns { data } - Returns array culture media
 */
export async function getCultureMediasBySampleType({ commit }, payload) {
  try {
    const { data } = await axios.get("/cultureMedia/medias-by-sampleType", {
      params: {
        sampleType: payload,
      },
    });

    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

/**
 * Function to get culture media by sample type
 * @param  { Function } {commit} - Vuex function
 * @returns { data } - Returns array culture media
 */
export async function getCultureMediasBySampleTypeLabels({ commit }, payload) {
  try {
    const { data } = await axios.get(
      "/cultureMedia/medias-by-sampleType-labels",
      {
        params: {
          sampleType: payload.sampleType,
          sampleComposition: payload.sampleComposition,
          samplingType: payload.samplingType,
        },
      }
    );

    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}
