<template>
  <v-snackbar
    v-model="config.visible"
    :timeout="config.timeout"
    bottom
    right
    :class="config.type ? config.type : 'info'"
    id="snackbar-alert"
    class="fixed"
  >
    <v-icon color="white">
      {{
        config.type === "success"
          ? "mdi-check-circle"
          : config.type === "error"
          ? "mdi-close-circle"
          : "mdi-file-alert-outline"
      }}
    </v-icon>

    <span class="ml-5">{{ config.text }}</span>
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState("alerts", ["config"]),
  },
};
</script>

<style scoped>
span {
  font-family: "Montserrat", "Open Sans", sans-serif !important;
  margin: 0 5px;
}
</style>
