<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    fullscreen
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="warning" block class="py-8" v-bind="attrs" v-on="on">
        <v-icon left>mdi-barcode-scan</v-icon> Incubación de cajas
      </v-btn>
    </template>

    <template v-slot:default="{ isActive }">
      <v-card scrollable>
        <v-card-text class="px-0" ref="principal_card">
          <v-toolbar color="primary" class="white--text">
            <h2>
              <v-icon color="white">mdi-tag-multiple-outline</v-icon> Incubación
              de cajas
            </h2>
            <v-spacer></v-spacer>
            <v-btn icon color="white" @click="dialog = false">
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-container>
            <v-row align="center" justify="center">
              <v-col cols="8" md="4">
                <VTextField
                  v-model="test_string"
                  ref="inputBarCode"
                  prepend-icon="mdi-barcode-scan"
                  label="Código de barras"
                  :autofocus="true"
                />
              </v-col>
              <audio
                ref="audioElement"
                src="https://www.soundjay.com/buttons/sounds/beep-07a.mp3"
              ></audio>
              <audio
                ref="audioElementError"
                src="https://www.soundjay.com/buttons/sounds/beep-09.mp3"
              ></audio>
              <v-col cols="4" md="2">
                <VSwitch
                  :label="use_camera ? 'Uso de cámara' : 'Uso de scanner'"
                  v-model="use_camera"
                ></VSwitch>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" v-if="use_camera">
              <v-col cols="10" md="6">
                <div id="reader" class="qr-reader-container"></div>
                <!-- <barcode-scanner
                  style="width: 100%"
                  class="barcode-scanner"
                  :source="camera"
                  @bcs-scanned="scanned"
                  @bcs-started="camera_started"
                  ref="scanner"
              /> -->
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="12" class="text-center">
                <span>Últimos ensayos incubados</span>
                <v-divider />
              </v-col>
              <v-col cols="12" md="8">
                <VDataTable
                  :headers="headers"
                  :items="last_tests_incubated"
                  hide-default-footer
                  height="23vh"
                  dense
                  :items-per-page="4"
                >
                  <template #[`item.folio_analysis_strain`]="{ item }">
                    {{
                      item.type == "Caja madre"
                        ? item.folio_analysis_strain
                        : item.folio
                    }}
                  </template>
                </VDataTable>
              </v-col></v-row
            >
          </v-container>
        </v-card-text>
      </v-card>
      <v-dialog
        v-model="incubation_all_data.dialog_incubate_all"
        width="600px"
        persistent
        transition="dialog-bottom-transition"
        hide-overlay
      >
        <v-toolbar
          color="warning"
          class="white--text"
          title="Interrumpir ensayos"
        >
          <h2>Interrumpir ensayos</h2>
        </v-toolbar>
        <v-card-text style="background: white">
          <div class="text-h6 pa-6">
            {{ incubation_all_data.incubate_all_message }}
          </div>
        </v-card-text>
        <v-card-actions class="justify-end" style="background: white">
          <v-btn
            text
            color="warning"
            variant="text"
            @click="incubation_all_data.dialog_incubate_all = false"
            >No incubar</v-btn
          >
          <v-btn
            text
            color="primary"
            variant="text"
            @click="incubateAllTests"
            :loading="incubation_loader"
            ><b>Incubar</b></v-btn
          >
        </v-card-actions>
      </v-dialog>
    </template>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
// import BarcodeScanner from "@undecaf/vue-barcode-scanner";
import { Html5Qrcode } from "html5-qrcode";
export default {
  components: {
    // BarcodeScanner,
  },
  data() {
    return {
      html5QrCodeScanner: null,
      dialog: false,
      dialogLabels: false,
      incubation_loader: false,
      test_string: "",
      bar_code: "",
      camera: null,
      use_camera: false,
      test_incubated: {},
      incubation_all_data: {
        dialog_incubate_all: false,
        incubate_all_message: "",
        folio: "",
        stage: "",
      },
      last_tests_incubated: [],
      headers: [
        { text: "Folio", value: "testMedia", sortable: false, align: "center" },
        {
          text: "Tipo de ensayo",
          value: "type",
          sortable: false,
          align: "center",
        },
        {
          text: "Folio de análisis",
          value: "folio_analysis_strain",
          sortable: false,
          align: "center",
        },
      ],
      readerSize: {
        width: 400,
        height: 400,
      },
      detecteds: [
        "code_128_reader",
        "ean_reader",
        "ean_8_reader",
        "code_39_reader",
        "code_39_vin_reader",
        "codabar_reader",
        "upc_reader",
        "upc_e_reader",
        "i2of5_reader",
        "2of5_reader",
        "code_93_reader",
      ],
    };
  },
  mounted() {},
  methods: {
    ...mapActions("labels", ["incubate_test", "incubate_all_tests"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    // camera_started() {
    //   const videoElement = this.$refs.scanner.$refs.srcRendererElem;
    //   if (videoElement) {
    //     // Agrega la propiedad playsinline al elemento de video
    //     videoElement.setAttribute("playsinline", "");
    //   }
    // },
    startScanner() {
      const config = { fps: 10, qrbox: this.calculateQrBox() };

      this.html5QrCodeScanner = new Html5Qrcode("reader");

      // If you want to prefer back camera
      this.html5QrCodeScanner.start(
        { facingMode: "environment" },
        config,
        this.onScanSuccess
      );
    },
    stopScanner() {
      // Detener la lectura cuando el componente se destruye (opcional)
      if (this.html5QrCodeScanner) {
        this.html5QrCodeScanner
          .stop()
          .then((ignore) => {
            // QR Code scanning is stopped.
            this.html5QrCodeScanner = null;
          })
          .catch((err) => {
            // Stop failed, handle it.
            console.log("ERROR");
          });
      }
    },
    calculateQrBox() {
      // Calcula el tamaño del cuadro QR en función del ancho de la pantalla
      const screenWidth = window.innerWidth;
      const baseQrBox = 250;

      // Puedes ajustar estos valores según tus necesidades
      if (screenWidth < 576) {
        return baseQrBox * 0.8;
      } else if (screenWidth < 768) {
        return baseQrBox * 0.7;
      } else if (screenWidth < 992) {
        return baseQrBox * 0.8;
      } else if (screenWidth < 1200) {
        return baseQrBox * 0.9;
      } else {
        return baseQrBox;
      }
    },
    onScanSuccess(decodedText, decodedResult) {
      // handle the scanned code as you like, for example:
      if (decodedText) {
        if (this.test_string != decodedText) {
          this.test_string = decodedText;
        }
      }
    },
    async incubateAllTests() {
      try {
        this.incubation_loader = true;

        const res = await this.incubate_all_tests(this.incubation_all_data);

        console.log(res);
        if (res.error) throw res;

        for (const test of res.tests_incubated) {
          this.last_tests_incubated.unshift(test);
        }

        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: "Ensayos incubados correctamente.",
          timeout: 4000,
        });

        this.incubation_all_data.dialog_incubate_all = false;
        this.incubation_loader = false;
      } catch (error) {
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Error al incubar los ensayos. Comunicate a soporte.",
          timeout: 4000,
        });
        this.incubation_loader = false;
      }
    },
    async incubate_test_req(testMedia) {
      try {
        const req = await this.incubate_test(testMedia);
        console.log(req);

        if (req.error) {
          throw req;
        }

        if (req.test.validation_status) {
          if (this.test_incubated[`${req.test.folio}-${req.test.stage}`]) {
            this.test_incubated[`${req.test.folio}-${req.test.stage}`].push(
              req.test
            );

            if (
              this.test_incubated[`${req.test.folio}-${req.test.stage}`]
                .length >= 2
            ) {
              const folio_to_incubate =
                req.test.stage == "Valuacion" || req.test.stage == "Resiembra"
                  ? req.test.folio
                  : req.test.folio_analysis_strain;
              const stage_to_incubate = req.test.stage;
              this.incubation_all_data.stage = stage_to_incubate;
              this.incubation_all_data.folio = folio_to_incubate;

              if (req.test.stage == "Medicion") {
                this.incubation_all_data.incubate_all_message = `¿Deseas incubar todos los ensayos de medición del folio "${folio_to_incubate}"?`;
              } else if (req.test.stage == "Valuacion") {
                this.incubation_all_data.incubate_all_message = `¿Deseas incubar todos los ensayos de valuación del folio "${folio_to_incubate}" ?`;
              } else if (req.test.stage == "Resiembra") {
                this.incubation_all_data.incubate_all_message = `¿Deseas incubar todas las resiembras del folio "${folio_to_incubate}"?`;
              }

              this.incubation_all_data.dialog_incubate_all = true;
            }
          } else {
            this.test_incubated[`${req.test.folio}-${req.test.stage}`] = [
              req.test,
            ];
          }

          this.last_tests_incubated.unshift(req.test);

          this.playSound();

          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: "Ensayo interrumpido correctamente",
            timeout: 4000,
          });
        } else {
          this.playSound(true);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: `No se puede incubar el ensayo. ${req.test.validation_message}.`,
            timeout: 4000,
          });
        }
      } catch (error) {
        console.error(error);
        this.playSound(true);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Error al incubar el ensayo. Comunicate a soporte.",
          timeout: 4000,
        });
      }
    },
    scanned(barcodes) {
      if (barcodes.codeResult) {
        if (this.test_string != data.codeResult.code) {
          console.log(data.codeResult.code);
          this.test_string = data.codeResult.code;
        }
      }
    },
    playSound(error = false) {
      if (error) {
        const audioElementError = this.$refs.audioElementError;
        audioElementError.currentTime = 0;
        audioElementError.play();
      } else {
        const audioElement = this.$refs.audioElement;
        audioElement.currentTime = 0;
        audioElement.play();
      }
    },
    waitFor(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
  beforeDestroy() {
    this.stopScanner();
  },
  watch: {
    async use_camera() {
      if (this.use_camera) {
        await this.waitFor(500);
        this.startScanner();
        // navigator.mediaDevices
        //   .getUserMedia({ audio: false, video: { facingMode: "environment" } })
        //   .then((stream) => {
        //     this.camera = stream;
        //     const videoElement = this.$refs.scanner.$refs.srcRendererElem;
        //     if (videoElement) {
        //       // Agrega la propiedad playsinline al elemento de video
        //       videoElement.setAttribute("playsinline", "");
        //     }
        //   });
      } else {
        this.stopScanner();
        // this.$refs.inputBarCode.focus();
        this.test_string = "";
        // this.camera = null;
      }
    },
    async dialog(newValue) {
      if (!newValue) {
        this.use_camera = false;
        this.test_string = "";
        this.test_incubated = {};
        this.incubation_all_data = {
          dialog_incubate_all: false,
          incubate_all_message: "",
          folio: "",
          stage: "",
        };
        this.last_tests_incubated = [];
        this.camera = null;
      } else {
        // await this.incubateAllTests();
      }
    },
    async test_string(newTest, oldTest) {
      if (newTest.length == 6) {
        await this.incubate_test_req(newTest);
        this.playSound();
      }
    },
  },
};
</script>

<style scoped>
.viewport {
  width: 100%;
}

video {
  width: 100%;
}
.qr-reader-container {
  max-width: 100%;
  margin: auto;
}
</style>