<template>
  <v-card elevation="5" class="flex d-flex flex-column">
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-btn color="primary" block @click="showResultModal = true">
              <v-icon small class="mr-1"> mdi-file-eye-outline </v-icon>
              ver resultados
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn color="primary" block @click="showSummaryModal = true">
              <v-icon small class="mr-1"> mdi-chart-bar-stacked </v-icon>
              Resumen valuación
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-row justify="start" align="start" class="mb-2">
              <v-col cols="2"
                ><span class="primary--text text-caption font-weight-bold"
                  >Dosis</span
                ></v-col
              >
              <v-col cols="5"
                ><span class="primary--text text-caption font-weight-bold"
                  >Producto</span
                ></v-col
              >
              <v-col
                cols="12"
                xl="5"
                lg="5"
                md="5"
                v-if="
                  profit_version &&
                  (profit_version.status == 'NEW' ||
                    profit_version.status == 'PROPOSAL' ||
                    profit_version.status == 'REJECTED' ||
                    profit_version.status == 'STRAINS_SUGGESTION')
                "
              >
                <v-btn
                  small
                  block
                  color="primary"
                  @click="recommendStrains"
                  v-if="
                    permissions.find(
                      (per) => per == 'lab_profit_recommendation'
                    )
                  "
                  >Recomendar cepas</v-btn
                >
              </v-col>
            </v-row>
            <div v-if="strains.length > 0">
              <v-virtual-scroll :items="strains" height="770" :item-height="50">
                <template v-slot:default="{ item }">
                  <v-list-item :key="item._id">
                    <v-list-item-action>
                      <span class="text-body-2">{{ item.dose }}</span>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-container fluid v-if="item.type === 'Bioactivo'">
                        <span class="text-body-2 primary--text"
                          >{{ item.name }}*Solena</span
                        >
                      </v-container>
                      <v-container fluid v-else>
                        <span class="text-body-2">{{ item.name }}*Nativa</span>
                        <br />
                        <span>{{ item.sample }}</span>
                      </v-container>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox
                        :disabled="
                          profit_version &&
                          profit_version.status !== 'NEW' &&
                          profit_version.status !== 'PROPOSAL' &&
                          profit_version.status !== 'REJECTED' &&
                          profit_version.status !== 'STRAINS_SUGGESTION'
                        "
                        v-model="item.isSelected"
                        @click="selectMO(item, item.isSelected)"
                      ></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </div>
            <div v-else>
              <div v-for="item of 7" :key="item">
                <v-skeleton-loader
                  class="ma-7"
                  type="list-item-avatar, divide"
                />
              </div>
            </div>
          </v-col>
        </v-row>
        <SubSamplePhoto
          :img="img"
          :visible="showImageDialog"
          @hideSubSampleModal="hideSubSampleModal"
        />
        <ResultModal
          v-model="showResultModal"
          v-if="showResultModal"
          :profitId="profitId"
        />
        <SummaryModal v-model="showSummaryModal" v-if="showSummaryModal" />
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
//Vuex
import { mapActions, mapState, mapMutations } from "vuex";
//components
import SubSamplePhoto from "@/components/station1/modals/SubSamplePhoto";
import ResultModal from "@/components/Profit/modals/ResultModal";
import SummaryModal from "@/components/Profit/modals/SummaryModal";

export default {
  components: {
    SubSamplePhoto,
    ResultModal,
    SummaryModal,
  },
  props: {
    profitId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    confrontations: [],
    loadingConfrontations: true,
    currentConfrontations: null,
    currentFolio: null,
    // strains: [],
    folios: [],
    img: [],
    showImageDialog: false,
    showResultModal: false,
    showSummaryModal: false,
  }),
  computed: {
    ...mapState("profit", [
      "strains",
      "strains_selected",
      "profit_version",
      "months_data",
      "suggestions",
    ]),
    ...mapState("session", ["permissions"]),
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("profit", ["getBestStrains", "saveBestStrains"]),
    ...mapMutations("profit", [
      "SET_STRAINS",
      "PUSH_STRAINS_SELECTED",
      "REMOVE_STRAINS_SELECTED",
      "ADD_DOSE_STRAIN",
      "SET_SUGGESTIONS",
      "SET_SUMMARY",
      "SET_LOAD_PROFIT",
    ]),
    changedFolio() {},
    selectMO(item, flag) {
      if (flag) {
        this.PUSH_STRAINS_SELECTED(item);
      } else {
        this.REMOVE_STRAINS_SELECTED({ strains: this.strains, value: item });
      }
    },
    async recommendStrains() {
      try {
        let array = [];

        for (let item of this.strains) {
          if (item.isSelected) {
            array.push(item);
          }
        }
        let profitVersionId = this.$route.params.id;
        let response = await this.saveBestStrains({
          id: profitVersionId,
          id_profit: this.profitId,
          productsSugestions: array,
        });
        if (response) {
          if (!response.error) {
            this.SET_SUGGESTIONS(response.productsSugestions);
            this.SET_ALERT_TYPE({
              visible: true,
              type: "success",
              text: "Cepas recomendadas !!",
              timeout: 5000,
            });
          } else {
            console.log(e);
            this.SET_ALERT_TYPE({
              visible: true,
              type: "error",
              text: "Ago salio mal al recomendar producto !!",
              timeout: 5000,
            });
          }
        } else {
          console.log(e);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: "Ago salio mal al recomendar producto !!",
            timeout: 5000,
          });
        }
      } catch (e) {
        console.log(e);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Ago salio mal al recomendar producto !!",
          timeout: 5000,
        });
      }
    },
    showImage(img) {
      this.img = [
        {
          title: "",
          src: img,
        },
      ];
      this.showImageDialog = true;
    },
    hideSubSampleModal(value) {
      this.showImageDialog = value;
    },
  },
  async created() {
    try {
      let profitId = this.profitId;
      let response = await this.getBestStrains({
        id: profitId,
      });
      if (response) {
        if (!response.error) {
          this.SET_SUMMARY(response.summary);
          this.confrontations = response.confrontations;
          let strains = response.strains;
          let aux = null;
          for (let strain of strains) {
            if (strain.name === "Ag Boost 200") {
              aux = strain;
            }
            for (let sugestion of response.suggestions) {
              if (sugestion.strainId == strain.strainId) {
                if (sugestion.isSelected) {
                  strain.isSelected = true;
                  strain.dose = sugestion.dose;
                  this.PUSH_STRAINS_SELECTED(strain);
                }
              }
            }
          }
          if (response.suggestions.length == 0) {
            aux.isSelected = true;
            aux.dose = 0;
            this.PUSH_STRAINS_SELECTED(aux);
          }
          // this.strains = strains;
          this.SET_STRAINS(strains);
          if (response.confrontations.length > 0) {
            this.currentFolio = response.confrontations[0].folio;
            for (let item of response.confrontations) {
              this.folios.push(item.folio);
            }
          }

          let allStrains = [];
          for (let month = 0; month < this.months_data.length; month++) {
            for (let week = 0; week < this.months_data[month].length; week++) {
              for (
                let dose = 0;
                dose < this.months_data[month][week].doses.length;
                dose++
              ) {
                for (
                  let prod = 0;
                  prod < this.months_data[month][week].doses[dose].length;
                  prod++
                ) {
                  for (let strain of this.strains) {
                    if (
                      this.months_data[month][week].doses[dose][prod].strain
                    ) {
                      if (
                        strain.strainId ==
                        this.months_data[month][week].doses[dose][prod].strain
                          .strainId
                      ) {
                        let total = 0;
                        let doseNumber = Number(
                          this.months_data[month][week].doses[dose][prod].dose
                        );
                        let oldValue = allStrains[strain.strainId];
                        if (oldValue) {
                          total = oldValue + doseNumber;
                        } else {
                          total = doseNumber;
                        }
                        allStrains[strain.strainId] = total;
                        break;
                      }
                    }
                  }
                }
              }
            }
          }
          this.ADD_DOSE_STRAIN(allStrains);
          this.SET_LOAD_PROFIT(1);
        } else {
          console.log(e);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: "Algo salio mal al cargar el producto !!",
            timeout: 5000,
          });
        }
      } else {
        console.log(e);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Algo salio mal al cargar el producto !!",
          timeout: 5000,
        });
      }
    } catch (e) {
      console.log(e);
      this.SET_ALERT_TYPE({
        visible: true,
        type: "error",
        text: "Algo salio mal al cargar el producto !!",
        timeout: 5000,
      });
    }

    this.loadingConfrontations = false;
  },
  watch: {
    currentFolio() {
      for (let item of this.confrontations) {
        if (item.folio === this.currentFolio) {
          this.currentConfrontations = item;
          break;
        }
      }
    },
  },
};
</script>
