<template>
  <v-container>
    <v-data-table
      v-model="checkBoxesSelected"
      :item-class="row_classes"
      :items="molecularTests"
      :headers="headers"
      :loading="loading"
      item-key="_id"
      show-select
      hide-default-footer
      dense
      @click:row="rowSelect"
    >
      <template v-slot:[`item.sampleType`]="{ item }">
        <span
          v-if="item.sampleType == 'Initial' || item.sampleType == 'INITIAL'"
          >Inicial</span
        >
        <span v-else>Seguimiento</span>
      </template>
      <template v-slot:[`item.file`]="{ item }">
        <small>{{ item.file }}</small>
      </template>
    </v-data-table>
    <v-row justify="center" align="center" class="pt-5">
      <v-pagination
        @input="getData"
        v-model="pagination.page"
        total-visible="12"
        :length="length"
        color="primary"
      ></v-pagination>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false,
      checkBoxesSelected: [],
      headers: [
        { text: "Folio de análisis", sortable: false, value: "folioAnalysis" },
        { text: "Tipo de muestra", sortable: false, value: "sampleType" },
        {
          text: "Razón social del cliente",
          sortable: false,
          value: "businessName",
        },
        { text: "# Iden", sortable: false, value: "identifications" },
        { text: "# No Iden", sortable: false, value: "noIdentifications" },
        { text: "Archivo", sortable: false, value: "file" },
        {
          text: "Fecha de identificación",
          sortable: false,
          value: "createdAt",
        },
      ],
      pagination: {
        page: 1,
        limit: 10,
        search: null,
      },
      totalData: 0,
      testSelected: null,
      molecularTests: [],
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  props: {
    identificationAdded: {
      type: Boolean,
      require: true,
    },
    reload: {
      type: Boolean,
      require: true,
    },
    search: {
      type: String,
      require: false,
    },
  },
  computed: {
    length() {
      return Math.ceil(this.totalData / this.pagination.limit);
    },
  },
  async created() {
    await this.getData();
  },
  methods: {
    ...mapActions("molecularIdentification", ["getMolecularTests"]),
    async getData() {
      this.loading = true;
      this.pagination.laboratory = this.user.laboratory._id;
      let request = await this.getMolecularTests(this.pagination);

      if (request) {
        this.totalData = request.TOTAL_TESTS;
        this.molecularTests = this.formatData(request.MOLECULAR_TESTS);

        if (this.molecularTests.length > 0) {
          this.molecularTests[0].clicked = true;
          this.testSelected = this.molecularTests[0];

          this.$emit("testSelected", this.testSelected);
        }
      }

      this.loading = false;
    },
    formatData(molecularTests) {
      return molecularTests.map((test) => {
        return {
          _id: test._id,
          folioAnalysis: test.soilSample.folioAnalysis,
          sampleType: test.soilSample.sampleType,
          businessName: test.analysis.client.companyName,
          identifications: test.mosDetected,
          noIdentifications: test.mosNotDetected,
          createdAt: test.createdAt,
          file: `./${test.file}.csv`,
          clicked: false,
          analysisType: "Molecular",
          status: "",
        };
      });
    },
    async rowSelect(rowValue) {
      // if(!rowValue.status.includes('Inoculado')){
      this.selectedRow = rowValue._id;
      this.testSelected = rowValue;
      this.testSelected.clicked = !this.testSelected.clicked;
      // }

      this.molecularTests = this.molecularTests.map((data) => {
        if (rowValue._id == data._id) {
          data.clicked = true;
        } else {
          data.clicked = false;
        }
        return data;
      });

      this.$emit("testSelected", this.testSelected);
    },
    row_classes(value) {
      if (value.clicked) {
        return "custom-highlight-row";
      }
    },
  },
  watch: {
    checkBoxesSelected: {
      handler() {
        this.$emit("testChecked", this.checkBoxesSelected);
      },
      deep: true,
    },
    async identificationAdded() {
      await this.getData();
    },
    async reload() {
      this.checkBoxesSelected = [];
      this.pagination = {
        page: 1,
        limit: 10,
        search: null,
      };

      await this.getData();
    },
    async search() {
      this.pagination.page = 1;
      this.pagination.search = this.search;
      await this.getData();
    },
    "pagination.page"() {
      if (this.pagination.page != parseInt(this.$route.params.page)) {
        this.$router.replace({
          params: {
            page: this.pagination.page,
          },
        });
      }
    },
  },
  async created() {
    this.pagination.page = parseInt(this.$route.params.page);
    await this.getData();
  },
};
</script>

<style></style>
