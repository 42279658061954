<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6" class="text-start">
        <v-btn color="primary" @click="goBack" :disabled="isSaving">
          <v-icon> mdi-arrow-left-thick </v-icon>
          Atras
        </v-btn>
      </v-col>
    </v-row>
    <v-container>
      <v-form ref="form" lazy-validation>
        <v-row>
          <v-col cols="6">
            <v-text-field
              dense
              outlined
              label="Nombre"
              type="text"
              clearable
              :rules="validate('nombre')"
              v-model="name"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              dense
              outlined
              label="Selecciona tipo"
              :items="moTypes"
              clearable
              item-text="name"
              :rules="validate('tipo')"
              v-model="moType"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-row class="d-flex justify-end">
      <v-col cols="2">
        <v-btn color="primary" block @click="saveData" :loading="isSaving">
          <v-icon> mdi-content-save </v-icon>
          Guardar
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

// validations
import { requiredValidation } from "@/helpers/validations.js";
export default {
  data: () => ({
    moType: null,
    name: null,
    moTypes: [],
    isSaving: false,
  }),
  computed: {
    ...mapState("identification", ["identificationSelectedInTable"]),
  },
  created() {
    this.moTypes = [
      {
        name: "Bacteria",
        value: "bacteria",
      },
      {
        name: "Hongo",
        value: "fungi",
      },
      {
        name: "Nematodo",
        value: "nematode",
      },
    ];
  },
  async mounted() {
    if (this.identificationSelectedInTable) {
      this.name = this.identificationSelectedInTable.name;
      this.moType = this.moTypes.find(
        (element) => element.value == this.identificationSelectedInTable.moType
      );
    }
  },
  methods: {
    ...mapActions("identification", ["createIdentification", "update"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async saveData() {
      try {
        if (this.$refs.form.validate()) {
          this.isSaving = true;
          let payload = {
            name: this.name,
            moType: this.moType,
          };
          if (this.identificationSelectedInTable) {
            await this.update({
              payload,
              identificationId: this.identificationSelectedInTable._id,
            });
            this.SET_ALERT_TYPE({
              visible: true,
              type: "success",
              text: `Identificación actualizado`,
              timeout: 5000,
            });
          } else {
            await this.createIdentification(payload);
            this.SET_ALERT_TYPE({
              visible: true,
              type: "success",
              text: `Identificación creada`,
              timeout: 5000,
            });
          }

          this.goBackAndUpdate();
        }
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
      this.isSaving = false;
    },
    goBack() {
      this.$emit("back");
    },
    goBackAndUpdate() {
      this.$emit("back_update");
    },
    validate(name) {
      return requiredValidation(name);
    },
  },
};
</script>
