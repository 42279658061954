<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6" class="text-start">
        <v-btn color="primary" @click="goBack" :disabled="isSaving">
          <v-icon> mdi-arrow-left-thick </v-icon>
          Atras
        </v-btn>
      </v-col>
      <v-col cols="6" class="text-end" v-if="!levelSelected">
        <v-btn color="primary" @click="openModalUpdateLevels">
          <v-icon> mdi-file-excel </v-icon>
          Añadir
        </v-btn>
      </v-col>
    </v-row>
    <v-container>
      <v-form ref="form" lazy-validation>
        <v-row>
          <v-col cols="6" v-if="showMarker">
            <v-autocomplete
              dense
              outlined
              label="Selecciona marcador"
              clearable
              :search-input.sync="searchMarker"
              :items="markers"
              item-text="microorganism_meta.name"
              item-value="_id"
              :loading="markersLoading"
              :rules="validate('marcador')"
              v-model="markerSelected"
              return-object
              :disabled="levelSelected ? true : false"
            >
              <template v-slot:item="data">
                {{ data.item.microorganism_meta.name }} ({{
                  isPathogen(data.item.isPathogen)
                }})
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              dense
              outlined
              label="Selecciona familia"
              :items="families"
              clearable
              :rules="validate('familia')"
              v-model="familySelected"
              :disabled="levelSelected ? true : false"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="6" v-if="showMarker">
            <v-autocomplete
              dense
              outlined
              label="Selecciona confiabilidad"
              :items="reliabilityeEnum"
              clearable
              :rules="validate('confiabilidad')"
              item-text="name"
              v-model="reliabilitySelected"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="body-2 font-weight-bold">Rangos nivel de riesgo</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              dense
              outlined
              label="Medio"
              type="number"
              clearable
              :rules="mediumRangeRules"
              v-model="mediumRange"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              dense
              outlined
              label="Alto"
              type="number"
              clearable
              :rules="hightRangeRules"
              v-model="hightRange"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-row class="d-flex justify-end">
      <v-col cols="2">
        <v-btn color="primary" block @click="saveData" :loading="isSaving">
          <v-icon> mdi-content-save </v-icon>
          Guardar
        </v-btn>
      </v-col>
    </v-row>
    <!-- modals -->
    <UpdateRiskLevelModal
      v-model="showUpdateRiskLevelModal"
      v-if="showUpdateRiskLevelModal"
      @refreshTable="goBackAndUpdate"
    />
  </v-container>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import UpdateRiskLevelModal from "@/components/cmda_admin/modals/UpdateRiskLevelModal.vue";
// validations
import { requiredValidation } from "@/helpers/validations.js";
export default {
  components: {
    UpdateRiskLevelModal,
  },
  data: () => ({
    showUpdateRiskLevelModal: false,
    families: [
      "Amaranthaceae",
      "Amaryllidaceae",
      "Anacardiaceae",
      "Annonaceae",
      "Apiaceae",
      "Araceae",
      "Arecaceae",
      "Asparagaceae",
      "Asphodelaceae",
      "Asteraceae",
      "Bixaceae",
      "Brassicaceae",
      "Bromeliaceae",
      "Cactaceae",
      "Caricaceae",
      "Chenopodiaceae",
      "Clusiaceae",
      "Convolvulaceae",
      "Cucurbitaceae",
      "Ebenaceae",
      "Ericaceae",
      "Euphorbiaceae",
      "Fabaceae",
      "Gramineae",
      "Gramíneas",
      "Juglandaceae",
      "Lamiaceae",
      "Lauraceae",
      "Liliaceae",
      "Linaceae",
      "Lythraceae",
      "Malpighiaceae",
      "Malvaceae",
      "Moraceae",
      "Moringaceae",
      "Musaceae",
      "Myrtaceae",
      "Oleaceae",
      "Orchidaceae",
      "Oxalidaceae",
      "Passifloraceae",
      "Pedaliaceae",
      "Pinaceae",
      "Piperaceae",
      "Portulacaceae",
      "Proteaceae",
      "Rosaceae",
      "Rubiaceae",
      "Rutaceae",
      "Sapindaceae",
      "Sapotaceae",
      "Solanaceae",
      "Vitaceae",
      "Zingiberaceae",
      "Otro",
    ],
    searchMarker: null,
    markersLoading: false,
    markers: [],
    isSaving: false,
    markerSelected: null,
    familySelected: null,
    mediumRange: null,
    hightRange: null,
    showMarker: true,
    reliabilityeEnum: [
      {
        name: "Baja",
        value: "low",
      },
      {
        name: "Media",
        value: "medium",
      },
      {
        name: "Alta",
        value: "hight",
      },
    ],
    reliabilitySelected: null,
  }),
  computed: {
    ...mapState("riskLevel", ["levelSelected"]),
    mediumRangeRules() {
      return [
        (v) => !!v || "El campo no puede estar vacío",
        (v) => v > 0 || "Debe ser un número mayor a 0",
        (v) => !isNaN(parseFloat(v)) || "Debe ser un número",
        (v) => v < parseFloat(this.hightRange) || "Debe ser menor que el rango alto",
      ];
    },
    hightRangeRules() {
      return [
        (v) => !!v || "El campo no puede estar vacío",
        (v) => v > 0 || "Debe ser un número mayor a 0",
        (v) => !isNaN(parseFloat(v)) || "Debe ser un número",
        (v) => v > parseFloat(this.mediumRange) || "Debe ser mayor que el rango medio",
      ];
    },
  },
  async mounted() {
    if (this.levelSelected) {
      if (this.levelSelected.molecularMarker.name) {
        await this.searchingMarkers(this.levelSelected.molecularMarker.name);

        this.markerSelected = this.markers.find(
          (element) =>
            element.microorganism_meta.name ==
            this.levelSelected.molecularMarker.name
        );
        let family =
          this.levelSelected.family != "OTHER"
            ? this.levelSelected.family
            : "Otro";
        this.familySelected = family;
        this.mediumRange = this.levelSelected.riskLevelMedium.min;
        this.hightRange = this.levelSelected.riskLevelMedium.max;
        this.reliabilitySelected = this.levelSelected.reliability;
      } else {
        this.showMarker = false;
        this.familySelected = "Otro";
        this.mediumRange = this.levelSelected.riskLevelMedium.min;
        this.hightRange = this.levelSelected.riskLevelMedium.max;
      }
    }
  },
  methods: {
    ...mapActions("laboratory", ["getMarkers"]),
    ...mapActions("riskLevel", ["createLevel", "updateLevel"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    formatItemText(item) {
      return `${item.microorganism_meta.name} (${this.isPathogen(
        item.isPathogen
      )})`;
    },
    openModalUpdateLevels() {
      this.showUpdateRiskLevelModal = true;
    },
    async saveData() {
      try {
        if (this.$refs.form.validate()) {
          this.isSaving = true;

          let payload = {
            family: this.familySelected,
            mediumRange: this.mediumRange,
            hightRange: this.hightRange,
          };
          if (this.showMarker) {
            payload.reliability = this.reliabilitySelected;
            payload.markerId = this.markerSelected._id;
          }
          if (this.levelSelected) {
            let id = this.levelSelected._id;
            await this.updateLevel({ payload, id });
            this.SET_ALERT_TYPE({
              visible: true,
              type: "success",
              text: `Nivel de riesgo actualizado`,
              timeout: 5000,
            });
          } else {
            await this.createLevel(payload);
            this.SET_ALERT_TYPE({
              visible: true,
              type: "success",
              text: `Nivel de riesgo creado`,
              timeout: 5000,
            });
          }

          this.goBackAndUpdate();
        }
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
      this.isSaving = false;
    },
    goBack() {
      this.$emit("back");
    },
    goBackAndUpdate() {
      this.$emit("back_update");
    },
    validate(name) {
      return requiredValidation(name);
    },
    async searchingMarkers(value) {
      if (value) {
        try {
          this.markersLoading = true;
          let payload = {
            filter: {
              search: value,
              isActive: "active",
            },
            per_page: 20,
            page: 1,
          };
          const response = await this.getMarkers(payload);
          if (response.error) throw response;
          this.markers = response.molecularMarkers;
        } catch (error) {
          console.log(error);
        }
        this.markersLoading = false;
      } else {
        this.markers = [];
      }
    },
    isPathogen(value) {
      if (value) {
        return "Patógeno";
      } else {
        return "Benéfico";
      }
    },
  },
  watch: {
    async searchMarker(value) {
      await this.searchingMarkers(value);
    },
  },
};
</script>
