<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="btn-square"
        small
        color="secondary"
        v-ripple="{ class: 'primary--text' }"
      >
        <v-icon>mdi-database-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5"
          >Nueva identificación -
          {{ moType == "fungi" ? "Hongo" : "Bacteria" }}</span
        >
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-container>
            <v-row justify="center" align="center">
              <v-col cols="6" sm="10" md="6">
                <v-text-field
                  v-model="name"
                  label="Nombre"
                  :rules="inputRules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" align="center"
              ><v-divider></v-divider>
            </v-row>

            <v-row justify="center" align="center">
              <v-col cols="12" sm="12" md="12">
                <span>Niveles de referencia</span>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="5">
                <v-text-field
                  v-model="lowReferenceLevel"
                  label="Bajo"
                  :rules="inputReferenceRules"
                  type="number"
                  required
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  v-model="highReferenceLevel"
                  label="Alto"
                  :rules="inputReferenceRules"
                  type="number"
                  required
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning darken-1" text @click="dialog = false">
          Cancelar
        </v-btn>
        <v-btn color="primary darken-1" text @click="saveIdentification">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      name: null,
      lowReferenceLevel: 0,
      highReferenceLevel: 0,
      dialog: false,
      valid: false,
      inputRules: [(v) => !!v || "El campo es requerido"],
      inputReferenceRules: [
        (v) => v > 0 || "Ingresa un nivel de referencia válido",
      ],
    };
  },
  props: {
    moType: {
      type: String,
      require: true,
    },
  },
  methods: {
    ...mapActions("identification", ["addStrainIdentification"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async saveIdentification() {
      if (this.$refs.form.validate()) {
        // this.dialog = false;

        let req = await this.addStrainIdentification({
          moType: this.moType,
          name: this.name,
          lowReferenceLevel: this.lowReferenceLevel,
          highReferenceLevel: this.highReferenceLevel,
        });

        if (req.error) throw req;

        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: `Iidentificación guardada correctamente`,
          timeout: 2000,
        });

        this.$emit("identificationSaved");
        this.dialog = false;
      }
    },
  },
  watch: {
    dialog() {
      this.name = null;
      this.lowReferenceLevel = 0;
      this.highReferenceLevel = 0;
    },
  },
};
</script>

<style>
</style>