// Set list of nutitions.
export function SET_NUTRITION_TEST_LIST(state, nutritionTestsList) {
  if (state.nutritionNegativeControl) {
    state.nutritionTestsList = [
      state.nutritionNegativeControl,
      ...nutritionTestsList,
    ];
  } else {
    state.nutritionTestsList = nutritionTestsList;
  }
}
// Select current nutrition test.
export function SET_SELECTED_NUTRITION_TEST(state, nutritionTest) {
  state.nutritionTestSelected = nutritionTest;
}

// Set control of nutrtion test.
export function SET_NUTRITION_NEGATIVE_CONTROL(state, data) {
  state.nutritionNegativeControl = data;
}
