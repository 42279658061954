var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"fill-height"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_c('h5',[_vm._v("Consola de administración CMDA")])])],1)],1)],1),_c('v-row',{staticClass:"fill-height"},[_c('v-col',[_c('v-card',{staticClass:"fill-height",attrs:{"rounded":"lg","elevation":"5"}},[_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.mini ? '1' : '3'}},[_c('v-row',[_c('v-col',[_c('v-navigation-drawer',{attrs:{"mini-variant":_vm.mini,"permanent":"","width":"auto"},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list-item',[_c('v-list-item-icon',{staticClass:"pointer-on-hover",on:{"click":function($event){$event.stopPropagation();_vm.mini = !_vm.mini}}},[_c('v-icon',[_vm._v("mdi-menu")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[(_vm.selectedItem == 0)?_c('span',[_vm._v(" Gestion CMDA ")]):(_vm.selectedItem == 1)?_c('span',[_vm._v(" Marcadores ")]):(_vm.selectedItem == 2)?_c('span',[_vm._v(" Niveles ")]):(_vm.selectedItem == 3)?_c('span',[_vm._v(" Test ")]):(_vm.selectedItem == 4)?_c('span',[_vm._v(" Productos (Prescripción) ")]):(_vm.selectedItem == 5)?_c('span',[_vm._v(" Identificaciones ")]):_c('span',[_vm._v(" No tienes permisos ")])]),_c('v-list-item-subtitle',[_vm._v(" Menú ")])],1)],1),_c('v-divider'),_c('v-list-item-group',{attrs:{"dense":"","nav":""}},[(
                            _vm.permissions.find(
                              (per) => per == 'console_cmda_management'
                            )
                          )?_c('v-list-item',{class:{ highlighted: _vm.selectedItem === 0 },on:{"click":function($event){return _vm.selectItem(0)}}},[_c('v-list-item-icon',[_c('v-icon',{class:_vm.selectedItem === 0 ? 'custom-icon' : ''},[_vm._v("mdi-cog")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Gestion CMDA")])],1)],1):_vm._e(),(
                            _vm.permissions.find(
                              (per) => per == 'console_cmda_molecular_markers'
                            )
                          )?_c('v-list-item',{class:{ highlighted: _vm.selectedItem === 1 },on:{"click":function($event){return _vm.selectItem(1)}}},[_c('v-list-item-icon',[_c('v-icon',{class:_vm.selectedItem === 1 ? 'custom-icon' : ''},[_vm._v("mdi-sign-text")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Marcadores")])],1)],1):_vm._e(),(
                            _vm.permissions.find(
                              (per) => per == 'console_cmda_risk_levels'
                            )
                          )?_c('v-list-item',{class:{ highlighted: _vm.selectedItem === 2 },on:{"click":function($event){return _vm.selectItem(2)}}},[_c('v-list-item-icon',[_c('v-icon',{class:_vm.selectedItem === 2 ? 'custom-icon' : ''},[_vm._v("mdi-chart-timeline-variant")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Niveles")])],1)],1):_vm._e(),(
                            _vm.permissions.find(
                              (per) => per == 'console_cmda_test'
                            )
                          )?_c('v-list-item',{class:{ highlighted: _vm.selectedItem === 3 },on:{"click":function($event){return _vm.selectItem(3)}}},[_c('v-list-item-icon',[_c('v-icon',{class:_vm.selectedItem === 3 ? 'custom-icon' : ''},[_vm._v("mdi-auto-fix")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Test")])],1)],1):_vm._e(),(
                            _vm.permissions.find(
                              (per) => per == 'console_cmda_prescriptions'
                            )
                          )?_c('v-list-item',{class:{ highlighted: _vm.selectedItem === 4 },on:{"click":function($event){return _vm.selectItem(4)}}},[_c('v-list-item-icon',[_c('v-icon',{class:_vm.selectedItem === 4 ? 'custom-icon' : ''},[_vm._v("mdi-basket-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Productos (Prescripción)")])],1)],1):_vm._e(),(
                            _vm.permissions.find(
                              (per) => per == 'console_cmda_identifications'
                            )
                          )?_c('v-list-item',{class:{ highlighted: _vm.selectedItem === 5 },on:{"click":function($event){return _vm.selectItem(5)}}},[_c('v-list-item-icon',[_c('v-icon',{class:_vm.selectedItem === 5 ? 'custom-icon' : ''},[_vm._v("mdi-bacteria")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Identificaciones")])],1)],1):_vm._e()],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":_vm.mini ? '11' : '9'}},[(_vm.selectedItem == 0)?_c('div',[_c('CMDAPanel')],1):(_vm.selectedItem == 1)?_c('div',[_c('MolecularMarkerTable')],1):(_vm.selectedItem == 2)?_c('div',[_c('LevelRiskTable')],1):_vm._e(),(_vm.selectedItem == 3)?_c('div',[_c('ResultTest')],1):(_vm.selectedItem == 4)?_c('div',[_c('ProductsTable')],1):(_vm.selectedItem == 5)?_c('div',[_c('IdentificationsTable')],1):_vm._e()])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }