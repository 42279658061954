<template>
  <v-card tile elevation="0" class="my-auto pb-1">
    <v-container>
      <!-- Photo row -->
      <v-row>
        <v-col
          cols="12"
          md="12"
          v-if="this.testBiostimulation.analysisType == 'P. AIA'"
        >
          <chart
            :results="productionResultsFormated"
            :label="testBiostimulation.inoculum"
          />
        </v-col>
        <v-col cols="12" md="12" v-else>
          <template v-if="!urlImage">
            <v-img
              :src="require('../../assets/img/no_image_strain.png')"
              aspect-ratio="1.0"
              contain
            ></v-img>
            <v-row
              v-if="
                testBiostimulation && testBiostimulation.status == 'INCUBATING'
              "
              justify="center"
            >
              <v-col cols="8">
                <AddEvidencePhotograph
                  :testMedia="testBiostimulation.idTest"
                  :testData="testBiostimulation"
                  :testType="testBiostimulation.analysisType"
                  @imageUpdated="formatData()"
                />
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-img :src="urlImage" aspect-ratio="1.0" contain></v-img>
            <v-row justify="center" align="center">
              <v-col cols="4">
                <EditMiniature
                  :imageUrl="urlOriginalImage"
                  @imageUpdated="formatData()"
                />
              </v-col>
              <v-col cols="4" v-if="testBiostimulation.status == 'IN_PROGRESS'">
                <AddEvidencePhotograph
                  :testMedia="testBiostimulation.idTest"
                  :testData="testBiostimulation"
                  :testType="testBiostimulation.analysisType"
                  @imageUpdated="formatData()"
                />
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
      <template>
        <!-- Analisis data row -->
        <v-row class="px-10">
          <v-col cols="7">
            <p class="font-weight-bold text-left mb-1">
              Análisis {{ testBiostimulation.folioAnalysis }}
            </p>
          </v-col>
          <v-col cols="5">
            <!-- <p class="font-weight-bold text-right mb-1">INICIAL MS</p> -->
          </v-col>
        </v-row>
        <!-- Test data headers row -->
        <v-row class="mt-0 pr-2">
          <v-col cols="2" class="pr-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              ID ensayo
            </p>
          </v-col>
          <v-col cols="2" class="px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">Medio</p>
          </v-col>
          <v-col cols="3" class="px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              Inóculo
            </p>
          </v-col>
          <v-col cols="2" class="px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              Caja petri
            </p>
          </v-col>
          <v-col cols="3" class="px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              Tipo análisis
            </p>
          </v-col>
        </v-row>
        <!-- Test data row -->
        <v-row class="mt-0 pr-2">
          <v-col cols="2" class="pr-0">
            <p class="text-caption text-center mb-1">
              {{ testBiostimulation.idTest }}
            </p>
          </v-col>
          <v-col cols="2" class="px-0">
            <p class="text-caption text-center mb-1">
              {{ testBiostimulation.medium }}
            </p>
          </v-col>
          <v-col cols="3" class="px-0">
            <p class="text-caption text-center mb-1">
              {{ testBiostimulation.inoculum }}
            </p>
          </v-col>
          <v-col cols="2" class="px-0">
            <p
              class="text-caption text-center mb-1"
              v-if="testBiostimulation.containerType == 'petriDish90mmx15mm'"
            >
              90x15 mm
            </p>
            <p class="text-caption text-center mb-1" v-else>60x15 mm</p>
          </v-col>
          <v-col cols="3" class="px-0">
            <p class="text-caption text-center mb-1">
              {{ testBiostimulation.analysisType }}
            </p>
          </v-col>
        </v-row>
        <template v-if="this.testBiostimulation.analysisType == 'P. AIA'">
          <!-- Area data row -->
          <v-row class="px-6 mt-0" align="center" justify="center">
            <v-col cols="3" class="pt-6 pb-0">
              <p class="font-weight-bold">Benéfico</p>
            </v-col>
            <v-col cols="4" class="pt-6 pb-0">
              <p class="secondary--text text-caption">
                {{ testBiostimulation.inoculum }}
              </p>
            </v-col>
            <v-col cols="5" class="pt-2">
              <v-select
                v-model="interpretation"
                label="Interpretación"
                :items="items"
                readonly
                :disabled="disableForms"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="4" class="py-0">
              <p class="text-center">Absorbancia</p>
            </v-col>
          </v-row>
          <template v-if="moType == 'fungi'">
            <v-row
              class="mt-0"
              justify="center"
              align="center"
              v-for="(result, index) in productionResultsFungi"
              :key="index"
            >
              <v-col cols="3" class="py-2">
                <p class="mb-0 text-caption secondary--text text-right">
                  {{ result.time }} hrs.
                </p>
              </v-col>
              <v-col cols="3" class="py-2">
                <v-text-field
                  v-model.number="result.result"
                  single-line
                  class="centered-input"
                  height="15px"
                  outlined
                  dense
                  hide-details
                  type="number"
                  :disabled="disableForms"
                ></v-text-field>
              </v-col>
              <v-col cols="3" class="py-2">
                <p class="mb-0 text-caption secondary--text">
                  {{ productionResultsFormated[index].ppm }} PPM
                </p>
              </v-col>
            </v-row>
          </template>
          <template v-else-if="moType == 'bacteria'">
            <v-row
              class="mt-0"
              justify="center"
              align="center"
              v-for="(result, index) in productionResultsBacteria"
              :key="index"
            >
              <v-col cols="3" class="py-2">
                <p class="mb-0 text-caption secondary--text text-right">
                  {{ result.time }} hrs.
                </p>
              </v-col>
              <v-col cols="3" class="py-2">
                <v-text-field
                  v-model.number="result.result"
                  single-line
                  class="centered-input"
                  height="15px"
                  outlined
                  dense
                  hide-details
                  type="number"
                  :disabled="disableForms"
                ></v-text-field>
              </v-col>
              <v-col cols="3" class="py-2">
                <p class="mb-0 text-caption secondary--text">
                  {{ productionResultsFormated[index].ppm }} PPM
                </p>
              </v-col>
            </v-row>
          </template>
        </template>

        <template v-else>
          <v-row justify="center" align="center">
            <v-col cols="6">
              <v-row align="center">
                <v-col cols="5"
                  ><strong class="font-weight-bold">Benéfico</strong></v-col
                >
                <v-col cols="7" class="text-center"
                  ><span class="secondary--text text-caption">{{
                    testBiostimulation.inoculum
                  }}</span></v-col
                >
              </v-row>
            </v-col>
            <v-col cols="4">
              <v-container class="px-0" v-if="cellulolyticActivity == null">
                <v-row class="px-0">
                  <v-col cols="12" class="px-0"
                    ><v-text-field
                      v-model="growth"
                      label="Crecimiento / mm"
                      outlined
                      dense
                      hide-details
                      type="number"
                      :disabled="disableForms"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <v-row v-if="this.testBiostimulation.analysisType == 'P. AIA'">
                  <v-col cols="12" class="px-0"
                    ><v-select
                      v-model="interpretation"
                      label="Interpretación"
                      :items="items"
                      :disabled="disableForms"
                      outlined
                      dense
                      hide-details
                    ></v-select
                  ></v-col>
                </v-row>
              </v-container>
              <v-container class="px-0" v-else>
                <v-row class="px-0">
                  <v-switch
                    v-model="cellulolyticActivity"
                    :true-value="true"
                    :false-value="false"
                    dense
                    class="pt-0 mt-0"
                    inset
                    label="Actividad Celulolítica"
                    :disabled="disableForms"
                  ></v-switch>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <!-- <v-row class="px-6 mt-0" align="center" justify="center">
            <v-col cols="3" class="pt-6">
              <p class="font-weight-bold">Benéfico</p>
            </v-col>
            <v-col cols="4" class="pt-6">
              <p class="secondary--text text-caption">
                {{ testBiostimulation.inoculum }}
              </p>
            </v-col>
            <v-col cols="5" class="pt-2">
              <v-select
                v-model="interpretation"
                label="Interpretación"
                :items="items"
                :disabled="disableForms"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>
          </v-row> -->
        </template>
      </template>
      <v-card-actions class="justify-center pt-0">
        <v-row class="pt-4" justify="center" align="center">
          <v-col cols="8">
            <v-btn
              depressed
              block
              color="secondary"
              :disabled="disableForms"
              :loading="loading"
              @click="saveTest"
            >
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import Chart from "../utils/chart.vue";
import EditMiniature from "./modals/EditMiniature.vue";
import AddEvidencePhotograph from "./modals/AddEvidencePhotograph.vue";

export default {
  components: {
    Chart,
    EditMiniature,
    AddEvidencePhotograph,
  },
  props: {
    testBiostimulation: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      loading: false,
      singleSelect: false,
      items: ["Media", "Alta", "Baja"],
      interpretation: "Media",
      cellulolyticActivity: null,
      urlImage: null,
      urlOriginalImage: "",
      growth: 0,
      disableForms: false,
      productionResultsFungi: [
        {
          time: 48,
          result: 0,
          ppm: 0,
        },
        {
          time: 96,
          result: 0,
          ppm: 0,
        },
        {
          time: 144,
          result: 0,
          ppm: 0,
        },
      ],
      productionResultsBacteria: [
        {
          time: 24,
          result: 0,
          ppm: 0,
        },
        {
          time: 48,
          result: 0,
          ppm: 0,
        },
        {
          time: 72,
          result: 0,
          ppm: 0,
        },
      ],
      productionResultsFormated: [
        {
          time: 24,
          result: 0,
          ppm: 0,
        },
        {
          time: 36,
          result: 0,
          ppm: 0,
        },
        {
          time: 72,
          result: 0,
          ppm: 0,
        },
        {
          time: 96,
          result: 0,
          ppm: 0,
        },
        {
          time: 120,
          result: 0,
          ppm: 0,
        },
      ],
      moType: "",
    };
  },
  async created() {
    this.validateTest();
    await this.formatData();
  },
  methods: {
    ...mapActions("bioStimulation", [
      "updateBiostimulationTest",
      "getBiostimulationTests",
    ]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    validateTest() {
      if (this.testBiostimulation.testData.status != "IN_PROGRESS") {
        this.disableForms = true;
      } else {
        this.disableForms = false;
      }
    },
    async saveTest() {
      this.loading = true;
      try {
        let data = {
          _id: this.testBiostimulation._id,
        };

        if (this.testBiostimulation.analysisType == "P. AIA") {
          let moType = this.moType;

          data.productionResults = this.productionResultsFormated;
          data.interpretation = this.getIntepretation(
            this.interpretation
          ).value;
        } else if (this.testBiostimulation.analysisType == "A. Celulolítica") {
          data.hasCellulolyticActivity = this.cellulolyticActivity;
        } else {
          data.growth = this.growth;
        }

        const response = await this.updateBiostimulationTest(data);
        if (response.error) {
          throw response;
        }

        console.log("Strain Object", data);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: "Bioestimulación guardada correctamente",
          timeout: 4000,
        });
      } catch (e) {
        console.log(e);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Error al actualizar bioestimulación, comunicate a soporte técnico para dar seguimiento.",
          timeout: 4000,
        });
      }
      // this.$emit("testsSaved");
      this.loading = false;
    },
    async formatData() {
      let request = await this.getBiostimulationTests(
        this.testBiostimulation._id
      );
      let beDBData = request.BIOSTIMULATION_TEST;
      console.log("BIOESTIMULATION", beDBData);

      if (this.testBiostimulation.analysisType == "P. AIA") {
        this.moType = beDBData.strain.identification.moType;

        if (this.moType == "fungi") {
          this.productionResultsFungi = beDBData.productionResults.filter(
            (result) =>
              result.time == 48 || result.time == 96 || result.time == 144
          );
          this.productionResultsFormated = this.productionResultsFungi;
        } else {
          this.productionResultsBacteria = beDBData.productionResults.filter(
            (result) =>
              result.time == 24 || result.time == 48 || result.time == 72
          );
          this.productionResultsFormated = this.productionResultsBacteria;
        }
      } else {
        if (
          this.testBiostimulation.testData.phytohormone !=
          "cellulolytic_activity"
        ) {
          this.cellulolyticActivity = null;
          this.growth = request.BIOSTIMULATION_TEST.growth;
        } else {
          this.cellulolyticActivity = beDBData.hasCellulolyticActivity;
        }

        let status = this.testBiostimulation.testData.status;
        this.urlImage =
          status == "IN_PROGRESS" || status == "COMPLETED"
            ? this.testBiostimulation.testData.incubation.morphologyPhoto
            : null;

        if (this.urlImage) {
          this.urlOriginalImage = this.urlImage;
          if (!this.urlImage.includes("-small")) {
            this.urlImage =
              this.urlImage.slice(0, this.urlImage.length - 4) +
              "-small.jpg?" +
              Date.now();
          } else {
            this.urlImage = `${this.urlImage}?${Date.now()}`;
            this.urlOriginalImage = `${this.urlImage.split("-small")[0]}${
              this.urlImage.split("-small")[1]
            }`;
          }
        }
      }

      this.interpretation = this.testBiostimulation.testData.interpretation
        ? this.getIntepretation(this.testBiostimulation.testData.interpretation)
            .value
        : "Media";
    },
    getIntepretation(interpretation) {
      let interpretations = [
        { text: "Media", value: "Good" },
        { text: "Alta", value: "Very good" },
        { text: "Baja", value: "Low" },
        { text: "Good", value: "Media" },
        { text: "Very good", value: "Alta" },
        { text: "Low", value: "Baja" },
      ];

      return interpretations.find((inter) => inter.text == interpretation);
    },
    getAIAResults(productionResults) {
      let arrayAIA = [];

      productionResults.forEach((element) => {
        arrayAIA.push(element.ppm ? parseFloat(element.ppm) : 0);
      });
      let value = Math.max(...arrayAIA);
      switch (true) {
        case value <= 0 || (value > 0 && value <= 10):
          return "Baja";
        case value > 10 && value <= 20:
          return "Media";
        case (value > 20 && value <= 30) || value > 30:
          return "Alta";
        default:
          return "No Valido";
      }
    },
    resultsWatchHandler() {
      this.interpretation = this.getAIAResults(this.productionResultsFormated);
      let moType = this.moType == "bacteria" ? 1 : 2;

      if (this.moType == "bacteria") {
        this.productionResultsFormated = this.productionResultsBacteria.map(
          (result) => {
            return {
              ppm: this.getPPM(moType, result.result),
              result: result.result,
              time: result.time,
            };
          }
        );
      } else {
        this.productionResultsFormated = this.productionResultsFungi.map(
          (result) => {
            return {
              ppm: this.getPPM(moType, result.result),
              result: result.result,
              time: result.time,
            };
          }
        );
      }
    },
    getAbsorbance(type, value) {
      let result;
      switch (type) {
        // bacteria
        case 1:
          result = value ? (value * 0.0194).toFixed(2) : null;
          return result;
        // fungi
        case 2:
          result = value ? (value * 0.0242).toFixed(2) : null;
          return result;
      }
    },
    getPPM(type, value) {
      let result;
      switch (type) {
        // bacteria
        case 1:
          result = value ? (value / 0.0194 + 0.0062).toFixed(2) : 0;
          return result;
        // fungi
        case 2:
          result = value ? (value / 0.0242).toFixed(2) : 0;
          return result;
      }
    },
  },
  watch: {
    async testBiostimulation() {
      this.validateTest();
      await this.formatData();
    },
    productionResultsFungi: {
      handler() {
        this.resultsWatchHandler();
      },
      deep: true,
    },
    productionResultsBacteria: {
      handler() {
        this.resultsWatchHandler();
      },
      deep: true,
    },
  },
};
</script>

<style>
.centered-input input {
  text-align: center;
}

.centered-input .v-input__control .v-input__slot {
  min-height: 25px !important;
  display: flex !important;
  align-items: center !important;
}
</style>
