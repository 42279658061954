<template>
  <v-dialog v-model="show" width="80%" scrollable persistent>
    <v-card>
      <v-card-text class="px-0 py-0">
        <vue-pdf-app style="height: 80vh" :pdf="urlPreview"></vue-pdf-app>
      </v-card-text>
      <v-card-actions>
        <v-container class="text-end">
          <v-btn
            text
            color="warning"
            @click="closeModal"
            dark
            :disabled="isLoading"
            >Cerrar</v-btn
          >

          <v-btn
            text
            color="primary"
            @click="confirmation"
            :loading="isLoading"
            v-if="actionButton"
            >Generar reporte final</v-btn
          >
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// vuex
import { mapState, mapMutations } from "vuex";
// components
import VuePdfApp from "vue-pdf-app";
export default {
  components: {
    VuePdfApp,
  },
  data: () => ({
    isLoading: false,
  }),
  props: {
    value: Boolean,
    urlPreview: String,
    actionButton: Boolean,
  },
  computed: {
    ...mapState("project", ["projectSelected"]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    closeModal() {
      this.$emit("close");
    },
    async confirmation() {
      try {
        this.isLoading = true;
        this.$emit("accept");
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>
