// Guard
import { privateGuard } from "@/middlewares/guards";

// Layouts
import NavLayout from "../layouts/NavLayout.vue";

// Views
import Profit from "../views/Profit.vue";
import ProfitDetail from "@/components/Profit/ProfitDetail.vue"

export default [
  {
    path: "/profit/:page",
    beforeEnter: privateGuard,
    component: NavLayout,
    children: [
      {
        path: "",
        name: "Profit",
        component: Profit,
        meta: {
          title: "Rentabilización",
        },
      },
    ],
  },
  {
    path: "/profit-detail/:id",
    beforeEnter: privateGuard,
    component: NavLayout,
    children: [
      {
        path: "",
        name: "ProfitDetail",
        component: ProfitDetail,
        meta: {
          title: "Detalle rentabilización",
        },
      },
    ],
  }
];
