import axios from "axios";

/**
 * Function to get count of every test media
 * @param {function} { commit } - Vuex function
 * @param {Object} payload - filters to get test media
 * @returns {data} -  Returns array of test media objects
 */

export async function countTestMedias({ commit }, payload) {
  try {
    const { data } = await axios.get(`/testMedia/count`, {
      params: payload,
    });
    if (data.error) throw data;
    commit("SET_TESTMEDIA_COUNT", data.counts);
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

/**
 * Function to add a test media
 * @param {function} { commit } - Vuex function
 * @param {Object} payload - test media info
 * @returns {data} -  Returns array of test media created
 */
export async function addTestMedia({ commit }, payload) {
  try {
    const { data } = await axios.post(`/testMedia/add`, payload);
    if (data.error) throw data;
    commit("SET_TESTMEDIA_LIST", data.testMedias);
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

/**
 * Function to add many test medias
 * @param {function} { commit } - Vuex function
 * @param {Object} payload - test media info
 * @returns {data} -  Returns array of test media created
 */
export async function addManyTestMedia({ commit }, payload) {
  try {
    const { data } = await axios.post(`/testMedia/addMany`, payload);
    if (data.error) throw data;
    commit("SET_TESTMEDIA_LIST", data.testMedias);
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

/**
 * Function to search test medias
 * @param {function} { commit } - Vuex function
 * @param {Object} payload - filters: lab id, status, and name of
 * @returns {data} -  Returns array of test medias
 */
export async function searchTestMedia({ commit, rootState }, payload) {
  try {
    const { data } = await axios.get(`/testMedia/search`, {
      params: payload,
      cancelToken: rootState.cancelToken && rootState.cancelToken.token,
    });
    if (data.error) throw data;
    commit("SET_TESTMEDIA_LIST", data.TESTMEDIAS);
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

/**
 * Function to search test media by id
 * @param {function} { commit } - Vuex function
 * @param {Object} payload - test media id
 * @returns {data} -  Returns test media object
 */
 export async function searchTestMediabyId({ commit }, payload) {
  try {
    const { data } = await axios.get(`/testMedia/search/${payload}`, {
      params: payload
    });
    if (data.error) throw data;
    
    return data;
  } catch (e) {
    console.log(e);
    throw Error(e.response.data.message);
  }
}

/**
 * Function to update a test media
 * @param {Object} dispatch - Vuex context.
 * @param {Object} payload - test media id and id lab
 * @returns {data} -  Returns status 200
 */
export async function updateTestMedia({ dispatch }, payload) {
  try {
    const { data } = await axios.put(`/testMedia/update`, {
      id: payload.id,
    });
    if (data.error) throw data;

    // Fetch test media
    const response = await dispatch("countTestMedias", {
      laboratory: payload.laboratory,
    });
    if (response.error) throw response;

    return data;
  } catch (e) {
    console.log(e);
    throw Error(e.response.data.message);
  }
}

/**
 * Function to get  test medias contaminated
 * @param {function} { commit } - Vuex function
 * @param {Object} { filters } - lab id
 * @returns {data} -  Returns array of test medias
 */
export async function getTestMediaContaminated({ commit }, filters) {
  try {
    const { data } = await axios.get(`/testMedia/contaminated`, {
      params: filters,
    });
    if (data.error) throw data;
    commit("SET_TESTMEDIA_CONTAMINATED", data);
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}
