<template>
  <v-container class="fill-height">
    <v-row v-if="!isAdding">
      <v-col cols="12">
        <v-row>
          <v-col cols="12" xl="5" lg="5" md="5">
            <v-text-field
              outlined
              dense
              prepend-inner-icon="mdi-magnify"
              label="Buscar..."
              clearable
              v-model="search"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" xl="1" lg="2" md="3">
            <v-menu
              offset-y
              :close-on-click="true"
              :close-on-content-click="false"
              v-model="filterMenu"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on" block>
                  <v-icon left>mdi-filter</v-icon>
                  Filtro
                </v-btn>
              </template>
              <v-card width="auto">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          dense
                          outlined
                          label="Selecciona tipo"
                          :items="moTypes"
                          clearable
                          item-text="name"
                          v-model="filter.moType"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="warning" @click="cleanFilter()"
                    >Restaurar</v-btn
                  >
                  <v-btn color="primary" @click="applyFilter()">Aplicar</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
          <v-col cols="12" xl="1" lg="2" md="3">
            <v-btn color="primary" @click="openFormCreate()">
              <v-icon>mdi-plus</v-icon>
              Añadir identificación
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-row>
          <v-col cols="12">
            <v-card elevation="3">
              <v-card-text>
                <v-container>
                  <v-data-table
                    :headers="headers"
                    :items="identifications"
                    :page.sync="page"
                    :items-per-page="10"
                    :loading="loadTable"
                    hide-default-footer
                    fixed-header
                    height="56vh"
                    no-data-text="No hay identificaciones"
                  >
                    <template #[`item.updatedAt`]="{ item }">
                      <span>{{ dateFormat(item.updatedAt) }} </span>
                    </template>
                    <template #[`item.moType`]="{ item }">
                      <span>{{ getMoType(item.moType) }} </span>
                    </template>
                    <template #[`item._id`]="{ item }">
                      <v-btn
                        color="primary"
                        dark
                        x-small
                        class="rounded-md mx-1"
                        @click="openFormUpdate(item)"
                      >
                        <v-icon small> mdi-pencil </v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-pagination
              v-model="page"
              :length="numPages"
              total-visible="10"
              color="primary"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <FormNewIdentification
          @back="isAdding = false"
          @back_update="fetchData(), (isAdding = false)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//Vuex
import { mapActions, mapMutations } from "vuex";
//components
import FormNewIdentification from "@/components/cmda_admin/FormNewIdentification.vue";
//Libraries
import dayjs from "dayjs";
export default {
  components: {
    FormNewIdentification,
  },
  data: () => ({
    headers: [
      {
        text: "Nombre",
        sortable: false,
        value: "name",
      },
      {
        text: "Tipo",
        sortable: false,
        value: "moType",
      },
      {
        text: "Ultima actualización",
        sortable: false,
        value: "updatedAt",
      },
      { text: "Acciones", value: "_id", sortable: false },
    ],
    filter: {
      moType: null,
    },
    per_page: 10,
    page: 1,
    numPages: 1,
    loadTable: false,
    isAdding: false,
    search: null,
    filterMenu: null,
    identifications: [],
    moTypes: [],
  }),
  created() {
    this.moTypes = [
      {
        name: "Bacteria",
        value: "bacteria",
      },
      {
        name: "Hongo",
        value: "fungi",
      },
      {
        name: "Nematodo",
        value: "nematode",
      },
    ];
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("identification", ["getIdentificationsPaged"]),
    ...mapMutations("identification", ["SET_IDENTIFICATION_SELECTED_IN_TABLE"]),
    async fetchData() {
      try {
        this.loadTable = true;
        let payload = {
          filter: this.filter,
          per_page: this.per_page,
          page: this.page,
          search: this.search,
        };
        let response = await this.getIdentificationsPaged(payload);
        if (response.error) throw Error(response.message);
        this.numPages = response.pages;
        this.identifications = response.identifications;
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
      this.loadTable = false;
    },
    dateFormat(value) {
      const date = `${dayjs(value).format("DD/MM/YYYY - HH:mm")} hrs`;
      return date || "--/--/----";
    },

    async cleanFilter() {
      this.filterMenu = false;
      this.filter.moType = null;
      await this.fetchData();
    },
    async applyFilter() {
      this.page = 1;
      this.numPages = 1;
      this.filterMenu = false;
      await this.fetchData();
    },
    getMoType(item) {
      let moTypes = {
        bacteria: "Bacteria",
        fungi: "Hongo",
        nematode: "Nematodo",
      };
      return moTypes[item] || item;
    },
    openFormUpdate(item) {
      this.SET_IDENTIFICATION_SELECTED_IN_TABLE(item);
      this.isAdding = true;
    },

    openFormCreate() {
      this.SET_IDENTIFICATION_SELECTED_IN_TABLE(null);
      this.isAdding = true;
    },
  },
  watch: {
    async page() {
      await this.fetchData();
    },
    async search() {
      this.page = 1;
      this.numPages = 1;
      await this.fetchData();
    },
  },
};
</script>
<style scoped></style>
