<template>
  <v-card class="flex d-flex flex-column" elevation="5">
    <v-card-text>
      <v-list height="900" class="overflow-y-auto">
        <template v-for="(month, indexM) in months_data">
          <v-list-item :key="indexM">
            <v-list-item-content>
              <v-row class="justify-center">
                <span class="primary--text font-weight-medium"
                  >MES {{ indexM + 1 }}</span
                >
              </v-row>
              <v-row v-for="indexW in 4" :key="indexW" class="align-center">
                <v-col cols="12" xl="3" lg="3" md="3">
                  <span class="primary--text text-caption font-weight-bold"
                    >Semana {{ indexW }}</span
                  >
                </v-col>
                <!-- <v-col
                  cols="12"
                  xl="3"
                  lg="3"
                  md="3"
                  sm="12"
                  xs="12"
                  v-if="!monthsData[indexM][indexW - 1].isNohdoses"
                >
                  <v-btn
                    small
                    dense
                    v-if="
                      profit_version &&
                      (profit_version.status === 'NEW' ||
                        profit_version.status === 'PROPOSAL' ||
                        profit_version.status === 'REJECTED')
                    "
                    color="primary"
                    @click="addAplication(indexM, indexW - 1)"
                    ><v-icon>mdi-plus</v-icon></v-btn
                  >
                </v-col> -->
                <v-col
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="12"
                  xs="12"
                  v-if="monthsData[indexM][indexW - 1].isNohdoses"
                >
                  <v-combobox
                    v-model="monthsData[indexM][indexW - 1].subject"
                    :items="options"
                    label="Motivo"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" xl="3" lg="3" md="3">
                  <v-switch
                    dense
                    class="text-caption"
                    v-if="
                      profit_version &&
                      (profit_version.status === 'NEW' ||
                        profit_version.status === 'PROPOSAL' ||
                        profit_version.status === 'REJECTED' ||
                        profit_version.status === 'STRAINS_SUGGESTION')
                    "
                    v-model="monthsData[indexM][indexW - 1].isNohdoses"
                    @click="checkInput(indexM, indexW - 1)"
                  >
                    <template v-slot:label>
                      <span class="input__label text-caption"
                        >Semana sin dosis</span
                      >
                    </template>
                  </v-switch>
                </v-col>
                <v-col
                  v-if="
                    monthsData.length > 0 &&
                    !monthsData[indexM][indexW - 1].isNohdoses
                  "
                >
                  <v-switch
                    label="Semana doble"
                    small
                    v-if="
                      profit_version &&
                      (profit_version.status === 'NEW' ||
                        profit_version.status === 'PROPOSAL' ||
                        profit_version.status === 'REJECTED' ||
                        profit_version.status === 'STRAINS_SUGGESTION')
                    "
                    v-model="monthsData[indexM][indexW - 1].doubleWeekDoses"
                    @click="checkDoubleInput(indexM, indexW - 1)"
                  >
                    <template v-slot:label>
                      <span class="input__label text-caption"
                        >Semana doble</span
                      >
                    </template>
                  </v-switch>
                </v-col>
                <v-col
                  v-if="
                    monthsData.length > 0 &&
                    !monthsData[indexM][indexW - 1].isNohdoses
                  "
                  cols="12"
                >
                  <v-row>
                    <v-col>
                      <v-btn
                        small
                        dense
                        block
                        v-if="
                          profit_version &&
                          (profit_version.status === 'NEW' ||
                            profit_version.status === 'PROPOSAL' ||
                            profit_version.status === 'REJECTED' ||
                            profit_version.status === 'STRAINS_SUGGESTION')
                        "
                        color="primary"
                        @click="addAplication1(indexM, indexW - 1)"
                        ><v-icon>mdi-plus</v-icon> Añadir día 1</v-btn
                      >
                    </v-col>
                    <v-col>
                      <v-btn
                        small
                        dense
                        block
                        v-if="
                          profit_version &&
                          (profit_version.status === 'NEW' ||
                            profit_version.status === 'PROPOSAL' ||
                            profit_version.status === 'REJECTED' ||
                            profit_version.status === 'STRAINS_SUGGESTION') &&
                          monthsData[indexM][indexW - 1].doubleWeekDoses
                        "
                        color="primary"
                        @click="addAplication2(indexM, indexW - 1)"
                        ><v-icon>mdi-plus</v-icon> Añadir día 2</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row v-if="monthsData[indexM][indexW - 1].doses[1]">
                    <v-col>
                      <span>Día 1</span>
                    </v-col>
                  </v-row>
                  <v-row
                    v-for="(prod, indexProd) in monthsData[indexM][indexW - 1]
                      .doses[0]"
                    :key="indexProd"
                  >
                    <v-col cols="12" xl="7" lg="7" md="9" sm="7" xs="5">
                      <v-combobox
                        :items="strains_selected"
                        hide-details=""
                        dense
                        clearable
                        :disabled="
                          profit_version &&
                          profit_version.status !== 'NEW' &&
                          profit_version.status !== 'PROPOSAL' &&
                          profit_version.status !== 'REJECTED' &&
                          profit_version.status !== 'STRAINS_SUGGESTION'
                        "
                        outlined
                        item-text="nameFull"
                        v-model="prod.strain"
                      >
                      </v-combobox>
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="3">
                      <v-text-field
                        dense
                        hide-details=""
                        outlined
                        :disabled="
                          profit_version &&
                          profit_version.status !== 'NEW' &&
                          profit_version.status !== 'PROPOSAL' &&
                          profit_version.status !== 'REJECTED' &&
                          profit_version.status !== 'STRAINS_SUGGESTION'
                        "
                        type="number"
                        v-model="prod.dose"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" xl="2" lg="2" md="3" sm="2" xs="2">
                      <v-btn
                        v-if="
                          profit_version &&
                          (profit_version.status === 'NEW' ||
                            profit_version.status === 'PROPOSAL' ||
                            profit_version.status === 'REJECTED' ||
                            profit_version.status === 'STRAINS_SUGGESTION')
                        "
                        color="red"
                        @click="
                          removeAplication(indexM, indexW - 1, 0, indexProd)
                        "
                        fab
                        x-small
                        dark
                      >
                        <v-icon>mdi-delete-forever</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <div
                    v-if="monthsData[indexM][indexW - 1].doses[1]"
                    class="mt-4"
                  >
                    <v-row>
                      <v-col>
                        <span> Día 2</span>
                      </v-col>
                    </v-row>
                    <v-row
                      v-for="(prod, indexProd) in monthsData[indexM][indexW - 1]
                        .doses[1]"
                      :key="indexProd"
                    >
                      <v-col cols="12" xl="7" lg="7" md="9" sm="7" xs="5">
                        <v-combobox
                          :items="strains_selected"
                          hide-details=""
                          dense
                          clearable
                          :disabled="
                            profit_version &&
                            profit_version.status !== 'NEW' &&
                            profit_version.status !== 'PROPOSAL' &&
                            profit_version.status !== 'REJECTED' &&
                            profit_version.status !== 'STRAINS_SUGGESTION'
                          "
                          outlined
                          item-text="nameFull"
                          v-model="prod.strain"
                        >
                        </v-combobox>
                      </v-col>
                      <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="3">
                        <v-text-field
                          dense
                          hide-details=""
                          outlined
                          :disabled="
                            profit_version &&
                            profit_version.status !== 'NEW' &&
                            profit_version.status !== 'PROPOSAL' &&
                            profit_version.status !== 'REJECTED' &&
                            profit_version.status !== 'STRAINS_SUGGESTION'
                          "
                          type="number"
                          v-model="prod.dose"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3" xl="2" lg="2" md="3" sm="2" xs="2">
                        <v-btn
                          v-if="
                            profit_version &&
                            (profit_version.status === 'NEW' ||
                              profit_version.status === 'PROPOSAL' ||
                              profit_version.status === 'REJECTED' ||
                              profit_version.status !== 'STRAINS_SUGGESTION')
                          "
                          color="red"
                          @click="
                            removeAplication(indexM, indexW - 1, 1, indexProd)
                          "
                          fab
                          x-small
                          dark
                        >
                          <v-icon>mdi-delete-forever</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
              <v-row class="justify-center my-1">
                <span class="gray--text">
                  Análisis de seguimiento {{ indexM + 1 }}
                </span>
              </v-row>
              <v-divider class="mx-4 my-2" inset></v-divider>
            </v-list-item-content>
          </v-list-item>
        </template>
        <div v-if="months_data.length == 0">
          <div v-for="item of 7" :key="item">
            <v-skeleton-loader class="ma-7" type="card" />
          </div>
        </div>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>
//Vuex
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  props: {
    schedule: {
      type: Array,
      required: true,
    },
    months: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    monthsData: [],
    swicth: false,
    options: ["Inicio Etapa 2"],
    idBoost: null,
  }),
  computed: {
    ...mapState("profit", [
      "strains",
      "strains_selected",
      "months_data",
      "profit_version",
      "weeksNotAvailable",
    ]),
  },
  async created() {
    let response = await this.getBoostId();
    this.idBoost = response.id;
    if (this.schedule.length > 0) {
      for (let month = 0; month < this.schedule.length; month++) {
        for (let week = 0; week < this.schedule[month].length; week++) {
          for (
            let days = 0;
            days < this.schedule[month][week].doses.length;
            days++
          ) {
            if (days == 0) {
              if (this.schedule[month][week].doses[days].length == 0) {
                this.schedule[month][week].doses[days].push({
                  applied: false,
                  appliedDate: null,
                  dose: 0,
                  month: month + 1,
                  strain: {
                    dose: 0,
                    isSelected: true,
                    name: "Ag Boost 200",
                    nameFull: "Ag Boost 200*Solena",
                    strainId: this.idBoost,
                    type: "Bioactivo",
                  },
                  week: week + 1,
                });
              }
            }
          }
        }
      }
      this.monthsData = this.schedule;
      this.SET_DOSE_SCHEDULE(this.schedule);
    } else {
      let aux = [];
      for (let i = 0; i < this.months; i++) {
        let month = [];
        for (let x = 0; x < 4; x++) {
          let week = [];
          month.push({ isNohdoses: false, doses: week, subject: "" });
        }
        aux.push(month);
      }
      this.monthsData = aux;
      this.SET_DOSE_SCHEDULE(aux);
    }
    this.SET_LOAD_PROFIT(1)
  },
  methods: {
    ...mapMutations("profit", ["ADD_DOSE_STRAIN", "SET_DOSE_SCHEDULE","SET_LOAD_PROFIT"]),
    ...mapActions("profit", ["getBoostId"]),
    addAplication1(month, week) {
      this.monthsData[month][week].doses[0].push({ strain: "", dose: 0 });
    },
    addAplication2(month, week) {
      if (this.monthsData[month][week].doses[1]) {
        this.monthsData[month][week].doses[1].push({ strain: "", dose: 0 });
      } else {
        let aux = [{ strain: "", dose: 0 }];
        this.monthsData[month][week].doses.push(aux);
      }
    },
    removeAplication(month, week, day, prodIndex) {
      this.monthsData[month][week].doses[day].splice(prodIndex, 1);
    },
    checkInput(month, week) {
      if (this.monthsData[month][week].isNohdoses) {
        this.monthsData[month][week].doses = [];
      }
    },
    checkDoubleInput(month, week) {
      if (!this.monthsData[month][week].doubleWeekDoses) {
        if (this.monthsData[month][week].doses.length > 1) {
          this.monthsData[month][week].doses.splice(-1);
        }
      }
    },
  },
  watch: {
    monthsData: {
      handler: function (val) {
        let allStrains = [];
        for (let month = 0; month < val.length; month++) {
          for (let week = 0; week < val[month].length; week++) {
            for (let dose = 0; dose < val[month][week].doses.length; dose++) {
              for (
                let prod = 0;
                prod < val[month][week].doses[dose].length;
                prod++
              ) {
                for (let strain of this.strains) {
                  if (val[month][week].doses[dose][prod].strain) {
                    if (
                      strain.strainId ==
                      val[month][week].doses[dose][prod].strain.strainId
                    ) {
                      let total = 0;
                      let doseNumber = Number(
                        val[month][week].doses[dose][prod].dose
                      );
                      let oldValue = allStrains[strain.strainId];
                      if (oldValue) {
                        total = oldValue + doseNumber;
                      } else {
                        total = doseNumber;
                      }
                      allStrains[strain.strainId] = total;
                      break;
                    }
                  }
                }
              }
            }
          }
        }
        this.ADD_DOSE_STRAIN(allStrains);
      },
      deep: true,
    },
    months() {
      let aux = [];
      for (let i = 0; i < this.months; i++) {
        let month = [];
        for (let x = 0; x < 4; x++) {
          let week = [];
          for (let y = 0; y < 1; y++) {
            let day = [];
            day.push({
              applied: false,
              appliedDate: null,
              dose: 0,
              month: month + 1,
              strain: {
                dose: 0,
                isSelected: true,
                name: "Ag Boost 200",
                nameFull: "Ag Boost 200*Solena",
                strainId: this.idBoost,
                type: "Bioactivo",
              },
              week: week + 1,
              day: y + 1,
            });
            week.push(day);
          }
          month.push({ isNohdoses: false, doses: week, subject: "" });
        }
        aux.push(month);
      }

      for (let index = 0; index < aux.length; index++) {
        const element = this.monthsData[index];
        if (element) {
          aux[index] = element;
        }
      }

      this.monthsData = aux;
      this.SET_DOSE_SCHEDULE(aux);
    },
  },
};
</script>
