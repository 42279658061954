<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6" class="text-start">
        <v-btn color="primary" @click="goBack">
          <v-icon> mdi-arrow-left-thick </v-icon>
          Atras
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span class="font-weight-bold"> Proxima versión </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-container>
          <v-form lazy-validation ref="form">
            <v-row>
              <v-col>
                <span class="body-2 font-weight-bold">Rango de Cq's</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" v-if="molecularMarkerToUpdate.markerType == 'MO'">
                <v-text-field
                  dense
                  outlined
                  label="Ingresa ID marcador"
                  clearable
                  v-model="form.molecularMarkerID"
                  :rules="validate('ID')"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  label="CQ mínimo"
                  type="number"
                  clearable
                  v-model="form.cqMin"
                  :rules="validate('CQ mínimo')"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  label="CQ maximo"
                  type="number"
                  clearable
                  v-model="form.cqMax"
                  :rules="validate('CQ maximo')"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-end">
              <v-col cols="2">
                <v-btn color="warning" block @click="saveData">
                  <v-icon>mdi-content-save</v-icon>
                  Guardar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
// validations
import { requiredValidation } from "@/helpers/validations.js";
export default {
  data: () => ({
    searchIdentification: null,
    identificationsLoading: false,
    identifications: [],
    form: {
      identification: null,
      molecularMarkerID: null,
      isPathogen: false,
      cqMax: null,
      cqMin: null,
      soilFunction: null,
      metabolicPathway: null,
    },
  }),
  computed: {
    ...mapState("laboratory", ["molecularMarkerToUpdate"]),
  },
  async mounted() {
    await this.mapContent();
  },
  methods: {
    ...mapActions("identification", ["searchIdentifications"]),
    ...mapActions("laboratory", ["createMarker", "updateMarker"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async saveData() {
      try {
        if (this.$refs.form.validate()) {
          let payload = {
            molecularMarkerID: this.form.molecularMarkerID,
            cqMax: this.form.cqMax,
            cqMin: this.form.cqMin,
            version: this.molecularMarkerToUpdate.version + 1,
            isNewVersion: true,
          };
          if (this.molecularMarkerToUpdate.markerType == "FNC") {
            delete payload.molecularMarkerID;
          }
          //update marker
          let request = {
            body: payload,
            params: { markerId: this.molecularMarkerToUpdate._id },
          };
          await this.updateMarker(request);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: `Marcador rediseñado exitosamente`,
            timeout: 5000,
          });
          this.goBack();
        }
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
    },
    goBack() {
      this.$emit("back_update");
    },
    validate(name) {
      return requiredValidation(name);
    },
    async mapContent() {
      if (this.molecularMarkerToUpdate.markerType == "MO") {
        this.markerType = this.markersTypes[0];
        await this.getIdentifications(
          this.molecularMarkerToUpdate.microorganism_meta.name
        );
        this.form.identification = this.identifications.find(
          (element) =>
            element._id == this.molecularMarkerToUpdate.microorganism_meta._id
        );
        this.form.molecularMarkerID =
          this.molecularMarkerToUpdate.molecularMarkerID;
        this.form.isPathogen = this.molecularMarkerToUpdate.isPathogen;
        this.form.cqMin = this.molecularMarkerToUpdate.cqMin;
        this.form.cqMax = this.molecularMarkerToUpdate.cqMax;
      } else {
        this.markerType = this.markersTypes[1];
        this.form.soilFunction = this.molecularMarkerToUpdate.soilFunction;
        this.form.metabolicPathway =
          this.molecularMarkerToUpdate.metabolicPathway;
        this.form.cqMin = this.molecularMarkerToUpdate.cqMin;
        this.form.cqMax = this.molecularMarkerToUpdate.cqMax;
      }
    },
    async getIdentifications(value) {
      if (value) {
        try {
          this.identificationsLoading = true;
          const response = await this.searchIdentifications({
            name: value,
          });
          if (response.error) throw response;
          this.identifications = response.IDENTIFICATIONS;
        } catch (error) {
          console.log(error);
        }
        this.identificationsLoading = false;
      } else {
        this.identifications = [];
      }
    },
    getNextVersion(str, replacement) {
      // Convert the string to an array to modify specific positions
      let chars = str.split("");

      // Ensure the replacement has exactly 2 characters
      if (replacement.length !== 2) {
        throw new Error("The replacement must be exactly 2 characters long.");
      }

      // Replace specific positions with the replacement characters
      chars[4] = replacement[0];
      chars[5] = replacement[1];

      // Convert the array back to a string
      return chars.join("");
    },
  },
  watch: {
    async searchIdentification(value) {
      await this.getIdentifications(value);
    },
  },
};
</script>
