// Guard
import { privateGuard } from "@/middlewares/guards";

// Layouts
import NavLayout from "../layouts/NavLayout.vue";

// Views
import AdminCmda from "../views/AdminCmda.vue";

export default [
    {
        path: "/cmda-admin",
        beforeEnter: privateGuard,
        component: NavLayout,
        children: [
            {
                path: "",
                name: "Amin CMDA",
                component: AdminCmda,
                meta: {
                    title: "Consola de administración CMDA",
                },
            },
        ],
    }
];
