<template>
  <v-dialog v-model="dialog" persistent max-width="85%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on" block small>
        Administración de paquetes
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5"
          >Administración de paquetes ({{ laboratory.name }})</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" md="2" v-if="laboratory && !permissions.find((per) => per == 'lab_unedit')">
              <PackageModal
                :laboratory="laboratory"
                @packages_updated="getPackagesLab()"
              />
            </v-col>
            <v-col cols="12" md="2" v-if="laboratory && !permissions.find((per) => per == 'lab_unedit')">
              <v-btn color="primary" small block @click="openLogoStore = true">
                <v-icon> mdi-image-plus </v-icon>
                Gestionar iconos
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="packages"
                class="elevation-1"
                height="50vh"
                :items-per-page="100"
                fixed-header
                hide-default-footer
              >
                <template v-slot:item.name="{ item }">
                  <span v-if="item.isDisabled == true" class="red--text">
                    {{ item.name }}
                  </span>
                  <span v-else>
                    {{ item.name }}
                  </span>
                </template>
                <template v-slot:item.kitType="{ item }">
                  <v-chip
                    v-for="(type, index) in item.kitType"
                    :key="index"
                    color="primary"
                    dark
                    small
                  >
                    {{ kitTypeEs(type) }}
                  </v-chip>
                </template>
                <template v-slot:item.category="{ item }">
                  <span>{{ getCategoryEs(item.category) }}</span>
                </template>
                <template v-slot:item.crop="{ item }">
                  <v-chip
                    v-for="(crop, index) in item.crop"
                    x-small
                    :color="getCropColor(crop)"
                    :key="index"
                    class="chip_custom"
                    ><small>{{ getCrop(crop) }}</small></v-chip
                  >
                </template>
                <template v-slot:item.businessPartner="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        {{ item.businessPartner ? item.businessPartner : "-" }}
                      </span>
                    </template>
                    <div
                      v-for="(distributor, index) in item.distributors"
                      :key="index"
                    >
                      <span>
                        {{ distributor.companyName }}
                      </span>
                      <v-spacer></v-spacer>
                    </div>
                  </v-tooltip>
                </template>
                <template v-slot:item.zones="{ item }">
                  <div v-if="item.zones && item.zones.length != 0">
                    <v-chip
                      v-for="(item, index) in item.zones"
                      :key="index"
                      color="gray"
                      dark
                      small
                      class="chip_custom"
                    >
                      <span>
                        {{ item }}
                      </span>
                    </v-chip>
                  </div>
                  <div v-else>
                    <span> - </span>
                  </div>
                </template>
                <template v-slot:item.approach="{ item }">
                  {{ item.approach ? approachEs(item.approach) : "-" }}
                </template>
                <template v-slot:item.service="{ item }">
                  {{ item.service ? serviceEs(item.service) : "-" }}
                </template>
                <template v-slot:item.isPlantTissue="{ item }">
                  <v-chip small v-if="item.isPlantTissue" color="secondary"
                    >Vegetal ({{
                      getVegetalSampleType(item.vegetalSampleTypes)
                    }})</v-chip
                  >
                  <v-chip small v-else color="warning">Suelo / Raíz</v-chip>
                </template>
                <template v-slot:item.mos="{ item }">
                  {{ item.mos.length }}
                </template>

                <template v-slot:item.edit="{ item }">
                  <v-row v-if="!permissions.find((per) => per == 'lab_unedit')">
                    <v-col cols="4" v-if="item.isDisabled != true">
                      <PackageModal
                        :package="item"
                        :laboratory="laboratory"
                        @packages_updated="getPackagesLab()"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        :color="item.isDisabled != true ? 'red' : 'warning'"
                        dark
                        x-small
                        @click="
                          (packageSelected = item),
                            (showVerificationModal = true)
                        "
                      >
                        <v-icon small v-if="item.isDisabled != true"
                          >mdi-delete</v-icon
                        >
                        <v-icon small v-else>mdi-delete-restore</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="4" v-if="item.isDisabled != true">
                      <v-btn
                        color="blue"
                        dark
                        x-small
                        @click="
                          (packageSelected = item),
                            (showTransferPackageModal = true)
                        "
                      >
                        <v-icon small>mdi-transfer</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table></v-col
            >
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" text @click="dialog = false"> Cerrar </v-btn>
        <!-- <v-btn color="primary" text @click="updateReaction"> Guardar </v-btn> -->
      </v-card-actions>
    </v-card>
    <PackageLogosStore
      v-model="openLogoStore"
      v-if="openLogoStore"
      :laboratory="laboratory"
    />
    <VerificationModal
      v-model="showVerificationModal"
      v-if="showVerificationModal"
      @confirmation="deletePackage"
      :title="`¿Estás seguro que quieres ${
        packageSelected
          ? packageSelected.isDisabled == true
            ? 'habilitar'
            : 'inhabilitar'
          : ''
      } este paquete?`"
    />
    <TransferPackageModal
      v-model="showTransferPackageModal"
      v-if="showTransferPackageModal"
      @confirmation="transferPackage"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import PackageLogosStore from "@/components/cmda_admin/modals/PackageLogosStore";
import PackageModal from "@/components/cmda_admin/modals/PackageModal";
import VerificationModal from "@/components/cmda_admin/modals/VerificationModal";
import TransferPackageModal from "@/components/cmda_admin/modals/TransferPackageModal";
export default {
  components: {
    PackageModal,
    PackageLogosStore,
    VerificationModal,
    TransferPackageModal,
  },
  props: {
    laboratory: {
      type: Object,
      default: {
        name: "-",
      },
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      headers: [
        {
          text: "Nombre",
          sortable: false,
          value: "name",
        },
        {
          text: "Usuario CMDA",
          sortable: false,
          value: "businessPartner",
        },
        {
          text: "Región/s",
          sortable: false,
          value: "zones",
        },
        { text: "Tipo/s de kit", value: "kitType", sortable: false },
        { text: "Categoria", value: "category", sortable: false },
        { text: "Enfoque", value: "approach", sortable: false },
        { text: "Servicio", value: "service", sortable: false },
        { text: "Tipo de muestra", value: "isPlantTissue", sortable: false },
        { text: "Cultivo/s", value: "crop", sortable: false },
        { text: "# Marcadores moleculares", value: "mos", sortable: false },
        { text: "Acciones", value: "edit", sortable: false, align: "center" },
      ],
      packages: [],
      kitTypes: [
        {
          name: "HYBRID",
          esName: "HÍBRIDO",
        },
        {
          name: "QPCR",
          esName: "QPCR",
        },
      ],
      categories: [
        {
          name: "NUTRITION",
          esName: "Nutrición",
        },
        {
          name: "STRESS_TOLERANCE",
          esName: "Tolerancia a estres",
        },
        {
          name: "PATHOGEN_CONTROL",
          esName: "Control de patógenos",
        },
        {
          name: "BIOLOGICAL_RICHNESS",
          esName: "Riqueza biológica",
        },
        {
          name: "AGROCHEMICALS_USE",
          esName: "Uso de agroquímicos",
        },
        {
          name: "SOIL_AGGREGABILITY",
          esName: "Agregabilidad del suelo",
        },
        {
          name: "CO2_FIXATION",
          esName: "Fijación de CO2",
        },
        {
          name: "WATER_RETENTION",
          esName: "Retención de Agua",
        },
      ],
      sampleTypes: [
        {
          name: "soil",
          esName: "Suelo / Raíz",
        },
        {
          name: "vegetable",
          esName: "Material Vegetal",
        },
      ],
      vegetalSampleTypes: [
        { name: "Seedling", esName: "Plántula" },
        { name: "Root", esName: "Raíz" },
        { name: "Seed", esName: "Semilla" },
        { name: "Stem", esName: "Tallo" },
        { name: "Leaf", esName: "Hoja" },
        { name: "Fruit", esName: "Fruto" },
        { name: "Flower", esName: "Flor" },
      ],
      openLogoStore: false,
      showVerificationModal: false,
      packageSelected: null,
      showTransferPackageModal: false,
    };
  },
  computed: {
    ...mapState("session", ["permissions"]),
  },
  watch: {
    async dialog() {
      if (this.dialog) {
        await this.getPackagesLab();
      }
    },
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("laboratory", [
      "getPackages",
      "disablePackage",
      "transferringPackage",
    ]),
    approachEs(approach) {
      const approachs = {
        taxonomic: "Taxonomico",
        functional: "Funcional",
      };

      return approachs[approach];
    },
    serviceEs(service) {
      const services = {
        monitoring: "Monitoreo",
        prescription: "Prescripción",
      };

      return services[service];
    },
    kitTypeEs(kitType) {
      const types = {
        HYBRID: "Híbrido",
        QPCR: "QPCR",
      };

      return types[kitType];
    },
    getCategoryEs(category) {
      const categories = {
        NUTRITION: "Nutrición",
        STRESS_TOLERANCE: "Tolerancia a estrés",
        PATHOGEN_CONTROL: "Control de patógenos",
        BIOLOGICAL_RICHNESS: "Nutrición",
        AGROCHEMICALS_USE: "Nutrición",
        SOIL_AGGREGABILITY: "Nutrición",
        CO2_FIXATION: "Nutrición",
        WATER_RETENTION: "Nutrición",
      };

      return categories[category];
    },
    getCrop(value) {
      let template = "";
      const crop = {
        olive: "Aceituna",
        chard: "Acelga",
        achiote: "Achiote",
        agave: "Agave",
        avocado: "Aguacate",
        garlic: "Ajo",
        sesame: "Ajonjolí",
        basil: "Albahaca",
        artichoke: "Alcachofa",
        alcatraz: "Alcatraz",
        shrunkenAlfalfa: "Alfalfa achicalada",
        greenAlfalfa: "Alfalfa verde",
        cotton: "Algodón",
        almond: "Almendro",
        birdseed: "Alpiste",
        amaranth: "Amaranto",
        anise: "Anís",
        celery: "Apio",
        blueberry: "Arándano",
        myrtle: "Arrayán",
        rice: "Arroz",
        greenForageOats: "Avena forrajera en verde",
        oatGrain: "Avena grano",
        bamboo: "Bambú",
        eggplant: "Berenjena",
        beetroot: "Betabel",
        broccoli: "Brocoli",
        peanut: "Cacahuate",
        cocoa: "Cacao",
        cherryCoffee: "Café cereza",
        pumpkin: "Calabaza",
        sweetPotato: "Camote",
        sugarCane: "Caña de azúcar",
        canola: "Canola",
        carambolo: "Carambolo",
        safflower: "Cártamo",
        barley: "Cebada",
        greenForageBarley: "Cebada forrajera en verde",
        onion: "Cebolla",
        chives: "Cebollín",
        ryeGrain: "Centeno grano",
        cherry: "Cereza",
        chabacano: "Chabacano",
        pea: "Chícharo",
        squash: "Chayote",
        chia: "Chía",
        chilacayote: "Chilacayote",
        chillyPepper: "Chile / Pimiento",
        custardApple: "Chirimoya",
        coriander: "Cilantro",
        plum: "Ciruela",
        coconutFruit: "Coco fruta",
        cabbage: "Col (repollo)",
        brusselsSprouts: "Col de Bruselas",
        cauliflower: "Coliflor",
        cumin: "Comino",
        chrysanthemum: "Crisantemo (planta)",
        peach: "Durazno",
        date: "Dátil",
        greenBean: "Ejote",
        cornCob: "Elote",
        dill: "Eneldo",
        epazote: "Epazote",
        asparagus: "Espárrago",
        spicesAndMedicinals: "Especias y medicinales",
        spinach: "Espinaca",
        raspberry: "Frambuesa",
        strawberry: "Fresa",
        bean: "Frijol",
        chickpea: "Garbanzo",
        sunflower: "Girasol",
        pomegranate: "Granada",
        soursop: "Guanábana",
        guava: "Guayaba",
        henequen: "Henequén",
        peppermint: "Hierbabuena",
        aromaticHerbs: "Hierbas aromáticas",
        fig: "Higo",
        jamaica: "Jamaica",
        ginger: "Jenjibre",
        jicama: "Jícama",
        lettuce: "Lechuga",
        lentil: "Lenteja",
        lime: "Lima",
        lemon: "Limón",
        linseed: "Linaza",
        litchi: "Litchi",
        corn: "Maíz",
        macadamia: "Macadamia",
        maguey: "Maguey",
        mamey: "Mamey",
        tangerine: "Mandarina",
        mango: "Mango",
        mangosteen: "Mangostán",
        chamomile: "Manzanilla",
        appleTree: "Manzano",
        passionFruit: "Maracuyá",
        daisyFlower: "Margarita",
        marjoram: "Mejorana",
        melon: "Melón",
        quince: "Membrillo",
        mint: "Menta",
        moringa: "Moringa",
        turnip: "Nabo",
        nanche: "Nanche",
        napa: "Napa",
        orange: "Naranja",
        medlar: "Níspero",
        nochebuena: "Nochebuena (planta)",
        walnut: "Nogal",
        noni: "Noni",
        nopal: "Nopal",
        oregano: "Orégano",
        ornamental: "Ornamentales",
        orchid: "Orquídea (planta)",
        oilPalm: "Palma africana o de aceite",
        potato: "Papa",
        papaya: "Papaya",
        grasslands: "Pastizales",
        matGrass: "Pasto tapete (m2)",
        pasturesAndMeadows: "Pastos y praderas",
        cucumber: "Pepino",
        pearTree: "Peral",
        parsley: "Perejil",
        peron: "Perón",
        persimony: "Persimonio",
        pepper: "Pimienta",
        pineapple: "Piña",
        pinion: "Piñón",
        pistache: "Pistache",
        pitaya: "Pitaya",
        banana: "Plátano",
        pore: "Poro",
        radish: "Rábano",
        rambutan: "Rambután",
        romerito: "Romerito",
        rosemary: "Romero",
        aloe: "Sábila",
        watermelon: "Sandía",
        sorghum: "Sorgo",
        soy: "Soya",
        stevia: "Stevia",
        tobacco: "Tabaco",
        tamarind: "Tamarindo",
        tejocote: "Tejocote",
        tomato: "Tomate Rojo (jitomate)",
        greenTomato: "Tomate Verde",
        thyme: "Tomillo",
        grapefruit: "Toronja (pomelo)",
        clover: "Trébol",
        wheat: "Trigo",
        dutchTulip: "Tulipán holandés",
        tuna: "Tuna",
        grape: "Uva",
        vanilla: "Vainilla",
        purslane: "Verdolaga",
        vine: "Vid",
        cassavaFood: "Yuca alimenticia",
        carrot: "Zanahoria",
        sapodilla: "Zapote",
        blackberry: "Zarzamora",
        zempoalxochitl: "Zempoalxochitl",
        fallow: "Barbecho",
        other: "Otro",
      };
      let cropName = crop[value];
      if (typeof cropName != "undefined") {
        template = cropName;
      } else {
        template = "Sin cultivo";
      }
      return template;
    },
    getCropColor(value) {
      let template = "";
      const crop = {
        olive: "green lighten-4",
        chard: "green lighten-4",
        achiote: "orange lighten-4",
        agave: "brown lighten-4",
        avocado: "green lighten-4",
        garlic: "white",
        sesame: "brown lighten-4",
        basil: "green lighten-4",
        artichoke: "green lighten-4",
        alcatraz: "purple lighten-4",
        shrunkenAlfalfa: "green lighten-4",
        greenAlfalfa: "green lighten-4",
        cotton: "white",
        almond: "brown lighten-4",
        birdseed: "brown lighten-4",
        amaranth: "red lighten-4",
        anise: "brown lighten-4",
        celery: "green lighten-4",
        blueberry: "blue lighten-4",
        myrtle: "green lighten-4",
        rice: "white",
        greenForageOats: "green lighten-4",
        oatGrain: "brown lighten-4",
        bamboo: "green lighten-4",
        eggplant: "purple lighten-4",
        beetroot: "red lighten-4",
        broccoli: "green lighten-4",
        peanut: "brown lighten-4",
        cocoa: "brown lighten-4",
        cherryCoffee: "red lighten-4",
        pumpkin: "orange lighten-4",
        sweetPotato: "orange lighten-4",
        sugarCane: "green lighten-4",
        canola: "yellow lighten-4",
        carambolo: "yellow lighten-4",
        safflower: "yellow lighten-4",
        barley: "brown lighten-4",
        greenForageBarley: "green lighten-4",
        onion: "purple lighten-4",
        chives: "green lighten-4",
        ryeGrain: "brown lighten-4",
        cherry: "red lighten-4",
        chabacano: "orange lighten-4",
        pea: "green lighten-4",
        squash: "green lighten-4",
        chia: "brown lighten-4",
        chilacayote: "green lighten-4",
        chillyPepper: "red lighten-4",
        custardApple: "green lighten-4",
        coriander: "green lighten-4",
        plum: "purple lighten-4",
        coconutFruit: "brown lighten-4",
        cabbage: "green lighten-4",
        brusselsSprouts: "green lighten-4",
        cauliflower: "white",
        cumin: "brown lighten-4",
        chrysanthemum: "purple lighten-4",
        peach: "orange lighten-4",
        date: "brown lighten-4",
        greenBean: "green lighten-4",
        cornCob: "yellow lighten-4",
        dill: "green lighten-4",
        epazote: "green lighten-4",
        asparagus: "green lighten-4",
        spicesAndMedicinals: "green lighten-4",
        spinach: "green lighten-4",
        raspberry: "red lighten-4",
        strawberry: "red lighten-4",
        bean: "brown lighten-4",
        chickpea: "brown lighten-4",
        sunflower: "yellow lighten-4",
        pomegranate: "red lighten-4",
        soursop: "green lighten-4",
        guava: "green lighten-4",
        henequen: "brown lighten-4",
        peppermint: "green lighten-4",
        aromaticHerbs: "green lighten-4",
        fig: "purple lighten-4",
        jamaica: "red lighten-4",
        ginger: "brown lighten-4",
        jicama: "brown lighten-4",
        lettuce: "green lighten-4",
        lentil: "brown lighten-4",
        lime: "green lighten-4",
        lemon: "yellow lighten-4",
        linseed: "brown lighten-4",
        litchi: "red lighten-4",
        corn: "yellow lighten-4",
        macadamia: "brown lighten-4",
        maguey: "green lighten-4",
        mamey: "orange lighten-4",
        tangerine: "orange lighten-4",
        mango: "orange lighten-4",
        mangosteen: "purple lighten-4",
        chamomile: "white",
        appleTree: "green lighten-4",
        passionFruit: "purple lighten-4",
        daisyFlower: "white",
        marjoram: "green lighten-4",
        melon: "green lighten-4",
        quince: "orange lighten-4",
        mint: "green lighten-4",
        moringa: "green lighten-4",
        turnip: "white",
        nanche: "yellow lighten-4",
        napa: "green lighten-4",
        orange: "orange lighten-4",
        medlar: "orange lighten-4",
        nochebuena: "red lighten-4",
        walnut: "brown lighten-4",
        noni: "green lighten-4",
        nopal: "green lighten-4",
        oregano: "green lighten-4",
        ornamental: "green lighten-4",
        orchid: "purple lighten-4",
        oilPalm: "brown lighten-4",
        potato: "yellow lighten-4",
        papaya: "orange lighten-4",
        grasslands: "green lighten-4",
        matGrass: "green lighten-4",
        pasturesAndMeadows: "green lighten-4",
        cucumber: "green lighten-4",
        pearTree: "green lighten-4",
        parsley: "green lighten-4",
        peron: "green lighten-4",
        persimony: "orange lighten-4",
        pepper: "green lighten-4",
        pineapple: "yellow lighten-4",
        pinion: "brown lighten-4",
        pistache: "green lighten-4",
        pitaya: "pink lighten-4",
        banana: "yellow lighten-4",
        pore: "green lighten-4",
        radish: "red lighten-4",
        rambutan: "red lighten-4",
        romerito: "green lighten-4",
        rosemary: "green lighten-4",
        aloe: "green lighten-4",
        watermelon: "green lighten-4",
        sorghum: "brown lighten-4",
        soy: "brown lighten-4",
        stevia: "green lighten-4",
        tobacco: "brown lighten-4",
        tamarind: "brown lighten-4",
        tejocote: "orange lighten-4",
        tomato: "red lighten-4",
        greenTomato: "green lighten-4",
        thyme: "green lighten-4",
        grapefruit: "pink lighten-4",
        clover: "green lighten-4",
        wheat: "brown lighten-4",
        dutchTulip: "purple lighten-4",
        tuna: "red lighten-4",
        grape: "purple lighten-4",
        vanilla: "brown lighten-4",
        purslane: "green lighten-4",
        vine: "green lighten-4",
        cassavaFood: "brown lighten-4",
        carrot: "orange lighten-4",
        sapodilla: "brown lighten-4",
        blackberry: "purple lighten-4",
        zempoalxochitl: "orange lighten-4",
        fallow: "brown lighten-4",
        other: "grey lighten-4",
      };
      let cropName = crop[value];
      if (typeof cropName != "undefined") {
        template = cropName;
      } else {
        template = "default";
      }
      return template;
    },
    async getPackagesLab() {
      try {
        const req = await this.getPackages({ laboratory: this.laboratory._id });

        if (req.error) throw req;

        this.packages = req.packages;

        this.$emit("packages_updated", req.packages);

        console.log(req);
      } catch (error) {
        console.error(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: error.message,
          timeout: 4000,
        });
      }
    },
    getVegetalSampleType(types) {
      let label = "";
      if (!types) {
        return "-";
      }
      for (let index = 0; index < types.length; index++) {
        const element = types[index];
        const typeData = this.vegetalSampleTypes.find((t) => t.name == element);
        if (index == types.length - 1) {
          label += typeData.esName;
        } else {
          label += `${typeData.esName}, `;
        }
      }
      return label;
    },
    async deletePackage() {
      try {
        let payload = {
          _id: this.packageSelected._id,
          isDisabled:
            typeof this.packageSelected.isDisabled != "undefined"
              ? this.packageSelected.isDisabled
                ? false
                : true
              : true,
        };
        await this.disablePackage(payload);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: "Actividad de paquete actualizada",
          timeout: 4000,
        });
        await this.getPackagesLab();
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: error.message,
          timeout: 4000,
        });
      }
    },
    async transferPackage(lab) {
      try {
        let payload = {
          _id: this.packageSelected._id,
          laboratoryId: lab._id,
        };
        await this.transferringPackage(payload);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: "Paquete transferido con exito",
          timeout: 4000,
        });
        await this.getPackagesLab();
        this.$emit("updateTable");
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: error.message,
          timeout: 4000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chip_custom {
  margin: 1px;
}
</style>
ç
