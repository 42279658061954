export function SET_CONTAINERS(state, containers) {
    state.containers = containers;
}
export function SET_SELECTED_CONTAINER(state, containerSelected) {
    state.containerSelected = containerSelected;
}

export function SET_PCT_SELECTED(state, pctSelected) {
    state.pctSelected = pctSelected;
}


