<template>
  <v-dialog v-model="show" width="40vw" scrollable persistent>
    <v-card height="auto" elevation="5" min-height="200">
      <v-card-title class="text-h5 lighten-2">
        Registro preservación
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="formPreserve" lazy-validation>
            <v-row>
              <v-col>
                <v-text-field
                  dense
                  outlined
                  :rules="validationNumber()"
                  label="Ingresa número de microtubos a preservar"
                  v-model="preservationQty"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col>
              <v-btn
                color="gray"
                block
                @click="show = false"
                dark
                :disabled="isLoading"
                >Cancelar</v-btn
              >
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                block
                @click="confirmPreservation"
                :loading="isLoading"
                >Preservar</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//Vuex
import { mapActions, mapMutations } from "vuex";
// validations
import { numberValidation } from "@/helpers/validations.js";
export default {
  data: () => ({
    isLoading: false,
    preservationQty: null,
  }),
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    validationNumber() {
      return numberValidation({ min: 1, required: true });
    },
    confirmPreservation() {
      if (this.$refs.formPreserve.validate()) {
        this.$emit("confirm", this.preservationQty);
      }
    },
  },
};
</script>
