<template>
  <v-card tile elevation="0" class="my-auto pb-1">
    <v-container>
      <v-row>
        <v-col cols="2" class="py-2 pb-0" @click="returnView">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                class="pointer"
              >
                mdi-arrow-left-thick
              </v-icon>
            </template>
            <span>Información de la muestra</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <!-- Photo row -->
      <v-row ref="parent" id="parent">
        <image-editor
          v-if="widthParent > 0 && imageUrl != null"
          :widthParent="widthParentString"
          :colonies="colonies"
          :imageUrl="imageUrl"
          @colonieCreated="colonieCreated"
          :editor="false"
        />
        <v-img
          v-else-if="imageUrl == null"
          :src="require('../../assets/img/no_image_strain.png')"
          aspect-ratio="1.0"
          contain
        ></v-img>
      </v-row>
      <template>
        <!-- Analisis data row -->
        <v-row class="px-10">
          <v-col cols="7">
            <p class="font-weight-bold text-left mb-1">
              Análisis
              {{ microbiologicalTestSelected.soilSample.folioAnalysis }}
            </p>
          </v-col>
          <v-col cols="5">
            <p
              class="font-weight-bold text-center mb-1"
              v-if="microbiologicalTestSelected.soilSample.soilArea.cropCycles"
            >
              {{
                getCrop(
                  microbiologicalTestSelected.soilSample.soilArea.cropCycles
                )
              }}
            </p>
            <p class="font-weight-bold text-center mb-1" v-else>Sin cultivo</p>
          </v-col>
        </v-row>
        <!-- Test data headers row -->
        <v-row class="mt-0 pr-2">
          <v-col cols="3 px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              ID ensayo
            </p>
          </v-col>
          <v-col cols="2 px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">Medio</p>
          </v-col>
          <v-col cols="2 px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              Dilución
            </p>
          </v-col>
          <v-col cols="2 px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              Caja petri
            </p>
          </v-col>
          <v-col cols="3 px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              Tipo análisis
            </p>
          </v-col>
        </v-row>
        <!-- Test data row -->
        <v-row class="mt-0 pr-2">
          <v-col cols="3 px-0">
            <p class="text-caption text-center mb-1">
              {{ microbiologicalTestSelected.testMedia._id }}
            </p>
          </v-col>
          <v-col cols="2 px-0">
            <p class="text-caption text-center mb-1">
              {{
                microbiologicalTestSelected.testMedia.cultureMedia.nutrientAgar
              }}
            </p>
          </v-col>
          <v-col cols="2 px-0">
            <p class="text-caption text-center mb-1">
              {{ microbiologicalTestSelected.inoculation.dilution }}
            </p>
          </v-col>
          <v-col cols="2 px-0">
            <p
              class="text-caption text-center mb-1"
              v-if="
                microbiologicalTestSelected.testMedia.containerType ==
                'petriDish90mmx15mm'
              "
            >
              90x15 mm
            </p>
            <p class="text-caption text-center mb-1" v-else>60x15 mm</p>
          </v-col>
          <v-col cols="3 px-0">
            <p class="text-caption text-center mb-1">
              {{
                getMoType(
                  microbiologicalTestSelected.testMedia.cultureMedia.moType
                )
              }}
            </p>
          </v-col>
        </v-row>
        <!-- Action buttons -->
        <v-row justify="center" align="center">
          <v-col cols="6">
            <reseeding-modal
              :microbiologicalTestSelected="microbiologicalTestSelected"
              :strains="selected"
              @reseedSaved="$emit('reseedSaved')"
            />
          </v-col>
          <v-col cols="6">
            <v-menu
              v-model="menuMoType"
              :close-on-content-click="false"
              transition="slide-y-transition"
              offset-y
            >
              <template v-slot:activator="{}">
                <v-btn
                  depressed
                  block
                  :disabled="
                    microbiologicalTestSelected.status == 'COMPLETED' ||
                    microbiologicalTestSelected.status == 'INCUBATING'
                  "
                  color="primary"
                  @click="addStrainEvent"
                >
                  Agregar cepa
                </v-btn>
              </template>

              <v-card>
                <v-container>
                  <v-row>
                    <v-btn color="secondary" text block @click="addFungiEvent"
                      >Hongo</v-btn
                    >
                  </v-row>
                  <v-row>
                    <v-btn
                      color="secondary"
                      text
                      block
                      @click="addBacteriaEvent"
                      >Bacteria</v-btn
                    >
                  </v-row>
                </v-container>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
        <!-- Area data row -->
        <v-row class="px-6 mt-0">
          <v-container>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="strains"
              :single-select="false"
              item-key="idStrain"
              show-select
              class="elevation-0"
              mobile-breakpoint="0"
              hide-default-footer
            >
              <template v-slot:[`header.identification`]="{}">
                <span
                  >{{
                    getMoType(
                      microbiologicalTestSelected.testMedia.cultureMedia.moType
                    )
                  }}</span
                >
              </template>
              <template v-slot:[`item.identification`]="{ item }">
                <p
                  class="ma-0 text-caption text-center warning--text"
                  v-if="
                    item.threat && item.identification != 'Sin identificación'
                  "
                >
                  {{ item.identification }}
                </p>
                <p
                  class="ma-0 text-caption text-center primary--text"
                  v-else-if="
                    !item.threat && item.identification != 'Sin identificación'
                  "
                >
                  {{ item.identification }}
                </p>
                <p class="ma-0 text-caption text-center" v-else>
                  {{ item.identification }}
                </p>
              </template>
              <template v-slot:[`item.idStrain`]="{ item }">
                <p class="ma-0 text-caption text-center">{{ item.idStrain }}</p>
              </template>
              <template v-slot:[`item.colonies`]="{ item }">
                <p class="ma-0 text-caption text-center">{{ item.colonies }}</p>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu
                  :offset-x="true"
                  :offset-y="false"
                  transition="slide-x-transition"
                  max-height="300"
                  v-if="microbiologicalTestSelected.status == 'IN_PROGRESS'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-bind="attrs" v-on="on">
                      mdi-dots-vertical
                    </v-icon>
                  </template>
                  <v-card :height="item.isolates.length == 0 ? '65' : '38'">
                    <v-container class="pt-4 pb-4">
                      <v-row align="center" justify="center">
                        <v-btn
                          block
                          text
                          small
                          :color="'primary'"
                          @click="$emit('updateStrainClicked', item._id)"
                        >
                          Actualizar
                        </v-btn>
                        <v-btn
                          v-if="item.isolates.length == 0"
                          text
                          block
                          small
                          @click="deleteStrainConfirmation(item._id)"
                        >
                          Eliminar
                        </v-btn>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-menu>
              </template>
              <template v-slot:[`item.concentration`]="{ item }">
                <p
                  v-if="
                    microbiologicalTestSelected.inoculation.dilution !=
                    'M. Vegetal'
                  "
                  class="ma-0 text-caption text-center"
                >
                  {{ item.concentration }}
                </p>
                <p v-else class="ma-0 text-caption text-center">Presencia</p>
              </template>
            </v-data-table>
          </v-container>
        </v-row>
      </template>
    </v-container>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Confirmación </v-card-title>
        <v-card-text>¿Realmente deseas eliminar la cepa?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning darken-1" text @click="cancelDeleteStrain">
            Cancelar
          </v-btn>
          <v-btn color="secondary darken-1" text @click="deleteStrainAction">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ReseedingModal from "./modals/reseedingModal.vue";
import imageEditor from "../utils/imageEditor.vue";

export default {
  components: {
    ReseedingModal,
    imageEditor,
  },
  data() {
    return {
      menuMoType: false,
      singleSelect: false,
      dialog: false,
      selected: [],
      headers: [
        {
          text: "Identificación",
          value: "identification",
          sortable: false,
          align: "center",
        },
        // {
        //   text: "ID Cepa",
        //   value: "idStrain",
        //   sortable: false,
        //   align: "center",
        // },
        {
          text: "Colonias",
          value: "colonies",
          sortable: false,
          align: "center",
        },
        {
          text: "Concentración",
          value: "concentration",
          sortable: false,
          align: "center",
        },
        { text: " ", value: "actions", sortable: false, align: "center" },
      ],
      strains: [],
      items: [{ title: "Actualizar" }, { title: "Eliminar" }],
      // EDITOR VARIABLES
      ro: {},
      widthParent: 0,
      widthParentString: "",
      colonies: [],
      imageUrl: null,
      strainToDelete: null,
    };
  },
  async created() {
    if (this.microbiologicalTestSelected) {
      let strains = await this.getStrainsByMT(
        this.microbiologicalTestSelected._id
      );
      this.formatStrains(strains);
      this.getImageURL();
    }
  },
  methods: {
    ...mapActions("strain", ["getStrainsByMBTest"]),
    deleteStrainConfirmation(idItem) {
      for (let index = 0; index < this.strains.length; index++) {
        const strain = this.strains[index];
        if (strain._id == idItem) {
          this.strainToDelete = strain;
          break;
        }
      }

      this.dialog = true;
    },
    deleteStrainAction() {
      for (let index = 0; index < this.strains.length; index++) {
        const strain = this.strains[index];
        if (strain._id == this.strainToDelete._id) {
          this.strains.splice(index, 1);
          break;
        }
      }

      this.$emit("deleteStrainClicked", this.strainToDelete._id);
      this.dialog = false;
      this.strainToDelete = null;
    },
    cancelDeleteStrain() {
      this.strainToDelete = null;
      this.dialog = false;
    },
    async getStrainsByMT(mbTest) {
      try {
        const response = await this.getStrainsByMBTest(mbTest);
        if (response.error) throw response;
        else return response.STRAINS;
      } catch (error) {
        console.log("Error getting strains by id", error);
      }
    },
    formatStrains(strains) {
      this.strains = [];

      let sampleComposition = this.microbiologicalTestSelected.soilSample
        .composition
        ? this.microbiologicalTestSelected.soilSample.composition
        : "SOLID";
      let index = 1;
      let petriId = this.microbiologicalTestSelected.testMedia._id;

      for (const strain of strains) {
        this.strains.push({
          identification:
            strain.identification != null
              ? strain.identification.name
              : "Sin identificación",
          type:
            strain.identification != null ? strain.identification.moType : null,
          idStrain: `${petriId}-${index}`,
          colonies: strain.colonies.number,
          isolates: strain.isolates,
          concentration:
            sampleComposition == "SOLID"
              ? `${parseInt(strain.concentration).toExponential(2)} UFC/g`
              : `${parseInt(strain.concentration).toExponential(2)} UFC/ml`,
          _id: strain._id,
          colonyData: strain.colonies,
          threat: strain.posibleThreat,
          idTest: null,
        });

        index++;
      }
    },
    getImageURL() {
      let image = this.microbiologicalTestSelected.incubation.morphologyPhoto;

      if (typeof image != "undefined") {
        this.imageUrl = image;
      } else {
        this.imageUrl = null;
      }
    },
    onResize() {
      this.widthParent = this.$refs.parent.offsetWidth;
    },
    colonieCreated(colonies) {
      console.log(colonies);
      this.colonies = colonies;
    },
    getMoType(value) {
      let types = {
        all: "Todos",
        bacteria: "Bacterias",
        fungi: "Hongos",
        nematode: "Nemátodos",
      };
      return types[value];
    },
    addStrainEvent() {
      if (
        this.microbiologicalTestSelected.testMedia.cultureMedia.moType == "all"
      ) {
        if (this.strains.length == 0) {
          this.menuMoType = true;
        } else {
          let firstStrain = this.strains[0];
          if (firstStrain.type == null) {
            this.menuMoType = true;
          } else if (firstStrain.type == "fungi") {
            this.addFungiEvent();
          } else if (firstStrain.type == "bacteria") {
            this.addBacteriaEvent();
          } else if (firstStrain.type == "all") {
            this.addAllEvent();
          }
          // console.log(this.strains);
        }
      } else {
        this.$emit("addStrainClicked", null);
      }
    },
    addFungiEvent() {
      this.$emit("addStrainClicked", "fungi");
    },
    addBacteriaEvent() {
      this.$emit("addStrainClicked", "bacteria");
    },
    addAllEvent() {
      this.$emit("addStrainClicked", "all");
    },
    returnView() {
      this.$emit("returnView");
    },
    getCrop(cropCycles) {
      if (cropCycles.length > 0) {
        let cropCycle = cropCycles[cropCycles.length - 1];
        let crop = cropCycle.crop;
        let cropName = this.getCropName(crop);

        return `${cropName}`;
      } else {
        return "Sin cultivo";
      }
    },
    getCropName(cropCycleName) {
      let template = "- - - -";
      const crop = {
        olive: "Aceituna",
        chard: "Acelga",
        achiote: "Achiote",
        agave: "Agave",
        avocado: "Aguacate",
        garlic: "Ajo",
        sesame: "Ajonjolí",
        basil: "Albahaca",
        artichoke: "Alcachofa",
        alcatraz: "Alcatraz",
        shrunkenAlfalfa: "Alfalfa achicalada",
        greenAlfalfa: "Alfalfa verde",
        cotton: "Algodón",
        almond: "Almendro",
        birdseed: "Alpiste",
        amaranth: "Amaranto",
        anise: "Anís",
        celery: "Apio",
        blueberry: "Arándano",
        myrtle: "Arrayán",
        rice: "Arroz",
        greenForageOats: "Avena forrajera en verde",
        oatGrain: "Avena grano",
        bamboo: "Bambú",
        eggplant: "Berenjena",
        beetroot: "Betabel",
        broccoli: "Brocoli",
        peanut: "Cacahuate",
        cocoa: "Cacao",
        cherryCoffee: "Café cereza",
        pumpkin: "Calabaza",
        sweetPotato: "Camote",
        sugarCane: "Caña de azúcar",
        canola: "Canola",
        carambolo: "Carambolo",
        safflower: "Cártamo",
        barley: "Cebada",
        greenForageBarley: "Cebada forrajera en verde",
        onion: "Cebolla",
        chives: "Cebollín",
        ryeGrain: "Centeno grano",
        cherry: "Cereza",
        chabacano: "Chabacano",
        pea: "Chícharo",
        squash: "Chayote",
        chia: "Chía",
        chilacayote: "Chilacayote",
        chillyPepper: "Chile / Pimiento",
        custardApple: "Chirimoya",
        coriander: "Cilantro",
        plum: "Ciruela",
        coconutFruit: "Coco fruta",
        cabbage: "Col (repollo)",
        brusselsSprouts: "Col de Bruselas",
        cauliflower: "Coliflor",
        cumin: "Comino",
        chrysanthemum: "Crisantemo (planta)",
        peach: "Durazno",
        date: "Dátil",
        greenBean: "Ejote",
        cornCob: "Elote",
        dill: "Eneldo",
        epazote: "Epazote",
        asparagus: "Espárrago",
        spicesAndMedicinals: "Especias y medicinales",
        spinach: "Espinaca",
        raspberry: "Frambuesa",
        strawberry: "Fresa",
        bean: "Frijol",
        chickpea: "Garbanzo",
        sunflower: "Girasol",
        pomegranate: "Granada",
        soursop: "Guanábana",
        guava: "Guayaba",
        henequen: "Henequén",
        peppermint: "Hierbabuena",
        aromaticHerbs: "Hierbas aromáticas",
        fig: "Higo",
        jamaica: "Jamaica",
        ginger: "Jenjibre",
        jicama: "Jícama",
        lettuce: "Lechuga",
        lentil: "Lenteja",
        lime: "Lima",
        lemon: "Limón",
        linseed: "Linaza",
        litchi: "Litchi",
        corn: "Maíz",
        macadamia: "Macadamia",
        maguey: "Maguey",
        mamey: "Mamey",
        tangerine: "Mandarina",
        mango: "Mango",
        mangosteen: "Mangostán",
        chamomile: "Manzanilla",
        appleTree: "Manzano",
        passionFruit: "Maracuyá",
        daisyFlower: "Margarita",
        marjoram: "Mejorana",
        melon: "Melón",
        quince: "Membrillo",
        mint: "Menta",
        moringa: "Moringa",
        turnip: "Nabo",
        nanche: "Nanche",
        napa: "Napa",
        orange: "Naranja",
        medlar: "Níspero",
        nochebuena: "Nochebuena (planta)",
        walnut: "Nogal",
        noni: "Noni",
        nopal: "Nopal",
        oregano: "Orégano",
        ornamental: "Ornamentales",
        orchid: "Orquídea (planta)",
        oilPalm: "Palma africana o de aceite",
        potato: "Papa",
        papaya: "Papaya",
        grasslands: "Pastizales",
        matGrass: "Pasto tapete (m2)",
        pasturesAndMeadows: "Pastos y praderas",
        cucumber: "Pepino",
        pearTree: "Peral",
        parsley: "Perejil",
        peron: "Perón",
        persimony: "Persimonio",
        pepper: "Pimienta",
        pineapple: "Piña",
        pinion: "Piñón",
        pistache: "Pistache",
        pitaya: "Pitaya",
        banana: "Plátano",
        pore: "Poro",
        radish: "Rábano",
        rambutan: "Rambután",
        romerito: "Romerito",
        rosemary: "Romero",
        aloe: "Sábila",
        watermelon: "Sandía",
        sorghum: "Sorgo",
        soy: "Soya",
        stevia: "Stevia",
        tobacco: "Tabaco",
        tamarind: "Tamarindo",
        tejocote: "Tejocote",
        tomato: "Tomate Rojo (jitomate)",
        greenTomato: "Tomate Verde",
        thyme: "Tomillo",
        grapefruit: "Toronja (pomelo)",
        clover: "Trébol",
        wheat: "Trigo",
        dutchTulip: "Tulipán holandés",
        tuna: "Tuna",
        grape: "Uva",
        vanilla: "Vainilla",
        purslane: "Verdolaga",
        vine: "Vid",
        cassavaFood: "Yuca alimenticia",
        carrot: "Zanahoria",
        sapodilla: "Zapote",
        blackberry: "Zarzamora",
        zempoalxochitl: "Zempoalxochitl",
        fallow: "Barbecho",
        other: "Otro",
      };
      if (cropCycleName !== null) {
        let cropName = crop[cropCycleName.cropName];
        if (typeof cropName != "undefined") {
          template = cropName;
          if (
            cropCycleName.variety != undefined &&
            cropCycleName.variety.length > 0
          ) {
            template += `(${cropCycleName.variety})`;
          }
        }
      }
      return template;
    },
  },
  computed: {
    ...mapState("microbiologicalTest", ["microbiologicalTestSelected"]),
  },
  watch: {
    selected() {
      this.colonies = [];
      for (const strain of this.selected) {
        for (const colony of strain.colonyData.colonyLocation) {
          this.colonies.push({
            width: colony.width,
            height: colony.height,
            top: colony.top,
            left: colony.left,
            label: colony.label,
            threat: strain.type != null ? strain.threat : undefined,
            imageProperties:
              typeof colony.imageProperties != "undefined"
                ? colony.imageProperties
                : null,
          });
        }
      }
    },
    widthParent() {
      this.widthParentString = `${this.widthParent - 15}`;
    },
    async microbiologicalTestSelected() {
      if (this.microbiologicalTestSelected) {
        let strains = await this.getStrainsByMT(
          this.microbiologicalTestSelected._id
        );
        console.log("STRAINS", strains);
        this.formatStrains(strains);
        this.getImageURL();
      }
    },
  },
  mounted() {
    this.ro = new ResizeObserver(this.onResize);
    this.ro.observe(this.$refs.parent);
  },
  beforeDestroy() {
    this.ro.unobserve(this.$refs.parent);
  },
};
</script>
