<template>
  <v-row class="fill-height">
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <h5>IMPRESIÓN DE ETIQUETAS</h5>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="4"
          md="4"
          v-if="permissions.find((per) => per == 'lab_labels_petri')"
        >
          <v-card class="fill-height">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-btn
                    color="success"
                    block
                    class="py-8"
                    @click="showNewMBFromFolio = true"
                  >
                    <v-icon left>mdi-dots-horizontal-circle-outline</v-icon>
                    Cajas madre
                  </v-btn>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          sm="4"
          md="4"
          v-if="permissions.find((per) => per == 'lab_labels_valuation')"
        >
          <v-card class="fill-height">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-btn
                    color="success"
                    block
                    class="py-8"
                    @click="showValuationModal = true"
                  >
                    <v-icon left> mdi-circle-opacity </v-icon>
                    Ensayos de valuación
                  </v-btn>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          sm="4"
          md="4"
          v-if="
            permissions.find((per) => per == 'lab_labels_ceparium_reception')
          "
        >
          <v-card class="fill-height">
            <v-card-text>
              <v-container>
                <v-row>
                  <!-- <v-btn
                    color="success"
                    block
                    class="py-8"
                    @click="showReseedModal = true"
                  >
                    <v-icon left>mdi-star-four-points-circle-outline</v-icon>
                    Resiembras</v-btn
                  > -->
                  <v-btn
                    color="success"
                    block
                    class="py-8"
                    @click="showReceptionCepariumVue = true"
                  >
                    <v-icon left>mdi mdi-bacteria</v-icon>
                    Recepción cepario</v-btn
                  >
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          sm="4"
          md="4"
          v-if="permissions.find((per) => per == 'lab_labels_ceparium_reseed')"
        >
          <v-card class="fill-height">
            <v-card-text>
              <v-container>
                <v-row>
                  <CepariumReseedsModal />
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        v-if="
          permissions.find((per) => per == 'lab_labels_incubation_interruption')
        "
      >
        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-title>
                  <h5>ESCANEO DE ETIQUETAS</h5>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-card class="fill-height">
            <v-card-text>
              <v-container>
                <v-row>
                  <IncubationModal />
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-card class="fill-height">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-btn
                    color="warning"
                    block
                    class="py-8"
                    @click="showInterruptionModal = true"
                  >
                    <v-icon left>mdi-camera</v-icon> Interrupción de incubación
                  </v-btn>
                  <InterruptionModal
                    v-model="showInterruptionModal"
                    v-if="showInterruptionModal"
                  />
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- <v-col cols="12" sm="4" md="4">
          <v-card class="fill-height">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-btn
                    color="warning"
                    block
                    class="py-8"
                    @click="showNewScanner = true"
                  >
                    <v-icon left>mdi-camera</v-icon> Nuevo scaner
                  </v-btn>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col> -->
      </v-row>
    </v-col>
    <!-- modal reseed -->
    <reseed-modal v-model="showReseedModal" v-if="showReseedModal" />
    <NewMBFromFolio v-model="showNewMBFromFolio" v-if="showNewMBFromFolio" />
    <ValuationModal v-model="showValuationModal" v-if="showValuationModal" />
    <NewScanner v-model="showNewScanner" v-if="showNewScanner" />
    <ReceptionCepariumVue
      v-model="showReceptionCepariumVue"
      v-if="showReceptionCepariumVue"
    />
  </v-row>
</template>

<script>
import CepariumReseedsModal from "../components/labels/modals/CepariumReseedsModal.vue";
import IncubationModal from "../components/labels/modals/IncubationModal.vue";
import InterruptionModal from "../components/labels/modals/InterruptionModal.vue";
import ReseedModal from "@/components/labels/modals/ReseedModal.vue";
import NewMBFromFolio from "@/components/labels/modals/NewMBFromFolio.vue";
import ValuationModal from "@/components/labels/modals/ValuationModal.vue";
import ReceptionCepariumVue from "../components/labels/modals/ReceptionCeparium.vue";
import NewScanner from "../components/labels/modals/NewScanner.vue";
//vuex
import { mapState } from "vuex";

export default {
  components: {
    NewScanner,
    CepariumReseedsModal,
    IncubationModal,
    InterruptionModal,
    ReseedModal,
    NewMBFromFolio,
    ValuationModal,
    ReceptionCepariumVue,
  },
  data() {
    return {
      showNewScanner: false,
      showInterruptionModal: false,
      closeOnClick: false,
      biocontrols: ["Malo", "Deficiente", "Bueno", "Muy Bueno"],
      solubilizations: ["Mala", "Media", "Buena", "Muy buena"],
      microorganisms: [
        "Botrytis cinerea",
        "Cladosporium fulvum",
        "Bacillus subtilis",
      ],
      page: 1,
      tab: "#reseed",
      headers: [
        {
          text: "ID. Cepa",
          sortable: false,
          value: "name",
        },
        { text: "Folio", value: "folioAnalysis" },
        { text: "Fecha de preservación", value: "preservedDate" },
        { text: "Organismo", value: "identification" },
        { text: "# Tubos", value: "tubes" },
      ],
      preserved_strains: [
        {
          name: "PB103",
          folioAnalysis: "11023-A",
          preservedDate: "25/04/2023",
          identification: "Bacillus thuringiensis",
          tubes: 4,
        },
        {
          name: "PB104",
          folioAnalysis: "10953-D",
          preservedDate: "25/06/2023",
          identification: "-",
          tubes: 4,
        },
        {
          name: "PH222",
          folioAnalysis: "10999-C",
          preservedDate: "12/07/2023",
          identification: "Azotobacter sp.",
          tubes: 6,
        },
        {
          name: "PH223",
          folioAnalysis: "11011-B",
          preservedDate: "11/09/2023",
          identification: "Fusarium nygamai",
          tubes: 4,
        },
      ],
      showReseedModal: false,
      showNewMBFromFolio: false,
      showValuationModal: false,
      showReceptionCepariumVue: false,
    };
  },
  computed: {
    ...mapState("session", ["permissions"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
