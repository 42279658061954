<template>
  <v-card tile elevation="0" class="my-auto pb-1">
    <v-container>
      <!-- Photo row -->
      <CapturePhotoModal
        v-if="showCaputre"
        @close="closePhoto"
        @strainFile="saveStrainFile"
        :photos="
          strain && strain.microscopePhotos ? strain.microscopePhotos : []
        "
      />
      <v-row ref="parent" id="parent">
        <image-editor
          v-if="widthParent > 0 && imageUrl != null"
          :widthParent="widthParentString"
          :colonies="colonies"
          :editor="editImage"
          :imageUrl="imageUrl"
          :reseed="isolate != null"
          @colonieCreated="colonieCreated"
          @colonieRemoved="colonieRemoved"
        />
        <v-img
          v-else-if="imageUrl == null"
          :src="require('../../assets/img/no_image_strain.png')"
          aspect-ratio="1.0"
          contain
        ></v-img>
      </v-row>

      <template>
        <v-container>
          <!-- <v-row
            v-if="
              (isolate == null && coloniesLength == 0 && coloniesLength != '') && microbiologicalTestSelected.inoculation.dilution != 'M. Vegetal'
            "
            align="center"
            justify="center"
          >
            <v-col cols="6" class="pb-0">
              <p class="secondary--text font-weight-regular">
                Número de colonias: {{ coloniesLength }}
              </p>
            </v-col>
          </v-row> -->
          <v-row
            v-if="
              isolate == null ||
              (isolate == null &&
                imageUrl == null &&
                microbiologicalTestSelected.inoculation.dilution !=
                  'M. Vegetal')
            "
            align="center"
            justify="center"
          >
            <v-col cols="6" class="px-0">
              <p class="secondary--text font-weight-regular mb-0 text-center">
                Número de colonias:
              </p>
            </v-col>
            <v-col cols="2" class="px-0 py-0">
              <v-text-field
                v-model="coloniesLength"
                :rules="[(v) => v > 0 || 'Número de colonias inválido']"
                type="number"
                class="centered-input"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row :class="isolate != null ? 'mt-2' : ''">
            <v-col cols="10" class="pb-0">
              <v-autocomplete
                v-model="identification"
                :search-input.sync="search"
                :items="identificationsFounded"
                :loading="isLoading"
                :disabled="disableFields"
                @keydown="changeIdentificationValue"
                label="Identificación final"
                placeholder="Identificación final"
                item-text="name"
                return-object
                outlined
                dense
                no-data-text="No hay identificaciones"
                :error="error"
                :error-messages="errorMessage"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2" class="pt-4 pb-0">
              <add-new-identification-modal moType="fungi" />
            </v-col>
          </v-row>
          <v-form ref="form" v-model="form" lazy-validation>
            <v-row class="pt-0 mt-0" justify="center" align="center">
              <v-col cols="6" class="py-1">
                <v-select
                  v-model="moMorphology.obverse"
                  :items="fungiColor"
                  :disabled="disableFields"
                  :rules="formRules"
                  class="pt-0 mt-0"
                  label="Color anverso"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="6" class="py-1">
                <v-select
                  v-model="moMorphology.reverse"
                  :items="fungiColor"
                  :disabled="disableFields"
                  :rules="formRules"
                  class="pt-0 mt-0"
                  label="Color reverso"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="pt-0 mt-0" justify="center" align="center">
              <v-col cols="6" class="py-1">
                <v-select
                  v-model="moMorphology.texture"
                  :items="fungiTexture"
                  :disabled="disableFields"
                  :rules="formRules"
                  class="pt-0 mt-0"
                  label="Aspecto"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="6" class="py-1">
                <v-select
                  v-model="moMorphology.growth"
                  :items="growth"
                  :disabled="disableFields"
                  :rules="formRules"
                  class="pt-0 mt-0"
                  label="Crecimiento"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
          <v-row class="pt-0 mt-4" justify="center" align="center">
            <v-col cols="6" class="py-0">
              <v-row justify="center" align="center">
                <v-switch
                  v-model="moMorphology.exudates"
                  :value="true"
                  :disabled="disableFields"
                  dense
                  class="pt-0 mt-0"
                  inset
                  color="secondary"
                  label="Exudados"
                ></v-switch>
              </v-row>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-row justify="center" align="center">
                <v-switch
                  v-model="moMorphology.posibleThreat"
                  :value="false"
                  :disabled="disableFields"
                  dense
                  class="pt-0 mt-0"
                  inset
                  color="warning"
                  label="Patógeno"
                ></v-switch>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-container>
    <v-card-actions class="justify-center pt-0">
      <v-row class="pt-0" justify="center" align="center">
        <v-col cols="6">
          <v-btn depressed block @click="openPhoto"> Foto microscópica </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            depressed
            block
            color="secondary"
            @click="saveStrain"
            :loading="loadingSave"
            :disabled="disableFields"
          >
            Guardar
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import imageEditor from "../utils/imageEditor.vue";
import AutocompleteIdentifications from "./autocompleteIdentifications.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import CapturePhotoModal from "./modals/CapturePhotoModal.vue";
import AddNewIdentificationModal from "./modals/addNewIdentificationModal.vue";

export default {
  components: {
    imageEditor,
    AutocompleteIdentifications,
    CapturePhotoModal,
    AddNewIdentificationModal,
  },
  data() {
    return {
      error: false,
      errorMessage: "",
      search: "",
      isLoading: false,
      identifications: [
        {
          name: "Sin identificar",
        },
      ],
      formData: [],
      showCaputre: false,
      ro: {},
      imageUrl: null,
      form: true,
      formRules: [(v) => !!v || "El campo es requerido"],
      editImage: false,
      disableFields: false,
      widthParent: 0,
      widthParentString: "",
      coloniesLength: 0,
      colonies: [],
      identification: {
        name: "Sin identificar",
      },
      search: "",
      isLoading: false,
      loadingSave: false,
      fillData: false,
      menu: false,
      moMorphology: {
        identification: null,
        moType: null,

        type: "bacteria",

        shape: "",
        margin: "",
        elevation: "",
        surface: [],
        color: "",
        opacity: "",

        obverse: null,
        reverse: null,
        texture: null,
        growth: null,
        exudates: null,
        posibleThreat: false,
      },
      strainDB: null,
      isolateDB: null,
    };
  },
  props: {
    strain: {
      type: Object,
      require: false,
    },
    isolate: {
      type: Object,
      require: false,
    },
  },
  computed: {
    ...mapState("microbiologicalTest", ["microbiologicalTestSelected"]),
    ...mapState("identification", ["identificationsFounded"]),
    ...mapState("strain", [
      "moTypes",
      "shape",
      "margin",
      "elevation",
      "surface",
      "opacity",
      "color",
      "fungiColor",
      "fungiTexture",
      "growth",
    ]),
  },
  async created() {
    await this.formatDataFromDB();
    this.getImageURL();

    if (this.strainDB != null) {
      await this.formatStrainData();
      this.fillData = false;
    }

    if (this.isolateDB != null) {
      if (
        this.isolateDB.status == "INOCULATED" ||
        this.isolateDB.status == "INCUBATING" ||
        this.isolateDB.status == "COMPLETED"
      ) {
        this.disableFields = true;
      }
    }
  },
  mounted() {
    this.ro = new ResizeObserver(this.onResize);
    this.ro.observe(this.$refs.parent);
  },
  beforeDestroy() {
    this.ro.unobserve(this.$refs.parent);
  },
  methods: {
    ...mapMutations("identification", ["SET_IDENTIFICATIONS"]),
    ...mapActions("identification", ["searchIdentifications"]),
    ...mapActions("strain", [
      "addStrain",
      "updateStrain",
      "getStrainById",
      "uploadStrainPhoto",
    ]),
    ...mapActions("isolate", ["getIsolateById"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    changeIdentificationValue() {
      if (!this.search) {
        this.identification = {};
      }
    },
    async formatStrainData() {
      this.fillData = true;

      if (this.strainDB.identification) {
        this.SET_IDENTIFICATIONS([this.strainDB.identification]);
      }

      this.identification =
        this.strainDB.identification != null
          ? this.strainDB.identification
          : { name: "Sin identificar" };
      let moMorphology = {
        identification: null,
        moType: "fungi",

        shape: "",
        margin: "",
        elevation: "",
        surface: [],
        color: "",
        opacity: "",

        obverse: this.strainDB.colonies.colonyMorphology.obverse,
        reverse: this.strainDB.colonies.colonyMorphology.reverse,
        texture: this.strainDB.colonies.colonyMorphology.texture,
        growth: this.strainDB.colonies.colonyMorphology.growth,
        exudates: this.strainDB.colonies.colonyMorphology.exudates,
        posibleThreat: this.strainDB.posibleThreat,
      };

      this.colonies = this.strainDB.colonies.colonyLocation;
      this.coloniesLength = parseInt(this.strainDB.colonies.number);

      this.moMorphology = { ...moMorphology };
    },
    getPossiblesIdentifications(payload) {
      this.SET_ALERT_TYPE({
        visible: true,
        type: "success",
        text: `Posibles identificaciónes guardadas`,
        timeout: 2000,
      });
      this.menu = false;
      this.possibleIdentifications = [{ name: "Sin identificar" }];
      for (const identification of payload) {
        this.possibleIdentifications.push(identification);
      }
    },
    getImageURL() {
      let image = "";
      if (this.isolateDB == null) {
        image = this.microbiologicalTestSelected.incubation.morphologyPhoto;
        this.editImage = true;
      } else {
        image = this.isolateDB.incubation.morphologyPhoto;
        this.editImage = false;
      }

      if (typeof image != "undefined") {
        this.imageUrl = image;
      } else {
        this.imageUrl = null;
      }
    },
    onResize() {
      this.widthParent = this.$refs.parent.offsetWidth;
    },
    colonieCreated(colonies) {
      this.colonies = colonies;
      this.coloniesLength++;
    },
    colonieRemoved(colonies) {
      this.colonies = colonies;

      if (this.coloniesLength > 0) this.coloniesLength--;
    },
    async saveStrain() {
      let sampleType = this.microbiologicalTestSelected.soilSample.sampleType
        ? this.microbiologicalTestSelected.soilSample.sampleType
        : "CLASSIC";
      let sampleComposition = this.microbiologicalTestSelected.soilSample
        .composition
        ? this.microbiologicalTestSelected.soilSample.composition
        : "SOLID";
      let sampleStatus = this.microbiologicalTestSelected.soilSample
        .tracingStatus
        ? this.microbiologicalTestSelected.soilSample.tracingStatus
        : "INITIAL";

      let strainObject = {
        identificationProcess:
          this.identification == null ||
          this.identification.name == "Sin identificar"
            ? []
            : [this.identification._id],
        microscopePhotos:
          this.strainDB && this.strainDB.microscopePhotos
            ? this.strainDB.microscopePhotos
            : [],
        soilSample: this.microbiologicalTestSelected.soilSample._id,
        colonies: {
          number: this.coloniesLength != "" ? this.coloniesLength : "0",
          colonyLocation: this.getColoniesLocations(),
          colonyMorphology: {
            shape: this.moMorphology.shape,
            margin: this.moMorphology.margin,
            elevation: this.moMorphology.elevation,
            surface: this.moMorphology.surface,
            color: this.moMorphology.color,
            opacity: this.moMorphology.opacity,
            obverse: this.moMorphology.obverse,
            reverse: this.moMorphology.reverse,
            texture: this.moMorphology.texture,
            exudates: this.moMorphology.exudates == null ? false : true,
            growth: this.moMorphology.growth,
          },
        },
        concentration: this.getConcentration(
          sampleStatus,
          sampleType,
          sampleComposition,
          this.coloniesLength,
          this.microbiologicalTestSelected.inoculation.dilution
        ),
        microbiologicalTest: this.microbiologicalTestSelected._id,
        identification:
          this.identification == null ||
          this.identification.name == "Sin identificar"
            ? null
            : this.identification._id,
        posibleThreat: this.moMorphology.posibleThreat,
      };

      this.loadingSave = true;
      let img = [];
      for (let file of this.formData) {
        let imgFile = this.dataURLtoFile(file);
        let form_data = new FormData();
        form_data.append("file", imgFile);
        const { urlImg } = await this.uploadStrainPhoto(form_data);
        img.push(urlImg);
      }
      strainObject.microscopePhotos = strainObject.microscopePhotos.concat(img);
      console.log("Strain Object", strainObject);

      if (this.$refs.form.validate()) {
        if (this.strainDB == null) {
          const response = await this.addStrain(strainObject);

          if (response.error) throw response;
          else
            this.SET_ALERT_TYPE({
              visible: true,
              type: "success",
              text: `Cepa guardada correctamente`,
              timeout: 2000,
            });
          // this.strainId = response.STRAIN._id;
        } else {
          strainObject._id = this.strainDB._id;
          const response = await this.updateStrain({
            strainId: this.strainDB._id,
            strain: strainObject,
          });

          if (response.error) throw response;
          else
            this.SET_ALERT_TYPE({
              visible: true,
              type: "success",
              text: `Cepa actualizada correctamente`,
              timeout: 2000,
            });
        }
        if (this.isolateDB == null) {
          this.$emit("strainSaved");
        } else {
          this.$emit("reseedUpdated");
        }
      }

      this.loadingSave = false;
    },
    getConcentration(
      sampleStatus,
      sampleType,
      sampleComposition,
      colonies,
      dilution
    ) {
      let inverse = this.getInverse(dilution);

      if (
        (sampleType == "PRODUCT" || sampleType == "COMPOST") &&
        sampleComposition == "LIQUID"
      ) {
        return (Number.parseInt(colonies) * inverse) / 0.02;
      } else {
        if (sampleStatus == "INITIAL") {
          return (((Number.parseInt(colonies) * inverse) / 0.02) * 180) / 20;
        } else {
          return (((Number.parseInt(colonies) * inverse) / 0.02) * 150) / 50;
        }
      }
    },
    getInverse(dilution) {
      const data = [
        { dilution: "P", inverse: 0.667 },
        { dilution: "Direct", inverse: 1 },
        { dilution: "-6", inverse: 1000000 },
        { dilution: "-5", inverse: 100000 },
        { dilution: "-4", inverse: 10000 },
        { dilution: "-3", inverse: 1000 },
        { dilution: "-2", inverse: 100 },
        { dilution: "-1", inverse: 10 },
      ];
      for (let opt of data) {
        if (dilution === opt.dilution) return opt.inverse;
      }
    },
    getColoniesLocations() {
      let colonies = [];

      colonies = this.colonies.map((colonie) => ({
        ...colonie,
        label:
          this.identification && this.identification.name
            ? this.identification.name
            : "Cepa sin identificar",
      }));

      return colonies;
    },
    openPhoto() {
      this.showCaputre = true;
    },
    closePhoto(value) {
      this.showCaputre = false;
    },
    saveStrainFile(value) {
      this.showCaputre = false;
      this.formData.push(...value);
    },
    dataURLtoFile(img) {
      let arr = img.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], "morphologyPhoto.png", { type: mime });
    },
    async formatDataFromDB() {
      this.SET_IDENTIFICATIONS([]);

      if (this.isolate != null) {
        let request = await this.getStrainById(this.strain._id);
        // console.log(request.STRAIN)
        // console.log("STRAIN", this.strain);
        this.strainDB = request.STRAIN;

        let requestIsolate = await this.getIsolateById({
          id: this.isolate._id,
        });
        // console.log(requestIsolate.ISOLATE)
        // console.log("ISOLATE", this.isolate);
        this.isolateDB = requestIsolate.ISOLATE;
      } else {
        this.strainDB = this.strain;
        this.isolateDB = null;
      }
    },
  },
  watch: {
    async search(val) {
      if (!val) return;
      this.isLoading = true;
      try {
        const response = await this.searchIdentifications({
          name: val,
          moType: "fungi",
        });
        if (response.error) throw response;
      } catch (e) {
        console.log(e);
      }
      // Remove from autocomplete items if the identification is already in the table.
      this.identifications.forEach((identif, i) => {
        const index = this.identificationsFounded.findIndex(
          (i) => i._id === identif._id
        );
        if (index !== -1) {
          this.identificationsFounded.splice(index, 1);
        }
      });
      this.isLoading = false;
    },
    async isolate() {
      await this.formatDataFromDB();
      this.getImageURL();

      if (this.strainDB != null) {
        await this.formatStrainData();
        this.fillData = false;
      }

      if (this.isolateDB != null) {
        if (
          this.isolateDB.status == "INOCULATED" ||
          this.isolateDB.status == "INCUBATING" ||
          this.isolateDB.status == "COMPLETED"
        ) {
          this.disableFields = true;
        } else {
          this.disableFields = false;
        }
      }
    },
    identification() {
      if (!this.fillData) {
        if (
          this.identification != null &&
          this.identification.morphology != "undefined" &&
          this.identification.morphology != null
        ) {
          this.moMorphology.obverse = this.identification.morphology.obverse;
          this.moMorphology.reverse = this.identification.morphology.reverse;
          this.moMorphology.texture = this.identification.morphology.texture;
          this.moMorphology.growth = this.identification.morphology.growth;
          this.moMorphology.exudates = null;

          if (
            this.identification.morphology.posibleThreat != undefined &&
            this.identification.morphology.posibleThreat != null
          ) {
            this.moMorphology.posibleThreat =
              this.identification.morphology.posibleThreat;
          }

          console.log("Morphology fonunded", this.identification.morphology);
        } else {
          // this.moMorphology.obverse = null;
          // this.moMorphology.reverse = null;
          // this.moMorphology.texture = null;
          // this.moMorphology.growth = null;
          // this.moMorphology.exudates = null;
        }

        if (this.identification && this.identification.name) {
          this.colonies = this.colonies.map((colonie) => ({
            ...colonie,
            label: this.identification.name,
          }));
        }
      }
    },
    widthParent() {
      this.widthParentString = `${this.widthParent - 15}`;
    },
    coloniesLength() {
      if (this.coloniesLength === 0) {
        this.colonies = [];
      }
    },
  },
};
</script>

<style scoped>
.btn-square {
  width: 28px;
  min-width: 28px !important;
}
</style>
