<template>
  <v-card tile elevation="0" class="my-auto pb-1">
    <v-container>
      <!-- Photo row -->
      <v-row>
        <v-col cols="12" md="12">
          <v-img
            :src="require('../../assets/img/img_sample.png')"
            aspect-ratio="1.0"
            contain
          ></v-img>
        </v-col>
      </v-row>
      <template>
        <!-- Analisis data row -->
        <v-row class="px-10">
          <v-col cols="7">
            <p class="font-weight-bold text-left mb-1">Análisis {{ microbiologicalTestSelected.soilSample.folioAnalysis }}</p>
          </v-col>
          <v-col cols="5">
            <!-- <p class="font-weight-bold text-right mb-1">INICIAL MS</p> -->
          </v-col>
        </v-row>
        <!-- Test data headers row -->
        <v-row class="mt-0 pl-5">
          <v-col cols="2" class="px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              ID ensayo
            </p>
          </v-col>
          <v-col cols="2" class="px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">Medio</p>
          </v-col>
          <v-col cols="3" class="px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              ID caja madre
            </p>
          </v-col>
          <v-col cols="2" class="px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              Medio cm
            </p>
          </v-col>
          <v-col cols="3" class="px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              Dilución
            </p>
          </v-col>
        </v-row>
        <!-- Test data row -->
        <v-row class="mt-0 pl-5">
          <v-col cols="2" class="px-0">
            <p class="text-caption text-center mb-1">rg09EU</p>
          </v-col>
          <v-col cols="2" class="px-0">
            <p class="text-caption text-center mb-1">PDA</p>
          </v-col>
          <v-col cols="3" class="px-0">
            <p class="text-caption text-center mb-1">YH0nEf</p>
          </v-col>
          <v-col cols="2" class="px-0">
            <p class="text-caption text-center mb-1">PDA</p>
          </v-col>
          <v-col cols="3" class="px-0">
            <p class="text-caption text-center mb-1">-1</p>
          </v-col>
        </v-row>

        <v-container>
          <v-row>
            <v-col cols="10" class="pb-0">
              <v-text-field
                outlined
                dense
                label="Identificación"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="pt-4 pb-0">
              <v-btn
                class="btn-square"
                small
                color="secondary"
                v-ripple="{ class: 'primary--text' }"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="pt-0 mt-0" justify="center" align="center">
            <v-col cols="6" class="py-0">
              <v-select
                class="pt-0 mt-0"
                :items="items"
                label="Color anverso"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-select
                class="pt-0 mt-0"
                :items="items"
                label="Color reverso"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="pt-0 mt-0" justify="center" align="center">
            <v-col cols="6" class="py-0">
              <v-select
                class="pt-0 mt-0"
                :items="items"
                label="Aspecto"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-select
                class="pt-0 mt-0"
                :items="items"
                label="Crecimiento"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="pt-0 mt-0" justify="center" align="center">
            <v-col cols="6" class="py-0">
              <v-select
                class="pt-0 mt-0"
                :items="items"
                label="Exudados"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-select
                class="pt-0 mt-0"
                :items="items"
                label="Patógeno"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-container>
    <v-card-actions class="justify-center pt-0">
      <v-row class="pt-0" justify="center" align="center">
        <v-col cols="6">
          <v-btn depressed block> Foto microscópica </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn depressed block color="secondary"> Guardar </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    };
  },
  computed: {
    ...mapState("microbiologicalTest", ["microbiologicalTestSelected"]),
  },
};
</script>

<style scoped>
.btn-square {
  width: 28px;
  min-width: 28px !important;
}
</style>
