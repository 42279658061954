import axios from "axios";

/**
 * Function to get a nematode by sample
 * @param {Object} context - Vuex functions.
 * @param {String} sampleId - SoilSample Id.
 * @returns Object of nematode.
 */
export async function getNematodeBySample({ commit }, sampleId) {
  try {
    const { data } = await axios.get("/nematodeBySample", {
      params: {
        sampleId,
      },
    });
    if (data.error) {
      throw data;
    }
    commit("SET_NEMATODE_TEST", data.NEMATODE[0]);
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to create a new nematode test.
 * @param {Object} context - Vuex functions.
 * @param {Object} payload - Nematode test info.
 * @returns Object of nematode created.
 */
export async function createNematode({ commit }, payload) {
  try {
    const { data } = await axios.post("/nematode/add", payload);
    if (data.error) throw data;
    commit("SET_NEMATODE_TEST", data.NEMATODE);
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to update a nematode test.
 * @param {Object} context - Vuex functions.
 * @param {Object} payload - Nematode info.
 * @returns Object of nematode updated
 */
export async function updateNematode({ commit }, payload) {
  try {
    const { data } = await axios.put(
      `/nematode/update/${payload.nematodeId}`,
      payload.nematode
    );
    if (data.error) throw data;
    commit("SET_NEMATODE_TEST", data.UPDATED_NEMATODE_TEST);
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to update a nematode test.
 * @param {Object} context - Vuex functions.
 * @param {Object} payload - strain id.
 * @returns Object of nematode updated
 */
 export async function deleteNematode({ commit }, payload) {
  try {
    const { data } = await axios.post(
      `/nematode/remove/`,
      payload
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}


/**
 * Function to update a nematode test.
 * @param {Object} context - Vuex functions.
 * @param {Object} payload - sample id.
 * @returns Object of nematodes updated
 */
 export async function finishNematodes({ commit }, payload) {
  try {
    const { data } = await axios.post(
      `/nematode/finish/`,
      payload
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}


/**
 * Function to update a nematode test.
 * @param {Object} context - Vuex functions.
 * @param {Object} payload - sample id.
 * @returns Object of nematodes updated
 */
 export async function reverthNematodes({ commit }, payload) {
  try {
    const { data } = await axios.post(
      `/nematode/revert/`,
      payload
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}