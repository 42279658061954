<template>
  <div id="app">
    <!-- Alerts and loader -->
    <Alert />
    <Loader v-if="loader" />
    <router-view />
    <v-alert v-if="test" type="info" color="#FF8F1C" dark dense elevation="20">
      <p style="padding-left: 10px">Ambiente de pruebas</p>
    </v-alert>
  </div>
</template>

<script>
// Vuex
import { mapState } from "vuex";
// Components
import Loader from "@/components/Loader";
import Alert from "@/components/Alert";

export default {
  name: "App",

  data: () => ({
    test: false,
  }),

  components: {
    Loader,
    Alert,
  },

  computed: {
    ...mapState(["loader"]),
  },
};
</script>

<style scoped>
.v-alert {
  position: fixed;
  left: 50%;
  /* top: 10%; */
  bottom: 0px;
  transform: translate(-50%, -50%);
  /* margin: 0 auto; */
}
</style>