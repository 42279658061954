// Guard
import { privateGuard } from "@/middlewares/guards";

// Layouts
import NavLayout from "../layouts/NavLayout.vue";

// Views
import Projects from "../views/Projects.vue";

export default [
  {
    path: "/projects",
    beforeEnter: privateGuard,
    component: NavLayout,
    children: [
      {
        path: "",
        name: "Tabla de proyectos",
        component: Projects,
        meta: {
          title: "Proyectos",
        },
      },
    ],
  },
];
