<template>
  <v-dialog v-model="show" width="50vw" scrollable persistent>
    <v-card height="auto" elevation="5" class="rounded-lg" min-height="200">
      <v-card-title class="text-h5 lighten-2"> Confirmación </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <span v-if="title" class="text-subtitle-1">
                {{ title }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col>
              <v-btn
                color="red"
                block
                @click="closeModal"
                dark
                :disabled="isLoading"
                >Cancelar</v-btn
              >
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                block
                @click="confirmation"
                :loading="isLoading"
                >Aceptar</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//vuex
import { mapState } from "vuex";
export default {
  data: () => ({
    loader: false,
    isLoading: false,
  }),
  props: {
    value: Boolean,
    title: String,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    closeModal() {
      this.show = false;
    },

    confirmation() {
      //emit desision
      this.$emit("confirmation");
      this.closeModal();
    },
  },
};
</script>
