<template>
  <v-dialog v-model="dialog" max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        depressed
        dark
        color="gray"
        :class="$vuetify.breakpoint.mdAndUp ? 'mr-5' : ''"
      >
        Medio contaminado
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-center headline">
        <v-row>
          <v-col> Medio de cultivo contaminado </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <!-- if is not multiple selection -->
        <!-- Form -->
        <v-form ref="form" v-model="valid" v-if="!multiple" lazy-validation>
          <v-autocomplete
            v-model="testMediaSelected"
            :search-input.sync="search"
            :items="testMediaList"
            :loading="loadingTestMedia"
            autocomplete="off"
            item-text="_id"
            return-object
            no-data-text="No hay medios de cultivo"
            placeholder="Id de caja petri"
            :rules="testMediaRules"
          ></v-autocomplete>
          <!-- Selected test media -->
          <v-expand-transition>
            <div v-if="testMediaSelected" class="black--text">
              <p>
                Caja petri: <span>{{ getPetriBoxType }}</span>
              </p>
              <p>
                Medio de cultivo:
                <span>{{ testMediaSelected.cultureMedia.nutrientAgar }}</span>
              </p>
              <p>
                Microorganismo: <span>{{ getMoType }}</span>
              </p>
              <p>
                Estatus: <span>{{ getTestMediaStatus }}</span>
              </p>
            </div>
          </v-expand-transition>
        </v-form>
        <!-- if is multiple selection -->
        <v-form ref="form" v-model="valid" v-else lazy-validation>
          <v-row
            v-for="(item, i) in selectedItems"
            :key="i"
            justify="center"
            align="center"
            class="black--text mt-0 mx-2"
          >
            <v-col>
              <p>
                Caja petri: <span>{{ item.idTestMedia }}</span>
              </p>
              <p>
                Medio de cultivo:
                <span>{{ item.cultureMedia }}</span>
              </p>
              <p>
                Estatus: <span>{{ item.testStatus }}</span>
              </p>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <!-- Actions -->
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey darken-1" depressed dark @click="dialog = false">
          Cerrar
        </v-btn>
        <v-btn
          color="primary"
          :loading="loading"
          depressed
          @click="updateStatus"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
// Validations
import { requiredValidation } from "@/helpers/validations";
import axios from "axios";

export default {
  name: "CultureMediaContaminated",
  props: {
    selectedItems: { type: Array },
  },
  data() {
    return {
      dialog: false,
      valid: true,
      loading: false,
      // if is multiple selection
      multiple: false,
      // Autocomplete.
      search: "",
      loadingTestMedia: false,
      testMediaSelected: null,
      // Rules:
      testMediaRules: requiredValidation("Id de caja petri"),
      // user data
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  watch: {
    // search an individual test media
    async search(value) {
      this.loadingTestMedia = true;

      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.SET_CANCEL_TOKEN(axios.CancelToken.source());

      await this.searchMedia();
      this.loadingTestMedia = false;
    },
    dialog(value) {
      // Reset form when opens or closes
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.multiple = false;
      }
      // verify if there are selected items
      if (value) {
        if (this.selectedItems && this.selectedItems.length > 0) {
          this.multiple = true;
        }
      }
    },
  },
  computed: {
    ...mapState("testMedia", ["testMediaList"]),
    ...mapState(["cancelToken"]),

    getPetriBoxType() {
      return this.testMediaSelected.containerType === "petriDish90mmx15mm"
        ? "90mmx15mm"
        : "60mmx15mm";
    },
    getTestMediaStatus() {
      return this.testMediaSelected.status === "UNREGISTERED"
        ? "Sin registrar"
        : "Usada";
    },
    getMoType() {
      let types = {
        all: "Todos",
        bacteria: "Bacteria",
        fungi: "Hongo",
        nematode: "Nemátodo",
      };
      return types[this.testMediaSelected.cultureMedia.moType];
    },
  },
  methods: {
    ...mapMutations(["SET_CANCEL_TOKEN"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("testMedia", ["searchTestMedia", "updateTestMedia"]),
    ...mapActions("microbiologicalTest", ["updateMBTest"]),

    async searchMedia() {
      try {
        const response = await this.searchTestMedia({
          _id: this.search,
          laboratory: this.user.laboratory._id,
          status: ["UNREGISTERED"],
        });
        if (response.error) throw response;
      } catch (e) {
        console.log("Error searching test media", e);
      }
    },

    async updateStatus() {
      try {
        if (this.$refs.form.validate()) {
          this.loading = true;
          // if is multiple selection, update test media and mbtest
          if (this.multiple) {
            for (let selected of this.selectedItems) {
              let filters = {
                _id: selected.id,
                status: "CONTAMINATED",
                laboratory: this.user.laboratory._id,
                statusMBTest: ["INOCULATED", "CONTAMINATED"],
                user : this.user._id
              };

              const response2 = await this.updateTestMedia({
                id: selected.idTestMedia,
                laboratory: this.user.laboratory._id,
              });
              if (response2.error) throw response2;

              const response = await this.updateMBTest(filters);
              if (response.error) throw response;
            }
          } else {
            // Not multiple
            const response = await this.updateTestMedia({
              id: this.testMediaSelected._id,
              laboratory: this.user.laboratory._id,
            });
            if (response.error) throw response;
          }
          this.loading = false;

          this.dialog = false;
          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: `Medio de cultivo actualizado correctamente`,
            timeout: 4000,
          });
        }
      } catch (error) {
        console.log("error updateStatus", error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Ha ocurrido un error, inténtalo de nuevo",
          timeout: 4000,
        });
      }
    },
  },
};
</script>

<style lang="css" scoped>
p {
  margin-bottom: 8px;
}
p > span {
  font-weight: bold;
}
</style>
