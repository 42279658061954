import axios from "axios";

/**
 * Function to add new molecular plate
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - Molecular plate data object
 * @returns {data} -  Returns array of molecular plates
 */
export async function addNewPlate({ commit }, payload) {
  try {
    const { data } = await axios.post("/molecularPlate/new-plate", payload);
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get plates by samples & plate type
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - Filters
 * @returns {data} -  Returns object with plates
 */
export async function getPlatesStats({ commit, rootState }, payload) {
  try {
    const { data } = await axios.get("/molecularWellPlate/get-plate-data", {
      params: payload,
    });
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get amplification plates by analysis samples
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - samples array
 * @returns {data} -  Returns array with plates
 */
 export async function getAmplificationPlatesBySamples({ commit, rootState }, payload) {
  try {
    const { data } = await axios.get("/molecularWellPlate/amplification/plates-by-samples", {
      params : payload
    });
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get plate info to generate csv
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - { id } plate id
 * @returns {data} -  Returns plate object with plates & sample data
 */
 export async function getPlateWellSampleData({ commit, rootState }, payload) {
  try {
    const { data } = await axios.get("/molecularWellPlate/wells-by-plate", {
      params : {
        id : payload
      } 
    });
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get plate wells by plate & folio
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - Plate folio & reaction
 * @returns {data} -  Returns array of plates info
 */
 export async function getWellsByFolioAndReaction({ commit, rootState }, payload) {
  try {
    const { data } = await axios.get("/molecularWellPlate/wells-by-plateFolio-reaction", {
      params: payload,
    });
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get plate stage status
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - Analysis id & plate type
 * @returns {data} -  Returns array of plates info
 */
 export async function getStageStatus({ commit }, payload) {
  try {
    const { data } = await axios.get("/molecularWellPlate/getStageStatus", {
      params: payload,
    });
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to put samples on wells
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - Analysis id & plate type
 * @returns {data} -  Returns array of plates info
 */
export async function putSamplesOnPlate({ commit }, payload) {
  try {
    const { data } = await axios.put("/molecularWellPlate/set-wells", payload);
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}