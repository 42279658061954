<template>
  <v-app>
    <!-- Top nav -->
    <v-app-bar app clipped-right fixed flat color="white">
      <v-toolbar-title>
        <router-link to="/reception/1/reception/">
          <v-img src="@/assets/img/logo-solena-black.png" width="150" />
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title
        class="px-2"
        v-if="
          minutesToExpire > 0 &&
          minutesToExpire <= 5 &&
          daysToExpire == 0 &&
          hoursToExpire == 0
        "
      >
        <v-alert icon="mdi-alert" dense outlined type="warning" class="mb-0">
          <!-- <h5><strong>La sesión está a punto de expirar</strong></h5> -->
          <h5 v-if="minutesToExpire > 1">
            La sesión expira en: <strong>{{ minutesToExpire }} minutos</strong>
          </h5>
          <h5 v-else>
            La sesión expira en: <strong>{{ minutesToExpire }} minuto</strong>
          </h5>
        </v-alert>
      </v-toolbar-title>
      <v-toolbar-title
        class="px-2"
        v-else-if="
          minutesToExpire <= 0 && daysToExpire <= 0 && hoursToExpire <= 0
        "
      >
        <v-alert icon="mdi-alert" dense outlined type="warning" class="mb-0">
          <!-- <h5><strong>La sesión está a punto de expirar</strong></h5> -->
          <h5><strong>La sesión ha expirado</strong></h5>
        </v-alert>
      </v-toolbar-title>

      <!-- <v-list-item two-line >
            <v-list-item-avatar color="primary">
                <span class="white--text headline">{{
              user.name ? user.name.charAt(0) : ""
            }}</span>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>{{ user.name }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item> -->
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
    </v-app-bar>

    <!-- Left nav -->
    <v-navigation-drawer app v-model="drawer" temporary clipped right>
      <v-list nav class="ma-3">
        <!-- User info -->
        <v-list-item two-line>
          <v-list-item-avatar color="primary">
            <span class="white--text headline">{{
              user.name ? user.name.charAt(0) : ""
            }}</span>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ user.name }}</v-list-item-title>
            <v-list-item-subtitle v-if="user.laboratory">{{
              user.laboratory.name
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="mb-5" />

        <v-list-item-group color="primary">
          <!-- Nav items -->
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            :to="item.to"
            active-class="highlighted"
            :class="item.path === $route.path ? 'highlighted' : ''"
          >
            <v-list-item-icon>
              <v-icon color="secondary">{{ item.icon }}</v-icon>
              <!-- <img
              :src="require('../assets/img/' + item.icon)"
              width="30"
              height="25"
            /> -->
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <!-- Logout -->
      <template v-slot:append>
        <v-btn block color="primary" @click="logOut"> Cerrar Sesión </v-btn>
      </template>
    </v-navigation-drawer>

    <!-- Content -->
    <v-main class="main_app">
      <v-container
        fill-height
        fluid
        :class="{
          'large-devices': $vuetify.breakpoint.xlOnly,
        }"
      >
        <router-view></router-view>
        <SessionDialog :dialog="dialog" />
      </v-container>
    </v-main>

    <v-dialog
      transition="dialog-top-transition"
      max-width="400"
      v-model="dialog_unauthorized"
      persistent
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="warning" dark>La sesión caducó</v-toolbar>
          <v-card-text class="py-0">
            <div class="text-body-4 py-6">
              Puedes volver iniciar sesión o recargar la página si es que ya lo
              hiciste.
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="$router.go()">Recargar</v-btn>
            <v-btn text @click="logOut()">Volver a iniciar sesión</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <!-- Footer -->
    <v-footer padless>
      <v-col class="text-center" cols="12">
        D.R. © Solena Ag, Inc. Av. Olímpica 3020-D Villas de San Juan 37295,
        León Gto, México.
        {{ new Date().getFullYear() }}
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import SessionDialog from "@/components/utils/SessionDialog.vue";

export default {
  data() {
    return {
      drawer: false,
      title: "Solena Lab",
      user: {},
      // items: [
      //   {
      //     icon: "mdi-truck-delivery",
      //     text: "Recepción / Nemátodos",
      //     to: "/reception/1/reception/",
      //   },
      //   {
      //     icon: "mdi-circle-edit-outline",
      //     text: "Medios e inoculación",
      //     to: "/media-inoculation",
      //   },
      //   {
      //     icon: "mdi-microscope",
      //     text: "Medición",
      //     to: "/tests/microbiological/1",
      //   },
      //   {
      //     icon: "mdi-bacteria",
      //     text: "Valuación",
      //     to: "/tests/biochemical/1",
      //   },
      //   {
      //     icon: "mdi-dna",
      //     text: "Resultados QPCR",
      //     to: "/tests/qpcr/1",
      //   },
      //   {
      //     icon: "mdi-currency-usd",
      //     text: "Rentabilización",
      //     to: "/profit/1",
      //   },
      //   {
      //     icon: "mdi-dna",
      //     text: "Secuenciación",
      //     to: "/sequencing-reports",
      //   },
      // ],
      items: [],
      authItems: [
        {
          icon: "mdi-logout",
          text: "Cerrar sesión",
          action: this.logOut,
        },
      ],
      dialog: false,
      actuallyDate: new Date(),
      expirationDate: new Date(localStorage.getItem("token_expiry")),
      daysToExpire: 0,
      hoursToExpire: 0,
      minutesToExpire: 10,
      dialog_unauthorized: false,
    };
  },
  async mounted() {
    this.getUserInfo();
    await this.getExpirationTime();
    await this.setNewUserInfo();
    window.setInterval(() => {
      this.actuallyDate = new Date();
      // }, (1000 * 60) * 5 )
    }, 1000 * 60);

    this.generateMenuOptions(this.permissions);
  },
  async beforeUpdate() {
    let now = new Date();
    const token_expiry = new Date(localStorage.getItem("token_expiry"));

    if (token_expiry < now) {
      // try {
      //     await this.getNewToken(refreshToken);
      // }
      // catch (error) {
      //     console.log("Error getting new token", error);
      // }

      this.dialog = true;
    }
  },
  methods: {
    ...mapActions("session", ["logout", "getNewToken", "updateInfo"]),
    ...mapMutations("session", ["SET_AUTHENTICATED_USER"]),
    // ...mapMutations("login", ["SET_ERROR"]),
    async logOut() {
      await this.logout({
        refreshToken: localStorage.getItem("refresh_token"),
        session: localStorage.getItem("session"),
      });
      this.$router.push("/login");
    },
    getUserInfo() {
      this.user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : "";
    },
    async getExpirationTime() {
      var diffMs = this.expirationDate - this.actuallyDate; // milliseconds between now & Christmas
      var diffDays = Math.floor(diffMs / 86400000); // days
      var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
      var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

      if (
        this.daysToExpire != diffDays ||
        this.hoursToExpire != diffHrs ||
        this.minutesToExpire != diffMins
      ) {
        this.daysToExpire = diffDays;
        this.hoursToExpire = diffHrs;
        this.minutesToExpire = diffMins;
      }

      // console.log(diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes until token expires");
    },
    async setNewUserInfo() {
      if (this.user) {
        try {
          let response = await this.updateInfo({ userId: this.user._id });
          if (response) {
            this.user = response.user;
            this.SET_AUTHENTICATED_USER(response.user);

            if (this.authenticated == null) {
              await this.logOut();
            } else if (this.authenticated === true) {
              this.dialog_unauthorized = false;
            }
          }
        } catch (error) {
          console.log(error);

          if (this.authenticated === false) {
            this.dialog_unauthorized = true;
          }
          console.log("Error", this.authenticated);
        }
      }
    },
    generateMenuOptions(permissions) {
      let menuOptions = [];

      if (
        permissions.find((permission) => permission == "lab_reception_read")
      ) {
        menuOptions.push({
          icon: "mdi-truck-delivery",
          text: "Recepción / Nemátodos",
          to: "/reception/1/reception/",
        });
      }

      if (
        permissions.find(
          (permission) =>
            permission == "lab_qpcr_plates" || permission == "lab_medias"
        )
      ) {
        const text = permissions.find(
          (permission) => permission == "lab_qpcr_plates"
        )
          ? "Placas"
          : "Medios e inoculación";
        menuOptions.push({
          to: "/media-inoculation",
          icon:
            text == "Placas" ? "mdi-table-large" : "mdi-circle-edit-outline",
          text,
        });
      }

      if (
        permissions.find((permission) => permission == "lab_measuring_results")
      ) {
        menuOptions.push({
          icon: "mdi-microscope",
          text: "Medición",
          to: "/tests/microbiological/1",
        });
      }

      if (
        permissions.find((permission) => permission == "lab_valuation_results")
      ) {
        menuOptions.push({
          icon: "mdi-bacteria",
          text: "Valuación",
          to: "/tests/biochemical/1",
        });
      }

      if (
        permissions.find((permission) => permission == "lab_preserved_results")
      ) {
        menuOptions.push({
          icon: "mdi-truck-snowflake",
          text: "Preservación",
          to: "/tests/preserved/1",
        });
      }

      if (permissions.find((permission) => permission == "lab_qpcr_results")) {
        menuOptions.push({
          icon: "mdi-dna",
          text: "Resultados QPCR",
          to: "/tests/molecular/1",
        });
      }

      if (permissions.find((permission) => permission == "lab_profit_read")) {
        menuOptions.push({
          icon: "mdi-currency-usd",
          text: "Rentabilización",
          to: "/profit/1",
        });
      }

      if (permissions.find((permission) => permission == "lab_seq_read")) {
        menuOptions.push({
          icon: "mdi-file-sign",
          text: "Secuenciación",
          to: "/sequencing-reports",
        });
      }

      if (permissions.find((permission) => permission == "lab_cmda_admin")) {
        menuOptions.push({
          icon: "mdi-list-status",
          text: "Admin CMDA",
          to: "/cmda-admin",
        });
      }
      if (
        permissions.find((permission) => permission == "lab_ceparium_admin")
      ) {
        menuOptions.push({
          icon: "mdi-test-tube",
          text: "Cepario",
          to: "/ceparium",
        });
      }
      if (
        permissions.find((permission) => permission == "lab_labels")
      ) {
        menuOptions.push({
          icon: "mdi-tag-text",
          text: "Etiquetas",
          to: "/labels",
        });
      }

      if (
        permissions.find((permission) => permission == "lab_projects")
      ) {
        menuOptions.push({
          icon: "mdi-home-silo",
          text: "Proyectos",
          to: "/projects",
        });
      }

      // console.log(menuOptions)
      this.items = menuOptions;
    },
  },
  watch: {
    async actuallyDate() {
      // console.log(this.actuallyDate)
      await this.getExpirationTime();
      await this.setNewUserInfo();
    },
  },
  components: { SessionDialog },
  computed: {
    ...mapState("session", ["permissions", "authenticated"]),
    show_auth_dialog() {
      return !this.authenticated;
    },
  },
};
</script>

<style scoped>
.main_app {
  background-color: #efefef;
}

.v-responsive {
  max-width: 97%;
}

.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0 !important;
}
</style>
