<template>
  <v-container fluid fill-height class="px-0">
    <v-row>
      <transition
        v-if="
          $vuetify.breakpoint.sm ||
          $vuetify.breakpoint.md ||
          $vuetify.breakpoint.lg ||
          $vuetify.breakpoint.xl
        "
        name="component-fade"
        mode="out-in"
      >
        <v-col
          v-if="selected == null"
          cols="12"
          xl="3"
          lg="4"
          md="4"
          sm="4"
          style="flex-direction: column"
        >
          <v-skeleton-loader
            type="image, image, article, list-item, list-item, list-item, actions"
            min-height="800"
          ></v-skeleton-loader>
        </v-col>

        <v-col
          v-else
          cols="12"
          xl="3"
          lg="4"
          md="4"
          sm="4"
          style="flex-direction: column"
        >
          <transition name="component-fade" mode="out-in">
            <test-data
              v-if="
                showDataTest &&
                selected != null &&
                selected.mbTestData != undefined
              "
              @continueClicked="showMbTestStrains"
              :selected="selected.mbTestData"
            />
            <test-data-template
              v-else-if="showAddDataTest"
              @addStrainClicked="preAddStrain"
              @updateStrainClicked="preUpdateStrain"
              @deleteStrainClicked="preDeleteStrain"
              @reseedSaved="reloadDataTable"
              @returnView="showSampleData"
            />
            <test-add-fungi
              v-else-if="showFungiSelected"
              :strain="strainSelected"
              :isolate="isolate"
              @strainSaved="showMbTestStrains()"
              @reseedUpdated="reloadDataTable"
            />
            <test-add-bacterias
              v-else-if="showBacteriaSelected"
              :strain="strainSelected"
              :isolate="isolate"
              @strainSaved="showMbTestStrains()"
              @reseedUpdated="reloadDataTable"
            />
            <TestAddMo
              v-else-if="showAllSelected"
              :strain="strainSelected"
              :isolate="isolate"
              @strainSaved="showMbTestStrains()"
              @reseedUpdated="reloadDataTable"
            />
            <!-- <test-add-reseeding v-else-if="showReseedingSelected" /> -->
            <test-control
              v-else-if="showControlTest"
              :control="selected"
              @controlUpdated="reloadDataTable"
            />
            <test-reseed
              v-else-if="showReseedTest"
              :reseed="selected"
              @reseedUpdated="reloadDataTable"
            />
            <test-antagonism
              :test="selected"
              v-else-if="showAntagonismTest"
              @testUpdated="reloadDataTable"
            />
            <test-nutrition
              :testNutrition="selected"
              v-else-if="showNutritionTest"
              @testsSaved="reloadDataTable"
            />
            <test-bio-stimulation
              v-else-if="showBioEstimulationTest"
              :testBiostimulation="selected"
              @testsSaved="reloadDataTable"
            />

            <MolecularIdentification
              :molecularIdentification="selected"
              v-else-if="showmolecularIdentification"
            />
          </transition>
        </v-col>
      </transition>
      <v-col cols="12" xl="9" lg="8" md="8" sm="8" class="flex-grow-1">
        <v-card
          tile
          elevation="0"
          class="my-auto"
          height="100%"
          min-height="800"
        >
          <v-container class="pa-5" fluid>
            <!-- Actions row -->
            <v-row justify="center" align="center">
              <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                <p class="text-h6 text-center mb-0 font-weight-black">
                  Ensayos
                </p>
                <v-row class="px-10" align="center" justify="center">
                  <v-col cols="10" md="9" v-if="$vuetify.breakpoint.xs">
                    <v-form @submit.prevent="searchTest">
                      <v-text-field
                        class="centered-input"
                        v-model="search"
                        label="Búsqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-form>
                  </v-col>
                  <v-col cols="10" md="9" v-else>
                    <v-form @submit.prevent="searchTest">
                      <v-text-field
                        class="centered-input"
                        append-icon="mdi-magnify"
                        v-model="search"
                        label="Búsqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-form>
                  </v-col>
                  <v-col cols="2" class="pt-6" v-if="$vuetify.breakpoint.xs">
                    <v-btn icon color="primary" small @click="searchTest">
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="3"
                    md="1"
                    class="pointer"
                    @click="reloadDataTable"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          class="pt-0"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-table-sync
                        </v-icon>
                      </template>
                      <span>Recargar tabla</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="3" md="2" v-if="!permissions.find((per) => per == 'lab_qpcr_results')">
                    <filters @filtersUpdated="setFilters" />
                  </v-col>
                </v-row>
                <v-row
                  class="px-0"
                  align="center"
                  justify="center"
                  v-if="permissions.find((per) => per == 'lab_qpcr_results') && !permissions.find((per) => per == 'lab_qpcr_unedit')"
                >
                  <v-col cols="8" xl="6" lg="6" md="6" sm="8" xs="8">
                    <molecular-identification-modal
                      @molecularIdentificationsAdded="identificationsAdded"
                    />
                  </v-col>
                  <!-- <v-col cols="3" xl="2" lg="2" md="2" sm="3" xs="3">
                    <molecular-reports-modal/>
                  </v-col> -->
                </v-row>
              </v-col>
              <!-- <v-col
                cols="12"
                xl="6"
                lg="6"
                md="6"
                sm="6"
                v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
              >
                <v-row>
                  <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                    <v-btn
                      small
                      depressed
                      block
                      @click="contaminateTests"
                      v-if="
                        permissions.find(
                          (per) =>
                            per == 'lab_measuring_results' ||
                            per == 'lab_valuation_results'
                        )
                      "
                    >
                      Contaminado
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    xl="6"
                    lg="6"
                    md="6"
                    sm="6"
                    v-if="
                      permissions.find((per) => per == 'lab_valuation_results')
                    "
                  >
                    <v-menu
                      v-model="menuValuations"
                      bottom
                      :offset-x="false"
                      :offset-y="true"
                      transition="slide-y-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          small
                          depressed
                          block
                          color="primary"
                        >
                          Valuación
                        </v-btn>
                      </template>

                      <v-list dense>
                        <v-list-item>
                          <antagonism-modal
                            @newTests="reloadDataTable"
                          ></antagonism-modal>
                        </v-list-item>
                        <v-list-item>
                          <nutrition-modal
                            @newTests="reloadDataTable"
                          ></nutrition-modal>
                        </v-list-item>
                        <v-list-item>
                          <bio-stimulation-modal
                            @newTests="reloadDataTable"
                          ></bio-stimulation-modal>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    xl="6"
                    lg="6"
                    md="6"
                    sm="6"
                    v-if="
                      permissions.find(
                        (per) =>
                          per == 'lab_measuring_results' ||
                          per == 'lab_valuation_results'
                      )
                    "
                  >
                    <revert-tests
                      :tests="checkBoxesSelected"
                      @testReverted="reloadDataTable"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    xl="6"
                    lg="6"
                    md="6"
                    sm="6"
                    v-if="
                      permissions.find(
                        (per) =>
                          per == 'lab_measuring_results' ||
                          per == 'lab_valuation_results'
                      )
                    "
                  >
                    <end-tests-mb
                      :tests="checkBoxesSelected"
                      @testSaved="reloadDataTable"
                    />
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col cols="6" xl="6" lg="6" md="6" sm="6">
                    <delete-test
                      :tests="checkBoxesSelected"
                      @testsDeleted="reloadDataTable"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    xl="6"
                    lg="6"
                    md="6"
                    sm="6"
                    v-if="
                      permissions.find(
                        (per) =>
                          per == 'lab_measuring_results' ||
                          per == 'lab_valuation_results'
                      )
                    "
                  >
                    <incubate-test
                      :tests="checkBoxesSelected"
                      @testsSaved="reloadDataTable"
                    />
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col
                    cols="12"
                    xl="6"
                    lg="6"
                    md="6"
                    sm="6"
                    v-if="
                      permissions.find(
                        (per) =>
                          per == 'lab_measuring_results' ||
                          per == 'lab_valuation_results'
                      )
                    "
                  >
                    <interrupt-incubation-modal
                      :tests="checkBoxesSelected"
                      @updateTests="reloadDataTable"
                    />
                  </v-col>
                </v-row>
              </v-col> -->
              <v-col
                cols="12"
                xl="6"
                lg="6"
                md="6"
                sm="6"
                v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
              >
                <v-row>
                  <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                    <v-btn
                      small
                      depressed
                      block
                      @click="contaminateTests"
                      v-if="
                        permissions.find(
                          (per) =>
                            per == 'lab_measuring_results' ||
                            per == 'lab_valuation_results'
                        )
                      "
                    >
                      Ensayo contaminado
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    xl="6"
                    lg="6"
                    md="6"
                    sm="6"
                    v-if="
                      permissions.find((per) => per == 'lab_valuation_results')
                    "
                  >
                    <v-menu
                      v-model="menuValuations"
                      bottom
                      :offset-x="false"
                      :offset-y="true"
                      transition="slide-y-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          small
                          depressed
                          block
                          color="primary"
                        >
                          Nueva valuación
                        </v-btn>
                      </template>

                      <v-list dense>
                        <v-list-item>
                          <antagonism-modal
                            @newTests="reloadDataTable"
                          ></antagonism-modal>
                        </v-list-item>
                        <v-list-item>
                          <nutrition-modal
                            @newTests="reloadDataTable"
                          ></nutrition-modal>
                        </v-list-item>
                        <v-list-item>
                          <bio-stimulation-modal
                            @newTests="reloadDataTable"
                          ></bio-stimulation-modal>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    xl="6"
                    lg="6"
                    md="6"
                    sm="6"
                    v-if="
                      permissions.find(
                        (per) =>
                          per == 'lab_measuring_results' ||
                          per == 'lab_valuation_results'
                      )
                    "
                  >
                    <revert-tests
                      :tests="checkBoxesSelected"
                      @testReverted="reloadDataTable"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    xl="6"
                    lg="6"
                    md="6"
                    sm="6"
                    v-if="
                      permissions.find(
                        (per) =>
                          per == 'lab_measuring_results' ||
                          per == 'lab_valuation_results'
                      )
                    "
                  >
                    <end-tests-mb
                      :tests="checkBoxesSelected"
                      @testSaved="reloadDataTable"
                    />
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col cols="6" xl="6" lg="6" md="6" sm="6" v-if="!permissions.find((per) => per == 'lab_qpcr_unedit')">
                    <delete-test
                      :tests="checkBoxesSelected"
                      @testsDeleted="reloadDataTable"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    xl="6"
                    lg="6"
                    md="6"
                    sm="6"
                    v-if="
                      permissions.find(
                        (per) =>
                          per == 'lab_measuring_results' ||
                          per == 'lab_valuation_results'
                      )
                    "
                  >
                    <incubate-test
                      :tests="checkBoxesSelected"
                      @testsSaved="reloadDataTable"
                    />
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col
                    cols="12"
                    xl="6"
                    lg="6"
                    md="6"
                    sm="6"
                    v-if="
                      permissions.find(
                        (per) =>
                          per == 'lab_measuring_results' ||
                          per == 'lab_valuation_results'
                      )
                    "
                  >
                    <interrupt-incubation-modal
                      :tests="checkBoxesSelected"
                      @updateTests="reloadDataTable"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <v-tabs
                v-model="activeTab"
                centered
                slider-color="secondary"
                grow
              >
                <v-tab
                  href="#microbiological"
                  v-if="
                    permissions.find((per) => per == 'lab_measuring_results')
                  "
                >
                  Medición 🔬</v-tab
                >
                <v-tab
                  href="#biochemical"
                  v-if="
                    permissions.find((per) => per == 'lab_valuation_results')
                  "
                >
                  Valuación 🧫</v-tab
                >
                <v-tab
                  href="#molecular"
                  v-if="permissions.find((per) => per == 'lab_qpcr_results')"
                >
                  ID. Molecular 🧬</v-tab
                >
                <v-tab
                  href="#preserved"
                  v-if="
                    permissions.find((per) => per == 'lab_preserved_results')
                  "
                >
                  Preservación 🧪</v-tab
                >
              </v-tabs>
            </v-row>
            <v-row align="center" justify="center">
              <v-container>
                <v-tabs-items v-model="activeTab" continuous>
                  <v-tab-item
                    value="microbiological"
                    v-if="
                      permissions.find((per) => per == 'lab_measuring_results')
                    "
                  >
                    <microbiological-tests-table
                      :active="activeMbTab"
                      :search="searchMb"
                      :reload="reloadDataTableMb"
                      @testSelected="testClicked"
                      @testChecked="testSelected"
                    />
                  </v-tab-item>
                  <v-tab-item
                    value="biochemical"
                    v-if="
                      permissions.find((per) => per == 'lab_valuation_results')
                    "
                  >
                    <biochemical-tests-table
                      :active="activeMbTab"
                      :search="searchBc"
                      :reload="reloadDataTableBc"
                      @testSelected="testClicked"
                      @testChecked="testSelected"
                    />
                  </v-tab-item>
                  <v-tab-item
                    value="molecular"
                    v-if="permissions.find((per) => per == 'lab_qpcr_results')"
                  >
                    <molecular-tests-table
                      :identificationAdded="molecularIdAdded"
                      :reload="reloadDataTableMo"
                      :search="searchMo"
                      @testSelected="testClicked"
                      @testChecked="testSelected"
                    />
                  </v-tab-item>
                  <v-tab-item
                    value="preserved"
                    v-if="
                      permissions.find((per) => per == 'lab_preserved_results')
                    "
                  >
                    <preserved-tests-table
                      :active="activePvTab"
                      :search="searchPv"
                      :reload="reloadDataTablePreserved"
                      @testSelected="testClicked"
                      @testChecked="testSelected"
                    />
                  </v-tab-item>
                </v-tabs-items>
              </v-container>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogTest"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }"> </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialogTest = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Detalle del ensayo</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-0">
          <test-data
            v-if="
              showDataTest &&
              selected != null &&
              selected.mbTestData != undefined
            "
            @continueClicked="showMbTestStrains"
            :selected="selected.mbTestData"
          />
          <test-data-template
            v-else-if="showAddDataTest"
            @addStrainClicked="preAddStrain"
            @updateStrainClicked="preUpdateStrain"
            @deleteStrainClicked="preDeleteStrain"
            @reseedSaved="reloadDataTable"
            @returnView="showSampleData"
          />
          <test-add-fungi
            v-else-if="showFungiSelected"
            :strain="strainSelected"
            :isolate="isolate"
            @strainSaved="showMbTestStrains()"
            @reseedUpdated="reloadDataTable"
          />
          <test-add-bacterias
            v-else-if="showBacteriaSelected"
            :strain="strainSelected"
            :isolate="isolate"
            @strainSaved="showMbTestStrains()"
            @reseedUpdated="reloadDataTable"
          />
          <TestAddMo
            v-else-if="showAllSelected"
            :strain="strainSelected"
            :isolate="isolate"
            @strainSaved="showMbTestStrains()"
            @reseedUpdated="reloadDataTable"
          />
          <test-reseed
            v-else-if="showReseedTest"
            :reseed="selected"
            @reseedUpdated="reloadDataTable"
          />
          <!-- <test-add-reseeding v-else-if="showReseedingSelected" /> -->
          <test-control
            v-else-if="showControlTest"
            :control="selected"
            @controlUpdated="reloadDataTable"
          />
          <test-antagonism
            :test="selected"
            v-else-if="showAntagonismTest"
            @testUpdated="reloadDataTable"
          />
          <test-nutrition
            :testNutrition="selected"
            v-else-if="showNutritionTest"
            @testsSaved="reloadDataTable"
          />
          <test-bio-stimulation
            v-else-if="showBioEstimulationTest"
            :testBiostimulation="selected"
            @testsSaved="reloadDataTable"
          />

          <MolecularIdentification
            :molecularIdentification="selected"
            v-else-if="showmolecularIdentification"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import TestData from "@/components/station3/testData";
import TestDataTemplate from "@/components/station3/testIdentifications";
import TestAddFungi from "@/components/station3/testAddFungi";
import TestAddBacterias from "@/components/station3/testAddBacterias";
import TestAddMo from "@/components/station3/testAddMo.vue";
import TestAddReseeding from "@/components/station3/testAddReseeding";
import AntagonismModal from "@/components/station3/modals/BQTests/antagonismModal.vue";
import NutritionModal from "@/components/station3/modals/BQTests/nutritionModal.vue";
import BioStimulationModal from "@/components/station3/modals/BQTests/bioStimulationModal.vue";
import MolecularIdentification from "../components/station3/testMolecularIdentification.vue";
import TestAntagonism from "../components/station3/testAntagonism.vue";
import TestControl from "../components/station3/testControl.vue";
import TestReseed from "../components/station3/testReseed.vue";
import TestNutrition from "../components/station3/testNutrition.vue";
import TestBioStimulation from "../components/station3/testBioStimulation.vue";
import IncubateTest from "../components/station3/modals/incubateTest.vue";
import DeleteTest from "../components/station3/modals/deleteTest.vue";
import EndTestsMb from "../components/station3/modals/endTestsMB.vue";
import RevertTests from "../components/station3/modals/revertTests.vue";

import MolecularIdentificationModal from "../components/station3/modals/MolTests/MolecularIdentificationModal.vue";

import Filters from "../components/station3/modals/filters.vue";

import MicrobiologicalTestsTable from "../components/station3/tables/MicrobiologicalTestsTable.vue";
import BiochemicalTestsTable from "../components/station3/tables/BiochemicalTestsTable.vue";
import MolecularTestsTable from "../components/station3/tables/MolecularTestsTable.vue";
import PreservedTestsTable from "../components/station3/tables/PreservedTestsTable.vue";
import InterruptIncubationModal from "../components/station3/modals/MBTests/interruptIncubationModal.vue";
import MolecularReportsModal from "../components/station3/modals/MolTests/MolecularReportsModal.vue";

export default {
  components: {
    TestAddFungi,
    TestData,
    TestDataTemplate,
    TestAddBacterias,
    TestAddReseeding,
    IncubateTest,
    AntagonismModal,
    NutritionModal,
    BioStimulationModal,
    TestAntagonism,
    TestControl,
    TestReseed,
    TestNutrition,
    TestBioStimulation,
    EndTestsMb,
    DeleteTest,
    RevertTests,
    MicrobiologicalTestsTable,
    BiochemicalTestsTable,
    MolecularTestsTable,
    PreservedTestsTable,
    Filters,
    MolecularIdentification,
    MolecularIdentificationModal,
    InterruptIncubationModal,
    MolecularReportsModal,
    TestAddMo,
  },
  data() {
    return {
      activeTab: this.$route.params.testType,
      activeMbTab: false,
      activeBcTab: false,
      activeMoTab: false,
      activePvTab: false,
      reloadDataTableMb: false,
      reloadDataTableBc: false,
      reloadDataTablePreserved: false,
      reloadDataTableMo: false,
      loading: false,
      menuValuations: false,
      search: null,
      searchMb: null,
      searchBc: null,
      searchMo: null,
      searchPv: null,
      checkBoxesSelected: [],
      selected: null,
      showDataTest: true,
      showAddDataTest: false,
      showFungiSelected: false,
      showBacteriaSelected: false,
      showAllSelected: false,
      showReseedingSelected: false,
      showControlTest: false,
      showReseedTest: false,
      showAntagonismTest: false,
      showNutritionTest: false,
      showBioEstimulationTest: false,
      showmolecularIdentification: false,
      selectedRow: null,
      user: JSON.parse(localStorage.getItem("user")),
      page: 1,
      strainSelected: null,
      isolate: null,
      molecularIdAdded: false,
      user: JSON.parse(localStorage.getItem("user")),
      dialogTest: false,
    };
  },
  computed: {
    ...mapState(["showSampleDataS3"]),
    ...mapState("session", ["permissions"]),
    xs_resolution() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  watch: {
    xs_resolution() {
      if (!this.xs_resolution && this.selected != null) {
        this.dialogTest = false;
      } else if (this.xs_resolution && this.selected != null) {
        this.dialogTest = true;
      }
    },
    async selected(newTest, oldTest) {
      this.dialogTest = false;
      this.showBioEstimulationTest = false;
      this.showAntagonismTest = false;
      this.showControlTest = false;
      this.showReseedTest = false;
      this.showNutritionTest = false;
      this.showDataTest = false;
      this.showAddDataTest = false;
      this.showFungiSelected = false;
      this.showBacteriaSelected = false;
      this.showReseedingSelected = false;
      this.showmolecularIdentification = false;
      this.isolate = null;

      if (this.selected.analysisType) {
        if (this.selected.analysisType == "Control") {
          this.showControlTest = true;
        } else if (this.selected.analysisType == "Resiembra") {
          if (this.selected.source == "MB") {
            this.strainSelected = this.selected.strain;
            this.isolate = this.selected.isolate;
            this.showFungiSelected = true;
          } else {
            this.showReseedTest = true;
          }
        } else if (this.selected.analysisType == "Confrontación") {
          this.showAntagonismTest = true;
        } else if (this.selected.analysisType.includes("S.")) {
          this.showNutritionTest = true;
        } else if (
          this.selected.analysisType.includes("P.") ||
          this.selected.analysisType.includes("A.")
        ) {
          this.showBioEstimulationTest = true;
        } else if (this.selected.analysisType == "Molecular") {
          this.showmolecularIdentification = true;
        } else {
          if (
            this.selected.analysisType == "Bacteria" ||
            this.selected.analysisType == "Hongo" ||
            this.selected.analysisType == "Todos"
          ) {
            if (this.showSampleDataS3) {
              this.showDataTest = true;
            } else {
              await this.getMicrobiologicalTestById(
                this.selected.mbTestData._id
              );

              this.showAddDataTest = true;
            }
          } else {
            this.showDataTest = true;
          }
        }
      }

      if (this.$vuetify.breakpoint.xs && oldTest != null && newTest != null) {
        this.dialogTest = true;
      }
      if (this.selected.source != "MB") {
        this.isolate = null;
        this.strainSelected = null;
      }
    },
    async menuValuations() {
      if (this.menuValuations) {
        await this.getBioactiveBankStrains();
      }
    },
    page() {
      if (this.page != parseInt(this.$route.params.page)) {
        this.$router.replace({
          params: {
            page: this.page,
          },
        });
      }
    },
    activeTab() {
      if (this.activeTab == "biochemical") {
        this.activeMbTab = true;
        this.activeBcTab = false;
        this.activeMoTab = false;
        this.activePvTab = false;
      } else if (this.activeTab == "microbiological") {
        this.activeMbTab = false;
        this.activeBcTab = true;
        this.activeMoTab = false;
        this.activePvTab = false;
      } else if (this.activeTab == "preserved") {
        this.activeMbTab = false;
        this.activeBcTab = false;
        this.activeMoTab = false;
        this.activePvTab = true;
      } else {
        this.activeMbTab = false;
        this.activeBcTab = false;
        this.activePvTab = false;
        this.activeMoTab = true;
      }

      this.$router.replace({
        params: {
          testType: this.activeTab,
        },
      });
    },
  },
  methods: {
    ...mapMutations(["SET_CANCEL_TOKEN", "UPDATE_SHOW_SAMPLE_DATA"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("microbiologicalTest", [
      "updateMBTest",
      "getMicrobiologicalTestById",
    ]),
    ...mapActions("strain", [
      "getStrainById",
      "removeStrain",
      "getBioactiveBankStrains",
    ]),
    ...mapActions("isolate", ["updateIsolate"]),
    ...mapActions("antagonism", ["updateAntagonismTest"]),
    ...mapActions("nutrition", ["updateNutritionTest"]),
    ...mapActions("bioStimulation", ["updateBiostimulationTest"]),
    ...mapActions("testMedia", ["updateTestMedia"]),
    async setFilters(filters) {
      await this.reloadDataTable();
    },
    searchTest() {
      if (this.activeTab == "microbiological") {
        this.searchMb = this.search;
      } else if (this.activeTab == "biochemical") {
        this.searchBc = this.search;
      } else if (this.activeTab == "preserved") {
        this.searchPv = this.search;
      } else {
        this.searchMo = this.search;
      }
    },
    async contaminateTests() {
      await this.checkBoxesSelected.forEach(async (test) => {
        if (
          test.analysisType == "Hongo" ||
          test.analysisType == "Bacteria" ||
          test.analysisType == "Todos"
        ) {
          let microbiologicalTest = test.mbTestData;
          let mbTest = {
            _id: microbiologicalTest._id,
            status: "CONTAMINATED",
            laboratory: this.user.laboratory._id,
          };

          // await microbiologicalTest.strains.forEach(async (strain) => {
          //   console.log(strain._id);
          //   let strainObj = {
          //     strainId: strain._id,
          //     mbTestId: microbiologicalTest._id,
          //   };
          //   await this.removeStrain(strainObj);
          // });

          let response = await this.updateMBTest(mbTest);
          if (response.error) throw response;
        } else if (
          test.analysisType == "Resiembra"
          // || test.analysisType == "Control"
        ) {
          let isolateData = {
            _id: test.isolate._id,
            status: "CONTAMINATED",
            user: this.user._id,
            laboratory: this.user.laboratory._id,
          };

          let response = await this.updateIsolate(isolateData);
          if (response.error) throw response;

          if (response.error) throw response;

          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: "Ensayo actualizado correctamente",
            timeout: 4000,
          });

          await this.reloadDataTable();
        } else if (test.analysisType.includes("S.")) {
          let nutritionTest = {
            _id: test.testData._id,
            status: "CONTAMINATED",
          };

          const response = await this.updateNutritionTest(nutritionTest);
          if (response.error) {
            throw response;
          }
        } else if (
          test.analysisType.includes("P.") ||
          test.analysisType.includes("A.")
        ) {
          let beTest = {
            _id: test.testData._id,
            status: "CONTAMINATED",
          };

          const response = await this.updateBiostimulationTest(beTest);
          if (response.error) {
            throw response;
          }
        } else if (test.analysisType == "Confrontación") {
          // let confrontation = {
          //   _id: test.testData._id,
          //   status: "CONTAMINATED",
          // };
          // const response = await this.updateAntagonismTest(confrontation);
          // if (response.error) {
          //   throw response;
          // }
        }

        if (test.analysisType != "P. AIA") {
          let response = await this.updateTestMedia(test.idTest);
          if (response.error) throw response;
        }

        if (
          test.analysisType != "Confrontación" &&
          test.analysisType != "Control"
        ) {
          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: "Ensayo actualizado correctamente",
            timeout: 4000,
          });
          await this.reloadDataTable();
        } else {
          this.SET_ALERT_TYPE({
            visible: true,
            type: "warning",
            text: "Por el momento no se pueden contaminar ensayos de antagonismo :(",
            timeout: 4000,
          });
        }
      });

      // this.checkBoxesSelected = []
    },
    testClicked(test) {
      this.selected = test;
    },
    testSelected(tests) {
      this.checkBoxesSelected = tests;
    },
    showMbTestStrains() {
      if (this.isolate == null) {
        this.showDataTest = false;
        this.showBacteriaSelected = false;
        this.showAddDataTest = true;
      }
    },
    showSampleData() {
      this.showAddDataTest = false;
      this.showDataTest = true;

      this.UPDATE_SHOW_SAMPLE_DATA(true);
    },
    preAddStrain(type) {
      this.showAddDataTest = false;
      this.strainSelected = null;
      // if (type == null) {
      this.showSection(this.selected.analysisType);
      // } else {
      //   if (type == "fungi") {
      //     this.showSection("Hongo");
      //   } else if (type == "bacteria") {
      //     this.showSection("Bacteria");
      //   } else if (type == "all") {
      //     this.showSection("Todo");
      //   }
      // }
    },
    async preUpdateStrain(_idStrain) {
      let response = await this.getStrainById(_idStrain);
      if (response.error) throw response;

      this.strainSelected = response.STRAIN;

      this.showAddDataTest = false;
      this.showSection(this.selected.analysisType);
    },
    async preDeleteStrain(_idStrain) {
      const response = await this.removeStrain({
        strainId: _idStrain,
        mbTestId: this.selected._id,
      });

      if (response.error) throw response;
      else
        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: "Se ha eliminado la cepa correctamente",
          timeout: 4000,
        });
    },
    async reloadDataTable() {
      this.checkBoxesSelected = [];
      if (this.search != "") {
        this.search = "";
        this.searchMb = null;
        this.searchBc = null;
        this.searchMo = null;
      }

      console.log("ACTIVE TAB", this.activeTab);
      if (this.activeTab == "biochemical") {
        this.reloadDataTableBc = !this.reloadDataTableBc;
      } else if (this.activeTab == "preserved") {
        this.reloadDataTablePreserved = !this.reloadDataTablePreserved;
      } else if (this.activeTab == "microbiological") {
        this.reloadDataTableMb = !this.reloadDataTableMb;
      } else {
        this.reloadDataTableMo = !this.reloadDataTableMo;
      }
    },
    showSection(analisis) {
      if (analisis == "Hongo") {
        this.showFungiSelected = true;
      } else if (analisis == "Bacteria") {
        this.showBacteriaSelected = true;
      } else if (analisis == "Todos") {
        this.showAllSelected = true;
      }
    },
    row_classes(value) {
      if (value.clicked) {
        return "custom-highlight-row";
      }
    },
    identificationsAdded() {
      this.molecularIdAdded = !this.molecularIdAdded;
    },
  },
  created() {
    console.log("CREATED");
    console.log(this.$route.params.testType);
  },
};
</script>

<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 8px 2px;
}

.custom-highlight-row {
  background-color: rgba(4, 193, 5, 0.13);
  font-weight: bold;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.15s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.pointer {
  cursor: pointer;
}

.centered-input >>> input {
  text-align: center;
}
</style>
