<template>
  <v-dialog v-model="show" width="50vw" scrollable persistent>
    <v-card height="auto" elevation="5" class="rounded-lg" min-height="200">
      <v-card-title class="text-h5 lighten-2">
        Información de análisis
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="folioSelected"
                :items="folios"
                dense
                :loading="isLoadingFolios"
                outlined
                clearable
                return-object
                item-text="folio"
                prepend-inner-icon="mdi-file-search-outline"
                no-data-text="Sin folios"
                label="Selecciona folio resultados"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <div v-if="folioSelected">
            <v-row v-if="!fecthingAnalysis">
              <v-col>
                <v-expansion-panels multiple>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Antagonismo
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div
                        class="custom-panel-content"
                        v-if="confrontations.length > 0"
                      >
                        <div
                          v-for="(confrontation, index) in confrontations"
                          :key="index"
                        >
                          <v-list-item :key="confrontation.isolate.name">
                            <v-list-item-content>
                              <v-container>
                                <v-row>
                                  <v-col
                                    cols="9"
                                    @click="
                                      showImage(confrontation.isolate.img)
                                    "
                                    ><span class="warning--text">{{
                                      confrontation.isolate.name
                                    }}</span></v-col
                                  >
                                  <v-col
                                    cols="3"
                                    class="gray--text text-body-1 text-center"
                                    >Control</v-col
                                  >
                                </v-row>

                                <v-row
                                  v-for="confrontation in confrontation.confrontations"
                                  :key="confrontation.strainId"
                                  align="center"
                                  class="my-0"
                                >
                                  <v-col
                                    cols="9"
                                    @click="showImage(confrontation.img)"
                                  >
                                    <v-container
                                      fluid
                                      v-if="confrontation.type === 'Bioactivo'"
                                    >
                                      <span class="primary--text">
                                        {{ confrontation.name }}*Solena
                                      </span>
                                    </v-container>

                                    <v-container fluid v-else>
                                      <span>
                                        {{ confrontation.name }}*Nativa</span
                                      >
                                      <br />
                                      <span>{{
                                        confrontation.folioAnalysis
                                      }}</span>
                                    </v-container>
                                  </v-col>
                                  <v-col
                                    cols="3"
                                    class="gray--text text-body-1"
                                  >
                                    <v-row no-gutters>
                                      <!-- <v-col cols="12">
                                        <span class="text-caption"
                                          >{{ confrontation.percentage }}%</span
                                        >
                                      </v-col> -->
                                      <v-col
                                        cols="12"
                                        class="colored-box text-center black--text"
                                      >
                                        <span
                                          class="text-caption font-weight-bold"
                                          :style="`background-color: ${confrontation.color};`"
                                        >
                                          {{ confrontation.percentage }}%
                                        </span>
                                      </v-col>
                                      <v-col cols="12" class="text-center"
                                        ><span
                                          class="text-caption black--text"
                                          >{{ confrontation.bioControl }}</span
                                        ></v-col
                                      >
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </div>
                      <div v-else>
                        <span>No hay antagonismos</span>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Nutrición
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div
                        class="custom-panel-content"
                        v-if="
                          nutritions &&
                          (nutritions.potasium.length > 0 ||
                            nutritions.phosphorus.length > 0)
                        "
                      >
                        <div>
                          <span class="font-weight-bold">Potasio</span>
                          <v-list-item>
                            <v-list-item-content>
                              <v-container>
                                <v-row
                                  v-for="(
                                    potasium, index
                                  ) in nutritions.potasium"
                                  :key="index"
                                  align="center"
                                  class="my-0"
                                >
                                  <v-col
                                    cols="9"
                                    @click="showImage(potasium.img)"
                                  >
                                    <v-container
                                      fluid
                                      v-if="potasium.type === 'Bioactivo'"
                                    >
                                      <span class="primary--text">
                                        {{ potasium.name }}*Solena
                                      </span>
                                    </v-container>

                                    <v-container
                                      fluid
                                      v-else-if="
                                        potasium.type === 'negative-control'
                                      "
                                    >
                                      <span>
                                        {{ potasium.name }}
                                      </span>
                                    </v-container>

                                    <v-container fluid v-else>
                                      <span> {{ potasium.name }}*Nativa</span>
                                      <br />
                                      <span
                                        v-if="potasium.type == 'Cepa nativa'"
                                        >{{ potasium.folio }}</span
                                      >
                                    </v-container>
                                  </v-col>
                                  <v-col
                                    cols="3"
                                    class="gray--text text-body-1"
                                  >
                                    <v-row
                                      no-gutters
                                      v-if="potasium.type != 'negative-control'"
                                    >
                                      <v-col
                                        cols="12"
                                        class="colored-box text-center black--text"
                                      >
                                        <span
                                          :style="`background-color: ${potasium.color};`"
                                        ></span>
                                      </v-col>
                                      <v-col cols="12" class="text-center">
                                        <span
                                          class="text-caption black--text"
                                          >{{ potasium.solubilization }}</span
                                        >
                                      </v-col>
                                    </v-row>

                                    <v-row v-else>
                                      <v-col cols="12" class="text-center">
                                        <span class="text-caption black--text"
                                          >Control</span
                                        >
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-list-item-content>
                          </v-list-item>
                          <span class="font-weight-bold">Fósforo</span>
                          <v-list-item>
                            <v-list-item-content>
                              <v-container>
                                <v-row
                                  v-for="(
                                    phosphorus, index
                                  ) in nutritions.phosphorus"
                                  :key="index"
                                  align="center"
                                  class="my-0"
                                >
                                  <v-col
                                    cols="9"
                                    @click="showImage(phosphorus.img)"
                                  >
                                    <v-container
                                      fluid
                                      v-if="phosphorus.type === 'Bioactivo'"
                                    >
                                      <span class="primary--text">
                                        {{ phosphorus.name }}*Solena
                                      </span>
                                    </v-container>

                                    <v-container
                                      fluid
                                      v-else-if="
                                        phosphorus.type === 'negative-control'
                                      "
                                    >
                                      <span>
                                        {{ phosphorus.name }}
                                      </span>
                                    </v-container>

                                    <v-container fluid v-else>
                                      <span> {{ phosphorus.name }}*Nativa</span>
                                      <br />
                                      <span
                                        v-if="phosphorus.type == 'Cepa nativa'"
                                        >{{ phosphorus.folio }}</span
                                      >
                                    </v-container>
                                  </v-col>
                                  <v-col
                                    cols="3"
                                    class="gray--text text-body-1"
                                  >
                                    <v-row
                                      no-gutters
                                      v-if="
                                        phosphorus.type != 'negative-control'
                                      "
                                    >
                                      <v-col
                                        cols="12"
                                        class="colored-box text-center black--text"
                                      >
                                        <span
                                          :style="`background-color: ${phosphorus.color};`"
                                        ></span>
                                      </v-col>
                                      <v-col cols="12" class="text-center">
                                        <span
                                          class="text-caption black--text"
                                          >{{ phosphorus.solubilization }}</span
                                        >
                                      </v-col>
                                    </v-row>

                                    <v-row v-else>
                                      <v-col cols="12" class="text-center">
                                        <span class="text-caption black--text"
                                          >Control</span
                                        >
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </div>
                      <div v-else>
                        <span>No hay nutriciones</span>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Nematodos
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div
                        class="custom-panel-content"
                        v-if="nematodes.length > 0"
                      >
                        <v-expansion-panels>
                          <v-expansion-panel
                            v-for="(item, index) in nematodes"
                            :key="index"
                          >
                            <v-expansion-panel-header>
                              {{ item.sample }}
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-container>
                                <v-row
                                  v-for="(nematode, indexN) in item.nematodes"
                                  :key="indexN"
                                  align="center"
                                  class="my-0"
                                >
                                  <v-col cols="9">
                                    <span> {{ nematode.name }}</span>
                                  </v-col>
                                  <v-col
                                    cols="3"
                                    class="gray--text text-body-1"
                                  >
                                    <v-row no-gutters>
                                      <v-col cols="12" class="colored-box">
                                        <span>
                                          {{ nematode.interpretation }}
                                        </span>
                                      </v-col>
                                      <v-col cols="12">
                                        <span class="text-caption black--text"
                                          >{{
                                            nematode.concentration
                                          }}
                                          juveniles/100g de suelo</span
                                        >
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </div>
                      <div v-else>
                        <span>No hay nematodos</span>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
            <v-row no-gutters v-else>
              <v-col cols="12">
                <v-skeleton-loader
                  type="list-item-three-line"
                ></v-skeleton-loader>
              </v-col>
              <v-col cols="12">
                <v-skeleton-loader
                  type="list-item-three-line"
                ></v-skeleton-loader>
              </v-col>
              <v-col cols="12">
                <v-skeleton-loader
                  type="list-item-three-line"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col>
              <v-btn color="gray" block @click="closeModal" dark>Cerrar</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
    <SubSamplePhoto
      :img="img"
      :visible="showImageDialog"
      @hideSubSampleModal="hideSubSampleModal"
    />
  </v-dialog>
</template>

<script>
//vuex
import { mapState, mapActions, mapMutations } from "vuex";
//components
import SubSamplePhoto from "@/components/station1/modals/SubSamplePhoto";
export default {
  components: {
    SubSamplePhoto,
  },
  data: () => ({
    loader: false,
    isLoadingFolios: false,
    folioSelected: null,
    folios: [],
    fecthingAnalysis: false,
    confrontations: [],
    nematodes: [],
    nutritions: null,
    img: [],
    showImageDialog: false,
  }),
  props: {
    value: Boolean,
    profitId: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    await this.fecthFolios();
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("profit", ["getFoliosInProfit", "getValuationFromAnalysis"]),
    closeModal() {
      this.show = false;
    },
    async fecthFolios() {
      this.isLoadingFolios = true;
      try {
        let payload = {
          profitId: this.profitId,
        };
        let response = await this.getFoliosInProfit(payload);
        if (!response) throw response;
        this.folios = response.folios;
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Algo salio mal al cargar los folios",
          timeout: 5000,
        });
      }
      this.isLoadingFolios = false;
    },
    showImage(img) {
      this.img = [
        {
          title: "",
          src: img,
        },
      ];
      this.showImageDialog = true;
    },
    hideSubSampleModal(value) {
      this.showImageDialog = value;
    },
  },
  watch: {
    async folioSelected(value) {
      if (!value) return;
      try {
        this.fecthingAnalysis = true;
        let payload = {
          analysisId: value._id,
        };
        let response = await this.getValuationFromAnalysis(payload);
        if (!response) throw response;
        this.confrontations = response.analysisData.valuation.antagonisms;
        this.nutritions = response.analysisData.valuation.nutritions;
        this.nematodes = response.analysisData.nematodes;
        console.log(response);
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Algo salio mal al cargar analisis",
          timeout: 5000,
        });
      }
      this.fecthingAnalysis = false;
    },
  },
};
</script>

<style scoped>
/* Estilos CSS personalizados para el contenido del panel */
.custom-panel-content {
  max-height: 500px; /* Tamaño máximo del panel */
  overflow-y: auto; /* Habilitar el desplazamiento vertical (scroll) si el contenido excede el tamaño máximo */
}
.colored-box {
  display: flex;
  align-items: center; /* Para centrar verticalmente el span */
}

.colored-box span {
  flex: 1; /* Para que el span se expanda y ocupe todo el espacio disponible */
  display: inline-block;
  height: 20px;
}
</style>
