<template>
  <v-dialog
    v-model="dialogT"
    :width="urlPreview == '' ? '20%' : '80%'"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" color="success" icon :disabled="status == 'CONTAMINATED' || status == 'UNREGISTERED'"
        ><v-icon>mdi-file-table</v-icon></v-btn
      >
    </template>
    <v-card>
      <v-card-text class="px-0 py-0">
        <v-img
          v-if="urlPreview == ''"
          max-width="160"
          src="img/GIF Solena.gif"
          class="mx-auto"
        ></v-img>
        <vue-pdf-app
          v-else
          style="height: 80vh"
          :pdf="urlPreview"
        ></vue-pdf-app>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="warning" @click="dialogT = false"> Cerrar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";

export default {
  components: {
    VuePdfApp,
  },
  data() {
    return {
      dialogT: false,
      urlPreview: "",
    };
  },
  props: {
    plateId : {
        type : String,
        require : true
    },
    status : {
        type : String,
        require : true
    },
  },
  methods: {
    ...mapActions('molecularPlate', ['getPdfFile']),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async getPdfUrl(){
        try {
            
            let request = await this.getPdfFile({plate : this.plateId})

            if(request.error) throw request

            this.urlPreview = request.url

            console.log(request)

        } catch (error) {
            this.SET_ALERT_TYPE({
                visible: true,
                type: "error",
                text: error.message,
                timeout: 4000,
            });
        }
    }
  },
  watch: {
        async dialogT(){
            if(this.dialogT){
                await this.getPdfUrl()
            }
        }
    }
};
</script>

<style>
</style>