export function SET_TESTMEDIA_LIST(state, data) {
  state.testMediaList = data;
}
export function SET_TESTMEDIA_COUNT(state, countData) {
  state.testMediaCounted = countData;
}
export function SET_TESTMEDIA_FORMATED(state, testMediaFormated) {
  state.testMediaFormated = testMediaFormated;
}
export function SET_TESTMEDIA_CONTAMINATED(state, data) {
  state.testMediaContaminated = data;
}
