// Guard
import { privateGuard } from "@/middlewares/guards";

// Layouts
import NavLayout from "../layouts/NavLayout.vue";

// Views
import Tests from "../views/Tests.vue";


export default [
  {
    path: "/tests/:testType/:page",
    beforeEnter: privateGuard,
    component: NavLayout,
    children: [
      {
        path: "",
        component: Tests,
        name : 'Ensayos',
        meta: {
          title: "Ensayos",
        }
      }
    ],
  }
];
