var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-app-bar',{attrs:{"app":"","clipped-right":"","fixed":"","flat":"","color":"white"}},[_c('v-toolbar-title',[_c('router-link',{attrs:{"to":"/reception/1/reception/"}},[_c('v-img',{attrs:{"src":require("@/assets/img/logo-solena-black.png"),"width":"150"}})],1)],1),_c('v-spacer'),(
        _vm.minutesToExpire > 0 &&
        _vm.minutesToExpire <= 5 &&
        _vm.daysToExpire == 0 &&
        _vm.hoursToExpire == 0
      )?_c('v-toolbar-title',{staticClass:"px-2"},[_c('v-alert',{staticClass:"mb-0",attrs:{"icon":"mdi-alert","dense":"","outlined":"","type":"warning"}},[(_vm.minutesToExpire > 1)?_c('h5',[_vm._v(" La sesión expira en: "),_c('strong',[_vm._v(_vm._s(_vm.minutesToExpire)+" minutos")])]):_c('h5',[_vm._v(" La sesión expira en: "),_c('strong',[_vm._v(_vm._s(_vm.minutesToExpire)+" minuto")])])])],1):(
        _vm.minutesToExpire <= 0 && _vm.daysToExpire <= 0 && _vm.hoursToExpire <= 0
      )?_c('v-toolbar-title',{staticClass:"px-2"},[_c('v-alert',{staticClass:"mb-0",attrs:{"icon":"mdi-alert","dense":"","outlined":"","type":"warning"}},[_c('h5',[_c('strong',[_vm._v("La sesión ha expirado")])])])],1):_vm._e(),_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}})],1),_c('v-navigation-drawer',{attrs:{"app":"","temporary":"","clipped":"","right":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{attrs:{"block":"","color":"primary"},on:{"click":_vm.logOut}},[_vm._v(" Cerrar Sesión ")])]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticClass:"ma-3",attrs:{"nav":""}},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-avatar',{attrs:{"color":"primary"}},[_c('span',{staticClass:"white--text headline"},[_vm._v(_vm._s(_vm.user.name ? _vm.user.name.charAt(0) : ""))])]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.user.name))]),(_vm.user.laboratory)?_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.user.laboratory.name))]):_vm._e()],1)],1),_c('v-divider',{staticClass:"mb-5"}),_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,class:item.path === _vm.$route.path ? 'highlighted' : '',attrs:{"to":item.to,"active-class":"highlighted"}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)],1)}),1)],1)],1),_c('v-main',{staticClass:"main_app"},[_c('v-container',{class:{
        'large-devices': _vm.$vuetify.breakpoint.xlOnly,
      },attrs:{"fill-height":"","fluid":""}},[_c('router-view'),_c('SessionDialog',{attrs:{"dialog":_vm.dialog}})],1)],1),_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"400","persistent":""},scopedSlots:_vm._u([{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"warning","dark":""}},[_vm._v("La sesión caducó")]),_c('v-card-text',{staticClass:"py-0"},[_c('div',{staticClass:"text-body-4 py-6"},[_vm._v(" Puedes volver iniciar sesión o recargar la página si es que ya lo hiciste. ")])]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$router.go()}}},[_vm._v("Recargar")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.logOut()}}},[_vm._v("Volver a iniciar sesión")])],1)],1)]}}]),model:{value:(_vm.dialog_unauthorized),callback:function ($$v) {_vm.dialog_unauthorized=$$v},expression:"dialog_unauthorized"}}),_c('v-footer',{attrs:{"padless":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_vm._v(" D.R. © Solena Ag, Inc. Av. Olímpica 3020-D Villas de San Juan 37295, León Gto, México. "+_vm._s(new Date().getFullYear())+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }