<template>
  <div id="mapContainer" style="height: 60vh; width: 100%" ref="hereMap"></div>
</template>

<script>
export default {
  name: "Map",
  data() {
    return {
      map: {},
      group: {},
      center: {},
      markers: [],
    };
  },
  watch: {
    soilSample() {
      if (this.soilSample != null) {
        if (
          this.soilSample.soilArea.location &&
          this.soilSample.soilArea.location.lat
        ) {
          this.addMarkers();
          this.addFigure();
        } else {
          //remove all objects from map
          this.map.removeObjects(this.map.getObjects());
          // set map center at solena
          this.map.setCenter({
            lat: 21.089031,
            lng: -101.593895,
          });
          //set zoom level
          this.map.setZoom(16);
        }
      }
    },
  },
  props: {
    soilSample: {
      type: Object,
      required: false,
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      const platform = new window.H.service.Platform({
        apikey: process.env.VUE_APP_HERE_APIKEY,
      });

      // rendering map
      const mapContainer = this.$refs.hereMap;
      const H = window.H;

      let maptypes = platform.createDefaultLayers();

      this.map = new H.Map(mapContainer, maptypes.vector.normal.map, {
        center: {
          lat: 21.089031,
          lng: -101.593895,
        },
        pixelRatio: window.devicePixelRatio || 1,
      });

      this.map.setZoom(16);

      // Set hybrid tile view
      const mapTileService = platform.getMapTileService({
        type: "aerial",
      });
      const parameters = {
        ppi: "320",
      };
      const tileLayer = mapTileService.createTileLayer(
        "maptile",
        "hybrid.day",
        256,
        "png8",
        parameters
      );

      this.map.setBaseLayer(tileLayer);

      addEventListener("resize", () => this.map.getViewPort().resize());

      new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));

      H.ui.UI.createDefault(this.map, maptypes);

      if (this.soilSample != null) {
        if (
          this.soilSample.soilArea.location &&
          this.soilSample.soilArea.location.lat
        ) {
          this.addMarkers();
          this.addFigure();
        } else {
          //remove all objects from map
          this.map.removeObjects(this.map.getObjects());
          // set map center at solena
          this.map.setCenter({
            lat: 21.089031,
            lng: -101.593895,
          });
          //set zoom level
          this.map.setZoom(16);
        }
      }
    },
    addMarkers() {
      //remove all objects from map
      this.map.removeObjects(this.map.getObjects());

      if (this.soilSample.subSamples) {
        this.group = new H.map.Group();

        this.map.addObject(this.group);

        let icon = new H.map.Icon(require("@/assets/img/solena-flag-new.png"), {
          size: {
            w: 30,
            h: 30,
          },
        });

        for (let i = 0; i < this.soilSample.subSamples.length; i++) {
          let location = {
            lat: this.soilSample.subSamples[i].location.lat,
            lng: this.soilSample.subSamples[i].location.lng,
          };
          let marker = new H.map.Marker(location, {
            icon: icon,
          });

          marker.setData(this.soilSample.subSamples[i]);
          this.group.addObject(marker);
        }

        this.group.addEventListener(
          "tap",
          (evt) => {
            this.$emit("markerTap", evt.target.getData());
          },
          false
        );
      }
    },
    addFigure() {
      // set figure center
      this.map.setCenter({
        lat: this.soilSample.soilArea.location.lat,
        lng: this.soilSample.soilArea.location.lng,
      });

      if (
        this.soilSample.soilArea.polygon &&
        this.soilSample.soilArea.polygon.length > 0
      ) {
        let points = [];
        for (let axis of this.soilSample.soilArea.polygon) {
          points.push(axis.lat, axis.lng, 0);
        }
        const polygon = new H.map.Polygon(
          new H.geo.LineString(points, "values lat lng alt"),
          {
            style: {
              fillColor: "rgba(2, 114, 35, .8)",
              lineWidth: 1,
            },
          }
        );
        this.group.addObject(polygon);
      } else {
        const circle = new H.map.Circle(
          {
            lat: this.soilSample.soilArea.location.lat,
            lng: this.soilSample.soilArea.location.lng,
          },
          100,
          {
            style: {
              fillColor: "rgba(2, 114, 35, .5)",
              strokeColor: "rgb(2, 114, 35)",
            },
          }
        );
        this.group.addObject(circle);
        this.map.setZoom(14);
      }

      this.map.getViewModel().setLookAtData({
        bounds: this.group.getBoundingBox(),
      });
    },
  },
};
</script>
