<template>
  <v-dialog
    transition="dialog-top-transition"
    max-width="650"
    :loading="loading"
    v-model="dialog"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
        small
        depressed
        block
        :disabled="!hasTests"
        v-bind="attrs"
        v-on="on"
      >
        Incubar
      </v-btn>
      <v-btn
        v-else
        small
        depressed
        block
        :disabled="!hasTests"
        v-bind="attrs"
        v-on="on"
      >
        Incubar ensayos
      </v-btn>
    </template>
    <template v-slot:default="dialog">
      <v-card v-if="tests.length > 0">
        <v-toolbar color="primary" dark>Incubación de ensayos</v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12"> Ensayos seleccionados: </v-col>
            </v-row>
            <v-row class="mt-2 pr-5">
              <v-col cols="3" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-caption text-center mb-0 font-weight-black">
                    ID de ensayo
                  </p>
                </v-row>
              </v-col>
              <v-col cols="2" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-caption text-center mb-0 font-weight-black">
                    Medio
                  </p>
                </v-row>
              </v-col>
              <v-col cols="2" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-caption text-center mb-0 font-weight-black">
                    Tipo de análisis
                  </p>
                </v-row>
              </v-col>
              <v-col cols="2" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-caption text-center mb-0 font-weight-black">
                    Tipo de MO
                  </p>
                </v-row>
              </v-col>
              <v-col cols="3" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-caption text-center mb-0 font-weight-black">
                    Cepa
                  </p>
                </v-row>
              </v-col>
            </v-row>
            <template v-for="test in tests">
              <v-row
                class="mt-6 pr-5"
                :key="test.idTest"
                v-if="
                  (test.analysisType == 'Resiembra' ||
                    test.analysisType == 'Control' ||
                    test.analysisType == 'Confrontación' ||
                    test.analysisType.includes('S.') ||
                    test.analysisType.includes('A.') ||
                    test.analysisType.includes('P.')) &&
                  test.status.includes('Inoculado')
                "
              >
                <v-col cols="3" class="px-0 pb-0">
                  <v-row justify="center">
                    <p class="text-body-2 text-center mb-0">
                      {{ test.idTest }}
                    </p>
                  </v-row>
                </v-col>
                <v-col cols="2" class="px-0 pb-0">
                  <v-row justify="center">
                    <p class="text-body-2 text-center mb-0">
                      {{ test.medium }}
                    </p>
                  </v-row>
                </v-col>
                <v-col cols="2" class="px-0 pb-0">
                  <v-row justify="center">
                    <p class="text-body-2 text-center mb-0">
                      {{ test.analysisType }}
                    </p>
                  </v-row>
                </v-col>
                <v-col cols="2" class="px-0 pb-0">
                  <v-row justify="center" v-if="test.mbTestData">
                    <p
                      class="text-body-2 text-center mb-0"
                      v-if="
                        test.mbTestData.incubation.incubator.moType == 'fungi'
                      "
                    >
                      Hongo
                    </p>
                    <p class="text-body-2 text-center mb-0" v-else>Bacteria</p>
                  </v-row>
                  <v-row
                    justify="center"
                    v-else-if="
                      test.bcTestData && test.bcTestData.type != 'Antagonism'
                    "
                  >
                    <p
                      class="text-body-2 text-center mb-0"
                      v-if="test.strain.identification.moType == 'fungi'"
                    >
                      Hongo
                    </p>
                    <p class="text-body-2 text-center mb-0" v-else>Bacteria</p>
                  </v-row>
                  <v-row justify="center" v-else>
                    <p class="text-body-2 text-center mb-0">---</p>
                  </v-row>
                </v-col>
                <v-col cols="3" class="px-0 pb-0">
                  <v-row justify="center">
                    <p
                      class="text-body-2 text-center mb-0"
                      v-if="test.analysisType != 'Confrontación'"
                    >
                      {{ test.inoculum }}
                    </p>
                    <p class="text-body-2 text-center mb-0" v-else>
                      {{ test.inoculumControl }} vs
                      {{ test.inoculumBeneficial }}
                    </p>
                  </v-row>
                </v-col>
              </v-row>
            </template>
          </v-container>
        </v-card-text>

        <v-card-actions class="justify-end mt-5">
          <v-btn text color="warning" @click="dialog.value = false"
            >Cancelar</v-btn
          >
          <v-btn
            text
            color="secondary"
            @click="incubateTests"
            :loading="loading"
            >Incubar</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-toolbar color="primary" dark>Incubar ensayos</v-toolbar>
        <v-card-text>
          <div class="text-h6 pa-12">Debes seleccionar una o mas ensayos</div>
        </v-card-text>
        <v-card-actions class="justify-end mt-5">
          <v-btn text @click="dialog.value = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      hasTests: false,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  props: {
    tests: {
      type: Array,
      require: true,
    },
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("antagonism", ["updateAntagonismTest"]),
    ...mapActions("nutrition", ["updateNutritionTest"]),
    ...mapActions("bioStimulation", ["updateBiostimulationTest"]),
    ...mapActions("isolate", ["updateIsolate"]),
    async incubateTests() {
      this.loading = true;
      let tests = this.tests.filter(
        (test) =>
          test.analysisType == "Resiembra" ||
          test.analysisType == "Control" ||
          test.analysisType == "Confrontación" ||
          ((test.analysisType.includes("S.") ||
            test.analysisType.includes("A.") ||
            test.analysisType.includes("P.")) &&
            test.status.includes("Inoculado"))
      );

      if (tests.length > 0) {
        let testsForm = tests.map((test) => {
          if (
            test.analysisType == "Control" ||
            test.analysisType == "Resiembra"
          ) {

            let form = null

            if(test.mbTestData){
              form = {
                _id: test.isolate._id,
                status: "INCUBATING",
                incubation: {
                  _id: test.mbTestData.incubation._id,
                  incubator: test.mbTestData.incubation.incubator,
                  temperature: test.mbTestData.incubation.temperature,
                  startDate: new Date(),
                },
                formType: "Isolate",
                user : this.user._id,
                laboratory : this.user.laboratory._id
              };
            }else{

              if(test.strain.identification.moType == 'bacteria'){
                form = {
                  _id: test.isolate._id,
                  status: "INCUBATING",
                  incubation: {
                    incubator: {
                      _id: "601b013c63cbfb221cecd2ff",
                      brand: "Incubadora",
                      model: "Bacterias 5",
                      moType: "bacteria",
                      temperature: "33",
                      __v: 0,
                    },
                    temperature: '33',
                    startDate: new Date(),
                  },
                  formType: "Isolate",
                };
              }else{
                form = {
                  _id: test.isolate._id,
                  status: "INCUBATING",
                  incubation: {
                    incubator: {
                      _id: "601b015263cbfb221cecd300",
                      brand: "Incubadora",
                      model: "Hongos 1",
                      moType: "fungi",
                      temperature: "29",
                      __v: 0,
                    },
                    temperature: '29',
                    startDate: new Date(),
                  },
                  formType: "Isolate",
                };
              }
            }

            return form;
          } else {
            if (test.analysisType != "Confrontación") {
              let form = {
                _id: test._id,
                status: "INCUBATING",
                incubation:
                  test.strain.identification.moType == "bacteria"
                    ? {
                        startDate: new Date(),
                        temperature: "33°",
                        incubator: {
                          _id: "601b013c63cbfb221cecd2ff",
                          brand: "Incubadora",
                          model: "Bacterias 5",
                          moType: "bacteria",
                          temperature: "33",
                          __v: 0,
                        },
                      }
                    : {
                        startDate: new Date(),
                        temperature: "33°",
                        incubator: {
                          _id: "601b015263cbfb221cecd300",
                          brand: "Incubadora",
                          model: "Hongos 1",
                          moType: "fungi",
                          temperature: "29",
                          __v: 0,
                        },
                      },
                formType: "BC Test",
                bcTestType: test.analysisType,
                user : this.user._id,
                laboratory : this.user.laboratory._id
              };

              return form;
            } else {
              let form = {
                _id: test._id,
                status: "INCUBATING",
                incubation:
                  test.testData.beneficalStrain.identification.moType ==
                  "bacteria"
                    ? {
                        startDate: new Date(),
                        temperature: "33°",
                        incubator: {
                          _id: "601b013c63cbfb221cecd2ff",
                          brand: "Incubadora",
                          model: "Bacterias 5",
                          moType: "bacteria",
                          temperature: "33",
                          __v: 0,
                        },
                      }
                    : {
                        startDate: new Date(),
                        temperature: "33°",
                        incubator: {
                          _id: "601b015263cbfb221cecd300",
                          brand: "Incubadora",
                          model: "Hongos 1",
                          moType: "fungi",
                          temperature: "29",
                          __v: 0,
                        },
                      },
                formType: "BC Test",
                bcTestType: test.analysisType,
                user : this.user._id,
                laboratory : this.user.laboratory._id
              };

              return form;
            }
          }
        });

        try {
          for (const test of testsForm) {
            if (test.formType == "Isolate") {
              const response = await this.updateIsolate(test);
              if (response.error) {
                throw response;
              }
            } else {
              if (test.bcTestType.includes("S.")) {
                const response = await this.updateNutritionTest(test);
                if (response.error) {
                  throw response;
                }
              } else if (
                test.bcTestType.includes("P.") ||
                test.bcTestType.includes("A.")
              ) {
                const response = await this.updateBiostimulationTest(test);
                if (response.error) {
                  throw response;
                }
              } else {
                const response = await this.updateAntagonismTest(test);
                if (response.error) {
                  throw response;
                }
              }
            }
          }

          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: "Ensayos incubadas correctamente",
            timeout: 4000,
          });
        } catch (e) {
          console.log(e);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: "Error al incubar ensayos, comunicate a soporte técnico para dar seguimiento.",
            timeout: 4000,
          });
        }
      } else {
        this.SET_ALERT_TYPE({
          visible: true,
          type: "warning",
          text: "No hay ensayos por incubar",
          timeout: 4000,
        });
      }

      this.$emit("testsSaved");
      this.dialog = false;
      this.loading = false;
    },
  },
  watch: {
    tests() {
      this.hasTests = false;

      for (const test of this.tests) {
        if (
          (test.analysisType == "Resiembra" ||
            test.analysisType == "Control" ||
            test.analysisType == "Confrontación" ||
            test.analysisType.includes("S.") ||
            test.analysisType.includes("A.") ||
            test.analysisType.includes("P.")) &&
          test.status.includes("Inoculado")
        ) {
          this.hasTests = true;
        }
      }
    },
  },
};
</script>

<style></style>
