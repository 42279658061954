import store from "../store/index";

/**
 * Function to get new token using refresh token.
 */
export const privateGuard = async (to, from, next) => {
  try {
    if (!store.getters["session/loggedIn"]) {
      if (
        localStorage.getItem("refresh_token") &&
        localStorage.getItem("token_expiry") && 
        localStorage.getItem("session") 
        ) {

        let now = new Date();
        const token_expiry = new Date(localStorage.getItem("token_expiry"));

        // console.log("ACTUALLY DATE", now);
        // console.log("EXPIRATION DATE", token_expiry);

        if (token_expiry < now) {
          console.log("IS EXPIRED", token_expiry < now);

          await store.dispatch(
            "session/logout",
            {refreshToken: localStorage.getItem("refresh_token"), session: localStorage.getItem("session")}
          );

          localStorage.clear();
          next({
            path: "/login",
          });
        }else{
          await store.dispatch("session/setCurrentToken");

          next()
        }

        
      } else {
        next({
          path: "/login",
        });
      }
    } else {
      next();
    }
  } catch (e) {
    console.log("Error", e);
    localStorage.clear();
    next({
      path: "/login",
    });
  }
};

export const defaultGuard = (to, from, next) => {
  // if a user is autenticated, redirect to home page.
  // console.log(typeof localStorage.getItem("refresh_token"),localStorage.getItem("refresh_token"))
  let token = localStorage.getItem("refresh_token")

  if(token){
    if(token.length == 36){
      if(to.path == '/login'){
        next()
      }else{
        next({
          path: "/login",
        });
      }
      
    }else{
      next({
        path: "/"
      })
    }
  }else{
    if(to.path == '/login'){
      next()
    }else{
      next({
        path: "/"
      })
    }
  }
};
