<template>
  <v-dialog v-model="show" width="50vw" scrollable>
    <v-card height="auto" elevation="5" class="rounded-xl" min-height="200">
      <v-card-title class="text-h5 lighten-2"> Añadir etiquetas </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col align="center">
              <ImageTagger
                :imageUrl="imageUrl"
                @tags="sendTags"
                :isEditable="true"
                :incomingTags="incomingTags"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col>
              <v-btn color="gray" block @click="show = false" dark
                >Cerrar</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//Vuex
import { mapMutations } from "vuex";
// component
import ImageTagger from "@/components/utils/ImageTagger.vue";
export default {
  components: {
    ImageTagger,
  },
  data: () => ({
    isLoading: false,
  }),
  props: {
    value: Boolean,
    imageUrl: String,
    incomingTags: Array,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    sendTags(tags) {
      this.$emit("evidenceTags", tags);
      this.SET_ALERT_TYPE({
        visible: true,
        type: "success",
        text: "Etiquetas guardadas",
        timeout: 4000,
      });
    },
  },
};
</script>
