<template>
  <v-container fluid>
    <template>
      <div class="text-center">
        <v-dialog v-model="dialogT" width="80%" persistent>
          <v-card>
            <v-card-text class="px-0 py-0">
              <vue-pdf-app style="height: 80vh" :pdf="urlPreview"></vue-pdf-app>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="warning" @click="closeResultDialog">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <v-card class="rounded-lg">
      <v-card-title>
        <span class="primary--text"> Rentabilizaciónes </span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" xl="5" lg="5" md="5">
            <v-autocomplete
              dense
              outlined
              label="Estatus"
              return-object
              :items="status"
              item-text="text"
              v-model="state"
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              height="600"
              :headers="headers"
              :items="data"
              :page.sync="page"
              :items-per-page="10"
              :loading="loadTable"
              hide-default-footer
            >
              <template #[`item.actionEdit`]="{ item }">
                <v-row>
                  <v-col>
                    <v-icon @click="showDetail(item)" color="primary"
                      >mdi-pencil</v-icon
                    >
                  </v-col>
                  <v-col
                    v-if="
                      item.status === 'ACCEPTED' || item.status === 'FINISHED'
                    "
                  >
                    <v-btn text @click="showPreview(item.url)">
                      <v-icon color="primary">mdi-eye</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
              </template>

              <template #[`item.duration`]="{ item }">
                <span>{{ item.duration }} Meses</span>
              </template>

              <template #[`item.crop`]="{ item }">
                <span>{{ getCrop(item.crop) }} </span>
              </template>

              <template #[`item.surface`]="{ item }">
                <span>{{ item.surface }} Ha</span>
              </template>

              <template #[`item.status`]="{ item }">
                <span v-if="item.status === 'NEW'" class="gray--text">
                  <v-chip color="gray" dark>
                    {{ getStatus(item.status) }}
                  </v-chip>
                </span>

                <span v-else-if="item.status === 'PROPOSAL'" class="info--text">
                  <v-chip color="info" dark>
                    {{ getStatus(item.status) }}
                  </v-chip>
                </span>

                <span
                  v-else-if="item.status === 'ACCEPTED'"
                  class="primary--text"
                >
                  <v-chip color="primary" dark>
                    {{ getStatus(item.status) }}
                  </v-chip>
                </span>

                <span
                  v-else-if="item.status === 'FINISHED'"
                  class="error--text"
                >
                  <v-chip color="error" dark>
                    {{ getStatus(item.status) }}
                  </v-chip>
                </span>
                <span
                  v-else-if="item.status === 'STRAINS_SUGGESTION'"
                  class="info--text"
                >
                  <v-chip color="info" dark>
                    {{ getStatus(item.status) }}
                  </v-chip>
                </span>
              </template>

              <template #[`item.price`]="{ item }">
                <span>${{ item.price }} </span>
              </template>

              <template #[`item.updatedAt`]="{ item }">
                <span> {{ dateFormat(item.updatedAt) }}</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-pagination
          v-model="page"
          :length="numPages"
          total-visible="10"
          color="primary"
        ></v-pagination>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
//Libraries
import dayjs from "dayjs";
import axios from "axios";
//Vuex
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
//pdf viewer
import VuePdfApp from "vue-pdf-app";
export default {
  components: {
    VuePdfApp,
  },
  data: () => ({
    laboratory: "",
    loadTable: false,
    page: 1,
    numPages: 0,
    search: "",
    headers: [
      {
        text: "Código",
        align: "start",
        sortable: false,
        value: "code",
      },
      {
        text: "Nombre del proyecto",
        align: "start",
        sortable: false,
        value: "proyectName",
      },
      {
        text: "Cliente",
        align: "start",
        sortable: false,
        value: "client",
      },
      {
        text: "Duración",
        align: "start",
        sortable: false,
        value: "duration",
      },
      {
        text: "Cultivo",
        align: "start",
        sortable: false,
        value: "crop",
      },
      {
        text: "Superficie",
        align: "start",
        sortable: false,
        value: "surface",
      },
      {
        text: "Estatus",
        align: "start",
        sortable: false,
        value: "status",
      },
      {
        text: "Precio / Ha",
        align: "start",
        sortable: false,
        value: "price",
      },
      {
        text: "Ultima actualización",
        align: "start",
        sortable: false,
        value: "updatedAt",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: false,
        value: "actionEdit",
      },
    ],
    data: [],
    dialogT: false,
    urlPreview: "",
    status: [
      {
        text: "Nuevo",
        value: "NEW",
      },
      {
        text: "Sugerencia de cepas",
        value: "STRAINS_SUGGESTION",
      },
      {
        text: "Propuesta rentabilización",
        value: "PROPOSAL",
      },
      {
        text: "Aceptado",
        value: "ACCEPTED",
      },
      {
        text: "Finalizado",
        value: "FINISHED",
      },
    ],
    state: null,
    // isLoadingDelete: false,
    // creatingNewReport: false,
    // showGenerate: false,
  }),
  async created() {
    this.UNSET_LOAD_PROFIT(0)
    this.page = parseInt(this.$route.params.page, 10);
    this.loadTable = true;
    let user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : "";
    this.laboratory = user.laboratory._id;
    await this.getProfitData();
    this.loadTable = false;
  },
  methods: {
    ...mapActions("profit", ["getProfitsFilter"]),
    ...mapMutations("profit", ["SET_PROFIT","UNSET_LOAD_PROFIT"]),
    ...mapMutations(["SET_CANCEL_TOKEN"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    showDetail(value) {
      this.SET_PROFIT(value);
      this.$router.push(`/profit-detail/${value._id}`);
    },
    dateFormat(value) {
      const date = `${dayjs(value).format("DD-MM-YYYY - HH:mm")} hrs`;
      return date ? date : "----";
    },
    async getProfitData() {
      try {
        let payload = {
          laboratory: this.laboratory,
          page: this.page,
          search: this.search,
          status: this.state ? this.state.value : null,
        };
        let response = await this.getProfitsFilter(payload);

        if (response) {
          if (!response.error) {
            this.numPages = response.pages;
            this.data = response.profits;
          } else {
            throw response;
          }
        } else {
          throw response;
        }
      } catch (e) {
        console.log(e);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: e.message,
          timeout: 5000,
        });
      }
    },
    getCrop(value) {
      let template = "";
      const crop = {
        olive: "Aceituna",
        chard: "Acelga",
        achiote: "Achiote",
        agave: "Agave",
        avocado: "Aguacate",
        garlic: "Ajo",
        sesame: "Ajonjolí",
        basil: "Albahaca",
        artichoke: "Alcachofa",
        alcatraz: "Alcatraz",
        shrunkenAlfalfa: "Alfalfa achicalada",
        greenAlfalfa: "Alfalfa verde",
        cotton: "Algodón",
        almond: "Almendro",
        birdseed: "Alpiste",
        amaranth: "Amaranto",
        anise: "Anís",
        celery: "Apio",
        blueberry: "Arándano",
        myrtle: "Arrayán",
        rice: "Arroz",
        greenForageOats: "Avena forrajera en verde",
        oatGrain: "Avena grano",
        bamboo: "Bambú",
        eggplant: "Berenjena",
        beetroot: "Betabel",
        broccoli: "Brocoli",
        peanut: "Cacahuate",
        cocoa: "Cacao",
        cherryCoffee: "Café cereza",
        pumpkin: "Calabaza",
        sweetPotato: "Camote",
        sugarCane: "Caña de azúcar",
        canola: "Canola",
        carambolo: "Carambolo",
        safflower: "Cártamo",
        barley: "Cebada",
        greenForageBarley: "Cebada forrajera en verde",
        onion: "Cebolla",
        chives: "Cebollín",
        ryeGrain: "Centeno grano",
        cherry: "Cereza",
        chabacano: "Chabacano",
        pea: "Chícharo",
        squash: "Chayote",
        chia: "Chía",
        chilacayote: "Chilacayote",
        chillyPepper: "Chile / Pimiento",
        custardApple: "Chirimoya",
        coriander: "Cilantro",
        plum: "Ciruela",
        coconutFruit: "Coco fruta",
        cabbage: "Col (repollo)",
        brusselsSprouts: "Col de Bruselas",
        cauliflower: "Coliflor",
        cumin: "Comino",
        chrysanthemum: "Crisantemo (planta)",
        peach: "Durazno",
        date: "Dátil",
        greenBean: "Ejote",
        cornCob: "Elote",
        dill: "Eneldo",
        epazote: "Epazote",
        asparagus: "Espárrago",
        spicesAndMedicinals: "Especias y medicinales",
        spinach: "Espinaca",
        raspberry: "Frambuesa",
        strawberry: "Fresa",
        bean: "Frijol",
        chickpea: "Garbanzo",
        sunflower: "Girasol",
        pomegranate: "Granada",
        soursop: "Guanábana",
        guava: "Guayaba",
        henequen: "Henequén",
        peppermint: "Hierbabuena",
        aromaticHerbs: "Hierbas aromáticas",
        fig: "Higo",
        jamaica: "Jamaica",
        ginger: "Jenjibre",
        jicama: "Jícama",
        lettuce: "Lechuga",
        lentil: "Lenteja",
        lime: "Lima",
        lemon: "Limón",
        linseed: "Linaza",
        litchi: "Litchi",
        corn: "Maíz",
        macadamia: "Macadamia",
        maguey: "Maguey",
        mamey: "Mamey",
        tangerine: "Mandarina",
        mango: "Mango",
        mangosteen: "Mangostán",
        chamomile: "Manzanilla",
        appleTree: "Manzano",
        passionFruit: "Maracuyá",
        daisyFlower: "Margarita",
        marjoram: "Mejorana",
        melon: "Melón",
        quince: "Membrillo",
        mint: "Menta",
        moringa: "Moringa",
        turnip: "Nabo",
        nanche: "Nanche",
        napa: "Napa",
        orange: "Naranja",
        medlar: "Níspero",
        nochebuena: "Nochebuena (planta)",
        walnut: "Nogal",
        noni: "Noni",
        nopal: "Nopal",
        oregano: "Orégano",
        ornamental: "Ornamentales",
        orchid: "Orquídea (planta)",
        oilPalm: "Palma africana o de aceite",
        potato: "Papa",
        papaya: "Papaya",
        grasslands: "Pastizales",
        matGrass: "Pasto tapete (m2)",
        pasturesAndMeadows: "Pastos y praderas",
        cucumber: "Pepino",
        pearTree: "Peral",
        parsley: "Perejil",
        peron: "Perón",
        persimony: "Persimonio",
        pepper: "Pimienta",
        pineapple: "Piña",
        pinion: "Piñón",
        pistache: "Pistache",
        pitaya: "Pitaya",
        banana: "Plátano",
        pore: "Poro",
        radish: "Rábano",
        rambutan: "Rambután",
        romerito: "Romerito",
        rosemary: "Romero",
        aloe: "Sábila",
        watermelon: "Sandía",
        sorghum: "Sorgo",
        soy: "Soya",
        stevia: "Stevia",
        tobacco: "Tabaco",
        tamarind: "Tamarindo",
        tejocote: "Tejocote",
        tomato: "Tomate Rojo (jitomate)",
        greenTomato: "Tomate Verde",
        thyme: "Tomillo",
        grapefruit: "Toronja (pomelo)",
        clover: "Trébol",
        wheat: "Trigo",
        dutchTulip: "Tulipán holandés",
        tuna: "Tuna",
        grape: "Uva",
        vanilla: "Vainilla",
        purslane: "Verdolaga",
        vine: "Vid",
        cassavaFood: "Yuca alimenticia",
        carrot: "Zanahoria",
        sapodilla: "Zapote",
        blackberry: "Zarzamora",
        zempoalxochitl: "Zempoalxochitl",
        fallow: "Barbecho",
        other: "Otro",
      };
      let cropName = crop[value];
      if (typeof cropName != "undefined") {
        template = cropName;
      } else {
        template = "Sin cultivo";
      }
      return template;
    },
    getStatus(value) {
      const status = {
        NEW: "Nuevo",
        PROPOSAL: "Propuesta",
        REJECTED: "Rechazado",
        ACCEPTED: "Aceptado",
        STARTED: "Iniciado",
        PAUSED: "Pausado",
        FINISHED: "Finalizado",
        CANCELED: "Cancelado",
        STRAINS_SUGGESTION: "Sugerencia de cepas",
      };
      return status[value];
    },
    closeResultDialog() {
      this.dialogT = false;
      this.urlPreview = "";
    },
    generatePreview() {},
    async showPreview(url) {
      this.dialogT = true;
      try {
        this.urlPreview = url;
      } catch (e) {
        console.log(e);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Algo salio mal, intente de nuevo !!",
          timeout: 5000,
        });
      }
    },
  },
  computed: {
    ...mapState(["cancelToken"]),
  },
  watch: {
    async page() {
      if (this.page != parseInt(this.$route.params.page)) {
        this.$router.replace({
          params: {
            page: this.page,
          },
        });
      }
      this.loadTable = true;
      await this.getProfitData();
      this.loadTable = false;
    },
    async search() {
      if (this.search && this.search.length > 0) {
        if (typeof this.cancelToken != "undefined") {
          this.cancelToken.cancel("Operation canceled due to new request.");
        }

        //Save the cancel token for the current request
        this.SET_CANCEL_TOKEN(axios.CancelToken.source());

        this.page = 1;

        if (this.page != parseInt(this.$route.params.page)) {
          this.$router.replace({
            params: {
              page: this.page,
            },
          });
        }

        this.receptionsList = [];

        this.loadingData = true;

        try {
          let response = await this.getProfitsFilter({
            laboratory: this.laboratory,
            page: this.page,
            search: this.search,
            status: this.state ? this.state.value : null,
          });

          if (response) {
            if (!response.error) {
              this.numPages = response.pages;
              this.data = response.profits;
            } else {
              throw response;
            }
          } else {
            throw response;
          }
        } catch (e) {
          console.log(e);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: e.message,
            timeout: 5000,
          });
        }

        this.loadingData = false;
      } else {
        this.loadTable = true;
        await this.getProfitData();
        this.loadTable = false;
      }
    },
    async state() {
      this.loadTable = true;
      await this.getProfitData();
      this.loadTable = false;
    },
  },
};
</script>
