// Guard
import { privateGuard } from "@/middlewares/guards";

// Layouts
import NavLayout from "../layouts/NavLayout.vue";

// Views
import Reception from "../views/Reception.vue";
import PQTest from "../views/PQTest.vue";
import MediaAndInoculation from "../views/MediaAndInoculation.vue";
import TracingSampleQPCR from "@/components/reception/plates/TracingSampleQPCR.vue";
import TracingSample from "@/components/reception/tracingSample/TracingSample.vue";

export default [
  {
    path: "/reception/:page/:tab",
    beforeEnter: privateGuard,
    component: NavLayout,
    children: [
      {
        path: "",
        name: "Reception",
        component: Reception,
        meta: {
          title: "Recepción",
        },
      },
    ],
  },
  {
    path: "/tracing-sample/:id",
    beforeEnter: privateGuard,
    component: NavLayout,
    children: [
      {
        path: "",
        name: "Muestra QPCR",
        component: TracingSampleQPCR,
        meta: {
          title: "Muestra QPCR",
        },
      },
    ],
  },
  {
    path: "/tracing/:id",
    beforeEnter: privateGuard,
    component: NavLayout,
    children: [
      {
        path: "",
        name: "Trazabilidad muestra",
        component: TracingSample,
        meta: {
          title: "Trazabilidad muestra",
        },
      },
    ],
  },
  {
    path: "/physicochemical-test/:analysis",
    beforeEnter: privateGuard,
    component: NavLayout,
    children: [
      {
        path: "",
        name: "Physicochemical Test",
        component: PQTest,
        meta: {
          title: "Parámetros fisicoquímicos",
        },
      },
    ],
  },
  {
    path: "/media-inoculation",
    beforeEnter: privateGuard,
    component: NavLayout,
    children: [
      {
        path: "",
        name: "Inoculation",
        component: MediaAndInoculation,
        meta: {
          title: "Medios de cultivo e inoculación",
        },
      },
    ],
  },
];
