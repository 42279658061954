import axios from "axios";

export async function getLevels(context, payload) {
  try {
    const { data } = await axios.get(`/levels`, {
      params: payload,
    });
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function createLevel(context, payload) {
  try {
    const { data } = await axios.post(`/level/add`, payload);
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function removeLevel(context, payload) {
  try {
    const { data } = await axios.delete(`/level/delete`, {
      params: payload,
    });
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function updateLevel(context, { payload, id }) {
  try {
    const { data } = await axios.put(`/level/update`, payload, {
      params: { levelId: id },
    });
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function addPreProcessFile({ commit }, payload) {
  try {
    const { data } = await axios.post("/level/add-preprocess-file", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (data.error) throw data;

    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function createMultipleLevel(context, payload) {
  try {
    const { data } = await axios.post(`/level/add-multiple`, payload);
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}