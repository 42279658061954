// Guard
import { privateGuard } from "@/middlewares/guards";

// Layouts
import NavLayout from "../layouts/NavLayout.vue";

// Views
import Sequencing from "../views/Sequencing.vue";
import SequencingReports from "../views/SequencingReports.vue";

export default [
    {
        path: "/sequencing-reports",
        beforeEnter: privateGuard,
        component: NavLayout,
        children: [
            {
                path: "",
                name: "Sequencing",
                component: SequencingReports,
                meta: {
                    title: "Reportes para secuenciación de muestras",
                },
            },
        ],
    },
    {
        path: "/sequencing/:id",
        beforeEnter: privateGuard,
        component: NavLayout,
        children: [
            {
                path: "",
                name: "Sequencing",
                component: Sequencing,
                meta: {
                    title: "Información de muestras",
                },
            },
        ],
    }
];
