var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table","types":{
          'table-thead': 'heading@4',
          'table-row': 'table-cell@4',
        }}}):_c('TestMedia')],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table","types":{
          'table-thead': 'heading@4',
          'table-row': 'table-cell@4',
        }}}):_c('MBTable')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }