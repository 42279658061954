<template>
  <v-dialog v-model="show" width="50vw" scrollable persistent>
    <v-card height="auto">
      <v-card-title class="text-h5 lighten-2"> Registro cepa I+D </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols="12" xl="6" lg="6">
                <v-autocomplete
                  outlined
                  dense
                  label="Selecciona tipo de muestra"
                  return-object
                  item-text="text"
                  :items="types"
                  v-model="type"
                  hide-no-data
                ></v-autocomplete>
              </v-col>
            </v-row>
            <div v-if="type">
              <v-row>
                <v-col>
                  <span class="text-subtitle-1 font-weight-bold">
                    Datos generales
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xl="6" lg="6">
                  <v-switch
                    label="Cepa proyecto"
                    v-model="isProject"
                    dense
                  ></v-switch>
                </v-col>
                <v-col cols="12" xl="6" lg="6" v-if="isProject">
                  <v-text-field
                    v-model="linkProject"
                    label="Enlace"
                    type="url"
                    :rules="validation('Enlace')"
                    outlined
                    required
                    dense
                    color="primary"
                    :append-icon="
                      miniatura
                        ? miniatura == 'doc'
                          ? 'mdi-file-excel-box'
                          : 'mdi-file-document'
                        : 'mdi-link'
                    "
                    @change="generarMiniatura"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xl="6" lg="6">
                  <v-text-field
                    dense
                    outlined
                    :rules="validation('ID')"
                    label="Ingresa ID cepa"
                    v-model="form.strainId"
                    type="text"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xl="6" lg="6">
                  <v-text-field
                    label="Origen"
                    outlined
                    dense
                    clearable
                    type="text"
                    v-model="form.companyName"
                    :rules="validation()"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  xl="6"
                  lg="6"
                  v-if="type.value == 'bacteria' || type.value == 'fungi'"
                >
                  <v-text-field
                    label="Fecha de inoculación"
                    outlined
                    dense
                    clearable
                    type="date"
                    v-model="form.inoculationDate"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  xl="6"
                  lg="6"
                  v-if="
                    type.value == 'bacteria' ||
                    type.value == 'fungi' ||
                    type.value == 'nematode'
                  "
                >
                  <v-text-field
                    label="Medio de cultivo"
                    outlined
                    dense
                    clearable
                    type="text"
                    v-model="form.cultureMedia"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  xl="6"
                  lg="6"
                  v-if="
                    type.value == 'bacteria' ||
                    type.value == 'fungi' ||
                    type.value == 'nematode'
                  "
                >
                  <v-combobox
                    :rules="validation()"
                    v-model="identification"
                    :search-input.sync="search"
                    :items="identificationsFounded"
                    :loading="isLoading"
                    @keydown="changeIdentificationValue"
                    label="Nombre microorganismo"
                    item-text="name"
                    clearable
                    hide-no-data
                    outlined
                    dense
                  >
                  </v-combobox>
                </v-col>
                <!-- <v-col
                  cols="12"
                  xl="6"
                  lg="6"
                  v-if="type.value == 'bacteria' || type.value == 'fungi'"
                >
                  <v-text-field
                    label="Número de cajas/tubos"
                    outlined
                    dense
                    clearable
                    type="text"
                    v-model="form.containerQty"
                  ></v-text-field>
                </v-col> -->
                <v-col
                  cols="12"
                  xl="6"
                  lg="6"
                  v-if="
                    type.value == 'bacteria' ||
                    type.value == 'fungi' ||
                    type.value == 'nematode'
                  "
                >
                  <v-radio-group
                    label="Confirmado/identificado"
                    v-model="form.identificationMedia"
                  >
                    <v-radio label="Microbiología" value="MB"></v-radio>
                    <v-radio label="Molecular" value="MOL"></v-radio>
                    <v-radio label="Otro" value="OTHER"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col>
                  <span class="text-subtitle-1 font-weight-bold">
                    Fotos evidencia
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xl="6" lg="6" md="6">
                  <v-file-input
                    v-model="filesMicroPhotos"
                    counter
                    multiple
                    show-size
                    accept="image/png, image/jpeg, image/bmp"
                    truncate-length="50"
                    label="Imágenes microscópicas"
                    prepend-icon="mdi-file"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip small label color="primary">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6">
                  <v-file-input
                    v-model="filesMacroPhotos"
                    counter
                    multiple
                    show-size
                    accept="image/png, image/jpeg, image/bmp"
                    truncate-length="50"
                    label="Imágenes macroscópicas"
                    prepend-icon="mdi-file"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip small label color="primary">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row> -->
              <v-row
                v-if="
                  type.value == 'bacteria' ||
                  type.value == 'fungi' ||
                  type.value == 'nematode'
                "
              >
                <v-col cols="12">
                  <span class="text-subtitle-1 font-weight-bold">
                    Confirmación de pureza
                  </span>
                </v-col>
                <v-col cols="12" xl="6" lg="6">
                  <v-text-field
                    label="Macroscópicamente axénica"
                    outlined
                    dense
                    clearable
                    type="text"
                    v-model="form.macroscopicallyAxenic"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xl="6" lg="6" v-if="type.value == 'bacteria'">
                  <v-checkbox
                    label="Colonias aisladas"
                    outlined
                    dense
                    clearable
                    type="text"
                    v-model="form.isolatedColonies"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" xl="6" lg="6" v-if="type.value == 'bacteria'">
                  <v-checkbox
                    label="Células/Gram"
                    outlined
                    dense
                    clearable
                    type="text"
                    v-model="form.cells_gram"
                  ></v-checkbox>
                </v-col>
                <v-col
                  cols="12"
                  xl="6"
                  lg="6"
                  v-if="type.value == 'bacteria' && form.cells_gram"
                >
                  <v-autocomplete
                    label="Selecciona tipo de gram"
                    outlined
                    dense
                    :items="['gram +', 'gram -']"
                    clearable
                    type="text"
                    v-model="form.cells_gram_type"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" xl="6" lg="6" v-if="type.value == 'fungi'">
                  <v-checkbox
                    label="Presencia de espora"
                    outlined
                    dense
                    clearable
                    type="text"
                    v-model="form.sporePresence"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" xl="6" lg="6" v-if="type.value == 'nematode'">
                  <v-checkbox
                    label="J2 viables"
                    outlined
                    dense
                    clearable
                    type="text"
                    v-model="form.j2"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="text-subtitle-1 font-weight-bold">
                    Datos cepario León
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xl="6" lg="6">
                  <v-text-field
                    label="Fecha de recepción"
                    outlined
                    dense
                    clearable
                    type="date"
                    v-model="form.receptionDate"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xl="6" lg="6">
                  <v-text-field
                    label="Número de tubos"
                    outlined
                    dense
                    clearable
                    type="text"
                    v-model="form.containerQtyCeparium"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xl="6" lg="6">
                  <v-text-field
                    label="Fecha preservación"
                    outlined
                    dense
                    clearable
                    type="date"
                    v-model="form.preservationDate"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xl="6" lg="6" v-if="type.value != 'nematode'">
                  <v-autocomplete
                    label="Metodo de preservación"
                    outlined
                    dense
                    clearable
                    v-model="form.preservationMethod"
                    :items="methods"
                    item-text="name"
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" xl="6" lg="6">
                  <v-switch
                    dense
                    v-model="form.viability"
                    label="Viabilidad"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="text-subtitle-1 font-weight-bold">
                    Datos de confirmación molecular
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xl="6" lg="6">
                  <v-text-field
                    label="Fecha de envio"
                    outlined
                    dense
                    clearable
                    type="date"
                    v-model="form.shippingDateMolecular"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xl="6" lg="6">
                  <v-combobox
                    label="Homología"
                    outlined
                    dense
                    clearable
                    item-text="name"
                    hide-no-data
                    v-model="identificationHomology"
                    :search-input.sync="searchHomology"
                    :items="identificationsFoundedHomology"
                    :loading="isLoadingHomology"
                    @keydown="changeIdentificationValueHomology"
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    filled
                    name="input-7-4"
                    label="Observaciones"
                    v-model="form.observations"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="text-subtitle-1 font-weight-bold">
                    Datos de CMA
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xl="6" lg="6">
                  <v-text-field
                    label="Fecha de recepción"
                    outlined
                    dense
                    clearable
                    type="date"
                    v-model="form.receptionCMA"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xl="6" lg="6">
                  <v-text-field
                    label="Número de tubos"
                    outlined
                    dense
                    clearable
                    type="text"
                    v-model="form.containerCMA"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xl="6" lg="6">
                  <v-text-field
                    label="Fecha preservación"
                    outlined
                    dense
                    clearable
                    type="date"
                    v-model="form.preservationDateCMA"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xl="6" lg="6" v-if="type.value != 'nematode'">
                  <v-autocomplete
                    label="Metodo de preservación"
                    outlined
                    dense
                    clearable
                    v-model="form.preservationMethodCMA"
                    :items="methods"
                    item-text="name"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xl="6" lg="6">
                  <v-text-field
                    label="Url validación de primers"
                    outlined
                    dense
                    clearable
                    type="text"
                    v-model="url"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-btn color="primary" @click="addURL"
                    ><v-icon>mdi-plus</v-icon>Agregar link</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-chip
                    class="ma-2"
                    close
                    v-for="(item, index) in form.validationDocuments"
                    :key="index"
                    @click:close="removeLink(index)"
                    @click="copyLink(item)"
                  >
                    {{ item }}
                  </v-chip>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col xl="4" lg="4" offset-xl="2" offset-lg="2">
              <v-btn
                dark
                color="gray"
                block
                @click="show = false"
                :disabled="isLoadingAdd"
              >
                Cerrar
              </v-btn>
            </v-col>
            <v-col xl="4" lg="4">
              <v-btn
                color="primary"
                block
                @click="addPreserved"
                :disabled="type ? false : true"
                :loading="isLoadingAdd"
              >
                Agregar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//libaries
import Compressor from "compressorjs";
//Vuex
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import { requiredValidation } from "@/helpers/validations.js";
export default {
  data: () => ({
    filesMicroPhotos: [],
    filesMacroPhotos: [],
    miniatura: null,
    isProject: false,
    linkProject: null,
    isLoading: false,
    samplesTypes: [
      {
        text: "Suelo",
        value: "SOIL",
      },
      {
        text: "Vegetal",
        value: "PLANT",
      },
    ],
    methods: [],
    type: null,
    identification: null,
    identificationHomology: null,
    url: null,
    form: {
      receptionDate: null,
      companyName: null,
      shippingWay: null,
      sampleType: null,
      suspectedDisease: null,
      inoculationDate: null,
      cultureMedia: null,
      identification: null,
      containerQty: null,
      identificationMedia: null,
      isolationMedia: null,
      macroscopicConformation: null,
      microscopicConformation: null,
      macroscopicallyAxenic: null,
      isolatedColonies: null,
      cells_gram: null,
      sporePresence: null,
      j2: null,
      containerQtyCeparium: null,
      shippingDateMolecular: null,
      homology: null,
      observations: null,
      validationDocuments: [],
      viability: false,
    },
    types: [
      {
        text: "Bacteria",
        value: "bacteria",
      },
      {
        text: "Hongo",
        value: "fungi",
      },
      {
        text: "Nemátodo",
        value: "nematode",
      },
      // {
      //   text: "Suelo/Vegetal",
      //   value: "sample",
      // },
    ],
    search: null,
    isLoadingAdd: false,
    searchHomology: null,
    isLoadingHomology: false,
    identificationsFoundedHomology: [],
  }),
  props: {
    value: Boolean,
  },
  computed: {
    ...mapState("identification", ["identificationsFounded"]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions("strain", ["uploadStrainPhoto"]),
    ...mapActions("ceparium", ["addPreservedID"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("identification", [
      "searchIdentifications",
      "addNematodeIdentification",
      "searchIdentificationsHomology",
    ]),
    validation(name) {
      return requiredValidation(name);
    },
    changeIdentificationValue() {
      if (!this.search) {
        this.identification = null;
      }
    },
    changeIdentificationValueHomology() {
      if (!this.searchHomology) {
        this.form.homology = null;
      }
    },
    async getIdentification() {
      try {
        if (typeof this.identification !== "object" && this.search) {
          // New.
          this.identification = {
            name: this.search,
            moType: this.type.value,
          };
          const response = await this.addNematodeIdentification(
            this.identification
          );
          if (response.error) throw response;
          return response.IDENTIFICATION;
        } else {
          // identification selected
          return this.identification;
        }
      } catch (e) {
        console.log("Error on getIdentificationId", e);
        return e;
      }
    },
    async getIdentificationHomology() {
      try {
        if (
          typeof this.identificationHomology !== "object" &&
          this.searchHomology
        ) {
          // New.
          this.identification = {
            name: this.searchHomology,
            moType: this.type.value,
          };
          const response = await this.addNematodeIdentification(
            this.identification
          );
          if (response.error) throw response;
          return response.IDENTIFICATION;
        } else {
          // identification selected
          return this.identificationHomology;
        }
      } catch (e) {
        console.log("Error on getIdentificationId", e);
        return e;
      }
    },
    async addPreserved() {
      if (this.$refs.form.validate()) {
        try {
          this.isLoadingAdd = true;
          // Set compression options according to your needs
          let imagesMacro = [];
          let imagesMicro = [];
          const options = {
            quality: 0.6,
            maxWidth: 800,
            maxHeight: 800,
          };
          for (let file of this.filesMicroPhotos) {
            // Create a promise to handle asynchronous compression
            const compressedBlob = await new Promise((resolve, reject) => {
              new Compressor(file, {
                ...options,
                success: resolve,
                error: reject,
              });
            });
            let form_data = new FormData();
            form_data.append("file", compressedBlob);
            const { urlImg } = await this.uploadStrainPhoto(form_data);
            imagesMicro.push({
              img: urlImg,
              date: new Date(),
              notes: "",
            });
          }
          for (let file of this.filesMacroPhotos) {
            // Create a promise to handle asynchronous compression
            const compressedBlob = await new Promise((resolve, reject) => {
              new Compressor(file, {
                ...options,
                success: resolve,
                error: reject,
              });
            });
            let form_data = new FormData();
            form_data.append("file", compressedBlob);
            const { urlImg } = await this.uploadStrainPhoto(form_data);
            imagesMacro.push({
              img: urlImg,
              date: new Date(),
              notes: "",
            });
          }
          //add new nematode
          let identification = await this.getIdentification();
          this.form.identification = identification._id;
          if (this.identificationHomology) {
            let identificationHomology = await this.getIdentificationHomology();
            this.form.homology = identificationHomology._id;
          }
          this.form.identificationName = identification.name;
          this.form.imagesMacro = imagesMacro;
          this.form.imagesMicro = imagesMicro;

          if (this.isProject) {
            this.form.urlProject = this.linkProject;
          }
          this.form.identificationsHistory = [
            {
              name: identification.name,
              identification: identification._id,
            },
          ];
          this.form.type = "ID";
          this.form.preservedType = this.type.value;
          this.form.moType = this.type.value;
          let response = await this.addPreservedID(this.form);
          if (!response) throw response;
          this.$emit("fetchData");
          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: "Preservada correctamente",
            timeout: 3500,
          });
          this.show = false;
        } catch (error) {
          console.log(error);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: "Error al crear preservada, contacta a soporte.",
            timeout: 3500,
          });
        }
        this.isLoadingAdd = false;
      }
    },
    generarMiniatura() {
      if (this.linkProject) {
        if (this.linkProject.includes(".xlsx")) {
          this.miniatura = "doc";
        } else if (this.linkProject.includes("https://docs")) {
          this.miniatura = "doc";
        } else {
          this.miniatura = "other";
        }
      } else {
        this.miniatura = null;
      }
    },
    dataURLtoFile(img) {
      let arr = img.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], "evidencePhoto.png", { type: mime });
    },
    addURL() {
      if (this.url.length != 0) {
        this.form.validationDocuments.push(this.url);
        this.url = null;
      }
    },
    removeLink(index) {
      this.form.validationDocuments.splice(index, 1);
    },
    copyLink(item) {
      const textToCopy = item; // Define the text you want to copy
      // Create a temporary textarea element
      const tempTextarea = document.createElement("textarea");
      tempTextarea.value = textToCopy;
      document.body.appendChild(tempTextarea);

      // Select the text in the temporary element
      tempTextarea.select();
      tempTextarea.setSelectionRange(0, 99999); // For mobile devices

      // Attempt to copy the selected text to the clipboard
      document.execCommand("copy");

      // Remove the temporary element
      document.body.removeChild(tempTextarea);

      // Show a message or perform any other action after copying
      this.SET_ALERT_TYPE({
        visible: true,
        type: "success",
        text: "¡Texto copiado al portapapeles!",
        timeout: 3500,
      });
    },
  },
  async mounted() {},
  watch: {
    async search(value) {
      if (value) {
        this.isLoading = true;
        try {
          const response = await this.searchIdentifications({
            name: value,
            moType: this.type.value,
          });
          if (response.error) throw response;
        } catch (e) {
          console.log(e);
        }
        this.isLoading = false;
        // Validate error text
        if (this.errorText && this.search) {
          this.errorText = false;
        }
      }
    },
    type() {
      // if (this.type.value == "bacteria") {
      this.methods = [
        { name: "Microtubo", value: "MICROTUBE" },
        { name: "Tubo inclinado", value: "INCLINED_TUBE" },
        { name: "Discos de agr", value: "AGAR_DISCS" },
      ];
      // } else if (this.type.value == "fungi") {
      //   this.methods = [
      //     { name: "Tubo inclinado", value: "INCLINED_TUBE" },
      //     { name: "Discos de agr", value: "AGAR_DISCS" },
      //   ];
      // }
    },
    async searchHomology(value) {
      if (value) {
        this.isLoadingHomology = true;
        try {
          const response = await this.searchIdentificationsHomology({
            name: value,
            moType: this.type.value,
          });
          if (response.error) throw response;

          this.identificationsFoundedHomology = response.IDENTIFICATIONS;
        } catch (e) {
          console.log(e);
        }
        this.isLoadingHomology = false;
      }
    },
  },
};
</script>
