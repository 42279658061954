import axios from "axios";

export async function getPrescriptions(context, payload) {
  try {
    const { data } = await axios.get(`/prescription`, {
      params: payload,
    });
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function createProduct(context, payload) {
  try {
    const { data } = await axios.post(`/prescription/add`, payload);
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}


export async function updateProduct(context, { payload, id }) {
  try {
    const { data } = await axios.put(`/prescription/update`, payload, {
      params: { prescriptionId: id },
    });
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function removeProduct(context, payload) {
  try {
    const { data } = await axios.delete(`/prescription/delete`, {
      params: payload,
    });
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}


export async function addPreProcessFile({ commit }, payload) {
  try {
    const { data } = await axios.post("/prescription/add-preprocess-file", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (data.error) throw data;

    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}


export async function createMultipleLevel(context, payload) {
  try {
    const { data } = await axios.post(`/prescription/add-multiple`, payload);
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}