<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="3" md="3" class="py-1">
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          v-model="search"
          clearable
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="2" md="2" class="py-1">
        <v-dialog v-model="filterDialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" rounded small
              ><v-icon>mdi-filter-multiple</v-icon></v-btn
            >
          </template>

          <v-card>
            <v-card-title> Filtros </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <span> Laboratorio </span>
                    <v-autocomplete
                      v-model="labFound"
                      :items="laboratories"
                      label="Buscar..."
                      hide-no-data
                      solo
                      dense
                      item-text="name"
                      clearable
                      return-object
                      prepend-inner-icon="mdi-magnify"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span> Cultivo </span>
                    <v-autocomplete
                      v-model="form.cropCycle"
                      :items="crop_items"
                      label="Seleccione un cultivo"
                      no-data-text="No se encontró coincidencias"
                      solo
                      dense
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span> Tipò de muestra </span>
                    <v-autocomplete
                      v-model="form.sampleType"
                      :items="sampleType"
                      label="Seleccione tipo de muestra"
                      solo
                      no-data-text="No se encontró coincidencias"
                      dense
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span> Tipo de análisis </span>
                    <v-autocomplete
                      v-model="form.analysisType"
                      :items="analysisType"
                      label="Seleccione tipo de análisis"
                      no-data-text="No se encontró coincidencias"
                      solo
                      dense
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span> Productor</span>
                    <v-autocomplete
                      v-model="form.organization"
                      :items="organizations"
                      :search-input.sync="searchOrganization"
                      label="Seleccione productor"
                      item-text="companyName"
                      no-data-text="No se encontró coincidencias"
                      solo
                      dense
                      return-object
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <span>Fecha preservación</span>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="dates"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-combobox
                          v-model="dates"
                          multiple
                          chips
                          small-chips
                          label="Selecciona rango de fechas"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          clearable
                          solo
                          dense
                        ></v-combobox>
                      </template>
                      <v-date-picker
                        v-model="dates"
                        multiple
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="handleSave"
                          :disabled="dates.length !== 2"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="gray" dark @click="filterDialog = false">
                Cancelar
              </v-btn>
              <v-btn color="info" @click="cleanFilters">
                Limpiar filtros
              </v-btn>
              <v-btn color="primary" @click="applyFilters"> Aplicar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn color="primary" rounded small class="ml-1" @click="fetchData">
          <v-icon> mdi-refresh </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          width="100%"
          height="600"
          :headers="headers"
          :items="samples"
          :items-per-page="10"
          fixed-header
          no-data-text="No hay muestras preservadas"
          loading-text="Cargando muestras preservadas..."
          :page.sync="page"
          hide-default-footer
          :loading="fetchingData"
        >
          <template #[`item.kit_type`]="{ item }">
            <span class="text-caption" v-if="item.kit_type">{{
              getKitType(item.kit_type)
            }}</span>
            <span class="text-caption" v-else>N.E.</span>
          </template>
          <template #[`item.crop`]="{ item }">
            <v-icon v-if="item.crop" color="primary">mdi-sprout-outline</v-icon>
            <v-icon v-else color="gray">mdi-sprout-outline</v-icon>
            {{ getCrop(item.crop) }}
          </template>

          <template #[`item.preservationDate`]="{ item }">
            <span v-if="item.preservationDate">
              <div v-if="item.discardPreservationDate">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" style="color: red">
                      {{ getDateFormatted(item.preservationDate) }}
                    </span>
                  </template>
                  <span
                    >Descartada
                    {{ getDateFormatted(item.discardPreservationDate) }}</span
                  >
                </v-tooltip>
              </div>
              <div v-else>
                {{ getDateFormatted(item.preservationDate) }}
              </div>
            </span>
            <span v-else> N.E </span>
          </template>
          <template #[`item.temperature`]="{ item }">
            <span v-if="item.temperature">
              {{ item.temperature }}
            </span>
            <span v-else> N.E </span>
          </template>
          <template #[`item.sample_type`]="{ item }">
            {{ getSampleType(item.sample_type) }}
          </template>
          <template #[`item.analysis_type`]="{ item }">
            {{ getAnalysisType(item.analysis_type) }}
          </template>
          <template #[`item.action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  text
                  v-bind="attrs"
                  v-on="on"
                  @click="deletePreservation(item)"
                >
                  <v-icon v-if="item.discardPreservationDate" color="primary">
                    mdi-restore
                  </v-icon>
                  <v-icon v-else color="red"> mdi-delete </v-icon>
                </v-btn>
              </template>

              <span v-if="item.discardPreservationDate"
                >Restaurar preservación</span
              >
              <span v-else>Descartar preservación</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-pagination
          v-model="page"
          :length="numPages"
          total-visible="10"
          color="primary"
        ></v-pagination>
      </v-col>
    </v-row>
    <v-row class="text-center" v-if="showFetchingDataButton">
      <v-col>
        <v-btn color="primary" rounded @click="fetchData"
          ><v-icon>mdi-refresh</v-icon></v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//Vuex
import { mapActions, mapState, mapMutations } from "vuex";
//Libraries
import dayjs from "dayjs";
//axios
import axios from "axios";
export default {
  data: () => ({
    headers: [
      { text: "Productor", value: "client_name", sortable: false },
      { text: "Folio", value: "folio", sortable: false },
      { text: "Tipo de kit", value: "kit_type", sortable: false },
      { text: "Tipo de análisis", value: "analysis_type", sortable: false },
      { text: "Tipo de muestra", value: "sample_type", sortable: false },
      { text: "Cultivo", value: "crop", sortable: false },
      { text: "Temperatura", value: "temperature", sortable: false },
      {
        text: "Lugar de procedencia",
        value: "samplingPlace",
        sortable: false,
      },
      {
        text: "Forma de presentación",
        value: "preservationType",
        sortable: false,
      },
      {
        text: "Fecha de preservación",
        value: "preservationDate",
        sortable: false,
      },
      {
        text: "Acción",
        value: "action",
        sortable: false,
      },
    ],
    samples: [],
    fetchingData: false,
    page: 1,
    numPages: 0,
    search: null,
    showFetchingDataButton: false,
    filterDialog: false,
    crop_items: [],
    sampleType: [
      {
        text: "Suelo compuesto",
        value: "CLASSIC",
      },
      {
        text: "Vegetal",
        value: "MAS_VEGETABLE",
      },
      {
        text: "Vegetal sin georeferencia",
        value: "VEGETABLE",
      },
      {
        text: "Composta",
        value: "COMPOST",
      },
      {
        text: "Producto",
        value: "PRODUCT",
      },
      {
        text: "Sustrato",
        value: "SUBSTRATUM",
      },
      {
        text: "Suelo sin asesor",
        value: "NO_ADVISOR",
      },
      {
        text: "Suelo compuesto",
        value: "CLASSIC",
      },
    ],
    analysisType: [
      {
        text: "Inicial",
        value: "INITIAL",
      },
      {
        text: "Seguimiento",
        value: "MONITORING",
      },
    ],
    form: {
      cropCycle: null,
      sampleType: null,
      analysisType: null,
      organization: null,
    },
    user: JSON.parse(localStorage.getItem("user")),
    dates: [],
    menu: false,
    organizations: [],
    kitTypes: [
      {
        text: "Tradiciónal",
        value: "CLASSIC",
      },
      {
        text: "Híbrido",
        value: "HYBRID",
      },
      {
        text: "QPCR",
        value: "QPCR",
      },
      {
        text: "Otro",
        value: "OTHER",
      },
    ],
    searchOrganization: null,
    laboratories: [],
    labFound: null,
  }),
  async mounted() {
    this.setCropItems();
    await this.fetchData();
    await this.loadLabs();
  },
  computed: {
    ...mapState("reception", ["crops"]),
    ...mapState(["cancelToken"]),
  },
  methods: {
    ...mapMutations(["SET_CANCEL_TOKEN"]),
    ...mapActions("reception", [
      "getPreservedSoil",
      "getFarms",
      "discardSamplePreserved",
    ]),
    ...mapActions("laboratory", ["getLaboratories"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async fetchData() {
      this.fetchingData = true;
      try {
        if (typeof this.cancelToken != "undefined") {
          this.cancelToken.cancel("Operation canceled due to new request.");
        }
        //Save the cancel token for the current request
        this.SET_CANCEL_TOKEN(axios.CancelToken.source());
        let payload = {
          page: this.page,
          search: this.search,
          laboratory: this.user.laboratory._id,
          filters: this.form,
        };
        if (this.labFound) {
          payload.laboratory = this.labFound._id;
        }
        let response = await this.getPreservedSoil(payload);
        if (response) {
          if (!response.error) {
            //hide loaders
            this.fetchingData = false;
            this.showFetchingDataButton = false;
            //set sample data
            this.samples = response.samples;
            this.numPages = response.pages;
            console.log(response);
          } else {
            throw response;
          }
        }
      } catch (e) {
        //print log
        console.log(e);
        //hide loaders
        this.fetchingData = false;
        //show attempt button to try fetch data manually
        this.showFetchingDataButton = true;
        //show error to user
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Algo salio mal cargar muestras preservadas, intente de nuevo !!",
          timeout: 5000,
        });
      }
    },
    getStatus(value) {
      let status = {
        IN_PROGRESS: "En proceso",
        VALIDATED: "Finalizado",
      };
      return status[value];
    },
    setCropItems() {
      let cropsArray = Object.entries(this.crops);
      for (let crop of cropsArray) {
        this.crop_items.push({
          text: crop[1],
          value: crop[0],
        });
      }
    },
    getCrop(value) {
      let template = "";
      const crop = {
        olive: "Aceituna",
        chard: "Acelga",
        achiote: "Achiote",
        agave: "Agave",
        avocado: "Aguacate",
        garlic: "Ajo",
        sesame: "Ajonjolí",
        basil: "Albahaca",
        artichoke: "Alcachofa",
        alcatraz: "Alcatraz",
        shrunkenAlfalfa: "Alfalfa achicalada",
        greenAlfalfa: "Alfalfa verde",
        cotton: "Algodón",
        almond: "Almendro",
        birdseed: "Alpiste",
        amaranth: "Amaranto",
        anise: "Anís",
        celery: "Apio",
        blueberry: "Arándano",
        myrtle: "Arrayán",
        rice: "Arroz",
        greenForageOats: "Avena forrajera en verde",
        oatGrain: "Avena grano",
        bamboo: "Bambú",
        eggplant: "Berenjena",
        beetroot: "Betabel",
        broccoli: "Brocoli",
        peanut: "Cacahuate",
        cocoa: "Cacao",
        cherryCoffee: "Café cereza",
        pumpkin: "Calabaza",
        sweetPotato: "Camote",
        sugarCane: "Caña de azúcar",
        canola: "Canola",
        carambolo: "Carambolo",
        safflower: "Cártamo",
        barley: "Cebada",
        greenForageBarley: "Cebada forrajera en verde",
        onion: "Cebolla",
        chives: "Cebollín",
        ryeGrain: "Centeno grano",
        cherry: "Cereza",
        chabacano: "Chabacano",
        pea: "Chícharo",
        squash: "Chayote",
        chia: "Chía",
        chilacayote: "Chilacayote",
        chillyPepper: "Chile / Pimiento",
        custardApple: "Chirimoya",
        coriander: "Cilantro",
        plum: "Ciruela",
        coconutFruit: "Coco fruta",
        cabbage: "Col (repollo)",
        brusselsSprouts: "Col de Bruselas",
        cauliflower: "Coliflor",
        cumin: "Comino",
        chrysanthemum: "Crisantemo (planta)",
        peach: "Durazno",
        date: "Dátil",
        greenBean: "Ejote",
        cornCob: "Elote",
        dill: "Eneldo",
        epazote: "Epazote",
        asparagus: "Espárrago",
        spicesAndMedicinals: "Especias y medicinales",
        spinach: "Espinaca",
        raspberry: "Frambuesa",
        strawberry: "Fresa",
        bean: "Frijol",
        chickpea: "Garbanzo",
        sunflower: "Girasol",
        pomegranate: "Granada",
        soursop: "Guanábana",
        guava: "Guayaba",
        henequen: "Henequén",
        peppermint: "Hierbabuena",
        aromaticHerbs: "Hierbas aromáticas",
        fig: "Higo",
        jamaica: "Jamaica",
        ginger: "Jenjibre",
        jicama: "Jícama",
        lettuce: "Lechuga",
        lentil: "Lenteja",
        lime: "Lima",
        lemon: "Limón",
        linseed: "Linaza",
        litchi: "Litchi",
        corn: "Maíz",
        macadamia: "Macadamia",
        maguey: "Maguey",
        mamey: "Mamey",
        tangerine: "Mandarina",
        mango: "Mango",
        mangosteen: "Mangostán",
        chamomile: "Manzanilla",
        appleTree: "Manzano",
        passionFruit: "Maracuyá",
        daisyFlower: "Margarita",
        marjoram: "Mejorana",
        melon: "Melón",
        quince: "Membrillo",
        mint: "Menta",
        moringa: "Moringa",
        turnip: "Nabo",
        nanche: "Nanche",
        napa: "Napa",
        orange: "Naranja",
        medlar: "Níspero",
        nochebuena: "Nochebuena (planta)",
        walnut: "Nogal",
        noni: "Noni",
        nopal: "Nopal",
        oregano: "Orégano",
        ornamental: "Ornamentales",
        orchid: "Orquídea (planta)",
        oilPalm: "Palma africana o de aceite",
        potato: "Papa",
        papaya: "Papaya",
        grasslands: "Pastizales",
        matGrass: "Pasto tapete (m2)",
        pasturesAndMeadows: "Pastos y praderas",
        cucumber: "Pepino",
        pearTree: "Peral",
        parsley: "Perejil",
        peron: "Perón",
        persimony: "Persimonio",
        pepper: "Pimienta",
        pineapple: "Piña",
        pinion: "Piñón",
        pistache: "Pistache",
        pitaya: "Pitaya",
        banana: "Plátano",
        pore: "Poro",
        radish: "Rábano",
        rambutan: "Rambután",
        romerito: "Romerito",
        rosemary: "Romero",
        aloe: "Sábila",
        watermelon: "Sandía",
        sorghum: "Sorgo",
        soy: "Soya",
        stevia: "Stevia",
        tobacco: "Tabaco",
        tamarind: "Tamarindo",
        tejocote: "Tejocote",
        tomato: "Tomate Rojo (jitomate)",
        greenTomato: "Tomate Verde",
        thyme: "Tomillo",
        grapefruit: "Toronja (pomelo)",
        clover: "Trébol",
        wheat: "Trigo",
        dutchTulip: "Tulipán holandés",
        tuna: "Tuna",
        grape: "Uva",
        vanilla: "Vainilla",
        purslane: "Verdolaga",
        vine: "Vid",
        cassavaFood: "Yuca alimenticia",
        carrot: "Zanahoria",
        sapodilla: "Zapote",
        blackberry: "Zarzamora",
        zempoalxochitl: "Zempoalxochitl",
        fallow: "Barbecho",
        other: "Otro",
      };
      let cropName = crop[value];
      if (cropName) {
        template = cropName;
      } else {
        template = "Sin cultivo";
      }
      return template;
    },
    getDateFormatted(value) {
      const date = `${dayjs(value).format("MM/DD/YYYY")}`;
      return date ? date : "----";
    },
    getSampleType(value) {
      const types = {
        CLASSIC: "Suelo compuesto",
        MAS: "Muestra para genómica",
        MAS_VEGETABLE: "Vegetal",
        VEGETABLE: "Vegetal sin georeferencia",
        COMPOST: "Composta",
        PRODUCT: "Producto",
        SUBSTRATUM: "Sustrato",
        NO_ADVISOR: "Suelo sin asesor",
      };
      if (value.type) {
        let temp = types[value.type];
        if (value.type == "COMPOST" || value.type == "PRODUCT") {
          if (value.composition) {
            let compositions = {
              LIQUID: "Líquido",
              SOLID: "Sólido",
            };
            temp += ` ${compositions[value.composition]}`;
          }
        }
        return temp;
      } else {
        return "N/A";
      }
    },
    getAnalysisType(value) {
      if (value == "INITIAL" || value == "Initial") {
        return "Inicial";
      } else {
        return "Seguimiento";
      }
    },
    async applyFilters() {
      this.form.dates = this.dates;
      this.filterDialog = false;
      this.page = 1;
      await this.fetchData();
    },
    async cleanFilters() {
      this.laboratories = [];
      this.labFound = null;
      this.search = null;
      this.filterDialog = false;
      this.form = {
        cropCycle: null,
        sampleType: null,
        analysisType: null,
        organization: null,
      };
      await this.fetchData();
    },
    getKitType(value) {
      let types = {
        CLASSIC: "Tradiciónal",
        HYBRID: "Híbrido",
        QPCR: "QPCR",
        OTHER: "Otro",
      };
      let type = types[value];
      if (type) {
        return type;
      } else {
        return "N.E.";
      }
    },
    handleSave() {
      if (this.dates.length === 2) {
        this.$refs.menu.save(this.dates);
      }
    },
    async deletePreservation(item) {
      try {
        if (item.discardPreservationDate) {
          let payload = {
            sampleId: item._id,
            discard: false,
          };
          let response = await this.discardSamplePreserved(payload);
          if (response.error) throw response;
          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: "Preservada restaurada",
            timeout: 3000,
          });
          await this.fetchData();
        } else {
          let payload = {
            sampleId: item._id,
            discard: true,
          };
          let response = await this.discardSamplePreserved(payload);
          if (response.error) throw response;
          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: "Preservada descartada",
            timeout: 3000,
          });
          await this.fetchData();
        }
      } catch (error) {
        //show error to user
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Algo salio mal descartar preservada, intente de nuevo !!",
          timeout: 5000,
        });
      }
    },
    async loadLabs() {
      try {
        let response = await this.getLaboratories({
          $or: [
            {
              type: "MICROBIOLOGICAL/MOLECULAR",
            },
            {
              type: "MICROBIOLOGICAL",
            },
            {
              type: "MOLECULAR",
            },
          ],
        });
        this.laboratories = response.laboratories;
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    async search() {
      this.page = 1;
      await this.fetchData();
    },
    async page() {
      await this.fetchData();
    },
    async searchOrganization(value) {
      if (value) {
        try {
          let payload = {
            page: 1,
            search: value,
          };
          let response = await this.getFarms(payload);
          if (response.error) throw response;
          this.organizations = response.famrs;
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style scoped></style>
