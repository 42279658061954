<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="850"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn text color="secondary" v-bind="attrs" v-on="on"
        >Panel de control</v-btn
      >
    </template>

    <v-card>
      <v-toolbar color="secondary" dark>Panel de control</v-toolbar>
      <v-card-text>
        <v-container>
          <v-row justify="center">
            <v-col cols="6" offset="4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="text-center pt-6">
              <v-dialog
                v-model="dialog2"
                transition="dialog-bottom-transition"
                max-width="550"
                scrollable
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                    class="pt-1"
                    small
                    ><v-icon>mdi-plus</v-icon></v-btn
                  >
                </template>

                <v-card>
                  <v-toolbar color="secondary" dark
                    >Nueva relación ( identificación - reacción - cultivo
                    )</v-toolbar
                  >
                  <v-card-text class="pb-0">
                    <v-container>
                      <v-row justify="center" align="center">
                        <v-col cols="8">
                          <v-autocomplete
                            v-model="newIdentificationReaction.identification"
                            :search-input.sync="searchIdentification"
                            :items="identificationsFounded"
                            :loading="isLoading"
                            @keydown="changeIdentificationValue"
                            label="Identificación"
                            placeholder="Identificación"
                            item-text="name"
                            return-object
                            dense
                            hide-details
                            no-data-text="No hay identificaciones"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="1">
                          <v-dialog
                            v-model="dialog3"
                            persistent
                            max-width="600px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="secondary"
                                icon
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>mdi-database-plus</v-icon>
                              </v-btn>
                            </template>
                            <v-card>
                              <v-card-title>
                                <span class="text-h5"
                                  >Nueva identificación</span
                                >
                              </v-card-title>
                              <v-card-text>
                                <v-form ref="form" lazy-validation>
                                  <v-container>
                                    <v-row justify="center" align="center">
                                      <v-col cols="6" sm="10" md="6">
                                        <v-text-field
                                          v-model="newIdentificationForm.name"
                                          label="Nombre"
                                          :rules="inputRules"
                                          required
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="4" sm="10" md="6">
                                        <v-autocomplete
                                          v-model="newIdentificationForm.moType"
                                          :items="moTypes"
                                          label="Clasificación"
                                          item-text="spanish"
                                          return-object
                                        ></v-autocomplete>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-form>
                              </v-card-text>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="warning darken-1"
                                  text
                                  @click="dialog3 = false"
                                >
                                  Cancelar
                                </v-btn>
                                <v-btn
                                  color="primary darken-1"
                                  text
                                  @click="saveIdentification"
                                >
                                  Guardar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-col>
                        <v-col cols="5">
                          <v-switch
                            v-model="newIdentificationReaction.threat"
                            color="warning"
                            label="Patógeno"
                          ></v-switch>
                        </v-col>
                        <v-col cols="5">
                          <v-switch
                            v-model="
                              newIdentificationReaction.vegetableMaterial
                            "
                            color="secondary"
                            label="Material vegetal"
                          ></v-switch>
                        </v-col>
                        <v-col cols="8">
                          <v-autocomplete
                            v-model="newIdentificationReaction.crops"
                            :items="crops"
                            label="Cultivo/s"
                            item-text="spanish"
                            return-object
                            hide-details
                            multiple
                            clearable
                            chips
                            deletable-chips
                            auto-select-first
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model.number="newIdentificationReaction.reaction"
                            label="Número de reacción"
                            type="number"
                            hide-details
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="dialog2 = false">Cancelar</v-btn>
                    <v-btn
                      text
                      @click="saveNewRelation"
                      color="primary"
                      :disabled="
                        !newIdentificationReaction.identification ||
                        !newIdentificationReaction.reaction
                      "
                      >Guardar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="12">
              <v-data-table
                height="50vh"
                group-by="reaction"
                fixed-header
                dense
                hide-default-footer
                :headers="headers"
                :items="relations"
                :search="search"
                :items-per-page="500"
              >
              <template v-slot:[`group.header`]="{items, isOpen, toggle}">
                <th colspan="5">
                  <v-icon @click="toggle"
                    >{{ isOpen ? 'mdi-minus' : 'mdi-chevron-down' }}
                  </v-icon>
                  Reacción: {{ items[0].reaction }}
                </th>
              </template>
                <template v-slot:[`item.threat`]="{ item }">
                  <span v-if="item.threat" class="warning--text">Patógeno</span>
                  <span v-else class="primary--text">Benéfico</span>
                </template>
                <template v-slot:[`item.vegetableMaterial`]="{ item }">
                  <span v-if="item.vegetableMaterial">✅</span>
                  <span v-else class="primary--text">❌</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      dialog2: false,
      dialog3: false,
      isLoading: false,
      inputRules: [(v) => !!v || "El campo es requerido"],
      search: "",
      searchIdentification: "",
      headers: [
        {
          text: "Identificación",
          value: "identification",
        },
        { text: "Ben. / Pat.", value: "threat" },
        { text: "# Reacción", value: "reaction" },
        { text: "Cultivo/s", value: "spanishCrops" },
        { text: "M. Vegetal", value: "vegetableMaterial" },
        { text: "", value: "tools", sortable: false },
      ],
      relations: [],
      newIdentificationReaction: {
        identification: null,
        threat: false,
        vegetableMaterial: false,
        reaction: 1,
        crops: [],
      },
      newIdentificationForm: {
        name: "",
        moType: {
          spanish: "Bacteria",
          english: "bacteria",
        },
      },
      identifications: [],
      crops: [
        { english: "olive", spanish: "Aceituna" },
        { english: "chard", spanish: "Acelga" },
        { english: "achiote", spanish: "Achiote" },
        { english: "agave", spanish: "Agave" },
        { english: "avocado", spanish: "Aguacate" },
        { english: "garlic", spanish: "Ajo" },
        { english: "sesame", spanish: "Ajonjolí" },
        { english: "basil", spanish: "Albahaca" },
        { english: "artichoke", spanish: "Alcachofa" },
        { english: "alcatraz", spanish: "Alcatraz" },
        { english: "shrunkenAlfalfa", spanish: "Alfalfa achicalada" },
        { english: "greenAlfalfa", spanish: "Alfalfa verde" },
        { english: "cotton", spanish: "Algodón" },
        { english: "almond", spanish: "Almendro" },
        { english: "birdseed", spanish: "Alpiste" },
        { english: "amaranth", spanish: "Amaranto" },
        { english: "anise", spanish: "Anís" },
        { english: "celery", spanish: "Apio" },
        { english: "blueberry", spanish: "Arándano" },
        { english: "myrtle", spanish: "Arrayán" },
        { english: "rice", spanish: "Arroz" },
        { english: "greenForageOats", spanish: "Avena forrajera en verde" },
        { english: "oatGrain", spanish: "Avena grano" },
        { english: "bamboo", spanish: "Bambú" },
        { english: "eggplant", spanish: "Berenjena" },
        { english: "beetroot", spanish: "Betabel" },
        { english: "broccoli", spanish: "Brocoli" },
        { english: "peanut", spanish: "Cacahuate" },
        { english: "cocoa", spanish: "Cacao" },
        { english: "cherryCoffee", spanish: "Café cereza" },
        { english: "pumpkin", spanish: "Calabaza" },
        { english: "sweetPotato", spanish: "Camote" },
        { english: "sugarCane", spanish: "Caña de azúcar" },
        { english: "canola", spanish: "Canola" },
        { english: "carambolo", spanish: "Carambolo" },
        { english: "safflower", spanish: "Cártamo" },
        { english: "barley", spanish: "Cebada" },
        { english: "greenForageBarley", spanish: "Cebada forrajera en verde" },
        { english: "onion", spanish: "Cebolla" },
        { english: "chives", spanish: "Cebollín" },
        { english: "ryeGrain", spanish: "Centeno grano" },
        { english: "cherry", spanish: "Cereza" },
        { english: "chabacano", spanish: "Chabacano" },
        { english: "pea", spanish: "Chícharo" },
        { english: "squash", spanish: "Chayote" },
        { english: "chia", spanish: "Chía" },
        { english: "chilacayote", spanish: "Chilacayote" },
        { english: "chillyPepper", spanish: "Chile / Pimiento" },
        { english: "custardApple", spanish: "Chirimoya" },
        { english: "coriander", spanish: "Cilantro" },
        { english: "plum", spanish: "Ciruela" },
        { english: "coconutFruit", spanish: "Coco fruta" },
        { english: "cabbage", spanish: "Col (repollo)" },
        { english: "brusselsSprouts", spanish: "Col de Bruselas" },
        { english: "cauliflower", spanish: "Coliflor" },
        { english: "cumin", spanish: "Comino" },
        { english: "chrysanthemum", spanish: "Crisantemo (planta)" },
        { english: "peach", spanish: "Durazno" },
        { english: "date", spanish: "Dátil" },
        { english: "greenBean", spanish: "Ejote" },
        { english: "cornCob", spanish: "Elote" },
        { english: "dill", spanish: "Eneldo" },
        { english: "epazote", spanish: "Epazote" },
        { english: "asparagus", spanish: "Espárrago" },
        { english: "spicesAndMedicinals", spanish: "Especias y medicinales" },
        { english: "spinach", spanish: "Espinaca" },
        { english: "raspberry", spanish: "Frambuesa" },
        { english: "strawberry", spanish: "Fresa" },
        { english: "bean", spanish: "Frijol" },
        { english: "chickpea", spanish: "Garbanzo" },
        { english: "sunflower", spanish: "Girasol" },
        { english: "pomegranate", spanish: "Granada" },
        { english: "soursop", spanish: "Guanábana" },
        { english: "guava", spanish: "Guayaba" },
        { english: "henequen", spanish: "Henequén" },
        { english: "peppermint", spanish: "Hierbabuena" },
        { english: "aromaticHerbs", spanish: "Hierbas aromáticas" },
        { english: "fig", spanish: "Higo" },
        { english: "jamaica", spanish: "Jamaica" },
        { english: "ginger", spanish: "Jenjibre" },
        { english: "jicama", spanish: "Jícama" },
        { english: "lettuce", spanish: "Lechuga" },
        { english: "lentil", spanish: "Lenteja" },
        { english: "lime", spanish: "Lima" },
        { english: "lemon", spanish: "Limón" },
        { english: "linseed", spanish: "Linaza" },
        { english: "litchi", spanish: "Litchi" },
        { english: "corn", spanish: "Maíz" },
        { english: "macadamia", spanish: "Macadamia" },
        { english: "maguey", spanish: "Maguey" },
        { english: "mamey", spanish: "Mamey" },
        { english: "tangerine", spanish: "Mandarina" },
        { english: "mango", spanish: "Mango" },
        { english: "mangosteen", spanish: "Mangostán" },
        { english: "chamomile", spanish: "Manzanilla" },
        { english: "appleTree", spanish: "Manzano" },
        { english: "passionFruit", spanish: "Maracuyá" },
        { english: "daisyFlower", spanish: "Margarita" },
        { english: "marjoram", spanish: "Mejorana" },
        { english: "melon", spanish: "Melón" },
        { english: "quince", spanish: "Membrillo" },
        { english: "mint", spanish: "Menta" },
        { english: "moringa", spanish: "Moringa" },
        { english: "turnip", spanish: "Nabo" },
        { english: "nanche", spanish: "Nanche" },
        { english: "napa", spanish: "Napa" },
        { english: "orange", spanish: "Naranja" },
        { english: "medlar", spanish: "Níspero" },
        { english: "nochebuena", spanish: "Nochebuena (planta)" },
        { english: "walnut", spanish: "Nogal" },
        { english: "noni", spanish: "Noni" },
        { english: "nopal", spanish: "Nopal" },
        { english: "oregano", spanish: "Orégano" },
        { english: "ornamental", spanish: "Ornamentales" },
        { english: "orchid", spanish: "Orquídea (planta)" },
        { english: "oilPalm", spanish: "Palma africana o de aceite" },
        { english: "potato", spanish: "Papa" },
        { english: "papaya", spanish: "Papaya" },
        { english: "grasslands", spanish: "Pastizales" },
        { english: "matGrass", spanish: "Pasto tapete (m2)" },
        { english: "pasturesAndMeadows", spanish: "Pastos y praderas" },
        { english: "cucumber", spanish: "Pepino" },
        { english: "pearTree", spanish: "Peral" },
        { english: "parsley", spanish: "Perejil" },
        { english: "peron", spanish: "Perón" },
        { english: "persimony", spanish: "Persimonio" },
        { english: "pepper", spanish: "Pimienta" },
        { english: "pineapple", spanish: "Piña" },
        { english: "pinion", spanish: "Piñón" },
        { english: "pistache", spanish: "Pistache" },
        { english: "pitaya", spanish: "Pitaya" },
        { english: "banana", spanish: "Plátano" },
        { english: "pore", spanish: "Poro" },
        { english: "radish", spanish: "Rábano" },
        { english: "rambutan", spanish: "Rambután" },
        { english: "romerito", spanish: "Romerito" },
        { english: "rosemary", spanish: "Romero" },
        { english: "aloe", spanish: "Sábila" },
        { english: "watermelon", spanish: "Sandía" },
        { english: "sorghum", spanish: "Sorgo" },
        { english: "soy", spanish: "Soya" },
        { english: "stevia", spanish: "Stevia" },
        { english: "tobacco", spanish: "Tabaco" },
        { english: "tamarind", spanish: "Tamarindo" },
        { english: "tejocote", spanish: "Tejocote" },
        { english: "tomato", spanish: "Tomate Rojo (jitomate)" },
        { english: "greenTomato", spanish: "Tomate Verde" },
        { english: "thyme", spanish: "Tomillo" },
        { english: "grapefruit", spanish: "Toronja (pomelo)" },
        { english: "clover", spanish: "Trébol" },
        { english: "wheat", spanish: "Trigo" },
        { english: "dutchTulip", spanish: "Tulipán holandés" },
        { english: "tuna", spanish: "Tuna" },
        { english: "grape", spanish: "Uva" },
        { english: "vanilla", spanish: "Vainilla" },
        { english: "purslane", spanish: "Verdolaga" },
        { english: "vine", spanish: "Vid" },
        { english: "cassavaFood", spanish: "Yuca alimenticia" },
        { english: "carrot", spanish: "Zanahoria" },
        { english: "sapodilla", spanish: "Zapote" },
        { english: "blackberry", spanish: "Zarzamora" },
        { english: "zempoalxochitl", spanish: "Zempoalxochitl" },
        { english: "fallow", spanish: "Barbecho" },
        { english: "other", spanish: "Otro" },
      ],
      moTypes: [
        {
          spanish: "Bacteria",
          english: "bacteria",
        },
        {
          spanish: "Hongo",
          english: "fungi",
        },
        {
          spanish: "Nemátodo",
          english: "nematode",
        },
      ],
    };
  },
  computed: {
    ...mapState("identification", ["identificationsFounded"]),
  },
  watch: {
    async dialog() {
      if (this.dialog) {
        await this.getIdentificationReactions();
      }
    },
    async searchIdentification(val) {
      if (!val) return;
      this.isLoading = true;
      try {
        const response = await this.searchIdentifications({
          name: val,
        });
        if (response.error) throw response;
      } catch (e) {
        console.log(e);
      }
      // Remove from autocomplete items if the identification is already in the table.
      this.identifications.forEach((identif, i) => {
        const index = this.identificationsFounded.findIndex(
          (i) => i._id === identif._id
        );
        if (index !== -1) {
          this.identificationsFounded.splice(index, 1);
        }
      });
      this.isLoading = false;
    },
  },
  methods: {
    ...mapActions("identification", [
      "searchIdentifications",
      "searchIdentificationReactions",
      "addNewIdentificationReaction",
      "addStrainIdentification",
    ]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapMutations("identification", ["SET_IDENTIFICATIONS"]),
    async getIdentificationReactions() {
      try {
        let request = await this.searchIdentificationReactions();

        if (request.error) throw request;

        this.formatRelations(request.identificationReactions);

        console.log(request);
      } catch (error) {
        console.error(error.message);
      }
    },
    formatRelations(data) {
      this.relations = [];
      for (const relationData of data) {
        this.relations.push({
          _id: relationData._id,
          identification: relationData.identification.name,
          threat: relationData.threat,
          reaction: relationData.reaction,
          moType: relationData.identification.moType,
          crops: relationData.crops,
          spanishCrops: relationData.crops.map((crop) => {
            return this.crops.find((cropData) => cropData.english == crop)
              .spanish;
          }),
          vegetableMaterial : relationData.vegetableMaterial
        });
      }
    },
    changeIdentificationValue() {
      if (!this.searchIdentification) {
        this.newIdentificationReaction.identification = {};
      }
    },
    async saveNewRelation() {
      try {
        console.log(this.newIdentificationReaction);
        let bodyRequest = {
          crops: this.newIdentificationReaction.crops.map((cropData) => {
            return cropData.english;
          }),
          identification: this.newIdentificationReaction.identification._id,
          reaction: this.newIdentificationReaction.reaction,
          threat: this.newIdentificationReaction.threat,
          vegetableMaterial : this.newIdentificationReaction.vegetableMaterial
        };

        let request = await this.addNewIdentificationReaction(bodyRequest);

        if (request.error) throw request;

        this.closeModal();

        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: "Relación guardada correctamente",
          timeout: 5000,
        });
      } catch (error) {
        console.log(error);

        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Error al guardar la relación. Error: " + error.message,
          timeout: 5000,
        });
      }
    },
    async closeModal() {
      this.newIdentificationReaction = {
        identification: null,
        threat: false,
        vegetableMaterial: false,
        reaction : this.newIdentificationReaction.reaction,
        crops: this.newIdentificationReaction.crops,
      };

      this.dialog2 = false;

      await this.getIdentificationReactions();
    },
    async saveIdentification() {
      if (this.$refs.form.validate()) {
        // this.dialog = false;

        let req = await this.addStrainIdentification({
          moType: this.newIdentificationForm.moType.english,
          name: this.newIdentificationForm.name,
          lowReferenceLevel: 0,
          highReferenceLevel: 0,
        });

        if (req.error) throw req;

        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: `Iidentificación guardada correctamente`,
          timeout: 2000,
        });

        this.newIdentificationForm = {
          name: "",
          moType: {
            spanish: "Bacteria",
            english: "bacteria",
          },
        };
        this.dialog3 = false;
      }
    },
  },
};
</script>

<style>
</style>