import axios from "axios";

/**
 * Function to create a new biostimulation test
 * @param {Object} context - Vuex context.
 * @param {Object} payload - biostimulation test info.
 */
export async function addBiostimulationTest(context, payload) {
  try {
    const { data } = await axios.post("/biostimulationTest/add", payload);
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    console.log(e);
    return e.response.data;
  }
}

/**
 * Function to get all biostimulation tests by biochemical test id.
 * @param {Object} context - Vuex context.
 * @param {Object} payload - Biochemical test id.
 */
export async function getBiostimulationTests(context, payload) {
  try {
    const { data } = await axios.get(`/biostimulationTest`, {
      params: {
        biochemicalTestId: payload,
      },
    });
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to upload the biostimulation photo to AWS S3.
 * @param {Object} { commit } - vuex function
 * @param {Image} image - Mother box biostimulation photo
 * @returns image url
 */
export async function uploadBiostimulationPhoto(context, image) {
  try {
    const { data } = await axios.post("/upload-image", image);
    if (data.error) {
      throw data;
    }
    return data.urlImg;
  } catch (error) {
    return error;
  }
}

/**
 * Function to update a biostimulation test.
 * @param {Object} context - Vuex context.
 * @param {Object} payload - biostimulation test new info.
 */
export async function updateBiostimulationTest(context, payload) {
  try {
    const { data } = await axios.put(
      `/biostimulationTest/update/${payload._id}`,
      payload
    );

    return data;
  } catch (e) {
    console.log(e);
    return e.response.data;
  }
}

/**
 * Function to remove a  biostimulation test
 * @param {Object} context - Vuex context.
 * @param {Object} payload - biostimulation test _id.
 */
export async function removeBiostimulationTest(context, payload) {
  try {
    const { data } = await axios.delete(
      `/biostimulationTest/remove/${payload}`,
      payload
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}

// - - - - - -
/**
 * Function to get all laboratories by filters
 * @param {Object} context - Vuex context.
 * @param {Object} payload - Filters.
 */
export async function getLaboratories(context, payload) {
  try {
    const { data } = await axios.get(`/laboratory/get`, {
      params: payload,
    });
    if (data.error) {
      throw data;
    }

    context.commit("SET_LABORATORIES", data.laboratories);
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to update an laboratory
 * @param {Object} context - Vuex context.
 * @param {Object} payload - Lab Properties.
 */
export async function updateLaboratory(context, payload) {
  try {
    const { data } = await axios.put(`/laboratory`, payload);
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to update an laboratory
 * @param {Object} context - Vuex context.
 * @param {Object} payload - Lab Properties.
 */
export async function getZones(context, payload) {
  try {
    const { data } = await axios.get(`/laboratory/get-zones`);
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function newPackage(context, payload) {
  try {
    const { data } = await axios.post(`/laboratory/package`, {
      package: payload,
    });
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function updatePackage(context, payload) {
  try {
    const { data } = await axios.put(`/laboratory/package`, {
      package: payload,
    });
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function disablePackage(context, payload) {
  try {
    const { data } = await axios.put(`/laboratory/package-disable`, payload);
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function transferringPackage(context, payload) {
  try {
    const { data } = await axios.put(`/laboratory/package-transfer`, payload);
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    throw Error(e.message);
  }
}

export async function getPackages(context, payload) {
  try {
    const { data } = await axios.get(`/laboratory/package`, {
      params: payload,
    });
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function getPackagesByName(context, payload) {
  try {
    const { data } = await axios.get(`/laboratory/package-by-name`, {
      params: payload,
    });
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function getMolecuclarLaboratories(context, payload) {
  try {
    const { data } = await axios.get(`/laboratory/get-molecular-laboratories`, {
      params: payload,
    });
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function getLaboratoryById(context, payload) {
  try {
    const { data } = await axios.get(`/laboratory/get-laboratory-by-id`, {
      params: payload,
    });
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function updateLogoPackages({ commit }, payload) {
  try {
    const { data } = await axios.post(
      "/laboratory/update-logo-packages",
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function removeLogoPackages({ commit }, payload) {
  try {
    const { data } = await axios.post(
      "/laboratory/remove-logo-packages",
      payload
    );
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function getMarkers(context, payload) {
  try {
    const { data } = await axios.get(`/laboratory/get-markers`, {
      params: payload,
    });
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function getMarkersAll(context, payload) {
  try {
    const { data } = await axios.get(`/laboratory/get-markers-all`, {
      params: payload,
    });
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function createMarker({ commit }, payload) {
  try {
    const { data } = await axios.post("/laboratory/create-marker", payload);
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function updateMarker({ commit }, payload) {
  try {
    const { data } = await axios.put(
      "/laboratory/update-marker",
      payload.body,
      {
        params: payload.params,
      }
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function deleteMarker({ commit }, payload) {
  try {
    const { data } = await axios.delete("/laboratory/delete-marker", {
      params: payload,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function restoreMarker({ commit }, payload) {
  try {
    const { data } = await axios.post("/laboratory/restore-marker", payload);
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function runTestData({ commit }, payload) {
  try {
    const { data } = await axios.post(
      "/laboratory/preprocess-test-file",
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (data.error) throw data;

    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function compareTestData(context, payload) {
  try {
    const { data } = await axios.post(`/laboratory/compare-test-data`, payload);
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}
