<template>
  <v-container>
    <v-row>
      <v-col cols="8">
        <span
          v-if=" farmUnit && farmUnit.type === 'INITIAL'"
          class="primary--text font-weight-bold"
          >Análisis Inicial</span
        >
        <span v-else class="primary--text font-weight-bold"
          >Análisis de Seguimiento</span
        >
      </v-col>
      <v-col cols="4">
        <v-row no-gutters>
          <v-col cols="6" class="text-end">
            <span v-if="farmUnit" class="primary--text mr-2"
              >{{ farmUnit.analysis.length }}/{{ totalSamples }}</span
            >
          </v-col>
          <v-col cols="6">
            <v-img
              :src="require('@/assets/img/solena-flag-outline.png')"
              max-width="20"
            ></v-img
          ></v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="2">
        <span class="primary--text text-caption">Folio</span></v-col
      >
      <v-col cols="6">
        <span class="primary--text text-caption">Muestra</span></v-col
      >
      <v-col cols="4">
        <span class="primary--text text-caption">Unidad agricola</span></v-col
      >
    </v-row>
    <template  v-for="recepcion in farmUnit.analysis">
      <v-row no-gutters :key="recepcion._id">
        <v-col cols="2">
          <span class="text-caption">{{
            getFolio(recepcion.container.sample.folioAnalysis)
          }}</span></v-col
        >
        <v-col cols="6">
          <v-row no-gutters>
            <v-col cols="12">
              <span class="text-caption">{{ recepcion.container.code }}</span>
            </v-col>
            <v-col cols="12">
              <span class="gray--text text-caption">{{
                dateFormat(recepcion.container.registrationDate)
              }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <span class="text-caption">{{
            truncateString(recepcion.container.sample.soilArea.name, 15)
          }}</span></v-col
        >
      </v-row>
    </template>
    <v-divider class="mx-4 my-2" inset></v-divider>
  </v-container>
</template>
<script>
//Libraries
import dayjs from "dayjs";
export default {
  data: () => ({}),
  props: {
    farmUnit: {
      type: Object,
      required: true,
    },
    totalSamples: {
      type: Number,
      required: true,
    },
  },
  methods: {
    truncateString(str, num) {
      // If the length of str is less than or equal to num
      // just return str--don't truncate it.
      if (str.length <= num) {
        return str;
      }
      // Return str truncated with '...' concatenated to the end of str.
      return str.slice(0, num) + "...";
    },
    getFolio(folio) {
      let arrayAUx = folio.split("-");
      return arrayAUx[0];
    },
    dateFormat(value) {
      const date = `${dayjs(value).format("DD-MM-YYYY - HH:mm")} hrs`;
      return date ? date : "----";
    },
  },
  watch: {},
};
</script>