var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"40vw","scrollable":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"rounded-xl",attrs:{"height":"auto","elevation":"5","min-height":"200"}},[_c('v-card-title',{staticClass:"text-h5 lighten-2"},[_vm._v(" Registros en tiempo ")]),_c('v-card-text',[(_vm.isLoading)?_c('div',[_c('v-row',{staticClass:"text-start mt-2"},[_c('v-col',[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"primary","indeterminate":""}}),_c('span',{staticClass:"font-weight-bold font-italic ml-5"},[_vm._v("Cargando eventos...")])],1)],1)],1):_c('div',[_c('v-timeline',{attrs:{"align-top":"","dense":_vm.$vuetify.breakpoint.smAndDown}},_vm._l((_vm.logs),function(log,i){return _c('v-timeline-item',{key:i,attrs:{"fill-dot":"","color":log.color,"icon":log.type == 'PCT'
                ? 'mdi-test-tube'
                : log.type == 'SEND'
                ? 'mdi-send-clock'
                : log.type == 'RECEIVED'
                ? 'mdi-hand-extended'
                : log.type.includes('MB_')
                ? 'mdi-microscope'
                : log.type.includes('BCT')
                ? 'mdi-leaf-circle-outline'
                : 'mdi-dna'}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-title',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.getType(log.type)))]),_c('v-card-text',{staticClass:"white text--primary"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-caption"},[_c('span',{staticClass:"font-weight-bold font-italic"},[_vm._v("Hora:")]),_vm._v(" "+_vm._s(_vm.dateFormat(log.date)))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-caption"},[_c('span',{staticClass:"font-weight-bold font-italic"},[_vm._v("Usuario:")]),_vm._v(" "+_vm._s(log.user.name)+" "+_vm._s(log.user.lastName))])]),(
                      log.type == 'RECEPTION' ||
                      log.type == 'SEND' ||
                      log.type == 'RECEIVED'
                    )?[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-caption"},[_c('span',{staticClass:"font-weight-bold font-italic"},[_vm._v("Origen:")]),_vm._v(" "+_vm._s(log.origin.name)+", "+_vm._s(log.origin.city)+", "+_vm._s(log.origin.state)+","+_vm._s(log.origin.country)+", "+_vm._s(log.origin.zipCode))])]),(log.to)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-caption"},[_c('span',{staticClass:"font-weight-bold font-italic"},[_vm._v("Destino:")]),_vm._v(" "+_vm._s(log.to.name)+", "+_vm._s(log.to.city)+", "+_vm._s(log.to.state)+","+_vm._s(log.to.country)+", "+_vm._s(log.to.zipCode))])]):_vm._e()]:_vm._e(),(
                      log.type == 'SEQUENCE_ADD' ||
                      log.type == 'SEQUENCE_REPORT' || 
                      log.type == 'SEQUENCE_RESULT_PLATE'
                    )?[_c('v-col',[_c('span',{staticClass:"text-caption"},[_c('span',{staticClass:"font-weight-bold font-italic"},[_vm._v(" Nombre: ")]),_vm._v(" "+_vm._s(log.sequencingReportId.name)+" ")]),_c('v-spacer'),_c('span',{staticClass:"text-caption"},[_c('span',{staticClass:"font-weight-bold font-italic"},[_vm._v(" ID: ")]),_vm._v(" "+_vm._s(log.sequencingReportId.plate.folio)+" ")])],1)]:_vm._e()],2)],1)],1)],1)}),1),(!_vm.isLoading && _vm.logs.length == 0)?_c('div',[_c('v-row',{staticClass:"text-center mt-2"},[_c('v-col',[_c('span',{staticClass:"font-weight-bold font-italic"},[_vm._v("No hay registros de eventos")])])],1)],1):_vm._e()],1)]),_c('v-divider')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }