<template>
  <v-row justify="center">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          class="px-2"
          :content="filtersLength"
          :value="filtersLength"
          color="primary"
          overlap
        >
          <v-btn color="primary" dark icon v-bind="attrs" v-on="on">
            <v-icon v-if="checkDefaultFilters()" @click="dialog = true">mdi-filter-outline</v-icon>
            <v-icon v-else @click="dialog = true">mdi-filter</v-icon>
          </v-btn>
        </v-badge>
      </template>
      <span>Filtros</span>
    </v-tooltip>
    <v-dialog v-model="dialog" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Filtros</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <!-- <v-row>
              <span>Tipo de muestra</span>
            </v-row>
            <v-row justify="center" align="center">
              <v-col md="3" sm="12" class="pt-0">
                <v-switch
                  v-model="chipsSample"
                  class="mt-0"
                  color="primary"
                  label="Inicial"
                  value="Inicial"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col md="3" sm="12" class="pt-0">
                <v-switch
                  v-model="chipsSample"
                  class="mt-0"
                  color="primary"
                  label="Seguimiento"
                  value="Seguimiento"
                  hide-details
                ></v-switch>
              </v-col>
            </v-row> -->
            <v-row class="pb-2">
              <v-divider></v-divider>
            </v-row>
            <v-row>
              <span>Tipo De Medición</span>
            </v-row>
            <v-row>
              <v-select
                v-model="chipsMBTests"
                :items="itemsMBTests"
                chips
                clearable
                open-on-clear
                label="Tipo de ensayo"
                multiple
                prepend-icon="mdi-filter-variant"
                solo
                :menu-props="{ top: false, offsetY: true }"
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="removeMBTestType(item)"
                  >
                    <strong>{{ item }}</strong>
                  </v-chip>
                </template>
              </v-select>
            </v-row>
            <v-row class="pb-2">
              <v-divider></v-divider>
            </v-row>
            <v-row>
              <span>Tipo De Valuación</span>
            </v-row>
            <v-row>
              <v-select
                v-model="chipsBCTests"
                :items="itemsBCTests"
                chips
                clearable
                open-on-clear
                label="Tipo de ensayo"
                multiple
                prepend-icon="mdi-filter-variant"
                solo
                :menu-props="{ top: false, offsetY: true }"
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="removeBCTestType(item)"
                  >
                    <strong>{{ item }}</strong>
                  </v-chip>
                </template>
              </v-select>
            </v-row>
            <v-row class="pb-2">
              <v-divider></v-divider>
            </v-row>
            <!-- <v-row>
              <span>Medios de cultivo</span>
            </v-row>
            <v-row>
              <v-select
                v-model="chipsCultureMedias"
                :items="itemsCultureMedias"
                chips
                clearable
                open-on-clear
                label="Tipo de muestra"
                multiple
                prepend-icon="mdi-filter-variant"
                solo
                :menu-props="{ top: false, offsetY: true }"
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="removeCultureMedia(item)"
                  >
                    <strong>{{ item }}</strong>
                  </v-chip>
                </template>
              </v-select>
            </v-row> -->
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning darken-1" text @click="dialog = false">
            Cancelar
          </v-btn>
          <v-btn color="secondary darken-1" text @click="setDefaultFilters">
            Revertir
          </v-btn>
          <v-btn color="primary darken-1" text @click="saveFilters">
            Aplicar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  data: () => ({
    dialog: false,
    filtersLength: 0,
    chipsSample: [],
    chipsBCTests: [],
    itemsBCTests: [
      "Control",
      "Confrontación",
      "S. Fosforo",
      "S. Potasio",
      "P. AIA",
      "P. Sideroforos",
      "A. Celulolítica",
    ],
    chipsMBTests: [],
    itemsMBTests: [
      "Hongo",
      "Bacteria",
      "Resiembra"
    ],
    chipsCultureMedias: [
      "PDA",
      "PDA c/antibiótico",
      "EMA",
      "TME",
      "AN",
      "V8",
      "SNA",
      "523",
      "YDC",
      "Pikovskaya",
      "Pikovskaya modificado",
      "CAS",
      "Manitol",
      "Pseudomonas",
      "EMARB",
      "TMERB",
      "CMC",
      "PRU",
    ],
    itemsCultureMedias: [],
  }),
  computed: {
    ...mapState(["filters"]),
    filtersFormated() {
      if (this.filters) {
        return JSON.parse(this.filters);
      } else {
        return null;
      }
    },
  },

  methods: {
    ...mapActions("cultureMedia", ["getCultureMedia"]),
    ...mapMutations(["SET_FILTERS"]),
    removeMBTestType(item) {
      this.chipsMBTests.splice(this.chipsMBTests.indexOf(item), 1);
      this.chipsMBTests = [...this.chipsMBTests];
    },
    removeBCTestType(item) {
      this.chipsBCTests.splice(this.chipsBCTests.indexOf(item), 1);
      this.chipsBCTests = [...this.chipsBCTests];
    },
    removeCultureMedia(item) {
      this.chipsCultureMedias.splice(this.chipsCultureMedias.indexOf(item), 1);
      this.chipsCultureMedias = [...this.chipsCultureMedias];
    },
    setDefaultFilters() {
      this.chipsSample = ["Inicial", "Seguimiento"];
      this.chipsBCTests = [
        "Control",
        "Confrontación",
        "S. Fosforo",
        "S. Potasio",
        "P. AIA",
        "P. Sideroforos",
        "A. Celulolítica",
      ];

      this.chipsMBTests = [
        "Hongo",
        "Bacteria",
        "Resiembra"
      ],

      this.chipsCultureMedias = this.itemsCultureMedias;

      this.SET_FILTERS({
        // samples: this.chipsSample,
        bcTests: this.chipsBCTests,
        mbTests: this.chipsMBTests,
        // cultureMedias: this.chipsCultureMedias,
      });
    },
    saveFilters() {
      this.SET_FILTERS({
        // samples: this.chipsSample,
        bcTests: this.chipsBCTests,
        mbTests: this.chipsMBTests,
        // cultureMedias: this.chipsCultureMedias,
      });

      this.dialog = false;
      this.$emit('filtersUpdated', this.filtersFormated)
    },
    checkDefaultFilters(){
      let defaultChipsBCTests = [
        "Control",
        "Confrontación",
        "S. Fosforo",
        "S. Potasio",
        "P. AIA",
        "P. Sideroforos",
        "A. Celulolítica",
      ], defaultChipsMBTests = [
        "Hongo",
        "Bacteria",
        "Resiembra"
      ], filters = JSON.parse(this.filters)

      if(filters == null || (filters.bcTests.length == defaultChipsBCTests.length && filters.mbTests.length == defaultChipsMBTests.length)){
        return true
      }else{
        return false
      }
    }
  },
  watch: {
    async dialog() {
      if (this.dialog) {
        let req = await this.getCultureMedia();

        this.itemsCultureMedias = req.CULTURE_MEDIA.map((cultureMedia) => {
          return cultureMedia.nutrientAgar;
        });

        if (!this.filtersFormated) {
          this.setDefaultFilters();
        } else {
          this.chipsSample = this.filtersFormated.samples;
          this.chipsBCTests = this.filtersFormated.bcTests;
          this.chipsMBTests = this.filtersFormated.mbTests;
          this.chipsCultureMedias = this.filtersFormated.cultureMedias;
        }
      }
    },
  },
};
</script>