import axios from "axios";

/**
 * Function to get microbiological tests
 * @param  {function} {commit} - Vuex function
 * @param {Object} { rootState } - Vuex store object
 * @param {payload} payload - laboratory, skip, limit, status, search
 * @returns {data} -  Returns array of mbtest
 */
export async function getMBTestList({ commit, rootState }, payload) {
  try {
    const { data } = await axios.get("/microbiologicalTests/laboratory", {
      params: payload,
      cancelToken: rootState.cancelToken && rootState.cancelToken.token,
    });
    if (data.error) throw data;

    commit("SET_MDTEST_LIST", data.MB_TESTS);
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

/**
 * Function to get microbiological tests and isolates
 * @param  {function} {commit} - Vuex function
 * @param {Object} { rootState } - Vuex store object
 * @param {payload} payload - laboratory, skip, limit, status, search
 * @returns {data} -  Returns array of mbtest
 */
 export async function getMBTestIsolatesList({ commit, rootState }, payload) {
  try {
    const { data } = await axios.get("/microbiologicalTestsIsolates/laboratory", {
      params: payload,
      cancelToken: rootState.cancelToken && rootState.cancelToken.token,
    });
    if (data.error) throw data;
    commit("SET_MDTEST_LIST", data.MB_TESTS);
    commit("SET_COUNT", data.COUNT);
    // commit("SET_MDTEST_LIST", data.MB_TESTS);
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

/**
 * Function to get only microbiological tests and isolates (pures)
 * @param  {function} {commit} - Vuex function
 * @param {Object} { rootState } - Vuex store object
 * @param {payload} payload - laboratory, skip, limit, status, search
 * @returns {data} -  Returns array of mbtest
 */
 export async function getMBTestIsolatesListS3({ commit, rootState }, payload) {
  try {
    const { data } = await axios.get("/microbiologicalTestsS3/laboratory", {
      params: payload,
      cancelToken: rootState.cancelToken && rootState.cancelToken.token,
    });
    if (data.error) throw data;
    commit("SET_MDTEST_LIST", data.MB_TESTS);
    commit("SET_COUNT", data.COUNT);
    // commit("SET_MDTEST_LIST", data.MB_TESTS);
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

/**
 * Function to create microbiological test
 * @param {Object} { dispatch } - vuex functions
 * @param {Object} mbTest - microbiological test info.
 * @returns {data} -  Returns mbtest created
 */
export async function createMBTest({ dispatch }, payload) {
  try {
    const { data } = await axios.post(`/microbiologicalTest/create`, payload);
    if (data.error) throw data;

    // Fetch mbTests list
    const response = await dispatch("getMBTestList", {
      laboratory: payload.laboratory,
      skip: 0,
      limit: 14,
      status: ["INOCULATED", "CONTAMINATED"],
    });
    if (response.error) throw response;

    // fetch testmedia
    const responseTestMedia = await dispatch(
      "testMedia/countTestMedias",
      { laboratory: payload.laboratory },
      { root: true }
    );
    if (responseTestMedia.error) throw responseTestMedia;

    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

/**
 * Function to update a microbiological test
 * @param {Object} { dispatch } - vuex function
 * @param {Object} payload - microbiological test info
 * @returns {data} -  Returns mbtest updated
 */
export async function updateMBTest({ dispatch }, payload) {
  try {
    const { data } = await axios.put(`/microbiologicalTest/update`, payload);
    if (data.error) throw data;

    // Fetch mbTests list
    const response = await dispatch("getMBTestList", {
      laboratory: payload.laboratory,
      skip: 0,
      limit: 14,
      status: payload.statusMBTest,
    });
    if (response.error) throw response;

    return data;
  } catch (e) {
    console.log(e);
    throw Error(e.response.data.message);
  }
}

/**
 * Function to incubate selected microbiological tests
 * @param {function} { commit } - vuex function
 * @param {Array} payload - selected tests
 * @returns {data} -  Returns message
 */
export async function incubateMBTests(context, payload) {
  try {
    const { data } = await axios.put(
      "/microbiologicalTest/incubate",
      payload.arrayIDs
    );
    if (data.error) throw data;

    // Fetch mbTests list
    const response = await context.dispatch("getMBTestList", {
      laboratory: payload.laboratory,
      skip: 0,
      limit: 14,
      status: ["INOCULATED", "CONTAMINATED"],
    });
    if (response.error) throw response;

    return data;
  } catch (e) {
    console.log(e);
    throw Error(e.response.data.message);
  }
}

/**
 * Function to get microbiological test by id
 * @param {Object} { commit } - vuex function
 * @param {String} id - microbiological test id
 */
 export async function getMicrobiologicalTestById({ commit }, id) {
  try {
    const { data } = await axios.get(`/microbiologicalTest/${id}`);
    if (data.error) throw data;
    commit("SET_MBTEST_SELECTED", data.MICROBIOLOGICALTEST);
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

/**
 * Function to delete microbiological test by id
 * @param {Object} { commit } - vuex function
 * @param {String} id - microbiological test id
 */
 export async function deleteMicrobiologicalTestById({ commit }, {id, user, laboratory}) {
  try {
    const { data } = await axios.delete(`/microbiologicalTest/remove/${id}/${user}/${laboratory}`);
    if (data.error) throw data;
    
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

/**
 * Function to interupt microbiological test by id
 * @param {Object} { commit } - vuex function
 * @param {String} payload - microbiological test id
 */
 export async function interruptMicrobiologicalTest({ commit }, payload) {
  try {
    const { data } = await axios.put(`/microbiologicalTest/interrupt`, payload);
    if (data.error) throw data;
    
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

/**
 * Function to interupt microbiological test by id
 * @param {Object} { commit } - vuex function
 * @param {String} payload - microbiological test id
 */
export async function interruptAllMicrobiologicalTest({ commit }, payload) {
  try {
    const { data } = await axios.post(`/microbiologicalTest/interruptAll`, payload);
    if (data.error) throw data;
    
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}
