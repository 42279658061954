<template>
  <v-dialog v-model="dialog" max-width="100%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" depressed dark color="secondary">
        Añadir resultados Ezdrop/Qbit
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-center headline">
        <v-row>
          <v-col> Nuevos resultados </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row justify="center">
            <v-col cols="12" md="6">
              <v-row align="center" justify="center">
                <v-col cols="12" md="4">
                  <span class="text-center">Resultados Ezdrop</span>
                </v-col>
                <v-col cols="12" md="6">
                  <v-file-input v-model="ezdropFile" color="secondary" label="Archivo Ezdrop"
                    placeholder="Seleccióna el archivo" prepend-icon="mdi-file-document-multiple-outline" outlined
                    accept=".csv" hide-details @change="ezDropFileEvent">
                    <template v-slot:selection="{ text }">
                      <v-chip color="secondary" dark label small>
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col v-if="ezdropFile" cols="auto">
                  <vue-excel-editor v-model="ezdropData" width="auto" height="auto" :readonly="true" :no-footer="true">
                    <vue-excel-column field="Item" label="Item = Active" type="string" width="100px" />
                    <vue-excel-column field="Sample" label="Sample" type="string" width="100px" />
                    <vue-excel-column field="Concentration" label="Concentration" type="string" width="120px" />
                    <!-- <vue-excel-column
                      field="Method"
                      label="Method"
                      type="string"
                      width="80px"
                    /> -->
                    <vue-excel-column field="A260/280" label="A260/280" type="string" width="80px" />
                    <vue-excel-column field="A260/230" label="A260/230" type="string" width="80px" />
                    <!-- <vue-excel-column
                      field="A260"
                      label="A260"
                      type="string"
                      width="80px"
                    /> -->
                    <!-- <vue-excel-column
                      field="A280"
                      label="A280"
                      type="string"
                      width="80px"
                    /> -->
                    <!-- <vue-excel-column
                      field="A230"
                      label="A230"
                      type="string"
                      width="80px"
                    /> -->
                    <!-- <vue-excel-column
                      field="Lambda"
                      label="λ (Corr.)"
                      type="string"
                      width="80px"
                    /> -->
                    <!-- <vue-excel-column
                      field="Length"
                      label="Path. Length"
                      type="string"
                      width="80px"
                    /> -->
                    <vue-excel-column field="Date" label="Date" type="string" width="120px" />
                  </vue-excel-editor>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row align="center" justify="center">
                <v-col cols="12" md="4">
                  <span class="text-center">Resultados Qbit</span>
                </v-col>
                <v-col cols="12" md="6">
                  <v-file-input v-model="qbitFile" color="secondary" label="Archivo Qbit"
                    placeholder="Seleccióna el archivo" prepend-icon="mdi-file-document-multiple-outline" outlined
                    accept=".csv" hide-details @change="qbitFileEvent">
                    <template v-slot:selection="{ text }">
                      <v-chip color="secondary" dark label small>
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col v-if="qbitFile" cols="auto">
                  <vue-excel-editor v-model="qbitData" width="auto" height="auto" :readonly="true" :no-footer="true">
                    <vue-excel-column field="Run ID" label="Run ID" type="string" width="120px" />
                    <vue-excel-column field="Test Date" label="Test Date" type="string" width="150px" />
                    <!-- <vue-excel-column
                      field="Assay Name"
                      label="Assay Name"
                      type="string"
                      width="80px"
                    /> -->
                    <vue-excel-column field="Sample Name" label="Sample Name" type="string" width="80px" />
                    <vue-excel-column field="Original Sample Conc." label="Original Sample Conc." type="string"
                      width="100px" />
                    <!-- <vue-excel-column
                      field="Original sample conc. units"
                      label="Original sample conc. units"
                      type="string"
                      width="100px"
                    />
                    <vue-excel-column
                      field="Qubit Tube Conc."
                      label="Qubit Tube Conc."
                      type="string"
                      width="80px"
                    />
                    <vue-excel-column
                      field="Qubit tube conc. units"
                      label="Qubit tube conc. units"
                      type="string"
                      width="100px"
                    />
                    <vue-excel-column
                      field="Sample Volume (uL)"
                      label="Sample Volume (uL)"
                      type="string"
                      width="80px"
                    />
                    <vue-excel-column
                      field="Dilution Factor"
                      label="Dilution Factor"
                      type="string"
                      width="80px"
                    />
                    <vue-excel-column
                      field="Extended Low Range"
                      label="Extended Low Range"
                      type="string"
                      width="80px"
                    />
                    <vue-excel-column
                      field="Core Range"
                      label="Core Range"
                      type="string"
                      width="100px"
                    />
                    <vue-excel-column
                      field="Extended High Range"
                      label="Extended High Range"
                      type="string"
                      width="80px"
                    />
                    <vue-excel-column
                      field="Excitation"
                      label="Excitation"
                      type="string"
                      width="100px"
                    />
                    <vue-excel-column
                      field="Std 1 RFU"
                      label="Std 1 RFU"
                      type="string"
                      width="100px"
                    />
                    <vue-excel-column
                      field="Std 2 RFU"
                      label="Std 2 RFU"
                      type="string"
                      width="100px"
                    />
                    <vue-excel-column
                      field="Std 3 RFU"
                      label="Std 3 RFU"
                      type="string"
                      width="100px"
                    />
                    <vue-excel-column
                      field="Sample RFU"
                      label="Sample RFU"
                      type="string"
                      width="100px"
                    />
                    <vue-excel-column
                      field="Last Read Standards"
                      label="Last Read Standards"
                      type="string"
                      width="100px"
                    />
                    <vue-excel-column
                      field="Reagent Lot#"
                      label="Reagent Lot#"
                      type="string"
                      width="100px"
                    />
                    <vue-excel-column
                      field="Well"
                      label="Well"
                      type="string"
                      width="100px"
                    />
                    <vue-excel-column
                      field="Sample ID"
                      label="Sample ID"
                      type="string"
                      width="100px"
                    />
                    <vue-excel-column
                      field="Tags"
                      label="Tags"
                      type="string"
                      width="100px"
                    /> -->
                  </vue-excel-editor>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <!-- Actions -->
      <v-card-actions>
        <v-spacer />
        <v-btn @click="closeDialog" depressed dark color="gray">
          Cerrar
        </v-btn>
        <v-btn :loading="loading" depressed color="primary" @click="addResultsData">
          Añadir resultados
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
export default {
  name: "AddResultsModal",
  data() {
    return {
      dialog: false,
      loading: false,
      ezdropFile: null,
      qbitFile: null,
      ezdropData: [],
      qbitData: [],
    };
  },
  computed: {
    ...mapState('sequencingReport', ['sequenceReport'])
  },
  methods: {
    ...mapActions('sequencingReport', ['saveResults']),
    ...mapMutations('alerts', ['SET_ALERT_TYPE']),
    ezDropFileEvent(ev) {
      if (ev) {
        this.processCsvEzdropFile(ev);
      } else {
        this.ezdropData = [];
      }
    },
    qbitFileEvent(ev) {
      if (ev) {
        this.processCsvQbitFile(ev);
      } else {
        this.qbitData = [];
      }
    },
    processCsvEzdropFile(file) {
      try {
        let _this = this;
        var reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (e) => {
          let jsonData = [];

          let importantContent = false;
          let rows = e.target.result.split("\n");
          // console.log(rows)
          let version = 1;
          for (let i = 0; i < rows.length; i++) {
            let cells = rows[i].split(",");
            if (cells[0] == "Plate ID") {
              version = 2;
            }
            if (version == 1) {
              if (rows[i - 3] && rows[i - 3].trim().includes("[Report]")) {
                importantContent = true;
              } else if (
                rows[i].trim().includes("[General]") ||
                rows[i].trim() == ""
              ) {
                importantContent = false;
              }

              if (importantContent && cells[0] != "") {
                let data = {
                  Item: cells[0],
                  Sample: cells[1].trim(),
                  Concentration: cells[2],
                  Method: cells[3],
                  "A260/280": cells[4],
                  "A260/230": cells[5],
                  A260: cells[6],
                  A280: cells[7],
                  A230: cells[8],
                  Lambda: cells[9],
                  Length: cells[10],
                  Date: cells[11],
                };

                jsonData.push(data);
              }
            } else {
              if (i != 0 && cells[1]) {
                let data = {
                  Item: cells[1],
                  Sample: cells[2],
                  Concentration: cells[6],
                  "A260/280": cells[10],
                  "A260/230": cells[11],
                  Date: cells[4],
                };

                jsonData.push(data);
              }
            }
          }

          _this.ezdropData = jsonData;
        };
      } catch (e) {
        this.ezdropData = [];
      }
    },
    processCsvQbitFile(file) {
      try {
        let _this = this;
        var reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (e) => {
          let jsonData = [];

          let rows = e.target.result.split("\n");
          // console.log(rows)
          let version = 1;
          for (let i = 0; i < rows.length; i++) {
            let cells = rows[i].split(",");

            if (i == 0 && cells[1].includes("Assay")) {
              version = 2;
            }
            if (version == 1) {
              if (i != 0 && cells[0].length != 0) {
                const data = {
                  "Run ID": cells[0], //
                  "Test Date": cells[1], //
                  "Assay Name": cells[2],
                  "Sample Name": cells[3].trim(), //
                  "Original Sample Conc.": cells[4], //
                  "Original sample conc. units": cells[5],
                  "Qubit Tube Conc.": cells[6],
                  "Qubit tube conc. units": cells[7],
                  "Sample Volume (uL)": cells[8],
                  "Dilution Factor": cells[9],
                  "Extended Low Range": cells[10],
                  "Core Range": cells[11],
                  "Extended High Range": cells[12],
                  Excitation: cells[13],
                  "Std 1 RFU": cells[14],
                  "Std 2 RFU": cells[15],
                  "Std 3 RFU": cells[16],
                  "Sample RFU": cells[17],
                  "Last Read Standards": cells[18],
                  "Reagent Lot#": cells[19],
                  "Plate Barcode": cells[20],
                  Well: cells[21],
                  "Sample ID": cells[22],
                  Tags: cells[23],
                };

                jsonData.push(data);
              }
            } else {
              if (i != 0 && cells[0].length != 0) {
                const data = {
                  "Run ID": cells[0].replace(/"/g, ""), //
                  "Test Date": cells[3].replace(/"/g, ""), //
                  "Assay Name": cells[1].replace(/"/g, ""),
                  "Sample Name": cells[2].trim().replace(/"/g, ""), //
                  "Original Sample Conc.": cells[6].replace(/"/g, ""), //
                  "Original sample conc. units": cells[7].replace(/"/g, ""),
                  "Qubit Tube Conc.": cells[4].replace(/"/g, ""),
                  "Qubit tube conc. units": cells[5].replace(/"/g, ""),
                  "Sample Volume (uL)": cells[8].replace(/"/g, ""),
                  "Dilution Factor": cells[9].replace(/"/g, ""),
                  "Extended Low Range": cells[10].replace(/"/g, ""),
                  "Core Range": cells[11].replace(/"/g, ""),
                  Excitation: cells[13].replace(/"/g, ""),
                  "Std 1 RFU": cells[10].replace(/"/g, ""),
                  "Std 2 RFU": cells[11].replace(/"/g, ""),
                  "Std 3 RFU": cells[12].replace(/"/g, ""),
                };

                jsonData.push(data);
              }
            }
          }
          _this.qbitData = jsonData;
        };
      } catch (e) {
        this.qbitData = [];
      }
    },
    async addResultsData() {
      try {
        let results = [...this.sequenceReport.data];
        const getQbitData = this.qbitData.length > 0 ? true : false;

        if (this.ezdropData.length > 0) {

          for (const ezdData of this.ezdropData) {

            let dataDB = results.find(r => r.sample == ezdData.Sample);

            if (dataDB) {
              dataDB.concentration = parseFloat(ezdData.Concentration);
              dataDB.a260280 = parseFloat(ezdData['A260/280']);
              dataDB.a260230 = parseFloat(ezdData['A260/230']);
            } else {
              results.push({
                sample: ezdData.Sample,
                concentration: parseFloat(ezdData.Concentration),
                totalDna: 0,
                volume: 0,
                a260280: parseFloat(ezdData['A260/280']),
                a260230: parseFloat(ezdData['A260/230']),
              });
            }

          }

          if (getQbitData) {

            for (const qData of this.qbitData) {

              const ezData = results.find(r => r.sample == qData['Sample Name']);

              if (ezData) {
                ezData.concentration = parseFloat(qData['Original Sample Conc.']);
              } else {
                results.push({
                  sample: qData['Sample Name'],
                  concentration: parseFloat(qData['Original Sample Conc.']),
                  totalDna: 0,
                  volume: 0,
                  a260280: 0,
                  a260230: 0,
                });
              }
            }

          }

          await this.saveResults({
            reportId: this.sequenceReport._id,
            results
          });

          this.SET_ALERT_TYPE({
            visible: true,
            type: 'success',
            text: `Resultados agregados correctamente`,
            timeout: 2000
          });

          this.$emit('newResultsSaved');
          this.closeDialog();

        } else {
          this.SET_ALERT_TYPE({
            visible: true,
            type: 'error',
            text: `Ingresa resultados`,
            timeout: 2000
          });
        }
      } catch (error) {
        this.SET_ALERT_TYPE({
          visible: true,
          type: 'error',
          text: `Error: ${error.message}`,
          timeout: 2000
        });
        console.log(error);
      }
    },
    closeDialog() {
      this.loading = false;
      this.ezdropFile = null;
      this.qbitFile = null;
      this.ezdropData = [];
      this.qbitData = [];
      this.dialog = false;
    }
  },
};
</script>

<style scoped>
.scrolleable {
  height: 78vh;
  overflow: auto;
}
</style>