import { render, staticRenderFns } from "./TracingSampleQPCR.vue?vue&type=template&id=7dc22654&scoped=true"
import script from "./TracingSampleQPCR.vue?vue&type=script&lang=js"
export * from "./TracingSampleQPCR.vue?vue&type=script&lang=js"
import style0 from "./TracingSampleQPCR.vue?vue&type=style&index=0&id=7dc22654&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dc22654",
  null
  
)

export default component.exports