<template>
  <v-main fluid>
    <v-container fluid>
      <!-- Logos Row -->
      <v-row align="center" justify="center" class="pt-5">
        <v-col cols="6" align="center">
          <v-img width="400px" src="/img/logo-solena-mono-white.png"> </v-img>
        </v-col>
      </v-row>

      <!-- Card row -->
      <v-row align="baseline" justify="center" class="pt-5">
        <v-col cols="12" sm="6" md="4" lg="4">
          <v-card :loading="loading" elevation="20">
            <v-card-title class="justify-center">
              <p class="text-center mb-0">Inicio de sesión</p>
            </v-card-title>
            <v-form ref="form" class="pb-5">
              <v-card-text class="pb-5">
                <v-row align="center" justify="center">
                  <v-col cols="10" md="10">
                    <v-text-field
                      v-model="email"
                      class="centered-input"
                      label="Correo"
                      :rules="emailRules"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center" class="mt-0">
                  <v-col cols="10" md="10">
                    <v-text-field
                      v-model="password"
                      class="centered-input"
                      :rules="passwordRules"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      label="Contraseña"
                      @click:append="showPassword = !showPassword"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  justify="center"
                  align="center"
                  align-content="center"
                  class="mt-0"
                >
                  <v-col
                    cols="12"
                    md="12"
                    sm="12"
                    align="center"
                    class="pb-0 pl-5 pt-0"
                  >
                    <v-checkbox
                      :true-value="true"
                      :false-value="false"
                      v-model="keepSessionOpen"
                      label="Mantener sesión abierta"
                    >
                    </v-checkbox>
                  </v-col>
                  <!-- <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    align="center"
                    class="pb-0 pl-5 pt-0"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="forgotPassword()"
                          v-bind="attrs"
                          v-on="on"
                          color="primary"
                          text
                        >
                          ¿Olvidaste tu contraseña?
                        </v-btn>
                      </template>
                      <span>⚠ En desarrollo...</span>
                    </v-tooltip>
                  </v-col> -->
                </v-row>
                <!-- Advice Row -->
                <!-- <v-row
                  align="center"
                  align-content="center"
                  justify="center"
                  class="mt-0"
                >
                  <v-col cols="12" lg="6" md="6" sm="4" align="center" class="pt-0"
                    ><v-checkbox
                      v-model="privacityAdvice"
                      :rules="checkRules"
                      :true-value="1"
                      :false-value="0"
                    >
                      <div slot="label">
                        Acepto el
                        <a @click.stop href="https://solena.ag/privacyNotice" class="text-decoration-underline" target="_blank">
                          aviso de privacidad
                        </a>
                      </div></v-checkbox
                    ></v-col
                  >
                </v-row> -->
              </v-card-text>
              <v-card-actions class="pb-5 pt-5">
                <v-row justify="center" align="center">
                  <v-col cols="4">
                    <v-btn
                        @click="loginUser()"
                        :loading="loading"
                        color="primary"
                        depressed
                        block
                      >
                        Aceptar
                    </v-btn>
                  </v-col>
                  <v-col cols="12" v-if="!keepSessionOpen" class="text-center py-0">
                    <small class="py-0">Tu sesión durará 2 horas</small>
                  </v-col>
                </v-row>
                
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar color="error" timeout="2000" v-model="showSnack">
      {{ textSnack }}
    </v-snackbar>
  </v-main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
// Validations
import { requiredFValidation, emailValidation } from "@/helpers/validations.js";

export default {
  name: "Login",
  layout: "login",
  auth: false,
  data() {
    return {
      email: "",
      password: "",
      textSnack: "",
      loading: false,
      showSnack: false,
      keepSessionOpen: true,
      privacityAdvice: false,
      showPassword: false,
      // validation rules
      emailRules: emailValidation(true, "correo electrónico"),
      passwordRules: requiredFValidation("contraseña"),
      checkRules: [
        (v) => v == 1 || "Debes aceptar los términos para continuar",
      ],
    };
  },
  computed: {
    ...mapState("session", ["error", "token", "authenticatedUser"]),
  },
  methods: {
    ...mapMutations(["SET_LOADER"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("session", ["login", "restartPassword"]),

    async loginUser() {
      try {
        if (this.$refs.form.validate()) {
          this.SET_LOADER(true);

          const response = await this.login({
            email: this.email,
            password: this.password,
            keepSessionOpen: this.keepSessionOpen,
          });
          if (response.error) throw response;

          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: `Bienvenido/a ${this.authenticatedUser.name} ${this.authenticatedUser.lastName}`,
            timeout: 4000,
          });

          this.$router.push("/reception");
        }
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: error.message,
          timeout: 4000,
        });
      }
      this.SET_LOADER(false);
    },

    async forgotPassword() {
      try {
        console.log("forgotPassword");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>
