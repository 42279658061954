<template>
  <v-dialog v-model="dialog" persistent max-width="450" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="!package"
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        small
        block
      >
        Nuevo paquete
      </v-btn>
      <v-btn v-else x-small color="primary" v-bind="attrs" v-on="on">
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Paquete CMDA</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-switch
                v-model="package_data.hasPrescriptions"
                hide-details
                label="Paquete con prescripción"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-switch
                v-model="package_data.isSolena"
                hide-details
                label="Paquete servicio solena"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-switch
                v-model="package_data.hasRiskLevels"
                hide-details
                label="Paquete con nivel de riesgo"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="package_data.name"
                hide-details
                label="Nombre del paquete"
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                truncate-length="15"
                v-model="package_data.icon"
                :items="logos"
                hide-details
                dense
                item-text="name"
                label="Logo paquete"
                no-data-text="logos no disponibles"
                return-object
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-combobox
                v-model="package_data.distributors"
                hide-details
                :items="distributorsFound"
                label="Distribuidores asociados"
                multiple
                :item-text="formatItemText"
                return-object
                no-data-text="No se encontro distribuidor"
                prepend-inner-icon="mdi-magnify"
                chips
                clearable
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="package_data.businessPartner"
                hide-details
                label="Usuario CMDA"
              />
            </v-col>
            <v-col cols="6">
              <VSelect
                :items="regions"
                v-model="package_data.zones"
                hide-details
                small-chips
                multiple
                label="Región"
              ></VSelect>
            </v-col>
            <v-col cols="12">
              <VSelect
                :items="services"
                v-model="package_data.service"
                hide-details
                label="Servicio"
                item-text="esName"
                return-object
              ></VSelect>
            </v-col>
            <v-col cols="12">
              <VSelect
                :items="kitTypes"
                v-model="package_data.kitTypes"
                hide-details
                multiple
                label="Tipo/s de kit"
                item-text="esName"
                return-object
                small-chips
              ></VSelect>
            </v-col>
            <v-col cols="12">
              <v-combobox
                :items="cropsLaboratory"
                v-model="package_data.crops"
                hide-details
                multiple
                label="Cultivo/s"
                item-text="es_text"
                return-object
                clearable
                chips
                dense
              >
                <template v-slot:selection="{ index, item }">
                  <v-chip
                    v-if="index < 1"
                    color="primary accent-4"
                    dark
                    label
                    small
                  >
                    {{ item.es_text }}
                  </v-chip>

                  <small
                    v-else-if="index === 1"
                    class="grey--text text--darken-3"
                  >
                    +{{ package_data.crops.length - 1 }} Cultivos
                  </small>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12">
              <VSelect
                :items="categories"
                v-model="package_data.category"
                hide-details
                label="Categoria"
                item-text="esName"
                return-object
              ></VSelect>
            </v-col>
            <!-- <v-col cols="12">
              <VSelect
                :items="zones"
                v-model="package_data.region"
                hide-details
                label="Región"
                item-text="name"
                return-object
              ></VSelect>
            </v-col> -->
            <v-col cols="12">
              <v-combobox
                :items="approachs"
                v-model="package_data.approach"
                hide-details
                label="Enfoque"
                item-text="esName"
                return-object
                multiple
                chips
                clearable
              ></v-combobox>
            </v-col>
            <v-col cols="12">
              <VSelect
                :items="sampleTypes"
                v-model="package_data.sampleType"
                hide-details
                label="Tipo de muestra"
                item-text="esName"
                return-object
              ></VSelect>
            </v-col>
            <v-col cols="12" v-if="package_data.sampleType.name == 'vegetable'">
              <v-combobox
                :items="vegetalSampleTypes"
                v-model="package_data.vegetalSampleTypes"
                hide-details
                label="Tipo de muestra vegetal"
                item-text="esName"
                return-object
                multiple
                chips
              ></v-combobox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" text @click="dialog = false"> Cerrar </v-btn>
        <v-btn color="primary" text @click="save_package"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import cropsJson from "@/data/crops.json";

export default {
  props: {
    laboratory: {
      type: Object,
      default: {
        name: "-",
      },
      required: true,
    },
    package: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      regions: [
        "Aguascalientes",
        "Baja California Sur",
        "Coahuila",
        "Colima",
        "Guanajuato",
        "Jalisco",
        "México",
        "Michoacan",
        "Puebla",
        "Sinaloa",
        "Sonora",
        "Tamaulipas",
        "Veracruz",
        "Zacatecas",
        "Africa","Europe","Asia","America","Oceania"
      ],
      dialog: false,
      package_data: {
        name: "",
        kitTypes: [],
        crops: [],
        // region: null,
        laboratory: "",
        category: {
          name: "PATHOGEN_CONTROL",
          esName: "Control de patógenos",
        },
        vegetalSampleType: null,
        sampleType: {
          name: "soil",
          esName: "Suelo / Raíz",
        },
        approach: [
        
        ],
        businessPartner: "",
        service: {
          name: "monitoring",
          esName: "Monitoreo",
        },
      },
      kitTypes: [
        {
          name: "HYBRID",
          esName: "HÍBRIDO",
        },
        {
          name: "QPCR",
          esName: "QPCR",
        },
      ],
      services: [
        {
          name: "monitoring",
          esName: "Monitoreo",
        },
        {
          name: "prescription",
          esName: "Prescripción",
        },
      ],
      categories: [
        {
          name: "NUTRITION",
          esName: "Nutrición",
        },
        {
          name: "STRESS_TOLERANCE",
          esName: "Tolerancia a estres",
        },
        {
          name: "PATHOGEN_CONTROL",
          esName: "Control de patógenos",
        },
        {
          name: "BIOLOGICAL_RICHNESS",
          esName: "Riqueza biológica",
        },
        {
          name: "AGROCHEMICALS_USE",
          esName: "Uso de agroquímicos",
        },
        {
          name: "SOIL_AGGREGABILITY",
          esName: "Agregabilidad del suelo",
        },
        {
          name: "CO2_FIXATION",
          esName: "Fijación de CO2",
        },
        {
          name: "WATER_RETENTION",
          esName: "Retención de Agua",
        },
      ],
      crops: cropsJson,
      zones: [],
      approachs: [
        {
          name: "taxonomic",
          esName: "Taxonomico",
        },
        {
          name: "functional",
          esName: "Funcional",
        },
      ],
      sampleTypes: [
        {
          name: "soil",
          esName: "Suelo / Raíz",
        },
        {
          name: "vegetable",
          esName: "Material Vegetal",
        },
      ],
      vegetalSampleTypes: [
        { name: "Seedling", esName: "Plántula" },
        { name: "Root", esName: "Raíz" },
        { name: "Seed", esName: "Semilla" },
        { name: "Stem", esName: "Tallo" },
        { name: "Leaf", esName: "Hoja" },
        { name: "Fruit", esName: "Fruto" },
        { name: "Flower", esName: "Flor" },
      ],
      distributorsFound: [],
      resizedImageDataUrl: null,
      canvasWidth: 180,
      canvasHeight: 90,
      logos: [],
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  computed: {
    cropsLaboratory() {
      return this.laboratory.cmda_properties.crops.map((crop) => {
        const cropJs = this.crops.find((c) => c.en_text == crop);

        return cropJs;
      });
    },
  },
  watch: {
    async dialog() {
      if (this.dialog) {
        // await this.getAvailableZones();
        await this.loadDistributors();
        await this.loadLogos();
        if (this.package) {
          this.format_package();
        } else {
          this.package_data = {
            name: "",
            kitTypes: [],
            crops: [],
            zone: "",
            laboratory: "",
            approach: [],
            category: {
              name: "PATHOGEN_CONTROL",
              esName: "Control de patógenos",
            },
            vegetalSampleType: null,
            sampleType: {
              name: "soil",
              esName: "Suelo / Raíz",
            },
            businessPartner: "",
            service: {
              name: "monitoring",
              esName: "Monitoreo",
            },
            hasRiskLevels: true,
          };
        }
      } else {
        this.package_data = {
          name: "",
          kitTypes: [],
          crops: [],
          zone: "",
          laboratory: "",
          approach: [],
          category: {
            name: "PATHOGEN_CONTROL",
            esName: "Control de patógenos",
          },
          vegetalSampleType: null,
          sampleType: {
            name: "soil",
            esName: "Suelo / Raíz",
          },
          businessPartner: "",
          service: {
            name: "monitoring",
            esName: "Monitoreo",
          },
          hasRiskLevels: true,
        };
        this.distributorsFound = [];
      }
    },
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("laboratory", [
      "getZones",
      "newPackage",
      "updatePackage",
      "getLaboratoryById",
    ]),
    ...mapActions("organization", ["getDistributorsByPackages"]),
    formatItemText(item) {
      let template = "";
      if (item.billingInformation) {
        template = `${item.billingInformation.companyName} - ${item.companyName}`;
      } else {
        template = `${item.companyName}`;
      }
      return template;
    },
    async loadDistributors() {
      try {
        let response = await this.getDistributorsByPackages({});
        if (!response && response.error) throw response;
        this.distributorsFound = response.distributors;
      } catch (error) {
        console.log(error);
      }
    },
    async getAvailableZones() {
      try {
        const req = await this.getZones();

        if (req.error) throw Error(req);

        this.zones = req.zones;

        console.log(req.zones);
      } catch (error) {
        console.error(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: error.message,
          timeout: 4000,
        });
      }
    },
    async save_package() {
      try {
        let packageData = {};
        if (
          this.package_data.name != "" &&
          this.package_data.businessPartner != "" &&
          this.package_data.kitTypes.length != 0 &&
          this.package_data.crops.length != 0
          // &&
          //this.package_data.distributors
          //&&
          //this.package_data.distributors.length != 0
          // this.package_data.region != null
        ) {
          let aproaches = []
            if(this.package_data.approach){
              for (const element of this.package_data.approach) {
              aproaches.push(element.name)
            }
            }
          if (this.package_data.sampleType.name == "vegetable") {
            if (
              this.package_data.vegetalSampleTypes == null ||
              this.package_data.vegetalSampleTypes.length == 0
            ) {
              throw Error("Todos los campos son requeridos");
            } else {
              let auxVegetableTypes = [];
              for (let item of this.package_data.vegetalSampleTypes) {
                auxVegetableTypes.push(item.name);
              }
              packageData = {
                name: this.package_data.name,
                businessPartner: this.package_data.businessPartner,
                distributors: this.package_data.distributors,
                zones: this.package_data.zones,
                kitType: this.package_data.kitTypes.map((k) => k.name),
                crop: this.package_data.crops.map((c) => c.en_text),
                isPlantTissue: true,
                vegetalSampleTypes: auxVegetableTypes,
                category: this.package_data.category.name,
                laboratory: this.laboratory._id,
                approach: aproaches,
                service: this.package_data.service
                  ? this.package_data.service.name
                  : null,
                mos:
                  this.package &&
                  this.package.mos &&
                  this.package.mos.length > 0
                    ? this.package.mos
                    : [],
                hasPrescriptions: this.package_data.hasPrescriptions,
                isSolena: this.package_data.isSolena,
                hasRiskLevels: this.package_data.hasRiskLevels,
                icon: this.package_data.icon || null,
                // region: this.package_data.region._id,
              };
            }
          } else {
            packageData = {
              name: this.package_data.name,
              businessPartner: this.package_data.businessPartner,
              distributors: this.package_data.distributors,
              zones: this.package_data.zones,
              kitType: this.package_data.kitTypes.map((k) => k.name),
              crop: this.package_data.crops.map((c) => c.en_text),
              isPlantTissue: false,
              vegetalSampleType: null,
              category: this.package_data.category.name,
              laboratory: this.laboratory._id,
              approach: aproaches,
              service: this.package_data.service
                ? this.package_data.service.name
                : null,
              mos:
                this.package && this.package.mos && this.package.mos.length > 0
                  ? this.package.mos
                  : [],
              hasPrescriptions: this.package_data.hasPrescriptions,
              isSolena: this.package_data.isSolena,
              hasRiskLevels: this.package_data.hasRiskLevels,
              icon: this.package_data.icon || null,
              // region: this.package_data.region._id,
            };
          }

          // let fileB64 = this.b64ToFile(
          //   `logo_${this.package_data.name}_${new Date()}`,
          //   this.resizedImageDataUrl
          // );
          //upload file
        } else {
          throw Error("Todos los campos son requeridos");
        }

        let req = null;

        if (this.package) {
          packageData._id = this.package._id;
          req = await this.updatePackage(packageData);
        } else {
          req = await this.newPackage(packageData);
        }

        if (req.error) throw req;

        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: "Paquete creado correctamente",
          timeout: 4000,
        });

        this.$emit("packages_updated");
        this.dialog = false;
      } catch (error) {
        console.error(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: error.message,
          timeout: 4000,
        });
      }
    },
    format_package() {
      let aproaches = []
      for (let item of this.approachs) {
        let exist = this.package.approach.find(element => element == item.name)
        if(exist){
          aproaches.push(item)
        }
      }
      console.log(aproaches);
      
      this.package_data = {
        name: this.package.name,
        kitTypes: this.package.kitType.map((k) => {
          const type = this.kitTypes.find((kT) => kT.name === k);

          return type;
        }),
        crops: this.package.crop.map((c) => {
          const cropData = this.crops.find((cr) => cr.en_text == c);

          return cropData;
        }),
        // region: null,
        laboratory: this.package.laboratory,
        category: this.categories.find((c) => c.name === this.package.category),
        vegetalSampleTypes: [],
        sampleType: this.package.isPlantTissue
          ? {
              name: "vegetable",
              esName: "Material Vegetal",
            }
          : {
              name: "soil",
              esName: "Suelo / Raíz",
            },
        approach: aproaches,
        service: this.services.find((s) => s.name === this.package.service),
        businessPartner: this.package.businessPartner,
        distributors: this.package.distributors,
        zones: this.package.zones,
        hasPrescriptions: this.package.hasPrescriptions || false,
        isSolena: this.package.isSolena || false,
        hasRiskLevels: this.package.hasRiskLevels || false,
        icon: this.package.icon || null,
      };

      if (this.package.isPlantTissue && this.package.vegetalSampleTypes) {
        for (let item of this.package.vegetalSampleTypes) {
          let exist = this.vegetalSampleTypes.find((vT) => vT.name == item);
          if (exist) {
            this.package_data.vegetalSampleTypes.push(exist);
          }
        }
      }
    },
    handleFileChange(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = this.canvasWidth;
          canvas.height = this.canvasHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, this.canvasWidth, this.canvasHeight); // Redimensiona la imagen

          this.resizedImageDataUrl = canvas.toDataURL(); // Guarda la imagen redimensionada
        };
        img.src = e.target.result;
      };

      reader.readAsDataURL(file);
    },
    b64ToFile(fileName, b64) {
      var base64data = b64
        .replace("data:image/png;base64,", "")
        .replace("data:image/jpg;base64,", "");
      var bs = atob(base64data);
      var buffer = new ArrayBuffer(bs.length);
      var ba = new Uint8Array(buffer);
      for (var i = 0; i < bs.length; i++) {
        ba[i] = bs.charCodeAt(i);
      }
      var blob = new Blob([ba], { type: "image/png" });

      let file = new File([blob], fileName);

      return file;
    },
    async loadLogos() {
      try {
        let payload = {
          laboratoryId: this.laboratory._id,
        };
        let response = await this.getLaboratoryById(payload);
        if (!response || response.error)
          throw response ? response.message : "error";
        this.logos = response.laboratory.packagesLogos;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
