<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  data() {
    return {
      gradient: null,
      gradient2: null,
      labels: [],
      values: [],
    };
  },
  props: {
    results: {
      type: Array,
      require: true,
    },
    label: {
      type: String,
      require: true,
    },
  },
  mounted() {
    this.formatData();
    this.gradient = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);

    this.gradient.addColorStop(0, "rgba(4, 193, 5, 0.5)");
    this.gradient.addColorStop(0.5, "rgba(4, 193, 5, 0.25)");
    this.gradient.addColorStop(1, "rgba(4, 193, 5, 0)");

    this.setChart()
  },
  methods: {
    setChart() {
      this.renderChart(
        {
          labels: this.labels,
          datasets: [
            {
              label: this.label,
              borderColor: "#30b700",
              pointBackgroundColor: "green",
              borderWidth: 2,
              pointBorderColor: "green",
              // backgroundColor: this.gradient,
              backgroundColor: "transparent",
              data: this.values,
            },
          ],
        },
        { responsive: true, maintainAspectRatio: false }
      );
    },
    formatData() {
      this.labels = this.results.map((result) => {
        return `${result.time} hrs`;
      });

      this.values = this.results.map((result) => {
        return result.ppm;
      });
    },
  },
  watch: {
    results: {
      handler() {
        this.formatData();
        this.setChart()
      },
      deep: true,
    },
  },
};
</script>
