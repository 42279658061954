<template>
  <v-container>
    <v-data-table
      height="100%"
      v-model="checkBoxesSelected"
      :headers="headers"
      :items="mbTestsFiltered"
      :item-class="row_classes"
      :show-select="$vuetify.breakpoint.xs ? false : true"
      dense
      item-key="_id"
      hide-default-footer
      :items-per-page="12"
      @click:row="rowSelect"
      :loading="loading"
    >
      <!-- analysisType -->
      <template v-slot:[`item.analysisType`]="{ item }">
        <p class="font-weight-bold mb-0">
          {{ item.analysisType }}
        </p>
      </template>

      <template v-slot:[`item.inoculum`]="{ item }">
        <p class="mb-0" v-if="item.analysisType != 'Confrontación'">
          <v-tooltip bottom v-if="item.kitType == 'HYBRID'">
            <template v-slot:activator="{ on, attrs }">
              <span 
                v-bind="attrs"
                v-on="on">
                🧬
              </span>
            </template>
            <span>Seguimiento de qPCR</span>
          </v-tooltip>
          <template
            v-if="
              (item.analysisType == 'Hongo' || item.analysisType == 'Bacteria' || item.analysisType == 'Todos') && item.inoculum != 'M. Vegetal'
            "
          >
            Dilución
            <span class="font-weight-bold">({{ item.inoculum }})</span>
          </template>
          <template v-else>
            {{ item.inoculum }}
          </template>
        </p>
        <p class="mb-0" v-else>
          <strong class="warning--text">{{ item.inoculumControl }}</strong>
          vs {{ item.inoculumBeneficial }}
        </p>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <p
          class="font-weight-bold text-caption warning--text mb-0"
          v-if="item.status.includes('Incubando')"
        >
          {{ item.status }}
        </p>
        <p
          class="font-weight-bold text-caption secondary--text mb-0"
          v-else-if="
            item.status.includes('Identificando') ||
            item.status.includes('Analizando')
          "
        >
          {{ item.status }}
        </p>
        <p
          class="
            font-weight-bold
            text-caption
            secondary--text
            text--disabled
            mb-0
          "
          v-else-if="item.status.includes('Finalizado')"
        >
          {{ item.status }}
        </p>
        <p class="font-weight-bold text-caption mb-0" v-else>
          {{ item.status }}
        </p>
      </template>
      <template slot="no-data">
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12"> No hay ensayos para mostrar </v-col>
          </v-row>
        </v-container>
      </template>
    </v-data-table>
    <v-row justify="center" align="center" class="pt-5">
      <v-pagination
        v-model="page"
        @input="updatePagination"
        :length="length"
        total-visible="12"
        color="primary"
      ></v-pagination>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import axios from "axios";

export default {
  props: {
    search: {
      type: String,
      require: false,
    },
    reload: {
      type: Boolean,
      require: true,
    },
    active : {
      type : Boolean,
      require: true,
    }
  },
  data() {
    return {
      loading: false,
      menuValuations: false,

      checkBoxesSelected: [],
      selected: null,
      headers: [
        { text: "Folio de análisis", sortable: false, value: "folioAnalysis" },
        { text: "ID de ensayo", sortable: false, value: "idTest" },
        { text: "Medio", sortable: false, value: "medium" },
        { text: "Inóculo", sortable: false, value: "inoculum" },
        { text: "Tipo de muestra", sortable: false, value: "sampleType" },
        {
          text: "Razón social del cliente",
          sortable: false,
          value: "businessName",
        },
        { text: "Tipo de análisis", sortable: false, value: "analysisType" },
        { text: "Laboratorio", sortable: false, value: "laboratory" },
        { text: "Estatus del análisis", sortable: false, value: "status" },
      ],
      dataTestsArray: [],
      showDataTest: true,
      showAddDataTest: false,
      showFungiSelected: false,
      showBacteriaSelected: false,
      showReseedingSelected: false,
      showControlTest: false,
      showAntagonismTest: false,
      showNutritionTest: false,
      showBioEstimulationTest: false,
      selectedRow: null,
      itemsBQ: [
        { title: "Antagonismo" },
        { title: "Nutrición" },
        { title: "Bioestimulación" },
      ],
      user: JSON.parse(localStorage.getItem("user")),
      page: 1,
      countPagination: 0,
      pagination: {
        skip: 0,
        limit: 12,
        skipContaminated: 0,
        limitContaminated: 2,
      },
      strainSelected: null,
      isolate: null,
    };
  },
  mounted() {
    this.page = parseInt(this.$route.params.page);
  },
  watch: {
    selected() {
      this.showBioEstimulationTest = false;
      this.showAntagonismTest = false;
      this.showControlTest = false;
      this.showNutritionTest = false;
      this.showDataTest = false;
      this.showAddDataTest = false;
      this.showFungiSelected = false;
      this.showBacteriaSelected = false;
      this.showReseedingSelected = false;

      if (this.selected.analysisType != "Resiembra") {
        if (this.selected.analysisType == "Control") {
          this.showControlTest = true;
        } else if (this.selected.analysisType == "Confrontación") {
          this.showAntagonismTest = true;
        } else if (this.selected.analysisType.includes("S.")) {
          this.showNutritionTest = true;
        } else if (
          this.selected.analysisType.includes("P.") ||
          this.selected.analysisType.includes("A.")
        ) {
          this.showBioEstimulationTest = true;
        } else {
          this.showDataTest = true;
        }
        this.isolate = null;
        this.strainSelected = null;
      } else {
        // Fungi reseed
        if (this.selected.strain.identificationBCTest == undefined) {
          this.showFungiSelected = true;

          // Bacteria reseed
        } else {
          this.showBacteriaSelected = true;
        }
        this.strainSelected = this.selected.strain;
        this.isolate = this.selected.isolate;
      }
    },
    async search(value) {
      this.checkBoxesSelected = []
      this.pagination.skip = 0;
      this.pagination.skipContaminated = 0;
      this.page = 1;

      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }

      //Save the cancel token for the current request
      this.SET_CANCEL_TOKEN(axios.CancelToken.source());
      if (this.search) {
        await this.getMBTests(this.search);
      } else {
        await this.getMBTests();
      }
    },
    async menuValuations() {
      if (this.menuValuations) {
        await this.getBioactiveBankStrains();
      }
    },
    page() {
      if (this.page != parseInt(this.$route.params.page)) {
        this.$router.replace({
          params: {
            page: this.page,
          },
        });
      }
    },
    checkBoxesSelected: {
      handler() {
        this.$emit("testChecked", this.checkBoxesSelected);
      },
      deep: true,
    },
    async reload() {
      await this.reloadDataTable();
    },
    active() {
      this.checkBoxesSelected = []
    }
  },
  async created() {
    if(this.$route.params.page){
      this.page = parseInt(this.$route.params.page);
    }else{
      this.page = 1;
    }

    this.pagination.skip = (this.page - 1) * 12;

    await this.getMBTests();
  },
  methods: {
    ...mapMutations(["SET_CANCEL_TOKEN"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapMutations("microbiologicalTest", ["SET_MBTEST_SELECTED"]),
    ...mapActions("microbiologicalTest", [
      "getMBTestIsolatesListS3",
      "incubateMBTests",
      "updateMBTest",
    ]),
    ...mapActions("strain", [
      "getStrainById",
      "removeStrain",
      "getBioactiveBankStrains",
    ]),
    ...mapActions("isolate", ["updateIsolate"]),
    ...mapActions("antagonism", ["updateAntagonismTest"]),
    ...mapActions("nutrition", ["updateNutritionTest"]),
    ...mapActions("bioStimulation", ["updateBiostimulationTest"]),
    ...mapActions("testMedia", ["updateTestMedia"]),
    async getMBTests(search = undefined) {
      this.loading = true;
      await this.getMBTestIsolatesListS3({
        laboratory: this.user.laboratory._id,
        skip: this.pagination.skip,
        limit: this.pagination.limit,
        status: ["INCUBATING", "COMPLETED", "IN_PROGRESS"],
        type: "MB",
        ...JSON.parse(this.filters),
        search
      });

      this.countPagination = this.count;
      await this.formatDataTable();

      this.loading = false;
    },
    async formatDataTable() {
      let mbTests = this.microbiologicalTestList;
      let mbTestsArray = [];
      for (const test of mbTests) {
        switch (test.testType) {
          case "Microbiological":
            let mbTestData = {
              _id: test._id,
              folioAnalysis:
                (test.soilSample &&
                  test.soilSample.container &&
                  test.soilSample.container.reception &&
                  test.soilSample.container.reception.folioAnalysis) ||
                (test.soilSample && test.soilSample.kit) ||
                "---",
              idTest:
                test.testMedia != undefined ? test.testMedia._id : "Legacy",
              medium:
                test.testMedia != undefined
                  ? test.testMedia.cultureMedia.nutrientAgar
                  : "Legacy",
              inoculum: test.inoculation ? test.inoculation.dilution : "---",
              sampleType: this.getSampleType(test.soilSample.tracingStatus),
              businessName: test.soilSample.container.client.companyName,
              analysisType:
                test.testMedia != undefined
                  ? this.getMoType(test.testMedia.cultureMedia.moType)
                  : "Legacy",
              status:
                test.status == "INCUBATING"
                  ? `Incubando ${this.getDateFormated(test.incubation.startDate)}`
                  : test.status == "IN_PROGRESS"
                  ? `Identificando ${this.getDateFormated(test.updatedAt)}`
                  : test.status == "COMPLETED"
                  ? `Finalizado ${this.getDateFormated(test.updatedAt)}`
                  : test.status == undefined
                  ? "Legacy"
                  : test.status,
              mbTestData: test,
              kitType : test.analysis.kitType,
              clicked: false,
              laboratory : test.laboratory ? test.laboratory.name : null,
              laboratory_id : test.laboratory ? test.laboratory._id : null
            };

            mbTestsArray.push(mbTestData);
            break;
          case "Isolate":
            let isolateData = null;
            if (test.isolate.isolateType == "Pure") {
              isolateData = {
                _id: test.isolate._id,
                folioAnalysis:
                  (test.soilSample &&
                    test.soilSample.container &&
                    test.soilSample.container.reception &&
                    test.soilSample.container.reception.folioAnalysis) ||
                  (test.soilSample && test.soilSample.kit) ||
                  "---",
                idTest: test.isolate.testMedia._id,
                medium: test.isolate.testMedia.cultureMedia.nutrientAgar,
                inoculum: test.isolateStrain.identification
                  ? test.isolateStrain.identification.name
                  : "Sin identificación",
                sampleType: this.getSampleType(test.soilSample.tracingStatus),
                businessName: test.soilSample.container.client.companyName,
                analysisType: "Resiembra",
                status:
                  test.isolate.status == "INOCULATED"
                    ? `Inoculado ${this.getDateFormated(test.isolate.inoculation)}`
                    : test.isolate.status == "INCUBATING"
                    ? `Incubando ${this.getDateFormated(test.isolate.updatedAt)}`
                    : test.isolate.status == "IN_PROGRESS"
                    ? `Identificando ${this.getDateFormated(test.isolate.updatedAt)}`
                    : test.isolate.status == "COMPLETED"
                    ? `Finalizado ${this.getDateFormated(test.isolate.updatedAt)}`
                    : test.isolate.status == undefined
                    ? "Legacy"
                    : test.isolate.status,
                mbTestData: test.isolateStrain.microbiologicalTest,
                isolate: test.isolate,
                strain: test.isolateStrain,
                clicked: false,
                laboratory : test.laboratory ? test.laboratory.name : null
              };
            } else {
              isolateData = {
                _id: test.isolate._id,
                folioAnalysis:
                  (test.soilSample &&
                    test.soilSample.container &&
                    test.soilSample.container.reception &&
                    test.soilSample.container.reception.folioAnalysis) ||
                  (test.soilSample && test.soilSample.kit) ||
                  "---",
                idTest: test.isolate.testMedia
                  ? test.isolate.testMedia._id
                  : `Legacy-${test.isolate.serialNumberStrain}`,
                medium: test.isolate.testMedia
                  ? test.isolate.testMedia.cultureMedia.nutrientAgar
                  : `Legacy-${test.isolate.serialNumberStrain}`,
                inoculum: test.isolateStrain.identification
                  ? test.isolateStrain.identification.name
                  : "Sin identificación",
                sampleType: this.getSampleType(test.analysis),
                businessName: test.soilSample.container.client.companyName,
                analysisType: "Control",
                status:
                  test.isolate.status == "INOCULATED"
                    ? `Inoculado ${this.getDateFormated(test.isolate.inoculation)}`
                    : test.isolate.status == "INCUBATING"
                    ? `Incubando ${this.getDateFormated(test.isolate.updatedAt)}`
                    : test.isolate.status == "IN_PROGRESS"
                    ? `Identificando ${this.getDateFormated(test.isolate.updatedAt)}`
                    : test.isolate.status == "COMPLETED"
                    ? `Finalizado ${this.getDateFormated(test.isolate.updatedAt)}`
                    : test.isolate.status == undefined
                    ? "Legacy"
                    : test.isolate.status,
                mbTestData: test.isolateStrain.microbiologicalTest,
                isolate: test.isolate,
                strain: test.isolateStrain,
                clicked: false,
                laboratory : test.laboratory ? test.laboratory.name : null
              };
            }

            mbTestsArray.push(isolateData);
            break;
        }
      }

      if (mbTests.length > 0) {
        if(!this.$vuetify.breakpoint.xs){
          mbTestsArray[0].clicked = true;
          this.selected = mbTestsArray[0];
          this.selected.source = "MB"
          this.SET_MBTEST_SELECTED(mbTestsArray[0].mbTestData);
          this.$emit("testSelected", this.selected);
        }

        this.dataTestsArray = mbTestsArray;
      } else {
        this.dataTestsArray = [];
      }
    },
    getTestType(test) {
      let types = [
        { text: "production_siderophores", value: "P. Sideroforos" },
        { text: "production_indoleaceticAcid", value: "P. AIA" },
        { text: "cellulolytic_activity", value: "A. Celulolítica" },
        { text: "phosphorum_solubilization", value: "S. Fósforo" },
        { text: "potassium_solubilization", value: "S. Potasio" },
      ];

      let type = types.find((type) => type.text == test);

      return type.value;
    },
    getSampleType(value) {
      const analysisTypes = {
        INITIAL: "Inicial",
        MONITORING: "Seguimiento",
        Initial : "Inicial",
        Monitoring : "Seguimiento"
      };

      if (value) {
        return analysisTypes[value];
      } else {
        return "Inicial";
      }
    },
    async updatePagination(page) {
      try {
        this.page = page;
        this.pagination.skip = (page - 1) * 12;

        if (typeof this.cancelToken != typeof undefined) {
          this.cancelToken.cancel("Operation canceled due to new request.");
        }

        //Save the cancel token for the current request
        this.SET_CANCEL_TOKEN(axios.CancelToken.source());
        await this.getMBTests(this.search);
      } catch (error) {
        console.error("Error", error);
      }
    },
    getMoType(value) {
      let types = {
        all: "Todos",
        bacteria: "Bacteria",
        fungi: "Hongo",
        nematode: "Nemátodo",
      };
      return types[value];
    },
    showMbTestStrains() {
      if (this.isolate == null) {
        this.showDataTest = false;
        this.showBacteriaSelected = false;
        this.showAddDataTest = true;
      }
    },
    preAddStrain(type) {
      this.showAddDataTest = false;
      this.strainSelected = null;

      if (type == null) {
        this.showSection(this.selected.analysisType);
      } else {
        this.showSection(type == "fungi" ? "Hongo" : "Bacteria");
      }
    },
    async preUpdateStrain(_idStrain) {
      let response = await this.getStrainById(_idStrain);
      if (response.error) throw response;

      this.strainSelected = response.STRAIN;

      this.showAddDataTest = false;
      this.showSection(this.selected.analysisType);
    },
    async preDeleteStrain(_idStrain) {
      const response = await this.removeStrain({
        strainId: _idStrain,
        mbTestId: this.selected._id,
      });

      if (response.error) throw response;
      else
        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: "Se ha eliminado la cepa correctamente",
          timeout: 4000,
        });
    },
    async reloadDataTable() {
      this.checkBoxesSelected = [];
      await this.getMBTests();
    },
    showSection(analisis) {
      if (analisis == "Hongo") {
        this.showFungiSelected = true;
      } else if (analisis == "Bacteria") {
        this.showBacteriaSelected = true;
      }
    },
    async rowSelect(rowValue) {
      this.selectedRow = rowValue._id;
      this.SET_MBTEST_SELECTED(rowValue.mbTestData);
      this.selected = rowValue;
      this.selected.clicked = !this.selected.clicked;
      this.dataTestsArray = this.dataTestsArray.map((data) => {
        if (rowValue._id == data._id) {
          data.clicked = true;
        } else {
          data.clicked = false;
        }
        return data;
      });
      this.selected.source = "MB"

      this.$emit("testSelected", this.selected);
    },
    row_classes(value) {
      if (value.clicked) {
        return "custom-highlight-row";
      }
    },
    getDateFormated(dateString){
      let date = (new Date(dateString)).toLocaleDateString('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit', hour: "numeric", minute: "numeric", hour12: false })
      return `${date} hrs`
    },
  },
  computed: {
    ...mapState("microbiologicalTest", ["microbiologicalTestList", "count"]),
    ...mapState(["cancelToken", "filters"]),
    // length of the paginator items
    length() {
      return Math.ceil(this.countPagination / 12);
    },
    mbTestsFiltered() {
      return this.dataTestsArray.map((x) => ({
        ...x,
        // isSelectable: !x.status.includes("Incubando"),
        isSelectable: true,
      }));
    },
  },
};
</script>

<style>
</style>