<template>
  <v-dialog v-model="dialog" persistent max-width="290">
    <v-card>
      <v-card-title class="text-h5"> La sesión ha expirado </v-card-title>
      <v-card-text
        >Vuelve a iniciar sesión para ingresar nuevamente a la
        plataforma</v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="logOut"> Aceptar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    dialog : {
        type : Boolean,
        require : true,
        default : false
    }
  },
  methods: {
    ...mapActions("session", ["logout", "getNewToken"]),

    async logOut() {
      await this.logout({
        refreshToken: localStorage.getItem("refresh_token"),
        session: localStorage.getItem("session"),
      });
      this.$router.push("/login");
    },
  },
};
</script>

<style>
</style>