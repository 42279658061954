export function SET_RECEPTIONS(state, receptions) {
  state.receptions = receptions;
}
export function SET_SELECTED_RECEPTION(state, reception) {
  state.currentSelection = reception;
}
export function SET_RECEPTION(state, reception) {
  state.receptions.push(reception);
}
export function SET_ANALYSES_FOUNDED(state, analysesFounded) {
  state.analysesFounded = analysesFounded;
}

export function REMOVE_ANALYSES_FOUNDED(state, analysesFounded) {
  state.analysesFounded = [];
}

export function SET_ANALYSES_RESULTS(state, analysesFounded) {
  state.analysisResults = analysesFounded;
}

export function SET_REFRESH_TABLE(state, value) {
  state.refreshReceptionTable = value;
}

export function SHOW_UPDATE_FOLIO_DIALOG(state, value) {
  state.showUpdateFolioDialog = value;
}

export function SET_FOLIO_SELECTED(state, folio) {
  state.folioSelected = folio;
}

export function SET_FILTERS(state, filters) {
  state.filters = filters;
}

export function SET_SEARCH(state, search) {
  state.searchFilter = search;
}

export function SET_FOLIOS_SELECTED(state, value) {
  state.folios = value;
}

export function PUSH_FOLIOS_SELECTED(state, value) {
  state.folios.push(value);
}

export function REMOVE_FOLIOS_SELECTED(state, index) {
  state.folios.splice(index, 1);
}

export function CLEAN_PARENT(state, value) {
  state.cleanParent = value;
}

export function SET_STORED_SAMPLES(state, samplesToBeStored) {
  state.samplesToBeStored = samplesToBeStored;
}
