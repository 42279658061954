<template>
  <v-dialog v-model="show" width="50vw" scrollable persistent>
    <v-card height="auto" elevation="5" class="rounded-lg" min-height="200">
      <v-card-title class="text-h5 lighten-2"> Asignar paquete </v-card-title>
      <v-card-text>
        <v-form lazy-validation ref="form">
          <v-container>
            <v-row>
              <v-col>
                <v-autocomplete
                  :search-input.sync="searchPackage"
                  v-model="packageSelected"
                  :items="packages"
                  label="Paquete"
                  hide-no-data
                  outlined
                  dense
                  item-text="name"
                  clearable
                  return-object
                  prepend-inner-icon="mdi-magnify"
                  :rules="validation()"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col>
              <v-btn
                color="red"
                block
                @click="closeModal"
                dark
                :disabled="isLoading"
                >Cancelar</v-btn
              >
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                block
                @click="confirmation"
                :loading="isLoading"
                >Aceptar</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//vuex
import { mapActions, mapMutations, mapState } from "vuex";
//validations
import { requiredValidation } from "@/helpers/validations.js";
export default {
  data: () => ({
    packages: [],
    searchPackage: null,
    packageSelected: null,
    isLoading: false,
  }),
  props: {
    value: Boolean,
  },
  computed: {
    ...mapState("project", ["projectSelected"]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions("laboratory", ["getPackagesByName"]),
    ...mapActions("project", ["assingPackage"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    closeModal() {
      this.show = false;
    },
    validation(name) {
      return requiredValidation(name);
    },
    async confirmation() {
      try {
        if (this.$refs.form.validate()) {
          this.isLoading = true;
          let payload = {
            body: this.packageSelected,
            query: {
              projectId: this.projectSelected._id,
            },
          };
          await this.assingPackage(payload);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: `Paquete asignado con exito`,
            timeout: 5000,
          });
          this.$emit("updateData", this.packageSelected);
        }
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
      this.isLoading = false;
    },
  },
  watch: {
    async searchPackage(value) {
      try {
        const req = await this.getPackagesByName({ name: value });

        if (req.error) throw req;

        this.packages = req.packages;
        console.log(req);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
