<template>
  <v-dialog
    v-model="show"
    scrollable
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card height="auto">
      <v-card-text>
        <project-detail @close="show = false" />
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script>
//components
import ProjectDetail from "@/components/projects/ProjectDetail.vue";
export default {
  components: {
    ProjectDetail,
  },
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
