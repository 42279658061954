import axios from "axios";
export async function newReport({ commit }, payload) {
  try {
    const { data } = await axios.post("/sequencing/new-report", payload);
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function getReportById({ commit }, payload) {
  try {
    const { data } = await axios.get("/sequencing/get-report", {
      params : {
        reportId : payload
      }
    });
    if (data.error) {
      throw data;
    }

    commit('SET_SEQUENCE_REPORT', data.report);
    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function saveResults({ commit }, payload) {
  try {
    const { data } = await axios.post("/sequencing/set-data", {
      ...payload
    });
    if (data.error) {
      throw data;
    }

    commit('SET_SEQUENCE_REPORT', data.report);
    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function getAllReports({ commit }, payload) {
  try {
    const { data } = await axios.get("/sequencing/get-reports", {
      params: {
        ...payload
      }
    });
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function updateWell({ commit }, payload) {
  try {
    const { data } = await axios.put("/sequencing/update-well", payload);
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function updateSampleData({ commit }, payload) {
  try {
    const { data } = await axios.post("/sequencing/update-sample-data", payload);
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function assignReportInSamples({ commit }, payload) {
  try {
    const { data } = await axios.post("/sequencing/assign-report", payload);
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function sendReports({ commit }, payload) {
  try {
    const { data } = await axios.post("/sequencing/generate-report", {
      reportId : payload.reportId,
      target: payload.target,
    });
    
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    return e.response.data;
  }
}
