var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"flex d-flex flex-column",attrs:{"elevation":"5"}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":function($event){_vm.showResultModal = true}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-file-eye-outline ")]),_vm._v(" ver resultados ")],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":function($event){_vm.showSummaryModal = true}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-chart-bar-stacked ")]),_vm._v(" Resumen valuación ")],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"mb-2",attrs:{"justify":"start","align":"start"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('span',{staticClass:"primary--text text-caption font-weight-bold"},[_vm._v("Dosis")])]),_c('v-col',{attrs:{"cols":"5"}},[_c('span',{staticClass:"primary--text text-caption font-weight-bold"},[_vm._v("Producto")])]),(
                _vm.profit_version &&
                (_vm.profit_version.status == 'NEW' ||
                  _vm.profit_version.status == 'PROPOSAL' ||
                  _vm.profit_version.status == 'REJECTED' ||
                  _vm.profit_version.status == 'STRAINS_SUGGESTION')
              )?_c('v-col',{attrs:{"cols":"12","xl":"5","lg":"5","md":"5"}},[(
                  _vm.permissions.find(
                    (per) => per == 'lab_profit_recommendation'
                  )
                )?_c('v-btn',{attrs:{"small":"","block":"","color":"primary"},on:{"click":_vm.recommendStrains}},[_vm._v("Recomendar cepas")]):_vm._e()],1):_vm._e()],1),(_vm.strains.length > 0)?_c('div',[_c('v-virtual-scroll',{attrs:{"items":_vm.strains,"height":"770","item-height":50},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('v-list-item',{key:item._id},[_c('v-list-item-action',[_c('span',{staticClass:"text-body-2"},[_vm._v(_vm._s(item.dose))])]),_c('v-list-item-content',[(item.type === 'Bioactivo')?_c('v-container',{attrs:{"fluid":""}},[_c('span',{staticClass:"text-body-2 primary--text"},[_vm._v(_vm._s(item.name)+"*Solena")])]):_c('v-container',{attrs:{"fluid":""}},[_c('span',{staticClass:"text-body-2"},[_vm._v(_vm._s(item.name)+"*Nativa")]),_c('br'),_c('span',[_vm._v(_vm._s(item.sample))])])],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"disabled":_vm.profit_version &&
                        _vm.profit_version.status !== 'NEW' &&
                        _vm.profit_version.status !== 'PROPOSAL' &&
                        _vm.profit_version.status !== 'REJECTED' &&
                        _vm.profit_version.status !== 'STRAINS_SUGGESTION'},on:{"click":function($event){return _vm.selectMO(item, item.isSelected)}},model:{value:(item.isSelected),callback:function ($$v) {_vm.$set(item, "isSelected", $$v)},expression:"item.isSelected"}})],1)],1)]}}],null,false,1405567408)})],1):_c('div',_vm._l((7),function(item){return _c('div',{key:item},[_c('v-skeleton-loader',{staticClass:"ma-7",attrs:{"type":"list-item-avatar, divide"}})],1)}),0)],1)],1),_c('SubSamplePhoto',{attrs:{"img":_vm.img,"visible":_vm.showImageDialog},on:{"hideSubSampleModal":_vm.hideSubSampleModal}}),(_vm.showResultModal)?_c('ResultModal',{attrs:{"profitId":_vm.profitId},model:{value:(_vm.showResultModal),callback:function ($$v) {_vm.showResultModal=$$v},expression:"showResultModal"}}):_vm._e(),(_vm.showSummaryModal)?_c('SummaryModal',{model:{value:(_vm.showSummaryModal),callback:function ($$v) {_vm.showSummaryModal=$$v},expression:"showSummaryModal"}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }