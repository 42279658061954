import Vue from "vue";
import VueRouter from "vue-router";
// Guard
import { defaultGuard } from "@/middlewares/guards";
// Layouts
import LoginLayout from "../layouts/LoginLayout.vue";
// Views
import Login from "../views/Login.vue";

// Module routes
import stationRoutes from "@/router/station.routes";
import testsRoutes from "@/router/tests.routes";
import profitRoutes from "@/router/profit.routes";
import sequencingRoutes from "@/router/sequencing.routes";
import cmdaRoutes from "@/router/cmda.routes";
import labels_routes from "@/router/labels.routes";
import cepariumRoutes from "@/router/ceparium.routes";
import levelRiskRoutes from "@/router/risklevel.routes";
import projectRoutes from "@/router/project.routes";
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: LoginLayout,
    beforeEnter: defaultGuard,
    children: [
      {
        path: "",
        name: "Login",
        component: Login,
        meta: {
          title: "Inicio de sesión",
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/reception/1/reception/",
  },
  {
    path: "/reception",
    redirect: "/reception/1/reception/",
  },
  {
    path: "/tests",
    redirect: "/tests/microbiological-tests/1",
  },
  ...stationRoutes,
  ...testsRoutes,
  ...profitRoutes,
  ...sequencingRoutes,
  ...cmdaRoutes,
  ...labels_routes,
  ...cepariumRoutes,
  ...levelRiskRoutes,
  ...projectRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const DEFAULT_TITLE = "Solena Laboratorio";
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
