import axios from "axios";

export async function findValidFolio(context, payload) {
  try {
    const { data } = await axios.get(`/labels/folio`, {
      params: { folio: payload.folio, filters: payload.status },
    });
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    console.log(e);
    return e.data;
  }
}

export async function getReseedsCeparium(context, payload) {
  try {
    const { data } = await axios.get(`/labels/reseeds_ceparium`, {
      params: { analysis: payload },
    });
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    console.log(e);
    return e.data;
  }
}

export async function createReseedsCeparium(context, payload) {
  try {
    const { data } = await axios.post("/labels/new_reseeds_ceparium", {
      strains: payload,
    });
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    console.log(e);
    return e.data;
  }
}

export async function incubate_test(context, payload) {
  try {
    const { data } = await axios.put("/labels/incubate_test", {
      test_media: payload,
    });
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    console.log(e);
    return e.data;
  }
}

export async function incubate_all_tests(context, payload) {
  try {
    const { data } = await axios.put("/labels/incubate_all_tests", payload);
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    console.log(e);
    return e.data;
  }
}

export async function validate_test(context, payload) {
  try {
    const { data } = await axios.get(`/labels/test_data`, {
      params: { test_media: payload },
    });
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    console.log(e);
    return e.data;
  }
}

export async function validate_test_ceparium(context, payload) {
  try {
    const { data } = await axios.get(`/labels/test_data_ceparium`, {
      params: { test_media: payload },
    });
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    console.log(e);
    return e.data;
  }
}

export async function getMTToReseed({ commit }, payload) {
  try {
    const { data } = await axios.post(`/labels/get-mb-to-reseed`, payload);
    if (data.error) throw data;

    return data;
  } catch (e) {
    throw Error(e.message);
  }
}

export async function createReseeds(context, payload) {
  try {
    const { data } = await axios.post("/labels/new_reseeds", payload);
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    console.log(e);
    return e.data;
  }
}

export async function searchValidFolioToInoculated(context, payload) {
  try {
    const { data } = await axios.get(`/labels/search-valid-folio-to-inoculated`, {
      params: payload,
    });
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    console.log(e);
    return e.data;
  }
}

export async function generateAutomaticValuation(context, payload) {
  try {
    const { data } = await axios.get(`/labels/generate-automatic-valuation`, {
      params: payload,
    });
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    console.log(e);
    return e.data;
  }
}

export async function generateControl(context, payload) {
  try {
    const { data } = await axios.post("/labels/generate-control", payload);
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    console.log(e);
    return e.data;
  }
}

export async function generateBiochemical(context, payload) {
  try {
    const { data } = await axios.post("/labels/generate-biochemical", payload);
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    console.log(e);
    return e.data;
  }
}

export async function generateAntagonism(context, payload) {
  try {
    const { data } = await axios.post("/labels/generate-antagonism", payload);
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    console.log(e);
    return e.data;
  }
}

export async function generateNutrition(context, payload) {
  try {
    const { data } = await axios.post("/labels/generate-nutrition", payload);
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    console.log(e);
    return e.data;
  }
}
