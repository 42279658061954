import axios from "axios";

/**
 * Function to create a new biostimulation test
 * @param {Object} context - Vuex context.
 * @param {Object} payload - biostimulation test info.
 */
export async function addBiostimulationTest(context, payload) {
  try {
    const { data } = await axios.post("/biostimulationTest/add", payload);
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    console.log(e)
    return e.response.data;
  }
}

/**
 * Function to get all biostimulation tests by biochemical test id.
 * @param {Object} context - Vuex context.
 * @param {Object} payload - Biochemical test id.
 */
export async function getBiostimulationTests(context, payload) {
  try {
    const { data } = await axios.get(`/biostimulationTest`, {
      params: {
        biochemicalTestId: payload,
      },
    });
    if (data.error) {
      throw data;
    }
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to upload the biostimulation photo to AWS S3.
 * @param {Object} { commit } - vuex function
 * @param {Image} image - Mother box biostimulation photo
 * @returns image url
 */
export async function uploadBiostimulationPhoto(context, image) {
  try {
    const { data } = await axios.post("/upload-image", image);
    if (data.error) {
      throw data;
    }
    return data.urlImg;
  } catch (error) {
    return error;
  }
}

/**
 * Function to update a biostimulation test.
 * @param {Object} context - Vuex context.
 * @param {Object} payload - biostimulation test new info.
 */
export async function updateBiostimulationTest(context, payload) {
  try {
    const { data } = await axios.put(
      `/biostimulationTest/update/${payload._id}`,
      payload
    );
    
    return data;
  } catch (e) {
    console.log(e)
    return e.response.data;
  }
}

/**
 * Function to remove a  biostimulation test
 * @param {Object} context - Vuex context.
 * @param {Object} payload - biostimulation test _id.
 */
export async function removeBiostimulationTest(context, payload) {
  try {
    const { data } = await axios.delete(
      `/biostimulationTest/remove/${payload}`,
      payload
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}
