export default {
  strainsList: [],
  strainSelected: null,
  bioactiveBankStrains : [],
  moTypes: [
    { text: "Bacteria", value: "bacteria" },
    { text: "Hongo", value: "fungi" },
    { text: "Nemátodo", value: "nematode" },
    { text: "Todos", value: "all" },
  ],
  shape: [
    { text: "Circular", value: "circular" },
    { text: "Filamentoso", value: "filamentous" },
    { text: "Rizoide", value: "rhizoid" },
    { text: "Puntiforme", value: "punctiform" },
    { text: "Irregular", value: "irregular" },
    { text: "Fusiforme", value: "fusiform" },
  ],
  margin: [
    { text: "Completo", value: "entire" },
    { text: "Ondular", value: "undulate" },
    { text: "Lobular", value: "lobate" },
    { text: "Crespo", value: "curled" },
    { text: "Filamentoso", value: "filamentous" },
    { text: "Dentado", value: "erose" },
  ],
  elevation: [
    { text: "Plano", value: "flat" },
    { text: "Elevado", value: "raised" },
    { text: "Convexo", value: "convex" },
    { text: "Pulvinado", value: "pulvinate" },
    { text: "Umbonada", value: "umbonate" },
    { text: "Crateriforme", value: "crateriform" },
  ],
  surface: [
    { text: "Lisa", value: "smooth" },
    { text: "Brillante", value: "glistening" },
    { text: "Aspera", value: "rough" },
    { text: "Rugosa", value: "wrinkle" },
    { text: "Mate", value: "dull" },
    { text: "Cremosa", value: "creamy" },
    { text: "Seca", value: "dry" },
    { text: "Invasiva", value: "invasive" },
    { text: "Superficial", value: "superficial" },
  ],
  opacity: [
    { text: "Opaco", value: "opaque" },
    { text: "Translucido", value: "translucent" },
    { text: "Transparente", value: "transparent" },
  ],
  color: [
    { text: "Amarillo", value: "yellow" },
    { text: "Naranja", value: "orange" },
    { text: "Naranja claro", value: "lightOrange" },
    { text: "Blanco", value: "white" },
    { text: "Crema", value: "cream" },
    { text: "Beige", value: "beige" },
    { text: "Rosa", value: "pink" },
    { text: "Rojo", value: "red" },
    { text: "Transparente", value: "transparent" },
  ],
  fungiColor: [
    { text: "Amarillo", value: "yellow" },
    { text: "Blanco", value: "white" },
    { text: "Café", value: "brown" },
    { text: "Morado", value: "purple" },
    { text: "Naranja", value: "orange" },
    { text: "Crema", value: "cream" },
    { text: "Negro", value: "black" },
    { text: "Rosa", value: "pink" },
    { text: "Verde", value: "green" },
  ],
  fungiTexture: [
    { text: "Algodonoso", value: "cottony" },
    { text: "Aterciopelado", value: "velvety" },
    { text: "Pulvurulento", value: "powdery" },
    { text: "Velloso", value: "hairy" },
    { text: "Denso", value: "dense" },
  ],
  growth: [
    { text: "Irregular", value: "irregular" },
    { text: "Radial", value: "radial" },
    { text: "Concéntrico", value: "concentric" },
  ],
};
