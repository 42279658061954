export function SET_IDENTIFICATION_SELECTED(state, data) {
  state.identificationSelected = data.identification;
}
export function ADD_TO_IDENTIFICATION_LIST(state, identif) {
  state.identificationList.push(identif);
}
export function SET_IDENTIF_FOUNDED(state, identifs) {
  state.identificationsFounded = identifs;
}
export function REMOVE_FROM_IDENTIFICATION_LIST(state, identifId) {
  const index = state.identificationList.findIndex(
    (identif) => identif._id === identifId
  );
  state.identificationList.splice(index, 1);
}
export function SET_IDENTIFICATION_LIST(state, identifs) {
  state.identificationList = identifs;
}
export function CLEAR_LIST(state) {
  state.identificationList = [];
}
export function SET_ERROR(state, error) {
  state.error = error;
}

export function SET_IDENTIFICATIONS(state, identifications) {
  state.identificationsFounded = identifications;
}

export function SET_IDENTIFICATION_SELECTED_IN_TABLE(state, identification) {
  state.identificationSelectedInTable = identification;
}
