<template>
  <v-container class="px-0">
    <v-row v-if="isLoading" align="center" justify="center">
      <v-col cols="6">
        <v-skeleton-loader type="list-item"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row
      v-else-if="!isLoading && !existsMolecularWellData"
      align="center"
      justify="center"
    >
      <v-col cols="4">
        <v-btn
          @click="preAssignSamples"
          block
          color="success"
          :loading="loadingAssignation"
          >Asignar muestras en placa</v-btn
        >
      </v-col>
    </v-row>
    <v-row class="px-0">
      <template v-if="!isLoading && existsMolecularWellData">
        <v-col cols="6" class="text-left">
          <h3>Repeticiónes</h3>
        </v-col>
        <v-col cols="6" class="text-right">
          <new-replica-modal
            :samples="samples"
            :replicas="replicasPurification"
            @acepted="assignReplicaSamples"
            stage="PURIFICATION"
          />
        </v-col>
        <v-col cols="12" v-if="replica">
          <v-stepper non-linear v-model="replica">
            <v-stepper-header>
              <template v-for="(replicaNum, index) in replicasPurification">
                <v-stepper-step
                  :key="`S-${replicaNum}`"
                  editable
                  :step="replicaNum"
                >
                  Repetición
                </v-stepper-step>
                <v-divider
                  v-if="index != replicasPurification.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content
                v-for="(plateData, index) in replicasPlates"
                :key="`C-${replicasPurification[index]}`"
                :step="replicasPurification[index]"
              >
                <Plate
                  :analysis="analysis.folio"
                  :plates="plateData"
                  type="PURIFICATION"
                />
                <!-- <v-btn
                  color="primary"
                  @click="replica = '2'"
                >
                  Siguiente
                </v-btn> -->
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </template>
      <v-col v-else-if="isLoading" cols="12" class="pa-0">
        <v-skeleton-loader type="table-tbody"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col>
        <v-skeleton-loader
          v-if="isLoading"
          type="list-item"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <confirmation-modal
      :samplesLength="samples.length"
      :dialog="confirmationDialog"
      @assignButtonClicked="setSamplesWells"
      @notAssignButtonClicked="notAssignSamples"
    />
  </v-container>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";

import Plate from "@/components/reception/plates/Plate.vue";
import ConfirmationModal from "../modals/ConfirmationModal.vue";
import NewReplicaModal from "../modals/NewReplicaModal.vue";
export default {
  components: { Plate, ConfirmationModal, NewReplicaModal },
  data: () => ({
    selected: [],
    isLoading: false,
    loadingAssignation: false,
    plates: [],
    platesFormated: [],
    user: JSON.parse(localStorage.getItem("user")),
    existsMolecularWellData: false,
    confirmationDialog: false,
    replica: null,
    replicasPurification : [],
    replicasPlates: [],
  }),
  props: {
    analysis: {
      type: Object,
      require: true,
    },
    samples: {
      type: Array,
      require: true,
    },
    tabActive: {
      type: Number,
      require: true,
    },
  },
  computed: {
    ...mapState("molecularLisisTube", ["replicas", "replicasSamples"])
  },
  methods: {
    ...mapActions("molecularPlateWell", [
      "getPlatesStats",
      "getStageStatus",
      "putSamplesOnPlate",
    ]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async getPurificationData() {
      try {
        let request = await this.getPlatesStats({
          plateType: "PURIFICATION",
          samples: this.samples.map((sample) => {
            return sample._id;
          }),
          analysis: this.analysis._id,
          laboratory: this.user.laboratory._id,
          replicas: this.replicasPurification,
          replica: this.replica,
        });

        if (request.error) throw request;

        this.plates = request.plates;
        this.formatPlates();
        this.existsMolecularWellData = true;
      } catch (error) {
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: error.message,
          timeout: 4000,
        });
      }
    },
    formatPlates() {
      this.replicasPlates = [];

      for (const replicaPlate of this.plates) {
        const plateRows = {
          0: "A",
          1: "B",
          2: "C",
          3: "D",
          4: "E",
          5: "F",
          6: "G",
          7: "H",
        };

        let platesResult = [];
        for (const plate of replicaPlate) {
          let plateResult = [];
          // 8 number of rows
          for (let rowIndex = 0; rowIndex < 8; rowIndex++) {
            let rowLetter = plateRows[rowIndex];
            // 12 number of columns

            let rowData = {};
            for (let columnIndex = 0; columnIndex < 12; columnIndex++) {
              let wellDB = plate.wells.find(
                (well) =>
                  well.wellPlate[0] == columnIndex + 1 &&
                  well.wellPlate[1] == rowLetter
              );
              let wellData = {
                plateFolio: plate.folio,
                type: plate.type,
                position: [columnIndex + 1, rowLetter],
                disabled: columnIndex != 0 && columnIndex != 6 ? true : false,
              };

              if (wellDB) {
                wellData.folioAnalysis = wellDB.soilSample.folioAnalysis;
                wellData.sampleType = wellDB.soilSample.sampleType;
                wellData.replica = wellDB.replica;
              } else {
                wellData.folioAnalysis = null;
                wellData.sampleType = null;
              }

              rowData[columnIndex] = { ...wellData };
            }

            plateResult.push(rowData);
          }

          platesResult.push(plateResult);
        }

        this.replicasPlates.push(platesResult);
        // this.platesFormated = platesResult
        // console.log(platesResult);
      }
    },
    async preAssignSamples() {
      this.loadingAssignation = true;

      if (this.samples.length < 4) {
        this.confirmationDialog = true;
      } else {
        await this.setSamplesWells();
        this.confirmationDialog = false;
      }
    },
    // async assignSamples() {
    //   try {
    //     await this.getPurificationData();
    //     this.confirmationDialog = false;
    //   } catch (error) {
    //     this.SET_ALERT_TYPE({
    //       visible: true,
    //       type: "error",
    //       text: error.message,
    //       timeout: 4000,
    //     });
    //   }
    // },
    notAssignSamples() {
      this.confirmationDialog = false;
      this.loadingAssignation = false;
    },
    async setSamplesWells(samples = null) {
      try {
        let nextReplica = "1";

        if (this.replicasPurification.length > 0) {
          const lastReplica = this.replicasPurification[this.replicasPurification.length - 1];
          nextReplica = (parseInt(lastReplica) + 1).toString();
        }

        let samplesIDs =
          samples == null
            ? this.samples.map((sample) => {
                return sample._id;
              })
            : samples.map((sample) => {
                return sample._id;
              });

        let request = await this.putSamplesOnPlate({
          plateType: "PURIFICATION",
          samples: samplesIDs,
          analysis: this.analysis._id,
          laboratory: this.user.laboratory._id,
          replicas: this.replicasPurification,
          replica: nextReplica,
        });

        if (request.error) throw request;

        await this.getStageData();
      } catch (e) {
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: e.message,
          timeout: 4000,
        });
      } finally {
        this.loadingAssignation = false;
      }
    },
    async getStageData() {
      try {
        this.replica = null;
        let statusRequest = await this.getStageStatus({
          analysis: this.analysis._id,
          stage: "PURIFICATION",
        });

        this.existsMolecularWellData = statusRequest.exists;
        this.replicasPurification = statusRequest.replicas;

        if (statusRequest.exists) {
          await this.getPurificationData();
          this.replica = "1";
        }
      } catch (error) {
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: error.message,
          timeout: 4000,
        });
      }
    },
    async assignReplicaSamples(samples) {
      try {
        this.loadingAssignation = true;
        await this.setSamplesWells(samples);
      } catch (error) {
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: e.message,
          timeout: 4000,
        });
      }
    },
  },
  watch: {
    async tabActive() {
      try {
        if (this.tabActive == 1) {
          await this.getStageData();
        }
      } catch (error) {
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: error.message,
          timeout: 4000,
        });
      }
    },
  },
};
</script>

<style>
</style>