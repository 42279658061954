<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-card>
        <v-card-title primary-title>
          <v-row>
            <v-col cols="12" md="8" class="text-center">
              Reportes de muestras para secuenciación
            </v-col>
            <v-col cols="12" md="4">
              <new-report-modal />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-card>
                  <v-card-title>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="filters.search" append-icon="mdi-magnify" label="Buscar" single-line
                      hide-details></v-text-field>
                  </v-card-title>
                  <v-card-text>
                    <v-data-table :headers="headers" :items="reports" :loading="loading" height="50vh" elevation="0"
                      fixed-header hide-default-footer>
                      <template v-slot:[`item.plate`]="{ item }">
                        <v-chip color="primary" dark>
                          {{ item.plate.folio }}
                        </v-chip>
                      </template>
                      <template v-slot:[`item.edit`]="{ item }">
                        <v-btn icon color="primary" @click="$router.push(`/sequencing/${item._id}`)">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                    <v-pagination v-model="filters.page" :length="filters.pages" total-visible="10"
                      color="primary"></v-pagination>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import NewReportModal from '../components/sequencing/modals/NewReportModal.vue';

export default {
  components: { NewReportModal },
  name: "SequencingReports",
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      reports: [],
      filters: {
        search: "",
        limit: 10,
        page: 1,
        pages: 0,
        total: 0,
      },
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Descripción", value: "description", sortable: false },
        { text: "Placa", value: "plate", sortable: false },
        { text: "Editar", value: "edit", sortable: false },
      ],
      loading: false
    };
  },
  methods: {
    ...mapActions("sequencingReport", ["getAllReports"]),
    async getReports() {
      this.loading = true;

      try {
        const request = await this.getAllReports({ ...this.filters });

        this.reports = request.reports;

        this.filters.pages = request.pages;
        this.filters.total = request.totalReports;

        // console.log(request);
      } catch (error) {
        console.error(error);
      }

      this.loading = false;
    },
  },
  watch: {
    async 'filters.search'() {
      try {
        if (this.filters.page != 1) {
          this.filters.page = 1;
        }

        await this.getReports();
      } catch (error) {
        console.error(error)
      }
    },
    async 'filters.page'() {
      try {
        await this.getReports();
      } catch (error) {
        console.error(error)
      }
    }
  },
  async created() {
    await this.getReports();
  },
};
</script>
