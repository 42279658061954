<template>
  <v-dialog v-model="show" width="auto" scrollable persistent>
    <v-card height="auto" width="80vw">
      <v-toolbar color="primary" dark>
        <span class="text-h5 lighten-2"> Carga de archivo Niveles</span>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" xl="6" lg="6" md="6">
              <v-row>
                <v-col>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        disable-icon-rotate
                        expand-icon="mdi-information-slab-circle"
                      >
                        Información de formato archivo
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col> Nombre de columnas en archivo </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <ul>
                              <li>
                                <span class="primary--text">name</span>
                                (ejemplo: Pyrenochaeta lycopersici)
                              </li>
                              <li>
                                <span class="primary--text">family</span>
                                (ejemplo: Solanaceae)
                              </li>
                              <li>
                                <span class="primary--text">medium</span>
                                (ejemplo: 16.75)
                              </li>
                              <li>
                                <span class="primary--text">hight</span>
                                (ejemplo: 19.39)
                              </li>
                              <li>
                                <span class="primary--text">symbiosis</span>
                                (ejemplo: benefic o pathogen)
                              </li>
                              <li>
                                <span class="primary--text">reliability</span>
                                (ejemplo: low, meedium, hight)
                              </li>
                            </ul>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-file-input
                    outlined
                    label="Adjuntar archivo..."
                    prepend-inner-icon="mdi-file-excel"
                    truncate-length="50"
                    show-size
                    :prepend-icon="null"
                    accept=".csv"
                    @change="processFile"
                    v-model="file"
                    :loading="loadingData"
                  >
                  </v-file-input>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6">
              <v-row>
                <v-col>
                  <v-simple-table
                    class="overflow-y-auto"
                    height="400px"
                    fixed-header
                    v-if="rawData.length != 0"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Fila</th>
                          <th class="text-left">Marcador</th>
                          <th class="text-left">Familia</th>
                          <th class="text-left">Rango</th>
                          <th class="text-left">Estatus</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in rawData" :key="index">
                          <td>{{ item.row }}</td>
                          <td>{{ item.name }}</td>
                          <td>{{ item.family }}</td>
                          <td>
                            medio: {{ item.medium }}
                            <v-spacer></v-spacer>
                            alto: {{ item.hight }}
                          </td>
                          <td>
                            <span v-if="item.errors.length != 0">
                              <div
                                v-for="(error, indexError) in item.errors"
                                :key="indexError"
                              >
                                <v-icon color="red" x-small
                                  >mdi-close-circle</v-icon
                                >
                                <span> {{ error }}</span>
                                <v-spacer></v-spacer>
                              </div>
                            </span>
                            <span v-else>
                              <v-icon color="primary">mdi-check-circle</v-icon>
                              Listo
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-simple-table
                    class="overflow-y-auto"
                    height="400px"
                    fixed-header
                    v-else
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Fila</th>
                          <th class="text-left">Marcador</th>
                          <th class="text-left">Familia</th>
                          <th class="text-left">Estatus</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td colspan="4">
                            <v-container>
                              <v-row class="text-center">
                                <v-col>
                                  <span class="font-italic">
                                    No hay datos
                                  </span>
                                </v-col>
                              </v-row>
                            </v-container>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    color="primary"
                    :disabled="getSuccesCount() == 0"
                    @click="createLevels"
                    :loading="loadingMultiple"
                    >Agregar ({{ getSuccesCount() }})</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col>
              <v-btn
                dark
                color="gray"
                block
                @click="show = false"
                :disabled="loadingData"
              >
                Cerrar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//Vuex
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";

export default {
  data: () => ({
    file: null,
    loadingData: false,
    rawData: [],
    loadingMultiple: false,
  }),
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions("riskLevel", ["addPreProcessFile", "createMultipleLevel"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async processFile() {
      try {
        if (this.file) {
          this.loadingData = true;
          var formData = new FormData();
          formData.append("file", this.file);
          let response = await this.addPreProcessFile(formData);
          this.rawData = response.rawData;
        } else {
          this.rawData = [];
        }
      } catch (error) {
        this.rawData = [];
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
      this.loadingData = false;
    },
    getSuccesCount() {
      return this.rawData.filter((element) => element.errors.length == 0)
        .length;
    },
    async createLevels() {
      try {
        this.loadingMultiple = true;
        let payload = {
          levels: [],
        };
        payload.levels = this.rawData.filter(
          (element) => element.errors.length == 0
        );
        await this.createMultipleLevel(payload);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: `Niveles agregados con exito`,
          timeout: 5000,
        });
        this.$emit("refreshTable");
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
      this.loadingMultiple = false;
    },
  },
  async mounted() {},
  watch: {},
};
</script>
