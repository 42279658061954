<template>
  <v-dialog v-model="dialog" persistent max-width="320">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
        color="warning"
        shaped
        block
        small
        v-bind="attrs"
        v-on="on"
        :disabled="disableFields"
        ><v-icon left> mdi-circle-off-outline </v-icon>Interrumpir</v-btn
      >
      <v-btn
        v-else
        color="warning"
        shaped
        block
        small
        v-bind="attrs"
        v-on="on"
        :disabled="disableFields"
        ><v-icon left> mdi-circle-off-outline </v-icon>Interrumpir incubación</v-btn
      >
    </template>
    <v-card>
      <v-card-title class="text-h5">⚠ Interrumpir incubación </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <span
              >¿Está seguro/a de interrumpir la incubación de los ensayos sin
              evidencia fotográfica?.</span
            >
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary darken-1"
          ref="cancelBtn"
          text
          @click="closeDialog"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="warning darken-1"
          plain
          text
          :disabled="disableFields"
          @click="interrumpTests"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      disableFields: true,
      mbTests: [],
      isolateTests: [],
    };
  },
  props: {
    tests: {
      type: Array,
      default: [],
      required: true,
    },
  },
  watch: {
    tests(mbTests) {
      let mbTestsIncubating = mbTests.filter((test) => {
        if (
          (test.analysisType == "Hongo" || test.analysisType == "Bacteria") &&
          test.mbTestData.status == "INCUBATING"
        ) {
          return test.mbTestData;
        }
      });

      // console.log("MB TESTS", mbTestsIncubating);
      this.mbTests = mbTestsIncubating;

      let isolatesIncubating = mbTests.filter((test) => {
        if (
          test.analysisType == "Resiembra" &&
          test.isolate.status == "INCUBATING"
        ) {
          return test.isolate;
        }
      });

      // console.log("ISOLATES", isolatesIncubating);
      this.isolateTests = isolatesIncubating;

      if (mbTestsIncubating.length == 0 && isolatesIncubating.length == 0) {
        this.disableFields = true;
      } else {
        this.disableFields = false;
      }
    },
  },
  methods: {
    ...mapActions("microbiologicalTest", ["interruptMicrobiologicalTest"]),
    ...mapActions("isolate", ["interruptIsolate"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async interrumpTests() {
      try {
        for (const test of this.isolateTests) {
          let request = await this.interruptIsolate(test);
          if (request.error) throw request;
        }

        for (const test of this.mbTests) {
          let request = await this.interruptMicrobiologicalTest(test);
          if (request.error) throw request;
        }

        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: "Ensayos interrumpidos correctamente",
          timeout: 3500,
        });
      } catch (e) {
          console.log(e)
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Error al guardar algúnos ensayos.",
          timeout: 3500,
        });
      }

      this.$emit("updateTests");
      this.dialog = false;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>