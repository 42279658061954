<template>
  <v-dialog v-model="dialogStatus" persistent max-width="320">
    <!-- <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on"> Open Dialog </v-btn>
    </template> -->
    <v-card>
      <v-card-title class="text-h5">
        ¿Asignar los espacios en placa?
      </v-card-title>
      <v-card-text>
        El análisis actualmente tiene {{samplesLength}} muestra/s.
        ¿Estás seguro de asignar los espacios en la placa?.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="notAssign">
          No asignar
        </v-btn>
        <v-btn color="warning darken-1" text @click="assign">
          Asignar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    data() {
        return {
          dialogStatus : false  
        }
    },
    props:{
        samplesLength : {
            type : Number,
            required : true
        },
        dialog : {
            type : Boolean,
            required: true
        }
    },
    methods: {
        assign() {
            this.dialogStatus = false
            this.$emit('assignButtonClicked')
        },
        notAssign(){
            this.dialogStatus = false
            this.$emit('notAssignButtonClicked')
        }
    },
    watch: {
        dialog(){
            this.dialogStatus = this.dialog
        }
    }
};
</script>

<style>
</style>