var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"650","loading":_vm.loading,"scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm)?_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","depressed":"","block":"","disabled":!_vm.hasTests}},'v-btn',attrs,false),on),[_vm._v(" Incubar ")]):_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","depressed":"","block":"","disabled":!_vm.hasTests}},'v-btn',attrs,false),on),[_vm._v(" Incubar ensayos ")])]}},{key:"default",fn:function(dialog){return [(_vm.tests.length > 0)?_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("Incubación de ensayos")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Ensayos seleccionados: ")])],1),_c('v-row',{staticClass:"mt-2 pr-5"},[_c('v-col',{staticClass:"px-0 pb-0",attrs:{"cols":"3"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('p',{staticClass:"text-caption text-center mb-0 font-weight-black"},[_vm._v(" ID de ensayo ")])])],1),_c('v-col',{staticClass:"px-0 pb-0",attrs:{"cols":"2"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('p',{staticClass:"text-caption text-center mb-0 font-weight-black"},[_vm._v(" Medio ")])])],1),_c('v-col',{staticClass:"px-0 pb-0",attrs:{"cols":"2"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('p',{staticClass:"text-caption text-center mb-0 font-weight-black"},[_vm._v(" Tipo de análisis ")])])],1),_c('v-col',{staticClass:"px-0 pb-0",attrs:{"cols":"2"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('p',{staticClass:"text-caption text-center mb-0 font-weight-black"},[_vm._v(" Tipo de MO ")])])],1),_c('v-col',{staticClass:"px-0 pb-0",attrs:{"cols":"3"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('p',{staticClass:"text-caption text-center mb-0 font-weight-black"},[_vm._v(" Cepa ")])])],1)],1),_vm._l((_vm.tests),function(test){return [(
                (test.analysisType == 'Resiembra' ||
                  test.analysisType == 'Control' ||
                  test.analysisType == 'Confrontación' ||
                  test.analysisType.includes('S.') ||
                  test.analysisType.includes('A.') ||
                  test.analysisType.includes('P.')) &&
                test.status.includes('Inoculado')
              )?_c('v-row',{key:test.idTest,staticClass:"mt-6 pr-5"},[_c('v-col',{staticClass:"px-0 pb-0",attrs:{"cols":"3"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('p',{staticClass:"text-body-2 text-center mb-0"},[_vm._v(" "+_vm._s(test.idTest)+" ")])])],1),_c('v-col',{staticClass:"px-0 pb-0",attrs:{"cols":"2"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('p',{staticClass:"text-body-2 text-center mb-0"},[_vm._v(" "+_vm._s(test.medium)+" ")])])],1),_c('v-col',{staticClass:"px-0 pb-0",attrs:{"cols":"2"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('p',{staticClass:"text-body-2 text-center mb-0"},[_vm._v(" "+_vm._s(test.analysisType)+" ")])])],1),_c('v-col',{staticClass:"px-0 pb-0",attrs:{"cols":"2"}},[(test.mbTestData)?_c('v-row',{attrs:{"justify":"center"}},[(
                      test.mbTestData.incubation.incubator.moType == 'fungi'
                    )?_c('p',{staticClass:"text-body-2 text-center mb-0"},[_vm._v(" Hongo ")]):_c('p',{staticClass:"text-body-2 text-center mb-0"},[_vm._v("Bacteria")])]):(
                    test.bcTestData && test.bcTestData.type != 'Antagonism'
                  )?_c('v-row',{attrs:{"justify":"center"}},[(test.strain.identification.moType == 'fungi')?_c('p',{staticClass:"text-body-2 text-center mb-0"},[_vm._v(" Hongo ")]):_c('p',{staticClass:"text-body-2 text-center mb-0"},[_vm._v("Bacteria")])]):_c('v-row',{attrs:{"justify":"center"}},[_c('p',{staticClass:"text-body-2 text-center mb-0"},[_vm._v("---")])])],1),_c('v-col',{staticClass:"px-0 pb-0",attrs:{"cols":"3"}},[_c('v-row',{attrs:{"justify":"center"}},[(test.analysisType != 'Confrontación')?_c('p',{staticClass:"text-body-2 text-center mb-0"},[_vm._v(" "+_vm._s(test.inoculum)+" ")]):_c('p',{staticClass:"text-body-2 text-center mb-0"},[_vm._v(" "+_vm._s(test.inoculumControl)+" vs "+_vm._s(test.inoculumBeneficial)+" ")])])],1)],1):_vm._e()]})],2)],1),_c('v-card-actions',{staticClass:"justify-end mt-5"},[_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":function($event){dialog.value = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"text":"","color":"secondary","loading":_vm.loading},on:{"click":_vm.incubateTests}},[_vm._v("Incubar")])],1)],1):_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("Incubar ensayos")]),_c('v-card-text',[_c('div',{staticClass:"text-h6 pa-12"},[_vm._v("Debes seleccionar una o mas ensayos")])]),_c('v-card-actions',{staticClass:"justify-end mt-5"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("Close")])],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})
}
var staticRenderFns = []

export { render, staticRenderFns }