<template>
  <v-dialog
    transition="dialog-top-transition"
    max-width="650"
    :loading="loading"
    v-model="dialog"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
        color="warning"
        small
        depressed
        block
        v-bind="attrs"
        v-on="on"
        :disabled="!hasTests"
      >
        Eliminar
      </v-btn>
      <v-btn
        v-else
        color="warning"
        small
        depressed
        block
        v-bind="attrs"
        v-on="on"
        :disabled="!hasTests"
      >
        Eliminar ensayos
      </v-btn>
    </template>
    <template v-slot:default="dialog">
      <v-card v-if="tests.length > 0">
        <v-toolbar color="primary" dark>Eliminar ensayos</v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12"> Ensayos: </v-col>
            </v-row>
            <v-row class="mt-2 pr-5">
              <v-col cols="2" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-caption text-center mb-0 font-weight-black">
                    ID análisis
                  </p>
                </v-row>
              </v-col>
              <v-col cols="2" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-caption text-center mb-0 font-weight-black">
                    ID ensayo
                  </p>
                </v-row>
              </v-col>
              <v-col cols="2" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-caption text-center mb-0 font-weight-black">
                    Medio
                  </p>
                </v-row>
              </v-col>
              <v-col cols="3" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-caption text-center mb-0 font-weight-black">
                    Inóculo
                  </p>
                </v-row>
              </v-col>
              <v-col cols="3" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-caption text-center mb-0 font-weight-black">
                    Tipo de análisis
                  </p>
                </v-row>
              </v-col>
            </v-row>
            <template v-for="test in tests">
              <v-row v-if="test.analysisType != 'Molecular'" class="mt-6 pr-5" :key="test.idTest">
                <v-col cols="2" class="px-0 pb-0">
                  <v-row justify="center">
                    <p class="text-caption text-center mb-0">
                      {{ test.folioAnalysis }}
                    </p>
                  </v-row>
                </v-col>
                <v-col cols="2" class="px-0 pb-0">
                  <v-row justify="center">
                    <p class="text-caption text-center mb-0">
                      {{ test.idTest }}
                    </p>
                  </v-row>
                </v-col>
                <v-col cols="2" class="px-0 pb-0">
                  <v-row justify="center">
                    <p class="text-caption text-center mb-0">
                      {{ test.medium }}
                    </p>
                  </v-row>
                </v-col>
                <v-col cols="3" class="px-0 pb-0">
                  <v-row justify="center">
                    <p
                      class="text-caption text-center mb-0"
                      v-if="test.inoculum != 'confrontation'"
                    >
                      {{ test.inoculum }}
                    </p>
                    <p class="text-caption text-center mb-0" v-else>
                      {{ test.inoculumControl }} vs
                      {{ test.inoculumBeneficial }}
                    </p>
                  </v-row>
                </v-col>
                <v-col cols="3" class="px-0 pb-0">
                  <v-row justify="center">
                    <p class="text-caption text-center mb-0">
                      {{ test.analysisType }}
                    </p>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-else class="mt-6 pr-5" :key="test.idTest">
                <v-col cols="2" class="px-0 pb-0">
                  <v-row justify="center">
                    <p class="text-caption text-center mb-0">
                      {{ test.folioAnalysis }}
                    </p>
                  </v-row>
                </v-col>
                <v-col cols="2" class="px-0 pb-0">
                  <v-row justify="center">
                    <p class="text-caption text-center mb-0">
                      - - -
                    </p>
                  </v-row>
                </v-col>
                <v-col cols="2" class="px-0 pb-0">
                  <v-row justify="center">
                    <p class="text-caption text-center mb-0">
                      - - - 
                    </p>
                  </v-row>
                </v-col>
                <v-col cols="3" class="px-0 pb-0">
                  <v-row justify="center">
                    <p class="text-caption text-center mb-0">
                      - - -
                    </p>
                  </v-row>
                </v-col>
                <v-col cols="3" class="px-0 pb-0">
                  <v-row justify="center">
                    <p class="text-caption text-center mb-0">
                      {{ test.analysisType }}
                    </p>
                  </v-row>
                </v-col>
              </v-row>
            </template>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end mt-5">
          <v-btn text color="warning" @click="dialog.value = false"
            >Cancelar</v-btn
          >
          <v-btn text color="secondary" @click="deleteTests" :loading="loading"
            >Eliminar</v-btn
          >
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      hasTests: false,
      // user data
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  props: {
    tests: {
      type: Array,
      require: true,
    },
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("isolate", ["deleteIsolateById"]),
    ...mapActions("antagonism", ["removeAntagonismTest"]),
    ...mapActions("nutrition", ["removeNutritionTest"]),
    ...mapActions("bioStimulation", ["removeBiostimulationTest"]),
    ...mapActions("microbiologicalTest", ["deleteMicrobiologicalTestById"]),
    ...mapActions("molecularIdentification", ["deleteMolecularTest"]),
    async deleteTests() {
      this.loading = true;
      let tests = this.tests;

      if (tests.length > 0) {
        let testsForm = tests.map((test) => {
          let form = null;

          if (
            test.analysisType == "Resiembra" ||
            test.analysisType == "Control"
          ) {
            form = {
              _id: test.isolate._id,
              type: "Isolate",
            };
          } else if (
            test.analysisType == "Hongo" ||
            test.analysisType == "Bacteria" ||
            test.analysisType == "Todos"
          ) {
            form = {
              _id: test.mbTestData._id,
              type: "MB Test",
            };
          } else if (
            test.analysisType == "Molecular"
          ) {
            form = {
              _id: test._id,
              type: "Molecular",
            };
          }else {
            form = {
              _id: test._id,
              analysisType: test.analysisType,
              type: "BQ Test",
            };
          }

          return form;
        });

        try {
          for (const test of testsForm) {
            if (test.type == "MB Test") {
              const response = await this.deleteMicrobiologicalTestById({id : test._id, user : this.user._id, laboratory : this.user.laboratory._id}
              );
              if (response.error) throw response;
            } else if (test.type == "Isolate") {
              const response = await this.deleteIsolateById(test._id);
              if (response.error) throw response;
            } else if (test.type == "BQ Test") {
              if (test.analysisType.includes("S.")) {
                const response = await this.removeNutritionTest(test._id);
                if (response.error) {
                  throw response;
                }
              } else if (
                test.analysisType.includes("P.") ||
                test.analysisType.includes("A.")
              ) {
                const response = await this.removeBiostimulationTest(test._id);
                if (response.error) {
                  throw response;
                }
              } else {
                const response = await this.removeAntagonismTest(test._id);
                if (response.error) {
                  throw response;
                }
              }
            } else if (test.type == "Molecular") {
              const response = await this.deleteMolecularTest(test._id);
              
              if (response.error) {
                throw response;
              }
            }
          }

          this.$emit("testsDeleted");
          this.dialog = false;
          this.loading = false;

          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: "Ensayo/s eliminados correctamente",
            timeout: 4000,
          });
        } catch (e) {
          console.log(e);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: "Error al eliminar ensayos, comunicate a soporte técnico para dar seguimiento.",
            timeout: 4000,
          });
          this.loading = false;
        }
      } else {
        this.loading = false;
        this.SET_ALERT_TYPE({
          visible: true,
          type: "warning",
          text: "No hay ensayos por revertir",
          timeout: 4000,
        });
      }
    },
  },
  watch: {
    tests() {
      this.hasTests = false;

      if (this.tests.length > 0) {
        this.hasTests = true;
      }
    },
  },
};
</script>

<style></style>
