import axios from "axios";

/**
 * Function to create a new antagonism test
 * @param {Object} context - Vuex context.
 * @param {Object} payload - Antagonism info.
 */
export async function addAntagonismTest(context, payload) {
  try {
    const { data } = await axios.post("/antagonismTest/add", payload);
    // Fetch antagonism list.
    const response = await context.dispatch(
      "getAntagonismTests",
      data.BIOCHEMICAL_TEST._id
    );
    if (response.error) {
      throw response;
    }
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get all antagonism tests by biochemical test id.
 * @param {Object} context - Vuex context.
 * @param {Object} payload - Biochemical test id.
 */
export async function getAntagonismTests({ commit, rootState }, payload) {
  try {
    const { data } = await axios.get(`/antagonismTest`, {
      params: {
        biochemicalTestId: payload,
      },
    });
    // add control to the list
    const isolate = rootState.isolate.isolateSelected;
    commit("SET_ANTAGONISM_TEST_LIST", [isolate, ...data.ANTAGONISM_LIST]);
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to update an antagonism test.
 * @param {Object} context - Vuex context.
 * @param {Object} payload - antagonism test new info.
 */
export async function updateAntagonismTest(context, payload) {
  try {
    const { data } = await axios.put(
      `/antagonismTest/update/${payload._id}`,
      payload
    );
    // Fetch antagonism list.
    const response = await context.dispatch(
      "getAntagonismTests",
      data.UPDATED_ANATAGONISM_TEST.biochemicalTest
    );
    if (response.error) {
      throw response;
    }
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to remove a  antagonism test
 * @param {Object} context - Vuex context.
 * @param {Object} payload - antagonism test _id.
 */
 export async function removeAntagonismTest(context, payload) {
  try {
    const { data } = await axios.delete(
      `/antagonismTest/remove/${payload}`,
      payload
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}
