<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card flat class="mb-5 pa-2" min-height="80vh">
          <v-card-text class="black--text">
            <v-row>
              <v-tabs
                v-model="labelsTabSelected"
                slider-color="secondary"
                height="35"
                grow
                centered
              >
                <v-tab href="#cultureMedias" v-if="permissions.find(per => per == 'lab_medias')"> Medios de cultivo </v-tab>
                <v-tab href="#plates" v-if="permissions.find(per => per == 'lab_qpcr_plates')"> Placas CMDA </v-tab>
              </v-tabs>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-tabs-items v-model="labelsTabSelected">
                  <v-tab-item value="cultureMedias" v-if="permissions.find(per => per == 'lab_medias')">
                    <v-container>
                      <v-row
                        class="px-3"
                        :justify="
                          $vuetify.breakpoint.xsOnly
                            ? 'center'
                            : $vuetify.breakpoint.mdAndDown
                            ? 'space-between'
                            : null
                        "
                        align="center"
                      >
                        <v-col cols="12" sm="12" md="8" lg="9">
                          <v-row justify="start">
                            <h3 class="title--gray">
                              Inoculación de diluciones de suelo
                            </h3>
                          </v-row>
                        </v-col>
                        <!-- create mbtest -->
                        <v-col cols="12" sm="6" md="3" lg="3">
                          <v-row
                            :justify="
                              $vuetify.breakpoint.mdAndDown ? 'center' : 'end'
                            "
                            class="mt-1"
                          >
                            <NewMicrobiological />
                          </v-row>
                        </v-col>
                        <!-- search -->
                        <v-col cols="10" sm="6" md="7" lg="5">
                          <v-row class="mt-1">
                            <v-text-field
                              v-model="search"
                              clearable
                              placeholder="Buscar"
                              prepend-inner-icon="mdi-magnify"
                            ></v-text-field>
                          </v-row>
                        </v-col>
                        <!-- contaminated -->
                        <v-col cols="12" sm="6" md="3" lg="4">
                          <v-row
                            :justify="
                              $vuetify.breakpoint.mdAndDown ? 'center' : 'end'
                            "
                            class="mt-1"
                          >
                            <CultureMediaContaminated
                              :selectedItems="selectedItems"
                            />
                          </v-row>
                        </v-col>
                        <!-- start incubation -->
                        <v-col cols="12" sm="6" md="12" lg="3">
                          <v-row
                            :justify="
                              $vuetify.breakpoint.mdAndDown ? 'center' : 'end'
                            "
                            class="mt-1"
                          >
                            <v-btn
                              depressed
                              color="secondary"
                              :disabled="selectedItems.length === 0"
                              @click="incubateTests"
                            >
                              Iniciar incubación
                            </v-btn>
                          </v-row>
                        </v-col>
                      </v-row>
                      <!-- Table -->
                      <v-row>
                        <v-col cols="12">
                          <v-data-table
                            id="table-mb"
                            v-model="selectedItems"
                            :headers="headers"
                            :items="itemsMBTest"
                            :items-per-page="5"
                            :loading="loading"
                            disable-pagination
                            disable-filtering
                            hide-default-footer
                            show-select
                            sort-by=""
                            no-data-text="No se han agregado análisis"
                            no-results-text="No se encontraron resultados"
                            loading-text="Cargando..."
                            :header-props="{
                              sortByText: 'Ordenar por',
                            }"
                          >
                            <template v-slot:[`item.inoculum`]="{ item }">
                              <template v-if="item.inoculum == 'M. Vegetal'">
                                <span class="font-weight-bold secondary--text"
                                  >Material Vegetal</span
                                >
                              </template>
                              <template v-else>
                                Dilución
                                <span class="font-weight-bold"
                                  >({{ item.inoculum }})</span
                                >
                              </template>
                            </template>
                            <!-- item status -->
                            <template v-slot:[`item.testStatus`]="{ item }">
                              <span :class="getColor(item.testStatus)">
                                {{ item.testStatus }}
                              </span>
                            </template>
                            <!-- checkbox -->
                            <template
                              v-slot:[`item.data-table-select`]="{
                                item,
                                isSelected,
                                select,
                              }"
                            >
                              <v-simple-checkbox
                                :value="isSelected"
                                :ripple="false"
                                :readonly="item.disabled"
                                :disabled="item.disabled"
                                @input="select($event)"
                              ></v-simple-checkbox>
                            </template>
                          </v-data-table>
                        </v-col>
                        <v-col cols="12">
                          <v-pagination
                            v-model="page"
                            @input="updatePagination"
                            :length="length"
                            total-visible="12"
                            color="primary"
                          ></v-pagination>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-tab-item>
                  <v-tab-item value="plates" v-if="permissions.find(per => per == 'lab_qpcr_plates')">
                    <v-container>
                      <v-row>
                        <v-col cols="6">
                          <h3 class="title--gray">Placas</h3>
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col cols="4">
                          <v-text-field
                            v-model="searchPlate"
                            clearable
                            placeholder="Buscar"
                            prepend-inner-icon="mdi-magnify"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-btn color="success" icon @click="getPlatesDB"
                            ><v-icon>mdi-table-sync</v-icon></v-btn
                          >
                        </v-col>
                        <!-- <v-col cols="4">
                          <v-btn
                            color="gray"
                            class="white--text"
                            @click="platesContaminated"
                            >Placa contaminada</v-btn
                          >
                        </v-col> -->
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-data-table
                            v-model="selectedPlates"
                            :headers="headersPlates"
                            :items="itemsPlates"
                            :items-per-page="paginationPlate.limit"
                            :loading="loadingPlate"
                            no-data-text="No hay placas por mostrar"
                            item-key="_id"
                            show-select
                            hide-default-footer
                            dense
                          >
                            <template v-slot:[`item.type`]="{ item }">
                              <span v-if="item.type == 'PURIFICATION'">
                                Placa de pozo
                              </span>
                              <span v-if="item.type == 'ELUTION'">
                                Placa PCR
                              </span>
                              <span v-if="item.type == 'AMPLIFICATION'">
                                Rack MIC
                              </span>
                            </template>
                            <template v-slot:[`item.tests`]="{ item }">
                              <span v-if="item.type == 'PURIFICATION'">
                                {{ item.wells.length }}/16
                              </span>
                              <span v-if="item.type == 'ELUTION'">
                                {{ item.wells.length }}/96
                              </span>
                              <span v-if="item.type == 'AMPLIFICATION'">
                                {{ item.wells.length }}/48
                              </span>
                            </template>
                            <template v-slot:[`item.replicaPlate`]="{ item }">
                              <v-switch
                                v-if="item.type == 'ELUTION'"
                                v-model="item.replicaPlate"
                                :disabled="user.role !== 'USER_LAB_ADMIN' || item.status != 'UNREGISTERED'"
                                @change="updatePlateStatus(item)"
                              ></v-switch>
                              <v-switch
                                v-else
                                disabled
                              ></v-switch>
                            </template>
                            <template v-slot:[`item.disponibility`]="{ item }">
                              <span v-if="item.type == 'PURIFICATION'">
                                {{
                                  (
                                    ((16 - item.wells.length) * 100) /
                                    16
                                  ).toFixed(2)
                                }}%
                              </span>
                              <span v-if="item.type == 'ELUTION'">
                                {{
                                  (
                                    ((96 - item.wells.length) * 100) /
                                    96
                                  ).toFixed(2)
                                }}%
                              </span>
                              <span v-if="item.type == 'AMPLIFICATION'">
                                {{
                                  (
                                    ((48 - item.wells.length) * 100) /
                                    48
                                  ).toFixed(2)
                                }}%
                              </span>
                            </template>
                            <template v-slot:[`item.stage`]="{ item }">
                              <span v-if="item.type == 'PURIFICATION'">
                                Purificación
                              </span>
                              <span v-if="item.type == 'ELUTION'">
                                Elución
                              </span>
                              <span v-if="item.type == 'AMPLIFICATION'">
                                Amplificación
                              </span>
                            </template>
                            <template v-slot:[`item.analysisType`]="{}">
                              <span> - </span>
                            </template>
                            <template v-slot:[`item.status`]="{ item }">
                              <span v-if="item.status == 'UNREGISTERED'">
                                Sin registrar {{ item.createdAt }}
                              </span>
                              <span
                                class="warning--text"
                                v-if="item.status == 'CONTAMINATED'"
                              >
                                Contaminada {{ item.updatedAt }}
                              </span>
                              <span
                                class="secondary--text"
                                v-if="item.status == 'IN_PROGRESS'"
                              >
                                En progreso {{ item.updatedAt }}
                              </span>
                              <span
                                class="primary--text"
                                v-if="item.status == 'COMPLETED'"
                              >
                                Completada {{ item.updatedAt }}
                              </span>
                            </template>
                            <template v-slot:[`item.tools`]="{ item }">
                              <template v-if="item.type == 'ELUTION'">
                                <PlatePdfPreview
                                  :plateId="item._id"
                                  :status="item.status"
                                />
                                <v-btn
                                  color="secondary"
                                  icon
                                  :disabled="item.status != 'IN_PROGRESS' && item.status != 'COMPLETED'"
                                  @click="downloadCsvElution(item._id)"
                                  ><v-icon>mdi-file-excel</v-icon></v-btn
                                >
                              </template>
                              <template v-else>
                                <PlatePdfPreview
                                  :plateId="item._id"
                                  :status="item.status"
                                />
                              </template>
                            </template>
                          </v-data-table>
                        </v-col>
                        <v-col cols="12">
                          <v-pagination
                            v-model="pagePlate"
                            @input="updatePaginationPlate"
                            :length="lengthPlate"
                            total-visible="12"
                            color="primary"
                          ></v-pagination>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// vuex
import { mapState, mapActions, mapMutations } from "vuex";
// components
import CultureMediaContaminated from "./modals/CultureMediaContaminated.vue";
import NewMicrobiological from "./modals/NewMicrobiological.vue";
import PlatePdfPreview from "./modals/PlatePdfPreview.vue";
// import AddMorphology from "./modals/AddMorphology.vue";
// library
import dayjs from "dayjs";
import axios from "axios";

import { createObjectCsvWriter } from "csv-writer";

export default {
  name: "MBTable",
  components: {
    CultureMediaContaminated,
    NewMicrobiological,
    PlatePdfPreview,
  },
  data() {
    return {
      loading: false,
      loadingPlate: false,
      search: "",
      searchPlate: "",
      mediaContaminated: [],
      listIsReady: false,
      // for disabled select
      disabledCount: 0,
      // table headers
      headers: [
        {
          text: "Folio de análisis",
          value: "idAnalysis",
        },
        { text: "ID ensayo", value: "idTestMedia" },
        { text: "Medio", value: "cultureMedia" },
        { text: "Inóculo", value: "inoculum" },
        { text: "Tipo de muestra", value: "sampleType" },
        { text: "Razón social del cliente", value: "companyName" },
        { text: "Tipo de análisis", value: "testType" },
        { text: "Estatus de análisis", value: "testStatus" },
      ],
      headersPlates: [
        { text: "ID de unidad", value: "folio" },
        { text: "Unidad", value: "type" },
        { text: "Muestras", value: "tests" },
        { text: "Disponibilidad", value: "disponibility" },
        { text: "Etapa del análisis", value: "stage" },
        // { text: "Tipo de análisis", value: "analysisType" },
        { text: "Estatus de análisis", value: "status" },
        // { text: "Placa de r.", value: "replicaPlate" },
        { text: "Herramientas", value: "tools" },
      ],
      // table items
      itemsMBTest: [],
      selectedItems: [],

      itemsPlates: [],
      selectedPlates: [],
      // pagination
      page: 1,
      count: 0,
      pagination: {
        skip: 0,
        limit: 12,
        skipContaminated: 0,
        limitContaminated: 2,
      },

      pagePlate: 1,
      countPlate: 0,
      paginationPlate: {
        skip: 0,
        limit: 12,
      },
      // user data
      user: JSON.parse(localStorage.getItem("user")),
      labelsTabSelected: "plates",
    };
  },
  async created() {
    this.labelsTabSelected = this.permissions.find(per => per == 'lab_medias') ? "cultureMedias" : "plates";
    
    await this.getMBTests();
    await this.getPlatesDB();
  },
  watch: {
    // search mbtest
    async search(value) {
      this.pagination.skip = 0;
      this.pagination.skipContaminated = 0;
      this.page = 1;

      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }

      //Save the cancel token for the current request
      this.SET_CANCEL_TOKEN(axios.CancelToken.source());
      await this.getMBTests();
    },
    async searchPlate(value) {
      this.paginationPlate.skip = 0;
      this.pagePlate = 1;

      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }

      //Save the cancel token for the current request
      this.SET_CANCEL_TOKEN(axios.CancelToken.source());
      await this.getPlatesDB();
    },

    // if microbiologicalTestList is updated, put initial values and format new data
    microbiologicalTestList: {
      handler(newValue, oldValue) {
        if (newValue.length > 0) {
          // this.selectedItems = [];
          this.listIsReady = true;
          this.formatDataTable();
        } else {
          this.listIsReady = false;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("reception", ["receptions"]),
    ...mapState("microbiologicalTest", ["microbiologicalTestList"]),
    ...mapState("session", ["permissions"]),

    // length of the paginator items
    length() {
      return Math.ceil(this.count / 12);
    },
    lengthPlate() {
      return Math.ceil(this.countPlate / 12);
    },
  },
  methods: {
    ...mapMutations(["SET_CANCEL_TOKEN"]),
    ...mapMutations(["SET_LOADER"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("microbiologicalTest", ["getMBTestList", "incubateMBTests"]),
    ...mapActions("molecularPlate", ["getPlates", "updatePlate", "setReplicaPlate"]),
    ...mapActions("molecularPlateWell", ["getPlateWellSampleData"]),
    async updatePlateStatus(plate){
      try{
        await this.setReplicaPlate({
          plate : plate._id, 
          status : plate.replicaPlate
        });

        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: "Placa actualizada correctamente.",
          timeout: 4000,
        });
      }catch(e){
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: e.message,
          timeout: 4000,
        });
      }
    },
    async getMBTests() {
      this.loading = true;
      try {
        const response = await this.getMBTestList({
          laboratory: this.user.laboratory._id,
          skip: this.pagination.skip,
          limit: this.pagination.limit,
          status: ["INOCULATED", "CONTAMINATED"],
          search: this.search,
        });

        if (response.error) throw response;

        // add counted for pagination
        this.count = response.COUNT;

        this.formatDataTable();
      } catch (error) {
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: error.message,
          timeout: 4000,
        });
      }
      this.loading = false;
    },
    async getPlatesDB() {
      this.loadingPlate = true;
      try {
        const response = await this.getPlates({
          laboratory: this.user.laboratory._id,
          skip: this.paginationPlate.skip,
          limit: this.paginationPlate.limit,
          search: this.searchPlate,
        });

        if (response.error) throw response;

        // add counted for pagination
        this.countPlate = response.COUNT;
        this.itemsPlates = response.plates.map((plate) => {
          return {
            ...plate,
            isSelectable: !(
              plate.status == "CONTAMINATED" || plate.status == "COMPLETED"
            ),
          };
        });
      } catch (error) {
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: error.message,
          timeout: 4000,
        });
      }
      this.loadingPlate = false;
    },
    async platesContaminated() {
      try {
        if (this.selectedPlates.length > 0) {
          for (const plate of this.selectedPlates) {
            let request = await this.updatePlate({
              ...plate,
              status: "CONTAMINATED",
            });

            if (request.error) throw request;
          }

          this.selectedPlates = [];
          await this.getPlatesDB();

          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: "Placas actualizadas correctamente.",
            timeout: 4000,
          });
        } else {
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: "No se han seleccionado placas.",
            timeout: 4000,
          });
        }
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: error.message,
          timeout: 4000,
        });
      }
    },
    // when page changes update table and pagination
    async updatePagination(page) {
      try {
        this.page = page;
        this.pagination.skip = (page - 1) * 12;
        this.pagination.skipContaminated = (page - 1) * 2;
        await this.getMBTests();
      } catch (error) {
        console.log("Error", error);
      }
    },
    // when page changes update table and pagination
    async updatePaginationPlate(page) {
      try {
        this.pagePlate = page;
        this.paginationPlate.skip = (page - 1) * 12;
        await this.getPlatesDB();
      } catch (error) {
        console.log("Error", error);
      }
    },

    // incubate selected tests
    async incubateTests() {
      this.SET_LOADER(true);
      try {
        if (this.selectedItems.length > 0) {
          let laboratory = this.user.laboratory._id;
          // get list of ids
          let arrayIDs = this.selectedItems.map((item) => {
            return item.id;
          });

          const response = await this.incubateMBTests({ arrayIDs, laboratory });
          if (response.error) throw response;

          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: `Medios incubados correctamente`,
            timeout: 4000,
          });
        }
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: error.message,
          timeout: 4000,
        });
      }
      this.SET_LOADER(false);
    },

    handleClick(item) {
      console.log("entre a Handle");
    },

    // color text based on the status
    getColor(value) {
      if (value.includes("Inoculado")) return "primary--text";
      else if (value.includes("Incubando")) return "";
      else return "grey--text";
    },

    // if all items are select, don't select disabled items
    selectAllToggle(props) {
      if (
        this.selectedItems.length !=
        this.itemsMBTest.length - this.disabledCount
      ) {
        this.selectedItems = [];
        const self = this;
        props.items.forEach((item) => {
          if (!item.disabled) {
            self.selectedItems.push(item);
          }
        });
      } else this.selectedItems = [];
    },

    getStatus(value) {
      const status = {
        NOT_STARTED: "No iniciado",
        INOCULATED: "Inoculado",
        INCUBATING: "Incubando",
        IDENTIFYING: "Idenficano",
        COMPLETED: "Completada",
        CONTAMINATED: "Contaminado",
      };
      return status[value] || "---";
    },
    getMoType(value) {
      let types = {
        all: "Todos",
        bacteria: "Bacteria",
        fungi: "Hongo",
        nematode: "Nemátodo",
      };
      return types[value];
    },
    getSampleType(value) {
      // console.log("HERE");
      // console.log(value);
      const tracingStatus = {
        Initial: "Inicial",
        Monitoring: "Seguimiento",
        INITIAL: "Inicial",
        MONITORING: "Seguimiento",
      };
      if (value) {
        if (value.tracingStatus) {
          return tracingStatus[value.tracingStatus];
        } else {
          return "- - -";
        }
      } else {
        return "- - -";
      }
    },
    dateFormat(value) {
      const date = `${dayjs(value).format("DD-MM-YYYY - HH:mm")} hrs`;
      return date ? date : "----";
    },

    formatDataTable() {
      if (this.listIsReady) {
        let list = [];
        this.disabledCount = 0;
        this.itemsMBTest = this.microbiologicalTestList.map((item) => {
          let status = this.getStatus(item.status);
          if (status.includes("Contaminado")) this.disabledCount++;

          return {
            id: item._id,
            idAnalysis:
              (item.soilSample &&
                item.soilSample.container &&
                item.soilSample.container.reception &&
                item.soilSample.container.reception.folioAnalysis) ||
              (item.soilSample && item.soilSample.kit) ||
              "---",
            idTestMedia: item.testMedia._id,
            cultureMedia: item.testMedia.cultureMedia.nutrientAgar,
            inoculum: item.inoculation ? item.inoculation.dilution : "---",
            sampleType: this.getSampleType(item.soilSample),
            companyName:
              (item.soilSample &&
                item.soilSample.container &&
                item.soilSample.container.client.companyName) ||
              "---",
            testType: this.getMoType(item.testMedia.cultureMedia.moType),
            testStatus: status.includes("Contaminado")
              ? `${status} ${
                  item.updatedAt ? this.dateFormat(item.updatedAt) : ""
                } `
              : `${status} ${
                  item.inoculation ? this.dateFormat(item.inoculation.date) : ""
                } `,
            disabled: status.includes("Contaminado"),
            isSelectable : !status.includes("Contaminado"),
            date: status.includes("Contaminado")
              ? item.updatedAt
              : item.inoculation
              ? item.inoculation.date
              : "",
          };
        });
      }
    },

    async downloadCsvElution(_idPlate) {
      try {
        let request = await this.getPlateWellSampleData(_idPlate);
        if (request.error) throw request;

        let headers = {
          Well: "Well",
          "Source Name": "Source Name",
          Groups: "Groups",
          "": "",
          "ID PLATE": "ID PLATE",
        };
        let wellsData = [];
        for (const well of request.plate.wells) {
          let data = null;

          let plateFolio = request.plate.folio;
          data = {
            Well: `${well.wellPlate[1]}${well.wellPlate[0]}`,
            "Source Name": well.soilSample.folioAnalysis,
            Groups: well.soilSample.sampleType && well.soilSample.sampleType.includes("VEGETABLE")
              ? "Tejido vegetal"
              : "Suelo",
            "": "",
            "ID PLATE": "",
          };

          data[plateFolio] = "";
          headers[plateFolio] = plateFolio;

          wellsData.push(data);
        }

        this.exportCSVFile(headers, wellsData, request.plate.folio)

      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: error.message,
          timeout: 4000,
        });
      }
    },
    exportCSVFile(headers, items, fileTitle) {
      if (headers) {
        items.unshift(headers);
      }

      // Convert Object to JSON
      var jsonObject = JSON.stringify(items);

      var csv = this.convertToCSV(jsonObject);

      var exportedFilenmae = fileTitle + ".csv" || "export.csv";

      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    convertToCSV(objArray) {
      var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      var str = "";

      for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
          if (line != "") line += ",";

          line += array[i][index];
        }

        str += line + "\r\n";
      }

      return str;
    },
  },
};
</script>

<style scoped></style>
