import axios from "axios";

/**
 * Function to create a new isolate
 * @param {Object} { dispatch } - vuex functions
 * @param {Object} isolate - isolate info
 */
export async function addIsolate({ dispatch }, payload) {
  try {
    const { data } = await axios.post(`/isolate/add`, payload.isolate);
    if (data.error) throw data;
    const response = await dispatch("getIsolateById", {
      id: data.isolate._id,
      isReseeding: payload.isReseeding,
    });
    if (response.error) throw response;
    return data;
  } catch (e) {
    console.log("agregando isolate error", e);
    return e.response.data;
  }
}

/**
 * Function to get isolates by filters
 * @param {Object} { commit } - vuex function
 * @param {Object} filters - to filter isolates
 * @returns array of isolates
 */
export async function getIsolates({ commit }, filters) {
  try {
    const { data } = await axios.get(`/isolate`, { params: { filters } });
    if (data.error) {
      throw data;
    }
    commit("SET_LIST", data.ISOLATES);
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get isolate by id
 * @param {*} { commit } - vuex function
 * @param {*} id - isolate id
 */
export async function getIsolateById({ commit, rootState }, payload) {
  try {
    const { data } = await axios.get(`/isolate/${payload.id}`);
    if (data.error) throw data;
    commit("SET_ISOLATE", data.ISOLATE);
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to update an isolate
 * @param {Object} { dispatch } - vuex function
 * @param {Object} isolate - isolate info
 */
export async function updateIsolate({ dispatch }, isolate) {
  try {
    const { data } = await axios.put(`/isolate/update/${isolate._id}`, isolate);
    if (data.error) throw data;

    const response = await dispatch("getIsolateById", { id: data.ISOLATE._id });
    if (response.error) throw response;
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to upload isolate photo to aws s3
 * @param {Object} { state, dispatch } - vuex functions
 * @param {Image} image - isolate image
 */
export async function uploadIsolatePhoto({ state, dispatch }, image) {
  try {
    const { data } = await axios.post("/upload-image", image);
    const isolate = state.isolateSelected;
    const update = {
      _id: isolate._id,
      incubation: {
        startDate: isolate.incubation.startDate,
        endDate: new Date(),
        temperature: isolate.incubation.temperature,
        morphologyPhoto: data.urlImg,
        incubator: isolate.incubation.incubator,
      },
    };
    const response = await dispatch("updateIsolate", update);
    if (response.error) throw response;
    return data;
  } catch (error) {
    return error;
  }
}

/**
 * Function to delete microbiological test by id
 * @param {Object} { commit } - vuex function
 * @param {String} id - microbiological test id
 */
export async function deleteIsolateById({ commit }, id) {
  try {
    const { data } = await axios.delete(`/isolate/remove/${id}`);
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to interupt isolate test by id
 * @param {Object} { commit } - vuex function
 * @param {String} payload - isolate test id
 */
export async function interruptIsolate({ commit }, payload) {
  try {
    const { data } = await axios.put(`/isolate/interrupt`, payload);
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 *
 * @param {Objecy} { commit } - vuex functions
 * @param {*} payload - strain data
 * @returns
 */
export async function findPossiblesStrainsReseeds({ commit }, payload) {
  try {
    const { data } = await axios.get(`/isolate/find-possibles-mo-samples`, {
      params: payload,
    });
    if (data.error) throw data;

    return data;
  } catch (e) {
    console.log("Error al buscar posibles cepas", e);
    return e.response.data;
  }
}

/**
 *
 * @param {Objecy} { commit } - vuex functions
 * @param {*} payload - reseed strain data
 * @returns
 */
export async function updateSamplePreservedIsolate({ commit }, payload) {
  try {
    const { data } = await axios.put(
      `/isolate/update-sample-reseed-isolate`,
      payload
    );
    if (data.error) throw data;

    return data;
  } catch (e) {
    console.log("Error al actualizar la resiembra", e);
    return e.response.data;
  }
}

/**
 * Function to get isolates for preservation
 * @param  {function} {commit} - Vuex function
 * @param {Object} { rootState } - Vuex store object
 * @param {payload} payload - laboratory, skip, limit, status, search
 * @returns {data} -  Returns array of mbtest
 */
export async function getPreservedIsolatesListS3(
  { commit, rootState },
  payload
) {
  try {
    const { data } = await axios.get("/isolate/laboratory", {
      params: payload,
      cancelToken: rootState.cancelToken && rootState.cancelToken.token,
    });

    console.log(data);
    if (data.error) throw data;
    commit("SET_ISOLATES_LIST", data.MB_TESTS);
    commit("SET_COUNT", data.COUNT);
  } catch (e) {
    console.error("Error getting isolates", e);
    return e.response ? e.response.data : e;
  }
}

/**
 *
 * @param {Objecy} { commit } - vuex functions
 * @param {*} payload - reseed strain data
 * @returns
 */
export async function preserveStrain({ commit }, payload) {
  try {
    const { data } = await axios.post(`/isolate/preserve-strain`, payload);
    if (data.error) throw data;

    return data;
  } catch (e) {
    console.log("Error al preservar", e);
    return e.response.data;
  }
}
