<template>
  <v-row>
    <v-col cols="12" md="12" class="my-2 mx-2" id="parent-col">
      <v-zoomer
        ref="zoomer"
        style="width: 97%; height: 100%; border: solid 1px silver"
        :doubleClickToZoom="false"
      >
        <editor
          :canvasWidth="widthParent"
          :canvasHeight="widthParent"
          ref="editor"
          editorId="canvasId"
        />
      </v-zoomer>
    </v-col>
    <!-- Action buttons -->
    <template v-if="editor">
      <v-col cols="6">
        <v-row justify="center">
          <v-btn color="secondary" fab small outlined @click="repeatRect">
            <v-icon>mdi-arrow-u-left-bottom</v-icon>
          </v-btn>
        </v-row>
        <v-row justify="center">
          <p class="font-weight-bold secondary--text text-center text-caption">
            Repetir polígono
          </p>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row justify="center">
          <v-btn color="secondary" fab small outlined @click="drawRect">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
        <v-row justify="center">
          <p class="font-weight-bold secondary--text text-center text-caption">
            Agregar colonia
          </p>
        </v-row>
      </v-col>
    </template>

    <!-- <v-btn @click="getFormID">FORM ID</v-btn>
    <v-btn @click="disableRect">DISABLE RECT</v-btn>
    <v-btn @click="enableRect">ENABLE RECT</v-btn> -->
  </v-row>
</template>

<script>
import Editor from "vue-image-markup";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    Editor,
  },
  props: {
    widthParent: {
      type: String,
      require: true,
    },
    imageUrl: {
      type: String,
      require: true,
    },
    colonies: {
      type: Array,
      require: true,
    },
    editor: {
      type: Boolean,
      require: true,
    },
    reseed: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {};
  },
  async mounted() {
    await this.getImage();

    this.$refs.editor.setBackgroundB64Image(this.imageUrlB64);

    // this.$refs.editor.setBackgroundImage(
    //   this.imageUrl
    //   // 'https://uc.uxpin.com/files/1185737/1142914/uxpmod_392d94fee0b24704befb2045cc9c6861_144444439_image_d7149c927c64c1d2673dec3c216f6e17_4f23da-c7097fa8f608dfc77ab59bc98958f01e-642d17.png'
    // );

    if (!this.reseed) {
      let canvasElement = document.getElementById("parent-col");

      canvasElement.addEventListener(
        "mouseup",
        (event) => {
          this.$refs.editor.set("selectMode");
          let coloniesObjects = this.getColoniesCoords();
          if (coloniesObjects.length != this.colonies.length) {
            this.$emit("colonieCreated", coloniesObjects);
          } else {
            // if (this.editor) {
            //   this.$emit("colonieEdited", coloniesObjects);
            // }
          }
        },
        false
      );

      for (const colonie of this.colonies) {
        let coords = this.scaleLabels(colonie);
        this.$refs.editor.set("rect", {
          stroke: "#04c105",
          strokeWidth: 3,
          ...coords,
          id: colonie.label,
          strokeUniform: true,
        });

        let textModeOptions = {
          id: `label-${colonie.label}`,
          fill: "white",
          backgroundColor: "#04c105",
          fontFamily: "Verdana",
          fontSize: 13,
          placeholder: colonie.label,
          top: coords.top,
          left: coords.left,
        };
        this.$refs.editor.set("text", textModeOptions);
      }
    }
  },
  methods: {
    ...mapActions("utils", ["getb64Image"]),
    getFormID() {
      console.log(this.$refs.editor.getObjectsById("rect1"));
    },
    disableRect() {
      this.$refs.editor.set("selectMode");
      this.getFormID();
    },
    enableRect() {
      this.$refs.editor.set("rect", {
        stroke: "#04c105",
        strokeWidth: 3,
        strokeUniform: true,
      });
    },
    drawRect() {
      this.$refs.editor.set("rect", {
        stroke: "#04c105",
        strokeWidth: 3,
        id: "Nueva colonia",
        strokeUniform: true,
      });
      // console.log(this.$refs.editor.getAllObjects());
    },
    repeatRect() {
      this.$refs.editor.undo();
      this.$refs.editor.undo();

      let coloniesObjects = this.getColoniesCoords();
      if (coloniesObjects.length != this.colonies.length) {
        this.$emit("colonieRemoved", coloniesObjects);
      }
    },
    getColoniesCoords() {
      let objects = this.$refs.editor.getAllObjects();
      let colonies = [];
      for (const object of objects) {
        if (!object.id.includes("label-")) {
          let colonie = {
            width: object.width,
            height: object.height,
            top: object.top,
            left: object.left,
            label: object.id,
            imageProperties: {
              width: this.widthParent,
              height: this.widthParent,
            },
          };
          colonies.push({ ...colonie });
        }
      }
      return colonies;
    },
    scaleLabels(coords) {
      let result = {
        height: 0,
        width: 0,
        top: 0,
        left: 0,
      };

      if (coords.imageProperties != null) {
        const actualResolution = {
          width: this.widthParent,
          height: this.widthParent,
        };

        const originalResolution = {
          width: coords.imageProperties.width,
          height: coords.imageProperties.height,
        };

        result.height =
          (actualResolution.height * coords.height) / originalResolution.height;

        result.top =
          (actualResolution.height * coords.top) / originalResolution.height;

        result.width =
          (actualResolution.width * coords.width) / originalResolution.width;
        result.left =
          (actualResolution.width * coords.left) / originalResolution.width;
      } else {
        const actualResolution = {
          width: this.widthParent,
          height: this.widthParent,
        };

        const originalResolution = {
          width: 552.01,
          height: 400,
        };

        result.height =
          (actualResolution.height * coords.height) / originalResolution.height;
        result.top =
          (actualResolution.height * coords.top) / originalResolution.height;

        result.width =
          (actualResolution.width * coords.width) / originalResolution.width;
        result.left =
          (actualResolution.width * coords.left) / originalResolution.width;

        // result.height = coords.height
        // result.top = coords.top
        // result.width = coords.width
        // result.left = coords.left
      }

      return result;
    },
    async getImage() {
      await this.getb64Image(this.imageUrl);
    },
  },
  watch: {
    async colonies() {
      if (!this.reseed) {
        this.$refs.editor.clear();
        this.$refs.editor.setBackgroundB64Image(
          this.imageUrlB64
          // 'https://uc.uxpin.com/files/1185737/1142914/uxpmod_392d94fee0b24704befb2045cc9c6861_144444439_image_d7149c927c64c1d2673dec3c216f6e17_4f23da-c7097fa8f608dfc77ab59bc98958f01e-642d17.png'
        );

        for (const colonie of this.colonies) {
          let coords = this.scaleLabels(colonie);
          let color = "white";

          if (typeof colonie.threat != "undefined") {
            if (colonie.threat) {
              color = "orange";
            } else {
              color = "green";
            }
          } else {
            if (this.editor) {
              color = "green";
            } else {
              color = "gray";
            }
          }

          this.$refs.editor.set("rect", {
            stroke: color,
            strokeWidth: 3,
            ...coords,
            id: colonie.label,
            strokeUniform: true,
          });

          let textModeOptions = {
            id: `label-${colonie.label}`,
            fill: color != "orange" ? "white" : "black",
            backgroundColor: color,
            fontFamily: "Verdana",
            fontSize: 13,
            placeholder: colonie.label,
            top: coords.top,
            left: coords.left,
          };
          this.$refs.editor.set("text", textModeOptions);
        }
      }
    },
    async imageUrl(newValue, oldValue) {
      if (newValue != oldValue) {
        await this.getImage();

        this.$refs.editor.clear();
        this.$refs.editor.setBackgroundB64Image(
          this.imageUrlB64
          // 'https://uc.uxpin.com/files/1185737/1142914/uxpmod_392d94fee0b24704befb2045cc9c6861_144444439_image_d7149c927c64c1d2673dec3c216f6e17_4f23da-c7097fa8f608dfc77ab59bc98958f01e-642d17.png'
        );
      }
    },
  },
  computed: {
    ...mapState("utils", ["imageUrlB64"]),
  },
};
</script>

<style></style>
