import axios from "axios";

export async function getProjects({ commit }, payload) {
  try {
    const { data } = await axios.get(`/projects`, {
      params: payload,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function assingPackage({ commit }, payload) {
  try {
    const { data } = await axios.put(`/project/assing-package`, payload.body, {
      params: payload.query,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function generateReport({ commit }, payload) {
  try {
    const { data } = await axios.get(`/project/generate-report`, {
      params: payload,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function sendReport({ commit }, payload) {
  try {
    const { data } = await axios.post(`/project/send-report`, payload);
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function finishProject({ commit }, payload) {
  try {
    const { data } = await axios.post(`/project/finish-project`, payload);
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}

export async function updateProjectPackageResultActon({ commit }, payload) {
  try {
    const { data } = await axios.put(
      `/project/update-project-package`,
      payload.body,
      {
        params: payload.query,
      }
    );
    if (data.error) throw data;
    return data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
}
