/**
 * General validation for an input
 * @param    {Boolean}   required    Specifies if the field is required.
 * @param    {Number}    length      Specifies the field limit.
 * @param    {String}    name        Specifies the field name.
 * @return   {Array<Fn>}             Array with the rules
 */

export const requiredValidation = (name = "campo") => {
  return [(v) => !!v || `El ${name} es obligatorio.`];
};

export const requiredFValidation = (name = "campo") => {
  return [(v) => !!v || `La ${name} es obligatoria.`];
};

export const requiredContainerValidation = (name = "campo",code = 0) => {
return [(v) => !!v || `El ${name} es obligatorio.`,
(v) => typeof v.length != null ? v.length == 14 || `El codigo debe tener al menos 14 digitos`: true]
}
export const emailValidation = (required = false, name = "correo") => {
  let validations = [];

  if (required) validations.push((v) => !!v || `El ${name} es obligatorio.`);

  validations.push(
    (v) =>
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
      `Debe introducir un ${name} válido.`
  );

  return validations;
};

export const numberValidation = (rules = { name: "campo", required: true }) => {
  if (!rules.name) rules.name = "campo";
  let validations = [];

  if (rules.required)
    validations.push((v) => !!v || `El ${rules.name} es obligatorio.`);

  if (rules.min !== undefined && rules.required)
    validations.push(
      (v) => Number(v) >= rules.min || `El valor mínimo es ${rules.min}.`
    );
  else if (rules.min !== undefined && !rules.required)
    validations.push((v) =>
      v !== null && v !== undefined && v !== ""
        ? v.length === 0 ||
          (Number(v) >= rules.min && Number(v) >= 0) ||
          `El valor mínimo es ${rules.min}.`
        : `El ${rules.name} es obligatorio.`
    );

  if (rules.max !== undefined)
    validations.push((v) =>
      v
        ? v.length === 0 ||
          Number(v) <= rules.max ||
          `El valor máximo es ${rules.max}.`
        : ""
    );

  return validations;
};
