export function ADD_TO_LIST(state, isolate) {
  state.isolateList.push(isolate);
}
export function SET_LIST(state, isolates) {
  state.isolateList = isolates;
}
export function SET_ISOLATE(state, isolate) {
  state.isolateSelected = isolate;
}

export function SET_ISOLATES_LIST(state, isolatesList) {
  state.isolatesList = isolatesList;
}

export function SET_COUNT(state, count) {
  state.count = count;
}