// Guard
import { privateGuard } from "@/middlewares/guards";

// Layouts
import NavLayout from "../layouts/NavLayout.vue";

// Views
import Labels from "../views/Labels.vue";

export default [
  {
    path: "/labels",
    beforeEnter: privateGuard,
    component: NavLayout,
    children: [
      {
        path: "",
        name: "Labels",
        component: Labels,
        meta: {
          title: "Generación de etiquetas",
        },
      },
    ],
  }
];
