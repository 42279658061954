<template>
  <v-dialog
    v-model="show"
    transition="dialog-bottom-transition"
    fullscreen
    scrollable
    persistent
  >
    <v-card scrollable>
      <v-card-text class="px-0">
        <v-toolbar color="primary" class="white--text">
          <h2><v-icon color="white">mdi-camera</v-icon>Scanner</h2>
          <v-spacer></v-spacer>
          <v-btn icon color="white" @click="show = false">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <div class="barcode-scanner-container">
            <StreamBarcodeReader
              ref="scanner"
              @decode="onDecode"
              @loaded="onLoaded"
            ></StreamBarcodeReader>
          </div>
          <v-row class="mt-5">
            <v-col class="text-center">
              <span class="text-xl">
                Este es tu codigo 👉🏼👉🏼👉🏼: {{ code }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="warning" @click="show = false">Cancelar</v-btn>
        <svg id="barcode" style="display: none"></svg>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
export default {
  components: {
    StreamBarcodeReader,
  },
  data() {
    return {
      code: "",
    };
  },
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {},
  beforeDestroy() {
    this.stopBarcodeScanner();
  },
  methods: {
    onDecode(text) {
      this.code = text;
      console.log(`Decode text from QR code is ${text}`);
    },
    onLoaded() {
      console.log(`Ready to start scanning barcodes`);
    },
    stopBarcodeScanner() {
      // Suponiendo que el componente StreamBarcodeReader tiene un método stop()
      // que detiene el stream del lector de códigos de barras
      this.$refs.scanner.codeReader.stream
        .getTracks()
        .forEach(function (track) {
          track.stop();
        });
    },
  },
};
</script>

<style scoped>
.barcode-scanner-container {
  width: 90vw; /* Utiliza el 90% del ancho de la ventana */
  max-width: 600px; /* Establece un ancho máximo */
  margin: 0 auto; /* Centra el contenedor */
}

/* Estilos para pantallas más pequeñas */
@media only screen and (max-width: 768px) {
  .barcode-scanner-container {
    width: 95vw; /* Usa el 95% del ancho de la ventana en pantallas pequeñas */
  }
}

/* Estilos para pantallas más grandes */
@media only screen and (min-width: 1200px) {
  .barcode-scanner-container {
    max-width: 33vw; /* Ancho máximo aumentado para pantallas más grandes */
  }
}

.text-xl {
  font-size: 30px;
}
</style>
