<template>
  <div class="loader-container">
    <v-dialog hide-overlay persistent width="unset" v-model="loaderView">
      <v-card color="white" class="px-15 py-7 text-center" width="350">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="40%"
          height="40%"
          viewBox="0 0 500 500"
          class="active"
        >
          <path
            id="Forma_1"
            data-name="Forma 1"
            class="cls-1 svg-elem-1"
            d="M55.233,44.016h219.48c3.274,0,16.222-.2,17.9.744,3.788,2.133,8.9,1.662,13.671,3.226,5.57,1.826,12.517,4.162,17.9,6.2,4.121,1.564,7.9,2.859,11.434,4.218H338.1c6.879,3.969,15.138,8,22.122,11.413,1.374,0.671,2.451.158,3.728,0.992,2.75,1.8,5.792,4.643,8.7,6.2h1.74c29,20.908,52.083,43.956,69.348,76.667,5.194,9.841,6.916,23.068,12.18,32.751l0.249,3.97c1.074,2.4,1.876,6.717,2.982,9.18,1.176,2.618.195,6.678,1,9.677,1.279,4.8,1.277,12.912,3.231,17.616V243.25c0.689,4.109.977,9.619,0,13.647-1.272,5.243.572,12.09-.994,16.127l-0.249,7.94c-0.7,1.757-1.527,6.225-1.988,7.939v4.218c-2.225,5.059-3.01,12.192-4.972,17.12q-0.621,3.35-1.243,6.7c-1.865,3.324-2.392,6.917-3.977,10.917a240.746,240.746,0,0,1-12.428,26.052c-10.356,18.924-25.341,36.5-40.515,50.863-12.92,12.234-29.189,21.2-44.493,31.014-1.327.852-2.614,0.154-3.977,0.993-4.111,2.53-9.424,4.753-13.67,7.195h-2.237a67.9,67.9,0,0,0-8.949,4.218h-2.734c-29.091,12.686-71.165,12.008-104.147,3.97C149.417,435.74,106.511,401.019,73.875,349.939v-1.985c-5.337-8.084-8.352-18.608-12.428-28.037-1.511-3.494-.928-7.233-1.988-11.165-5.938-22.024-4.225-52.741-4.226-80.388V44.016ZM135.27,123.66l-1.243,1.488V237.792c0,22.65-1.095,47.711,6.214,63.269,13.532,28.8,32.827,46.32,59.406,62.028,6.021,3.558,13.486,5.649,19.885,8.436,2.214,0.964,4.43.055,6.711,1.24,18.269,9.493,61.477,5.679,78.3-2.233,12.339-5.8,27.322-12.871,36.539-21.834,11.217-10.908,22.595-22.949,30.076-37.465,3.689-7.159,6.18-15.171,9.2-22.826,2.393-6.072,1.915-13.621,4.226-19.6V257.889c1.6-4.052.8-22.948-.994-26.8v-6.451c-3.7-11.639-6.055-22.861-10.44-33.247-2.61-6.183-7.293-12.09-11.185-17.12-2.706-3.5-4.415-7.32-7.457-10.421-11.713-11.937-25.409-20.057-40.516-28.781a70.8,70.8,0,0,0-7.705-3.225h-2.486c-16.2-9.259-41.429-8.188-67.111-8.188H135.27Z"
          ></path>
        </svg>
        <v-card-text class="pb-0">
          <p>Cargando</p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
 data() {
   return {
     loaderView: true
   }
 },
};
</script>

<style scoped>
.loader-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 10;
}

svg .svg-elem-1 {
  stroke-dashoffset: 2243.93798828125px;
  stroke-dasharray: 2243.93798828125px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715)
      0s,
    fill 0.2s ease-out 0.9s;
  transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
    fill 0.2s ease-out 0.9s;
}

@keyframes draw {
  100% {
    stroke-dashoffset: 0;
    fill: rgba(48, 183, 0.5);
  }
}
svg.active .svg-elem-1 {
  animation: draw 1500ms infinite;
}
.cls-1 {
  fill: rgba(48, 183, 0.5);
  stroke: #30b700;
  stroke-width: 5px;
  fill-rule: evenodd;
}

p {
  margin-bottom: 0 !important;
}
</style>
