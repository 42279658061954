import axios from "axios";

/**
 * Function to add new reception
 * @param {Object} { commit } - Vuex function
 * @param {Object} payload - New reception
 */
export async function addReception({ commit }, payload) {
  try {
    const { data } = await axios.post("/reception/add", {
      payload,
    });
    if (data.error) throw data;
    commit("SET_RECEPTION", data.analysis);
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to add new reception
 * @param {Object} { commit } - Vuex function
 * @param {Object} payload - New reception
 */
export async function updateReception({ commit }, payload) {
  try {
    const { data } = await axios.post("/reception/update", {
      payload,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to add new reception
 * @param {Object} { commit } - Vuex function
 * @param {Object} payload - New reception
 */
export async function validateSample({ commit }, payload) {
  try {
    const { data } = await axios.post("/reception/validate", {
      payload,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to logout a user
 * @param {Object} { commit } - Vuex function
 * @param {String} payload - laboratory id
 */
export async function getReceptions({ commit, rootState }, payload) {
  try {
    const { data } = await axios.get(
      `/reception/getReceptions/${payload.laboratory}/${payload.page}/${payload.folio}`,
      {
        cancelToken: rootState.cancelToken && rootState.cancelToken.token,
      }
    );
    if (data && data.error) throw data;
    commit("SET_RECEPTIONS", data.analysis);
    return data;
  } catch (e) {
    return false;
  }
}

/**
 * Function to get sample stats
 * @param {Object} { commit } - Vuex function
 * @param {String} payload - laboratory id
 */
export async function getSamplesStats({ commit }, payload) {
  try {
    const { data } = await axios.get(`/reception/get-samples-stats/${payload}`);
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to verify if container is correct
 * @param {Object} { commit } - Vuex function
 * @param {String} payload - container code
 */
export async function verifyContainer({ commit }, payload) {
  try {
    const { data } = await axios.get(`/reception/verify-container/${payload}`);
    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function verifyContainerUpdate({ commit }, payload) {
  try {
    const { data } = await axios.post(`/reception/verify-container`, payload);
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get containers from chars
 * @param {Object} { commit } - Vuex function
 * @param {String} payload - container char code
 */
export async function getContainersFromChars({ commit }, payload) {
  try {
    const { data } = await axios.get(`/reception/search-container/${payload}`);
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get new folio analysis
 * @param {Object} { commit } - Vuex function
 */
// export async function getNewFolioAnalysis({ commit }) {
//   try {
//     const { data } = await axios.get(`/reception/get-new-folio-analysis`);
//     return data;
//   } catch (e) {
//     return e.response.data;
//   }
// }

/**
 * Function to get new reception from char
 * @param {Object} { commit } - Vuex function
 */
export async function searchReception({ commit }, payload) {
  try {
    const { data } = await axios.get(
      `/reception/searchReception/${payload.laboratory}/${payload.char}`
    );

    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get receptions without wrong containers
 * @param {Object} { commit } - Vuex function
 * @param {String} payload - laboratory id
 */
export async function getValidatedReceptions({ commit }, payload) {
  try {
    const { data } = await axios.get(
      `/reception/getValidatedReceptions/${payload}`
    );
    if (data.error) throw data;
    commit("SET_RECEPTIONS", data.analysis);
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get new reception from char
 * @param {Object} { commit } - Vuex function
 * @param {Object} { payload }
 */
export async function searchValidatedReceptionS2(
  { commit, rootState },
  payload
) {
  try {
    const { data } = await axios.get(`/reception/search-validated-reception/`, {
      cancelToken: rootState.cancelToken && rootState.cancelToken.token,
      params: payload,
    });

    if (data & data.error) {
      throw data;
    }

    commit("SET_ANALYSES_FOUNDED", data.analysesSearched);

    return data;
  } catch (e) {
    return e.data;
  }
}

/**
 * Function to get new reception from char
 * @param {Object} { commit } - Vuex function
 * @param {Object} { payload }
 */
export async function searchValidatedReception({ commit }, payload) {
  try {
    const { data } = await axios.get(`/reception/search-validated-reception/`, {
      params: payload,
    });
    commit("SET_ANALYSES_FOUNDED", data.analysesSearched);

    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get new reception from char
 * @param {Object} { commit } - Vuex function
 * @param {Object} { payload }
 */
export async function searchAnalysis({ commit }, payload) {
  try {
    const { data } = await axios.get(`/reception/search-analysis/`, {
      params: payload,
    });
    commit("SET_ANALYSES_FOUNDED", data.analysesSearched);

    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get strains by analysis
 * @param {Object} { commit } - Vuex function
 * @param {Object} { payload } - Analysis _id
 */
export async function getStrainsByAnalysis({ commit }, payload) {
  try {
    const { data } = await axios.get(
      `/reception/get-strains/${payload.analysis}/${payload.threath}`,
      {
        params: payload,
      }
    );

    return data;
  } catch (e) {
    return e.response.data;
  }
}
export async function searchValidatedAnalysis({ commit }, payload) {
  try {
    const { data } = await axios.get(
      `/reception/get-analysis/${payload.folio}/${payload.laboratory}`,
      {
        params: payload,
      }
    );
    commit("SET_ANALYSES_RESULTS", data.analysis);
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get new reception from char
 * @param {Object} { commit } - Vuex function
 * @param {Object} { payload }
 */
export async function generateResults({ commit }, payload) {
  try {
    const { data } = await axios.post(
      `/results/generateResultsReport`,
      payload
    );
    commit("SET_ANALYSES_RESULTS", data.analysis);
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get new reception from char
 * @param {Object} { commit } - Vuex function
 * @param {Object} { payload }
 */
export async function generateDiagnosis({ commit }, payload) {
  try {
    const { data } = await axios.post(
      `/results/generate-diagnosis-report`,
      payload
    );
    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function generateMonitoring({ commit }, payload) {
  try {
    const { data } = await axios.post(
      `/results/generate-monitoring-report`,
      payload
    );
    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function generatePrescription({ commit }, payload) {
  try {
    const { data } = await axios.post(
      `/results/generate-prescription`,
      payload
    );
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get new reception from char
 * @param {Object} { commit } - Vuex function
 * @param {Object} { payload }
 */
export async function deleteTempFiles({ commit }, payload) {
  try {
    const { data } = await axios.post(`/profit/delete-temp-file`, payload);
    if (data.error) throw data;
    // commit("SET_STRAINS", data.strains);
    return data;
  } catch (e) {
    return e;
  }
}

/**
 * Function to get sample stats
 * @param {Object} { commit } - Vuex function
 * @param {String} payload - laboratory id
 */
export async function getInfoContainer({ commit }, payload) {
  try {
    const { data } = await axios.get(`/reception/get-container/${payload}`);
    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function getSampleTransit({ commit, rootState }, payload) {
  try {
    const { data } = await axios.post(`/reception/sample-transit`, payload, {
      cancelToken: rootState.cancelToken && rootState.cancelToken.token,
    });
    return data;
  } catch (e) {
    // console.log(e);
    // return e.response.data;
  }
}

export async function getReceptions2({ commit, rootState }, payload) {
  try {
    const { data } = await axios.post(`/reception/getReceptions2`, payload, {
      cancelToken: rootState.cancelToken && rootState.cancelToken.token,
    });

    return data;
  } catch (e) {
    // return e;
  }
}

export async function getFolioAnalysis({ commit }, payload) {
  try {
    const { data } = await axios.post(`/reception/get-folio`, payload);

    return data;
  } catch (e) {
    return e;
  }
}

/**
 * Function to upload isolate photo to aws s3
 * @param {Object} { state, dispatch } - vuex functions
 * @param {Image} image - isolate image
 */
export async function uploadImage({ state }, image) {
  try {
    const { data } = await axios.post("/reception/uploadImage", image);
    if (data.error) throw data;
    return data;
  } catch (error) {
    return error;
  }
}

export async function getSamplesByArea({ commit }, payload) {
  try {
    const { data } = await axios.post(`/sample/getSamplesByArea`, {
      _id: payload,
    });

    return data;
  } catch (e) {
    return e;
  }
}

export async function getMedition({ commit }, payload) {
  try {
    const { data } = await axios.post(`/results/get-medition`, payload);

    return data;
  } catch (e) {
    return e;
  }
}

export async function getValuation({ commit }, payload) {
  try {
    const { data } = await axios.post(`/results/get-valuation`, payload);

    return data;
  } catch (e) {
    return e;
  }
}

export async function getLaboratoriesRequest({ commit }) {
  try {
    const { data } = await axios.get(`/reception/get-laboratories`);

    return data;
  } catch (e) {
    return e;
  }
}

export async function sendFolios({ commit }, payload) {
  try {
    const { data } = await axios.post(`/reception/send-folios`, payload);

    return data;
  } catch (e) {
    return e;
  }
}

export async function receiveFolioRequest({ commit }, payload) {
  try {
    const { data } = await axios.post("/reception/receive-folio", payload);
    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function getTimeLineAnalysis({ commit }, payload) {
  try {
    const { data } = await axios.post("/reception/time-line", payload);
    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function updateResult({ commit }, payload) {
  try {
    const { data } = await axios.post("/reception/update-result-date", payload);
    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function setProjectKit({ commit }, payload) {
  try {
    const { data } = await axios.post("/reception/setProjectKit", {
      analysis_id: payload,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function setSetUpKit({ commit }, payload) {
  try {
    const { data } = await axios.post("/reception/set-setup-kit", {
      analysis_id: payload,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function getPreservedSoil({ commit }, payload) {
  try {
    const { data } = await axios.post("/reception/get-preserved-soil", payload);
    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function getFarms(context, payload) {
  try {
    const { data } = await axios.get(`/distributors/farms`, {
      params: payload,
    });
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    console.log(e);
    return e.data;
  }
}

export async function discardSamplePreserved({ commit }, payload) {
  try {
    const { data } = await axios.post("/reception/discard-sample-preserved", payload);
    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}
