<template>
  <v-container fluid class="pa-0">
    <template>
      <div class="text-center">
        <v-dialog v-model="dialogT" width="80%" persistent>
          <v-card>
            <v-card-text class="px-0 py-0">
              <vue-pdf-app style="height: 80vh" :pdf="urlPreview"></vue-pdf-app>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="warning"
                @click="closeResultDialog"
                :loading="isLoadingDelete"
                :disabled="creatingNewReport"
              >
                Cerrar
              </v-btn>
              <v-btn
                v-if="showGenerate"
                text
                color="success"
                :disabled="isLoadingDelete"
                :loading="creatingNewReport"
                @click="generatePDFAndSave()"
              >
                Enviar propuesta
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <v-card class="flex d-flex flex-column" elevation="5">
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="7">
              <v-row no-gutters>
                <v-col cols="12" class="mb-2">
                  <v-combobox
                    :search-input.sync="billing"
                    :items="businessNames"
                    clearable
                    hide-selected
                    outlined
                    v-model="billing"
                    dense
                    label="Razón social"
                  ></v-combobox>
                  <!-- <span class="text-subtitle-2 font-weight-bold">{{
                  companyName
                }}</span> -->
                </v-col>
                <v-col cols="12" class="mb-1">
                  <span class="text-body-1 font-weight-bold">{{
                    projectName
                  }}</span>
                </v-col>
                <v-col cols="12" class="mb-1">
                  <span>{{ code }}</span>
                </v-col>
                <v-col cols="12" class="mb-3">
                  <span>Creación: {{ createdDate }}</span>
                </v-col>
                <v-col
                  cols="12"
                  class="mb-4"
                  v-if="profit.status === 'FINISHED'"
                >
                  <span> Finzalización: {{ finishDate }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="5">
              <v-row
                v-if="
                  profit &&
                  profit.status !== 'NEW' &&
                  profit.status !== 'PROPOSAL' &&
                  profit.status !== 'REJECTED' &&
                  profit.status !== 'STRAINS_SUGGESTION'
                "
                no-gutters
              >
                <!-- <v-col cols="12" class="my-1"
                ><v-btn block color="warning" @click="pause"
                  >Pausar</v-btn
                ></v-col
              > -->
                <v-col
                  cols="12"
                  class="my-1"
                  v-if="profit.status !== 'FINISHED'"
                >
                  <v-btn block color="error" @click="callDialog"
                    >Finalizar</v-btn
                  ></v-col
                >
                <v-col cols="12" class="my-1">
                  <v-btn block color="primary" small v-if="url" :href="url">
                    Descargar PDF</v-btn
                  ></v-col
                >
              </v-row>
              <v-row v-else no-gutters>
                <v-col
                  cols="12"
                  class="my-1"
                  v-if="
                    profit &&
                    (profit.status === 'NEW' ||
                      profit.status === 'PROPOSAL' ||
                      profit.status === 'REJECTED' ||
                      profit.status === 'STRAINS_SUGGESTION')
                  "
                >
                  <v-combobox
                    :items="versions"
                    item-text="version"
                    @input="changeVersion()"
                    v-model="version"
                    clearable
                    hide-selected
                    outlined
                    dense
                    label="Versión"
                  ></v-combobox>
                </v-col>
                <v-col
                  cols="12"
                  class="my-1"
                  v-if="
                    version != null &&
                    permissions.find((per) => per == 'lab_profit_update')
                  "
                  ><v-btn
                    block
                    color="primary"
                    @click="save"
                    small
                    :loading="isSave"
                    >Guardar</v-btn
                  ></v-col
                >
                <v-col
                  cols="12"
                  class="my-1"
                  v-if="
                    version != null &&
                    permissions.find((per) => per == 'lab_profit_update')
                  "
                >
                  <v-btn
                    block
                    color="warning"
                    :loading="loadingPDF"
                    small
                    @click="showPreview(true)"
                  >
                    Generar PDF</v-btn
                  ></v-col
                >
                <v-col cols="12" class="my-1" v-if="loadingProfitPages == 2">
                  <v-btn block color="primary" small v-if="url" :href="url">
                    Descargar PDF</v-btn
                  ></v-col
                >
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mb-0">
            <v-col cols="12" class="pb-0">
              <span class="font-weight-bold">Objetivo</span>
              <p>{{ target }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-switch
                label="Rentabilización quincenal"
                small
                v-model="isBiweekly"
              >
              </v-switch>
            </v-col>
          </v-row>
          <v-row v-if="notes.length > 0" class="mt-0">
            <v-col cols="12" class="pt-0">
              <span class="font-weight-bold">Notas</span>
              <p>{{ notes }}</p>
            </v-col>
          </v-row>
          <v-row v-if="solubilization">
            <v-col>
              <span class="primary--text font-italic text-body-1">
                &#x2022; Esta rentabilizacion llevara solubilización de fosforo
                y potasio
              </span>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="version != null">
            <v-col cols="12" class="mb-3">
              <span class="warning--text font-weight-bold">Nuevo proyecto</span>
            </v-col>
            <v-col cols="12">
              <v-combobox
                :items="moths"
                v-model="selectedMoth"
                clearable
                hide-selected
                outlined
                :disabled="
                  profit &&
                  profit.status !== 'NEW' &&
                  profit.status !== 'PROPOSAL' &&
                  profit.status !== 'REJECTED' &&
                  profit.status !== 'STRAINS_SUGGESTION'
                "
                dense
                label="Duración del proyecto"
              ></v-combobox>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <vuetify-money
                    v-model="precie"
                    v-bind:label="label"
                    v-bind:placeholder="placeholder"
                    v-bind:readonly="false"
                    v-bind:disabled="
                      profit &&
                      profit.status !== 'NEW' &&
                      profit.status !== 'PROPOSAL' &&
                      profit.status !== 'REJECTED' &&
                      profit.status !== 'STRAINS_SUGGESTION'
                    "
                    v-bind:outlined="true"
                    v-bind:clearable="true"
                    v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                    v-bind:options="options"
                    dense
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    outlined
                    dense
                    clearable
                    :disabled="
                      profit &&
                      profit.status !== 'NEW' &&
                      profit.status !== 'PROPOSAL' &&
                      profit.status !== 'REJECTED' &&
                      profit.status !== 'STRAINS_SUGGESTION'
                    "
                    label="Área mínima"
                    type="number"
                    v-model="minSurface"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <!-- <v-text-field
              outlined
              dense
              clearable
              label="Precio rentabilización"
              type="number"
              v-model="precie"
            >
              <template slot="append">
                <v-icon>mdi-currency-usd</v-icon>
              </template>
            </v-text-field> -->
            </v-col>
          </v-row>
          <v-row no-gutters v-if="version != null">
            <v-col cols="12">
              <span class="primary--text font-weight-bold">{{
                currentCrop
              }}</span>
            </v-col>
            <v-col cols="12">
              <span>{{ parseFloat(surface).toFixed(2) }} Ha a tratar</span>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="version != null">
            <v-col cols="12">
              <span class="font-weight-bold">Unidad(es) agrícola(s):</span>
            </v-col>

            <v-col cols="12">
              <v-list height="85" class="overflow-y-auto">
                <template v-for="item in farmUnits">
                  <v-row no-gutters :key="item._id">
                    <v-col cols="6">
                      {{ truncateString(item.name, 23) }}
                    </v-col>
                    <v-col cols="6">
                      <!-- <span class="primary--text">
                      {{ truncateString(item.crop.cropName, 23) }}</span
                    > -->
                      <span
                        class="primary--text"
                        v-if="item.dateStart && item.dateEnd"
                      >
                        {{ dateFormat(item.dateStart) }} -
                        {{ dateFormat(item.dateEnd) }}</span
                      >
                    </v-col>
                  </v-row>
                </template>
              </v-list>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-container v-if="analysis.length > 0">
            <v-list height="250" class="overflow-y-auto">
              <template v-for="analy in analysis">
                <FarmUnit
                  :farmUnit="analy"
                  :key="analy._id"
                  :totalSamples="analysis[0].analysis.length"
                />
              </template>
            </v-list>
          </v-container>

          <v-dialog v-model="dialogFinsh" persistent max-width="320">
            <v-card>
              <v-toolbar color="primary" dark block>
                <span class="text-center">
                  Selecciona fecha de finalización</span
                >
              </v-toolbar>
              <!-- <v-card-title class="text-h6 text-center">
          Selecciona fecha de finalización
        </v-card-title> -->
              <v-date-picker
                style="width: 320px"
                v-model="picker"
                locale="es"
              ></v-date-picker>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="dialogFinsh = false">
                  cancelar
                </v-btn>
                <v-btn
                  color="green darken-1"
                  text
                  :loading="loadingFinsh"
                  @click="finish"
                >
                  aceptar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
//components
import FarmUnit from "@/components/Profit/FarmUnit";
//pdf viewer
import VuePdfApp from "vue-pdf-app";
//Libraries
import dayjs from "dayjs";
//Vuex
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";

export default {
  props: {
    profit: {
      type: Object,
      required: true,
    },
  },
  components: {
    FarmUnit,
    VuePdfApp,
  },
  data: () => ({
    isBiweekly: false,
    billing: null,
    businessNames: [],
    isSave: false,
    finishDate: null,
    picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dialogFinsh: false,
    url: null,
    loadingPDF: false,
    loadingBuyBack: false,
    loadingFinsh: false,
    minSurface: null,
    user: JSON.parse(localStorage.getItem("user")),
    moths: [
      "1 mes",
      "2 meses",
      "3 meses",
      "4 meses",
      "5 meses",
      "6 meses",
      "7 meses",
      "8 meses",
      "9 meses",
      "10 meses",
      "11 meses",
      "12 meses",
    ],
    analysis: [],
    farmUnits: [],
    precie: null,
    selectedMoth: null,
    companyName: "",
    projectName: "",
    code: "",
    createdDate: "",
    currentCrop: "",
    surface: "",
    target: "",
    notes: "",
    versions: [],
    version: null,
    options: {
      locale: "pt-BR",
      prefix: "$",
      suffix: "",
      length: 11,
      precision: 0,
    },
    label: "Precio rentabilización",
    placeholder: "",
    valueWhenIsEmpty: "",
    dialogT: false,
    urlPreview: "",
    isLoadingDelete: false,
    creatingNewReport: false,
    keyPreview: null,
    showGenerate: false,
    solubilization: false,
  }),
  computed: {
    ...mapState("profit", [
      "months_data",
      "strains_selected",
      "profit_selected",
      "profit_version",
      "loadingProfitPages",
    ]),
    ...mapState("session", ["permissions"]),
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapMutations("profit", ["SET_SUGGESTIONS", "UNSET_LOAD_PROFIT"]),
    ...mapActions("profit", [
      "getProfit",
      "saveProfit",
      "generateProfitPDF",
      "deleteTempFiles",
      "generateProfitTempPDF",
      "finishProfit",
      "makeBuyBack",
    ]),
    dateFormat(value) {
      const date = `${dayjs(value).format("DD/MM/YYYY")}`;
      return date ? date : "----";
    },
    callDialog() {
      this.dialogFinsh = true;
    },
    async finish() {
      try {
        if (this.picker) {
          let profitId = this.$route.params.id;
          let response = await this.finishProfit({
            id: profitId,
            date: this.picker,
          });
          if (response) {
            if (!response.error) {
              let date = response.data;
              this.profit_version.status = "FINISHED";
              this.profit_version.finishDate = date;
              this.finishDate = date;
              this.profit.status = "FINISHED";
              this.dialogFinsh = false;
              this.SET_ALERT_TYPE({
                visible: true,
                type: "success",
                text: "Rentabilización finalizada!!",
                timeout: 8000,
              });
            } else {
              this.SET_ALERT_TYPE({
                visible: true,
                type: "error",
                text: "Algo salio mal al finalizar rentabilización!!",
                timeout: 8000,
              });
            }
          } else {
            this.SET_ALERT_TYPE({
              visible: true,
              type: "error",
              text: "Algo salio mal al finalizar rentabilización!!",
              timeout: 8000,
            });
          }
        } else {
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: "Selecciona una fecha de finalización!!",
            timeout: 8000,
          });
        }
      } catch (e) {
        console.log(e);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Algo salio mal al generar PDF !!",
          timeout: 5000,
        });
      }
      this.loadingFinsh = false;
    },
    async generatePDF() {
      try {
        this.loadingPDF = true;
        let id = "";
        if (this.version.id) {
          id = this.version.id;
        } else {
          id = this.version._id;
        }
        let response = await this.generateProfitPDF({
          profit: id,
          profitVersion: this.$route.params.id,
          url: this.urlPreview,
        });
        if (response) {
          if (!response.error) {
            this.url = response.url;
            this.dialogT = false;
            this.urlPreview = "";
            this.creatingNewReport = false;

            this.SET_ALERT_TYPE({
              visible: true,
              type: "success",
              text: "PDF generado !!",
              timeout: 5000,
            });
          } else {
            this.SET_ALERT_TYPE({
              visible: true,
              type: "error",
              text: "Algo salio mal al generar PDF !!",
              timeout: 5000,
            });
          }
        } else {
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: "Algo salio mal al generar PDF !!",
            timeout: 5000,
          });
        }
      } catch (e) {
        console.log(e);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Algo salio mal al generar PDF !!",
          timeout: 5000,
        });
      }
      this.loadingPDF = false;
    },

    async generateTempPDF(value) {
      try {
        this.loadingPDF = true;
        let id = "";
        if (this.version.id) {
          id = this.version.id;
        } else {
          id = this.version._id;
        }
        let response = await this.generateProfitTempPDF({
          profit: id,
          profitVersion: this.$route.params.id,
        });
        if (response) {
          if (!response.error) {
            console.log(response);
            this.urlPreview = response.data.url;
            this.keyPreview = response.data.key;
            this.SET_ALERT_TYPE({
              visible: true,
              type: "success",
              text: "PDF generado !!",
              timeout: 5000,
            });
          } else {
            this.SET_ALERT_TYPE({
              visible: true,
              type: "error",
              text: "Algo salio mal al generar PDF !!",
              timeout: 5000,
            });
          }
        } else {
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: "Algo salio mal al generar PDF !!",
            timeout: 5000,
          });
        }
      } catch (e) {
        console.log(e);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Algo salio mal al generar PDF !!",
          timeout: 5000,
        });
      }
      this.loadingPDF = false;
    },
    async save() {
      try {
        this.isSave = true;
        let profitId = this.$route.params.id;
        let arrayAux = this.selectedMoth.split(" ");
        let number = parseInt(arrayAux[0]);
        let cost = Number(this.precie);
        let notDuplicates = Array.from(new Set(this.strains_selected));
        let response = await this.saveProfit({
          schedule: this.months_data,
          strainSelected: notDuplicates,
          profitId: this.profit_selected._id,
          months: number,
          cost: cost,
          minSurface: this.minSurface,
          profitVersionId: profitId,
          billing: this.billing,
          isBiweekly: this.isBiweekly,
        });
        console.log(response);
        if (response) {
          if (!response.error) {
            this.businessNames.push(this.billing);
            this.SET_SUGGESTIONS(response.profit.productsSugestions);
            this.SET_ALERT_TYPE({
              visible: true,
              type: "success",
              text: "Rentabilización actualizada !!",
              timeout: 5000,
            });
          } else {
            console.log(e);
            this.SET_ALERT_TYPE({
              visible: true,
              type: "error",
              text: "Algo salio mal al guardar rentabilización !!",
              timeout: 5000,
            });
          }
        } else {
          console.log(e);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: "Algo salio mal al guardar rentabilización !!",
            timeout: 5000,
          });
        }
      } catch (e) {
        console.log(e);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Algo salio mal al guardar rentabilización !!",
          timeout: 5000,
        });
      }
      this.isSave = false;
    },
    async generatePDFAndSave(value) {
      this.creatingNewReport = true;
      await this.save();
      await this.generatePDF(value);
    },
    getCrop(value) {
      let template = "";
      const crop = {
        olive: "Aceituna",
        chard: "Acelga",
        achiote: "Achiote",
        agave: "Agave",
        avocado: "Aguacate",
        garlic: "Ajo",
        sesame: "Ajonjolí",
        basil: "Albahaca",
        artichoke: "Alcachofa",
        alcatraz: "Alcatraz",
        shrunkenAlfalfa: "Alfalfa achicalada",
        greenAlfalfa: "Alfalfa verde",
        cotton: "Algodón",
        almond: "Almendro",
        birdseed: "Alpiste",
        amaranth: "Amaranto",
        anise: "Anís",
        celery: "Apio",
        blueberry: "Arándano",
        myrtle: "Arrayán",
        rice: "Arroz",
        greenForageOats: "Avena forrajera en verde",
        oatGrain: "Avena grano",
        bamboo: "Bambú",
        eggplant: "Berenjena",
        beetroot: "Betabel",
        broccoli: "Brocoli",
        peanut: "Cacahuate",
        cocoa: "Cacao",
        cherryCoffee: "Café cereza",
        pumpkin: "Calabaza",
        sweetPotato: "Camote",
        sugarCane: "Caña de azúcar",
        canola: "Canola",
        carambolo: "Carambolo",
        safflower: "Cártamo",
        barley: "Cebada",
        greenForageBarley: "Cebada forrajera en verde",
        onion: "Cebolla",
        chives: "Cebollín",
        ryeGrain: "Centeno grano",
        cherry: "Cereza",
        chabacano: "Chabacano",
        pea: "Chícharo",
        squash: "Chayote",
        chia: "Chía",
        chilacayote: "Chilacayote",
        chillyPepper: "Chile / Pimiento",
        custardApple: "Chirimoya",
        coriander: "Cilantro",
        plum: "Ciruela",
        coconutFruit: "Coco fruta",
        cabbage: "Col (repollo)",
        brusselsSprouts: "Col de Bruselas",
        cauliflower: "Coliflor",
        cumin: "Comino",
        chrysanthemum: "Crisantemo (planta)",
        peach: "Durazno",
        date: "Dátil",
        greenBean: "Ejote",
        cornCob: "Elote",
        dill: "Eneldo",
        epazote: "Epazote",
        asparagus: "Espárrago",
        spicesAndMedicinals: "Especias y medicinales",
        spinach: "Espinaca",
        raspberry: "Frambuesa",
        strawberry: "Fresa",
        bean: "Frijol",
        chickpea: "Garbanzo",
        sunflower: "Girasol",
        pomegranate: "Granada",
        soursop: "Guanábana",
        guava: "Guayaba",
        henequen: "Henequén",
        peppermint: "Hierbabuena",
        aromaticHerbs: "Hierbas aromáticas",
        fig: "Higo",
        jamaica: "Jamaica",
        ginger: "Jenjibre",
        jicama: "Jícama",
        lettuce: "Lechuga",
        lentil: "Lenteja",
        lime: "Lima",
        lemon: "Limón",
        linseed: "Linaza",
        litchi: "Litchi",
        corn: "Maíz",
        macadamia: "Macadamia",
        maguey: "Maguey",
        mamey: "Mamey",
        tangerine: "Mandarina",
        mango: "Mango",
        mangosteen: "Mangostán",
        chamomile: "Manzanilla",
        appleTree: "Manzano",
        passionFruit: "Maracuyá",
        daisyFlower: "Margarita",
        marjoram: "Mejorana",
        melon: "Melón",
        quince: "Membrillo",
        mint: "Menta",
        moringa: "Moringa",
        turnip: "Nabo",
        nanche: "Nanche",
        napa: "Napa",
        orange: "Naranja",
        medlar: "Níspero",
        nochebuena: "Nochebuena (planta)",
        walnut: "Nogal",
        noni: "Noni",
        nopal: "Nopal",
        oregano: "Orégano",
        ornamental: "Ornamentales",
        orchid: "Orquídea (planta)",
        oilPalm: "Palma africana o de aceite",
        potato: "Papa",
        papaya: "Papaya",
        grasslands: "Pastizales",
        matGrass: "Pasto tapete (m2)",
        pasturesAndMeadows: "Pastos y praderas",
        cucumber: "Pepino",
        pearTree: "Peral",
        parsley: "Perejil",
        peron: "Perón",
        persimony: "Persimonio",
        pepper: "Pimienta",
        pineapple: "Piña",
        pinion: "Piñón",
        pistache: "Pistache",
        pitaya: "Pitaya",
        banana: "Plátano",
        pore: "Poro",
        radish: "Rábano",
        rambutan: "Rambután",
        romerito: "Romerito",
        rosemary: "Romero",
        aloe: "Sábila",
        watermelon: "Sandía",
        sorghum: "Sorgo",
        soy: "Soya",
        stevia: "Stevia",
        tobacco: "Tabaco",
        tamarind: "Tamarindo",
        tejocote: "Tejocote",
        tomato: "Tomate Rojo (jitomate)",
        greenTomato: "Tomate Verde",
        thyme: "Tomillo",
        grapefruit: "Toronja (pomelo)",
        clover: "Trébol",
        wheat: "Trigo",
        dutchTulip: "Tulipán holandés",
        tuna: "Tuna",
        grape: "Uva",
        vanilla: "Vainilla",
        purslane: "Verdolaga",
        vine: "Vid",
        cassavaFood: "Yuca alimenticia",
        carrot: "Zanahoria",
        sapodilla: "Zapote",
        blackberry: "Zarzamora",
        zempoalxochitl: "Zempoalxochitl",
        fallow: "Barbecho",
        other: "Otro",
      };
      let cropName = crop[value];
      if (typeof cropName != "undefined") {
        template = cropName;
      } else {
        template = "Sin cultivo";
      }
      return template;
    },
    truncateString(str, num) {
      // If the length of str is less than or equal to num
      // just return str--don't truncate it.
      if (str.length <= num) {
        return str;
      }
      // Return str truncated with '...' concatenated to the end of str.
      return str.slice(0, num) + "...";
    },
    async changeVersion() {
      this.UNSET_LOAD_PROFIT(0);
      this.$emit("version_change", null);
      this.selectedMoth = null;
      this.surface = "";
      this.farmUnits = [];
      this.precie = null;
      this.analysis = [];
      this.notes = "";
      this.url = null;
      if (this.version) {
        let response = await this.getProfit(this.version.id);
        this.isBiweekly = response.profit.isBiweekly || false;
        //set farm units here
        if (response.profit.cycleLength == 1) {
          this.selectedMoth = `${response.profit.cycleLength} mes`;
        } else {
          this.selectedMoth = `${response.profit.cycleLength} meses`;
        }
        this.surface = response.profit.surface;
        this.farmUnits = response.profit.samplesInitial;
        this.precie = response.profit.price;
        this.analysis = response.profit.analysis;
        this.notes = response.profit.notes;
        this.url = response.profit.urlPDF;
        this.minSurface = response.profit.minSurface;
        this.solubilization = response.profit.solubilization;
        if (response.profit.targets && response.profit.targets.length > 0) {
          this.target = this.getDescriptionText(response.profit.targets);
        } else {
          this.target = response.profit.target;
        }
        //send all data to others components
        this.$emit("version_change", response.profit);
      }
    },
    async buyBack() {
      this.loadingBuyBack = true;
      try {
        let response = await this.makeBuyBack({ _id: this.$route.params.id });
        if (response) {
          if (!response.error) {
            this.loadingBuyBack = false;
            this.SET_ALERT_TYPE({
              visible: true,
              type: "success",
              text: "Se creo la recrompra !!",
              timeout: 5000,
            });
          } else {
            this.loadingBuyBack = false;
            throw response;
          }
        } else {
          this.loadingBuyBack = false;
          throw response;
        }
      } catch (e) {
        console.log(e);
        this.loadingBuyBack = false;
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Algo salio mal, intente de nuevo !!",
          timeout: 5000,
        });
      }
    },
    async closeResultDialog() {
      if (this.showGenerate) {
        this.isLoadingDelete = true;
        //delete temp file
        // await this.deleteTempFiles({ key: this.keyPreview });
        this.isLoadingDelete = false;
        this.dialogT = false;
        this.urlPreview = "";
        this.creatingNewReport = false;
      } else {
        this.dialogT = false;
        this.urlPreview = "";
      }
    },
    generatePreview() {},
    async showPreview(value) {
      this.loadingPDF = true;
      try {
        // await this.save();
        await this.generateTempPDF(value);
        this.dialogT = true;
        this.showGenerate = true;
      } catch (e) {
        console.log(e);
        this.loadingPDF = false;
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Algo salio mal, intente de nuevo !!",
          timeout: 5000,
        });
      }
    },
    arraysEqual(arr1, arr2) {
      // Check if arrays have the same elements
      if (arr1.length !== arr2.length) return false;
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
      }
      return true;
    },
    arrayContainsExactCombination(arr, combination) {
      const arrSet = new Set(arr);
      const combSet = new Set(combination);
      return this.arraysEqual([...arrSet], [...combSet]);
    },
    getDescriptionText(targets) {
      const combination1 = ["CSPF"];
      const combination2 = ["PPS"];
      const combination3 = ["CPN"];
      const combination4 = ["CSPF", "PPS"];
      const combination5 = ["CSPF", "PPS", "CPN"];
      const combination6 = ["CSPF", "CPN"];
      const combination7 = ["PPS", "CPN"];

      if (this.arrayContainsExactCombination(targets, combination1)) {
        return "Disminuir concentraciones de hongos patógenos de suelo mediante el uso de microorganismos benéficos.";
      } else if (this.arrayContainsExactCombination(targets, combination2)) {
        return "Solubilización de Fósforo y Potasio.";
      } else if (this.arrayContainsExactCombination(targets, combination3)) {
        return "Disminuir concentraciones de nematodos fitopatógenos mediante el uso de microorganismos benéficos.";
      } else if (this.arrayContainsExactCombination(targets, combination4)) {
        return "Disminuir concentraciones de hongos patógenos mediante el uso de microorganismos benéficos. Solubilización de Fósforo y Potasio.";
      } else if (this.arrayContainsExactCombination(targets, combination5)) {
        return "Disminuir concentraciones de hongos patógenos de suelo y nematodos fitopatógenos mediante el uso de microorganismos benéficos. Solubilización de Fósforo y Potasio.";
      } else if (this.arrayContainsExactCombination(targets, combination6)) {
        return "Disminuir concentraciones de hongos patógenos de suelo y nematodos fitopatógenos mediante el uso de microorganismos benéficos.";
      } else if (this.arrayContainsExactCombination(targets, combination7)) {
        return "Disminuir concentraciones de nematodos fitopatógenos mediante el uso de microorganismos benéficos. Solubilización de Fósforo y Potasio.";
      }
    },
  },
  async created() {
    if (this.profit) {
      this.companyName = this.profit.companyName;
      this.businessNames = this.profit.companyNames;
      if (this.profit.businessName) {
        this.billing = this.profit.businessName;
      } else {
        if (this.businessNames.length > 0) {
          this.billing = this.businessNames[0];
        }
      }
      this.code = this.profit.code;
      this.projectName = this.profit.profitName;
      this.createdDate = this.dateFormat(this.profit.date);
      this.finishDate = this.dateFormat(this.profit.finishDate);
      this.currentCrop = this.getCrop(this.profit.crop);
      if (this.profit.version) {
        this.version = this.profit.version;
        let response = await this.getProfit(this.version._id);
        this.isBiweekly = response.profit.isBiweekly || false;
        //set farm units here
        if (response.profit.cycleLength == 1) {
          this.selectedMoth = `${response.profit.cycleLength} mes`;
        } else {
          this.selectedMoth = `${response.profit.cycleLength} meses`;
        }
        this.surface = response.profit.surface;
        this.farmUnits = response.profit.samplesInitial;

        this.precie = response.profit.price;
        if (response.profit.targets && response.profit.targets.length > 0) {
          this.target = this.getDescriptionText(response.profit.targets);
        } else {
          this.target = response.profit.target;
        }
        this.notes = response.profit.notes;
        this.notes = response.profit.notes;
        this.analysis = response.profit.analysis;
        this.url = response.profit.urlPDF;
        this.minSurface = response.profit.minSurface;
        this.solubilization = response.profit.solubilization;
        //send all data to others components
        this.$emit("version_change", response.profit);
      } else {
        this.versions = this.profit.versions;
        this.version = this.versions[0];
        this.$emit("version_change", null);
        this.selectedMoth = null;
        this.surface = "";
        this.farmUnits = [];
        this.precie = null;
        this.analysis = [];
        if (this.version) {
          let response = await this.getProfit(this.version.id);
          this.isBiweekly = response.profit.isBiweekly || false;
          //set farm units here
          if (response.profit.cycleLength == 1) {
            this.selectedMoth = `${response.profit.cycleLength} mes`;
          } else {
            this.selectedMoth = `${response.profit.cycleLength} meses`;
          }
          this.surface = response.profit.surface;
          if (response.profit.targets && response.profit.targets.length > 0) {
            this.target = this.getDescriptionText(response.profit.targets);
          } else {
            this.target = response.profit.target;
          }
          this.notes = response.profit.notes;
          this.farmUnits = response.profit.samplesInitial;
          this.precie = response.profit.price;
          this.analysis = response.profit.analysis;
          this.url = response.profit.urlPDF;
          this.minSurface = response.profit.minSurface;
          this.solubilization = response.profit.solubilization;
          //send all data to others components
          this.$emit("version_change", response.profit);
        }
      }
    }
  },
  watch: {
    selectedMoth() {
      this.$emit("month_selected", this.selectedMoth);
    },
  },
};
</script>

<style scoped>
.datetime-picker input[data-v-a46a390c] {
  width: 150px;
  height: 38px;
}
</style>
