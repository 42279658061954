<template>
  <v-dialog v-model="dialog" width="80%" persistent scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn block class="default" elevation="0" v-bind="attrs" v-on="on">
        Nutrición
      </v-btn>
    </template>

    <v-card class="py-2">
      <!-- <v-card-title class="text-h5 grey lighten-2">
        Privacy Policy
      </v-card-title> -->

      <v-card-text>
        <v-container class="pa-6">
          <v-row>
            <v-col cols="12">
              <p class="text-center text-h5">Nueva Nutrición</p>
            </v-col>
          </v-row>

          <v-form v-model="valid" ref="form" lazy-validation>
            <v-container>
              <v-row align="center" justify="center">
                <v-col cols="8">
                  <v-combobox
                    v-model="analysisToFound"
                    :items="analysesFounded"
                    :rules="analysisRules"
                    :loading="loadingAnalysis"
                    no-data-text="No se encontró el análisis"
                    return-object
                    label="Análisis"
                    no-filter
                    clearable
                    outlined
                    dense
                  >
                  <template v-slot:item="{ item }">
                      {{ item.folio }} -
                      {{
                        item.status == "MEASURED"
                          ? "MEDIDO"
                          : item.status == "VALUATING"
                          ? "VALUANDO"
                          : item.status == "RECEIVED"
                          ? "RECIBIDO"
                          : "VALUADO"
                      }}
                    </template>
                    <template v-slot:selection="{ item }">
                      <template v-if="typeof item == 'string'">
                        {{ item }}
                      </template>
                      <template v-else>
                        {{ item.folio }} -
                        {{
                          item.status == "MEASURED"
                            ? "MEDIDO"
                            : item.status == "VALUATING"
                            ? "VALUANDO"
                            : item.status == "RECEIVED"
                            ? "RECIBIDO"
                            : "VALUADO"
                        }}
                      </template>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </v-container>

            <v-row class="mt-2 pr-2">
              <v-col cols="3" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-caption text-center mb-0 font-weight-black">
                    ID medio
                  </p>
                </v-row>
              </v-col>
              <v-col cols="1" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-caption text-center mb-0 font-weight-black">
                    Medio
                  </p>
                </v-row>
              </v-col>
              <v-col cols="3" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-caption text-center mb-0 font-weight-black">
                    Tipo de ensayo
                  </p>
                </v-row>
              </v-col>
              <v-col cols="5" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-caption text-center mb-0 font-weight-black">
                    Inóculo
                  </p>
                </v-row>
              </v-col>
            </v-row>

            <v-row
              class="pt-4 pr-2"
              v-for="(item, index) in bcTestsSelected"
              :key="index"
            >
              <v-col cols="3" class="px-0 pb-0">
                <v-row justify="center" align="center">
                  <v-autocomplete
                    v-if="!item.autocompleted"
                    v-model="item.testMedia"
                    class="px-8"
                    :items="testMediaList"
                    :rules="testMediaRules"
                    :search-input.sync="search[index]"
                    :loading="item.testMediaLoading"
                    @change="assingDataRow(index)"
                    return-object
                    item-text="_id"
                    no-data-text="No se ha encontrado"
                    outlined
                    dense
                  ></v-autocomplete>
                  <p class="text-center pt-2 mb-0" v-else>
                    {{item.testMedia._id}}
                  </p>
                </v-row>
              </v-col>
              <v-col cols="1" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-center pt-2 mb-0" v-if="item.testMedia == null">
                    - - -
                  </p>
                  <p class="text-center pt-2 mb-0" v-else>
                    {{ item.testMedia.cultureMedia.nutrientAgar }}
                  </p>
                </v-row>
              </v-col>
              <v-col cols="3" class="px-5 pb-0">
                <v-row justify="center" align="center">
                  <v-select
                    v-model="item.testType"
                    :items="testTypes"
                    label="Tipo de ensayo"
                    outlined
                    dense
                  ></v-select>
                </v-row>
              </v-col>
              <v-col
                :cols="bcTestsSelected.length > 1 ? '4' : '5'"
                class="px-5 pb-0"
              >
                <v-row justify="center" align="center">
                  <v-combobox
                    v-model="item.inoculum"
                    label="Inóculo"
                    item-text="identificationSample"
                    :items="inocules"
                    :rules="inoculumRules"
                    :loading="loadingAnalysis"
                    return-object
                    outlined
                    dense
                  >
                    <template v-slot:selection="{item}">
                      <template v-if="!item.identificationSample.includes('Banco')">
                        <span v-if="item.microbiologicalTest">
                          🔬 {{item.identificationSample}}
                        </span>
                        <span v-else>
                          🧬 {{item.identificationSample}}
                        </span>
                      </template>
                      <template v-else>
                        <img
                          src="/favicon.png"
                          width="25px"
                          height="25px"
                        /><img />
                        <span>
                          {{ item.identificationSample }}</span
                        >
                      </template>
                    </template>
                    <template v-slot:item="{ item }">
                      <template v-if="!item.identificationSample.includes('Banco')">
                        <span v-if="item.microbiologicalTest">
                          🔬 {{item.identificationSample}}
                        </span>
                        <span v-else>
                          🧬 {{item.identificationSample}}
                        </span>
                      </template>
                      <template v-else>
                        <img
                          src="/favicon.png"
                          width="25px"
                          height="25px"
                        /><img />
                        <span class="font-weight-bold">
                          {{ item.identificationSample }}</span
                        >
                      </template>
                    </template>
                  </v-combobox>
                </v-row>
              </v-col>
              <v-col cols="1" class="px-0 pb-0" v-if="bcTestsSelected.length > 1">
                <v-row justify="center">
                  <v-btn @click="removeTest(index)" color="red" fab x-small dark>
                    <v-icon>mdi-delete-forever</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-row class="pt-0 mt-0">
          <v-col cols="4">
            <v-btn depressed block color="warning" @click="dialog = false">
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn depressed block color="secondary" @click="addTest">
              Añadir ensayo
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn depressed block color="primary" @click="createTests">
              Continuar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
// validations
import {
  requiredValidation,
  requiredFValidation,
} from "@/helpers/validations.js";
// library
import axios from "axios";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      loadingAnalysis: false,
      valid: true,
      analysisSelected: null,
      analysisToFound: "",
      // autocomplete
      searchAnalysisList: "",
      search: [],
      // items dilution
      dilutionItems: [
        { dilution: "P", inverse: 0.667 },
        { dilution: "Direct", inverse: 1 },
        { dilution: "-6", inverse: 1000000 },
        { dilution: "-5", inverse: 100000 },
        { dilution: "-4", inverse: 10000 },
        { dilution: "-3", inverse: 1000 },
        { dilution: "-2", inverse: 100 },
        { dilution: "-1", inverse: 10 },
      ],
      // rules
      analysisRules: requiredFValidation("muestra"),
      testMediaRules: requiredValidation("ID de medio"),
      inoculumRules: requiredValidation("Inóculo"),
      dilutionRules: requiredFValidation("dilución"),
      // user info
      user: JSON.parse(localStorage.getItem("user")),
      bcTestsSelected: [
        {
          testMedia: null,
          testMediaLoading: false,
          autocompleted : false,
          medium: null,
          testType: "S. Fósforo",
          inoculum: null,
        },
      ],
      testTypes: ["S. Fósforo", "S. Potasio"],
      inocules: [],
    };
  },
  watch: {
    // Search test media
    // async search(search) {
    //   if (this.dialog) {
    //     if (typeof this.cancelToken != typeof undefined) {
    //       this.cancelToken.cancel("Operation canceled due to new request.");
    //     }
    //     //Save the cancel token for the current request
    //     this.SET_CANCEL_TOKEN(axios.CancelToken.source());

    //     if (search) {
    //       try {
    //         const response = await this.searchTestMedia({
    //           laboratory: this.user.laboratory._id,
    //           status: "UNREGISTERED",
    //         });
    //         if (response.error) throw response;
    //       } catch (error) {
    //         console.log("Error searching test media", error);
    //         this.SET_ALERT_TYPE({
    //           visible: true,
    //           type: "error",
    //           text: error.message,
    //           timeout: 4000,
    //         });
    //       }
    //     }
    //   }
    // },
    // search samples that are validated
    async searchAnalysisList(value) {
      this.loadingAnalysis = true;

      if (value && typeof value == "string") {
        this.analysisSelected = null;

        if (typeof this.cancelToken != typeof undefined) {
          this.cancelToken.cancel("Operation canceled due to new request.");
        }
        //Save the cancel token for the current request
        this.SET_CANCEL_TOKEN(axios.CancelToken.source());

        try {
          const response = await this.searchAnalysis({
            laboratory: this.user.laboratory._id,
            status: ["MEASURED", "VALUATING", "VALUATED", "RECEIVED"],
            search: value,
          });

          if (response.error) throw response;

          try {
            const response = await this.searchTestMedia({
              laboratory: this.user.laboratory._id,
              status: "UNREGISTERED",
            });
            if (response.error) throw response;
          } catch (error) {
            console.log("Error searching test media", error);
            this.SET_ALERT_TYPE({
              visible: true,
              type: "error",
              text: error.message,
              timeout: 4000,
            });
          }
        } catch (error) {
          console.log("Error searching sample", error);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: error.message,
            timeout: 4000,
          });
        }
      } else {
        await this.processAnalysis(value);
      }

      this.loadingAnalysis = false;
    },
    dialog: function () {
      if (this.$refs.form) {
        // this.$refs.form.reset();
        this.analysisToFound = ""
        this.bcTestsSelected = [
          {
            testMedia: null,
            testMediaLoading: false,
            autocompleted : false,
            medium: null,
            testType: "S. Fósforo",
            inoculum: null,
          },
        ];
      }
    },
    analysisToFound() {
      let analysisString = this.analysisToFound;
      this.searchAnalysisList = analysisString;
    },
    bcTestsSelected: {
      async handler(val, oldVal) {
        if (val.length > 0) {
          for (const bcTest of val) {
            if (
              bcTest.testMediaLoading == false &&
              bcTest.testMedia == null &&
              bcTest.medium == null &&
              bcTest.inoculum &&
              bcTest.inoculum.identificationSample.includes("Banco") &&
              bcTest.inoculum.autocompleteTest &&
              bcTest.inoculum.autocompleteTest.length > 0
            ) {
              console.log(bcTest);

              let testType = bcTest.testType == 'S. Fósforo' ? 'Nutrition-phosphorus' : 'Nutrition-potasium'
              let testToAutocomplete = bcTest.inoculum.autocompleteTest.find(test => test.type == testType )

              if(testToAutocomplete){
                bcTest.testMediaLoading = true
                const response = await this.searchTestMediabyId(testToAutocomplete.testMedia);

                if(response.error){
                  this.SET_ALERT_TYPE({
                    visible: true,
                    type: "error",
                    text: `No se pudo agregar el ensayo, agrégala manualmente. Error: ${response.message}`,
                    timeout: 4000,
                  });
                }else{
                  bcTest.testMedia = response.testMedia
                  bcTest.autocompleted = true
                }

                bcTest.testMediaLoading = false
                break;
              }
            }
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(["cancelToken"]),
    ...mapState("reception", ["analysesFounded"]),
    ...mapState("testMedia", ["testMediaList"]),
    ...mapState("strain", ["bioactiveBankStrains"]),
  },
  methods: {
    ...mapMutations(["SET_CANCEL_TOKEN"]),
    ...mapMutations(["SET_LOADER"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("testMedia", ["searchTestMedia", "searchTestMediabyId"]),
    ...mapActions("microbiologicalTest", ["createMBTest"]),
    ...mapActions("biochemicalTest", ["addBCTest"]),
    ...mapActions("nutrition", ["addNutritionTest"]),
    ...mapActions("reception", [
      "searchValidatedReception",
      "getStrainsByAnalysis",
      "searchAnalysis",
    ]),

    // add sample to array of selected tests
    addTest() {
      this.bcTestsSelected.push({
        testMedia: null,
        testMediaLoading: false,
        autocompleted : false,
        medium: null,
        testType: "S. Fósforo",
        inoculum: null,
      });
    },
    // remove sample to array of selected tests
    removeTest(index) {
      this.bcTestsSelected.splice(index, 1);
    },

    // assing data selected for dilution to the actual row
    assingDataRow(i) {
      this.bcTestsSelected[i].dilution =
        this.bcTestsSelected[i].testMedia &&
        this.bcTestsSelected[i].testMedia.cultureMedia &&
        this.bcTestsSelected[i].testMedia.cultureMedia.dilution
          ? this.bcTestsSelected[i].testMedia.cultureMedia.dilution
          : null;

      if (this.bcTestsSelected[i].dilution) {
        this.bcTestsSelected[i].medium =
          this.bcTestsSelected[i].testMedia.cultureMedia.nutrientAgar;
      }

      this.bcTestsSelected[i].testType = 
        this.bcTestsSelected[i].testMedia && 
        this.bcTestsSelected[i].testMedia.cultureMedia && 
        this.bcTestsSelected[i].testMedia.cultureMedia.nutrientAgar == "Pikovskaya"  
        ? 'S. Fósforo' 
        : 'S. Potasio';
    },

    // get microorganism type
    getMOType(type) {
      let itemsMoType = {
        all: "Todos",
        bacteria: "Bacteria",
        fungi: "Hongo",
        nematode: "Nemátodo",
      };
      return itemsMoType[type];
    },

    async createTests() {
      if (this.$refs.form.validate()) {
        this.SET_LOADER(true);

        try {
          let testToSave = [];
          let bcTestPhosphorus = this.analysisSelected.bcTests.find(
            (test) => test.type == "Nutrition-phosphorus"
          );
          let bcTestPotasium = this.analysisSelected.bcTests.find(
            (test) => test.type == "Nutrition-potasium"
          );

          console.log("BIOCHEMICAL TESTS", this.analysisSelected.bcTests);

          for (const test of this.bcTestsSelected) {
            let nutritionTest = {};
            if (test.testType == "S. Fósforo" && bcTestPhosphorus) {
              nutritionTest = this.formatTestData(test, bcTestPhosphorus);
            } else if (test.testType == "S. Potasio" && bcTestPotasium) {
              nutritionTest = this.formatTestData(test, bcTestPotasium);
            } else {
              let bcTest = {
                user: this.user._id,
                type:
                  test.testType == "S. Fósforo"
                    ? "Nutrition-phosphorus"
                    : test.testType == "S. Potasio"
                    ? "Nutrition-potasium"
                    : "Nutrition-negativeControl",
                status: "IN_PROGRESS",
                analysis: this.analysisSelected._id,
                isolate:
                  test.testType === "S. Fósforo"
                    ? "619fe99abc8a44acf554349c"
                    : "619fe8b6bc8a44acf554349b",
                laboratory: this.user.laboratory._id,
              };

              let request = await this.addBCTest(bcTest);

              if (request.error) throw new Error(request.message);

              if (request.biochemicalTest.type == "Nutrition-phosphorus") {
                bcTestPhosphorus = request.biochemicalTest;
                nutritionTest = this.formatTestData(test, bcTestPhosphorus);
              } else {
                bcTestPotasium = request.biochemicalTest;
                nutritionTest = this.formatTestData(test, bcTestPotasium);
              }
            }

            console.log("NEW NUTRITION", nutritionTest);
            const response = await this.addNutritionTest(nutritionTest);
            if (response.error) {
              throw response;
            }
          }

          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: "Nutriciónes agregadas correctamente.",
            timeout: 4000,
          });

          this.$emit("newTests");
          this.dialog = false;
        } catch (error) {
          console.log(error);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text:
              error.message === "testMediaRegisted"
                ? "Ya está registrado este medio de cultivo"
                : "Ha ocurrido un error, inténtalo de nuevo",
            timeout: 4000,
          });
        }
        this.SET_LOADER(false);
      }
    },
    formatTestData(test, bqTest) {
      if (test.inoculum.autocompleteTest.length > 0) {
        let morphology = test.inoculum.autocompleteTest.find(
          (test) => test.type == bqTest.type
        );

        if (morphology) {
          return {
            status: "COMPLETED",
            biochemicalTest: bqTest._id,
            nutrient:
              bqTest.type == "Nutrition-phosphorus"
                ? "phosphorum_solubilization"
                : "potassium_solubilization",
            testMedia: test.testMedia._id,
            inoculation: new Date(),
            incubation: {
              startDate: new Date(),
              endDate: new Date(),
              morphologyPhoto: morphology.morphologyPhoto,
            },
            strain: test.inoculum.strainId,
            soilSample: test.inoculum.soilSample
              ? test.inoculum.soilSample
              : undefined,
            solubilization: "Very good",
            laboratory: this.user.laboratory._id,
          };
        }
      }

      return {
        status: "INOCULATED",
        biochemicalTest: bqTest._id,
        nutrient:
          bqTest.type == "Nutrition-phosphorus"
            ? "phosphorum_solubilization"
            : "potassium_solubilization",
        testMedia: test.testMedia._id,
        inoculation: new Date(),
        strain: test.inoculum.strainId,
        soilSample: test.inoculum.soilSample
          ? test.inoculum.soilSample
          : undefined,
        solubilization: "Good",
        laboratory: this.user.laboratory._id,
      };
    },

    async processAnalysis(analysisSelected) {
      this.inocules = [];
      this.analysisSelected = analysisSelected;

      if (analysisSelected) {
        let analysisId = analysisSelected._id;

        let request = await this.getStrainsByAnalysis({
          analysis: analysisId,
          threath: false,
          laboratory : this.user.laboratory._id
        });

        if (request.error) throw new Error();

        let inocules = [];
        for (const strain of request.strains) {
          if (!strain.posibleThreat) {
            let obj = {
              strainId: strain._id,
              microbiologicalTest: strain.microbiologicalTest,
              identification: strain.identification.name,
              identificationSample: `${strain.identification.name} - ${strain.sampleFolio}`,
              moType: strain.identification.moType,
              concentration: strain.concentration,
              soilSample: strain.soilSample,
              autocompleteTest: [],
            };

            inocules.push(obj);
          }
        }

        for (const strain of this.bioactiveBankStrains) {
          let obj = {
            strainId: strain._id,
            identification: strain.identification.name,
            identificationSample: `${strain.identification.name} - Banco de bioactivos`,
            moType: strain.identification.moType,
            autocompleteTest: strain.autocompleteTests
              ? strain.autocompleteTests.filter((test) =>
                  test.type.includes("Nutrition")
                )
              : [],
          };

          inocules.push(obj);
        }

        this.inocules = inocules;
      }
    },
  },
};
</script>
