<template>
  <v-card tile elevation="0" class="my-auto pb-1">
    <v-container>
      <!-- Photo row -->
      <v-row>
        <v-col cols="12" md="12">
          <template v-if="imageUrl == null">
            <v-img
              :src="require('../../assets/img/no_image_strain.png')"
              aspect-ratio="1.0"
              contain
            ></v-img>
            <v-row
              v-if="antagonismTestDB && antagonismTestDB.status == 'INCUBATING'"
              justify="center"
            >
              <v-col cols="8">
                <AddEvidencePhotograph
                  :testMedia="test.idTest"
                  :testData="antagonismTestDB"
                  testType="Confrontación"
                  @imageUpdated="formatData()"
                />
              </v-col>
            </v-row>
          </template>

          <template v-else>
            <v-img :src="imageUrl" aspect-ratio="1.0" contain></v-img>
            <v-row justify="center" align="center">
              <v-col cols="4">
                <EditMiniature
                  :imageUrl="urlOriginalImage"
                  @imageUpdated="formatData()"
                />
              </v-col>
              <v-col
                cols="4"
                v-if="
                  antagonismTestDB && antagonismTestDB.status == 'IN_PROGRESS'
                "
              >
                <AddEvidencePhotograph
                  :testMedia="test.idTest"
                  :testData="antagonismTestDB"
                  testType="Confrontación"
                  @imageUpdated="formatData()"
                />
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
      <template>
        <!-- Analisis data row -->
        <v-row class="px-10">
          <v-col cols="7">
            <p class="font-weight-bold text-left mb-1">
              Análisis {{ test.folioAnalysis }}
            </p>
          </v-col>
          <v-col cols="5">
            <!-- <p class="font-weight-bold text-right mb-1">INICIAL MS</p> -->
          </v-col>
        </v-row>
        <!-- Test data headers row -->
        <v-row class="mt-0 pr-2">
          <v-col cols="2" class="pr-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              ID ensayo
            </p>
          </v-col>
          <v-col cols="2" class="px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">Medio</p>
          </v-col>
          <v-col cols="3" class="px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              Inóculo
            </p>
          </v-col>
          <v-col cols="2" class="px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              Caja petri
            </p>
          </v-col>
          <v-col cols="3" class="px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              Tipo análisis
            </p>
          </v-col>
        </v-row>
        <!-- Test data row -->
        <v-row class="mt-0 pr-2">
          <v-col cols="2" class="pr-0">
            <p class="text-caption text-center mb-1">{{ test.idTest }}</p>
          </v-col>
          <v-col cols="2" class="px-0">
            <p class="text-caption text-center mb-1">{{ test.medium }}</p>
          </v-col>
          <v-col cols="3" class="px-0">
            <p class="text-caption text-center mb-1">
              {{ test.inoculumControl }} vs {{ test.inoculumBeneficial }}
            </p>
          </v-col>
          <v-col cols="2" class="px-0">
            <p
              class="text-caption text-center mb-1"
              v-if="test.containerType == 'petriDish90mmx15mm'"
            >
              90x15 mm
            </p>
            <p class="text-caption text-center mb-1" v-else>60x15 mm</p>
          </v-col>
          <v-col cols="3" class="px-0">
            <p class="text-caption text-center mb-1">Confrontación</p>
          </v-col>
        </v-row>
        <!-- Area data row -->
        <v-row class="px-6 mt-0">
          <v-container>
            <v-data-table
              :headers="headers"
              :items="tests"
              item-key="idStrain"
              class="elevation-0"
              hide-default-footer
              hide-default-header
              mobile-breakpoint="100"
            >
              <template v-slot:[`item.moType`]="{ item }">
                <strong class="text-bold">{{ item.moType }}</strong>
              </template>
              <template v-slot:[`item.mo`]="{ item }">
                <strong
                  class="warning--text"
                  v-if="item.moType == 'Control' || item.moType == 'Patógeno'"
                  >{{ item.mo }}</strong
                >
                <strong class="secondary--text" v-else>{{ item.mo }}</strong>
              </template>
              <template v-slot:[`item.growth`]="{ item }">
                <strong class="text-bold"> {{ item.growth }} mm </strong>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu
                  v-model="item.menu"
                  v-if="item.moType != 'Control' && !disabledFields"
                  :offset-x="true"
                  :offset-y="false"
                  transition="slide-x-transition"
                  max-height="65"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-bind="attrs" v-on="on">
                      mdi-dots-vertical
                    </v-icon>
                  </template>

                  <v-card height="38">
                    <v-container class="pt-4 pb-4">
                      <v-row align="center" justify="center">
                        <v-dialog v-model="item.dialog" width="250">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              block
                              text
                              small
                              :color="'primary'"
                              v-bind="attrs"
                              v-on="on"
                            >
                              Actualizar
                            </v-btn>
                          </template>

                          <v-card>
                            <v-card-title class="text-h6 secondary white--text">
                              Crecimiento
                            </v-card-title>

                            <v-container>
                              <v-row justify="center" align="center">
                                <v-col cols="5">
                                  <p class="text-center mb-0">
                                    {{ item.moType }}
                                  </p>
                                </v-col>
                                <v-col cols="3">
                                  <v-text-field
                                    v-if="item.moType == 'Patógeno'"
                                    v-model="threathGrowth"
                                  ></v-text-field>
                                  <v-text-field
                                    v-else
                                    v-model="beneficialGrowth"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="3">mm</v-col>
                              </v-row>
                            </v-container>

                            <v-divider></v-divider>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                :disabled="
                                  threathGrowth == '' || beneficialGrowth == ''
                                "
                                color="primary"
                                text
                                @click="saveGrowth(item.moType)"
                              >
                                Guardar
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-menu>
              </template>
            </v-data-table>
          </v-container>
        </v-row>

        <v-row class="mt-4">
          <v-row justify="center" align="center">
            <v-switch
              v-model="mycoparasitism"
              :disabled="disabledFields"
              :true-value="true"
              :false-value="false"
              dense
              class="pt-0 mt-0"
              inset
              label="Micoparasitismo"
            ></v-switch>
          </v-row>
          <v-col cols="12">
            <p class="text-center secondary--text font-weight-bold">
              Biocontrol {{ biocontrolPercentage }}% - {{ biocontrol }}
            </p>
          </v-col>
        </v-row>
      </template>
      <v-card-actions class="justify-center pt-0">
        <v-row class="pt-0" justify="center" align="center">
          <v-col cols="8">
            <v-btn
              depressed
              block
              color="secondary"
              :loading="loading"
              :disabled="disabledFields"
              @click="saveTest"
            >
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import EditMiniature from "./modals/EditMiniature.vue";
import AddEvidencePhotograph from "./modals/AddEvidencePhotograph.vue";

export default {
  components: {
    EditMiniature,
    AddEvidencePhotograph,
  },
  data() {
    return {
      loading: false,
      dialog: false,
      threathGrowth: "0",
      beneficialGrowth: "0",
      headers: [
        { text: "Tipo", value: "moType", sortable: false, align: "center" },
        { text: "MOs", value: "mo", sortable: false, align: "center" },
        {
          text: "Crecimiento",
          value: "growth",
          sortable: false,
          align: "center",
        },
        { text: " ", value: "actions", sortable: false, align: "center" },
      ],
      controlData: {
        moType: "Control",
        mo: "- - -",
        growth: "0",
        dialog: false,
        menu: false,
      },
      threathData: {
        moType: "Patógeno",
        mo: "- - -",
        growth: "0",
        dialog: false,
        menu: false,
      },
      beneficialData: {
        moType: "Benéfico",
        mo: "- - -",
        growth: "0",
        dialog: false,
        menu: false,
      },
      mycoparasitism: false,
      biocontrolPercentage: 0,
      disabledFields: false,
      imageUrl: null,
      urlOriginalImage: "",
      isolateDB: null,
      antagonismTestDB: null,
    };
  },
  props: {
    test: {
      type: Object,
      require: true,
    },
  },
  async created() {
    await this.formatData();
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("antagonism", ["updateAntagonismTest", "getAntagonismTests"]),
    ...mapActions("isolate", ["getIsolateById"]),
    async formatData() {
      console.log(this.test);
      let bcTest = this.test.bcTestData;
      let antTest = this.test.testData;
      let isolate = bcTest.isolate;

      let requestIsolate = await this.getIsolateById({ id: isolate._id });
      console.log("CONTROL", requestIsolate.ISOLATE);
      this.isolateDB = requestIsolate.ISOLATE;

      let requestTest = await this.getAntagonismTests(bcTest._id);
      let antagonismTests = requestTest.ANTAGONISM_LIST;

      let antagonismTest = antagonismTests.find(
        (test) => test._id == antTest._id
      );

      if (antagonismTest) {
        this.antagonismTestDB = antagonismTest;
        console.log("TEST", antagonismTest);
      }

      this.controlData = {
        moType: "Control",
        mo: this.test.inoculumControl,
        growth: this.isolateDB.isolateGrowth,
        dialog: false,
        menu: false,
      };

      this.threathData = {
        moType: "Patógeno",
        mo: this.test.inoculumControl,
        growth: this.antagonismTestDB.pathogenGrowth
          ? this.antagonismTestDB.pathogenGrowth
          : "0",
        dialog: false,
        menu: false,
      };

      this.beneficialData = {
        moType: "Benéfico",
        mo: this.test.inoculumBeneficial,
        growth: this.antagonismTestDB.beneficialGrowth
          ? this.antagonismTestDB.beneficialGrowth
          : "0",
        dialog: false,
        menu: false,
      };

      this.mycoparasitism = this.antagonismTestDB.mycoparasitism
        ? this.antagonismTestDB.mycoparasitism
        : false;

      this.disabledFields =
        this.antagonismTestDB.status == "IN_PROGRESS" ? false : true;
      this.imageUrl =
        (this.antagonismTestDB.status == "IN_PROGRESS" ||
          this.antagonismTestDB.status == "COMPLETED") &&
        this.antagonismTestDB.incubation.morphologyPhoto
          ? this.antagonismTestDB.incubation.morphologyPhoto
          : null;

      if (this.imageUrl) {
        this.urlOriginalImage = this.imageUrl;
        if (!this.imageUrl.includes("-small")) {
          this.imageUrl =
            this.imageUrl.slice(0, this.imageUrl.length - 4) +
            "-small.jpg?" +
            Date.now();
        } else {
          this.imageUrl = `${this.imageUrl}?${Date.now()}`;
          this.urlOriginalImage = `${this.imageUrl.split("-small")[0]}${
            this.imageUrl.split("-small")[1]
          }`;
        }
      }

      this.calculateInvasionPercentage(
        this.controlData.growth,
        this.threathData.growth
      );
    },
    calculateInvasionPercentage(isolateGrowth, pathogenGrowth) {
      if (isolateGrowth != 0 && pathogenGrowth != 0) {
        let controlDiameter = isolateGrowth * 10;
        let testDiameter = pathogenGrowth * 10;
        let calculate =
          ((controlDiameter - testDiameter) / controlDiameter) * 100;
        const percentage = calculate.toFixed(2);

        this.biocontrolPercentage = percentage;
      } else {
        this.biocontrolPercentage = 0;
      }
    },
    saveGrowth(type) {
      if (type == "Benéfico") {
        this.beneficialData.growth = this.beneficialGrowth;
        this.beneficialData.dialog = false;
        this.beneficialData.menu = false;
      } else {
        this.threathData.growth = this.threathGrowth;
        this.threathData.dialog = false;
        this.threathData.menu = false;
      }

      this.calculateInvasionPercentage(
        this.controlData.growth,
        this.threathData.growth
      );

      this.beneficialGrowth = "0";
      this.threathGrowth = "0";
    },
    async saveTest() {
      this.loading = true;
      try {
        let antagonismTest = {
          _id: this.test._id,
          pathogenGrowth: this.threathData.growth,
          beneficialGrowth: this.beneficialData.growth,
          mycoparasitism: this.mycoparasitism,
        };

        console.log("NEW ANTAGONISM TEST", antagonismTest);

        const response = await this.updateAntagonismTest(antagonismTest);
        if (response.error) {
          throw response;
        }

        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: "Confrontación actualizada correctamente",
          timeout: 4000,
        });

        // this.$emit("testUpdated");
      } catch (e) {
        console.log(e);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Error al actualizar confrontación, comunicate a soporte técnico para dar seguimiento.",
          timeout: 4000,
        });
      }

      this.loading = false;
    },
  },
  watch: {
    async test() {
      await this.formatData();
    },
  },
  computed: {
    biocontrol() {
      let bioControl = "";
      let mycoparasitism = this.mycoparasitism;
      if (
        this.biocontrolPercentage <= 0 ||
        (this.biocontrolPercentage > 0 && this.biocontrolPercentage < 1)
      ) {
        bioControl = "Muy malo";
      } else if (
        this.biocontrolPercentage <= 1 ||
        (this.biocontrolPercentage > 1 && this.biocontrolPercentage < 25)
      ) {
        bioControl = "Malo";
      } else if (
        this.biocontrolPercentage >= 25 &&
        this.biocontrolPercentage <= 50
      ) {
        bioControl = "Deficiente";
      } else if (
        this.biocontrolPercentage >= 50 &&
        this.biocontrolPercentage <= 100
      ) {
        if (mycoparasitism) {
          bioControl = "Muy bueno";
        } else {
          bioControl = "Bueno";
        }
      }

      return bioControl;
    },
    tests() {
      return [this.controlData, this.threathData, this.beneficialData];
    },
  },
};
</script>
