export default {
  profit_selected: null,
  strains: [],
  strains_selected: [],
  profit_version: null,
  months_data: [],
  suggestions: [],
  weeksNotAvailable: [],
  summary: null,
  loadingProfitPages: 0,
};
