import axios from "axios";

  /**
 * Function to verify if container is correct
 * @param {Object} { commit } - Vuex function
 * @param {String} payload - container code
 */
   export async function getReceptionDetails({ commit }, payload) {
    try {
      const { data } = await axios.get(`/reception/receptionDetail/${payload.analysis}/${payload.laboratory}`);
      commit("SET_CONTAINERS",data.receptions)
      return data;
    } catch (e) {
      return e.response.data;
    }
  }


  export async function addPCT({ commit }, payload) {
    try {
      const { data } = await axios.post(`/physicalChemicalTest/add`, payload);
      commit("SET_PCT_SELECTED", data.PCTest);
      if (data.error) {
        throw data;
      }
      return data;
    } catch (error) {
      return error.response.data;
    }
  }

  export async function updatePCT({commit},payload) {
    try{
      const { data } = await axios.put(`/physicalChemicalTest/update/`, payload);
      commit("SET_PCT_SELECTED", data.PCTest);
      if (data.error) {
        throw data;
      }
      return data;
    }catch(error){
      return error.response.data;
    }
  }


  export async function getPCTBySample({commit},payload) {
    try{
      const { data } = await axios.get(`/physicalChemicalTest/sample/${payload}`);
      commit("SET_PCT_SELECTED", data.PCTest);
      if (data.error) {
        throw data;
      }
      return data;
    }catch(error){
      return error.response.data;
    }
  }

  export async function getPCTByReception({commit},payload) {
    try{
      const { data } = await axios.get(`/physicalChemicalTest/reception/${payload}`);
      commit("SET_PCT_SELECTED", data.PCTest);
      if (data.error) {
        throw data;
      }
      return data;
    }catch(error){
      return error.response.data;
    }
  }

