import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import vuetify from "./plugins/vuetify";

// Globals styles
import "./assets/css/main.css";

// Plugins
require("./plugins");

// Dayjs
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
const es = require("dayjs/locale/es");
dayjs.locale(es);
dayjs.extend(relativeTime); // to use function "fromNow"

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
