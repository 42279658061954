import axios from "axios";

/**
 * Function to add new molecular plate
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - Molecular plate data object
 * @returns {data} -  Returns array of molecular plates
 */
export async function addNewPlate({ commit }, payload) {
  try {
    const { data } = await axios.post("/molecularPlate/new-plate", payload);
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get plates numbers from DB
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - Filters
 * @returns {data} -  Returns object with different stats
 */
export async function getPlatesStats({ commit, rootState }, payload) {
  try {
    const { data } = await axios.get("/molecularPlate/plates-stats", {
      params: payload,
    });
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get plates data
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - Filters
 * @returns {data} -  Returns array of plates info
 */
 export async function getPlates({ commit, rootState }, payload) {
  try {
    const { data } = await axios.get("/molecularPlate/get-plates", {
      params: payload,
    });
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to update plate data
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - Plate data object
 * @returns {data} -  Returns status of update
 */
 export async function updatePlate({ commit, rootState }, payload) {
  try {
    const { data } = await axios.put("/molecularPlate/update-plate", payload);
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get PDF from plate
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - Plate id
 * @returns {data} -  Returns status & pdf url
 */
 export async function getPdfFile({ commit, rootState }, payload) {
  try {
    const { data } = await axios.post("/molecularPlate/generate-pdf", payload);
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to set replica plate status
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - Plate ID & status
 * @returns {data} -  Returns status
 */
export async function setReplicaPlate({ commit, rootState }, payload) {
  try {
    const { data } = await axios.put("/molecularPlate/replica-plate", payload);
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}
