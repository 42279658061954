<template>
  <v-dialog v-model="show" width="auto" scrollable persistent>
    <v-card height="auto" elevation="5" class="rounded-lg" min-height="200">
      <v-card-title class="text-h5 lighten-2">
        Resumen de valuación
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <span class="text-subtitle-2 font-weight-bold">
                Control de hongos fitopatógenos
              </span>
            </v-col>
            <v-col>
              <v-simple-table height="auto" fixed-header dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-left"
                        v-for="(header, index) in headers"
                        :key="index"
                      >
                        {{ header }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(row, i) in dataSet"
                      :key="i"
                      :class="row.isSelected ? 'menu-item-selected' : ''"
                    >
                      <td
                        v-for="(colum, y) in row.columnValues"
                        :key="y"
                        :class="y == 0 ? 'text-left' : 'text-center'"
                      >
                        <span v-if="y == 0">
                          <v-icon
                            :color="row.type == 'fungi' ? 'red' : 'primary'"
                            >{{ getIcon(row.type) }}</v-icon
                          >
                          {{ colum }}
                          <span v-if="row.isBioactiveBank">
                            <img
                              src="/favicon.png"
                              width="25px"
                              height="25px"
                            /><img />
                          </span>
                        </span>
                        <span v-else>
                          {{ colum }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <span class="text-subtitle-2 font-weight-bold">
                Solubilización de fósforo y potasio
              </span>
            </v-col>
            <v-col cols="12" v-if="!solubilization">
              <v-icon color="primary"> mdi-nutrition </v-icon>
              <span class="font-italic black--text"
                >No lleva seleccón de solubilización de fósforo y potasio</span
              >
            </v-col>
            <v-col cols="12" v-else>
              <v-icon color="primary"> mdi-nutrition </v-icon>
              <span v-if="nutritonSuggestion" class="font-italic black--text">
                {{ nutritonSuggestion.name }}
              </span>
              <span v-else class="font-italic black--text"
                >Pseudomonas fluorescens
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <span class="text-subtitle-2 font-weight-bold">
                Control de nemátodos
              </span>
            </v-col>
            <v-col cols="12" v-if="isHightNematodes">
              <span class="font-italic black--text"
                >Paecilomyces lilacinus</span
              >
              <v-spacer></v-spacer>
              <span class="font-italic black--text"
                >Pochonia chlamydosporia</span
              >
            </v-col>
            <v-col cols="12" v-else>
              <span class="font-italic black--text">
                Paecilomyces lilacinus
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col>
              <v-btn color="gray" block @click="closeModal" dark>Cerrar</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//vuex
import { mapState } from "vuex";
//components

export default {
  components: {},
  data: () => ({
    desserts: [
      {
        name: "Frozen Yogurt",
        calories: 159,
      },
      {
        name: "Ice cream sandwich",
        calories: 237,
      },
      {
        name: "Eclair",
        calories: 262,
      },
      {
        name: "Cupcake",
        calories: 305,
      },
      {
        name: "Gingerbread",
        calories: 356,
      },
      {
        name: "Jelly bean",
        calories: 375,
      },
      {
        name: "Lollipop",
        calories: 392,
      },
      {
        name: "Honeycomb",
        calories: 408,
      },
      {
        name: "Donut",
        calories: 452,
      },
      {
        name: "KitKat",
        calories: 518,
      },
    ],
    dataSet: [],
    headers: [],
    solubilization: false,
    isHightNematodes: false,
    nutritonSuggestion: null,
  }),
  props: {
    value: Boolean,
  },
  async mounted() {
    await this.setData();
  },
  computed: {
    ...mapState("profit", ["summary"]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    closeModal() {
      this.show = false;
    },
    async setData() {
      //   set data in table
      let benefic = [];
      for (let strain of this.summary.strainsWithAcerage) {
        let columnValues = [];
        for (let patoghen of this.summary.pathogenNames) {
          let exist = strain.isolates.find(
            (element) => element.name == patoghen
          );
          if (exist) {
            columnValues.push(exist.inhibition);
          } else {
            columnValues.push(0);
          }
        }
        columnValues.push(parseFloat(strain.average).toFixed(2));
        columnValues.unshift(strain.name);
        benefic.push({
          isSelected: strain.isSelected ? strain.isSelected : false,
          type: strain.moType,
          isBioactiveBank: strain.type == "Bioactivo",
          columnValues,
        });
      }
      this.dataSet = benefic;
      // set headers
      this.headers.push(...this.summary.pathogenNames);
      this.headers.unshift("");
      this.headers.push("Promedio");
      //   set solubilization
      this.solubilization = this.summary.solubilization
        ? this.summary.solubilization
        : false;
      this.isHightNematodes = this.summary.isHightNematodes;
      this.nutritonSuggestion = this.summary.nutritonSuggestion;
    },
    getIcon(type) {
      if (type == "fungi") {
        return "mdi-mushroom-outline";
      } else if (type == "bacteria") {
        return "mdi-bacteria-outline";
      } else {
        return "mdi-help";
      }
    },
  },
};
</script>

<style scoped>
.menu-item-selected {
  background-color: #cef5bf;
  animation: bounce 0.3s;
}
</style>
