<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-card-title primary-title>
            <v-row>
              <v-col cols="12" md="4" class="text-center">
                <v-btn
                  icon
                  color="primary"
                  @click="$router.push(`/sequencing-reports`)"
                >
                  <v-icon>mdi-arrow-left-thick</v-icon>
                </v-btn>
                Muestras para Secuenciación
              </v-col>
              <v-col cols="12" md="3" offset-md="3">
                <add-results-modal
                  @newResultsSaved="loadSequencingReportData"
                />
              </v-col>
              <v-col cols="12" md="2">
                <v-btn
                  @click="showSelectOrginSecuencingModal = true"
                  color="success"
                  :loading="downloadReport"
                  block
                  >Generar reporte</v-btn
                >
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="scrolleable">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <!-- Plate Data -->
                  <v-row justify="center">
                    <v-col cols="6" md="2">
                      <p>
                        <strong class="black--text">Nombre del reporte:</strong>
                      </p>
                      <span>{{ reportData ? reportData.name : "" }}</span>
                    </v-col>
                    <v-col cols="6" md="10">
                      <p><strong class="black--text">Descripción:</strong></p>
                      <span>{{
                        reportData ? reportData.description : ""
                      }}</span>
                    </v-col>
                    <v-col cols="12" class="text-center">
                      <h3>
                        Placa de muestras -
                        <strong>{{
                          reportData ? reportData.plate.folio : "-"
                        }}</strong>
                      </h3>
                      <small
                        >Click sobre el pozo de la placa para editar el
                        valor</small
                      >
                    </v-col>
                    <v-col cols="10" class="pt-0">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-center pointer"></th>
                              <th class="text-center pointer">1</th>
                              <th class="text-center pointer">2</th>
                              <th class="text-center pointer">3</th>
                              <th class="text-center pointer">4</th>
                              <th class="text-center pointer">5</th>
                              <th class="text-center pointer">6</th>
                              <th class="text-center pointer">7</th>
                              <th class="text-center pointer">8</th>
                              <th class="text-center pointer">9</th>
                              <th class="text-center pointer">10</th>
                              <th class="text-center pointer">11</th>
                              <th class="text-center pointer">12</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(row, index) in plateData" :key="index">
                              <th>{{ row.cells[0].wellPlate[1] }}</th>
                              <td
                                v-for="(cell, index) in row.cells"
                                :key="`c-${index}`"
                                class="text-center pa-0 pointer"
                              >
                                <v-card
                                  :color="
                                    cell.folioAnalysis == '-'
                                      ? '#f2f2f2'
                                      : '#ff8f1c'
                                  "
                                  class="ma-1 mx-auto"
                                  width="7vh"
                                  elevation="4"
                                >
                                  <v-card-text class="px-0 py-2">
                                    <v-edit-dialog
                                      :return-value.sync="cell.folioAnalysis"
                                      @save="saveWell(cell)"
                                      large
                                      persistent
                                    >
                                      <!-- @cancel="cancel"
                                      @open="open"
                                      @close="close" -->
                                      <small
                                        :class="
                                          cell.folioAnalysis == '-'
                                            ? ''
                                            : 'white--text'
                                        "
                                      >
                                        {{ cell.folioAnalysis }}
                                      </small>
                                      <template v-slot:input>
                                        <div class="mt-4 text-h6">
                                          Pozo ({{ cell.wellPlate[1] }},
                                          {{ cell.wellPlate[0] }})
                                        </div>
                                        <!-- <v-text-field
                                          v-model="cell.folioAnalysis"
                                          label="Muestra"
                                          single-line
                                          autofocus
                                        ></v-text-field> -->
                                        <v-combobox
                                          v-model="cell.folioAnalysis"
                                          :items="samplesAssigned"
                                          :return-object="false"
                                          item-text="folioAnalysis"
                                          item-value="folioAnalysis"
                                          label="Folio"
                                        ></v-combobox>
                                      </template>
                                    </v-edit-dialog> </v-card-text
                                ></v-card>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>

                    <!-- Results Table -->
                    <v-col cols="12" class="text-center">
                      <h3>Resultados</h3>
                      <small
                        >Click sobre la celda de volúmen para editar el
                        valor</small
                      >
                    </v-col>

                    <v-col cols="8" class="pt-0">
                      <v-data-table
                        :headers="headers"
                        :items="sequencingData"
                        :items-per-page="200"
                        fixed-header
                        height="40vh"
                        hide-default-footer
                        dense
                      >
                        <template v-slot:[`item.totalDna`]="props">
                          <span>{{
                            (
                              props.item.volume * props.item.concentration
                            ).toFixed(3)
                          }}</span>
                        </template>
                        <template v-slot:[`item.volume`]="props">
                          <v-edit-dialog
                            :return-value.sync="props.item.volume"
                            @save="saveSample(props.item)"
                            large
                            persistent
                          >
                            {{ props.item.volume }}
                            <template v-slot:input>
                              <div class="mt-4 text-h6">
                                Volume uL ({{ props.item.sample }})
                              </div>
                              <v-text-field
                                v-model.number="props.item.volume"
                                label="Edit"
                                single-line
                                counter
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
    <SelectOrginSecuencingModal
      v-model="showSelectOrginSecuencingModal"
      v-if="showSelectOrginSecuencingModal"
      :trigger="generateReport"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

import AddResultsModal from "../components/sequencing/modals/AddResultsModal.vue";
import SelectOrginSecuencingModal from "@/components/sequencing/modals/SelectOrginSecuencingModal.vue";

export default {
  components: { AddResultsModal, SelectOrginSecuencingModal },
  name: "Sequencing",
  data() {
    return {
      snack: false,
      snackColor: "",
      snackText: "",
      max25chars: (v) => v.length <= 25 || "Input too long!",
      pagination: {},
      headers: [
        {
          text: "ID SAMPLE",
          align: "start",
          value: "sample",
        },
        {
          text: "CONCENTRATION Qubit (ng/uL)",
          value: "concentration",
          sortable: false,
        },
        { text: "TOTAL DNA (ng)", value: "totalDna", sortable: false },
        { text: "VOLUME (uL)", value: "volume", sortable: false },
        { text: "A 260/280", value: "a260280", sortable: false },
        { text: "A 260/230", value: "a260230", sortable: false },
      ],
      reportData: null,
      sequencingData: [],
      plateData: [],
      downloadReport: false,
      showSelectOrginSecuencingModal: false,
    };
  },
  computed: {
    sequencingReportId() {
      return this.$route.params.id;
    },
    reportPlate() {
      return this.reportData && this.reportData.plate
        ? this.reportData.plate
        : [];
    },
    samplesAssigned() {
      return this.reportData
        ? this.reportData.samples.map((s) => {
            // const disabled = this.samplesDisabled.find(
            //   (sD) => sD == s.folioAnalysis
            // );
            return {
              folioAnalysis: s.folioAnalysis,
              disabled: false
              // typeof disabled != "undefined" ? true : false,
            };
          })
        : [];
    },
    samplesDisabled() {
      return this.reportData && this.reportData.plate
        ? this.reportData.plate.wells.map((s) => s.folioAnalysis)
        : [];
    },
  },
  methods: {
    ...mapActions("sequencingReport", [
      "getReportById",
      "updateWell",
      "updateSampleData",
      "sendReports",
    ]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async saveWell(wellData) {
      try {
        const request = await this.updateWell(wellData);

        await this.loadSequencingReportData();
        this.generatePlateWells();
        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: `Pozo actualizado correctamente`,
          timeout: 2000,
        });

        console.log(request);
      } catch (error) {
        console.error(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Error: ${error.message}`,
          timeout: 2000,
        });
      }
    },
    async saveSample(sample) {
      try {
        const request = await this.updateSampleData({
          reportId: this.sequencingReportId,
          data: sample,
        });

        console.log(request);

        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: `Resultado actualizado`,
          timeout: 2000,
        });
      } catch (error) {
        console.error(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Error al actualizar los resultados`,
          timeout: 2000,
        });
      }
      console.log(sample);
    },
    async loadSequencingReportData() {
      try {
        let request = await this.getReportById(this.sequencingReportId);

        this.reportData = request.report;
        this.sequencingData = request.report.data;
      } catch (error) {
        console.error(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Error: ${error.message}`,
          timeout: 2000,
        });
      }
    },
    generatePlateWells() {
      const rows = ["A", "B", "C", "D", "E", "F", "G", "H"];
      const cols = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

      let wells = [];

      for (const row of rows) {
        let rowData = {
          row,
          cells: [],
        };
        for (const col of cols) {
          const wellDB = this.reportPlate.wells.find(
            (w) => w.wellPlate[0] == col && w.wellPlate[1] == row
          );

          if (wellDB) {
            rowData.cells.push({ ...wellDB });
          } else {
            rowData.cells.push({
              molecularPlate: this.reportData.plate._id,
              soilSample: null,
              isVegetal: false,
              wellPlate: [col, row],
              control: false,
              folioAnalysis: "-",
            });
          }
        }

        wells.push(rowData);
      }

      this.plateData = wells;

      // folioAnalysis
    },
    async generateReport(target) {
      this.downloadReport = true;

      try {
        const response = await this.sendReports({
          reportId: this.reportData._id,
          target: target,
        });

        if (response.error) {
          throw Error(response);
        }

        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: `Reportes enviados por correo`,
          timeout: 2000,
        });
      } catch (error) {
        console.error(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Error al generar reportes: ${error.message}`,
          timeout: 2000,
        });
      }

      this.downloadReport = false;
    },
  },
  async created() {
    try {
      await this.loadSequencingReportData();
      this.generatePlateWells();
    } catch (error) {
      console.error(error);
      this.SET_ALERT_TYPE({
        visible: true,
        type: "error",
        text: `Error: ${error.message}`,
        timeout: 2000,
      });
    }
  },
};
</script>

<style scoped>
.scrolleable {
  height: 78vh;
  overflow: auto;
}
</style>
