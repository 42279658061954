import axios from "axios";

/**
 * Function to set observation text
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - Text & analysis data
 * @returns {data} -  Returns status of update
 */
export async function setObservationTC({ commit }, payload) {
  try {
    const { data } = await axios.post("/analysis/setObservationTC", payload);
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get plates numbers from DB
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - Filters
 * @returns {data} -  Returns object with different stats
 */
export async function getDataById({ commit, rootState }, payload) {
  try {
    const { data } = await axios.get("/analysis/getDataById", {
      params: payload,
    });
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to delete an analysis
 * @param  {function} {commit} - Vuex function
 * @param {Object} payload - Filters to found an analysis to delete
 * @returns
 */
export async function deleteAnalysisFiltered({ commit }, payload) {
  try {
    console.log(payload);
    const { data } = await axios.delete("/analysis/delete", {
      params: payload,
    });
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function storeSamples({ commit }, payload) {
  try {
    const { data } = await axios.post("/analysis/storeSamples", payload);
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function storedSampleNotAvailables({ commit }, payload) {
  try {
    const { data } = await axios.post(
      "/analysis/storedSampleNotAvailables",
      payload
    );
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get analysis stored in laboratory
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - Filters
 * @returns {data} -  Returns object with different stats
 */
export async function getDataStored({ commit }, payload) {
  try {
    const { data } = await axios.get("/analysis/getDataStored", {
      params: payload,
    });
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}
