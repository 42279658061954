import { Line } from "vue-chartjs";

export default {
  extends: Line,
  data() {
    return {
      gradient: null,
      gradient2: null,
      labels: [],
      values: [],
      data: [],
    };
  },
  props: {
    results: {
      type: Array,
      require: true,
    },
  },
  mounted() {
    this.formatData();
    this.gradient = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);

    this.gradient.addColorStop(0, "rgba(4, 193, 5, 0.5)");
    this.gradient.addColorStop(0.5, "rgba(4, 193, 5, 0.25)");
    this.gradient.addColorStop(1, "rgba(4, 193, 5, 0)");

    this.setChart();
  },
  methods: {
    setChart() {
      this.renderChart(
        {
          labels: this.labels,
          datasets: this.data,
        },
        { responsive: true, maintainAspectRatio: false }
      );
    },
    formatData() {
      this.data = [];
      this.labels = [];
      this.labels = this.results[0].dataChart.data.map((result) => {
        return `${result.time} hrs`;
      });

      this.results.forEach((element) => {
        let color = Math.floor(Math.random() * 16777215).toString(16);
        let ppm = [];
        element.dataChart.data.forEach((element) => {
          ppm.push(element.ppm);
        });
        let obj = {
          label: element.name,
          borderColor: `#${color}`,
          pointBackgroundColor: `#${color}`,
          borderWidth: 2,
          pointBorderColor: `#${color}`,
          backgroundColor: "transparent",
          data: ppm,
        };
        this.data.push(obj);
      });
    },
  },
  watch: {
    results: {
      handler() {
        this.formatData();
        this.setChart();
      },
      deep: true,
    },
  },
};
