import axios from "axios";

export async function getOrganizationsHeadquarters(context, payload) {
  try {
    const { data } = await axios.get(`/distributors/headquarters`);
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function getFarms(context, payload) {
  try {
    const { data } = await axios.get(`/distributors/farms`, {
      params: payload,
    });
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function getDistributorsByPackages(context, payload) {
  try {
    const { data } = await axios.get(`/distributors/get-distributors-by-packages`, {
      params: payload,
    });
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    return e.response.data;
  }
}
