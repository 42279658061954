<template>
  <v-container class="fill-height">
    <v-row v-if="!isAdding">
      <v-col cols="12">
        <v-row>
          <v-col cols="12" xl="5" lg="5" md="5">
            <v-text-field
              outlined
              dense
              prepend-inner-icon="mdi-magnify"
              label="Buscar..."
              clearable
              v-model="search"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" xl="1" lg="2" md="3">
            <v-menu
              offset-y
              :close-on-click="true"
              :close-on-content-click="false"
              v-model="filterMenu"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on" block>
                  <v-icon left>mdi-filter</v-icon>
                  Filtro
                </v-btn>
              </template>
              <v-card width="auto">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          label="Cultivo"
                          hide-details
                          outlined
                          clearable
                          dense
                          :items="crops_items"
                          hide-no-data
                          item-text="name"
                          v-model="filter.crop"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          label="Etapa Fenológica"
                          hide-details
                          outlined
                          clearable
                          dense
                          :items="phenologicalStages"
                          item-text="name"
                          hide-no-data
                          v-model="filter.phenologicalStage"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          :search-input.sync="searchPackage"
                          v-model="filter.package"
                          :items="packages"
                          label="Paquete"
                          hide-no-data
                          outlined
                          dense
                          item-text="name"
                          clearable
                          return-object
                          prepend-inner-icon="mdi-magnify"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="warning" @click="cleanFilter()"
                    >Restaurar</v-btn
                  >
                  <v-btn color="primary" @click="applyFilter()">Aplicar</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
          <v-col cols="12" xl="1" lg="2" md="3">
            <v-btn color="primary" @click="openFormCreate()">
              <v-icon>mdi-plus</v-icon>
              Añadir producto(s)
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-row>
          <v-col cols="12">
            <v-card elevation="3">
              <v-card-text>
                <v-container>
                  <v-data-table
                    :headers="headers"
                    :items="prescriptions"
                    :page.sync="page"
                    :items-per-page="10"
                    :loading="loadTable"
                    hide-default-footer
                    fixed-header
                    height="56vh"
                    no-data-text="No hay niveles de riesgo"
                  >
                    <template #[`item.molecularMarker`]="{ item }">
                      <div v-if="item.molecularMarker.name">
                        <span
                          v-if="item.molecularMarker._id.isPathogen"
                          class="warning--text"
                          >{{ item.molecularMarker.name }}
                        </span>
                        <span v-else class="primary--text"
                          >{{ item.molecularMarker.name }}
                        </span>
                      </div>
                      <div v-else>-</div>
                    </template>
                    <template #[`item.updatedAt`]="{ item }">
                      <span>{{ dateFormat(item.updatedAt) }} </span>
                    </template>
                    <template #[`item.status`]="{ item }">
                      <span> {{ getStatus(item.status) }} </span>
                    </template>
                    <template #[`item.package`]="{ item }">
                      <span>{{ item.package.name }} </span>
                    </template>
                    <template #[`item.crop`]="{ item }">
                      <span>{{ getCrop(item.crop) }} </span>
                    </template>
                    <template #[`item.riskLevelLow`]="{ item }">
                      Bajo =
                      <span>min: {{ item.riskLevelLow.min }} </span>
                      /
                      <span>max: {{ item.riskLevelLow.max }} </span>
                      <v-spacer></v-spacer>
                      Medio =
                      <span>min: {{ item.riskLevelMedium.min }} </span>
                      /
                      <span>max: {{ item.riskLevelMedium.max }} </span>
                      <v-spacer></v-spacer>
                      Alto =
                      <span>min: {{ item.riskLevelHight.min }} </span>
                      /
                      <span>max: {{ item.riskLevelHight.max }} </span>
                    </template>
                    <template #[`item.productName`]="{ item }">
                      <div v-if="item.productName">
                        <span class="primary--text">{{
                          item.productName
                        }}</span>
                        <v-spacer></v-spacer>
                        Bajo: {{ item.dosesLow }}
                        <v-spacer></v-spacer>
                        Medio: {{ item.dosesMedium }}
                        <v-spacer></v-spacer>
                        Alto: {{ item.dosesHight }}
                      </div>

                      <span v-else>-</span>
                    </template>
                    <template #[`item.phenologicalStage`]="{ item }">
                      <span>{{ getPeheno(item.phenologicalStage) }}</span>
                    </template>
                    <template #[`item._id`]="{ item }">
                      <v-btn
                        color="red"
                        dark
                        x-small
                        class="rounded-md mx-1"
                        @click="openDeleteModal(item)"
                        :loading="item.isDeleting"
                      >
                        <v-icon small> mdi-delete </v-icon>
                      </v-btn>
                      <v-btn
                        color="primary"
                        dark
                        x-small
                        class="rounded-md mx-1"
                        @click="openFormUpdate(item)"
                      >
                        <v-icon small> mdi-pencil </v-icon>
                      </v-btn>
                      <v-btn
                        color="blue"
                        dark
                        x-small
                        class="rounded-md mx-1"
                        @click="openTimeLjne(item)"
                      >
                        <v-icon small> mdi-history</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-pagination
              v-model="page"
              :length="numPages"
              total-visible="10"
              color="primary"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <FormNewProduct
          @back="isAdding = false"
          @back_update="fetchData(), (isAdding = false)"
        />
      </v-col>
    </v-row>
    <DeleteCofirmationModal
      v-model="showDeleteCofirmationModal"
      v-if="showDeleteCofirmationModal"
      @confirmation="deleteProduct()"
      :title="deleteModalTitle"
    />
    <TimeLineProduct v-model="showTimeLine" v-if="showTimeLine" />
  </v-container>
</template>

<script>
//Vuex
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
//components
//Libraries
import dayjs from "dayjs";
//modals
import UpdateRiskLevelModal from "@/components/cmda_admin/modals/UpdateRiskLevelModal.vue";
import UpdateProductmodal from "@/components/cmda_admin/modals/UpdateProductmodal.vue";
import LevelRiskTable from "@/components/cmda_admin/LevelRiskTable.vue";
import FormNewProduct from "@/components/cmda_admin/FormNewProduct.vue";
import TimeLineProduct from "@/components/cmda_admin/modals/TimeLineProduct.vue";
import DeleteCofirmationModal from "@/components/utils/GenericConfirmationModal.vue";
export default {
  components: {
    UpdateRiskLevelModal,
    UpdateProductmodal,
    LevelRiskTable,
    FormNewProduct,
    TimeLineProduct,
    DeleteCofirmationModal,
  },
  data: () => ({
    headers: [
      {
        text: "Marcador molecular",
        sortable: false,
        value: "molecularMarker",
      },
      {
        text: "Cultivo",
        sortable: false,
        value: "crop",
      },
      {
        text: "Paquete",
        sortable: false,
        value: "package",
      },
      { text: "Etapa fenologica", value: "phenologicalStage", sortable: false },
      { text: "Origen", value: "status", sortable: false },
      { text: "Producto", value: "productName", sortable: false },
      { text: "Ultima actualización", value: "updatedAt", sortable: false },
      { text: "Acciones", value: "_id", sortable: false },
    ],
    filter: {
      crop: null,
      package: null,
      phenologicalStage: null,
      search: null,
    },
    showUpdateRiskLevelModalBASF: false,
    per_page: 10,
    page: 1,
    numPages: 1,
    loadTable: false,
    prescriptions: [],
    isAdding: false,
    search: null,
    filterMenu: null,
    crops_items: [],
    phenologicalStages: [
      { name: "Trasplante", value: "Transplanting" },
      { name: "Desarrollo Vegetativo", value: "Vegetative growth" },
      { name: "Floración", value: "Bloom" },
      { name: "Cosecha", value: "Harvest" },
      { name: "Siembra", value: "Sowing" },
      { name: "Emergencia", value: "Sprout" },
      { name: "Tuberización", value: "Tuberization" },
      { name: "Llenado de tuberculos", value: "Tuber filling" },
      { name: "Desarrollo foliar", value: "Foliage development" },
      { name: "Expansión foliar", value: "Foliar expansion" },
      { name: "Primera hoja", value: "First leaf" },
      { name: "Tercer hoja", value: "Third leaf" },
      { name: "Quinta hoja", value: "Fifth leaf" },
      { name: "Inicio de bulbificación", value: "Start of bulbing" },
      { name: "Desarrollo del bulbo", value: "Bulb growth" },
      { name: "Maduración de fruto", value: "Fruit maturity" },
      { name: "Brotación", value: "Sprouting" },
      { name: "Presiembra", value: "Pre-planting" },
    ],
    packages: [],
    searchPackage: null,
    showTimeLine: false,
    showDeleteCofirmationModal: null,
    deleteModalTitle: "",
  }),
  computed: {
    ...mapState("reception", ["crops"]),
    ...mapState("prescription", ["prescriptionSelected"]),
  },
  async mounted() {
    this.setCropItems();
    await this.fetchData();
  },
  methods: {
    ...mapActions("laboratory", ["getPackagesByName"]),
    ...mapActions("prescription", ["getPrescriptions", "removeProduct"]),
    ...mapMutations("prescription", ["SET_CURRENT_PRESCRIPTION"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async fetchData() {
      try {
        this.loadTable = true;
        let payload = {
          filter: this.filter,
          per_page: this.per_page,
          page: this.page,
        };
        let response = await this.getPrescriptions(payload);
        if (response.error) throw Error(response.message);
        this.numPages = response.pages;
        this.prescriptions = response.prescriptions;
        console.log(this.prescriptions);
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
      this.loadTable = false;
    },
    dateFormat(value) {
      const date = `${dayjs(value).format("DD/MM/YYYY - HH:mm")} hrs`;
      return date || "--/--/----";
    },
    getPeheno(item) {
      let peheno = {
        Transplanting: "Trasplante",
        "Vegetative growth": "Desarrollo Vegetativo",
        Bloom: "Floración",
        Harvest: "Cosecha",
        Sowing: "Siembra",
        Sprout: "Emergencia",
        Tuberization: "Tuberización",
        "Tuber filling": "Llenado de tuberculos",
        "Foliage development": "Desarrollo foliar",
        "Foliar expansion": "Expansión foliar",
        "First leaf": "Primera hoja",
        "Third leaf": "Tercer hoja ",
        "Fifth leaf": "Quinta hoja",
        "Start of bulbing": "Inicio de bulbificación",
        "Bulb growth": "Desarrollo del bulbo",
        "Fruit maturity": "Maduración de fruto",
        Sprouting: "Brotación",
        "Pre-planting": "Presiembra",
      };
      return peheno[item] ? peheno[item] : item;
    },
    getCrop(value) {
      let template = "";
      const crop = {
        olive: "Aceituna",
        chard: "Acelga",
        achiote: "Achiote",
        agave: "Agave",
        avocado: "Aguacate",
        garlic: "Ajo",
        sesame: "Ajonjolí",
        basil: "Albahaca",
        artichoke: "Alcachofa",
        alcatraz: "Alcatraz",
        shrunkenAlfalfa: "Alfalfa achicalada",
        greenAlfalfa: "Alfalfa verde",
        cotton: "Algodón",
        almond: "Almendro",
        birdseed: "Alpiste",
        amaranth: "Amaranto",
        anise: "Anís",
        celery: "Apio",
        blueberry: "Arándano",
        myrtle: "Arrayán",
        rice: "Arroz",
        greenForageOats: "Avena forrajera en verde",
        oatGrain: "Avena grano",
        bamboo: "Bambú",
        eggplant: "Berenjena",
        beetroot: "Betabel",
        broccoli: "Brocoli",
        peanut: "Cacahuate",
        cocoa: "Cacao",
        cherryCoffee: "Café cereza",
        pumpkin: "Calabaza",
        sweetPotato: "Camote",
        sugarCane: "Caña de azúcar",
        canola: "Canola",
        carambolo: "Carambolo",
        safflower: "Cártamo",
        barley: "Cebada",
        greenForageBarley: "Cebada forrajera en verde",
        onion: "Cebolla",
        chives: "Cebollín",
        ryeGrain: "Centeno grano",
        cherry: "Cereza",
        chabacano: "Chabacano",
        pea: "Chícharo",
        squash: "Chayote",
        chia: "Chía",
        chilacayote: "Chilacayote",
        chillyPepper: "Chile / Pimiento",
        custardApple: "Chirimoya",
        coriander: "Cilantro",
        plum: "Ciruela",
        coconutFruit: "Coco fruta",
        cabbage: "Col (repollo)",
        brusselsSprouts: "Col de Bruselas",
        cauliflower: "Coliflor",
        cumin: "Comino",
        chrysanthemum: "Crisantemo (planta)",
        peach: "Durazno",
        date: "Dátil",
        greenBean: "Ejote",
        cornCob: "Elote",
        dill: "Eneldo",
        epazote: "Epazote",
        asparagus: "Espárrago",
        spicesAndMedicinals: "Especias y medicinales",
        spinach: "Espinaca",
        raspberry: "Frambuesa",
        strawberry: "Fresa",
        bean: "Frijol",
        chickpea: "Garbanzo",
        sunflower: "Girasol",
        pomegranate: "Granada",
        soursop: "Guanábana",
        guava: "Guayaba",
        henequen: "Henequén",
        peppermint: "Hierbabuena",
        aromaticHerbs: "Hierbas aromáticas",
        fig: "Higo",
        jamaica: "Jamaica",
        ginger: "Jenjibre",
        jicama: "Jícama",
        lettuce: "Lechuga",
        lentil: "Lenteja",
        lime: "Lima",
        lemon: "Limón",
        linseed: "Linaza",
        litchi: "Litchi",
        corn: "Maíz",
        macadamia: "Macadamia",
        maguey: "Maguey",
        mamey: "Mamey",
        tangerine: "Mandarina",
        mango: "Mango",
        mangosteen: "Mangostán",
        chamomile: "Manzanilla",
        appleTree: "Manzano",
        passionFruit: "Maracuyá",
        daisyFlower: "Margarita",
        marjoram: "Mejorana",
        melon: "Melón",
        quince: "Membrillo",
        mint: "Menta",
        moringa: "Moringa",
        turnip: "Nabo",
        nanche: "Nanche",
        napa: "Napa",
        orange: "Naranja",
        medlar: "Níspero",
        nochebuena: "Nochebuena (planta)",
        walnut: "Nogal",
        noni: "Noni",
        nopal: "Nopal",
        oregano: "Orégano",
        ornamental: "Ornamentales",
        orchid: "Orquídea (planta)",
        oilPalm: "Palma africana o de aceite",
        potato: "Papa",
        papaya: "Papaya",
        grasslands: "Pastizales",
        matGrass: "Pasto tapete (m2)",
        pasturesAndMeadows: "Pastos y praderas",
        cucumber: "Pepino",
        pearTree: "Peral",
        parsley: "Perejil",
        peron: "Perón",
        persimony: "Persimonio",
        pepper: "Pimienta",
        pineapple: "Piña",
        pinion: "Piñón",
        pistache: "Pistache",
        pitaya: "Pitaya",
        banana: "Plátano",
        pore: "Poro",
        radish: "Rábano",
        rambutan: "Rambután",
        romerito: "Romerito",
        rosemary: "Romero",
        aloe: "Sábila",
        watermelon: "Sandía",
        sorghum: "Sorgo",
        soy: "Soya",
        stevia: "Stevia",
        tobacco: "Tabaco",
        tamarind: "Tamarindo",
        tejocote: "Tejocote",
        tomato: "Tomate Rojo (jitomate)",
        greenTomato: "Tomate Verde",
        thyme: "Tomillo",
        grapefruit: "Toronja (pomelo)",
        clover: "Trébol",
        wheat: "Trigo",
        dutchTulip: "Tulipán holandés",
        tuna: "Tuna",
        grape: "Uva",
        vanilla: "Vainilla",
        purslane: "Verdolaga",
        vine: "Vid",
        cassavaFood: "Yuca alimenticia",
        carrot: "Zanahoria",
        sapodilla: "Zapote",
        blackberry: "Zarzamora",
        zempoalxochitl: "Zempoalxochitl",
        fallow: "Barbecho",
        other: "Otro",
      };
      let cropName = crop[value];
      if (cropName) {
        template = cropName;
      } else {
        template = "Sin cultivo";
      }
      return template;
    },
    getStatus(value) {
      let statusType = {
        TECHNICAL_SHEET: "Ficha téncnica",
        FEEDBACK: "FeedBack",
      };
      return statusType[value] || value;
    },
    async cleanFilter() {
      this.filterMenu = false;
      this.filter.crop = null;
      this.filter.package = null;
      this.filter.phenologicalStage = null;
      await this.fetchData();
    },
    async applyFilter() {
      this.page = 1;
      this.numPages = 1;
      this.filterMenu = false;
      await this.fetchData();
    },
    setCropItems() {
      let cropsArray = Object.entries(this.crops);
      for (let crop of cropsArray) {
        this.crops_items.push({
          name: crop[1],
          value: crop[0],
        });
      }
    },
    openFormUpdate(item) {
      this.SET_CURRENT_PRESCRIPTION(item);
      this.isAdding = true;
    },
    openTimeLjne(item) {
      this.SET_CURRENT_PRESCRIPTION(item);
      this.showTimeLine = true;
    },
    openFormCreate() {
      this.SET_CURRENT_PRESCRIPTION(null);
      this.isAdding = true;
    },
    openDeleteModal(item) {
      this.SET_CURRENT_PRESCRIPTION(item);
      this.deleteModalTitle = `¿Esta seguro que desea eliminar este producto ${item.molecularMarker.name} (${item.productName})?`;
      this.showDeleteCofirmationModal = true;
    },
    async deleteProduct() {
      try {
        this.prescriptionSelected.isDeleting = true;
        let payload = {
          productId: this.prescriptionSelected._id,
        };
        await this.removeProduct(payload);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: `Producto eliminado con exito`,
          timeout: 5000,
        });
        this.SET_CURRENT_PRESCRIPTION(null);
        this.page = 1;
        await this.fetchData();
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
    },
  },
  watch: {
    async page() {
      await this.fetchData();
    },
    async search(value) {
      this.page = 1;
      this.numPages = 1;
      this.filter.search = value;
      await this.fetchData();
    },
    async searchPackage(value) {
      try {
        const req = await this.getPackagesByName({ name: value });

        if (req.error) throw req;

        this.packages = req.packages;
        console.log(req);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
<style scoped></style>
