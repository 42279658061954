<template>
  <v-dialog
    v-model="photoDialog"
    scrollable
    width="1100"
    @click:outside="closeModal"
  >
    <v-card>
      <v-card-title class="headline">Evidencia fotográfica</v-card-title>
      <v-card-text>
        <v-tabs v-model="tabs" color="primary" grow>
          <v-tab>Captura imagen</v-tab>
          <v-tab>Selección imagen</v-tab>
          <!-- <v-tab v-if="photos.length > 0">Fotografías</v-tab> -->
          <v-tab-item>
            <v-row align="center">
              <v-col sm="12" md="6">
                <!-- Cameras available -->
                <v-select
                  v-model="cameraSelected"
                  :items="camerasAvailable"
                  placeholder="Cámaras disponibles"
                ></v-select>
                <!-- Stream -->
                <video
                  id="video-stream"
                  :width="width"
                  :height="height"
                  autoplay
                ></video>
                <!-- Take photo -->
                <div class="text-center">
                  <v-btn @click="takePhoto" class="px-12" color="accent" dark>
                    <v-icon left size="25">mdi-camera-iris</v-icon>
                    Capturar
                  </v-btn>
                </div>
              </v-col>
              <!-- Canvas image -->
              <v-col sm="12" md="6">
                <p>Imagenes {{ imagesCamera.length }}</p>
                <canvas ref="canvas" :width="width" :height="height"></canvas>
                <v-row justify="end" class="text-end" v-if="capture">
                  <v-col>
                    <v-btn
                      color="red"
                      dark
                      class="mx-1"
                      @click="deleteImageCanvas"
                      >Eliminar</v-btn
                    >
                    <v-btn color="primary" class="mx-1" @click="addImage"
                      >Agregar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-container>
              <div class="text-center">
                <v-file-input
                  v-model="files"
                  counter
                  multiple
                  show-size
                  accept="image/png, image/jpeg, image/bmp"
                  truncate-length="50"
                  label="Adjuntar imagen"
                  prepend-icon="mdi-file"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </div>
            </v-container>
          </v-tab-item>
        </v-tabs>
      </v-card-text>

      <!-- Actions -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- Cancel -->
        <v-btn color="grey darken-1" dark depressed @click="closeModal"
          >Cancelar</v-btn
        >
        <!-- Save morphology -->
        <v-btn
          @click="savePhoto"
          :loading="loading"
          color="primary"
          depressed
          :disabled="isDisabled"
          >Guardar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// Vuex
import { mapActions, mapMutations } from "vuex";
// component
export default {
  data() {
    return {
      imagesCamera: [],
      files: [],
      isDisabled: true,
      tabs: [],
      photoDialog: false,
      videoElement: null,
      capture: null,
      cameraSelected: null,
      camerasAvailable: [],
      currentStream: null,
      loading: false,
      // width: 500,
      // height: 400,
    };
  },
  //   props: {
  //     photos : {
  //       type : Array,
  //       require : false,
  //       default : []
  //     }
  //   },
  watch: {
    cameraSelected() {
      this.stopStream();
      this.startStream();
    },
    capture() {
      if (this.files.length > 0 || this.imagesCamera.length > 0) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
    files: {
      handler: function (val) {
        if (val.length > 0 || this.capture != null) {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.photoDialog = true;
    await this.startModal();
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    // Start Modal
   async  startModal() {
     await  this.startStream();
    },
    // Start stream video (Show it on the screen)
    async startStream() {
      let constraints = {
        video: {},
      };
      if (!this.cameraSelected) {
        constraints.video.facingMode = "environment";
      } else {
        constraints.video.deviceId = { exact: this.cameraSelected };
      }
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      this.videoElement = document.getElementById("video-stream");
      this.videoElement.srcObject = stream;
      this.currentStream = stream;
      await this.getCameraDevices();
    },
    // Get camera devices
    async getCameraDevices() {
      const devices = await navigator.mediaDevices.enumerateDevices();
      devices.forEach((device, index) => {
        if (device.kind === "videoinput") {
          const label = device.label || `Camera ${index + 1}`;
          this.camerasAvailable.push({
            value: device.deviceId,
            text: label,
          });
        }
      });
    },
    // Stop stream
    stopStream() {
      const tracks = this.currentStream.getTracks();
      tracks.forEach(function (track) {
        track.stop();
      });
      this.videoElement.srcObject = null;
    },
    // Take photo.
    takePhoto() {
      const canvas = this.$refs.canvas;
      canvas
        .getContext("2d")
        .drawImage(this.videoElement, 0, 0, this.width, this.height);
      this.capture = canvas.toDataURL("image/png");
    },
    deleteImageCanvas() {
      this.capture = null;
      const canvas = this.$refs.canvas;
      canvas.getContext("2d").clearRect(0, 0, this.width, this.height);
    },
    // Convert captured photo to image file.
    async savePhoto() {
      this.loading = true;
      try {
        let filesEmit = [];
        // Build form data.

        for (let item of this.imagesCamera) {
          filesEmit.push({ date: new Date(), img: item });
        }

        for (let item of this.files) {
          let fileB64 = await this.getBase64(item);
          filesEmit.push({ date: new Date(), img: fileB64 });
        }

        this.$emit("files", filesEmit);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: "Foto guardada correctamente",
          timeout: 4000,
        });
        this.photoDialog = false;
        this.stopStream();
      } catch (error) {
        console.log("Error capturing the photo", error);
      }
      this.loading = false;
    },
    closeModal() {
      // If there is stream, stop it.
      if (this.currentStream) {
        this.stopStream();
      }
      this.photoDialog = false;
      this.$emit("close", false);
    },
    addImage() {
      console.log(this.imagesCamera);
      this.imagesCamera.push(this.capture);
      this.deleteImageCanvas();
    },
    async getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
  computed: {
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 300;
        case "sm":
          return 300;
        case "md":
          return 300;
        case "lg":
          return 400;
        case "xl":
          return 400;
      }
    },
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 290;
        case "sm":
          return 400;
        case "md":
          return 400;
        case "lg":
          return 500;
        case "xl":
          return 500;
      }
    },
  },
};
</script>
