<template>
  <v-card tile elevation="0" class="my-auto pb-1">
    <v-container>
      <!-- Photo row -->
      <v-row>
        <v-col cols="12" md="12">
          <template v-if="!urlImage">
            <v-img
              :src="require('../../assets/img/no_image_strain.png')"
              aspect-ratio="1.0"
              contain
            ></v-img>
            <v-row
              v-if="testNutritionDB && testNutritionDB.status == 'INCUBATING'"
              justify="center"
            >
              <v-col cols="8">
                <AddEvidencePhotograph
                  :testMedia="testNutrition.idTest"
                  :testData="testNutritionDB"
                  :testType="testNutrition.analysisType"
                  @imageUpdated="formatData()"
                />
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-img :src="urlImage" aspect-ratio="1.0" contain></v-img>
            <v-row justify="center" align="center">
              <v-col cols="4">
                <EditMiniature
                  :imageUrl="urlOriginalImage"
                  @imageUpdated="formatData()"
                />
              </v-col>
              <v-col
                cols="4"
                v-if="
                  testNutritionDB && testNutritionDB.status == 'IN_PROGRESS'
                "
              >
                <AddEvidencePhotograph
                  :testMedia="testNutrition.idTest"
                  :testData="testNutritionDB"
                  :testType="testNutrition.analysisType"
                  @imageUpdated="formatData()"
                />
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
      <template>
        <!-- Analisis data row -->
        <v-row class="px-10">
          <v-col cols="7">
            <p class="font-weight-bold text-left mb-1">
              Análisis {{ testNutrition.folioAnalysis }}
            </p>
          </v-col>
          <v-col cols="5">
            <!-- <p class="font-weight-bold text-right mb-1">INICIAL MS</p> -->
          </v-col>
        </v-row>
        <!-- Test data headers row -->
        <v-row class="mt-0 pr-2">
          <v-col cols="2" class="pr-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              ID ensayo
            </p>
          </v-col>
          <v-col cols="2" class="px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">Medio</p>
          </v-col>
          <v-col cols="3" class="px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              Inóculo
            </p>
          </v-col>
          <v-col cols="2" class="px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              Caja petri
            </p>
          </v-col>
          <v-col cols="3" class="px-0 pb-0">
            <p class="text-caption text-center mb-0 font-weight-black">
              Tipo análisis
            </p>
          </v-col>
        </v-row>
        <!-- Test data row -->
        <v-row class="mt-0 pr-2">
          <v-col cols="2" class="pr-0">
            <p class="text-caption text-center mb-1">
              {{ testNutrition.idTest }}
            </p>
          </v-col>
          <v-col cols="2" class="px-0">
            <p class="text-caption text-center mb-1">
              {{ testNutrition.medium }}
            </p>
          </v-col>
          <v-col cols="3" class="px-0">
            <p class="text-caption text-center mb-1">
              {{ testNutrition.inoculum }}
            </p>
          </v-col>
          <v-col cols="2" class="px-0">
            <p
              class="text-caption text-center mb-1"
              v-if="testNutrition.containerType == 'petriDish90mmx15mm'"
            >
              90x15 mm
            </p>
            <p class="text-caption text-center mb-1" v-else>60x15 mm</p>
          </v-col>
          <v-col cols="3" class="px-0">
            <p class="text-caption text-center mb-1">
              {{ testNutrition.analysisType }}
            </p>
          </v-col>
        </v-row>
        <!-- Area data row -->
        <v-row class="px-6 mt-0" align="center" justify="center">
          <v-col cols="3">
            <p class="font-weight-bold">Benéfico</p>
          </v-col>
          <v-col cols="4">
            <p class="secondary--text text-caption">
              {{ testNutrition.inoculum }}
            </p>
          </v-col>
          <v-col cols="5" class="pt-6">
            <v-select
              v-model="interpretationTest"
              label="Interpretación"
              :items="items"
              :disabled="disableTest"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>

        <v-row class="mt-0">
          <v-col cols="12">
            <p class="text-center secondary--text font-weight-bold">
              {{ testType }} - {{ interpretationTest }}
            </p>
          </v-col>
        </v-row>
      </template>
      <v-card-actions class="justify-center pt-0">
        <v-row class="pt-0" justify="center" align="center">
          <v-col cols="8">
            <v-btn
              depressed
              block
              color="secondary"
              :disabled="disableTest"
              @click="saveTest"
              :loading="loading"
            >
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import EditMiniature from "./modals/EditMiniature.vue";
import AddEvidencePhotograph from "./modals/AddEvidencePhotograph.vue";

export default {
  components: {
    EditMiniature,
    AddEvidencePhotograph,
  },
  props: {
    testNutrition: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      singleSelect: false,
      items: ["Buena", "Muy buena", "Mala", "Media"],
      interpretationTest: "Buena",
      disableTest: false,
      testType: "",
      loading: false,
      urlImage: "",
      urlOriginalImage: "",
      testNutritionDB: null,
    };
  },
  async created() {
    await this.formatData();
  },
  methods: {
    ...mapActions("nutrition", ["updateNutritionTest", "getNutritionTests"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async saveTest() {
      this.loading = true;
      try {
        let test = {
          _id: this.testNutrition._id,
          solubilization: this.getSolubilization(),
        };

        const response = await this.updateNutritionTest(test);
        if (response.error) {
          throw response;
        }

        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: "Nutrición guardada correctamente",
          timeout: 4000,
        });
      } catch (e) {
        console.log(e);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Error al actualizar nutrición, comunicate a soporte técnico para dar seguimiento.",
          timeout: 4000,
        });
      }

      // this.$emit("testsSaved");
      this.loading = false;
    },
    getSolubilization() {
      let interpretations = [
        { text: "Buena", value: "Good" },
        { text: "Muy buena", value: "Very good" },
        { text: "Mala", value: "Low" },
        { text: "Media", value: "Medium" },
      ];

      let interpretation = interpretations.find(
        (inter) => inter.text == this.interpretationTest
      );

      return interpretation.value;
    },
    getReverseSolubilization(value) {
      let interpretations = [
        { text: "Good", value: "Buena" },
        { text: "Very good", value: "Muy buena" },
        { text: "Low", value: "Mala" },
        { text: "Medium", value: "Media" },
      ];

      let interpretation = interpretations.find((inter) => inter.text == value);

      return interpretation.value;
    },
    async formatData() {
      let nutritionTest = this.testNutrition.testData;

      let request = await this.getNutritionTests(
        nutritionTest.biochemicalTest._id
      );
      let nutritionList = request.NUTRITION_LIST;

      let nutritionTestDB = nutritionList.find(
        (test) => test._id == nutritionTest._id
      );

      console.log("NUTRITION TEST", nutritionTestDB);

      if (nutritionTestDB) {
        this.testNutritionDB = nutritionTestDB;
      }

      if (
        this.testNutritionDB.status == "INOCULATED" ||
        this.testNutritionDB.status == "INCUBATING" ||
        this.testNutritionDB.status == "COMPLETED"
      ) {
        this.disableTest = true;
      } else {
        this.disableTest = false;
      }

      this.interpretationTest = this.testNutritionDB.solubilization
        ? this.getReverseSolubilization(this.testNutritionDB.solubilization)
        : "Buena";

      this.urlImage =
        this.testNutritionDB.incubation &&
        this.testNutritionDB.incubation.morphologyPhoto
          ? this.testNutritionDB.incubation.morphologyPhoto
          : "";

      if (this.urlImage) {
        this.urlOriginalImage = this.urlImage;
  if (!this.urlImage.includes("-small")) {
          this.urlImage =
            this.urlImage.slice(0, this.urlImage.length - 4) +
            "-small.jpg?" +
            Date.now();
        } else {
          this.urlImage = `${this.urlImage}?${Date.now()}`;
          this.urlOriginalImage = `${this.urlImage.split("-small")[0]}${
            this.urlImage.split("-small")[1]
          }`;
        }
      }

      this.testType =
        this.testNutrition.analysisType == "S. Fósforo"
          ? "Solubilización de fósforo"
          : "Solubilización de potasio";
    },
  },
  watch: {
    async testNutrition() {
      await this.formatData();
    },
  },
};
</script>
