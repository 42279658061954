export function SET_BCTEST_LIST(state, biochemicalTests) {
  state.biochemicalTestList = biochemicalTests;
}
export function SET_BCTEST_SELECTED(state, data) {
  state.biochemicalTestSelected = data;
}
export function SET_ITEM_SELECTED(state, data) {
  state.itemSelected = data;
}
export function ADD_TO_LIST(state, bcTest) {
  state.biochemicalTestList.push(bcTest);
}
export function SET_BCTEST_TYPE(state, bcTestType) {
  state.bcTestType = bcTestType;
}

export function SET_COUNT(state, count) {
  state.count = count;
}