<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title class="background-green">
                Configuración pruebas
              </v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-form ref="form" lazy-validation>
                    <v-row class="py-0 my-0">
                      <v-col cols="6" class="py-0 my-0">
                        <v-file-input
                          outlined
                          label="Adjuntar archivo..."
                          prepend-inner-icon="mdi-file-excel"
                          truncate-length="50"
                          show-size
                          :prepend-icon="null"
                          accept=".csv"
                          v-model="file"
                          dense
                          :disabled="isRunningTest"
                          :rules="validate('archivo')"
                        >
                        </v-file-input>
                      </v-col>
                      <v-col cols="6" class="py-0 my-0">
                        <v-text-field
                          type="number"
                          outlined
                          dense
                          label="Número de test por registro"
                          v-model="runTimes"
                          :rules="validate('número de test')"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="py-0 my-0">
                        <v-range-slider
                          v-model="efficiencyRange"
                          :min="0"
                          :max="1"
                          :step="0.1"
                          thumb-label
                          :thumb-label-format="formatThumbLabel"
                          label="Rango eficiencia"
                        ></v-range-slider>
                      </v-col>
                      <v-col cols="12" class="py-0 my-0">
                        <v-range-slider
                          v-model="cqRange"
                          :min="0"
                          :max="100"
                          :step="1"
                          thumb-label
                          label="Rango CQ"
                        ></v-range-slider>
                      </v-col>
                    </v-row>
                    <v-row class="py-0 my-0">
                      <v-col class="py-0 my-0">
                        <v-switch
                          label="Añadir prescripción"
                          color="primary"
                          dense
                          v-model="prescription"
                          :disabled="isRunningTest"
                        ></v-switch>
                      </v-col>
                      <v-col v-if="prescription" cols="6" class="py-0 my-0">
                        <v-autocomplete
                          outlined
                          label="Selecciona paquete"
                          prepend-inner-icon="mdi-package"
                          dense
                          v-model="packageFound"
                          :search-input.sync="searchPackage"
                          :items="packages"
                          hide-no-data
                          item-text="name"
                          clearable
                          return-object
                          :disabled="isRunningTest"
                          :rules="validate('paquete')"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title class="background-green">
                Resultados sumatoria
              </v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="6">
                          <span class="font-weight-bold"
                            >No detectados:
                            <span class="red--text">{{
                              getCountNotDetected()
                            }}</span>
                          </span>
                          <v-spacer></v-spacer>
                          <span class="font-weight-bold"
                            >Detectados:
                            <span class="primary--text">{{
                              getCountDetected()
                            }}</span>
                          </span>
                        </v-col>
                        <v-col cols="6">
                          <v-btn
                            color="gray"
                            block
                            dark
                            @click="processFile()"
                            :disabled="isRunningTest"
                          >
                            <v-icon>mdi-play</v-icon>
                            Iniciar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <v-btn
                        color="blue"
                        block
                        dark
                        :disabled="isRunningTest || rawData.length == 0"
                        @click="openCompareModalTest()"
                      >
                        <v-icon>mdi-file-compare</v-icon>
                        Comparar
                      </v-btn>
                    </v-col>
                    <v-col cols="12">
                      <v-btn
                        color="primary"
                        block
                        :disabled="isRunningTest || rawData.length == 0"
                        :loading="isExporting"
                        @click="exportToCSV"
                      >
                        <v-icon>mdi-file-export</v-icon>
                        Exportar
                      </v-btn>
                    </v-col>
                    <v-col cols="12">
                      <v-btn
                        color="warning"
                        block
                        :disabled="isRunningTest"
                        @click="restartTest"
                      >
                        <v-icon>mdi-restart</v-icon>
                        Reiniciar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="8">
        <v-card>
          <v-card-title class="background-green"> Resultados </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" v-if="rawData.length != 0">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-data-table
                    :headers="headers"
                    :items="rawData"
                    :search="search"
                    class="overflow-y-auto"
                    height="400px"
                    fixed-header
                    v-if="rawData.length != 0"
                  >
                    <template #[`item.mo`]="{ item }">
                      <span>
                        {{ item.mo }} ({{ Number(item.cqMin.toFixed(2)) }},
                        {{ Number(item.cqMax.toFixed(2)) }})
                      </span>
                    </template>
                    <template #[`item.riskLevelMedium`]="{ item }">
                      <span>
                        {{ Number(item.riskLevelMedium.min.toFixed(2)) }},
                        {{ Number(item.riskLevelMedium.max.toFixed(2)) }}
                      </span>
                    </template>
                    <template #[`item.family`]="{ item }">
                      <div v-if="item.family.includes('aplica')">
                        <span> Otra </span>
                      </div>
                      <div v-else>
                        <span>
                          {{ item.family }}
                        </span>
                      </div>
                    </template>
                    <template #[`item.level`]="{ item }">
                      <span>
                        {{ getLevel(item.level) }}
                      </span>
                    </template>
                    <template #[`item.products`]="{ item }">
                      <div
                        v-for="(product, index) in item.products"
                        :key="index"
                      >
                        <div v-if="product != null">
                          <span>
                            {{ product }}
                          </span>
                          <v-spacer></v-spacer>
                        </div>
                      </div>
                    </template>
                    <template #[`item.isDetected`]="{ item }">
                      <div v-if="item.isDetected">
                        <span class="primary--text"> Detectado </span>
                      </div>
                      <div v-else>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on" class="red--text"
                              >No detectado</span
                            >
                          </template>
                          <div
                            v-for="(error, index) in item.errors"
                            :key="index"
                          >
                            <span></span>
                            <v-spacer>
                              {{ error }}
                            </v-spacer>
                          </div>
                        </v-tooltip>
                      </div>
                    </template>
                  </v-data-table>
                  <v-simple-table
                    class="overflow-y-auto"
                    height="400px"
                    fixed-header
                    v-else
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Marcador</th>
                          <th class="text-left">Nivel rango</th>
                          <th class="text-left">Familia</th>
                          <th class="text-left">Eficiencia</th>
                          <th class="text-left">CQ</th>
                          <th class="text-left">UMC</th>
                          <th class="text-left">Nivel</th>
                          <th class="text-left">Productos</th>
                          <th class="text-left">Estatus</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td colspan="9">
                            <v-container>
                              <v-row class="text-center">
                                <v-col cols="12" v-if="isRunningTest">
                                  <v-progress-linear
                                    indeterminate
                                    color="green"
                                  ></v-progress-linear>
                                </v-col>
                                <v-col v-else>
                                  <span class="font-italic">
                                    No hay datos
                                  </span>
                                </v-col>
                              </v-row>
                            </v-container>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <CompareRawDataTestModal
      v-model="showCompareRawDataTestModal"
      v-if="showCompareRawDataTestModal"
    />
  </v-container>
</template>

<script>
//Vuex
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
// validations
import { requiredValidation } from "@/helpers/validations.js";
//Libraries
import dayjs from "dayjs";

import CompareRawDataTestModal from "@/components/cmda_admin/modals/CompareRawDataTestModal.vue";
export default {
  components: {
    CompareRawDataTestModal,
  },
  data: () => ({
    file: null,
    prescription: false,
    search: "",
    isRunningTest: false,
    packageFound: null,
    searchPackage: null,
    packages: [],
    rawData: [],
    testData:[],
    runTimes: 1,
    efficiencyRange: [0, 1], // Initial value for slider
    cqRange: [0, 40],
    isExporting: false,
    search: null,
    headers: [
      {
        text: "Marcador",
        sortable: true,
        value: "mo",
      },
      {
        text: "Nivel rango",
        sortable: true,
        value: "riskLevelMedium",
      },
      {
        text: "Familia",
        sortable: true,
        value: "family",
      },
      {
        text: "Eficiencia",
        sortable: true,
        value: "efficiency",
      },
      {
        text: "CQ",
        sortable: true,
        value: "cq",
      },
      {
        text: "UMC",
        sortable: true,
        value: "umc",
      },
      {
        text: "Nivel",
        sortable: true,
        value: "level",
      },
      {
        text: "Productos",
        sortable: true,
        value: "products",
      },
      {
        text: "Estatus",
        sortable: true,
        value: "isDetected",
      },
    ],
    showCompareRawDataTestModal: false,
  }),
  methods: {
    ...mapActions("laboratory", ["runTestData", "getPackagesByName"]),
    ...mapMutations("laboratory", ["SET_TEST_DATA","SET_RAW_DATA"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    validate(name) {
      return requiredValidation(name);
    },
    async processFile() {
      try {
        if (this.$refs.form.validate()) {
          if (this.file) {
            this.isRunningTest = true;
            var formData = new FormData();
            formData.append("file", this.file);
            if (this.packageFound) {
              formData.append("package", this.packageFound._id);
            }
            formData.append("runTimes", this.runTimes);
            formData.append("efficiencyRange", this.efficiencyRange);
            formData.append("cqRange", this.cqRange);
            let response = await this.runTestData(formData);
            this.rawData = response.rawData;
            this.testData = response.testData;
            console.log(response);
          } else {
            this.rawData = [];
          }
        }
      } catch (error) {
        this.rawData = [];
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
      this.isRunningTest = false;
    },
    getCountDetected() {
      return this.rawData.filter((element) => element.isDetected).length;
    },
    getCountNotDetected() {
      return this.rawData.filter((element) => !element.isDetected).length;
    },
    getLevel(level) {
      let levesl = {
        low: "bajo",
        medium: "medio",
        high: "alto",
      };

      return levesl[level];
    },
    formatThumbLabel(val) {
      return val.toFixed(1);
    },
    restartTest() {
      this.rawData = [];
      this.prescription = false;
      this.efficiencyRange = [0, 1];
      this.cqRange = [0, 40];
      this.file = null;
      this.runTimes = 1;
      this.$refs.form.resetValidation();
    },
    exportToCSV() {
      try {
        this.isExporting = true;
        let dataToExport = [];
        for (let item of this.rawData) {
          let productText = "";
          for (let index = 0; index < item.products.length; index++) {
            if (index + 1 == item.products.length) {
              productText += item.products[index];
            } else {
              productText += `${item.products[index]},`;
            }
          }
          dataToExport.push({
            name: item.mo,
            cq_min: item.cqMin,
            cq_max: item.cqMax,
            level_medium: item.riskLevelMedium.min,
            level_hight: item.riskLevelMedium.max,
            family: item.family,
            efficiency: item.efficiency,
            cq: item.cq,
            umc: item.umc,
            level: item.level,
            products: productText,
            isDetected: item.isDetected,
          });
        }
        var headers = {
          name: "name".replace(/,/g, ""),
          cq_min: "cq_min".replace(/,/g, ""),
          cq_max: "cq_max".replace(/,/g, ""),
          level_medium: "level_medium".replace(/,/g, ""),
          level_hight: "level_hight".replace(/,/g, ""),
          family: "family".replace(/,/g, ""),
          efficiency: "efficiency".replace(/,/g, ""),
          cq: "cq".replace(/,/g, ""),
          umc: "umc".replace(/,/g, ""),
          level: "level".replace(/,/g, ""),
          products: "products".replace(/,/g, ""),
          isDetected: "isDetected".replace(/,/g, ""),
        };

        const date = `${dayjs().format("YYYY-MM-DDTHH:mm:ssZ[Z]")}`;
        var fileTitle = `data-test-export-${date}`; // or 'my-unique-title'

        this.exportCSVFile(headers, dataToExport, fileTitle);
        this.isExporting = false;
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
    },
    exportCSVFile(headers, items, fileTitle) {
      if (headers) {
        items.unshift(headers);
      }

      // Convert Object to JSON
      var jsonObject = JSON.stringify(items);

      var csv = this.convertToCSV(jsonObject);

      var exportedFilenmae = fileTitle + ".csv" || "export.csv";

      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    convertToCSV(objArray) {
      var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      var str = "";

      for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
          if (line != "") line += ",";

          line += array[i][index];
        }

        str += line + "\r\n";
      }

      return str;
    },
    openCompareModalTest() {
      this.SET_TEST_DATA(this.testData);
      this.SET_RAW_DATA(this.rawData);
      console.log("");
      this.showCompareRawDataTestModal = true;
    },
  },
  watch: {
    async searchPackage(value) {
      try {
        const req = await this.getPackagesByName({ name: value });

        if (req.error) throw req;

        this.packages = req.packages;
      } catch (error) {
        console.error(error);
      }
    },
    prescription(value) {
      if (!value) {
        this.packageFound = null;
        this.searchPackage = null;
        this.packages = [];
      }
    },
  },
};
</script>

<style scoped>
.background-green {
  background-color: rgba(4, 193, 5, 0.1);
}
</style>
