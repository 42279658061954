<template>
  <v-dialog v-model="show" width="50vw" scrollable>
    <v-card height="auto" elevation="5" class="rounded-lg" min-height="200">
      <v-card-title class="text-h5 lighten-2"> </v-card-title>
      <v-card-text>
        <v-container>
          <div v-if="stored.length != 0">
            <v-row>
              <v-col>
                <span class="font-weight-bold text-subtitle-1">
                  Muestras resguardadas
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn color="primary" @click="storeSamplesNotAvailables">
                  <v-icon> mdi-collapse-all </v-icon>
                  Dar de baja
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="stored"
                  :items-per-page="5"
                  no-data-text="No hay muestras por resguardar"
                  class="elevation-1"
                  show-expand
                  :expanded.sync="expanded"
                  :single-expand="true"
                  dense
                  item-key="_id"
                >
                  <template #[`item.storedObservations`]="{ item }">
                    <v-container>
                      <v-row class="justify-center align-center">
                        <v-col class="justify-center align-center">
                          <v-text-field
                            v-model="item.storedObservations"
                            outlined
                            dense
                            clearable
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                  <template #expanded-item="{ headers, item }">
                    <td class="px-0 py-2" :colspan="headers.length">
                      <template>
                        <v-data-table
                          v-model="selectedChild"
                          :headers="headersChild"
                          :items="item.receptions"
                          item-key="qr_container"
                          hide-default-footer
                          elevation="0"
                          show-select
                        >
                          <template #[`item.folio_sample`]="{ item }">
                            <span class="primary--text">{{
                              item.folio_sample
                            }}</span>
                          </template>
                          <template #[`item.sample_type`]="{ item }">
                            {{ getAnalysisType(item.tracingStatus) }}
                            {{ getSampleType(item.sample_type) }}
                          </template>
                          <template #[`item.crop`]="{ item }">
                            {{ getCrop(item.crop) }}
                          </template>
                          <template
                            #[`item.date_field_registration`]="{ item }"
                          >
                            {{ dateFormat(item.date_field_registration) }}
                          </template>
                          <template #[`item.last_update`]="{ item }">
                            {{ lastUpdate(item.last_update) }}
                          </template>
                          <template #[`item.analysis_stage`]="{ item }">
                            <v-chip color="#dadada">
                              {{ getStatus(item.analysis_stage) }}
                            </v-chip>
                          </template>

                          <template #[`item.irrigation_unit_name`]="{ item }">
                            <span
                              v-if="item.farm_unit_id"
                              class="primary--text"
                            >
                              {{ item.irrigation_unit_name }}
                            </span>
                            <span v-else>
                              {{ item.irrigation_unit_name }}
                            </span>
                          </template>
                        </v-data-table>
                      </template>
                    </td>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
          <div v-if="toBeStore.length != 0">
            <v-row>
              <v-col>
                <span class="font-weight-bold text-subtitle-1">
                  Muestras a resguardar
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn color="primary" @click="storeSamplesRequest">
                  <v-icon> mdi-expand-all </v-icon>
                  Resguardar todas
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="toBeStore"
                  :items-per-page="5"
                  no-data-text="No hay muestras por resguardar"
                  class="elevation-1"
                >
                </v-data-table>
              </v-col>
            </v-row>
          </div>
          <div v-if="stored.length == 0 && toBeStore.length == 0">
            <span class="font-weght-bold text-subtitle-1 font-italic">
              No se pueden resguardar/dar de baja folio(s)
            </span>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col>
              <v-btn color="gray" block @click="show = false" dark
                >Cerrar</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//Vuex
import { mapMutations, mapState, mapActions } from "vuex";
//Libraries
import dayjs from "dayjs";
export default {
  data: () => ({
    isLoading: false,
    stored: [],
    toBeStore: [],
    headers: [
      { text: "Folio", value: "folio", sortable: false },

      {
        text: "Muestras",
        value: "qty_samples",
        align: "center",
        sortable: false,
      },
      {
        text: "Obervaciones",
        value: "storedObservations",
        align: "center",
        sortable: false,
      },
    ],
    headersChild: [
      {
        text: "Folio muestra",
        value: "folio_sample",
        align: "center",
        sortable: false,
      },
      { text: "QR contenedor", value: "qr_container", sortable: false },
      { text: "Tipo de muestra", value: "sample_type", sortable: false },
      { text: "# Submuestras", value: "sub_samples", sortable: false },
      {
        text: "Nombre de la unidad de riego",
        value: "irrigation_unit_name",
        sortable: false,
      },
      { text: "Cultivo", value: "crop", sortable: false },
      { text: "Responsable", value: "sampler_name", sortable: false },
      {
        text: "Registro en campo",
        value: "date_field_registration",
        sortable: false,
      },
      { text: "Última modificacción", value: "last_update", sortable: false },
      { text: "Etapa de análisis", value: "analysis_stage", sortable: false },
    ],
    expanded: [],
    selectedChild: [],
  }),
  props: {
    value: Boolean,
  },
  computed: {
    ...mapState("reception", ["samplesToBeStored"]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("analysis", ["storeSamples", "storedSampleNotAvailables"]),
    async storeSamplesRequest() {
      try {
        let ids = this.toBeStore.map((element) => {
          return element._id;
        });
        let payload = {
          analysis: ids,
        };
        let response = await this.storeSamples(payload);
        if (!response) throw response;
        this.toBeStore = [];
        this.$emit("fetchData");
        this.show = false;
        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: "Muestras resguardadas",
          timeout: 4000,
        });
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Error al resguardar todas",
          timeout: 4000,
        });
      }
    },
    async storeSamplesNotAvailables() {
      try {
        let ids = this.stored.map((element) => {
          return {
            id: element._id,
            storedObservations: element.storedObservations,
          };
        });
        let foliosSamples = this.selectedChild.map((element) => {
          return element.folio_sample;
        });
        let payload = {
          analysis: ids,
          foliosSamples,
        };
        let response = await this.storedSampleNotAvailables(payload);
        if (!response) throw response;
        this.stored = [];
        this.$emit("fetchData");
        this.show = false;
        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: "Muestras resguardadas dadas de baja",
          timeout: 4000,
        });
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Error al resguardar todas",
          timeout: 4000,
        });
      }
    },
    getAnalysisType(value) {
      if (value == "INITIAL" || value == "Initial") {
        return "Inicial";
      } else {
        return "Seguimiento";
      }
    },
    getSampleType(value) {
      const types = {
        CLASSIC: "Suelo compuesto",
        MAS: "Muestra para genómica",
        MAS_VEGETABLE: "Vegetal",
        VEGETABLE: "Vegetal sin georeferencia",
        COMPOST: "Composta",
        PRODUCT: "Producto",
        SUBSTRATUM: "Sustrato",
        NO_ADVISOR: "Suelo sin asesor",
      };
      if (value.type) {
        let temp = types[value.type];
        if (value.type == "COMPOST" || value.type == "PRODUCT") {
          if (value.composition) {
            let compositions = {
              LIQUID: "Líquido",
              SOLID: "Sólido",
            };
            temp += ` ${compositions[value.composition]}`;
          }
        } else if (value.type == "MAS_VEGETABLE" || value.type == "VEGETABLE") {
          if (value.vegetableType) {
            let vegetableTypes = {
              Seedling: "Plántula",
              Root: "Raíz",
              Seed: "Semilla",
              Stem: "Tallo",
              Leaf: "Hoja",
              Fruit: "Fruto",
              Flower: "Flor",
            };
            if (vegetableTypes[value.vegetableType]) {
              temp += ` ${vegetableTypes[value.vegetableType]}`;
            }
          }
        }
        return temp;
      } else {
        return "N/A";
      }
    },
    getCrop(value) {
      let template = "";
      const crop = {
        olive: "Aceituna",
        chard: "Acelga",
        achiote: "Achiote",
        agave: "Agave",
        avocado: "Aguacate",
        garlic: "Ajo",
        sesame: "Ajonjolí",
        basil: "Albahaca",
        artichoke: "Alcachofa",
        alcatraz: "Alcatraz",
        shrunkenAlfalfa: "Alfalfa achicalada",
        greenAlfalfa: "Alfalfa verde",
        cotton: "Algodón",
        almond: "Almendro",
        birdseed: "Alpiste",
        amaranth: "Amaranto",
        anise: "Anís",
        celery: "Apio",
        blueberry: "Arándano",
        myrtle: "Arrayán",
        rice: "Arroz",
        greenForageOats: "Avena forrajera en verde",
        oatGrain: "Avena grano",
        bamboo: "Bambú",
        eggplant: "Berenjena",
        beetroot: "Betabel",
        broccoli: "Brocoli",
        peanut: "Cacahuate",
        cocoa: "Cacao",
        cherryCoffee: "Café cereza",
        pumpkin: "Calabaza",
        sweetPotato: "Camote",
        sugarCane: "Caña de azúcar",
        canola: "Canola",
        carambolo: "Carambolo",
        safflower: "Cártamo",
        barley: "Cebada",
        greenForageBarley: "Cebada forrajera en verde",
        onion: "Cebolla",
        chives: "Cebollín",
        ryeGrain: "Centeno grano",
        cherry: "Cereza",
        chabacano: "Chabacano",
        pea: "Chícharo",
        squash: "Chayote",
        chia: "Chía",
        chilacayote: "Chilacayote",
        chillyPepper: "Chile / Pimiento",
        custardApple: "Chirimoya",
        coriander: "Cilantro",
        plum: "Ciruela",
        coconutFruit: "Coco fruta",
        cabbage: "Col (repollo)",
        brusselsSprouts: "Col de Bruselas",
        cauliflower: "Coliflor",
        cumin: "Comino",
        chrysanthemum: "Crisantemo (planta)",
        peach: "Durazno",
        date: "Dátil",
        greenBean: "Ejote",
        cornCob: "Elote",
        dill: "Eneldo",
        epazote: "Epazote",
        asparagus: "Espárrago",
        spicesAndMedicinals: "Especias y medicinales",
        spinach: "Espinaca",
        raspberry: "Frambuesa",
        strawberry: "Fresa",
        bean: "Frijol",
        chickpea: "Garbanzo",
        sunflower: "Girasol",
        pomegranate: "Granada",
        soursop: "Guanábana",
        guava: "Guayaba",
        henequen: "Henequén",
        peppermint: "Hierbabuena",
        aromaticHerbs: "Hierbas aromáticas",
        fig: "Higo",
        jamaica: "Jamaica",
        ginger: "Jenjibre",
        jicama: "Jícama",
        lettuce: "Lechuga",
        lentil: "Lenteja",
        lime: "Lima",
        lemon: "Limón",
        linseed: "Linaza",
        litchi: "Litchi",
        corn: "Maíz",
        macadamia: "Macadamia",
        maguey: "Maguey",
        mamey: "Mamey",
        tangerine: "Mandarina",
        mango: "Mango",
        mangosteen: "Mangostán",
        chamomile: "Manzanilla",
        appleTree: "Manzano",
        passionFruit: "Maracuyá",
        daisyFlower: "Margarita",
        marjoram: "Mejorana",
        melon: "Melón",
        quince: "Membrillo",
        mint: "Menta",
        moringa: "Moringa",
        turnip: "Nabo",
        nanche: "Nanche",
        napa: "Napa",
        orange: "Naranja",
        medlar: "Níspero",
        nochebuena: "Nochebuena (planta)",
        walnut: "Nogal",
        noni: "Noni",
        nopal: "Nopal",
        oregano: "Orégano",
        ornamental: "Ornamentales",
        orchid: "Orquídea (planta)",
        oilPalm: "Palma africana o de aceite",
        potato: "Papa",
        papaya: "Papaya",
        grasslands: "Pastizales",
        matGrass: "Pasto tapete (m2)",
        pasturesAndMeadows: "Pastos y praderas",
        cucumber: "Pepino",
        pearTree: "Peral",
        parsley: "Perejil",
        peron: "Perón",
        persimony: "Persimonio",
        pepper: "Pimienta",
        pineapple: "Piña",
        pinion: "Piñón",
        pistache: "Pistache",
        pitaya: "Pitaya",
        banana: "Plátano",
        pore: "Poro",
        radish: "Rábano",
        rambutan: "Rambután",
        romerito: "Romerito",
        rosemary: "Romero",
        aloe: "Sábila",
        watermelon: "Sandía",
        sorghum: "Sorgo",
        soy: "Soya",
        stevia: "Stevia",
        tobacco: "Tabaco",
        tamarind: "Tamarindo",
        tejocote: "Tejocote",
        tomato: "Tomate Rojo (jitomate)",
        greenTomato: "Tomate Verde",
        thyme: "Tomillo",
        grapefruit: "Toronja (pomelo)",
        clover: "Trébol",
        wheat: "Trigo",
        dutchTulip: "Tulipán holandés",
        tuna: "Tuna",
        grape: "Uva",
        vanilla: "Vainilla",
        purslane: "Verdolaga",
        vine: "Vid",
        cassavaFood: "Yuca alimenticia",
        carrot: "Zanahoria",
        sapodilla: "Zapote",
        blackberry: "Zarzamora",
        zempoalxochitl: "Zempoalxochitl",
        fallow: "Barbecho",
        other: "Otro",
      };
      let cropName = crop[value];
      if (cropName) {
        template = cropName;
      } else {
        template = "Sin cultivo";
      }
      return template;
    },
    dateFormat(value) {
      if (value) {
        const date = `${dayjs(value).format("DD-MM-YYYY - HH:mm")} hrs`;
        return date ? date : "N/A";
      } else {
        return "N/A";
      }
    },
    lastUpdate(date) {
      if (date) {
        let now = new Date();
        let update = new Date(date);
        const diffTime = Math.abs(update - now);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        var cd = 24 * 60 * 60 * 1000,
          ch = 60 * 60 * 1000,
          d = Math.floor(diffTime / cd),
          h = Math.floor((diffTime - d * cd) / ch),
          m = Math.round((diffTime - d * cd - h * ch) / 60000),
          pad = function (n) {
            return n < 10 ? "0" + n : n;
          };
        if (m === 60) {
          h++;
          m = 0;
        }
        if (h === 24) {
          d++;
          h = 0;
        }
        let template = "Hace ";
        let day = d;
        let houres = pad(h);
        let minutes = pad(m);
        let nothing = 0;
        if (day > 0) {
          if (day <= 1) {
            template += `${day} día `;
          } else {
            template += `${day} días `;
          }
        } else {
          nothing++;
        }

        if (houres > 0) {
          if (houres <= 1) {
            template += `${houres} hora `;
          } else {
            template += `${houres} horas `;
          }
        } else {
          nothing++;
        }

        if (minutes > 0) {
          if (minutes <= 1) {
            template += `${minutes} minuto`;
          } else {
            template += `${minutes} minutos`;
          }
        } else {
          nothing++;
        }

        if (nothing++ == 3) {
          template += `unos segundos`;
        }

        return template;
      } else {
        return "N/A";
      }
    },
    getStatus(value) {
      const status = {
        RECEIVED: "Recibida",
        MEASURING: "Midiendo",
        MEASURED: "Medido",
        VALUATING: "Valuando",
        VALUATED: "Valuado",
      };
      let newValue = status[value];
      return newValue ? newValue : "Esperando...";
    },
  },
  async mounted() {
    // console.log(this.samplesToBeStored);
    this.stored = this.samplesToBeStored.filter(
      (element) => element.isStored && element.storedAvailable
    );
    console.log(this.stored);
    for (let item of this.stored) {
      for (let reception of item.receptions) {
        let exist = item.samplesRemoved.find(
          (element) => element == reception.folio_sample
        );
        if (exist) {
          this.selectedChild.push(reception);
        }
      }
    }
    this.toBeStore = this.samplesToBeStored.filter(
      (element) => !element.isStored
    );
  },
};
</script>
