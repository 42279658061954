<template>
  <v-container
    @mousemove="handleMouseMove"
    @mouseup="stopAction"
    class="image-tagger-container"
  >
    <v-row v-if="!isUpdatingText && isEditable">
      <v-col>
        <v-btn color="primary" @click="addTag">Agregar Etiqueta</v-btn>
      </v-col>
      <v-col>
        <v-btn color="primary" @click="sendTags">Guardar etiquetas</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="isUpdatingText">
      <v-col>
        <div>
          <v-row no-gutters class="mt-3">
            <v-col cols="12">
              <v-text-field
                v-model="editedText"
                dense
                outlined
                label="Nombre etiqueta"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-row>
                <v-col>
                  <v-btn color="primary" small @click="saveEdit()" block>
                    <v-icon> mdi-content-save </v-icon>
                    Guardar
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn color="red" small dark @click="cancelEdit()" block>
                    <v-icon> mdi-close </v-icon>
                    Cancelar
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div @mousedown="handleMouseDown">
          <div class="image-container">
            <img
              :src="imageUrl"
              alt="Imagen"
              ref="image"
              draggable="false"
              class="no-select image"
            />
            <div
              v-for="(tag, index) in tags"
              :key="index"
              class="tag"
              :style="{
                left: tag.left + 'px',
                top: tag.top + 'px',
                width: tag.width + 'px',
                height: tag.height + 'px',
              }"
            >
              <div v-if="isEditable">
                <v-container>
                  <v-row class="text-center">
                    <v-col>
                      <v-icon
                        color="black"
                        small
                        class="resize-handle"
                        @mousedown="startResizing(index, $event)"
                      >
                        mdi-resize-bottom-right
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
              <div v-if="isEditable">
                <v-container>
                  <v-row class="text-center">
                    <v-col>
                      <v-icon
                        color="black"
                        small
                        class="remove-handle"
                        @click="removeTag(index)"
                      >
                        mdi-close
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
              <div v-if="isEditable">
                <v-container>
                  <v-row class="text-center">
                    <v-col>
                      <v-icon
                        color="black"
                        small
                        class="drag-handle"
                        @mousedown="startDragging(index, $event)"
                      >
                        mdi-drag
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
              <div class="edit-handle" v-if="isEditable">
                <v-container>
                  <v-row class="text-center">
                    <v-col class="pa-0">
                      <v-icon
                        color="black"
                        x-small
                        @click="toggleEditMode(index)"
                      >
                        mdi-pencil
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
              <v-row>
                <v-col>
                  <span class="white--text tag-text">
                    {{ tag.text }}
                  </span>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//Vuex
import { mapMutations } from "vuex";
export default {
  props: {
    imageUrl: String,
    isEditable: Boolean,
    incomingTags: Array,
  },
  data() {
    return {
      tags: [],
      isDragging: false,
      isResizing: false,
      activeTagIndex: null,
      mouseX: 0,
      mouseY: 0,
      startLeft: 0,
      startTop: 0,
      startWidth: 0,
      startHeight: 0,
      editedText: "",
      isUpdatingText: false,
    };
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    handleMouseDown(event) {
      if (this.isUpdatingText) return;
      if (
        !event.target.classList.contains("tag") &&
        !event.target.classList.contains("tag-text")
      )
        return;
      const isTagText = event.target.classList.contains("tag-text");
      if (isTagText && !event.target.classList.contains("edit-input")) return;
      this.isDragging = true;
      this.activeTagIndex = this.tags.findIndex(
        (tag) =>
          tag &&
          tag.left <= event.clientX &&
          event.clientX <= tag.left + tag.width &&
          tag.top <= event.clientY &&
          event.clientY <= tag.top + tag.height
      );
      this.mouseX = event.clientX;
      this.mouseY = event.clientY;
      if (this.activeTagIndex !== -1) {
        this.startLeft = this.tags[this.activeTagIndex].left;
        this.startTop = this.tags[this.activeTagIndex].top;
        this.startWidth = this.tags[this.activeTagIndex].width;
        this.startHeight = this.tags[this.activeTagIndex].height;
      }
    },
    handleMouseMove(event) {
      if (this.isUpdatingText) return;
      if (this.isDragging) {
        const deltaX = event.clientX - this.mouseX;
        const deltaY = event.clientY - this.mouseY;
        const tag = this.tags[this.activeTagIndex];

        if (tag) {
          // Actualiza visualmente la posición del tag
          tag.left = this.startLeft + deltaX;
          tag.top = this.startTop + deltaY;

          // Actualiza los valores en el arreglo this.tags
          this.$set(tag, "left", tag.left);
          this.$set(tag, "top", tag.top);
        }
      } else if (this.isResizing) {
        const deltaX = event.clientX - this.mouseX;
        const deltaY = event.clientY - this.mouseY;
        const tag = this.tags[this.activeTagIndex];

        if (tag) {
          // Actualiza visualmente el tamaño del tag
          tag.width = Math.max(10, this.startWidth + deltaX);
          tag.height = Math.max(10, this.startHeight + deltaY);

          // Actualiza los valores en el arreglo this.tags
          this.$set(tag, "width", tag.width);
          this.$set(tag, "height", tag.height);
        }
      }
    },
    startAction(index, event) {
      if (this.isUpdatingText) return;
      const tag = this.tags[index];
      if (event.target.classList.contains("resize-handle")) {
        this.isResizing = true;
      } else {
        this.isDragging = true;
        this.activeTagIndex = index;
        this.mouseX = event.clientX;
        this.mouseY = event.clientY;
        this.startLeft = tag.left;
        this.startTop = tag.top;
        this.startWidth = tag.width;
        this.startHeight = tag.height;
      }
    },
    startResizing(index, event) {
      if (!event.target.classList.contains("resize-handle")) return;
      if (this.isUpdatingText) return;
      this.isResizing = true;
      this.activeTagIndex = index;
      this.mouseX = event.clientX;
      this.mouseY = event.clientY;
      this.startWidth = this.tags[index].width;
      this.startHeight = this.tags[index].height;
    },
    startDragging(index, event) {
      if (!event.target.classList.contains("drag-handle")) return;
      if (this.isUpdatingText) return;
      this.isDragging = true;
      this.activeTagIndex = index;
      this.mouseX = event.clientX;
      this.mouseY = event.clientY;
      this.startLeft = this.tags[index].left;
      this.startTop = this.tags[index].top;
    },
    stopAction() {
      this.isDragging = false;
      this.isResizing = false;
    },
    saveEdit() {
      this.tags[this.activeTagIndex].text = this.editedText;
      this.activeTagIndex = null;
      this.isUpdatingText = false;
    },
    cancelEdit() {
      this.activeTagIndex = null;
      this.editedText = "";
      this.isUpdatingText = false;
    },
    addTag() {
      const rect = this.$refs.image.getBoundingClientRect();
      const randomColor = `rgba(${Math.floor(
        Math.random() * 256
      )}, ${Math.floor(Math.random() * 256)}, ${Math.floor(
        Math.random() * 256
      )}, 0.2)`;

      this.tags.push({
        left: rect.width / 2,
        top: rect.height / 2,
        width: 200,
        height: 100,
        color: randomColor,
        text: "Descripción..",
      });
    },
    removeTag(index) {
      if (this.isUpdatingText) return;
      this.tags.splice(index, 1);
      this.activeTagIndex = null;
    },
    toggleEditMode(index) {
      this.activeTagIndex = this.activeTagIndex === index ? null : index;
      if (this.activeTagIndex != null) {
        this.isUpdatingText = true;
        this.editedText = this.tags[index].text;
      } else {
        this.isUpdatingText = false;
        this.editedText = "";
      }
    },
    print() {
      console.log(this.tags);
    },
    sendTags() {
      if (this.tags.length > 0) {
        this.$emit("tags", this.tags);
      } else {
        this.SET_ALERT_TYPE({
          visible: true,
          type: "info",
          text: "No hay etiquetas",
          timeout: 4000,
        });
      }
    },
    setTags() {
      if (this.incomingTags) {
        this.tags = this.incomingTags;
      }
    },
  },
  created() {
    this.setTags();
  },
  watch: {
    incomingTags(value) {
      if (value) {
        this.tags = value;
      }
    },
  },
};
</script>

<style scoped>
.image-tagger-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; /* Ocupar todo el alto disponible */
}

.image-container {
  position: relative; /* Importante: para que los tags se posicionen dentro de la imagen */
  width: 100%;
  height: 100%;
}

.tag {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #04c105;
  overflow: hidden;
}

.tag-text {
  background-color: rgba(4, 193, 5, 0.5);
  font-weight: bolder;
}

.resize-handle,
.remove-handle,
.drag-handle,
.edit-button {
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #04c105;
  cursor: pointer;
  z-index: 2;
}

.resize-handle {
  bottom: 0;
  right: 0;
  cursor: nwse-resize;
  color: black;
}

.remove-handle {
  top: 0;
  right: 0;
  cursor: pointer;
  color: black;
}

.drag-handle {
  top: 0;
  left: 0;
  cursor: move;
}

.edit-button {
  bottom: -20px;
  left: 0;
  cursor: pointer;
}

.edit-input {
  position: absolute;
  bottom: -40px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.edit-handle {
  position: absolute;
  bottom: -5px;
  left: 0;
  cursor: pointer;
  background-color: #04c105;
  z-index: 2;
}

.no-select {
  user-select: none;
}

.image {
  max-width: 600px;
  max-height: 600px;
  object-fit: contain; /* Ajustar la imagen proporcionalmente al contenedor */
}
</style>
