<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="650"
    v-model="dialogOpened"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn depressed block color="secondary" v-bind="attrs" v-on="on">
        Pruebas bioquímicas
      </v-btn>
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar color="secondary" dark>Pruebas bioquímicas</v-toolbar>
        <v-card-text>
          <v-container>
            <v-row align="center" justify="center" class="mt-0">
              <v-radio-group v-model="gram" row class="mt-0">
                <v-col cols="3" class="py-0">Gram</v-col>
                <v-col cols="3" class="py-0"
                  ><v-radio
                    class="my-0 py-0"
                    label="Sin prueba"
                    :value="null"
                    color="default"
                  ></v-radio
                ></v-col>
                <v-col cols="3" class="py-0"
                  ><v-radio
                    class="my-0 py-0"
                    label="Negativo"
                    :value="false"
                    color="warning"
                  ></v-radio
                ></v-col>
                <v-col cols="3" class="py-0"
                  ><v-radio
                    class="my-0 py-0"
                    label="Positivo"
                    :value="true"
                    color="primary"
                  ></v-radio
                ></v-col>
              </v-radio-group>
            </v-row>
            <v-row align="center" justify="center" class="mt-0">
              <v-radio-group v-model="KOH" row>
                <v-col cols="3" class="py-0">KOH</v-col>
                <v-col cols="3" class="py-0"
                  ><v-radio
                    class="my-0 py-0"
                    label="Sin prueba"
                    :value="null"
                    color="default"
                  ></v-radio
                ></v-col>
                <v-col cols="3" class="py-0"
                  ><v-radio
                    class="my-0 py-0"
                    label="Negativo"
                    :value="false"
                    color="warning"
                  ></v-radio
                ></v-col>
                <v-col cols="3" class="py-0"
                  ><v-radio
                    class="my-0 py-0"
                    label="Positivo"
                    :value="true"
                    color="primary"
                  ></v-radio
                ></v-col>
              </v-radio-group>
            </v-row>
            <v-row align="center" justify="center" class="mt-0">
              <v-radio-group v-model="catalase" row>
                <v-col cols="3" class="py-0">Catalasa</v-col>
                <v-col cols="3" class="py-0"
                  ><v-radio
                    class="my-0 py-0"
                    label="Sin prueba"
                    :value="null"
                    color="default"
                  ></v-radio
                ></v-col>
                <v-col cols="3" class="py-0"
                  ><v-radio
                    class="my-0 py-0"
                    label="Negativo"
                    :value="false"
                    color="warning"
                  ></v-radio
                ></v-col>
                <v-col cols="3" class="py-0"
                  ><v-radio
                    class="my-0 py-0"
                    label="Positivo"
                    :value="true"
                    color="primary"
                  ></v-radio
                ></v-col>
              </v-radio-group>
            </v-row>
            <v-row align="center" justify="center" class="mt-0">
              <v-radio-group v-model="oxidase" row>
                <v-col cols="3" class="py-0">Oxidasa</v-col>
                <v-col cols="3" class="py-0"
                  ><v-radio
                    class="my-0 py-0"
                    label="Sin prueba"
                    :value="null"
                    color="default"
                  ></v-radio
                ></v-col>
                <v-col cols="3" class="py-0"
                  ><v-radio
                    class="my-0 py-0"
                    label="Negativo"
                    :value="false"
                    color="warning"
                  ></v-radio
                ></v-col>
                <v-col cols="3" class="py-0"
                  ><v-radio
                    class="my-0 py-0"
                    label="Positivo"
                    :value="true"
                    color="primary"
                  ></v-radio
                ></v-col>
              </v-radio-group>
            </v-row>
            <v-row align="center" justify="center" class="mt-0">
              <v-radio-group v-model="simmonsCitrate" row>
                <v-col cols="3" class="py-0">CDS</v-col>
                <v-col cols="3" class="py-0"
                  ><v-radio
                    class="my-0 py-0"
                    label="Sin prueba"
                    :value="null"
                    color="default"
                  ></v-radio
                ></v-col>
                <v-col cols="3" class="py-0"
                  ><v-radio
                    class="my-0 py-0"
                    label="Negativo"
                    :value="false"
                    color="warning"
                  ></v-radio
                ></v-col>
                <v-col cols="3" class="py-0"
                  ><v-radio
                    class="my-0 py-0"
                    label="Positivo"
                    :value="true"
                    color="primary"
                  ></v-radio
                ></v-col>
              </v-radio-group>
            </v-row>
            <v-row align="center" justify="center" class="mt-0">
              <v-radio-group v-model="MIO" row>
                <v-col cols="3" class="py-0">MIO</v-col>
                <v-col cols="3" class="py-0"
                  ><v-radio
                    class="my-0 py-0"
                    label="Sin prueba"
                    :value="null"
                    color="default"
                  ></v-radio
                ></v-col>
                <v-col cols="3" class="py-0"
                  ><v-radio
                    class="my-0 py-0"
                    label="Negativo"
                    :value="false"
                    color="warning"
                  ></v-radio
                ></v-col>
                <v-col cols="3" class="py-0"
                  ><v-radio
                    class="my-0 py-0"
                    label="Positivo"
                    :value="true"
                    color="primary"
                  ></v-radio
                ></v-col>
              </v-radio-group>
            </v-row>
            <v-row align="center" justify="center" class="mt-0">
              <v-radio-group v-model="LIA" row>
                <v-col cols="3" class="py-0">LIA</v-col>
                <v-col cols="3" class="py-0"
                  ><v-radio
                    class="my-0 py-0"
                    label="Sin prueba"
                    :value="null"
                    color="default"
                  ></v-radio
                ></v-col>
                <v-col cols="3" class="py-0"
                  ><v-radio
                    class="my-0 py-0"
                    label="Negativo"
                    :value="false"
                    color="warning"
                  ></v-radio
                ></v-col>
                <v-col cols="3" class="py-0"
                  ><v-radio
                    class="my-0 py-0"
                    label="Positivo"
                    :value="true"
                    color="primary"
                  ></v-radio
                ></v-col>
              </v-radio-group>
            </v-row>
            <v-row align="center" justify="center" class="mt-0">
              <v-radio-group v-model="TSI" row>
                <v-col cols="3" class="py-0">TSI</v-col>
                <v-col cols="3" class="py-0"
                  ><v-radio
                    class="my-0 py-0"
                    label="Sin prueba"
                    :value="null"
                    color="default"
                  ></v-radio
                ></v-col>
                <v-col cols="3" class="py-0"
                  ><v-radio
                    class="my-0 py-0"
                    label="Negativo"
                    :value="false"
                    color="warning"
                  ></v-radio
                ></v-col>
                <v-col cols="3" class="py-0"
                  ><v-radio
                    class="my-0 py-0"
                    label="Positivo"
                    :value="true"
                    color="primary"
                  ></v-radio
                ></v-col>
              </v-radio-group>
            </v-row>
            <v-row align="center" justify="center" class="mt-0">
              <v-radio-group v-model="UDC" row>
                <v-col cols="3" class="py-0">UDC</v-col>
                <v-col cols="3" class="py-0"
                  ><v-radio
                    class="my-0 py-0"
                    label="Sin prueba"
                    :value="null"
                    color="default"
                  ></v-radio
                ></v-col>
                <v-col cols="3" class="py-0"
                  ><v-radio
                    class="my-0 py-0"
                    label="Negativo"
                    :value="false"
                    color="warning"
                  ></v-radio
                ></v-col>
                <v-col cols="3" class="py-0"
                  ><v-radio
                    class="my-0 py-0"
                    label="Positivo"
                    :value="true"
                    color="primary"
                  ></v-radio
                ></v-col>
              </v-radio-group>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialog.value = false" class="warning"
            >Cancelar</v-btn
          >
          <v-btn text @click="saveBCTests" class="primary"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogOpened: false,
      gram: null,
      KOH: null,
      catalase: null,
      oxidase: null,
      simmonsCitrate: null,
      MIO: null,
      LIA: null,
      TSI: null,
      UDC: null,
    };
  },
  props: {
    morphology: {
      type: Object,
      require: true,
    },
  },
  watch: {
    dialogOpened() {
      if (this.dialogOpened) {
        this.gram = this.morphology.gram;
        this.KOH = this.morphology.KOH;
        this.catalase = this.morphology.catalase;
        this.oxidase = this.morphology.oxidase;
        this.simmonsCitrate = this.morphology.simmonsCitrate;
        this.MIO = this.morphology.MIO;
        this.LIA = this.morphology.LIA;
        this.TSI = this.morphology.TSI;
        this.UDC = this.morphology.UDC;
      }
    },
  },
  methods: {
    saveBCTests() {
      let test = {
        gram: this.gram,
        KOH: this.KOH,
        catalase: this.catalase,
        oxidase: this.oxidase,
        simmonsCitrate: this.simmonsCitrate,
        MIO: this.MIO,
        LIA: this.LIA,
        TSI: this.TSI,
        UDC: this.UDC,
      };

      this.$emit("aceptClicked", test);
      this.dialogOpened = false;
    },
  },
};
</script>
