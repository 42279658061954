<template>
  <v-container class="fill-height">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h5>Consola de administración CMDA</h5>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="fill-height">
      <v-col>
        <v-card rounded="lg" elevation="5" class="fill-height">
          <v-card-text class="ma-0 pa-0">
            <v-container fluid>
              <v-row>
                <v-col :cols="mini ? '1' : '3'">
                  <v-row>
                    <v-col>
                      <v-navigation-drawer
                        v-model="drawer"
                        :mini-variant.sync="mini"
                        permanent
                        width="auto"
                      >
                        <v-list-item>
                          <v-list-item-icon
                            @click.stop="mini = !mini"
                            class="pointer-on-hover"
                          >
                            <v-icon>mdi-menu</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title class="text-h6">
                              <span v-if="selectedItem == 0">
                                Gestion CMDA
                              </span>
                              <span v-else-if="selectedItem == 1">
                                Marcadores
                              </span>
                              <span v-else-if="selectedItem == 2">
                                Niveles
                              </span>
                              <span v-else-if="selectedItem == 3"> Test </span>
                              <span v-else-if="selectedItem == 4">
                                Productos (Prescripción)
                              </span>
                              <span v-else-if="selectedItem == 5">
                                Identificaciones
                              </span>
                              <span v-else> No tienes permisos </span>
                            </v-list-item-title>
                            <v-list-item-subtitle> Menú </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider></v-divider>

                        <v-list-item-group dense nav>
                          <v-list-item
                            :class="{ highlighted: selectedItem === 0 }"
                            @click="selectItem(0)"
                            v-if="
                              permissions.find(
                                (per) => per == 'console_cmda_management'
                              )
                            "
                          >
                            <v-list-item-icon>
                              <v-icon
                                :class="selectedItem === 0 ? 'custom-icon' : ''"
                                >mdi-cog</v-icon
                              >
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title
                                >Gestion CMDA</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            :class="{ highlighted: selectedItem === 1 }"
                            @click="selectItem(1)"
                            v-if="
                              permissions.find(
                                (per) => per == 'console_cmda_molecular_markers'
                              )
                            "
                          >
                            <v-list-item-icon>
                              <v-icon
                                :class="selectedItem === 1 ? 'custom-icon' : ''"
                                >mdi-sign-text</v-icon
                              >
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Marcadores</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            :class="{ highlighted: selectedItem === 2 }"
                            @click="selectItem(2)"
                            v-if="
                              permissions.find(
                                (per) => per == 'console_cmda_risk_levels'
                              )
                            "
                          >
                            <v-list-item-icon>
                              <v-icon
                                :class="selectedItem === 2 ? 'custom-icon' : ''"
                                >mdi-chart-timeline-variant</v-icon
                              >
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Niveles</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            :class="{ highlighted: selectedItem === 3 }"
                            @click="selectItem(3)"
                            v-if="
                              permissions.find(
                                (per) => per == 'console_cmda_test'
                              )
                            "
                          >
                            <v-list-item-icon>
                              <v-icon
                                :class="selectedItem === 3 ? 'custom-icon' : ''"
                                >mdi-auto-fix</v-icon
                              >
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Test</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            :class="{ highlighted: selectedItem === 4 }"
                            @click="selectItem(4)"
                            v-if="
                              permissions.find(
                                (per) => per == 'console_cmda_prescriptions'
                              )
                            "
                          >
                            <v-list-item-icon>
                              <v-icon
                                :class="selectedItem === 4 ? 'custom-icon' : ''"
                                >mdi-basket-outline</v-icon
                              >
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title
                                >Productos (Prescripción)</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            :class="{ highlighted: selectedItem === 5 }"
                            @click="selectItem(5)"
                            v-if="
                              permissions.find(
                                (per) => per == 'console_cmda_identifications'
                              )
                            "
                          >
                            <v-list-item-icon>
                              <v-icon
                                :class="selectedItem === 5 ? 'custom-icon' : ''"
                                >mdi-bacteria</v-icon
                              >
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title
                                >Identificaciones</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-navigation-drawer>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col :cols="mini ? '11' : '9'">
                  <div v-if="selectedItem == 0">
                    <CMDAPanel />
                  </div>
                  <div v-else-if="selectedItem == 1">
                    <MolecularMarkerTable />
                  </div>
                  <div v-else-if="selectedItem == 2">
                    <LevelRiskTable />
                  </div>
                  <div v-if="selectedItem == 3">
                    <ResultTest />
                  </div>
                  <div v-else-if="selectedItem == 4">
                    <ProductsTable />
                  </div>
                  <div v-else-if="selectedItem == 5">
                    <IdentificationsTable />
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//Vuex
import { mapState } from "vuex";
//components
import LevelRiskTable from "@/components/cmda_admin/LevelRiskTable.vue";
import ResultTest from "@/components/cmda_admin/ResultTest.vue";
import ProductsTable from "@/components/cmda_admin/ProductsTable.vue";
import CMDAPanel from "@/components/cmda_admin/CMDAPanel.vue";
import MolecularMarkerTable from "@/components/cmda_admin/MolecularMarkerTable.vue";
import IdentificationsTable from "@/components/cmda_admin/IdentificationsTable.vue";
export default {
  components: {
    LevelRiskTable,
    ResultTest,
    ProductsTable,
    CMDAPanel,
    MolecularMarkerTable,
    IdentificationsTable,
  },
  computed: {
    ...mapState("session", ["permissions"]),
  },
  data: () => ({
    selectedItem: 999,
    drawer: true,
    mini: false,
  }),
  methods: {
    selectItem(index) {
      this.selectedItem = index;
      // Aquí puedes realizar cualquier otra acción necesaria cuando se selecciona un elemento
    },
  },
  watch: {
    permissions() {
      if (this.selectedItem == 999) {
        if (this.permissions.find((per) => per == "console_cmda_management")) {
          this.selectedItem = 0;
        } else if (
          this.permissions.find(
            (per) => per == "console_cmda_molecular_markers"
          )
        ) {
          this.selectedItem = 1;
        } else if (
          this.permissions.find((per) => per == "console_cmda_risk_levels")
        ) {
          this.selectedItem = 2;
        } else if (this.permissions.find((per) => per == "console_cmda_test")) {
          this.selectedItem = 3;
        } else if (
          this.permissions.find((per) => per == "console_cmda_prescriptions")
        ) {
          this.selectedItem = 4;
        }
      }
    },
  },
  mounted() {
    if (this.selectedItem == 999) {
      if (this.permissions.find((per) => per == "console_cmda_management")) {
        this.selectedItem = 0;
      } else if (
        this.permissions.find((per) => per == "console_cmda_molecular_markers")
      ) {
        this.selectedItem = 1;
      } else if (
        this.permissions.find((per) => per == "console_cmda_risk_levels")
      ) {
        this.selectedItem = 2;
      } else if (this.permissions.find((per) => per == "console_cmda_test")) {
        this.selectedItem = 3;
      } else if (
        this.permissions.find((per) => per == "console_cmda_prescriptions")
      ) {
        this.selectedItem = 4;
      }
    }
  },
};
</script>
<style scoped>
.highlighted {
  background-color: rgba(4, 193, 5, 0.1);
}

.custom-icon {
  background: linear-gradient(45deg, #ff8f1c, #04c105); /* Degradado de color */
  -webkit-background-clip: text; /* Clip para que el degradado se aplique al texto */
  -webkit-text-fill-color: transparent; /* Oculta el color del texto */
}

.pointer-on-hover:hover {
  cursor: pointer;
}
</style>
