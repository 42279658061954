<template>
  <v-dialog v-model="dialog" max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" depressed dark block color="primary">
        Nuevo Reporte
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-center headline">
        <v-row>
          <v-col> Nuevo Reporte </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row justify="center">
            <v-col cols="8">
              <v-text-field
                v-model="newReportName"
                name="name"
                label="Nombre"
                hint="Nombre para generar el reporte"
                id="id"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="newReportDescription"
                outlined
                name="description"
                label="Descripción"
                hint="Descripción corta del reporte"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <!-- Actions -->
      <v-card-actions>
        <v-spacer />
        <v-btn @click="closeDialog" depressed dark color="gray">
          Cancelar
        </v-btn>
        <v-btn
          :loading="loading"
          depressed
          color="primary"
          @click="createReport"
        >
          Crear
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  name: "NewReportModal",
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      loading: false,
      dialog: false,
      newReportName: "",
      newReportDescription: "",
    };
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("sequencingReport", ["newReport"]),
    closeDialog() {
      this.dialog = false;
    },
    async createReport() {
      this.loading = true;
      try {
        if (this.newReportName != "" && this.newReportDescription != "") {
          await this.createNewReport();

          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: `Reporte creado correctamente`,
            timeout: 2000,
          });
        } else {
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: `Debes completar el campo de nombre y descripción`,
            timeout: 2000,
          });
        }
      } catch (error) {
        console.error(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Error al crear el reporte. Error: ${error.message}`,
          timeout: 2000,
        });
      }

      this.loading = false;
    },
    async createNewReport() {
      try {
        const payload = {
          user: this.user._id,
          laboratory: this.user.laboratory._id,
          name: this.newReportName,
          description: this.newReportDescription,
        };

        const request = await this.newReport(payload);

        this.$router.push(`/sequencing/${request.report._id}`);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
</style>