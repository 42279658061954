var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"flex d-flex flex-column",attrs:{"elevation":"5"}},[_c('v-card-text',[_c('v-list',{staticClass:"overflow-y-auto",attrs:{"height":"900"}},[_vm._l((_vm.months_data),function(month,indexM){return [_c('v-list-item',{key:indexM},[_c('v-list-item-content',[_c('v-row',{staticClass:"justify-center"},[_c('span',{staticClass:"primary--text font-weight-medium"},[_vm._v("MES "+_vm._s(indexM + 1))])]),_vm._l((4),function(indexW){return _c('v-row',{key:indexW,staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"12","xl":"3","lg":"3","md":"3"}},[_c('span',{staticClass:"primary--text text-caption font-weight-bold"},[_vm._v("Semana "+_vm._s(indexW))])]),(_vm.monthsData[indexM][indexW - 1].isNohdoses)?_c('v-col',{attrs:{"cols":"12","xl":"4","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('v-combobox',{attrs:{"items":_vm.options,"label":"Motivo"},model:{value:(_vm.monthsData[indexM][indexW - 1].subject),callback:function ($$v) {_vm.$set(_vm.monthsData[indexM][indexW - 1], "subject", $$v)},expression:"monthsData[indexM][indexW - 1].subject"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","xl":"3","lg":"3","md":"3"}},[(
                    _vm.profit_version &&
                    (_vm.profit_version.status === 'NEW' ||
                      _vm.profit_version.status === 'PROPOSAL' ||
                      _vm.profit_version.status === 'REJECTED' ||
                      _vm.profit_version.status === 'STRAINS_SUGGESTION')
                  )?_c('v-switch',{staticClass:"text-caption",attrs:{"dense":""},on:{"click":function($event){return _vm.checkInput(indexM, indexW - 1)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"input__label text-caption"},[_vm._v("Semana sin dosis")])]},proxy:true}],null,true),model:{value:(_vm.monthsData[indexM][indexW - 1].isNohdoses),callback:function ($$v) {_vm.$set(_vm.monthsData[indexM][indexW - 1], "isNohdoses", $$v)},expression:"monthsData[indexM][indexW - 1].isNohdoses"}}):_vm._e()],1),(
                  _vm.monthsData.length > 0 &&
                  !_vm.monthsData[indexM][indexW - 1].isNohdoses
                )?_c('v-col',[(
                    _vm.profit_version &&
                    (_vm.profit_version.status === 'NEW' ||
                      _vm.profit_version.status === 'PROPOSAL' ||
                      _vm.profit_version.status === 'REJECTED' ||
                      _vm.profit_version.status === 'STRAINS_SUGGESTION')
                  )?_c('v-switch',{attrs:{"label":"Semana doble","small":""},on:{"click":function($event){return _vm.checkDoubleInput(indexM, indexW - 1)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"input__label text-caption"},[_vm._v("Semana doble")])]},proxy:true}],null,true),model:{value:(_vm.monthsData[indexM][indexW - 1].doubleWeekDoses),callback:function ($$v) {_vm.$set(_vm.monthsData[indexM][indexW - 1], "doubleWeekDoses", $$v)},expression:"monthsData[indexM][indexW - 1].doubleWeekDoses"}}):_vm._e()],1):_vm._e(),(
                  _vm.monthsData.length > 0 &&
                  !_vm.monthsData[indexM][indexW - 1].isNohdoses
                )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',[(
                        _vm.profit_version &&
                        (_vm.profit_version.status === 'NEW' ||
                          _vm.profit_version.status === 'PROPOSAL' ||
                          _vm.profit_version.status === 'REJECTED' ||
                          _vm.profit_version.status === 'STRAINS_SUGGESTION')
                      )?_c('v-btn',{attrs:{"small":"","dense":"","block":"","color":"primary"},on:{"click":function($event){return _vm.addAplication1(indexM, indexW - 1)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Añadir día 1")],1):_vm._e()],1),_c('v-col',[(
                        _vm.profit_version &&
                        (_vm.profit_version.status === 'NEW' ||
                          _vm.profit_version.status === 'PROPOSAL' ||
                          _vm.profit_version.status === 'REJECTED' ||
                          _vm.profit_version.status === 'STRAINS_SUGGESTION') &&
                        _vm.monthsData[indexM][indexW - 1].doubleWeekDoses
                      )?_c('v-btn',{attrs:{"small":"","dense":"","block":"","color":"primary"},on:{"click":function($event){return _vm.addAplication2(indexM, indexW - 1)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Añadir día 2")],1):_vm._e()],1)],1),(_vm.monthsData[indexM][indexW - 1].doses[1])?_c('v-row',[_c('v-col',[_c('span',[_vm._v("Día 1")])])],1):_vm._e(),_vm._l((_vm.monthsData[indexM][indexW - 1]
                    .doses[0]),function(prod,indexProd){return _c('v-row',{key:indexProd},[_c('v-col',{attrs:{"cols":"12","xl":"7","lg":"7","md":"9","sm":"7","xs":"5"}},[_c('v-combobox',{attrs:{"items":_vm.strains_selected,"hide-details":"","dense":"","clearable":"","disabled":_vm.profit_version &&
                        _vm.profit_version.status !== 'NEW' &&
                        _vm.profit_version.status !== 'PROPOSAL' &&
                        _vm.profit_version.status !== 'REJECTED' &&
                        _vm.profit_version.status !== 'STRAINS_SUGGESTION',"outlined":"","item-text":"nameFull"},model:{value:(prod.strain),callback:function ($$v) {_vm.$set(prod, "strain", $$v)},expression:"prod.strain"}})],1),_c('v-col',{attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"3","xs":"3"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","outlined":"","disabled":_vm.profit_version &&
                        _vm.profit_version.status !== 'NEW' &&
                        _vm.profit_version.status !== 'PROPOSAL' &&
                        _vm.profit_version.status !== 'REJECTED' &&
                        _vm.profit_version.status !== 'STRAINS_SUGGESTION',"type":"number"},model:{value:(prod.dose),callback:function ($$v) {_vm.$set(prod, "dose", $$v)},expression:"prod.dose"}})],1),_c('v-col',{attrs:{"cols":"3","xl":"2","lg":"2","md":"3","sm":"2","xs":"2"}},[(
                        _vm.profit_version &&
                        (_vm.profit_version.status === 'NEW' ||
                          _vm.profit_version.status === 'PROPOSAL' ||
                          _vm.profit_version.status === 'REJECTED' ||
                          _vm.profit_version.status === 'STRAINS_SUGGESTION')
                      )?_c('v-btn',{attrs:{"color":"red","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.removeAplication(indexM, indexW - 1, 0, indexProd)}}},[_c('v-icon',[_vm._v("mdi-delete-forever")])],1):_vm._e()],1)],1)}),(_vm.monthsData[indexM][indexW - 1].doses[1])?_c('div',{staticClass:"mt-4"},[_c('v-row',[_c('v-col',[_c('span',[_vm._v(" Día 2")])])],1),_vm._l((_vm.monthsData[indexM][indexW - 1]
                      .doses[1]),function(prod,indexProd){return _c('v-row',{key:indexProd},[_c('v-col',{attrs:{"cols":"12","xl":"7","lg":"7","md":"9","sm":"7","xs":"5"}},[_c('v-combobox',{attrs:{"items":_vm.strains_selected,"hide-details":"","dense":"","clearable":"","disabled":_vm.profit_version &&
                          _vm.profit_version.status !== 'NEW' &&
                          _vm.profit_version.status !== 'PROPOSAL' &&
                          _vm.profit_version.status !== 'REJECTED' &&
                          _vm.profit_version.status !== 'STRAINS_SUGGESTION',"outlined":"","item-text":"nameFull"},model:{value:(prod.strain),callback:function ($$v) {_vm.$set(prod, "strain", $$v)},expression:"prod.strain"}})],1),_c('v-col',{attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"3","xs":"3"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","outlined":"","disabled":_vm.profit_version &&
                          _vm.profit_version.status !== 'NEW' &&
                          _vm.profit_version.status !== 'PROPOSAL' &&
                          _vm.profit_version.status !== 'REJECTED' &&
                          _vm.profit_version.status !== 'STRAINS_SUGGESTION',"type":"number"},model:{value:(prod.dose),callback:function ($$v) {_vm.$set(prod, "dose", $$v)},expression:"prod.dose"}})],1),_c('v-col',{attrs:{"cols":"3","xl":"2","lg":"2","md":"3","sm":"2","xs":"2"}},[(
                          _vm.profit_version &&
                          (_vm.profit_version.status === 'NEW' ||
                            _vm.profit_version.status === 'PROPOSAL' ||
                            _vm.profit_version.status === 'REJECTED' ||
                            _vm.profit_version.status !== 'STRAINS_SUGGESTION')
                        )?_c('v-btn',{attrs:{"color":"red","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.removeAplication(indexM, indexW - 1, 1, indexProd)}}},[_c('v-icon',[_vm._v("mdi-delete-forever")])],1):_vm._e()],1)],1)})],2):_vm._e()],2):_vm._e()],1)}),_c('v-row',{staticClass:"justify-center my-1"},[_c('span',{staticClass:"gray--text"},[_vm._v(" Análisis de seguimiento "+_vm._s(indexM + 1)+" ")])]),_c('v-divider',{staticClass:"mx-4 my-2",attrs:{"inset":""}})],2)],1)]}),(_vm.months_data.length == 0)?_c('div',_vm._l((7),function(item){return _c('div',{key:item},[_c('v-skeleton-loader',{staticClass:"ma-7",attrs:{"type":"card"}})],1)}),0):_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }