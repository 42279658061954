import axios from "axios";

/**
 * Function to create a new nutrition test
 * @param {Object} context - Vuex context.
 * @param {Object} payload - Nutrition test info.
 */
export async function addNutritionTest(context, payload) {
  try {
    const { data } = await axios.post("/nutritionTest/add", payload);
    if (data.error) {
      throw data;
    }
    // Fetch nutrition list.
    const response = await context.dispatch(
      "getNutritionTests",
      data.BIOCHEMICAL_TEST._id
    );
    if (response.error) {
      throw response;
    }
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get all nutrition tests by biochemical test id.
 * @param {Object} context - Vuex context.
 * @param {Object} payload - Biochemical test id.
 */
export async function getNutritionTests(context, payload) {
  try {
    const { data } = await axios.get(`/nutritionTest`, {
      params: {
        biochemicalTestId: payload,
      },
    });
    if (data.error) {
      throw data;
    }
    context.commit("SET_NUTRITION_TEST_LIST", data.NUTRITION_LIST);
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to update a nutrition test.
 * @param {Object} context - Vuex context.
 * @param {Object} payload - nutrition test new info.
 */
export async function updateNutritionTest(context, payload) {
  try {
    const { data } = await axios.put(
      `/nutritionTest/update/${payload._id}`,
      payload
    );
    if (data.error) {
      throw data;
    }
    // Fetch nutrition list.
    const response = await context.dispatch(
      "getNutritionTests",
      data.UPDATED_NUTRITION_TEST.biochemicalTest
    );
    if (response.error) {
      throw response;
    }
    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to upload the nutrition photo to AWS S3.
 * @param {Object} { commit } - vuex function
 * @param {Image} image - Mother box antogonism photo
 * @returns image url
 */
export async function uploadNutritionPhoto(context, image) {
  try {
    const { data } = await axios.post("/upload-image", image);
    if (data.error) {
      throw data;
    }
    return data.urlImg;
  } catch (error) {
    return error;
  }
}

/**
 * Function to delete a nutrition test.
 * @param {Object} context - Vuex context.
 * @param {Object} payload - nutrition test id.
 */
 export async function removeNutritionTest(context, payload) {
  try {
    const { data } = await axios.delete(
      `/nutritionTest/remove/${payload}`,
      payload
    );
    if (data.error) {
      throw data;
    }

    return data;
  } catch (e) {
    return e.response.data;
  }
}