export function SET_AUTHENTICATED_USER(state, user) {
  state.authenticatedUser = user;
}
export function SET_TOKEN(state, token) {
  state.token = token;
}
export function SET_ERROR(state, error) {
  state.error = error;
}
export function SET_USER_DATA(state, data) {
  state.token = data.TOKEN;
  state.authenticatedUser = data.USER;
  state.session = data.SESSION;
}
export function SET_LOG_OUT(state) {
  state.authenticatedUser = null;
  state.token = null;
  state.session = null;
  state.permissions = [];
}

export function SET_PERMISSIONS(state, userRoles) {
  let allPermissions = userRoles.map(role => role.permissions);
  let permissionsFiltered = [...new Set(allPermissions.flat())];

  state.permissions = permissionsFiltered;
}

export function SET_AUTHENTICATED_STATUS(state, status) {
  state.authenticated = status;
}