// Guard
import { privateGuard } from "@/middlewares/guards";

// Layouts
import NavLayout from "../layouts/NavLayout.vue";

// Views
import Ceparium from "../views/Ceparium.vue";

export default [
    {
        path: "/ceparium",
        beforeEnter: privateGuard,
        component: NavLayout,
        children: [
            {
                path: "",
                name: "Ceparium",
                component: Ceparium,
                meta: {
                    title: "Cepario Solena",
                },
            },
        ],
    }
];
