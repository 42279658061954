<template>
  <v-dialog
    v-model="show"
    transition="dialog-bottom-transition"
    fullscreen
    scrollable
    persistent
  >
    <v-card scrollable>
      <v-card-text class="px-0" ref="principal_card">
        <v-toolbar color="primary" class="white--text">
          <h2>
            <v-icon color="white">mdi-camera-rear</v-icon> Recepción cepario
          </h2>
          <v-spacer></v-spacer>
          <v-btn icon color="white" @click="show = false">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <v-row align="center" justify="center">
            <v-col cols="12" class="text-center" v-if="test == null">
              <small>Escanea el código de barras de la caja </small>
            </v-col>
            <template v-else>
              <v-col cols="1" class="text-center" @click="resetView">
                <v-btn color="success" icon
                  ><v-icon>mdi-arrow-left</v-icon></v-btn
                >
              </v-col>

              <v-col cols="11" class="text-center">
                <small>Toma la fotografía del ensayo </small>
              </v-col>
            </template>

            <v-col cols="10" md="6" v-if="test != null">
              <Camera :test_name="test.media" @image_uploaded="updateTest" />
            </v-col>
            <v-col cols="10" md="6" v-else>
              <div id="reader" class="qr-reader-container"></div>
            </v-col>
            <v-col
              cols="10"
              v-if="test != null"
              justify="center"
              align="center"
            >
              <v-row justify="center" align="center">
                <v-col cols="5"> <b> ID de ensayo </b></v-col>
                <v-col cols="5">
                  {{ test.media }}
                </v-col>
                <v-col cols="5"> <b> Tipo de ensayo </b></v-col>
                <v-col cols="5">
                  {{ test.type }}
                </v-col>
                <v-col cols="5"> <b> Folio </b></v-col>
                <v-col cols="5">
                  {{ test.folioAnalysis }}
                </v-col>
                <v-col cols="5"> <b> Número de cepa </b></v-col>
                <v-col cols="5">
                  {{ test.strainNumber }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-form ref="formPreserve" lazy-validation>
                    <v-row>
                      <v-col xl="8" offset-xl="2" lg="8" offset-lg="2">
                        <v-text-field
                          dense
                          outlined
                          :rules="validationNumber()"
                          label="Ingresa número de tubos a preservar"
                          v-model="numberOfMicrotubes"
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col xl="8" offset-xl="2" lg="8" offset-lg="2">
                        <v-switch
                          v-model="isMicroTube"
                          label="¿Se usarán microtubos?"
                          color="warning"
                        ></v-switch>
                      </v-col>
                      <v-col xl="8" offset-xl="2" lg="8" offset-lg="2">
                        <v-text-field
                          dense
                          outlined
                          :rules="validation('ID')"
                          label="Ingresa ID cepa"
                          v-model="strainSequenceID"
                          type="text"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="allTestDialog"
      width="600px"
      persistent
      transition="dialog-bottom-transition"
      hide-overlay
    >
      <v-toolbar
        color="warning"
        class="white--text"
        title="Interrumpir ensayos"
      >
        <h2>Interrumpir ensayos</h2>
      </v-toolbar>
      <v-card-text style="background: white">
        <div class="text-h6 pa-6">
          ¿Deseas interrumpir todos los ensayos de medición del folio
          {{ folioAll }}?
        </div>
      </v-card-text>
      <v-card-actions class="justify-end" style="background: white">
        <v-btn
          text
          color="warning"
          variant="text"
          :disabled="interuptLoader"
          @click="allTestDialog = false"
          >No interrumpir</v-btn
        >
        <v-btn
          text
          color="primary"
          variant="text"
          @click="interruptAllTests"
          :loading="interuptLoader"
          ><b>Interrumpir</b></v-btn
        >
      </v-card-actions>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import BarcodeScanner from "@undecaf/vue-barcode-scanner";
import Camera from "../Camera.vue";
import { Html5Qrcode } from "html5-qrcode";
import bwipjs from "bwip-js";
// validations
import { numberValidation, requiredValidation } from "@/helpers/validations.js";
export default {
  components: {
    BarcodeScanner,
    Camera,
  },
  data() {
    return {
      html5QrCodeScanner: null,
      dialog: false,
      captures: [],
      imgReport: [],
      frontCam: false,
      webcam: null,
      img: null,
      camera: null,
      deviceId: null,
      devices: [],
      test: null,
      test_string: "",
      folios: [],
      folioAll: "",
      allTestDialog: false,
      interuptLoader: false,
      numberOfMicrotubes: null,
      strainSequenceID: null,
      isMicroTube: false,
    };
  },
  computed: {
    ...mapState("utils", ["fontLabels"]),
    device: function () {
      return find(this.devices, (n) => n.deviceId == this.deviceId);
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  props: {
    value: Boolean,
  },
  async mounted() {
    this.startScanner();
  },
  watch: {
    async show() {
      if (!this.dialog) {
        this.camera = null;
        this.test = null;
        this.test_string = "";
        this.stopScanner();
      } else {
        await this.waitFor(500);
        this.startScanner();
        // navigator.mediaDevices
        //   .getUserMedia({ audio: false, video: { facingMode: "environment" } })
        //   .then((stream) => {
        //     this.camera = stream;
        //     const videoElement = this.$refs.scanner.$refs.srcRendererElem;
        //     if (videoElement) {
        //       // Agrega la propiedad playsinline al elemento de video
        //       videoElement.setAttribute("playsinline", "");
        //     }
        //   });
      }
    },
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      if (typeof window.orientation === "undefined") {
        // Once we have a list select the first one
        let first = this.devices.length > 0 ? this.devices[0] : null;
        if (first) {
          this.camera = first.deviceId;
          this.deviceId = first.deviceId;
        }
      } else {
        // this.frontCam = false;
      }
    },
    async test_string(newValue, oldValue) {
      try {
        if (newValue != "" && newValue.length == 6) {
          const res = await this.validate_test_ceparium(newValue);

          if (res.error) throw res;

          if (res.test == null) {
            this.SET_ALERT_TYPE({
              visible: true,
              type: "error",
              text: `El ensayo está en otra etapa del análisis o no existe`,
              timeout: 4000,
            });
          } else {
            this.stopScanner();
            await this.waitFor(500);
            this.test = res.test;

            console.log(res);
          }
        }
      } catch (error) {
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Error al encontrar el ensayo. Error: ${error.message}`,
          timeout: 4000,
        });
      }
    },
  },
  methods: {
    ...mapActions("labels", ["validate_test_ceparium"]),
    ...mapActions("isolate", ["preserveStrain"]),
    ...mapActions("biochemicalTest", ["interruptTest"]),
    ...mapActions("microbiologicalTest", ["interruptAllMicrobiologicalTest"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    validationNumber() {
      return numberValidation({ min: 1, required: true });
    },
    validation(name) {
      return requiredValidation(name);
    },
    waitFor(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    startScanner() {
      const config = { fps: 10, qrbox: this.calculateQrBox() };

      this.html5QrCodeScanner = new Html5Qrcode("reader");

      // If you want to prefer back camera
      this.html5QrCodeScanner.start(
        { facingMode: "environment" },
        config,
        this.onScanSuccess
      );
    },
    stopScanner() {
      // Detener la lectura cuando el componente se destruye (opcional)
      if (this.html5QrCodeScanner) {
        this.html5QrCodeScanner
          .stop()
          .then((ignore) => {
            // QR Code scanning is stopped.
            this.html5QrCodeScanner = null;
          })
          .catch((err) => {
            // Stop failed, handle it.
            console.log("ERROR");
          });
      }
    },
    calculateQrBox() {
      // Calcula el tamaño del cuadro QR en función del ancho de la pantalla
      const screenWidth = window.innerWidth;
      const baseQrBox = 250;

      // Puedes ajustar estos valores según tus necesidades
      if (screenWidth < 576) {
        return baseQrBox * 0.8;
      } else if (screenWidth < 768) {
        return baseQrBox * 0.7;
      } else if (screenWidth < 992) {
        return baseQrBox * 0.8;
      } else if (screenWidth < 1200) {
        return baseQrBox * 0.9;
      } else {
        return baseQrBox;
      }
    },
    onScanSuccess(decodedText, decodedResult) {
      // handle the scanned code as you like, for example:
      if (decodedText) {
        if (this.test_string != decodedText) {
          this.test_string = decodedText;
        }
      }
    },
    camera_started() {
      const videoElement = this.$refs.scanner.$refs.srcRendererElem;
      if (videoElement) {
        // Agrega la propiedad playsinline al elemento de video
        videoElement.setAttribute("playsinline", "");
      }
    },
    scanned(barcodes) {
      if (barcodes.length > 0) {
        if (this.test_string != barcodes[0].rawValue) {
          console.log(barcodes[0]);
          this.test_string = barcodes[0].rawValue;
        }
      }
    },
    async updateTest({ original, small }) {
      if (this.$refs.formPreserve.validate()) {
        try {
          let payload = {
            isolateId: this.test.test_id,
            preservedQty: this.numberOfMicrotubes,
            photo: small,
            strainSequenceID: this.strainSequenceID,
            strainNumber: this.test.strainNumber,
          };

          let endResponse = await this.preserveStrain(payload);

          if (endResponse.error) throw endResponse;
          // print labels

          let pdfTestMedia = new jsPDF({
            orientation: "landscape",
            unit: "cm",
            format: [108, 34], // page size in points: 108 width, 34 height
          });
          pdfTestMedia.addFileToVFS(
            "RobotoMono-VariableFont_wght.ttf",
            this.fontLabels
          );
          pdfTestMedia.addFont(
            "RobotoMono-VariableFont_wght.ttf",
            "RobotoMono-VariableFont_wght",
            "normal"
          );
          pdfTestMedia.setFont("RobotoMono-VariableFont_wght", "normal");
          pdfTestMedia.setFontSize(6);

          for (
            let index = 0;
            index < parseInt(this.numberOfMicrotubes);
            index++
          ) {
            let canvas = document.createElement("canvas");
            if (!this.isMicroTube) {
              bwipjs.toCanvas(canvas, {
                bcid: "code128", // Barcode type
                text: `${endResponse.isolateFound.testMedia}`, // Text to encode
                scale: 3, // 3x scaling factor
                height: 10, // Bar height, in millimeters
                includetext: false, // Show human-readable text
                textxalign: "center", // Always good to set this
              });
              pdfTestMedia.text(this.test.strain, 0.08, 0.28);
              let folio = endResponse.sample.folioAnalysis.split("-");
              pdfTestMedia.text(
                `${endResponse.strainSequenceID} | ${folio[0]}-${this.test.strainNumber} | ${folio[1]} ${endResponse.isolateFound.mb_metadata.medium} (${endResponse.isolateFound.mb_metadata.dilution})`,
                0.08,
                0.58
              );

              pdfTestMedia.addImage(
                canvas.toDataURL("image/png"),
                "JPG",
                0.08, // x
                0.78, // y
                3.65, // w
                0.4 // h
              );
            } else {
              bwipjs.toCanvas(canvas, {
                bcid: "code128", // Barcode type
                text: `${endResponse.isolateFound.testMedia}`, // Text to encode
                scale: 3, // 3x scaling factor
                height: 5, // Bar height, in millimeters
                includetext: false, // Show human-readable text
                textxalign: "center", // Always good to set this
              });
              pdfTestMedia.text(this.test.strain, 0.08, 0.28);
              let folio = endResponse.sample.folioAnalysis.split("-");
              pdfTestMedia.text(
                `${endResponse.strainSequenceID} | ${folio[0]}-${this.test.strainNumber} | ${folio[1]} ${endResponse.isolateFound.mb_metadata.medium} (${endResponse.isolateFound.mb_metadata.dilution})`,
                0.08,
                0.58
              );

              pdfTestMedia.addImage(
                canvas.toDataURL("image/png"),
                "JPG",
                0.08, // x
                0.78, // y
                1.32, // w
                0.4 // h
              );
            }
            if (index != parseInt(this.numberOfMicrotubes) - 1) {
              pdfTestMedia.addPage();
            }
          }

          // Save PDF in local
          pdfTestMedia.save(`etiquetas-preservación-${new Date()}.pdf`);

          pdfTestMedia.autoPrint();
          window.open(pdfTestMedia.output("bloburl"), "_blank");

          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: `Las etiquetas de la preservación se generaron correctamente y se guardó una copia en descargas.`,
            timeout: 8000,
          });
          await this.resetView();
          this.numberOfMicrotubes = null;
          this.isMicroTube = false;

          console.log(this.test);
        } catch (error) {
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: `Error al actualizar el ensayo. Error: ${error.message}`,
            timeout: 4000,
          });
        }
      }
    },
    async interruptAllTests() {
      try {
        this.interuptLoader = true;
        let payload = {
          folioAnalysis: this.folioAll,
        };
        let response = await this.interruptAllMicrobiologicalTest(payload);
        if (!response) throw response;
        this.allTestDialog = false;
        this.folioAll = "";
        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: `Se interrumpieron correctamente ensayos del folio ${this.folioAll}`,
          timeout: 4000,
        });
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Error al actualizar el ensayo. Error: ${error.message}`,
          timeout: 4000,
        });
      }
      this.interuptLoader = false;
    },
    async resetView() {
      this.camera = null;
      this.test = null;
      this.test_string = "";
      await this.waitFor(500);
      this.startScanner();
      // navigator.mediaDevices
      //   .getUserMedia({ audio: false, video: { facingMode: "environment" } })
      //   .then((stream) => {
      //     this.camera = stream;
      //     const videoElement = this.$refs.scanner.$refs.srcRendererElem;
      //     if (videoElement) {
      //       // Agrega la propiedad playsinline al elemento de video
      //       videoElement.setAttribute("playsinline", "");
      //     }
      //   });
    },
  },
  filters: {
    percent: function (value) {
      if (!value) return "";
      return (Math.floor(value * 10000) / 100).toFixed(0) + "%";
    },
  },
  beforeDestroy() {
    this.stopScanner();
  },
};
</script>

<style scoped>
.viewport {
  width: 100%;
}

video {
  width: 100%;
}
.qr-reader-container {
  max-width: 100%;
  margin: auto;
}
</style>
