<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="4" md="4" v-if="preservedSelected">
        <v-card class="fill-height">
          <v-card-text>
            <v-container>
              <v-row class="mb-2">
                <v-col>
                  <v-btn fab color="primary" x-small @click="closeDetail">
                    <v-icon> mdi-keyboard-backspace </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <DetailPreservedDI
                @fetchTable="fetchData"
                v-if="preservedSelected"
              />
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        :sm="preservedSelected ? '8' : '12'"
        :md="preservedSelected ? '8' : '12'"
      >
        <v-card class="fill-height">
          <v-card-text class="fill-height">
            <v-container class="fill-height">
              <v-row class="fill-height" align="center">
                <v-col cols="12" xl="4" lg="4" offset-xl="4" offset-lg="4">
                  <v-text-field
                    label="Búsqueda"
                    prepend-inner-icon="mdi-magnify"
                    hide-details
                    v-model="search"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-menu
                    offset-y
                    :close-on-click="true"
                    :close-on-content-click="false"
                    v-model="menu"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" v-bind="attrs" v-on="on" block>
                        <v-icon left>mdi-filter</v-icon>
                        Filtro
                      </v-btn>
                    </template>
                    <v-card width="350px">
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <v-autocomplete
                                label="Identificación"
                                :items="microorganisms"
                                hide-details
                                chips
                                v-model="identification"
                                :search-input.sync="searchMo"
                                :loading="isLoading"
                                @keydown="changeIdentificationValue"
                                item-text="name"
                                clearable
                                hide-no-data
                                return-object
                                dense
                              ></v-autocomplete>
                            </v-col>
                            <v-col>
                              <v-combobox
                                dense
                                label="Viabilidad"
                                :items="enumViability"
                                v-model="viability"
                                item-text="name"
                                clearable
                              ></v-combobox>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" @click="clearFilter"
                          >Restaurar</v-btn
                        >
                        <v-btn color="primary" @click="applyFilter"
                          >Aplicar</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-btn
                    color="primary"
                    block
                    @click="showAddIDStrainModal = true"
                  >
                    <v-icon> mdi-database-plus </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-data-table
                    :headers="headers"
                    :items="preserved_strains"
                    :page.sync="page"
                    :items-per-page="10"
                    :loading="loadTable"
                    hide-default-footer
                    fixed-header
                    height="56vh"
                    @click:row="rowSelect"
                    :item-class="row_classes"
                  >
                    <template #[`item.preservationDateCMA`]="{ item }">
                      <span v-if="item.preservationDateCMA"
                        >{{ dateFormat(item.preservationDateCMA) }} -
                        {{ item.containerCMA || 0 }} tubos</span
                      >
                      <span v-else>----/--/--</span>
                    </template>
                    <template #[`item.preservationDate`]="{ item }">
                      <span v-if="item.preservationDate"
                        >{{ dateFormat(item.preservationDate) }} -
                        {{ item.containerQtyCeparium || 0 }} tubos</span
                      >
                      <span v-else>----/--/--</span>
                    </template>
                    <template #[`item.viability`]="{ item }">
                      <span v-if="item.viability == true" class="primary--text">
                        Es viable
                      </span>
                      <span v-else class="red--text"> No viable </span>
                    </template>

                    <template #[`item.identificationName`]="{ item }">
                      <v-menu
                        offset-y
                        :close-on-click="true"
                        :close-on-content-click="false"
                        v-if="
                          item.identificationsHistory &&
                          item.identificationsHistory.length != 0 &&
                          item.identificationsHistory.length != 1
                        "
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" style="color: #04c105"
                            >{{ item.identificationName }}
                          </span>
                        </template>
                        <v-card width="250px">
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col
                                  cols="12"
                                  v-for="(
                                    identification, index
                                  ) in item.identificationsHistory"
                                  :key="index"
                                >
                                  {{ index + 1 }} - {{ identification.name }}
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                        </v-card>
                      </v-menu>

                      <span v-else>{{ item.identificationName }} </span>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col cols="12">
                  <v-pagination
                    v-model="page"
                    :length="numPages"
                    total-visible="10"
                    color="primary"
                  ></v-pagination>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <add-i-d-strain-modal
      v-model="showAddIDStrainModal"
      v-if="showAddIDStrainModal"
      @fetchData="fetchData"
    />
  </v-container>
</template>

<script>
// vuex
import { mapActions, mapMutations, mapState } from "vuex";
//Libraries
import dayjs from "dayjs";

// components
import DetailPreservedDI from "@/components/ceparium/DetailPreservedDI.vue";
import AddIDStrainModal from "@/components/ceparium/modals/AddIDStrainModal.vue";
export default {
  components: {
    AddIDStrainModal,
    DetailPreservedDI,
  },
  data: () => ({
    menu: null,
    extraDataMo: null,
    identification: null,
    searchMo: null,
    isLoading: false,
    microorganisms: [],
    page: 1,
    numPages: 1,
    search: null,
    filter: null,
    loadTable: false,
    headers: [
      {
        text: "ID. Cepa",
        sortable: false,
        value: "strainId",
      },
      { text: "Microorganismo", value: "identificationName", sortable: false },
      // {
      //   text: "Cantidad de tubos",
      //   value: "containerQtyCeparium",
      //   sortable: false,
      // },

      { text: "Origen", value: "companyName", sortable: false },
      { text: "Viabilidad", value: "viability", sortable: false },
      {
        text: "Fecha de preservación CMA",
        value: "preservationDateCMA",
        sortable: false,
      },
      {
        text: "Fecha de preservación León",
        value: "preservationDate",
        sortable: false,
      },
    ],
    viability: null,
    enumViability: [
      {
        name: "Es viable",
        value: "viable",
      },
      {
        name: "No es viable",
        value: "not_viable",
      },
    ],
    preserved_strains: [],
    showAddIDStrainModal: false,
  }),
  computed: {
    ...mapState("ceparium", ["preservedSelected"]),
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("ceparium", ["getPrserved"]),
    ...mapMutations("ceparium", ["SET_PRESERVED_SELECTED"]),
    ...mapActions("identification", ["searchIdentifications"]),
    changeIdentificationValue() {
      if (!this.searchMo) {
        this.identification = null;
      }
    },
    dateFormat(value) {
      const date = `${dayjs(value).format("MM/DD/YYYY")}`;
      return date || "--/--/----";
    },
    rowSelect(rowValue) {
      this.preserved_strains = this.preserved_strains.map((data) => {
        if (rowValue._id == data._id) {
          data.clicked = true;
        } else {
          data.clicked = false;
        }
        return data;
      });
      this.SET_PRESERVED_SELECTED(rowValue);
    },
    row_classes(value) {
      if (value.clicked) {
        return "custom-highlight-row";
      }
    },
    closeDetail() {
      this.SET_PRESERVED_SELECTED(null);
      this.preserved_strains = this.preserved_strains.map((data) => {
        data.clicked = false;
        return data;
      });
    },
    async applyFilter() {
      this.filter = {};
      if (this.identification) {
        this.filter.identification = this.identification._id;
      } else {
        this.filter.identification = null;
      }

      if (this.viability) {
        this.filter.viability = this.viability.value;
      } else {
        this.filter.viability = null;
      }

      this.menu = false;
      this.SET_PRESERVED_SELECTED(null);
      await this.fetchData();
    },
    async clearFilter() {
      this.identification = null;
      this.viability = null;
      this.menu = false;
      this.filter = null;
      this.SET_PRESERVED_SELECTED(null);
      await this.fetchData();
    },
    async fetchData(value) {
      try {
        if (value) {
          this.SET_PRESERVED_SELECTED(value);
        }
        this.loadTable = true;
        let payload = {
          page: this.page,
          filter: this.filter,
          search: this.search,
          type: "ID",
        };
        let response = await this.getPrserved(payload);
        if (!response) throw Error(response);
        this.preserved_strains = response.preserved;
        this.numPages = response.pages;
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Error al buscar cargar cepas preservadas",
          timeout: 4000,
        });
      }
      this.loadTable = false;
    },
  },
  async mounted() {
    await this.fetchData();
  },
  watch: {
    async page() {
      await this.fetchData();
    },
    async search() {
      this.SET_PRESERVED_SELECTED(null);
      this.page = 1;
      this.numPages = 1;
      await this.fetchData();
    },
    async searchMo(value) {
      if (value) {
        this.isLoading = true;
        try {
          const response = await this.searchIdentifications({
            name: value,
          });
          if (response.error) throw response;
          this.microorganisms = response.IDENTIFICATIONS;
        } catch (e) {
          console.log(e);
        }
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-highlight-row {
  background-color: rgba(4, 193, 5, 0.13);
  font-weight: bold;
}
</style>
