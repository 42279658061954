<template>
  <v-dialog
    v-model="show"
    transition="dialog-bottom-transition"
    scrollable
    max-width="800px"
    persistent
  >
    <v-card scrollable>
      <v-card-text class="px-0">
        <v-toolbar color="primary" class="white--text">
          <h2>
            <v-icon color="white" class="mr-1">mdi-image-plus</v-icon>Logos de
            paquetes
          </h2>
          <v-spacer></v-spacer>
          <v-btn icon color="white" @click="show = false">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <v-tabs color="primary" grow>
            <v-tab>Agregar</v-tab>
            <v-tab>Eliminar</v-tab>
            <v-tab-item>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-form ref="form" lazy-validation>
                      <v-row>
                        <v-col cols="6">
                          <v-row>
                            <v-col cols="12">
                              <v-file-input
                                truncate-length="15"
                                v-model="newIcon"
                                @change="handleFileChange"
                                hide-details
                                label="Agregar logo"
                                outlined
                                dense
                                chips
                                :rules="validation()"
                              ></v-file-input>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="logoName"
                                outlined
                                dense
                                label="Nombre del logo"
                                :rules="validation()"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="6" class="text-end">
                          <span>Vista previa</span>
                          <v-spacer></v-spacer>
                          <canvas
                            ref="canvas"
                            :width="canvasWidth"
                            :height="canvasHeight"
                            style="border: 1px solid black"
                          ></canvas>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            small
                            @click="addLogo"
                            :loading="isLoadingAdd"
                            :disabled="isLoadingDelete"
                          >
                            Agregar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-row>
                      <v-col cols="12">
                        <span class="text-subtitle-1 font-weight-bold">
                          Logos ({{ laboratory.name }})
                        </span>
                      </v-col>
                      <v-col>
                        <v-autocomplete
                          truncate-length="15"
                          v-model="logoSelected"
                          :items="logos"
                          hide-details
                          dense
                          item-text="name"
                          label="Logo paquete"
                          no-data-text="logos no disponibles"
                          return-object
                          outlined
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" class="text-end">
                    <span>Vista previa</span>
                    <v-spacer></v-spacer>
                    <canvas
                      ref="canvas_delete"
                      :width="canvasWidth"
                      :height="canvasHeight"
                      style="border: 1px solid black"
                    ></canvas>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="red"
                      small
                      @click="removeLogo"
                      dark
                      :loading="isLoadingDelete"
                      :disabled="isLoadingAdd || !logoSelected"
                    >
                      Eliminar logo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="gray"
          @click="show = false"
          dark
          :disabled="isLoadingAdd || isLoadingDelete"
          >Cerrar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
//validations
import { requiredValidation } from "@/helpers/validations.js";
import { mapActions, mapMutations } from "vuex";
export default {
  components: {},
  data() {
    return {
      newIcon: null,
      logoName: null,
      resizedImageDataUrl: null,
      canvasWidth: 180,
      canvasHeight: 90,
      user: JSON.parse(localStorage.getItem("user")),
      logos: [],
      logoSelected: null,
      isLoadingAdd: false,
      isLoadingDelete: false,
    };
  },
  props: {
    value: Boolean,
    laboratory: {
      type: Object,
      default: {
        name: "-",
      },
      required: true,
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  async mounted() {
    await this.loadLogos();
  },
  beforeDestroy() {},
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("laboratory", [
      "updateLogoPackages",
      "getLaboratoryById",
      "removeLogoPackages",
    ]),
    async addLogo() {
      try {
        if (this.$refs.form.validate()) {
          this.isLoadingAdd = true;
          let nameFile = `logo_${this.logoName.trim()}`;
          // let fileB64 = this.b64ToFile(nameFile, this.resizedImageDataUrl);
          var formData = new FormData();
          formData.append("file", this.resizedImageDataUrl, nameFile);
          formData.append("name", this.logoName);
          formData.append("laboratory", this.laboratory._id);
          let response = await this.updateLogoPackages(formData);
          if (!response || response.error)
            throw response ? response.message : "error";
          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: `Logo agregado.`,
            timeout: 4000,
          });
          this.newIcon = null;
          this.logoName = null;
          this.resizedImageDataUrl = null;
          this.$refs.form.reset();
          this.cleanCanvasAdd();
          await this.loadLogos();
        }
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Error al agregar logo: ${error.message}`,
          timeout: 4000,
        });
      }
      this.isLoadingAdd = false;
    },
    async removeLogo() {
      try {
        this.isLoadingDelete = true;
        let payload = {
          laboratory: this.laboratory._id,
          logo: this.logoSelected,
        };
        let response = await this.removeLogoPackages(payload);
        if (!response || response.error)
          throw response ? response.message : "error";
        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: `Logo eliminado.`,
          timeout: 4000,
        });
        this.cleanCanvasRemove();
        this.logoSelected = null;
        await this.loadLogos();
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Error al eliminar logo: ${error.message}`,
          timeout: 4000,
        });
      }
      this.isLoadingDelete = false;
    },
    handleFileChange(file) {
      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            const canvas = this.$refs.canvas;
            canvas.width = this.canvasWidth;
            canvas.height = this.canvasHeight;
            const ctx = canvas.getContext("2d");

            const aspectRatio = img.width / img.height;
            let newWidth = this.canvasWidth;
            let newHeight = this.canvasWidth / aspectRatio;

            if (newHeight > this.canvasHeight) {
              newHeight = this.canvasHeight;
              newWidth = this.canvasHeight * aspectRatio;
            }

            canvas.width = newWidth;
            canvas.height = newHeight;

            //   const offsetX = (this.canvasWidth - newWidth) / 2;
            //   const offsetY = (this.canvasHeight - newHeight) / 2;

            ctx.drawImage(img, 0, 0, newWidth, newHeight); // Redimensiona la imagen

            this.resizedImageDataUrl = file; //canvas.toDataURL(); // Guarda la imagen redimensionada
          };
          img.src = e.target.result;
        };

        reader.readAsDataURL(file);
      }
    },
    b64ToFile(fileName, b64) {
      var base64data = b64
        .replace("data:image/png;base64,", "")
        .replace("data:image/jpg;base64,", "");
      var bs = atob(base64data);
      var buffer = new ArrayBuffer(bs.length);
      var ba = new Uint8Array(buffer);
      for (var i = 0; i < bs.length; i++) {
        ba[i] = bs.charCodeAt(i);
      }
      var blob = new Blob([ba], { type: "image/png" });

      let file = new File([blob], fileName);

      return file;
    },
    validation(name) {
      return requiredValidation(name);
    },
    async loadLogos() {
      try {
        let payload = {
          laboratoryId: this.laboratory._id,
        };
        let response = await this.getLaboratoryById(payload);
        if (!response || response.error)
          throw response ? response.message : "error";
        this.logos = response.laboratory.packagesLogos;
      } catch (error) {
        console.log(error);
      }
    },
    cleanCanvasAdd() {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    },
    cleanCanvasRemove() {
      const canvas = this.$refs.canvas_delete;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    },
  },
  watch: {
    logoSelected(logo) {
      if (logo) {
        const img = new Image();
        img.onload = () => {
          const canvas = this.$refs.canvas_delete;
          canvas.width = this.canvasWidth;
          canvas.height = this.canvasHeight;
          const ctx = canvas.getContext("2d");

          const aspectRatio = img.width / img.height;
          let newWidth = this.canvasWidth;
          let newHeight = this.canvasWidth / aspectRatio;

          if (newHeight > this.canvasHeight) {
            newHeight = this.canvasHeight;
            newWidth = this.canvasHeight * aspectRatio;
          }

          canvas.width = newWidth;
          canvas.height = newHeight;

          ctx.drawImage(img, 0, 0, newWidth, newHeight);
        };
        img.src = logo.url;
      }
    },
  },
};
</script>

<style scoped>
.carousel-image {
  width: 180px;
  height: 90px;
}

.carousel-fit {
  max-width: 100%;
  max-height: 270px;
}
.carousel-container {
  max-width: 200px;
}
</style>
