var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"450"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"success","disabled":_vm.nextReplica == 5 || (_vm.stage != 'LISIS' && _vm.samples.length == 0 || (_vm.stage != 'LISIS' && !_vm.samplesPerReplicas[_vm.nextReplica]))}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Nueva repetición ")],1)]}}]),model:{value:(_vm.dialogStatus),callback:function ($$v) {_vm.dialogStatus=$$v},expression:"dialogStatus"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[[_vm._v(" Nueva repetición ")]],2),_c('v-card-text',[_c('v-container',[_c('v-row',[(_vm.stage == 'LISIS')?_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Selección de muestras.")]):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-autocomplete',{attrs:{"items":_vm.samplesToSelect,"rules":_vm.autocompleteRules,"disabled":_vm.stage != 'LISIS',"item-text":"folioAnalysis","label":"Muestras","return-object":"","multiple":"","chips":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected,"close":_vm.stage == 'LISIS',"color":data.item.sampleType.includes('VEGETABLE')
                        ? 'primary'
                        : 'warning'},on:{"click":data.select,"click:close":function($event){return _vm.remove(data.item)}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.folioAnalysis)+" ")])]}},{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.folioAnalysis)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(
                        data.item.sampleType.includes('VEGETABLE')
                          ? 'VEGETAL'
                          : 'SUELO'
                      )}})],1)]}}]),model:{value:(_vm.samplesSelected),callback:function ($$v) {_vm.samplesSelected=$$v},expression:"samplesSelected"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.notAssign}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"warning darken-1","text":""},on:{"click":_vm.assign}},[_vm._v(" Aceptar ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }