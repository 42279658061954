export function SET_MDTEST_LIST(state, mbTests) {
  state.microbiologicalTestList = mbTests;
}
export function SET_ERROR(state, err) {
  state.error = err;
}
export function SET_PAGINATION(state, pagination) {
  state.pagination = pagination;
}
export function SET_COUNT(state, count) {
  state.count = count;
}

export function SET_MBTEST_SELECTED(state, mbTest) {
  state.microbiologicalTestSelected = mbTest
}