<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6" class="text-start">
        <v-btn color="primary" @click="goBack" :disabled="isSaving">
          <v-icon> mdi-arrow-left-thick </v-icon>
          Atras
        </v-btn>
      </v-col>
      <v-col cols="6" class="text-end">
        <v-btn
          color="primary"
          @click="openModalUpdateLevels"
          :disabled="isSaving"
        >
          <v-icon> mdi-file-excel </v-icon>
          Añadir
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span class="text-body-1 font-weight-bold"> Nuevo producto </span>
      </v-col>
    </v-row>
    <v-container>
      <v-form ref="form" lazy-validation>
        <v-row>
          <v-col cols="4">
            <v-autocomplete
              dense
              outlined
              label="Selecciona marcador"
              clearable
              :search-input.sync="searchMarker"
              :items="markers"
              item-text="microorganism_meta.name"
              item-value="_id"
              :loading="markersLoading"
              :rules="validate('marcador')"
              v-model="markerSelected"
              return-object
              prepend-inner-icon="mdi-magnify"
            >
              <template v-slot:item="data">
                {{ data.item.microorganism_meta.name }} ({{
                  isPathogen(data.item.isPathogen)
                }})
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              hide-details
              outlined
              clearable
              dense
              :items="crops_items"
              hide-no-data
              item-text="name"
              label="Selecciona cultivo"
              v-model="cropSelected"
              :rules="validate('cultivo')"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              :search-input.sync="searchPackage"
              v-model="packageSelected"
              :items="packages"
              label="Paquete"
              hide-no-data
              outlined
              dense
              item-text="name"
              clearable
              return-object
              prepend-inner-icon="mdi-magnify"
              :rules="validate('paquete')"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <span class="body-2 font-weight-bold">Configuración</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field
              dense
              outlined
              label="Nombre"
              :rules="validate('nombre')"
              v-model="nameProduct"
              clearable
              type="text"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              hide-no-data
              outlined
              dense
              label="Selecciona composición"
              :items="composition"
              v-model="compositionSelected"
              clearable
              item-text="name"
              :rules="validate('composición')"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              hide-no-data
              outlined
              dense
              label="Selecciona estatus"
              :items="statusEnum"
              v-model="statusSelected"
              clearable
              item-text="name"
              :rules="validate('estatus')"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="4" v-if="this.prescriptionSelected">
            <v-autocomplete
              dense
              outlined
              label="Selecciona etapa fenologica"
              v-model="phenologicalStagesSelected"
              :rules="validate('etapa fenologica')"
              :items="phenologicalStages"
              item-text="name"
              clearable
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="4" v-else>
            <v-autocomplete
              dense
              outlined
              label="Selecciona etapa fenologica"
              v-model="phenologicalStagesSelected"
              :rules="validate('etapa fenologica')"
              :items="phenologicalStages"
              item-text="name"
              clearable
              multiple
              chips
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="body-2 font-weight-bold">Rangos de dosis</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field
              dense
              outlined
              label="Dosis baja"
              v-model="dosesLow"
              :rules="validationNumber"
              clearable
              type="number"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              dense
              outlined
              label="Dosis media"
              v-model="dosesMedium"
              :rules="validationNumber"
              clearable
              type="number"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              dense
              outlined
              label="Dosis alta"
              v-model="dosesHight"
              :rules="validationNumber"
              clearable
              type="number"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-end">
          <v-col cols="2">
            <v-btn color="primary" block @click="saveData" :loading="isSaving">
              <v-icon> mdi-content-save </v-icon>
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <!-- modals -->
    <UpdateProductmodal
      v-model="showUpdateProductModal"
      v-if="showUpdateProductModal"
      @refreshTable="goBackAndUpdate"
    />
  </v-container>
</template>

<script>
//VUEX
import { mapActions, mapState, mapMutations } from "vuex";
// validations
import { requiredValidation } from "@/helpers/validations.js";
//components
import UpdateProductmodal from "@/components/cmda_admin/modals/UpdateProductmodal.vue";
export default {
  components: {
    UpdateProductmodal,
  },
  data: () => ({
    showUpdateProductModal: false,
    searchMarker: null,
    markersLoading: false,
    markers: [],
    crops_items: [],
    markerSelected: null,
    cropSelected: null,
    searchPackage: null,
    packageSelected: null,
    packages: [],
    composition: [
      {
        name: "Solida",
        value: "SOLID",
      },
      {
        name: "Liquida",
        value: "LIQUID",
      },
    ],
    compositionSelected: null,
    nameProduct: null,
    phenologicalStages: [
      { name: "Presiembra", value: "Pre-planting" },
      { name: "Siembra", value: "Sowing" },
      { name: "Trasplante", value: "Transplanting" },
      { name: "Desarrollo Vegetativo", value: "Vegetative growth" },
      { name: "Floración", value: "Bloom" },
      { name: "Cosecha", value: "Harvest" },
      { name: "Emergencia", value: "Sprout" },
      { name: "Tuberización", value: "Tuberization" },
      { name: "Llenado de tuberculos", value: "Tuber filling" },
      { name: "Desarrollo foliar", value: "Foliage development" },
      { name: "Expansión foliar", value: "Foliar expansion" },
      { name: "Primera hoja", value: "First leaf" },
      { name: "Tercer hoja", value: "Third leaf" },
      { name: "Quinta hoja", value: "Fifth leaf" },
      { name: "Inicio de bulbificación", value: "Start of bulbing" },
      { name: "Desarrollo del bulbo", value: "Bulb growth" },
      { name: "Maduración de fruto", value: "Fruit maturity" },
      { name: "Brotación", value: "Sprouting" },
    ],
    statusEnum: [
      {
        name: "Ficha téncnica",
        value: "TECHNICAL_SHEET",
      },
      {
        name: "FeedBack",
        value: "FEEDBACK",
      },
    ],
    statusSelected: null,
    phenologicalStagesSelected: null,
    dosesLow: null,
    dosesMedium: null,
    dosesHight: null,
    isSaving: false,
  }),
  computed: {
    ...mapState("reception", ["crops"]),
    ...mapState("prescription", ["prescriptionSelected"]),
    validationNumber() {
      return [
        (v) => !!v || "El campo no puede estar vacío",
        (v) => v > 0 || "Debe ser un número mayor a 0",
        (v) => !isNaN(parseFloat(v)) || "Debe ser un número",
      ];
    },
  },
  async mounted() {
    this.setCropItems();
    if (this.prescriptionSelected) {
      await this.searchingMarkers(
        this.prescriptionSelected.molecularMarker.name
      );
      await this.searchingPackage(this.prescriptionSelected.package.name);
      this.markerSelected = this.markers.find(
        (element) =>
          element.microorganism_meta.name ==
          this.prescriptionSelected.molecularMarker.name
      );
      this.cropSelected = this.crops_items.find(
        (element) => element.value == this.prescriptionSelected.crop
      ).value;
      this.packageSelected = this.packages.find(
        (element) => element._id == this.prescriptionSelected.package._id
      );
      this.nameProduct = this.prescriptionSelected.productName;
      this.compositionSelected = this.composition.find(
        (element) => element.value == this.prescriptionSelected.productType
      ).value;
      this.phenologicalStagesSelected = this.phenologicalStages.find(
        (element) =>
          element.value == this.prescriptionSelected.phenologicalStage
      ).value;
      this.dosesLow = this.prescriptionSelected.dosesLow;
      this.dosesMedium = this.prescriptionSelected.dosesMedium;
      this.dosesHight = this.prescriptionSelected.dosesHight;
      this.statusSelected = this.prescriptionSelected.status;
    }
  },
  methods: {
    ...mapActions("laboratory", ["getMarkers", "getPackagesByName"]),
    ...mapActions("prescription", ["createProduct", "updateProduct"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    formatItemText(item) {
      return `${item.microorganism_meta.name} (${this.isPathogen(
        item.isPathogen
      )})`;
    },
    openModalUpdateLevels() {
      this.showUpdateProductModal = true;
    },
    async saveData() {
      try {
        if (this.$refs.form.validate()) {
          this.isSaving = true;
          let payload = {
            crop: this.cropSelected,
            phenologicalStages: this.phenologicalStagesSelected,
            packageId: this.packageSelected._id,
            markerId: this.markerSelected._id,
            dosesLow: this.dosesLow,
            dosesMedium: this.dosesMedium,
            dosesHight: this.dosesHight,
            productType: this.compositionSelected,
            productName: this.nameProduct,
            status: this.statusSelected,
          };

          if (this.prescriptionSelected) {
            let id = this.prescriptionSelected._id;
            await this.updateProduct({ payload, id });
            this.SET_ALERT_TYPE({
              visible: true,
              type: "success",
              text: `Producto actualizado`,
              timeout: 5000,
            });
          } else {
            await this.createProduct(payload);
            this.SET_ALERT_TYPE({
              visible: true,
              type: "success",
              text: `Producto creado`,
              timeout: 5000,
            });
          }

          this.goBackAndUpdate();
        }
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
    },
    validate(name) {
      return requiredValidation(name);
    },
    goBack() {
      this.$emit("back");
    },
    goBackAndUpdate() {
      this.$emit("back_update");
    },
    async searchingMarkers(value) {
      if (value) {
        try {
          this.markersLoading = true;
          let payload = {
            filter: {
              search: value,
            },
            per_page: 20,
            page: 1,
          };
          const response = await this.getMarkers(payload);
          if (response.error) throw response;
          this.markers = response.molecularMarkers;
        } catch (error) {
          console.log(error);
        }
        this.markersLoading = false;
      } else {
        this.markers = [];
      }
    },
    async searchingPackage(value) {
      try {
        const req = await this.getPackagesByName({ name: value });

        if (req.error) throw req;

        this.packages = req.packages;
      } catch (error) {
        console.error(error);
      }
    },
    setCropItems() {
      let cropsArray = Object.entries(this.crops);
      for (let crop of cropsArray) {
        this.crops_items.push({
          name: crop[1],
          value: crop[0],
        });
      }
    },
    isPathogen(value) {
      if (value) {
        return "Patógeno";
      } else {
        return "Benéfico";
      }
    },
  },
  watch: {
    async searchMarker(value) {
      await this.searchingMarkers(value);
    },
    async searchPackage(value) {
      await this.searchingPackage(value);
    },
  },
};
</script>
