<template>
  <v-dialog v-model="show" width="50vw" scrollable persistent>
    <v-card height="auto" elevation="5" class="rounded-lg" min-height="200">
      <v-card-title class="text-h5 lighten-2">
        Tipos de resultsdos</v-card-title
      >
      <v-card-text>
        <v-container>
          <v-row>
            <v-col >
              <v-autocomplete
                outlined
                dense
                :items="resultsTypes"
                item-text="text"
                return-object
                label="Selecciona el tipo de resultados"
                v-model="resultType"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col>
              <v-btn
                color="gray"
                block
                @click="show = false"
                dark
                :disabled="isLoading"
                >Cerrar</v-btn
              >
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                block
                @click="generateResult"
                :disabled="!resultType"
                :loading="isLoading"
              >
                Generar vista prevía</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  data: () => ({
    isLoading: false,
    resultType: null,
    resultsTypes: [
      { text: "Microbiología", value: "MB" },
      { text: "Molecular", value: "ML" },
    ],
    user: JSON.parse(localStorage.getItem("user")),
  }),
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    generateResult() {
      this.$emit("result", this.resultType.value);
    },
  },
};
</script>
