import axios from "axios";

/**
 * Function to get profits by laboratory using pagination
 * @param {Object} { commit } - Vuex function
 * @param {String} payload - laboratry id and currevt page
 */
// export async function getProfits({ commit }, payload) {
//   try {
//     const { data } = await axios.get(
//       `/profit/${payload.laboratory}/${payload.page}`
//     );
//     if (data.error) throw data;
//     return data;
//   } catch (e) {
//     return e;
//   }
// }

export async function getProfitsFilter({ commit }, payload) {
  try {
    const { data } = await axios.get(`/profit/getProfits`, {
      params: payload,
    });
    if (data.error) throw data;
    return data;
  } catch (e) {
    return e;
  }
}

export async function getProfit({ commit }, payload) {
  try {
    const { data } = await axios.get(`/profit`, {
      params: { id: payload },
    });
    if (data.error) throw data;
    commit("SET_PROFIT", data.profit);
    return data;
  } catch (e) {
    return e;
  }
}

export async function getBestStrains({ commit }, payload) {
  try {
    const { data } = await axios.post(`/profit/get-best-strains`, {
      id: payload.id,
    });
    if (data.error) throw data;
    commit("SET_STRAINS", data.strains);
    return data;
  } catch (e) {
    return e;
  }
}

export async function saveBestStrains({ commit }, payload) {
  try {
    const { data } = await axios.post(`/profit/save-best-strains`, {
      id: payload.id,
      productsSugestions: payload.productsSugestions,
      id_profit: payload.id_profit,
    });
    if (data.error) throw data;
    // commit("SET_STRAINS", data.strains);
    return data;
  } catch (e) {
    return e;
  }
}

/**
 * Function to get profits by laboratory using pagination
 * @param {Object} { commit } - Vuex function
 * @param {String} payload - laboratry id and currevt page
 */
export async function getProfitVersion({ commit }, payload) {
  try {
    const { data } = await axios.post(`/profit/get-version-profit`, {
      id: payload,
    });
    if (data.error) throw data;
    commit("SET_PROFIT", data.profit);
    return data;
  } catch (e) {
    return e;
  }
}

export async function saveProfit({ commit }, payload) {
  try {
    const { data } = await axios.post(`/profit/save-profit`, {
      data: payload,
    });
    if (data.error) throw data;
    // commit("SET_STRAINS", data.strains);
    return data;
  } catch (e) {
    return e;
  }
}

export async function generateProfitPDF({ commit }, payload) {
  try {
    const { data } = await axios.post(`/profit/generate-pdf`, {
      profit: payload.profit,
      profitVersion: payload.profitVersion,
      url: payload.url,
    });
    if (data.error) throw data;
    // commit("SET_STRAINS", data.strains);
    return data;
  } catch (e) {
    return e;
  }
}

export async function generateProfitTempPDF({ commit }, payload) {
  try {
    const { data } = await axios.post(`/results/generate-profit-report`, {
      profit: payload.profit,
      profitVersion: payload.profitVersion,
    });
    if (data.error) throw data;
    // commit("SET_STRAINS", data.strains);
    return data;
  } catch (e) {
    return e;
  }
}

export async function deleteTempFiles({ commit }, payload) {
  try {
    const { data } = await axios.post(`/profit/delete-temp-file`, payload);
    if (data.error) throw data;
    // commit("SET_STRAINS", data.strains);
    return data;
  } catch (e) {
    return e;
  }
}

export async function finishProfit({ commit }, payload) {
  try {
    const { data } = await axios.post(`/profit/finish-profit`, {
      id: payload.id,
      date: payload.date,
    });
    if (data.error) throw data;
    // commit("SET_STRAINS", data.strains);
    return data;
  } catch (e) {
    return e;
  }
}

export async function getBoostId({ commit }, payload) {
  try {
    const { data } = await axios.post(`/profit/get-boost`);
    if (data.error) throw data;
    // commit("SET_STRAINS", data.strains);
    return data;
  } catch (e) {
    return e;
  }
}

export async function makeBuyBack({ commit }, payload) {
  try {
    const { data } = await axios.post("/profit/buyback", payload);
    if (data.error) throw data;
    return data;
  } catch (e) {
    return e.response.data;
  }
}

export async function getFoliosInProfit({ commit }, payload) {
  const { data } = await axios.post(`/profit/getFoliosInProfit`, payload);
  if (data.error) throw data;
  return data;
}

export async function getValuationFromAnalysis({ commit }, payload) {
  const { data } = await axios.post(
    `/profit/getValuationFromAnalysis`,
    payload
  );
  if (data.error) throw data;
  return data;
}
