<template>
  <v-dialog v-model="show" width="100vw" scrollable persistent>
    <v-card height="auto" elevation="5" class="rounded-lg" min-height="200">
      <v-card-title class="text-h5 lighten-2">
        Resultados sistema vs Resultados Test
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xl="6" lg="6" md="6">
              <v-data-table
                :headers="headers"
                :items="testData"
                :search="search"
                class="overflow-y-auto"
                height="400px"
                fixed-header
                no-data-text="No hay datos"
                loading-text="Cargando datos del sistema..."
                :loading="isLoading"
              >
                <template #[`item.mo`]="{ item }">
                  <span v-if="item.cqMin">
                    {{ item.mo }} ({{ Number(item.cqMin.toFixed(2)) }},
                    {{ Number(item.cqMax.toFixed(2)) }})
                  </span>
                  <span v-else> {{ item.mo }} (-, -) </span>
                </template>
                <template #[`item.riskLevelMedium`]="{ item }">
                  <span v-if="item.riskLevelMedium">
                    {{ Number(parseFloat(item.riskLevelMedium.min).toFixed(2)) }},
                    {{ Number(parseFloat(item.riskLevelMedium.max).toFixed(2)) }}
                  </span>
                  <span v-else> (-, -) </span>
                </template>
                <template #[`item.family`]="{ item }">
                  <div v-if="item.family.includes('aplica')">
                    <span> Otra </span>
                  </div>
                  <div v-else>
                    <span>
                      {{ item.family }}
                    </span>
                  </div>
                </template>
                <template #[`item.level`]="{ item }">
                  <span>
                    {{ getLevel(item.level) }}
                  </span>
                </template>
                <template #[`item.products`]="{ item }">
                  <div v-for="(product, index) in item.products" :key="index">
                    <div v-if="product != null">
                      <span>
                        {{ product }}
                      </span>
                      <v-spacer></v-spacer>
                    </div>
                  </div>
                </template>
                <template #[`item.isDetected`]="{ item }">
                  <div v-if="item.isDetected">
                    <span class="primary--text"> Detectado </span>
                  </div>
                  <div v-else>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="red--text"
                          >No detectado</span
                        >
                      </template>
                      <div v-for="(error, index) in item.errors" :key="index">
                        <span></span>
                        <v-spacer>
                          {{ error }}
                        </v-spacer>
                      </div>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6">
              <v-data-table
                :headers="headers"
                :items="rawData"
                :search="search"
                class="overflow-y-auto"
                height="400px"
                fixed-header
              >
                <template #[`item.mo`]="{ item }">
                  <span>
                    {{ item.mo }} ({{ Number(item.cqMin.toFixed(2)) }},
                    {{ Number(item.cqMax.toFixed(2)) }})
                  </span>
                </template>
                <template #[`item.riskLevelMedium`]="{ item }">
                  <span>
                    {{ Number(item.riskLevelMedium.min.toFixed(2)) }},
                    {{ Number(item.riskLevelMedium.max.toFixed(2)) }}
                  </span>
                </template>
                <template #[`item.family`]="{ item }">
                  <div v-if="item.family.includes('aplica')">
                    <span> Otra </span>
                  </div>
                  <div v-else>
                    <span>
                      {{ item.family }}
                    </span>
                  </div>
                </template>
                <template #[`item.level`]="{ item }">
                  <span>
                    {{ getLevel(item.level) }}
                  </span>
                </template>
                <template #[`item.products`]="{ item }">
                  <div v-for="(product, index) in item.products" :key="index">
                    <div v-if="product != null">
                      <span>
                        {{ product }}
                      </span>
                      <v-spacer></v-spacer>
                    </div>
                  </div>
                </template>
                <template #[`item.isDetected`]="{ item }">
                  <div v-if="item.isDetected">
                    <span class="primary--text"> Detectado </span>
                  </div>
                  <div v-else>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="red--text"
                          >No detectado</span
                        >
                      </template>
                      <div v-for="(error, index) in item.errors" :key="index">
                        <span></span>
                        <v-spacer>
                          {{ error }}
                        </v-spacer>
                      </div>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col>
              <v-btn
                color="gray"
                block
                @click="closeModal"
                dark
                :disabled="isLoading"
                >Cancelar</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//vuex
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  data: () => ({
    search: null,

    isLoading: false,
    headers: [
      {
        text: "Marcador",
        sortable: true,
        value: "mo",
      },
      {
        text: "Nivel rango",
        sortable: true,
        value: "riskLevelMedium",
        align: "center",
      },
      {
        text: "Familia",
        sortable: true,
        value: "family",
      },
      {
        text: "Eficiencia",
        sortable: true,
        value: "efficiency",
      },
      {
        text: "CQ",
        sortable: true,
        value: "cq",
      },
      {
        text: "UMC",
        sortable: true,
        value: "umc",
      },
      {
        text: "Nivel",
        sortable: true,
        value: "level",
      },
      {
        text: "Productos",
        sortable: true,
        value: "products",
      },
      {
        text: "Estatus",
        sortable: true,
        value: "isDetected",
      },
    ],
    systemData: [],
  }),
  props: {
    value: Boolean,
  },
  async mounted() {
    // await this.getDataFromSystem();
  },
  computed: {
    ...mapState("laboratory", ["testData","rawData"]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions("laboratory", ["compareTestData"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    closeModal() {
      this.show = false;
    },
    getLevel(level) {
      let levesl = {
        low: "bajo",
        medium: "medio",
        high: "alto",
      };

      return levesl[level];
    },
    async getDataFromSystem() {
      try {
        this.isLoading = true;
        let response = await this.compareTestData({
          testData: this.testData,
        });
        this.systemData = response.rawData;
        console.log( response.rawData);
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>
