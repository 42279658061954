import Vue from "vue";
import Vuex from "vuex";

// Modules
import alerts from "./modules/alerts/index";
import session from "@/store/modules/session/index";
import reception from "@/store/modules/reception/index";
import testMedia from "@/store/modules/testMedia/index";
import cultureMedia from "@/store/modules/cultureMedia/index";
import pqTest from "@/store/modules/pqTest/index";
import nematodes from "@/store/modules/nematodes/index";
import identification from "@/store/modules/identification/index";
import isolate from "@/store/modules/isolate/index";
import microbiologicalTest from "@/store/modules/microbiologicalTest/index";
import biochemicalTest from "@/store/modules/biochemicalTest/index";
import antagonism from "@/store/modules/antagonism/index";
import nutrition from "@/store/modules/nutrition/index";
import bioStimulation from "@/store/modules/bioStimulation/index";
import strain from "@/store/modules/strain/index";
import molecularIdentification from "@/store/modules/molecularIdentification/index";
import molecularPlate from "@/store/modules/molecularPlate/index";
import molecularPlateWell from "@/store/modules/molecularPlateWell/index";
import molecularLisisTube from "@/store/modules/molecularLisisTube/index";
import utils from "@/store/modules/utils/index";
import profit from "@/store/modules/profit/index";
import analysis from "@/store/modules/analysis/index";
import sequencingReport from "@/store/modules/sequencingReport/index";
import laboratory from "@/store/modules/laboratory/index";
import labels from "@/store/modules/labels/index";
import ceparium from "@/store/modules/ceparium/index";
import organization from "@/store/modules/organization/index";
import riskLevel from "@/store/modules/riskLevel/index";
import prescription from "@/store/modules/prescription/index";
import project from "@/store/modules/project/index";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    key_here_xyz: "0ZQXvkZ0rddyktNimruIMs2TUyFCAIkPisTmyzKGhcY",
    loader: false,
    cancelToken: undefined,
    filters: localStorage.getItem("testsFilters"),
    showSampleDataS3:
      localStorage.getItem("showSampleDataS3") == "true" ||
      localStorage.getItem("showSampleDataS3") == null,
  },
  mutations: {
    SET_LOADER(state, payload) {
      state.loader = payload;
    },
    SET_CANCEL_TOKEN(state, token) {
      state.cancelToken = token;
    },
    SET_FILTERS(state, filters) {
      localStorage.setItem("testsFilters", JSON.stringify(filters));
      state.filters = JSON.stringify(filters);
    },
    UPDATE_SHOW_SAMPLE_DATA(state, status) {
      localStorage.setItem("showSampleDataS3", status);
      state.showSampleDataS3 = status;
    },
  },
  actions: {},
  modules: {
    alerts,
    session,
    reception,
    testMedia,
    cultureMedia,
    pqTest,
    nematodes,
    identification,
    isolate,
    microbiologicalTest,
    biochemicalTest,
    antagonism,
    nutrition,
    bioStimulation,
    strain,
    utils,
    profit,
    molecularIdentification,
    molecularPlate,
    molecularPlateWell,
    analysis,
    molecularLisisTube,
    sequencingReport,
    laboratory,
    labels,
    ceparium,
    organization,
    riskLevel,
    prescription,
    project,
  },
});
