<template>
  <v-dialog v-model="dialog" width="85%" scrollable persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn block class="default" elevation="0" v-bind="attrs" v-on="on">
        Antagonismo
      </v-btn>
    </template>

    <v-card class="py-2">
      <!-- <v-card-title class="text-h5 grey lighten-2">
        Privacy Policy
      </v-card-title> -->
      <v-card-text>
        <v-container class="pa-6">
          <v-row>
            <v-col cols="12">
              <p class="text-center text-h5">Nuevo Antagonismo</p>
            </v-col>
          </v-row>
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-container>
              <v-row align="center" justify="center">
                <v-col cols="12" md="4">
                  <v-combobox
                    v-model="analysisToFound"
                    :items="analysesFounded"
                    :rules="analysisRules"
                    :loading="loadingAnalysis"
                    no-data-text="No se encontró el análisis"
                    return-object
                    label="Análisis"
                    no-filter
                    clearable
                    outlined
                    dense
                  >
                    <template v-slot:item="{ item }">
                      {{ item.folio }} -
                      {{
                        item.status == "MEASURED"
                          ? "MEDIDO"
                          : item.status == "VALUATING"
                          ? "VALUANDO"
                          : item.status == "RECEIVED"
                          ? "RECIBIDO"
                          : "VALUADO"
                      }}
                    </template>
                    <template v-slot:selection="{ item }">
                      <template v-if="typeof item == 'string'">
                        {{ item }}
                      </template>
                      <template v-else>
                        {{ item.folio }} -
                        {{
                          item.status == "MEASURED"
                            ? "MEDIDO"
                            : item.status == "VALUATING"
                            ? "VALUANDO"
                            : item.status == "RECEIVED"
                            ? "RECIBIDO"
                            : "VALUADO"
                        }}
                      </template>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </v-container>

            <v-row class="mt-2 pr-2">
              <v-col cols="2" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-caption text-center mb-0 font-weight-black">
                    ID de ensayo
                  </p>
                </v-row>
              </v-col>
              <v-col cols="1" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-caption text-center mb-0 font-weight-black">
                    Medio
                  </p>
                </v-row>
              </v-col>
              <v-col cols="4" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-caption text-center mb-0 font-weight-black">
                    Tipo de ensayo
                  </p>
                </v-row>
              </v-col>
              <v-col cols="4" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-caption text-center mb-0 font-weight-black">
                    Inóculo
                  </p>
                </v-row>
              </v-col>
            </v-row>

            <v-row
              class="pt-4 pr-2"
              v-for="(item, index) in bcTestsSelected"
              :key="index"
            >
              <v-col cols="2" class="px-0 pb-0">
                <v-row justify="center" align="center">
                  <v-autocomplete
                    v-model="item.testMedia"
                    class="px-8"
                    :items="testMediaList"
                    :rules="testMediaRules"
                    @change="assingDataRow(index)"
                    return-object
                    item-text="_id"
                    no-data-text="No se ha encontrado"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-row>
              </v-col>
              <v-col cols="1" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-center pt-2 mb-0" v-if="item.testMedia == null">
                    - - -
                  </p>
                  <p class="text-center pt-2 mb-0" v-else>
                    {{ item.testMedia.cultureMedia.nutrientAgar }}
                  </p>
                </v-row>
              </v-col>
              <v-col cols="4" class="px-5 pb-0">
                <v-row justify="center" align="center">
                  <v-select
                    v-model="item.testType"
                    :items="testTypes"
                    label="Tipo de ensayo"
                    :menu-props="{ top: false, offsetY: true }"
                    :loading="loadingAnalysis"
                    outlined
                    dense
                  ></v-select>
                </v-row>
              </v-col>
              <v-col
                :cols="bcTestsSelected.length > 1 ? '4' : '5'"
                class="px-5 pb-0"
              >
                <v-row justify="center" align="center">
                  <v-combobox
                    v-if="item.testType.includes('Confrontación')"
                    v-model="item.inoculum"
                    label="Inóculo"
                    item-text="identificationSample"
                    :items="beneficalInocules"
                    :rules="inoculumRules"
                    :menu-props="{ top: false, offsetY: true }"
                    :loading="loadingAnalysis"
                    return-object
                    outlined
                    dense
                  >
                    <template v-slot:selection="{item}">
                      <template v-if="!item.identificationSample.includes('Banco')">
                        <span v-if="item.microbiologicalTest">
                          🔬 {{item.identificationSample}}
                        </span>
                        <span v-else>
                          🧬 {{item.identificationSample}}
                        </span>
                      </template>
                      <template v-else>
                        <img
                          src="/favicon.png"
                          width="25px"
                          height="25px"
                        /><img />
                        <span>
                          {{ item.identificationSample }}</span
                        >
                      </template>
                    </template>
                    <template v-slot:item="{ item }">
                      <template v-if="!item.identificationSample.includes('Banco')">
                        <span v-if="item.microbiologicalTest">
                          🔬 {{item.identificationSample}}
                        </span>
                        <span v-else>
                          🧬 {{item.identificationSample}}
                        </span>
                      </template>
                      <template v-else>
                        <img
                          src="/favicon.png"
                          width="25px"
                          height="25px"
                        /><img />
                        <span class="font-weight-bold">
                          {{ item.identificationSample }}</span
                        >
                      </template>
                    </template>
                  </v-combobox>
                  <v-combobox
                    v-else-if="item.testType == 'Control'"
                    v-model="item.inoculum"
                    label="Inóculo"
                    item-text="identificationSample"
                    :items="threathInocules"
                    :rules="inoculumRules"
                    :item-disabled="disableItem"
                    :menu-props="{ top: false, offsetY: true }"
                    :loading="loadingAnalysis"
                    return-object
                    outlined
                    dense
                  >
                    <template v-slot:selection="{item}">
                      <span v-if="item.microbiologicalTest">
                        🔬 {{item.identificationSample}}
                      </span>
                      <span v-else>
                        🧬 {{item.identificationSample}}
                      </span>
                    </template>
                    <template v-slot:item="{ item }">
                      <span v-if="item.microbiologicalTest">
                        🔬 {{item.identificationSample}}
                      </span>
                      <span v-else>
                        🧬 {{item.identificationSample}}
                      </span>
                    </template>
                  </v-combobox>
                </v-row>
              </v-col>
              <v-col cols="1" class="px-0 pb-0" v-if="bcTestsSelected.length > 1">
                <v-row justify="center">
                  <v-btn @click="removeTest(index)" color="red" fab x-small dark>
                    <v-icon>mdi-delete-forever</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-container>  
      </v-card-text>
      

      <v-card-actions class="justify-center">
        <v-row class="pt-0 mt-0">
          <v-col cols="4">
            <v-btn depressed block color="warning" @click="dialog = false">
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn depressed block color="secondary" @click="addTest">
              Añadir ensayo
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn depressed block color="primary" @click="createTests">
              Continuar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
// validations
import {
  requiredValidation,
  requiredFValidation,
} from "@/helpers/validations.js";
// library
import axios from "axios";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      loadingAnalysis: false,
      valid: true,
      analysisSelected: null,
      analysisToFound: "",
      // autocomplete
      searchAnalysisList: "",
      search: [],
      // items dilution
      dilutionItems: [
        { dilution: "P", inverse: 0.667 },
        { dilution: "Direct", inverse: 1 },
        { dilution: "-6", inverse: 1000000 },
        { dilution: "-5", inverse: 100000 },
        { dilution: "-4", inverse: 10000 },
        { dilution: "-3", inverse: 1000 },
        { dilution: "-2", inverse: 100 },
        { dilution: "-1", inverse: 10 },
      ],
      // rules
      analysisRules: requiredFValidation("muestra"),
      testMediaRules: requiredValidation("ID de medio"),
      inoculumRules: requiredValidation("Inóculo"),
      dilutionRules: requiredFValidation("dilución"),
      user: JSON.parse(localStorage.getItem("user")),
      bcTestsSelected: [
        {
          testMedia: null,
          medium: null,
          testType: "Control",
          inoculum: null,
        },
      ],
      testTypes: ["Control"],
      controlsFromDB: [],
      beneficalInocules: [],
      threathInocules: [],
    };
  },
  watch: {
    bcTestsSelected: {
      async handler() {
        this.testTypes = ["Control"];
        if (this.analysisSelected && this.analysisSelected.bcTests) {
          // console.log("BC TESTS FROM ANALYSIS", this.analysisSelected.bcTests)
          await this.getControls(this.analysisSelected.bcTests);
        }
        for (const testAdded of this.bcTestsSelected) {
          if (testAdded.testType == "Control" && testAdded.inoculum != null) {
            this.testTypes.push(
              `Confrontación ${testAdded.inoculum.identificationSample}`
            );
          }
        }
      },
      deep: true,
    },
    // Search test media
    // async search(search) {
    //   if (this.dialog) {
    //     if (typeof this.cancelToken != typeof undefined) {
    //       this.cancelToken.cancel("Operation canceled due to new request.");
    //     }
    //     //Save the cancel token for the current request
    //     this.SET_CANCEL_TOKEN(axios.CancelToken.source());

    //     if (search && search[search.length - 1]) {
    //       try {
    //         const response = await this.searchTestMedia({
    //           laboratory: this.user.laboratory._id,
    //           status: "UNREGISTERED",
    //         });
    //         if (response.error) throw response;
    //       } catch (error) {
    //         console.log("Error searching test media", error);
    //         this.SET_ALERT_TYPE({
    //           visible: true,
    //           type: "error",
    //           text: error.message,
    //           timeout: 4000,
    //         });
    //       }
    //     }
    //   }
    // },
    // search samples that are validated
    async searchAnalysisList(value) {
      this.loadingAnalysis = true;

      if (value && typeof value == "string") {
        this.analysisSelected = null;

        if (typeof this.cancelToken != typeof undefined) {
          this.cancelToken.cancel("Operation canceled due to new request.");
        }
        //Save the cancel token for the current request
        this.SET_CANCEL_TOKEN(axios.CancelToken.source());

        try {
          const response = await this.searchAnalysis({
            laboratory: this.user.laboratory._id,
            status: ["MEASURED", "VALUATING", "VALUATED", "RECEIVED"],
            search: value,
          });

          if (response.error) throw response;

          try {
            const response = await this.searchTestMedia({
              laboratory: this.user.laboratory._id,
              status: "UNREGISTERED",
            });
            if (response.error) throw response;
          } catch (error) {
            console.log("Error searching test media", error);
            this.SET_ALERT_TYPE({
              visible: true,
              type: "error",
              text: error.message,
              timeout: 4000,
            });
          }
        } catch (error) {
          console.log("Error searching sample", error);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: error.message,
            timeout: 4000,
          });
        }
        
      } else {
        await this.processAnalysis(value)
      }

      this.loadingAnalysis = false;
    },
    dialog: function () {
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.analysisSelected = null;
        this.REMOVE_ANALYSES_FOUNDED();
        this.controlsFromDB = [];
        this.bcTestsSelected = [
          {
            testMedia: null,
            medium: null,
            testType: "Control",
            inoculum: null,
          },
        ];
      }
    },
    analysisToFound() {
      let analysisString = this.analysisToFound;
      this.searchAnalysisList = analysisString;
    },
  },
  computed: {
    ...mapState(["cancelToken"]),
    ...mapState("reception", ["analysesFounded"]),
    ...mapState("testMedia", ["testMediaList"]),
    ...mapState("strain", ["bioactiveBankStrains"]),
  },
  methods: {
    ...mapMutations(["SET_CANCEL_TOKEN"]),
    ...mapMutations(["SET_LOADER"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapMutations("reception", ["REMOVE_ANALYSES_FOUNDED"]),
    ...mapActions("testMedia", ["searchTestMedia"]),
    ...mapActions("microbiologicalTest", ["createMBTest"]),
    ...mapActions("biochemicalTest", ["addBCTest"]),
    ...mapActions("isolate", ["addIsolate"]),
    ...mapActions("antagonism", ["addAntagonismTest"]),
    ...mapActions("reception", [
      "searchValidatedReception",
      "getStrainsByAnalysis",
      "searchAnalysis",
    ]),
    disableItem(item) {
      if (item != null) {
        for (const bcTest of this.bcTestsSelected) {
          if (
            bcTest.inoculum != null &&
            item.identificationSample == bcTest.inoculum.identificationSample
          ) {
            return true;
          }
        }
        return false;
      }
    },
    // add sample to array of selected tests
    addTest() {
      this.bcTestsSelected.push({
        testMedia: null,
        medium: null,
        testType: "Control",
        inoculum: null,
      });
    },
    // remove sample to array of selected tests
    removeTest(index) {
      this.bcTestsSelected.splice(index, 1);
    },
    // assing data selected for dilution to the actual row
    assingDataRow(i) {
      this.bcTestsSelected[i].dilution =
        this.bcTestsSelected[i].testMedia &&
        this.bcTestsSelected[i].testMedia.cultureMedia &&
        this.bcTestsSelected[i].testMedia.cultureMedia.dilution
          ? this.bcTestsSelected[i].testMedia.cultureMedia.dilution
          : null;

      if (this.bcTestsSelected[i].dilution) {
        this.bcTestsSelected[i].medium =
          this.bcTestsSelected[i].testMedia.cultureMedia.nutrientAgar;
      }
    },
    // get microorganism type
    getMOType(type) {
      let itemsMoType = {
        all: "Todos",
        bacteria: "Bacteria",
        fungi: "Hongo",
        nematode: "Nemátodo",
      };
      return itemsMoType[type];
    },
    async createTests() {
      let analysisId = this.analysisSelected._id;

      if (this.$refs.form.validate()) {
        this.SET_LOADER(true);

        try {
          let controls = this.bcTestsSelected.filter(
            (test) => test.testType == "Control"
          );

          for (const dbControl of this.controlsFromDB) {
            let obj = {
              _id: dbControl.isolate._id,
              inoculum: {
                identification: dbControl.isolate.strain.identification.name,
                identificationSample: `${dbControl.isolate.strain.identification.name} - ${dbControl.isolate.strain.soilSample.folioAnalysis}`,
                moType: dbControl.isolate.strain.identification.moType,
                soilSample: dbControl.isolate.strain.soilSample._id,
                strainId: dbControl.isolate.strain._id,
              },
              isolate: dbControl.isolate,
              biochemicalTest: dbControl,
            };

            controls.push(obj);
          }

          for (const control of controls) {
            let beneficialsControl = this.bcTestsSelected.filter(
              (test) =>
                test.testType ==
                `Confrontación ${control.inoculum.identificationSample}`
            );

            let isolate = null;
            if (control._id == undefined) {
              let newIsolate = {
                user: this.user._id,
                soilSample: control.inoculum.soilSample,
                testMedia: control.testMedia._id,
                isolateType: "Control",
                status: "INOCULATED",
                strain: control.inoculum.strainId,
                inoculation: new Date(),
                beneficialGrowth: "0",
                isolateGrowth: "0",
                laboratory: this.user.laboratory._id,
              };
              isolate = await this.createIsolate(newIsolate);
            } else {
              isolate = control.isolate;
            }

            if (isolate != null) {
              let biochemicalTest = null;
              if (control.biochemicalTest) {
                biochemicalTest = control.biochemicalTest;
              } else {
                let bcTest = {
                  isolate: isolate._id,
                  status: "IN_PROGRESS",
                  user: this.user._id,
                  type: "Antagonism",
                  analysis: analysisId,
                  laboratory: this.user.laboratory._id,
                };

                let request = await this.addBCTest(bcTest);
                if (request.error) throw new Error(request.message);

                biochemicalTest = request.biochemicalTest;
              }

              for (const beneficial of beneficialsControl) {
                let antagonismTest = {
                  status: "INOCULATED",
                  mycoparasitism: false,
                  beneficalStrain: beneficial.inoculum.strainId,
                  testMedia: beneficial.testMedia._id,
                  inoculation: new Date(),
                  biochemicalTest: biochemicalTest._id,
                  pathogenGrowth: "0",
                  beneficialGrowth: "0",
                  soilSample: control.inoculum.soilSample,
                  laboratory: this.user.laboratory._id,
                };

                let response = await this.addAntagonismTest(antagonismTest);
                if (response.error) throw response;

                console.log("RESPONSE ANTAGONISM TEST", response);
              }
            }
          }

          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: "Confrontaciónes agregadas correctamente.",
            timeout: 4000,
          });

          this.$emit("newTests");
          this.dialog = false;
        } catch (e) {
          console.log(e);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text:
              e.message === "testMediaRegisted"
                ? "Ya está registrado este medio de cultivo"
                : "Ha ocurrido un error, inténtalo de nuevo",
            timeout: 4000,
          });
        }

        this.SET_LOADER(false);
      }
    },
    async createIsolate(isolateObj) {
      let requestBody = {
        isolate: isolateObj,
        isReseeding: false,
      };
      const response = await this.addIsolate(requestBody);
      if (response.error) {
        throw response;
      } else {
        return response.isolate;
      }
    },
    async getControls(bcTests) {
      let antagonismTest = bcTests.filter(
        (test) => test.type == "Antagonism" && test.isolate
      );
      this.controlsFromDB = antagonismTest;
      for (const antagonism of antagonismTest) {
        let newTestType = `Confrontación ${antagonism.isolate.strain.identification.name} - ${antagonism.isolate.strain.soilSample.folioAnalysis}`;
        let newTypes = this.testTypes.concat(newTestType);
        // console.log(newTypes);
        this.testTypes = newTypes;
      }
    },
    async processAnalysis(analysisSelected) {
      this.beneficalInocules = [];
      this.analysisSelected = analysisSelected

      try {
        if (analysisSelected) {
          let analysisId = analysisSelected._id;

          let requestB = await this.getStrainsByAnalysis({
            analysis: analysisId,
            threath: false,
            laboratory : this.user.laboratory._id
          });

          if (requestB.error) throw new Error();

          let requestT = await this.getStrainsByAnalysis({
            analysis: analysisId,
            threath: true,
            laboratory : this.user.laboratory._id
          });

          if (requestT.error) throw new Error();

          let beneficalInocules = [];
          let threathInocules = [];
          for (const strain of requestB.strains) {
            let obj = {
              strainId: strain._id,
              microbiologicalTest: strain.microbiologicalTest,
              identification: strain.identification.name,
              identificationSample: `${strain.identification.name} - ${strain.sampleFolio}`,
              moType: strain.identification.moType,
              concentration: strain.concentration,
              soilSample: strain.soilSample,
            };
            beneficalInocules.push(obj);
          }

          for (const strain of requestT.strains) {
            let obj = {
              strainId: strain._id,
              microbiologicalTest: strain.microbiologicalTest,
              molecularTest: strain.molecularTest,
              identification: strain.identification.name,
              identificationSample: `${strain.identification.name} - ${strain.sampleFolio}`,
              moType: strain.identification.moType,
              concentration: strain.concentration,
              soilSample: strain.soilSample,
            };
            threathInocules.push(obj);
          }

          for (const strain of this.bioactiveBankStrains) {
            let obj = {
              strainId: strain._id,
              identification: strain.identification.name,
              identificationSample: `${strain.identification.name} - Banco de bioactivos`,
              moType: strain.identification.moType,
            };
            beneficalInocules.push(obj);
          }

          this.beneficalInocules = beneficalInocules;
          this.threathInocules = threathInocules;

          if (analysisSelected && analysisSelected.bcTests) {
            // console.log("BC TESTS FROM ANALYSIS", analysisSelected.bcTests)
            await this.getControls(analysisSelected.bcTests);
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
