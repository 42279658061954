<template>
  <v-dialog v-model="show" width="50vw" scrollable persistent>
    <v-card height="auto" elevation="5" class="rounded-lg" min-height="200">
      <v-card-title class="text-h5 lighten-2">
        Exportar muestras resguardadas</v-card-title
      >
      <v-card-text>
        <v-container>
          <v-row>
            <v-col xl="6">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dates"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-combobox
                    v-model="dates"
                    multiple
                    chips
                    small-chips
                    label="Selecciona rango de fechas"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    outlined
                    dense
                  ></v-combobox>
                </template>
                <v-date-picker v-model="dates" multiple no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="handleSave"
                    :disabled="dates.length !== 2"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col xl="6">
              <v-autocomplete
                outlined
                dense
                :items="statusItems"
                item-text="text"
                return-object
                label="Estatus muestra"
                v-model="status"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col>
              <v-btn
                color="gray"
                block
                @click="show = false"
                dark
                :disabled="isLoading"
                >Cerrar</v-btn
              >
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                block
                @click="exportData"
                :disabled="dates.length !== 2 || !status"
                :loading="isLoading"
              >
                <v-icon> mdi-file-excel </v-icon>
                Exportar a Excel</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//Vuex
import { mapMutations, mapState, mapActions } from "vuex";
// library
import dayjs from "dayjs";
export default {
  components: {},
  data: () => ({
    isLoading: false,
    dates: [],
    menu: false,
    status: null,
    statusItems: [
      { text: "Todos", value: "ALL" },
      { text: "Disponibles", value: "AVAILABLE" },
      { text: "No disponibles", value: "UNAVAILABLE" },
    ],
    exportHeaders: ["Mes", "Folio", "Muestras suelo", "Muestras vegetales", "Observaciones","Estatus"],
    user: JSON.parse(localStorage.getItem("user")),
  }),
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("analysis", ["getDataStored"]),
    handleSave() {
      if (this.dates.length === 2) {
        this.$refs.menu.save(this.dates);
      }
    },
    async exportData() {
      try {
        this.isLoading = true;
        let allData = [];
        let page = 1;
        let isFetching = true;
        do {
          let payload = {
            status: this.status.value,
            initialDate: this.dates[0],
            finalDate: this.dates[1],
            page: page,
            laboratory: this.user.laboratory._id,
          };
          let response = await this.getDataStored(payload);
          if (!response) throw response;
          allData.push(...response.analysesFormatted);
          page++;
          isFetching = response.analysesFormatted.length != 0
        } while (isFetching);

        // check if theres some to export
        if (allData.length == 0) {
          this.SET_ALERT_TYPE({
            visible: true,
            type: "info",
            text: "No se encontraron muestras para exportar.",
            timeout: 6000,
          });
        } else {
          let currentDate = this.dateFormat(new Date());
          let fileTitle = `Muestras resguardadas exportadas - ${currentDate}`;
          this.exportCSVFile(this.exportHeaders, allData, fileTitle);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: "Muestras exportadas.",
            timeout: 4000,
          });
          this.dates = [];
          this.status = null;
        }
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: error.message,
          timeout: 4000,
        });
      }
      this.isLoading = false;
    },
    exportCSVFile(headers, items, fileTitle) {
      if (headers) {
        items.unshift(headers);
      }

      // Convert Object to JSON
      var jsonObject = JSON.stringify(items);

      var csv = this.convertToCSV(jsonObject);

      var exportedFilenmae = fileTitle + ".csv" || "export.csv";

      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    convertToCSV(objArray) {
      var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      var str = "";

      for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
          if (line != "") line += ",";

          line += array[i][index];
        }

        str += line + "\r\n";
      }

      return str;
    },
    dateFormat(value) {
      const date = `${dayjs(value).format("DD-MM-YYYY - HH:mm")} hrs`;
      return date ? date : "----";
    },
  },
};
</script>
