<template>
  <v-dialog
    transition="dialog-top-transition"
    max-width="650"
    :loading="loading"
    v-model="dialog"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
        small
        depressed
        block
        color="primary"
        v-bind="attrs"
        v-on="on"
        :disabled="!hasTests"
      >
        Finalizar
      </v-btn>
      <v-btn
        v-else
        small
        depressed
        block
        color="primary"
        v-bind="attrs"
        v-on="on"
        :disabled="!hasTests"
      >
        Finalizar ensayos
      </v-btn>
    </template>
    <template v-slot:default="dialog">
      <v-card v-if="tests.length > 0">
        <v-toolbar color="primary" dark
          >Finalizar ensayos microbiológicos</v-toolbar
        >
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12"> Análisis microbiológicos / resiembras: </v-col>
            </v-row>
            <v-row class="mt-2 pr-5">
              <v-col cols="2" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-caption text-center mb-0 font-weight-black">
                    ID análisis
                  </p>
                </v-row>
              </v-col>
              <v-col cols="2" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-caption text-center mb-0 font-weight-black">
                    ID ensayo
                  </p>
                </v-row>
              </v-col>
              <v-col cols="2" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-caption text-center mb-0 font-weight-black">
                    Medio
                  </p>
                </v-row>
              </v-col>
              <v-col cols="3" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-caption text-center mb-0 font-weight-black">
                    Inóculo
                  </p>
                </v-row>
              </v-col>
              <v-col cols="3" class="px-0 pb-0">
                <v-row justify="center">
                  <p class="text-caption text-center mb-0 font-weight-black">
                    Tipo de análisis
                  </p>
                </v-row>
              </v-col>
            </v-row>
            <template v-for="test in tests">
              <v-row
                class="mt-6 pr-5"
                :key="test.idTest"
                v-if="
                  (test.analysisType != 'Resiembra' &&
                    test.analysisType != 'Control' &&
                    (test.status.includes('Identificando') ||
                      (test.status.includes('Analizando') &&
                        test.mbTestData &&
                        test.mbTestData.strains.length > 0) ||
                      test.bcTestData) &&
                    test.status.includes('Identificando')) ||
                  test.status.includes('Analizando') ||
                  test.status.includes('Identificando') ||
                  test.status.includes('Analizando')
                "
              >
                <v-col cols="2" class="px-0 pb-0">
                  <v-row justify="center">
                    <p class="text-caption text-center mb-0">
                      {{ test.folioAnalysis }}
                    </p>
                  </v-row>
                </v-col>
                <v-col cols="2" class="px-0 pb-0">
                  <v-row justify="center">
                    <p class="text-caption text-center mb-0">
                      {{ test.idTest }}
                    </p>
                  </v-row>
                </v-col>
                <v-col cols="2" class="px-0 pb-0">
                  <v-row justify="center">
                    <p class="text-caption text-center mb-0">
                      {{ test.medium }}
                    </p>
                  </v-row>
                </v-col>
                <v-col cols="3" class="px-0 pb-0">
                  <v-row justify="center">
                    <p
                      class="text-caption text-center mb-0"
                      v-if="test.inoculum != 'confrontation'"
                    >
                      {{ test.inoculum }}
                    </p>
                    <p class="text-caption text-center mb-0" v-else>
                      {{ test.inoculumControl }} vs
                      {{ test.inoculumBeneficial }}
                    </p>
                  </v-row>
                </v-col>
                <v-col cols="3" class="px-0 pb-0">
                  <v-row justify="center">
                    <p class="text-caption text-center mb-0">
                      {{ test.analysisType }}
                    </p>
                  </v-row>
                </v-col>
              </v-row>
            </template>
          </v-container>
        </v-card-text>

        <v-card-actions class="justify-end mt-5">
          <v-btn text color="warning" @click="dialog.value = false"
            >Cancelar</v-btn
          >
          <v-btn text color="secondary" @click="endTests" :loading="loading"
            >Finalizar</v-btn
          >
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      hasTests: false,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  props: {
    tests: {
      type: Array,
      require: true,
    },
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("isolate", ["updateIsolate"]),
    ...mapActions("antagonism", ["updateAntagonismTest"]),
    ...mapActions("nutrition", ["updateNutritionTest"]),
    ...mapActions("bioStimulation", ["updateBiostimulationTest"]),
    ...mapActions("microbiologicalTest", ["updateMBTest"]),
    async endTests() {
      this.loading = true;
      let tests = this.tests.filter(
        (test) =>
          (test.analysisType != "Resiembra" &&
            test.analysisType != "Control" &&
            (test.status.includes("Identificando") ||
              (test.status.includes("Analizando") &&
                test.mbTestData &&
                test.mbTestData.strains.length > 0) ||
              (test.bcTestData && test.status.includes("Identificando")) ||
              test.status.includes("Analizando"))) ||
          test.status.includes("Identificando") ||
          test.status.includes("Analizando")
      );

      if (tests.length > 0) {
        let testsForm = tests.map((test) => {
          let form = null;

          if (
            test.analysisType == "Resiembra" ||
            test.analysisType == "Control"
          ) {
            form = {
              _id: test.isolate._id,
              status: "COMPLETED",
              type: "Isolate",
              user : this.user._id,
            };
          } else if (
            test.analysisType == "Hongo" ||
            test.analysisType == "Bacteria" ||
            test.analysisType == "Todos"
          ) {
            form = {
              _id: test.mbTestData._id,
              status: "COMPLETED",
              type: "MB Test",
              user : this.user._id,
            };
          } else {
            form = {
              _id: test._id,
              status: "COMPLETED",
              analysisType: test.analysisType,
              type: "BQ Test",
              user : this.user._id,
            };
          }
          return form;
        });

        try {
          console.log(testsForm);
          for (const test of testsForm) {
            if (test.type == "Isolate") {
              const response = await this.updateIsolate(test);
              if (response.error) {
                throw response;
              }
            } else if (test.type == "MB Test") {
              const response = await this.updateMBTest(test);
              if (response.error) {
                throw response;
              }
            } else if (test.type == "BQ Test") {
              if (test.analysisType.includes("S.")) {
                const response = await this.updateNutritionTest(test);
                if (response.error) {
                  throw response;
                }
              } else if (
                test.analysisType.includes("P.") ||
                test.analysisType.includes("A.")
              ) {
                const response = await this.updateBiostimulationTest(test);
                if (response.error) {
                  throw response;
                }
              } else {
                const response = await this.updateAntagonismTest(test);
                if (response.error) {
                  throw response;
                }
              }
            }
          }

          this.$emit("testSaved");
          this.dialog = false;
          this.loading = false;

          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: "Análisis finalizados correctamente",
            timeout: 4000,
          });
        } catch (e) {
          console.log(e);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: "Finalizar análisis, comunicate a soporte técnico para dar seguimiento.",
            timeout: 4000,
          });
          this.loading = false;
        }
      } else {
        this.loading = false;
        this.SET_ALERT_TYPE({
          visible: true,
          type: "warning",
          text: "No hay ensayos por finalizar",
          timeout: 4000,
        });
      }
    },
  },
  watch: {
    tests() {
      this.hasTests = false;

      for (const test of this.tests) {
        if (
          (test.analysisType != "Resiembra" && test.analysisType != "Control" && test.status.includes("Identificando")) ||
          (test.status.includes("Analizando") && test.mbTestData && test.mbTestData.strains.length > 0) ||
          (test.bcTestData && test.status.includes("Identificando")) || 
          test.status.includes("Analizando") || test.status.includes("Identificando")
        ) {
          this.hasTests = true;
        }
      }
    },
  },
};
</script>

<style></style>
