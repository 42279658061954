<template>
  <div>
    <v-row>
      <v-col class="pa-1">
        <v-card flat class="mb-5 pa-2" min-height="80vh">
          <v-card-text>
            <v-row>
              <v-col cols="12" xl="5" lg="6" md="6">
                <v-text-field
                  outlined
                  dense
                  label="Buscar..."
                  v-model="search"
                  type="text"
                  prepend-inner-icon="mdi-magnify"
                  clearable
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  dense
                  :headers="headers"
                  :items="projects"
                  item-key="metagenomicId"
                  class="elevation-0"
                  fixed-header
                  hide-default-footer
                  no-data-text="No hay proyectos"
                  :page.sync="page"
                  :items-per-page="per_page"
                  :loading="isFetching"
                  height="56vh"
                  @click:row="rowSelect"
                  :item-class="row_classes"
                >
                  <template #[`item.status`]="{ item }">
                    <span>{{ getStatus(item.status) }} </span>
                  </template>
                  <template #[`item.createdAt`]="{ item }">
                    <span>{{ dateFormat(item.createdAt) }} </span>
                  </template>
                  <template #[`item.updatedAt`]="{ item }">
                    <span>{{ dateFormat(item.updatedAt) }} </span>
                  </template>
                  <template #[`item.applications`]="{ item }">
                    <span>{{ item.applications.length }} </span>
                  </template>
                  <template #[`item.products`]="{ item }">
                    <span>{{ item.products.length }} </span>
                  </template>
                  <template #[`item.variant`]="{ item }">
                    <span>{{ getVariant(item.variant) }} </span>
                  </template>
                  <template #[`item.crop`]="{ item }">
                    <span>{{ getCrop(item.crop) }} </span>
                  </template>
                  <template #[`item.client`]="{ item }">
                    <span
                      v-if="
                        item.client.billingInformation &&
                        item.client.billingInformation.companyName.length != 0
                      "
                    >
                      {{ item.client.billingInformation.companyName }}
                    </span>
                    <span v-else>
                      {{ item.client.companyName }}
                    </span>
                  </template>
                </v-data-table>
              </v-col>
              <v-col>
                <v-pagination
                  v-model="page"
                  :length="numPages"
                  :total-visible="per_page"
                  color="primary"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
//vuex
import { mapActions, mapMutations, mapState } from "vuex";
//Libraries
import dayjs from "dayjs";
export default {
  data: () => ({
    projects: [],
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "metagenomicId",
      },
      {
        text: "Estatus",
        align: "start",
        sortable: false,
        value: "status",
      },
      {
        text: "Nombre",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Agricola", value: "client" },
      { text: "Cultivo", value: "crop" },
      { text: "variante", value: "variant" },
      { text: "Número de productos", value: "products", align: "center" },
      { text: "Aplicaciones", value: "applications", align: "center" },
      { text: "Creación", value: "createdAt" },
      { text: "Ultima actualización", value: "updatedAt" },
    ],
    per_page: 10,
    page: 1,
    numPages: 1,
    sort: { createdAt: -1 },
    search: null,
    isFetching: false,
    filter: {
      status: null,
      crop: null,
      variant: null,
    },
  }),
  async mounted() {
    await this.fetchData();
  },
  methods: {
    ...mapActions("project", ["getProjects"]),
    ...mapMutations("project", ["SET_PROJECT"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async fetchData() {
      try {
        this.isFetching = true;
        let payload = {
          filter: this.filter,
          page: this.page,
          per_page: this.per_page,
          sort: this.sort,
          search: this.search,
        };
        let response = await this.getProjects(payload);
        this.projects = response.projects;
        this.numPages = response.pages;
        if (!this.$vuetify.breakpoint.mdAndDown) {
          if (this.projects.length > 0) {
            this.projects[0].clicked = true;
            this.SET_PROJECT(this.projects[0]);
          } else {
            this.SET_PROJECT(null);
          }
        }
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
      this.isFetching = false;
    },
    dateFormat(value) {
      const date = `${dayjs(value).format("DD/MM/YYYY - HH:mm")} hrs`;
      return date || "--/--/----";
    },
    getVariant(variant) {
      let variants = {
        UNCONTRASTED: "Sin contraste",
        CONTRAST: "Contraste",
      };
      return variants[variant] || variant;
    },
    getCrop(value) {
      let template = "";
      const crop = {
        olive: "Aceituna",
        chard: "Acelga",
        achiote: "Achiote",
        agave: "Agave",
        avocado: "Aguacate",
        garlic: "Ajo",
        sesame: "Ajonjolí",
        basil: "Albahaca",
        artichoke: "Alcachofa",
        alcatraz: "Alcatraz",
        shrunkenAlfalfa: "Alfalfa achicalada",
        greenAlfalfa: "Alfalfa verde",
        cotton: "Algodón",
        almond: "Almendro",
        birdseed: "Alpiste",
        amaranth: "Amaranto",
        anise: "Anís",
        celery: "Apio",
        blueberry: "Arándano",
        myrtle: "Arrayán",
        rice: "Arroz",
        greenForageOats: "Avena forrajera en verde",
        oatGrain: "Avena grano",
        bamboo: "Bambú",
        eggplant: "Berenjena",
        beetroot: "Betabel",
        broccoli: "Brocoli",
        peanut: "Cacahuate",
        cocoa: "Cacao",
        cherryCoffee: "Café cereza",
        pumpkin: "Calabaza",
        sweetPotato: "Camote",
        sugarCane: "Caña de azúcar",
        canola: "Canola",
        carambolo: "Carambolo",
        safflower: "Cártamo",
        barley: "Cebada",
        greenForageBarley: "Cebada forrajera en verde",
        onion: "Cebolla",
        chives: "Cebollín",
        ryeGrain: "Centeno grano",
        cherry: "Cereza",
        chabacano: "Chabacano",
        pea: "Chícharo",
        squash: "Chayote",
        chia: "Chía",
        chilacayote: "Chilacayote",
        chillyPepper: "Chile / Pimiento",
        custardApple: "Chirimoya",
        coriander: "Cilantro",
        plum: "Ciruela",
        coconutFruit: "Coco fruta",
        cabbage: "Col (repollo)",
        brusselsSprouts: "Col de Bruselas",
        cauliflower: "Coliflor",
        cumin: "Comino",
        chrysanthemum: "Crisantemo (planta)",
        peach: "Durazno",
        date: "Dátil",
        greenBean: "Ejote",
        cornCob: "Elote",
        dill: "Eneldo",
        epazote: "Epazote",
        asparagus: "Espárrago",
        spicesAndMedicinals: "Especias y medicinales",
        spinach: "Espinaca",
        raspberry: "Frambuesa",
        strawberry: "Fresa",
        bean: "Frijol",
        chickpea: "Garbanzo",
        sunflower: "Girasol",
        pomegranate: "Granada",
        soursop: "Guanábana",
        guava: "Guayaba",
        henequen: "Henequén",
        peppermint: "Hierbabuena",
        aromaticHerbs: "Hierbas aromáticas",
        fig: "Higo",
        jamaica: "Jamaica",
        ginger: "Jenjibre",
        jicama: "Jícama",
        lettuce: "Lechuga",
        lentil: "Lenteja",
        lime: "Lima",
        lemon: "Limón",
        linseed: "Linaza",
        litchi: "Litchi",
        corn: "Maíz",
        macadamia: "Macadamia",
        maguey: "Maguey",
        mamey: "Mamey",
        tangerine: "Mandarina",
        mango: "Mango",
        mangosteen: "Mangostán",
        chamomile: "Manzanilla",
        appleTree: "Manzano",
        passionFruit: "Maracuyá",
        daisyFlower: "Margarita",
        marjoram: "Mejorana",
        melon: "Melón",
        quince: "Membrillo",
        mint: "Menta",
        moringa: "Moringa",
        turnip: "Nabo",
        nanche: "Nanche",
        napa: "Napa",
        orange: "Naranja",
        medlar: "Níspero",
        nochebuena: "Nochebuena (planta)",
        walnut: "Nogal",
        noni: "Noni",
        nopal: "Nopal",
        oregano: "Orégano",
        ornamental: "Ornamentales",
        orchid: "Orquídea (planta)",
        oilPalm: "Palma africana o de aceite",
        potato: "Papa",
        papaya: "Papaya",
        grasslands: "Pastizales",
        matGrass: "Pasto tapete (m2)",
        pasturesAndMeadows: "Pastos y praderas",
        cucumber: "Pepino",
        pearTree: "Peral",
        parsley: "Perejil",
        peron: "Perón",
        persimony: "Persimonio",
        pepper: "Pimienta",
        pineapple: "Piña",
        pinion: "Piñón",
        pistache: "Pistache",
        pitaya: "Pitaya",
        banana: "Plátano",
        pore: "Poro",
        radish: "Rábano",
        rambutan: "Rambután",
        romerito: "Romerito",
        rosemary: "Romero",
        aloe: "Sábila",
        watermelon: "Sandía",
        sorghum: "Sorgo",
        soy: "Soya",
        stevia: "Stevia",
        tobacco: "Tabaco",
        tamarind: "Tamarindo",
        tejocote: "Tejocote",
        tomato: "Tomate Rojo (jitomate)",
        greenTomato: "Tomate Verde",
        thyme: "Tomillo",
        grapefruit: "Toronja (pomelo)",
        clover: "Trébol",
        wheat: "Trigo",
        dutchTulip: "Tulipán holandés",
        tuna: "Tuna",
        grape: "Uva",
        vanilla: "Vainilla",
        purslane: "Verdolaga",
        vine: "Vid",
        cassavaFood: "Yuca alimenticia",
        carrot: "Zanahoria",
        sapodilla: "Zapote",
        blackberry: "Zarzamora",
        zempoalxochitl: "Zempoalxochitl",
        fallow: "Barbecho",
        other: "Otro",
      };
      let cropName = crop[value];
      if (cropName) {
        template = cropName;
      } else {
        template = "Sin cultivo";
      }
      return template;
    },
    getStatus(status) {
      let statusEnum = {
        NEW: "Nuevo",
        IN_PROGRESS: "En progreso",
        FINISHED: "Finalizado",
      };
      return statusEnum[status] || status;
    },
    async rowSelect(item) {
      this.projects = this.projects.map((data) => {
        if (item._id == data._id) {
          data.clicked = true;
        } else {
          data.clicked = false;
        }
        return data;
      });
      this.SET_PROJECT(item);
    },
    row_classes(value) {
      if (value.clicked) {
        return "custom-highlight-row";
      }
    },
  },
  computed: {
    ...mapState("project", ["projectSelected"]),
  },
  watch: {
    async search() {
      this.page = 1;
      this.numPages = 1;
      await this.fetchData();
    },
    async page() {
      await this.fetchData();
    },
    projectSelected(value) {
      if (!value) {
        this.projects = this.projects.map((data) => {
          data.clicked = false;

          return data;
        });
      }
    },
  },
  beforeDestroy() {
    this.SET_PROJECT(null);
  },
};
</script>
<style scoped>
.fill-height {
  height: 100%;
}
.custom-highlight-row {
  background-color: rgba(4, 193, 5, 0.13);
  font-weight: bold;
}
</style>
