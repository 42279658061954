<template>
  <v-row class="px-0 fill-height">
    <template>
      <div class="text-center">
        <v-dialog
          v-model="dialogEvidence"
          width="50vw"
          persistent
          v-if="dialogEvidence"
        >
          <v-card height="auto">
            <v-card-text class="px-0 py-0">
              <v-carousel
                height="auto"
                hide-delimiter-background
                show-arrows-on-hover
                v-if="formData.length > 0"
              >
                <v-carousel-item
                  v-for="(item, i) in formData"
                  :key="i"
                  transition="slide-x-transition"
                >
                  <v-row>
                    <v-col cols="12" xl="4" lg="4" md="4">
                      <v-btn
                        color="red"
                        class="my-2 mx-2"
                        dark
                        @click="deleteEvidence(i)"
                        block
                        >Eliminar</v-btn
                      >
                    </v-col>
                    <v-col cols="12" xl="4" lg="4" md="4">
                      <v-btn
                        color="primary"
                        class="my-2 mx-2"
                        dark
                        @click="showImageTagger(item.img, i, item.tags)"
                        block
                        >Añadir etiquetas</v-btn
                      >
                    </v-col>
                    <v-col
                      cols="12"
                      xl="4"
                      lg="4"
                      md="4"
                      class="justify-center d-flex align-center"
                    >
                      <span class="black--text">{{
                        dateFormat(item.date)
                      }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align="center">
                      <ImageTagger
                        :imageUrl="item.img"
                        :isEditable="false"
                        :incomingTags="item.tags || []"
                      />
                      {{ item.tags }}
                    </v-col>
                  </v-row>
                </v-carousel-item>
              </v-carousel>
              <v-row class="text-center">
                <v-col class="d-flex justify-center">
                  <v-img
                    :src="require('@/assets/img/add_image_icon.png')"
                    max-height="250"
                    max-width="250"
                    contain
                    @click="showCaputre = true"
                    v-if="formData.length == 0"
                  ></v-img>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="warning" @click="dialogEvidence = false">
                Cerrar
              </v-btn>
              <!-- <v-btn text color="success" @click="$refs.file.click()"> -->
              <v-btn color="success" @click="showCaputre = true">
                Agregar evidencia
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <v-col cols="12">
      <v-card elevation="0" class="rounded-lg">
        <v-row align="center">
          <v-col cols="12" xl="2" lg="2" md="2" sm="4" class="hidden-xs-only">
            <v-container>
              <v-row class="d-flex justify-center">
                <p class="font-weight-bold mb-0">
                  {{ registered.toFixed(0) }}
                </p>
              </v-row>
              <v-row class="d-flex justify-center">
                <p class="font-weight-bold mb-0">Muestras registradas</p>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="12" xl="2" lg="2" md="2" sm="4" class="hidden-xs-only">
            <v-container>
              <v-row justify="center">
                <p class="font-weight-bold mb-0">
                  {{ analazing.toFixed(0) }}
                </p>
              </v-row>
              <v-row justify="center">
                <p class="font-weight-bold mb-0">Muestras en análisis</p>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="12" xl="2" lg="2" md="2" sm="4" class="hidden-xs-only">
            <v-container>
              <v-row justify="center">
                <p class="font-weight-bold mb-0">
                  {{ analaized.toFixed(0) }}
                </p>
              </v-row>
              <v-row justify="center">
                <p class="font-weight-bold mb-0">Muestras analizadas</p>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="12" xl="5" lg="5" md="5">
            <v-row>
              <v-col
                cols="6"
                :offset="
                  permissions.find((per) => per == 'lab_transfer_soil') ? 0 : 4
                "
              >
                <template>
                  <v-dialog v-model="recibe" persistent max-width="60vw">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        block
                        rounded
                      >
                        <v-icon dark> mdi-package-check </v-icon>
                        Recibir muestras
                      </v-btn>
                    </template>

                    <v-card class="rounded-lg" elevation="3">
                      <v-container>
                        <v-row class="text-end" justify="end">
                          <v-col cols="12">
                            <v-icon color="black" large @click="cleanList()"
                              >mdi-close</v-icon
                            >
                          </v-col>
                          <v-col cols="12" class="pb-0">
                            <v-btn
                              color="primary"
                              rounded
                              @click="openEvidenceDialog"
                              small
                              >Agregar evidencia</v-btn
                            >
                          </v-col>
                          <v-col
                            cols="12"
                            class="pt-0"
                            v-if="formData.length != 0"
                          >
                            <span style="font-size: 12px" class="mr-1">
                              Imagenes {{ formData.length }}
                            </span>
                          </v-col>
                        </v-row>
                      </v-container>

                      <v-card-title class="justify-center">
                        <span class="text-md-h4 text-lg-h4 text-sm-h5"
                          >Recepción de kit</span
                        >
                      </v-card-title>

                      <v-card-text>
                        <!-- <v-container class="text-center mb-4">
                          <span class="text-md-h6 text-lg-h6 text-sm-body-2">
                            Folio de análisis {{ folioAnalysis }}</span
                          >
                        </v-container> -->
                        <v-container>
                          <v-row>
                            <v-col justify="center" align="center">
                              <v-autocomplete
                                v-model="kitType"
                                dense
                                outlined
                                label="Selecciona el tipo de kit"
                                :items="kitTypes"
                                return-object
                                clearable
                              ></v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-form
                            ref="formContainer"
                            lazy-validation
                            v-on:submit.prevent
                          >
                            <draggable
                              v-model="containersList"
                              group="receptions"
                              @start="drag = true"
                              @end="drag = false"
                            >
                              <div
                                v-for="(container, index) in containersList"
                                :key="index"
                                fluid
                                class="pb-0"
                              >
                                <v-row
                                  class="text-center align-center justify-center"
                                >
                                  <v-col
                                    cols="12"
                                    xl="3"
                                    lg="3"
                                    md="4"
                                    class="mb-2"
                                  >
                                    <span class="mx-2">
                                      Muestra
                                      {{ chartFromIndex(index) }}</span
                                    >
                                    <v-menu
                                      v-model="openMenu"
                                      bottom
                                      :offset-x="true"
                                      :close-on-content-click="false"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          :id="'container_' + index"
                                          maxlength="14"
                                          v-model="container.container"
                                          @input="
                                            verifyContainerForm(
                                              container.container,
                                              index
                                            )
                                          "
                                          @click:clear="clearTextField()"
                                          outlined
                                          dense
                                          required
                                          clearable
                                          :rules="validation()"
                                          :error-messages="
                                            container.error
                                              ? [container.message]
                                              : []
                                          "
                                          autocomplete="off"
                                          v-on="on"
                                          v-on:keyup.enter="handleEnter"
                                          autofocus
                                        ></v-text-field>
                                      </template>

                                      <v-list
                                        v-if="
                                          container.autocomplete &&
                                          items.length > 0
                                        "
                                      >
                                        <v-list-item-group
                                          active-class="border"
                                          color="indigo"
                                        >
                                          <v-list-item
                                            v-for="(item, index) in items"
                                            :key="index"
                                            @click="
                                              listItemClicked(
                                                container.container,
                                                index
                                              )
                                            "
                                          >
                                            <v-list-item-title>{{
                                              item.code
                                            }}</v-list-item-title>
                                          </v-list-item>
                                        </v-list-item-group>
                                      </v-list>
                                    </v-menu>
                                  </v-col>
                                  <v-col
                                    xl="1"
                                    lg="1"
                                    md="1"
                                    class="text-center mb-4"
                                    v-if="containersList.length > 1"
                                  >
                                    <v-icon
                                      @click="deleteContainer(index)"
                                      class="ml-4 mb-6"
                                      color="gray"
                                      fab
                                      dark
                                      >mdi-delete</v-icon
                                    >
                                  </v-col>
                                  <v-col
                                    xl="2"
                                    lg="2"
                                    md="2"
                                    class="text-center mb-6"
                                    v-if="container.type"
                                  >
                                    <span class="font-weight-bold">
                                      {{ getType(container.type) }}
                                    </span>
                                  </v-col>
                                  <v-col
                                    xl="1"
                                    lg="1"
                                    md="1"
                                    class="text-center mb-6"
                                    v-if="container.type"
                                  >
                                    <v-icon
                                      color="primary"
                                      @click="
                                        showInformationContainer(
                                          container.container
                                        )
                                      "
                                      >mdi-eye</v-icon
                                    >
                                  </v-col>
                                </v-row>
                              </div>
                            </draggable>

                            <v-row v-if="shouldNotes">
                              <v-col cols="12" class="d-flex justify-center">
                                <v-textarea
                                  id="note"
                                  filled
                                  name="input-7-4"
                                  label="Observaciones del kit"
                                  auto-grow
                                  v-model="notes"
                                ></v-textarea>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-row class="justify-center mb-5">
                          <v-col cols="12" sm="8" md="4">
                            <v-btn
                              :disabled="!addSamples"
                              color="secondary"
                              width="100%"
                              @click="addContainer()"
                              rounded
                            >
                              <span class="text-caption"
                                >Añadir muestras al análisis</span
                              >
                            </v-btn>
                          </v-col>
                          <v-col cols="12" sm="8" md="4">
                            <v-btn
                              :disabled="!addSamples"
                              color="primary"
                              width="100%"
                              rounded
                              @click="dialogAdvice = true"
                            >
                              <span class="text-caption">Continuar</span>
                            </v-btn>

                            <v-row justify="center">
                              <v-dialog
                                v-model="dialogAdvice"
                                persistent
                                max-width="350"
                              >
                                <v-card>
                                  <v-card-title class="text-h5">
                                    Confirmación de recepción
                                  </v-card-title>
                                  <v-card-text>
                                    ¿Estas seguro que desea agregar estas
                                    muestras a recepciones?
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      color="green darken-1"
                                      text
                                      @click="dialogAdvice = false"
                                      v-if="!isAddingReception"
                                    >
                                      Cancelar
                                    </v-btn>
                                    <v-btn
                                      color="green darken-1"
                                      text
                                      @click="addReceptionForm()"
                                      :loading="isAddingReception"
                                    >
                                      Crear
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
              </v-col>
              <v-col
                v-if="permissions.find((per) => per == 'lab_transfer_soil')"
              >
                <template>
                  <v-dialog v-model="send" persistent max-width="60vw">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        block
                        @click="getLaboratories"
                        rounded
                      >
                        <v-icon dark> mdi-truck </v-icon>
                        Transferir muestras
                      </v-btn>
                    </template>

                    <v-card class="rounded-lg" elevation="3">
                      <v-card-title class="justify-center">
                        <span class="text-md-h5 text-lg-h5 text-sm-h5"
                          >Transferencia de muestra</span
                        >
                      </v-card-title>

                      <v-card-text>
                        <v-row no-gutters>
                          <v-col cols="12" class="d-flex justify-center">
                            <v-autocomplete
                              v-model="lab_selected"
                              outlined
                              dense
                              clearable
                              return-object
                              :error="errorLab.error"
                              :error-messages="errorLab.message"
                              item-text="name"
                              label="Seleccione el laboratorio a enviar"
                              no-data-text="No se encontró laboratorio disponible"
                              :items="laboratories"
                              class="mt-2"
                            ></v-autocomplete>
                          </v-col>
                          <v-col
                            cols="12"
                            class="d-flex justify-center align-start"
                          >
                            <v-autocomplete
                              label="Buscar folio"
                              outlined
                              dense
                              item-text="folio"
                              :search-input.sync="searchFolioSend"
                              :items="foliosSearched"
                              v-model="searchFolio"
                              :loading="isLoadingFolio"
                              hide-no-data
                              :error="errorFolio.error"
                              :error-messages="errorFolio.message"
                              ref="folios"
                              return-object
                              clearable
                            ></v-autocomplete>
                            <v-progress-circular
                              indeterminate
                              color="primary"
                              v-if="isLoadingFolio"
                            ></v-progress-circular>
                            <!-- <v-btn
                              color="primary"
                              rounded
                              class="ml-1"
                              :disabled="!searchFolio"
                              @click="addFolio"
                              >Agregar folio</v-btn
                            > -->
                          </v-col>
                          <!-- <v-col
                            cols="12"
                            class="d-flex justify-center align-start"
                          >
                            <v-text-field
                              type="number"
                              outlined
                              v-model="qtySamples"
                              clearable
                              dense
                              :error="errorQtySamples.error"
                              :error-messages="errorQtySamples.message"
                              label="Total de muestras físicas a enviar"
                            ></v-text-field>
                          </v-col> -->
                          <v-col cols="12" class="d-flex justify-center">
                            <v-chip
                              v-for="(folio, index) in folios"
                              :key="folio._id"
                              @click:close="removeFolio(index)"
                              close
                              class="mx-1"
                            >
                              {{ folio.folio }}
                            </v-chip>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-row class="justify-center align-center mb-5">
                          <v-col cols="12" sm="8" md="4">
                            <v-btn
                              color="secondary"
                              width="100%"
                              rounded
                              :disabled="isSending"
                              @click="closeSendSamples"
                            >
                              <span class="text-caption">Cancelar</span>
                            </v-btn>
                          </v-col>
                          <v-col cols="12" sm="8" md="4">
                            <v-btn
                              color="primary"
                              width="100%"
                              rounded
                              :loading="isSending"
                              @click="sendSamples"
                            >
                              <span class="text-caption">Enviar</span>
                            </v-btn>
                          </v-col>
                          <v-col
                            cols="12"
                            xl="3"
                            lg="3"
                            md="3"
                            v-if="lab_selected"
                          >
                            <v-row no-gutters class="text-start font-italic">
                              <v-col cols="12">
                                <span>{{ lab_selected.name }}</span>
                              </v-col>
                              <v-col cols="12">
                                <span
                                  >{{ lab_selected.city }},
                                  {{ lab_selected.state }},
                                  {{ lab_selected.country }}</span
                                >
                              </v-col>
                              <v-col cols="12">
                                <span>C.P {{ lab_selected.zipCode }}</span>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-tabs v-model="tabs" color="primary" grow class="rounded-lg">
        <v-tab>Muestras en laboratorio</v-tab>
        <v-tab>Muestras en tránsito</v-tab>
        <v-tab v-if="permissions.find((per) => per == 'lab_preserved_soil')"
          >Preservación de suelos</v-tab
        >
        <v-tab-item>
          <SampleReception />
        </v-tab-item>
        <v-tab-item>
          <v-container>
            <SampleTransit />
          </v-container>
        </v-tab-item>
        <v-tab-item
          v-if="permissions.find((per) => per == 'lab_preserved_soil')"
        >
          <v-container>
            <SamplePreservation />
          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-col>

    <template>
      <div class="text-center">
        <v-dialog v-model="dialogResponseResult" width="500" persistent>
          <v-card>
            <v-card-title class="text-h5 primary justify-center white--text">
              Reportes generados
            </v-card-title>

            <!-- add each response -->
            <v-container>
              <v-row>
                <v-col>
                  <v-data-table
                    hide-default-footer
                    :headers="headersResponse"
                    :items="responseList"
                  >
                    <template v-slot:[`item.status`]="{ item }">
                      <span class="text-subtitle-2">{{
                        getStatusResponse(item.status)
                      }}</span>
                    </template>
                    <template v-slot:[`item.error`]="{ item }">
                      <span class="text-subtitle-2">{{
                        getErrorResponse(item.error)
                      }}</span>
                    </template>
                    <template v-slot:[`item.url`]="{ item }">
                      <div v-if="item.url == undefined"></div>
                      <div v-else>
                        <v-btn color="primary" dense :href="item.url">
                          descargar
                        </v-btn>
                      </div>
                      <!-- {{item.sample_type}} -->
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="dialogResponseResult = false">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>

    <div class="text-center ma-2">
      <v-snackbar v-model="showSnack" :timeout="4000">
        Informacion de Muestra
      </v-snackbar>
    </div>
    <v-dialog v-model="dialogInformationSample" max-width="500" persistent>
      <v-card v-if="inforCont != null" class="rounded-lg" elevation="3">
        <v-card-title class="text-center headline">
          <v-row>
            <v-col>Detalle de muestra </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-5" no-gutters>
            <v-col cols="12">
              <v-label>Tipo de muestra: </v-label>
              <span class="primary--text">{{ getSampleType(inforCont) }}</span>
            </v-col>
            <v-col cols="12" class="mt-2">
              <v-label>Unidad de riego: </v-label>
              <span class="primary--text">{{ inforCont.name }}</span>
            </v-col>
            <v-col cols="12" class="mt-2">
              <v-label>Cultivo: </v-label>
              <span
                class="primary--text"
                v-if="inforCont.cropCycle.length > 0"
                >{{ getCrop(inforCont.cropCycle) }}</span
              >
              <span class="primary--text" v-else>Sin cultivo</span>
            </v-col>
            <v-col cols="12" class="mt-2">
              <v-label>Fecha de toma: </v-label>
              <span class="primary--text">{{
                dateFormat(inforCont.endDate)
              }}</span>
            </v-col>
            <v-col cols="12" class="mt-2">
              <v-label>Muestreador: </v-label>
              <span class="primary--text">{{ inforCont.sampler }}</span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeInfoContainer()"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogUpdateFolio"
      persistent
      max-width="70%"
      v-if="showUpdateFolioDialog"
    >
      <v-card class="rounded-lg" elevation="3">
        <v-container>
          <v-row class="text-end" justify="end">
            <v-col cols="12">
              <v-icon color="black" large @click="closeUpdateFolioDialog()"
                >mdi-close</v-icon
              >
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-btn color="primary" rounded @click="openEvidenceDialog" small
                >Ver evidencia</v-btn
              >
            </v-col>
            <v-col cols="12" class="pt-0" v-if="formData.length != 0">
              <span style="font-size: 12px" class="mr-1">
                Imagenes {{ formData.length }}
              </span>
            </v-col>

            <v-col cols="12" xl="7" lg="4" md="7">
              <v-autocomplete
                v-model="kitType"
                dense
                outlined
                label="Selecciona el tipo de kit"
                :items="kitTypes"
                return-object
                clearable
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>

        <v-card-title class="justify-center">
          <span class="text-md-h4 text-lg-h4 text-sm-h5">Actualizar folio</span>
        </v-card-title>

        <v-card-text>
          <v-container class="text-center mb-4">
            <span
              class="text-md-h6 text-lg-h6 text-sm-body-2"
              color="black"
              v-if="folioSelected"
            >
              Folio de análisis {{ folioSelected.folio }}</span
            >
          </v-container>
          <v-form ref="formContainer" lazy-validation v-on:submit.prevent>
            <draggable
              v-model="containerListUpdate"
              group="receptions"
              @start="drag = true"
              @end="drag = false"
            >
              <div
                v-for="(container, index) in containerListUpdate"
                :key="index"
                fluid
                class="pb-0"
              >
                <v-row class="text-center align-center justify-center">
                  <v-col cols="12" xl="3" lg="3" md="4" class="mb-2">
                    <span class="mx-2">
                      Muestra {{ chartFromIndex(index) }}</span
                    >
                    <v-menu
                      v-model="openMenu"
                      bottom
                      :offset-x="true"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :id="'container_' + index"
                          maxlength="14"
                          v-model="container.container"
                          @input="
                            verifyContainerFormUpdate(
                              container.container,
                              index
                            )
                          "
                          @click:clear="clearTextField()"
                          outlined
                          dense
                          required
                          clearable
                          :rules="validation()"
                          :error-messages="
                            container.error ? [container.message] : []
                          "
                          autocomplete="off"
                          v-on="on"
                          v-on:keyup.enter="handleEnter"
                          autofocus
                        ></v-text-field>
                      </template>

                      <v-list
                        v-if="
                          items && container.autocomplete && items.length > 0
                        "
                      >
                        <v-list-item-group active-class="border" color="indigo">
                          <v-list-item
                            v-for="(item, index) in items"
                            :key="index"
                            @click="
                              listItemClickedUpdate(container.container, index)
                            "
                          >
                            <v-list-item-title>{{
                              item.code
                            }}</v-list-item-title>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                  </v-col>
                  <v-col
                    xl="1"
                    lg="1"
                    md="1"
                    class="text-center mb-4"
                    v-if="containerListUpdate.length > 1"
                  >
                    <v-icon
                      @click="deleteContainer(index)"
                      class="ml-4 mb-6"
                      color="gray"
                      fab
                      dark
                      >mdi-delete</v-icon
                    >
                  </v-col>
                  <v-col
                    xl="2"
                    lg="2"
                    md="2"
                    class="text-center mb-6"
                    v-if="container.type"
                  >
                    <span class="font-weight-bold">
                      {{ getType(container.type) }}
                    </span>
                  </v-col>
                  <v-col
                    xl="1"
                    lg="1"
                    md="1"
                    class="text-center mb-6"
                    v-if="container.type"
                  >
                    <v-icon
                      color="primary"
                      @click="showInformationContainer(container.container)"
                      >mdi-eye</v-icon
                    >
                  </v-col>
                </v-row>
              </div>
            </draggable>

            <v-row v-if="shouldNotes">
              <v-col cols="12" class="d-flex justify-center">
                <v-textarea
                  id="note"
                  filled
                  name="input-7-4"
                  label="Observaciones del kit"
                  auto-grow
                  v-model="notes"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row class="justify-center mb-5">
            <v-col cols="12" sm="8" md="4" v-if="showReceiber">
              <v-btn
                :disabled="!addSamples"
                color="secondary"
                width="100%"
                @click="addContainer()"
                rounded
              >
                <span class="text-caption">Añadir muestras al análisis</span>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="8" md="4" v-if="showReceiber">
              <v-btn
                :disabled="!addSamples"
                color="primary"
                width="100%"
                @click="showAdviceUpdate"
                rounded
              >
                <span class="text-caption">Continuar</span>
              </v-btn>

              <v-row justify="center">
                <v-dialog v-model="dialogAdvice2" persistent max-width="350">
                  <v-card>
                    <v-card-title class="text-h5">
                      Confirmación de recepción
                    </v-card-title>
                    <v-card-text>
                      <span v-if="!showUpdateFolioDialog">
                        ¿Estas seguro(a) que desea agregar estas muestras a
                        recepciones?
                      </span>
                      <span v-else>
                        ¿Estas seguro(a) que desea actualizar este folio?
                      </span>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="dialogAdvice2 = false"
                        v-if="!isAddingReception"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="addReceptionForm()"
                        :loading="isAddingReception"
                      >
                        <span v-if="!showUpdateFolioDialog"> Crear </span>
                        <span v-else> Actualizar </span>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              sm="8"
              md="4"
              v-if="
                folioSelected && folioSelected.statusReception === 'PENDING'
              "
            >
              <v-btn
                :disabled="!addSamples"
                color="info"
                width="100%"
                @click="validateSamples()"
                rounded
              >
                <span class="text-caption">Validar muestras</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="8"
              md="4"
              v-else-if="
                folioSelected && folioSelected.statusReception === 'SEND'
              "
            >
              <v-btn
                :disabled="!addSamples"
                @click="reciveFolio()"
                color="primary"
                width="100%"
                rounded
              >
                <span class="text-caption">Recibir muestras</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <CaptureImagesModal
      v-if="showCaputre"
      @close="closePhoto"
      @files="saveFiles"
    />
    <TaggerModal
      v-model="showTaggerModal"
      :imageUrl="imgToTagger"
      v-if="showTaggerModal"
      @evidenceTags="loadTags"
      :incomingTags="tags"
    />
  </v-row>
</template>

<script>
// Validations
import {
  requiredContainerValidation,
  requiredValidation,
} from "@/helpers/validations.js";
//Libraries
import dayjs from "dayjs";
//pdf viewer
import VuePdfApp from "vue-pdf-app";
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css";
import axios from "axios";
import gsap from "gsap";
import Compressor from "compressorjs";
//Vuex
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
//components
import SampleTransit from "@/components/reception/SampleTransit.vue";
import SampleReception from "@/components/reception/SampleReception.vue";
import SamplePreservation from "@/components/reception/SamplePreservation.vue";
import CaptureImagesModal from "@/components/reception/modals/CaptureImagesModal.vue";
import draggable from "vuedraggable";
import TaggerModal from "@/components/reception/modals/TaggerModal.vue";
import ImageTagger from "@/components/utils/ImageTagger.vue";
export default {
  components: {
    VuePdfApp,
    SampleTransit,
    SampleReception,
    draggable,
    CaptureImagesModal,
    TaggerModal,
    ImageTagger,
    SamplePreservation,
  },
  data: () => ({
    currentImgIndexToTaggin: null,
    tags: [],
    showTaggerModal: false,
    imgToTagger: null,
    //qtySamples: 0,
    isSending: false,
    errorLab: { error: false, message: "" },
    errorFolio: { error: false, message: "" },
    errorQtySamples: { error: false, message: "" },
    isLoadingFolio: false,
    searchFolioSend: null,
    foliosSearched: [],
    searchFolio: null,
    lab_selected: null,
    foliosSelected: [],
    laboratories: [],
    send: false,
    formData: [],
    showCaputre: false,
    dialogEvidence: false,
    dialogUpdateFolio: false,
    isAddingReception: false,
    tabs: null,
    inforCont: null,
    dialogInformationSample: false,
    dialogAdvice: false,
    dialogAdvice2: false,
    closeLoading: false,
    reportKeys: [],
    loadAnalysis: false,
    urlPreview: "",
    dialogT: false,
    dialog: false,
    showSnack: false,
    analysisToGenerateResults: [],
    analysis: null,
    searchAnalysis: null,
    analysisFound: [],
    isLoading: false,
    errorAnalysis: false,
    errorMessage: "",
    selectedParend: [],
    selectedChild: [],
    expanded: [],
    dialogResponseResult: false,
    responseList: [],
    isLoadingGenerate: false,
    showReceiber: true,
    headersResponse: [
      {
        text: "Folio de análisis",
        value: "folio",
        align: "center",
      },
      {
        text: "Estatus analysis",
        value: "status",
        align: "center",
      },
      {
        text: "Generación de reporte",
        value: "error",
        align: "center",
      },
      {
        text: "Reporte",
        value: "url",
        align: "center",
      },
    ],

    page: 1,
    numPages: 0,
    samplesRegistered: 0,
    samplesAnalazing: 0,
    samplesAnalaized: 0,
    registered: 0,
    analazing: 0,
    analaized: 0,
    search: "",
    headers: [
      {
        text: "Folio de análisis",
        value: "folio",
      },
      {
        text: "Código de la muestra",
        value: "qr_code",
      },
      {
        text: "Tipo de muestra",
        value: "sample_type",
      },
      {
        text: "# SM",
        value: "qty_sub_samples",
      },
      {
        text: "Razón social del cliente",
        value: "client_company_name",
      },
      {
        text: "Muestreador",
        value: "sampler",
      },
      {
        text: "Registro en campo",
        value: "end_date",
      },
      ,
      {
        text: "Recepción en laboratorio",
        value: "reception_date",
      },
      {
        text: "Etapa de análisis",
        value: "analysis_status",
      },
      {
        text: "Reporte",
        value: "report",
      },
    ],
    headersChild: [
      {
        text: "Folio de análisis",
        value: "folio",
      },
      {
        text: "Código de la muestra",
        value: "qr_code",
      },
      {
        text: "Tipo de muestra",
        value: "sample_type",
      },
      {
        text: "# SM",
        value: "qty_sub_samples",
      },
      {
        text: "Razón social del cliente",
        value: "client_company_name",
      },
      {
        text: "Muestreador",
        value: "sampler",
      },
      {
        text: "Registro en campo",
        value: "end_date",
      },
      ,
      {
        text: "Recepción en laboratorio",
        value: "reception_date",
      },
      {
        text: "Etapa de análisis",
        value: "analysis_status",
      },
    ],
    headersAnalysis: [
      {
        text: "Folio de análisis",
        value: "folio",
      },
      {
        text: "Estatus",
        value: "status",
      },
      {
        text: "Tipo de analisis",
        value: "type",
      },
      {
        text: "Ultima modificaciòn",
        value: "updatedAt",
      },
      {
        text: "Vista previa reporte",
        value: "report_view",
      },
    ],
    receptionsList: [],
    recibe: false,
    containersList: [
      {
        char: "A",
        container: "",
        error: false,
        message: "",
        type: "",
        autocomplete: false,
      },
    ],
    // folioAnalysis: 0,
    containerListUpdate: [],
    counterContainer: "A",
    notes: "",
    shouldNotes: false,
    laboratory: null,
    receptionSelected: null,
    addSamples: false,
    openMenu: false,
    valueMenu: "",
    items: [],
    loadingData: false,
    user: JSON.parse(localStorage.getItem("user")),
    kitTypes: [
      {
        text: "Tradiciónal",
        value: "CLASSIC",
      },
      {
        text: "Híbrido",
        value: "HYBRID",
      },
      {
        text: "QPCR",
        value: "QPCR",
      },
      {
        text: "Otro",
        value: "OTHER",
      },
    ],
    kitType: null,
  }),
  watch: {
    samplesRegistered(n) {
      gsap.to(this, { duration: 0.5, registered: Number(n) || 0 });
    },
    samplesAnalazing(n) {
      gsap.to(this, { duration: 0.5, analazing: Number(n) || 0 });
    },
    samplesAnalaized(n) {
      gsap.to(this, { duration: 0.5, analaized: Number(n) || 0 });
    },
    analysis() {
      if (this.analysis != null) {
        if (this.analysis.isReady) {
          this.errorAnalysis = false;
          this.errorMessage = "";
        } else {
          this.errorAnalysis = true;
          this.errorMessage = this.analysis.message;
        }
      } else {
        this.errorAnalysis = false;
        this.errorMessage = "";
      }
    },
    containersList: {
      handler: function (val) {
        for (let item of this.containersList) {
          if (item.container == null) {
            item.container = "";
          }
        }
      },
      deep: true,
    },
    async page() {
      if (this.page != parseInt(this.$route.params.page)) {
        this.$router.replace({
          params: {
            page: this.page,
          },
        });
      }
      this.loadingData = true;
      await this.getAnalysis();
      this.loadingData = false;
    },
    async search() {
      if (this.search.length > 0) {
        if (typeof this.cancelToken != "undefined") {
          this.cancelToken.cancel("Operation canceled due to new request.");
        }

        //Save the cancel token for the current request
        this.SET_CANCEL_TOKEN(axios.CancelToken.source());

        this.page = 1;

        if (this.page != parseInt(this.$route.params.page)) {
          this.$router.replace({
            params: {
              page: this.page,
            },
          });
        }

        this.receptionsList = [];

        this.loadingData = true;
        let analysesSearched = await this.getReceptions({
          laboratory: this.laboratory,
          page: this.page,
          folio: this.search,
        });

        // let analysesSearched = await this.searchReception({
        //   laboratory: this.laboratory,
        //   char: this.search,
        // });

        if (analysesSearched) {
          this.numPages = analysesSearched.pages;
          let analysis = analysesSearched.analysis;
          for (let item of analysis) {
            if (item.sample_type.length == 0) {
              item.sample_type = "-";
            }
            if (item.client_company_name.length == 0) {
              item.client_company_name = "-";
            }

            if (item.sampler.length == 0) {
              item.sampler = "-";
            }

            if (item.end_date != null) {
              item.end_date = this.dateFormat(item.end_date);
            } else {
              item.end_date = "-";
            }

            item.reception_date = this.dateFormat(item.reception_date);

            item.analysis_status = this.getStatus(item.analysis_status);
          }

          this.receptionsList = analysis;
        }

        this.loadingData = false;
      } else {
        await this.getAnalysis();
      }
    },
    async showUpdateFolioDialog(value) {
      if (value) {
        for (
          let index = 0;
          index < this.folioSelected.receptions.length;
          index++
        ) {
          this.formData = this.folioSelected.evidence
            ? this.folioSelected.evidence
            : [];
          this.containerListUpdate.push({
            char: this.chartFromIndex(index),
            container: this.folioSelected.receptions[index].container.code,
            type: this.folioSelected.receptions[index].container.sample
              ? this.folioSelected.receptions[index].container.sample
                  .tracingStatus
              : "",
            message: "",
            error: false,
            autocomplete: false,
          });
          this.notes = this.folioSelected.notes;
          this.kitType = this.folioSelected.kitType;
          await this.verifyContainerFormUpdate(
            this.folioSelected.receptions[index].container.code,
            index
          );
        }

        this.addSamples = true;
        this.dialogUpdateFolio = true;
      }
    },
    async searchFolioSend(value) {
      if (value) {
        this.isLoadingFolio = true;
        try {
          if (typeof this.cancelToken != "undefined") {
            this.cancelToken.cancel("Operation canceled due to new request.");
          }
          //Save the cancel token for the current request
          this.SET_CANCEL_TOKEN(axios.CancelToken.source());
          let payload = {
            page: 1,
            search: value,
            laboratory: this.user.laboratory._id,
          };

          let response = await this.getReceptions2(payload);
          if (response) {
            if (!response.error) {
              //set sample data
              this.isLoadingFolio = false;
              this.foliosSearched = response.analysis;
            } else {
              throw response;
            }
          }
        } catch (e) {
          this.isLoadingFolio = false;
          console.log(e);
        }
      }
    },
    lab_selected(value) {
      if (value) {
        this.errorLab.error = false;
        this.errorLab.message = "";
      }
    },
    searchFolio(value) {
      if (value) {
        let list = this.folios.filter(
          (element) => element.folio == this.searchFolio.folio
        );

        if (list.length == 0) {
          this.PUSH_FOLIOS_SELECTED(this.searchFolio);
          this.errorFolio.error = false;
          this.errorFolio.message = "";
        }
        this.searchFolio = null;
        this.$refs["folios"].reset();
      }
    },
    folioSelected(value) {
      if (value) {
        if (value.statusReception === "SEND") {
          this.showReceiber = false;
        }
      } else {
        this.showReceiber = true;
      }
    },
    // qtySamples(value) {
    //   if (value <= 0) {
    //     this.errorQtySamples.error = true;
    //     this.errorQtySamples.message = "Campo obligatorio";
    //   } else {
    //     this.errorQtySamples.error = false;
    //     this.errorQtySamples.message = "";
    //   }
    // },
  },
  async created() {
    let user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : "";
    if (
      user.laboratory &&
      user.laboratory.type &&
      user.laboratory.type == "MOLECULAR"
    ) {
      this.kitTypes = [
        {
          text: "Híbrido",
          value: "HYBRID",
        },
        {
          text: "QPCR",
          value: "QPCR",
        },
      ];
    } else if (
      user.laboratory &&
      user.laboratory.type &&
      user.laboratory.type == "MICROBIOLOGICAL/MOLECULAR"
    ) {
      this.kitTypes = [
        {
          text: "Híbrido",
          value: "HYBRID",
        },
        {
          text: "QPCR",
          value: "QPCR",
        },
        {
          text: "Secuenciación",
          value: "SEQUENCING",
        },
      ];
    } else {
      this.kitTypes = [
        {
          text: "Tradiciónal",
          value: "CLASSIC",
        },
        {
          text: "Híbrido",
          value: "HYBRID",
        },
        {
          text: "QPCR",
          value: "QPCR",
        },
        {
          text: "Otro",
          value: "OTHER",
        },
      ];
    }
    this.handleRouteByConfigUser(user);
    this.laboratory = user.laboratory._id;
    this.loadingData = true;
    try {
      // this.page = parseInt(this.$route.params.page, 10);
      // await this.getAnalysis();
      // this.loadingData = false;
      let { samplesReceived, samplesAnalysis, samplesAnalyzed } =
        await this.getSamplesStats(this.laboratory);

      this.registered = samplesReceived;
      this.analazing = samplesAnalysis;
      this.analaized = samplesAnalyzed;
    } catch (error) {
      this.loadingData = false;
      console.log(`REQUEST ERROR ${error}`);
    }
  },
  computed: {
    ...mapState(["cancelToken"]),
    ...mapState("reception", [
      "currentSelection",
      "receptions",
      "analysisResults",
      "refreshReceptionTable",
      "showUpdateFolioDialog",
      "folioSelected",
      "folios",
    ]),
    ...mapState("session", ["authenticatedUser", "permissions"]),
    length() {
      return Math.ceil(this.numPages / 12);
    },
  },
  methods: {
    ...mapMutations(["SET_CANCEL_TOKEN"]),
    ...mapMutations(["SET_LOADER"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapMutations("reception", [
      "SET_SELECTED_RECEPTION",
      "SET_ANALYSES_RESULTS",
      "SET_REFRESH_TABLE",
      "SET_FOLIO_SELECTED",
      "SHOW_UPDATE_FOLIO_DIALOG",
      "PUSH_FOLIOS_SELECTED",
      "REMOVE_FOLIOS_SELECTED",
      "SET_FOLIOS_SELECTED",
      "CLEAN_PARENT",
    ]),
    ...mapActions("reception", [
      "verifyContainer",
      "addReception",
      "getReceptions",
      "getContainersFromChars",
      // "getNewFolioAnalysis",
      "getSamplesStats",
      "searchReception",
      "searchValidatedAnalysis",
      "generateResults",
      "generateResultData",
      "deleteTempFiles",
      "getInfoContainer",
      "verifyContainerUpdate",
      "updateReception",
      "validateSample",
      "uploadImage",
      "getLaboratoriesRequest",
      "getReceptions2",
      "sendFolios",
      "receiveFolioRequest",
    ]),
    listItemClicked(containerCode, index) {
      let indexInner = 0;
      for (const container of this.containersList) {
        if (container.container == containerCode) {
          container.container = this.items[index].code;
          this.verifyContainerForm(container.container, indexInner);
          break;
        }
        indexInner++;
      }
    },
    listItemClickedUpdate(containerCode, index) {
      let indexInner = 0;
      for (const container of this.containerListUpdate) {
        if (container.container == containerCode) {
          container.container = this.items[index].code;
          this.verifyContainerFormUpdate(container.container, indexInner);
          break;
        }
        indexInner++;
      }
    },
    // async getReceptionCode() {
    // var numbers = []; // new empty array
    // var min, max, r, n, p;
    // min = 1;
    // max = 50;
    // r = 3; // how many numbers you want to extract
    // for (let i = 0; i < r; i++) {
    //     do {
    //         n = Math.floor(Math.random() * (max - min + 1)) + min;
    //         p = numbers.includes(n);
    //         if (!p) {
    //             numbers.push(n);
    //         }
    //     }
    //     while (p);
    // }
    // let newFolioObj = await this.getNewFolioAnalysis();
    // this.folioAnalysis = newFolioObj.newFolioAnalysis;
    // },
    cleanList() {
      this.items = [];
      this.formData = [];
      this.recibe = false;
      this.notes = "";
      this.shouldNotes = false;
      this.containersList = [
        {
          char: "A",
          container: "",
          message: "",
          error: false,
          autocomplete: false,
        },
      ];
      this.counterContainer = "A";
      this.kitType = null;
    },
    chartFromIndex(index) {
      let initialChart = "A";
      var character = String.fromCharCode(initialChart.charCodeAt(0) + index);
      return character;
    },
    addContainer() {
      if (this.showUpdateFolioDialog) {
        var character = String.fromCharCode(
          this.counterContainer.charCodeAt(0) + 1
        );
        // this.counterContainer = character;
        // console.log(character)
        this.containerListUpdate.push({
          char: character,
          container: "",
          message: "",
          error: false,
          autocomplete: false,
        });

        for (
          let index = 0;
          index < this.containerListUpdate.length - 1;
          index++
        ) {
          const element = this.containerListUpdate[index];
          element.autocomplete = false;
        }

        this.addSamples = false;
        this.items = [];
      } else {
        var character = String.fromCharCode(
          this.counterContainer.charCodeAt(0) + 1
        );
        // this.counterContainer = character;
        // console.log(character)
        this.containersList.push({
          char: character,
          container: "",
          message: "",
          error: false,
          autocomplete: false,
        });

        for (let index = 0; index < this.containersList.length - 1; index++) {
          const element = this.containersList[index];
          element.autocomplete = false;
        }

        this.addSamples = false;
        this.items = [];
      }
    },
    deleteContainer(index) {
      if (this.showUpdateFolioDialog) {
        this.containerListUpdate.splice(index, 1);

        let containersWrong = 0;
        for (const containerData of this.containerListUpdate) {
          if (containerData.container.length != 14) {
            containersWrong++;
          }
        }

        if (containersWrong > 0) {
          this.addSamples = false;
        } else {
          this.addSamples = true;
        }
      } else {
        this.containersList.splice(index, 1);

        let containersWrong = 0;
        for (const containerData of this.containersList) {
          if (containerData.container.length != 14) {
            containersWrong++;
          }
        }

        if (containersWrong > 0) {
          this.addSamples = false;
        } else {
          this.addSamples = true;
        }
      }
    },
    async showInformationContainer(container) {
      try {
        let response = await this.getInfoContainer(container);
        if (response) {
          if (!response.error) {
            this.inforCont = response.container;
            // console.log(this.inforCont);
            this.dialogInformationSample = true;
          } else {
            throw response;
          }
        } else {
          throw response;
        }
      } catch (e) {
        console.log(e);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: e.message,
          timeout: 5000,
        });
      }
    },
    closeInfoContainer() {
      this.inforCont = null;
      this.dialogInformationSample = false;
    },
    clearTextField() {
      this.addSamples = false;
    },
    validation(name) {
      return requiredContainerValidation(name);
    },
    validationField(name) {
      return requiredValidation(name);
    },
    async verifyContainerForm(container, index) {
      if (container != null) {
        this.items = [];
        this.filterItems(container);
        if (container.length > 0 && container.length == 14) {
          let numSameContainers = 0;
          for (const containerValue of this.containersList) {
            if (containerValue.container == container) {
              numSameContainers++;
            }
          }

          if (numSameContainers > 1) {
            this.containersList[this.containersList.length - 1].error = true;
            this.containersList[this.containersList.length - 1].message =
              "El QR está duplicado";
            this.containersList[this.containersList.length - 1].type = "";
          } else {
            try {
              let result = await this.verifyContainer(container);
              console.log(result);
              if (result != null) {
                if (result.exist) {
                  if (result.isRegistered) {
                    //Code has already registered
                    if (result.message == "Container registered on reception") {
                      this.containersList[index].error = true;
                      this.containersList[
                        index
                      ].message = `El contenedor ya se recibió (${result.folioAnalysis})`;
                      this.containersList[index].type = "";
                      this.addSamples = false;
                    } else {
                      console.log(index);
                      console.log(this.containersList);
                      this.containersList[index].error = false;
                      this.containersList[index].message = "";
                      this.containersList[index].type = result.type;
                      this.addSamples = true;
                    }
                  } else {
                    //Code not has registered
                    if (result.message == "Container registered on reception") {
                      this.containersList[index].error = true;
                      this.containersList[
                        index
                      ].message = `La muestra ya se recibió (${result.folioAnalysis})`;
                      this.containersList[index].type = "";
                    } else {
                      this.containersList[index].error = true;
                      this.containersList[index].message = "QR sin registrar";
                      this.addSamples = true;
                      this.containersList[index].type = "";
                    }
                  }

                  this.filterItems(container);
                } else {
                  //Code not exist
                  this.containersList[index].error = true;
                  this.containersList[index].message = "QR no existe";
                  this.addSamples = false;
                }
              } else {
                console.log(`Error in request ${error}`);
              }
            } catch (error) {
              console.log(`Error in request ${error}`);
            }
          }
        } else if (container.length >= 3 && container.length <= 14) {
          await this.searchContainers(container);
        } else {
          //Code not has registered
          this.containersList[index].error = false;
          this.containersList[index].message = "";
          this.containersList[index].type = "";
          this.addSamples = false;
        }
        let error = false;
        for (let item of this.containersList) {
          if (item.error) {
            if (item.message != "El QR está duplicado") error = true;
            break;
          } else {
            error = false;
          }

          if (item.message.includes("La muestra ya se recibió")) {
            this.addSamples = false;
          }
        }

        this.shouldNotes = error;
      } else {
        this.containersList[index].type = "";
      }
    },
    async verifyContainerFormUpdate(container, index) {
      if (container) {
        this.items = [];
        this.filterItemsUpdate(container);
        if (container.length > 0 && container.length == 14) {
          let numSameContainers = 0;
          for (const containerValue of this.containerListUpdate) {
            if (containerValue.container == container) {
              numSameContainers++;
            }
          }

          if (numSameContainers > 1) {
            this.containerListUpdate[
              this.containerListUpdate.length - 1
            ].error = true;
            this.containerListUpdate[
              this.containerListUpdate.length - 1
            ].message = "El QR está duplicado";
            this.containerListUpdate[this.containerListUpdate.length - 1].type =
              "";
          } else {
            try {
              let result = await this.verifyContainerUpdate({
                code: container,
                folio: this.folioSelected.folio,
              });
              if (result != null) {
                if (result.exist) {
                  if (result.isRegistered) {
                    //Code has already registered
                    if (result.message == "Container registered on reception") {
                      this.containerListUpdate[index].error = true;
                      this.containerListUpdate[
                        index
                      ].message = `El contenedor ya se recibió (${result.folioAnalysis})`;
                      this.containerListUpdate[index].type = "";
                      this.addSamples = false;
                    } else {
                      this.containerListUpdate[index].error = false;
                      this.containerListUpdate[index].message = "";
                      this.containerListUpdate[index].type = result.type;
                      this.addSamples = true;
                    }
                  } else {
                    //Code not has registered
                    if (result.message == "Container registered on reception") {
                      this.containerListUpdate[index].error = true;
                      this.containerListUpdate[
                        index
                      ].message = `La muestra ya se recibió (${result.folioAnalysis})`;
                      this.containerListUpdate[index].type = "";
                    } else {
                      this.containerListUpdate[index].error = true;
                      this.containerListUpdate[index].message =
                        "QR sin registrar";
                      this.addSamples = true;
                      this.containerListUpdate[index].type = "";
                    }
                  }

                  this.filterItemsUpdate(container);
                } else {
                  //Code not exist
                  this.containerListUpdate[index].error = true;
                  this.containerListUpdate[index].message = "QR no existe";
                  this.addSamples = false;
                }
              } else {
                console.log(`Error in request ${error}`);
              }
            } catch (error) {
              console.log(`Error in request ${error}`);
            }
          }
        } else if (container.length >= 3 && container.length <= 14) {
          await this.searchContainers(container);
        } else {
          //Code not has registered
          this.containerListUpdate[index].error = false;
          this.containerListUpdate[index].message = "";
          this.containerListUpdate[index].type = "";
          this.addSamples = false;
        }
        let error = false;
        for (let item of this.containerListUpdate) {
          if (item.error) {
            if (item.message != "El QR está duplicado") error = true;
            break;
          } else {
            error = false;
          }

          if (item.message.includes("La muestra ya se recibió")) {
            this.addSamples = false;
          }
        }

        this.shouldNotes = error;
      } else {
        console.log("EL OTRO");
        this.containerListUpdate[index].type = "";
      }
    },
    async searchContainers(code) {
      // console.log(code)
      if (code.length >= 3 && code.length < 14) {
        let codes = await this.getContainersFromChars(code);
        this.items = codes;
        this.openMenu = true;
      } else {
        this.items = [];
      }
    },
    async addReceptionForm() {
      if (this.showUpdateFolioDialog) {
        try {
          this.isAddingReception = true;
          let img = [];
          for (let file of this.formData) {
            if (file.img.startsWith("http")) {
              img.push({
                date: file.date,
                img: file.img,
                tags: file.tags || [],
              });
            } else {
              let imgFile = this.dataURLtoFile(file.img);
              // Configura las opciones de compresión según tus necesidades
              const options = {
                quality: 0.6,
                maxWidth: 800,
                maxHeight: 800,
              };

              // Crea una promesa para manejar la compresión asincrónica
              const compressedBlob = await new Promise((resolve, reject) => {
                new Compressor(imgFile, {
                  ...options,
                  success: resolve,
                  error: reject,
                });
              });
              let form_data = new FormData();
              form_data.append("file", compressedBlob);
              const { urlImg } = await this.uploadImage(form_data);
              img.push({ date: file.date, img: urlImg, tags: file.tags || [] });
            }
          }
          let containers = [];
          let wrongContainers = [];
          for (let item of this.containerListUpdate) {
            if (item.error) {
              wrongContainers.push(item.container);
            }
            containers.push(item.container);
          }
          let receptions = await this.updateReception({
            _id: this.folioSelected._id,
            note: this.notes,
            containers: containers,
            wrongContainers: wrongContainers,
            img: img,
            laboratory: this.laboratory,
            kitType: this.kitType ? this.kitType.value : null,
          });
          if (!receptions.error) {
            this.dialogAdvice2 = false;
            this.closeUpdateFolioDialog();
            this.SET_REFRESH_TABLE(true);
            this.SET_ALERT_TYPE({
              visible: true,
              type: "success",
              text: "Recepción actualizada!!",
              timeout: 8000,
            });
          } else {
            this.SET_ALERT_TYPE({
              visible: true,
              type: "error",
              text: "Algo salio mal al actualizar el folio, intenta de nuevo!!",
              timeout: 8000,
            });
          }
        } catch (error) {
          console.log(`Error in request ${error}`);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: "Algo salio mal al actualizar el folio, intenta de nuevo!!",
            timeout: 8000,
          });
        }
      } else {
        try {
          this.isAddingReception = true;

          let img = [];
          for (let file of this.formData) {
            let imgFile = this.dataURLtoFile(file.img);
            let form_data = new FormData();
            // Configura las opciones de compresión según tus necesidades
            const options = {
              quality: 0.6,
              maxWidth: 800,
              maxHeight: 800,
            };

            // Crea una promesa para manejar la compresión asincrónica
            const compressedBlob = await new Promise((resolve, reject) => {
              new Compressor(imgFile, {
                ...options,
                success: resolve,
                error: reject,
              });
            });
            form_data.append("file", compressedBlob);
            const { urlImg } = await this.uploadImage(form_data);
            img.push({ date: file.date, img: urlImg, tags: file.tags || [] });
          }

          let containers = [];
          let wrongContainers = [];
          for (let item of this.containersList) {
            if (item.error) {
              wrongContainers.push(item.container);
            }
            containers.push(item.container);
          }
          let user = localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user"))
            : "";
          let receptions = await this.addReception({
            // folioAnalysis: this.folioAnalysis,
            note: this.notes,
            containers: containers,
            wrongContainers: wrongContainers,
            img: img,
            user: user._id,
            laboratory: this.laboratory,
            kitType: this.kitType ? this.kitType.value : null,
          });
          if (!receptions.error) {
            this.cleanList();
            this.formData = [];
            this.SET_REFRESH_TABLE(true);
            this.SET_ALERT_TYPE({
              visible: true,
              type: "success",
              text: "Recepción creada!!",
              timeout: 8000,
            });
          } else {
            this.SET_ALERT_TYPE({
              visible: true,
              type: "error",
              text: "Algo salio mal al registrar muestras, intenta de nuevo!!",
              timeout: 8000,
            });
          }
        } catch (error) {
          console.log(`Error in request ${error}`);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: "Algo salio mal al registrar muestras, intenta de nuevo!!",
            timeout: 8000,
          });
        }
      }
      this.isAddingReception = false;
    },
    dateFormat(value) {
      const date = `${dayjs(value).format("DD-MM-YYYY - HH:mm")} hrs`;
      return date ? date : "----";
    },
    getStatus(value) {
      const status = {
        RECEIVED: "Recibida",
        MEASURING: "Midiendo",
        MEASURED: "Medido",
        VALUATING: "Valuando",
        VALUATED: "Valuado",
      };
      return status[value];
    },
    handleClick(item) {
      this.SET_SELECTED_RECEPTION(item);
      this.$router.push(`/physicochemical-test/${item._id}`);
    },
    filterItems(container) {
      for (const containerData of this.containersList) {
        if (containerData.container == container) {
          containerData.autocomplete = true;
        } else {
          containerData.autocomplete = false;
        }

        if (containerData.message.includes("La muestra ya se recibió")) {
          this.addSamples = false;
        }
      }
    },
    filterItemsUpdate(container) {
      for (const containerData of this.containerListUpdate) {
        if (containerData.container == container) {
          containerData.autocomplete = true;
        } else {
          containerData.autocomplete = false;
        }

        if (containerData.message.includes("La muestra ya se recibió")) {
          this.addSamples = false;
        }
      }
    },
    getCrop(value) {
      const crop = {
        olive: "Aceituna",
        chard: "Acelga",
        achiote: "Achiote",
        agave: "Agave",
        avocado: "Aguacate",
        garlic: "Ajo",
        sesame: "Ajonjolí",
        basil: "Albahaca",
        artichoke: "Alcachofa",
        alcatraz: "Alcatraz",
        shrunkenAlfalfa: "Alfalfa achicalada",
        greenAlfalfa: "Alfalfa verde",
        cotton: "Algodón",
        almond: "Almendro",
        birdseed: "Alpiste",
        amaranth: "Amaranto",
        anise: "Anís",
        celery: "Apio",
        blueberry: "Arándano",
        myrtle: "Arrayán",
        rice: "Arroz",
        greenForageOats: "Avena forrajera en verde",
        oatGrain: "Avena grano",
        bamboo: "Bambú",
        eggplant: "Berenjena",
        beetroot: "Betabel",
        broccoli: "Brocoli",
        peanut: "Cacahuate",
        cocoa: "Cacao",
        cherryCoffee: "Café cereza",
        pumpkin: "Calabaza",
        sweetPotato: "Camote",
        sugarCane: "Caña de azúcar",
        canola: "Canola",
        carambolo: "Carambolo",
        safflower: "Cártamo",
        barley: "Cebada",
        greenForageBarley: "Cebada forrajera en verde",
        onion: "Cebolla",
        chives: "Cebollín",
        ryeGrain: "Centeno grano",
        cherry: "Cereza",
        chabacano: "Chabacano",
        pea: "Chícharo",
        squash: "Chayote",
        chia: "Chía",
        chilacayote: "Chilacayote",
        chillyPepper: "Chile / Pimiento",
        custardApple: "Chirimoya",
        coriander: "Cilantro",
        plum: "Ciruela",
        coconutFruit: "Coco fruta",
        cabbage: "Col (repollo)",
        brusselsSprouts: "Col de Bruselas",
        cauliflower: "Coliflor",
        cumin: "Comino",
        chrysanthemum: "Crisantemo (planta)",
        peach: "Durazno",
        date: "Dátil",
        greenBean: "Ejote",
        cornCob: "Elote",
        dill: "Eneldo",
        epazote: "Epazote",
        asparagus: "Espárrago",
        spicesAndMedicinals: "Especias y medicinales",
        spinach: "Espinaca",
        raspberry: "Frambuesa",
        strawberry: "Fresa",
        bean: "Frijol",
        chickpea: "Garbanzo",
        sunflower: "Girasol",
        pomegranate: "Granada",
        soursop: "Guanábana",
        guava: "Guayaba",
        henequen: "Henequén",
        peppermint: "Hierbabuena",
        aromaticHerbs: "Hierbas aromáticas",
        fig: "Higo",
        jamaica: "Jamaica",
        ginger: "Jenjibre",
        jicama: "Jícama",
        lettuce: "Lechuga",
        lentil: "Lenteja",
        lime: "Lima",
        lemon: "Limón",
        linseed: "Linaza",
        litchi: "Litchi",
        corn: "Maíz",
        macadamia: "Macadamia",
        maguey: "Maguey",
        mamey: "Mamey",
        tangerine: "Mandarina",
        mango: "Mango",
        mangosteen: "Mangostán",
        chamomile: "Manzanilla",
        appleTree: "Manzano",
        passionFruit: "Maracuyá",
        daisyFlower: "Margarita",
        marjoram: "Mejorana",
        melon: "Melón",
        quince: "Membrillo",
        mint: "Menta",
        moringa: "Moringa",
        turnip: "Nabo",
        nanche: "Nanche",
        napa: "Napa",
        orange: "Naranja",
        medlar: "Níspero",
        nochebuena: "Nochebuena (planta)",
        walnut: "Nogal",
        noni: "Noni",
        nopal: "Nopal",
        oregano: "Orégano",
        ornamental: "Ornamentales",
        orchid: "Orquídea (planta)",
        oilPalm: "Palma africana o de aceite",
        potato: "Papa",
        papaya: "Papaya",
        grasslands: "Pastizales",
        matGrass: "Pasto tapete (m2)",
        pasturesAndMeadows: "Pastos y praderas",
        cucumber: "Pepino",
        pearTree: "Peral",
        parsley: "Perejil",
        peron: "Perón",
        persimony: "Persimonio",
        pepper: "Pimienta",
        pineapple: "Piña",
        pinion: "Piñón",
        pistache: "Pistache",
        pitaya: "Pitaya",
        banana: "Plátano",
        pore: "Poro",
        radish: "Rábano",
        rambutan: "Rambután",
        romerito: "Romerito",
        rosemary: "Romero",
        aloe: "Sábila",
        watermelon: "Sandía",
        sorghum: "Sorgo",
        soy: "Soya",
        stevia: "Stevia",
        tobacco: "Tabaco",
        tamarind: "Tamarindo",
        tejocote: "Tejocote",
        tomato: "Tomate Rojo (jitomate)",
        greenTomato: "Tomate Verde",
        thyme: "Tomillo",
        grapefruit: "Toronja (pomelo)",
        clover: "Trébol",
        wheat: "Trigo",
        dutchTulip: "Tulipán holandés",
        tuna: "Tuna",
        grape: "Uva",
        vanilla: "Vainilla",
        purslane: "Verdolaga",
        vine: "Vid",
        cassavaFood: "Yuca alimenticia",
        carrot: "Zanahoria",
        sapodilla: "Zapote",
        blackberry: "Zarzamora",
        zempoalxochitl: "Zempoalxochitl",
        fallow: "Barbecho",
        other: "Otro",
      };
      let result = crop[value];
      return result;
    },
    async getAnalysis() {
      try {
        let folio = -1;
        if (this.search) {
          if (this.search.length > 0) {
            folio = this.search;
          }
        }
        let receptions = await this.getReceptions({
          laboratory: this.laboratory,
          page: this.page,
          folio: folio,
        });
        this.receptionsList = [];
        if (!receptions.error) {
          this.numPages = receptions.pages;
          //process data
          let analysis = receptions.analysis;
          for (let item of analysis) {
            if (item.sample_type.length == 0) {
              item.sample_type = "-";
            }
            if (item.client_company_name.length == 0) {
              item.client_company_name = "-";
            }

            if (item.sampler.length == 0) {
              item.sampler = "-";
            }

            if (item.end_date != null) {
              item.end_date = this.dateFormat(item.end_date);
            } else {
              item.end_date = "-";
            }

            item.reception_date = this.dateFormat(item.reception_date);

            item.analysis_status = this.getStatus(item.analysis_status);
          }

          this.receptionsList = analysis;
        }
      } catch (error) {
        console.log(`REQUEST ERROR ${error}`);
      }
    },
    changeAnalysisValue() {
      if (!this.searchAnalysis) {
        this.analysis = null;
      }
    },
    async addAnalysis() {
      try {
        if (!this.errorAnalysis && this.analysis) {
          let flag = false;
          for (let item of this.analysisToGenerateResults) {
            if (this.analysis.analysis.folio === item.folio) {
              flag = true;
              break;
            }
          }

          if (flag) {
            this.errorMessage = "El analisis ya se agrego";
            this.errorAnalysis = true;
          } else {
            this.loadAnalysis = true;
            let response = await this.generateResultData(
              this.analysis.analysis.folio
            );
            let url = "";
            let key = "";
            if (typeof response != null) {
              url = response.result;
              key = response.key;
              this.reportKeys.push(response.key);
            }

            this.analysis.analysis.report_view = url;
            this.analysis.analysis.key = key;
            this.analysisToGenerateResults.push(this.analysis.analysis);
            this.analysis = null;
            this.loadAnalysis = false;
            this.SET_ANALYSES_RESULTS([]);
          }
        }
      } catch (e) {
        this.loadAnalysis = false;
        console.log(e);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: e.message,
          timeout: 5000,
        });
      }
    },
    async removeAnalysis() {
      let keys = [];
      this.selectedParend.forEach((item, index) => {
        this.removeItemOnce(this.analysisToGenerateResults, item);
        keys.push(item.key);
      });
      this.selectedParend = [];
      if (keys.length > 0) {
        await this.deleteTempFiles(keys);
      }
      keys.forEach((item, index) => {
        this.removeItemOnce(this.reportKeys, item);
      });
      this.SET_ANALYSES_RESULTS([]);
    },
    async generateResultsByFolios() {
      if (this.analysisToGenerateResults.length > 0) {
        try {
          this.isLoadingGenerate = true;
          let folios = [];
          for (let item of this.analysisToGenerateResults) {
            folios.push(item.folio);
          }
          let response = await this.generateResults(folios);
          if (response.error) throw response;

          this.responseList = response.data;

          let listToDelete = [];

          this.responseList.forEach((item, index) => {
            if (!item.error) {
              for (let analysis of this.analysisToGenerateResults) {
                if (item.folio === analysis.folio) {
                  listToDelete.push(analysis);
                  break;
                }
              }
            }
          });

          listToDelete.forEach((item, index) => {
            this.removeItemOnce(this.analysisToGenerateResults, item);
          });

          this.dialogResponseResult = true;
          this.isLoadingGenerate = false;
        } catch (e) {
          this.isLoadingGenerate = false;
          console.log(e);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: e.message,
            timeout: 5000,
          });
        }
        this.SET_ANALYSES_RESULTS([]);
      } else {
        this.showSnack = true;
      }
    },
    removeItemOnce(arr, value) {
      var index = arr.indexOf(value);
      if (index > -1) {
        arr.splice(index, 1);
      }
      return arr;
    },
    getType(value) {
      const types = {
        INITIAL: "Inicial",
        MONITORING: "Seguimiento",
      };
      return types[value];
    },
    dateFormat(value) {
      const date = `${dayjs(value).format("D/M/YYYY - HH:mm")} hrs`;
      return date ? date : "----";
    },
    getSampleType(value) {
      console.log(value);
      const types = {
        CLASSIC: "Muestra suelo",
        MAS: "Muestra para genómica",
        MAS_VEGETABLE: "Muestra vegetal",
        VEGETABLE: "Muestra vegetal sin georeferencia",
        COMPOST: "Composta",
        PRODUCT: "Producto",
        SUBSTRATUM: "Sustrato",
        NO_ADVISOR: "Muestra suelo sin asesor",
      };
      if (value.sample_type) {
        let temp = types[value.sample_type];
        if (value.sample_type == "COMPOST" || value.sample_type == "PRODUCT") {
          if (value.composition) {
            let compositions = {
              LIQUID: "Líquido",
              SOLID: "Sólido",
            };
            temp += ` ${compositions[value.composition]}`;
          }
        } else if (
          value.sample_type == "MAS_VEGETABLE" ||
          value.sample_type == "VEGETABLE"
        ) {
          if (value.vegetableType) {
            let vegetableTypes = {
              Seedling: "Plántula",
              Root: "Raíz",
              Seed: "Semilla",
              Stem: "Tallo",
              Leaf: "Hoja",
              Fruit: "Fruto",
              Flower: "Flor",
            };
            if (vegetableTypes[value.vegetableType]) {
              temp += ` ${vegetableTypes[value.vegetableType]}`;
            }
          }
        }
        return temp;
      } else {
        return "";
      }
    },
    async closeResultDialog() {
      this.closeLoading = true;
      if (this.reportKeys.length > 0) {
        await this.deleteTempFiles(this.reportKeys);
      }
      this.dialog = false;
      this.selectedParend = [];
      this.analysisToGenerateResults = [];
      this.SET_ANALYSES_RESULTS([]);
      this.closeLoading = false;
    },
    getStatusResponse(value) {
      const types = {
        0: "No iniciado",
        1: "Midiendo",
        2: "Medido",
        3: "Valuado",
      };
      return types[value];
    },
    getErrorResponse(value) {
      if (value) {
        return "No generado";
      } else {
        return "Generado";
      }
    },
    handleEnter() {
      if (this.addSamples) {
        this.addContainer();
      }
    },
    showReport(url) {
      this.urlPreview = url;
      this.dialogT = true;
    },
    showReport(reportURL) {
      this.urlPreview = reportURL;
      this.dialogT = true;
    },
    closeUpdateFolioDialog() {
      this.formData = [];
      this.items = [];
      this.containerListUpdate = [];
      this.dialogUpdateFolio = false;
      this.SET_FOLIO_SELECTED(null);
      this.SHOW_UPDATE_FOLIO_DIALOG(0);
      this.notes = "";
      this.shouldNotes = false;
    },
    async validateSamples() {
      try {
        let response = await this.validateSample({
          _id: this.folioSelected._id,
        });
        if (response) {
          if (!response.error) {
            this.closeUpdateFolioDialog();
            this.SET_REFRESH_TABLE(true);
            this.SET_ALERT_TYPE({
              visible: true,
              type: "success",
              text: "Muestras validadas!!",
              timeout: 8000,
            });
          } else {
            this.SET_ALERT_TYPE({
              visible: true,
              type: "error",
              text: "Algo salio mal al validar folio, intenta de nuevo!!",
              timeout: 8000,
            });
          }
        }
      } catch (e) {
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Algo salio mal al validar folio, intenta de nuevo!!",
          timeout: 8000,
        });
      }
    },
    async reciveFolio() {
      try {
        let response = await this.receiveFolioRequest({
          id: this.folioSelected._id,
          laboratory: this.user.laboratory._id,
          user: this.user._id,
        });
        let receptionsResponse = null;
        if (this.shouldNotes) {
          this.isAddingReception = true;
          let img = [];
          for (let file of this.formData) {
            if (file.img.includes("http")) {
              img.push({ date: file.date, img: file.img });
            } else {
              let imgFile = this.dataURLtoFile(file.img);
              let form_data = new FormData();
              form_data.append("file", imgFile);
              const { urlImg } = await this.uploadImage(form_data);
              img.push({ date: file.date, img: urlImg });
            }
          }

          let containers = [];
          let wrongContainers = [];
          for (let item of this.containerListUpdate) {
            if (item.error) {
              wrongContainers.push(item.container);
            }
            containers.push(item.container);
          }
          receptionsResponse = await this.updateReception({
            _id: this.folioSelected._id,
            note: this.notes,
            containers: containers,
            wrongContainers: wrongContainers,
            img: img,
            laboratory: this.laboratory,
          });
        } else {
          receptionsResponse = { error: false };
        }
        if (response && receptionsResponse) {
          if (!response.error && !receptionsResponse.error) {
            this.closeUpdateFolioDialog();
            this.SET_REFRESH_TABLE(true);
            this.SET_ALERT_TYPE({
              visible: true,
              type: "success",
              text: "Muestras recibidas!!",
              timeout: 8000,
            });
          } else {
            this.SET_ALERT_TYPE({
              visible: true,
              type: "error",
              text: "Algo salio mal al recibir muestras, intenta de nuevo!!",
              timeout: 8000,
            });
          }
        }
      } catch (e) {
        console.log(e);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Algo salio mal al recibir muestras, intenta de nuevo!!",
          timeout: 8000,
        });
      }
    },
    openEvidenceDialog() {
      this.dialogEvidence = true;
    },
    deleteEvidence(index) {
      this.formData.splice(index, 1);
    },
    closePhoto() {
      this.showCaputre = false;
    },
    saveFiles(value) {
      this.showCaputre = false;
      this.formData.push(...value);
    },
    dataURLtoFile(img) {
      let arr = img.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], "evidencePhoto.png", { type: mime });
    },
    async getLaboratories() {
      try {
        let response = await this.getLaboratoriesRequest();
        if (response) {
          if (!response.error) {
            this.laboratories = response.laboratories;
          } else {
            console.log(response.message);
            this.SET_ALERT_TYPE({
              visible: true,
              type: "error",
              text: "Algo salio mal al cargar laboratorios, intenta de nuevo!!",
              timeout: 8000,
            });
          }
        } else {
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: "Algo salio mal al cargar laboratorios, intenta de nuevo!!",
            timeout: 8000,
          });
        }
      } catch (e) {
        console.log(e);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Algo salio mal al cargar laboratorios, intenta de nuevo!!",
          timeout: 8000,
        });
      }
    },
    removeFolio(index) {
      this.REMOVE_FOLIOS_SELECTED(index);
    },
    closeSendSamples() {
      this.CLEAN_PARENT(true);
      this.lab_selected = null;
      this.laboratories = [];
      this.searchFolioSend = null;
      this.searchFolio = null;
      this.send = false;
      // this.qtySamples = 0;
    },
    async sendSamples() {
      try {
        this.isSending = true;
        let flag = true;
        if (!this.lab_selected) {
          flag = false;
          this.errorLab.error = true;
          this.errorLab.message = "Campo obligatorio";
        }
        if (this.folios.length == 0) {
          flag = false;
          this.errorFolio.error = true;
          this.errorFolio.message =
            "Campo obligatorio, selecciona al menos una muestra";
        }
        // if (this.qtySamples <= 0) {
        //   flag = false;
        //   this.errorQtySamples.error = true;
        //   this.errorQtySamples.message = "Campo obligatorio";
        // }
        if (flag) {
          // Mak process to send samples to other laboratory
          let payload = {
            analysis: this.folios,
            laboratorieTo: this.lab_selected._id,
            laboratorieFrom: this.user.laboratory._id,
            user: this.user._id,
            // qty: this.qtySamples,
          };
          // console.log(payload);
          let response = await this.sendFolios(payload);
          if (response) {
            if (!response.error) {
              this.SET_REFRESH_TABLE(true);
              this.closeSendSamples();
              this.SET_ALERT_TYPE({
                visible: true,
                type: "success",
                text: "Muestra enviada exitosamente !!",
                timeout: 5000,
              });
            } else {
              console.log(response.message);
              this.SET_ALERT_TYPE({
                visible: true,
                type: "error",
                text: "Algo salio mal al enviar muestras, intente de nuevo !!",
                timeout: 5000,
              });
            }
          } else {
            this.SET_ALERT_TYPE({
              visible: true,
              type: "error",
              text: "Algo salio mal al enviar muestras, intente de nuevo !!",
              timeout: 5000,
            });
          }
        }
      } catch (e) {
        console.log(e);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: "Algo salio mal al enviar muestras, intente de nuevo !!",
          timeout: 5000,
        });
      }
      this.isSending = false;
    },
    showImageTagger(img, index, tags) {
      this.currentImgIndexToTaggin = index;
      this.imgToTagger = img;
      this.tags = tags || [];
      this.showTaggerModal = true;
    },
    loadTags(tags) {
      this.tags = [];

      this.formData[this.currentImgIndexToTaggin].tags = tags;
      console.log(this.currentImgIndexToTaggin);
      console.log(this.formData);
      this.showTaggerModal = false;
      this.imgToTagger = null;
      this.currentImgIndexToTaggin = null;
    },
    showAdviceUpdate() {
      console.log("ENTRE");
      this.dialogAdvice2 = true;
    },
    handleRouteByConfigUser(user) {
      console.log(user);
      if (user.config) {
        switch (user.config.home) {
          case "RISK_LEVEL_DASHBOARD":
            this.$router.push(`/cmda-admin`);
            break;

          default:
            //stays in the reception dashboard
            break;
        }
      }
    },
  },
};
</script>

<style scoped>
.v-input {
  flex: none;
  width: 400px;
}

.v-card__title {
  justify-content: end;
}
.scrollBox {
  overflow-y: scroll !important;
}
</style>
