<template>
  <v-dialog v-model="show" width="50vw" scrollable persistent>
    <v-card height="auto" elevation="5" class="rounded-lg" min-height="200">
      <v-card-title class="text-h5 lighten-2">
        Configuración paquete
      </v-card-title>
      <v-card-text>
        <v-form lazy-validation ref="form">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  clearable
                  label="Nombre del paquete"
                  v-model="packageName"
                  :rules="validation()"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  outlined
                  dense
                  clearable
                ></v-text-field>
              </v-col>
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="markersList"
                  :search="search"
                  fixed-header
                  height="300px"
                >
                  <template #[`item.name`]="{ item }">
                    <span v-if="item.isPathogen" class="warning--text">
                      {{ item.name }}
                    </span>
                    <span v-else class="primary--text">
                      {{ item.name }}
                    </span>
                  </template>
                  <template #[`item.markerId`]="{ item }">
                    <v-icon @click="removeMarkerFromPackage(item)"
                      >mdi-close</v-icon
                    >
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col>
              <v-btn
                color="red"
                block
                @click="closeModal"
                dark
                :disabled="isLoading"
                >Cancelar</v-btn
              >
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                block
                @click="updateProjectPackageResult"
                :loading="isLoading"
                >Actualizar paquete</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//vuex
import { mapActions, mapMutations, mapState } from "vuex";
//validations
import { requiredValidation } from "@/helpers/validations.js";
export default {
  data: () => ({
    headers: [
      {
        text: "Nombre",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "Reacción",
        align: "start",
        sortable: false,
        value: "reaction",
      },
      {
        text: "Acciones",
        align: "start",
        sortable: false,
        value: "markerId",
      },
    ],
    markersList: [],
    markersToBeRemoved: [],
    packageName: null,
    isLoading: false,
    search: null,
  }),
  props: {
    value: Boolean,
  },
  created() {
    this.markersList.push(...this.projectSelected.markersPackage.mos);
    this.packageName = this.projectSelected.markersPackage.name;
  },
  computed: {
    ...mapState("project", ["projectSelected"]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions("project", ["updateProjectPackageResultActon"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    closeModal() {
      this.show = false;
    },
    validation(name) {
      return requiredValidation(name);
    },
    async updateProjectPackageResult() {
      try {
        if (this.$refs.form.validate()) {
          this.isLoading = true;
          let payload = {
            body: {
              packageId: this.projectSelected.markersPackage._id,
              laboratoryId: this.projectSelected.markersPackage.laboratory,
              markersList: this.markersList,
              markersToBeRemoved: this.markersToBeRemoved,
              packageName: this.packageName,
            },
            query: {
              projectId: this.projectSelected._id,
            },
          };

          await this.updateProjectPackageResultActon(payload);
          this.SET_ALERT_TYPE({
            visible: true,
            type: "success",
            text: `Paquete actualizado con exito`,
            timeout: 5000,
          });
          this.$emit("updateData", this.packageName);
        }
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
      this.isLoading = false;
    },
    removeMarkerFromPackage(item) {
      this.markersToBeRemoved.push(item);
      let index = this.markersList.indexOf(item);
      this.markersList.splice(index, 1);
    },
  },
};
</script>
