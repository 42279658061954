import axios from "axios";

/**
 * Function to process molecular csv file
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - Molecular csv file
 * @returns {data} -  Returns array of molecular tests
 */
export async function uploadFile({ commit }, payload) {
  try {
    const { data } = await axios.post("/molecular/process-files", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to process molecular csv file
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - Molecular csv file
 * @returns {data} -  Returns array of molecular tests
 */
export async function addIdentifications({ commit }, payload) {
  try {
    const { data } = await axios.post("/molecular/save-identifications", {
      molecularIdentifications: payload.testsFormated,
      user : payload.user
    });
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get molecular identifications from DB
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - Filters
 * @returns {data} -  Returns array of molecular tests
 */
export async function getIdentifications({ commit, rootState }, payload) {
  try {
    const { data } = await axios.get("/molecular/get-identifications", {
      params: payload,
      cancelToken: rootState.cancelToken && rootState.cancelToken.token,
    });
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}

/**
 * Function to get molecular tests from DB
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - Filters
 * @returns {data} -  Returns array of molecular tests
 */
 export async function getMolecularTests({ commit, rootState }, payload) {
  try {
    const { data } = await axios.get("/molecular/get-molecular-tests", {
      params: payload,
      cancelToken: rootState.cancelToken && rootState.cancelToken.token,
    });
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e;
  }
}

/**
 * Function to get molecular test from DB
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - Molecular test ID
 * @returns {data} -  Returns molecular test
 */
 export async function getMolecularTestById({ commit, rootState }, payload) {
  try {
    const { data } = await axios.get(`/molecular/molecular-test-by-id/${payload}`);
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e;
  }
}

/**
 * Function to update concentration from strain
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - Strain ID & concentration
 * @returns {data} -  Returns strain updated
 */
 export async function updateStrainConcentration({ commit, rootState }, payload) {
  try {
    const { data } = await axios.put(`/molecular/update-strain`, payload);
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e;
  }
}

/**
 * Function to delete molecular test by _id
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - Molecular test _id
 * @returns {data} -  Returns object with error status
 */
 export async function deleteMolecularTest({ commit, rootState }, payload) {
  try {
    const { data } = await axios.delete(`/molecular/delete`, {
      params: {
        _id : payload
      },
      cancelToken: rootState.cancelToken && rootState.cancelToken.token,
    });
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e;
  }
}

/**
 * Function to get analysis with molecular tests & report
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - Filters
 * @returns {data} -  Returns object with analyises
 */
 export async function getAnalysisWithReport({ commit, rootState }, payload) {
  try {
    const { data } = await axios.get(`/molecular/analysis-with-report`, {
      params: payload,
      cancelToken: rootState.cancelToken && rootState.cancelToken.token,
    });
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e;
  }
}

/**
 * Function to get analysis with molecular tests & without report
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - Filters
 * @returns {data} -  Returns object with analyises
 */
 export async function getAnalysisWithoutReport({ commit, rootState }, payload) {
  try {
    const { data } = await axios.get(`/molecular/analysis-without-report`, {
      params: payload,
      cancelToken: rootState.cancelToken && rootState.cancelToken.token,
    });
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e;
  }
}

/**
 * Function to generate qpcr report
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - Params { analysis : String, temp : Boolean }
 * @returns {data} -  Returns object with request status & report url
 */
 export async function generateReport({ commit }, payload) {
  try {
    const { data } = await axios.get(`/results/qpcr-report`, {
      params: payload
    });
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e;
  }
}

/**
 * Function to upload .csv file
 * @param  {function} {commit} - Vuex function
 * @param {payload} payload - File
 * @returns {data} -  Returns object with request status
 */
export async function saveReportResult({ commit }, payload) {
  try {
    const { data } = await axios.post("/molecular/save-file", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (data.error) throw data;

    return data;
  } catch (e) {
    return e.response.data;
  }
}